<div class="mat-typography mat-app-background">
    <!-- <pre>{{form.value.SubID | json2}}</pre> -->
    <!-- <pre>{{data.subs | json2}}</pre> -->
    <!-- <pre>{{data.prov}}</pre>
    <pre>{{data.ins}}</pre> -->
    <!-- <pre>{{provID}}</pre> -->
    <!-- <pre>{{insID}}</pre> -->
    <!-- <pre>{{data.sID}}</pre> -->
    <!-- <pre>{{provID}}</pre> -->
    <!-- <pre>{{insID}}</pre> -->

    <h2 mat-dialog-title>{{data.engLang ? 'Submitter Information (Clearing House or Insurance)' : 'Información del
        Remitente
        (Clearing House o Seguro)'}}</h2>

    <div class="text-center">
        <div *ngIf="dProv">Prov: {{dProv}}</div>
        <div *ngIf="dIns">Plan: {{dIns}}</div>
    </div>

    <mat-dialog-content [formGroup]="form">
        <div class="text-center">
            <mat-form-field class="select-width">
                <mat-label>{{data.engLang ? 'By way of and/or account name' : 'Vía y/o nombre de la
                    cuenta'}}</mat-label>
                <mat-select placeholder="{{data.engLang ? 'Select Account' : 'Seleccione Cuenta'}}"
                    (selectionChange)="onChange_subID($event)" formControlName="SubID">

                    <mat-option *ngFor="let sub of data.subs" [value]="sub.SubID">
                        {{sub.SubVia}}
                    </mat-option>

                </mat-select>
            </mat-form-field>
        </div>

        <div class="d-flex justify-content-between">
            <div class="label-div">
                <mat-label>{{data.engLang ? 'Account name:' : 'Nombre de la cuenta:'}}</mat-label>
            </div>
            <div>
                <mat-form-field>
                    <input matInput placeholder="Account name" formControlName="SubVia" (input)="toUpperCase($event)">
                </mat-form-field>
                <mat-error *ngIf="SubVia.invalid && SubVia.touched">
                    <span *ngIf="SubVia.errors?.required">
                        {{data.engLang ? 'Ex': 'Ej'}}: Inmediata - Dr John Doe
                    </span>
                </mat-error>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="label-div">
                <mat-label>{{data.engLang ? 'Last or practice name:' : 'Apellidos o nombre oficina:'}}</mat-label>
            </div>
            <div>
                <mat-form-field>
                    <input matInput placeholder="Last/practice name" formControlName="SubLastNm"
                        (input)="toUpperCase($event)">
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="label-div">
                <mat-label>{{data.engLang ? 'First name if personal acct.:' : 'Nombre si cuenta personal:'}}</mat-label>
            </div>
            <div class="align-items-end">
                <mat-form-field>
                    <input matInput placeholder="First name if person" formControlName="SubFstNm"
                        (input)="toUpperCase($event)">
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="label-div">
                <mat-label>ISA06 - Interchange Sender ID (accnt. no.):</mat-label>
            </div>
            <div class="align-items-end">
                <mat-form-field>
                    <input matInput placeholder="ISA06" formControlName="SubIDrem">
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="label-div">
                <mat-label>ISA05 - Interchange Sender ID Qualifier:</mat-label>
                <button type="button" class="btn btn-link btn-sm" style="font-style: italic;"
                    (click)="onClick_inmediata()"
                    matTooltip="{{ data.engLang ? 'Input Inmediata values' : 'Entrar valores para Inmediata' }}"
                    matTooltipPosition="right">Inmediata</button>
            </div>
            <div>
                <mat-form-field>
                    <input matInput placeholder="ISA05" formControlName="SubIsa05" maxlength="2"
                        (input)="toUpperCase($event)">
                </mat-form-field>
                <mat-error *ngIf="SubIsa05.invalid && SubIsa05.touched">
                    <span *ngIf="SubIsa05.errors?.invalidIsaQualifier">
                        {{SubIsa05.errors?.validValues}}
                    </span>
                </mat-error>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="label-div">
                <mat-label>ISA08 - Interchange Receiver ID (CH no.):</mat-label>
            </div>
            <div>
                <mat-form-field>
                    <input matInput placeholder="ISA08" formControlName="SubIsa08">
                </mat-form-field>
                <mat-error *ngIf="SubIsa08.invalid && SubIsa08.touched">
                    <span *ngIf="SubIsa08.errors?.required">
                        Inmediata = 660610220
                    </span>
                </mat-error>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="label-div">
                <mat-label>ISA07 - Interchange Receiver ID Qualifier:</mat-label>
            </div>
            <div>
                <mat-form-field>
                    <input matInput placeholder="ISA07" formControlName="SubIsa07" maxlength="2"
                        (input)="toUpperCase($event)">
                </mat-form-field>
                <mat-error *ngIf="SubIsa07.invalid && SubIsa07.touched">
                    <span *ngIf="SubIsa07.errors?.invalidIsaQualifier">
                        {{SubIsa07.errors?.validValues}}
                    </span>
                </mat-error>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="label-div">
                <mat-label>GS02 - Application Sender's Code:</mat-label>
            </div>
            <div>
                <mat-form-field>
                    <input matInput placeholder="GS02" formControlName="SubGs02">
                </mat-form-field>
                <mat-error *ngIf="SubGs02.invalid && SubGs02.touched">
                    <span *ngIf="SubGs02.errors?.required">
                        Inmediata = 660610220
                    </span>
                </mat-error>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="label-div">
                <mat-label>GS03 - Application Receiver's Code:</mat-label>
            </div>
            <div>
                <mat-form-field>
                    <input matInput placeholder="GS03" formControlName="SubGs03">
                </mat-form-field>
                <mat-error *ngIf="SubGs03.invalid && SubGs03.touched">
                    <span *ngIf="SubGs03.errors?.required">
                        Inmediata = 660610220
                    </span>
                </mat-error>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="label-div">
                <mat-label>{{data.engLang ? 'Office contact (first & last names):' : 'Contacto en la oficina (nombre y
                    apellidos):'}}</mat-label>
            </div>
            <div>
                <mat-form-field>
                    <input matInput placeholder="Contact" formControlName="SubContact" (input)="toUpperCase($event)">
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="label-div">
                <mat-label>{{data.engLang ? 'Office contact tel:' : 'Teléfono en la oficina:'}}</mat-label>
            </div>
            <div>
                <mat-form-field>
                    <input matInput placeholder="Tel" formControlName="SubContTel" maxlength="10">
                </mat-form-field>
                <mat-error *ngIf="SubContTel.invalid && SubContTel.touched">
                    <span *ngIf="SubContTel.errors?.invalidPhone">
                        {{data.engLang ? '10 digits, ex: 8097938833': '10 dígitos, ej: 8097938833'}}
                    </span>
                    <span *ngIf="SubContTel.errors?.required">
                        {{data.engLang ? 'Required': 'Requerido'}}
                    </span>
                </mat-error>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="label-div">
                <mat-label>{{data.engLang ? 'Office contact fax:' : 'Fax en la oficina:'}}</mat-label>
            </div>
            <div>
                <mat-form-field>
                    <input matInput placeholder="Fax" formControlName="SubContFax">
                </mat-form-field>
                <mat-error *ngIf="SubContFax.invalid && SubContFax.touched">
                    <span *ngIf="SubContFax.errors?.invalidPhone">
                        {{data.engLang ? '10 digits, ex: 8097938833': '10 dígitos, ej: 8097938833'}}
                    </span>
                </mat-error>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="label-div">
                <mat-label>{{data.engLang ? 'Office contact email:' : 'Email en la oficina:'}}</mat-label>
            </div>
            <div>
                <mat-form-field>
                    <input matInput placeholder="Email" formControlName="SubContEmail">
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="label-div">
                <mat-label>{{data.engLang ? 'Acct. user name:' : 'Nombre usuario cuenta:'}}</mat-label>
            </div>
            <div>
                <mat-form-field>
                    <input matInput placeholder="User" formControlName="SubUser">
                </mat-form-field>
                <mat-error *ngIf="SubUser.invalid && SubUser.touched">
                    <span *ngIf="SubUser.errors?.required">
                        {{data.engLang ? 'NONE if not needed': 'NINGUNO si no es requerido'}}
                    </span>
                </mat-error>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="label-div">
                <mat-label>{{data.engLang ? 'Password:' : 'Contraseña:'}}</mat-label>
            </div>
            <div>
                <mat-form-field>
                    <input matInput placeholder="Password" formControlName="SubPw">
                </mat-form-field>
                <mat-error *ngIf="SubPw.invalid && SubPw.touched">
                    <span *ngIf="SubPw.errors?.required">
                        {{data.engLang ? 'NONE if not needed': 'NINGUNO si no es requerido'}}
                    </span>
                </mat-error>
            </div>
        </div>

    </mat-dialog-content>

    <!-- <div>{{form.value | json2}}</div> -->

    <!-- <pre>{{form.valid}}</pre> -->

    <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-start">
            <div *ngIf="+form.value.SubID" class="align-content-center">
                &nbsp;&nbsp;&nbsp;
                <i *ngIf="subIndx > -1" class="fa fa-trash" (click)="onClick_delete(null)"
                    matTooltip="{{ data.engLang ? 'Delete account' : 'Eliminar cuenta' }}"
                    matTooltipPosition="right"></i>
            </div>
            <div *ngIf="+form.value.SubID" class="align-content-center">
                &nbsp;&nbsp;&nbsp;
                <span *ngIf="subIndx > -1 && dProv && dIns" class="material-symbols-outlined new pt-1"
                    (click)="onClick_delete('unlink')"
                    matTooltip="{{ data.engLang ? 'Unlink provider/plan' : 'Desligar proveedor/seguro' }}"
                    matTooltipPosition="right">
                    link_off
                </span>
            </div>
            <div class="align-content-center">
                &nbsp;&nbsp;&nbsp;
                <span class="material-symbols-outlined new pt-1" (click)="onClick_new()"
                    matTooltip="{{ data.engLang ? 'Add new account' : 'Añadir cuenta nueva' }}"
                    matTooltipPosition="right">
                    draft
                </span>
            </div>
        </div>
        <div>
            <mat-dialog-actions align="end">
                <button mat-raised-button mat-dialog-close>Cancel</button>
                <button mat-raised-button [mat-dialog-close]="true" color="primary" (click)="onClick_save()"
                    [disabled]="!form.valid">{{data.engLang ? 'Save' : 'Grabar'}}</button>
            </mat-dialog-actions>
        </div>
    </div>
</div>