
import {
  ChangeDetectionStrategy,
  Component
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirm-dialog',
    template: `
  <div>
    <div class="modal-header">
      <h4 class="modal-title">{{title}}</h4>
    </div>
    <div class="modal-body">
      <p>{{prompt}}</p>
    </div>
    <div class="modal-footer">
      <button type="button"
        [class.btn]=true
        [class.btn-default]="btn_default"
        (click)="close($event, false)">
          {{cancel}}
      </button>
      <button type="button"
        [class.btn]=true
        [class.btn-primary]="btn_primary"
        (click)="close($event, true)">
          {{ok}}
      </button>
    </div>
  </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConfirmDialogComponent {

  constructor(public _activeModal: NgbActiveModal) {  }

  // properties flagged as missing in 7/31/19 build
  title: string;
  prompt: string;
  btn_default: string;
  cancel: string;
  btn_primary: string;
  ok: string;

  close(event: any, result: boolean): any {
    if (event) {
      event.stopPropagation();
      this._activeModal.close(result);
    }
  }

}
