<div style="position: relative;">
    <img [src]="reclFormImg64Seled" alt="Form Image" width="100%" height="100%">

    <div class="input-group txtProvNmSSS">
        <input type="text" id="txtProvNmSSS" class="form-control-sm uc font10" maxlength="50" style="width: 215px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtProvNmSSS">
    </div>

    <div class="input-group txtProvNoSSS">
        <input type="text" id="txtProvNoSSS" class="form-control-sm px-1 uc" maxlength="10" style="max-width: 96px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtProvNoSSS">
    </div>

    <div class="input-group txtDateSSS">
        <input type="text" id="txtDateSSS" class="form-control-sm px-1" maxlength="8" style="max-width: 85px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtDateSSS">
    </div>

    <div class="form-check-input optAHM" ngbTooltip="AHM">
        <input type="radio" id="optAHM" name="plan" (change)="onChange_need2Save()" [(ngModel)]="e.opt_plan"
            value="AHM">
    </div>

    <div class="form-check-input optSSS" ngbTooltip="Triple S">
        <input type="radio" id="optSSS" name="plan" (change)="onChange_need2Save()" [(ngModel)]="e.opt_plan"
            value="SSS">
    </div>

    <div class="input-group txtPatNmSSS">
        <input type="text" id="txtPatNmSSS" class="form-control-sm px-1 py-0 uc font10" maxlength="50"
            style="width: 176px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtPatNmSSS">
    </div>

    <div class="input-group txtContrSSS">
        <input type="text" id="txtContrSSS" class="form-control-sm px-1 py-0 uc font10" maxlength="15"
            style="width: 97px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtContrSSS">
    </div>

    <div class="input-group txtFrom1">
        <input type="text" id="txtFrom1" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtFrom1">
    </div>

    <div class="input-group txtTo1">
        <input type="text" id="txtTo1" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtTo1">
    </div>

    <div class="input-group txtCpt1">
        <input type="text" id="txtCpt1" class="form-control-sm p-0 uc font10" maxlength="14" style="width: 68px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtCpt1">
    </div>

    <div class="input-group txtBal1">
        <input type="text" id="txtBal1" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 66px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtBal1">
    </div>

    <div class="input-group txtRazon1">
        <input type="text" id="txtRazon1" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 84px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtRazon1">
    </div>

    <div class="input-group txtPayDt1">
        <input type="text" id="txtPayDt1" class="form-control-sm px-1 py-0 font10" maxlength="10" style="width: 59px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtPayDt1">
    </div>

    <div class="input-group txtCkNo1">
        <input type="text" id="txtCkNo1" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 63px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtCkNo1">
    </div>

    <div class="input-group txtPayed1">
        <input type="text" id="txtPayed1" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 79px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtPayed1">
    </div>

    <div class="input-group txtComment1">
        <input type="text" id="txtComment1" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 147px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtComment1">
    </div>

    <div class="input-group txtFrom2">
        <input type="text" id="txtFrom2" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtFrom2">
    </div>

    <div class="input-group txtTo2">
        <input type="text" id="txtTo2" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtTo2">
    </div>

    <div class="input-group txtCpt2">
        <input type="text" id="txtCpt2" class="form-control-sm p-0 uc font10" maxlength="14" style="width: 68px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtCpt2">
    </div>

    <div class="input-group txtBal2">
        <input type="text" id="txtBal2" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 66px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtBal2">
    </div>

    <div class="input-group txtRazon2">
        <input type="text" id="txtRazon2" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 84px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtRazon2">
    </div>

    <div class="input-group txtPayDt2">
        <input type="text" id="txtPayDt2" class="form-control-sm px-1 py-0 font10" maxlength="10" style="width: 59px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtPayDt2">
    </div>

    <div class="input-group txtCkNo2">
        <input type="text" id="txtCkNo2" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 63px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtCkNo2">
    </div>

    <div class="input-group txtPayed2">
        <input type="text" id="txtPayed2" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 79px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtPayed2">
    </div>

    <div class="input-group txtComment2">
        <input type="text" id="txtComment2" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 147px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtComment2">
    </div>

    <div class="input-group txtFrom3">
        <input type="text" id="txtFrom3" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtFrom3">
    </div>

    <div class="input-group txtTo3">
        <input type="text" id="txtTo3" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtTo3">
    </div>

    <div class="input-group txtCpt3">
        <input type="text" id="txtCpt3" class="form-control-sm p-0 uc font10" maxlength="14" style="width: 68px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtCpt3">
    </div>

    <div class="input-group txtBal3">
        <input type="text" id="txtBal3" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 66px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtBal3">
    </div>

    <div class="input-group txtRazon3">
        <input type="text" id="txtRazon3" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 84px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtRazon3">
    </div>

    <div class="input-group txtPayDt3">
        <input type="text" id="txtPayDt3" class="form-control-sm px-1 py-0 font10" maxlength="10" style="width: 59px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtPayDt3">
    </div>

    <div class="input-group txtCkNo3">
        <input type="text" id="txtCkNo3" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 63px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtCkNo3">
    </div>

    <div class="input-group txtPayed3">
        <input type="text" id="txtPayed3" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 79px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtPayed3">
    </div>

    <div class="input-group txtComment3">
        <input type="text" id="txtComment3" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 147px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtComment3">
    </div>

    <div class="input-group txtFrom4">
        <input type="text" id="txtFrom4" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtFrom4">
    </div>

    <div class="input-group txtTo4">
        <input type="text" id="txtTo4" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtTo4">
    </div>

    <div class="input-group txtCpt4">
        <input type="text" id="txtCpt4" class="form-control-sm p-0 uc font10" maxlength="14" style="width: 68px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtCpt4">
    </div>

    <div class="input-group txtBal4">
        <input type="text" id="txtBal4" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 66px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtBal4">
    </div>

    <div class="input-group txtRazon4">
        <input type="text" id="txtRazon4" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 84px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtRazon4">
    </div>

    <div class="input-group txtPayDt4">
        <input type="text" id="txtPayDt4" class="form-control-sm px-1 py-0 font10" maxlength="10" style="width: 59px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtPayDt4">
    </div>

    <div class="input-group txtCkNo4">
        <input type="text" id="txtCkNo4" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 63px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtCkNo4">
    </div>

    <div class="input-group txtPayed4">
        <input type="text" id="txtPayed4" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 79px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtPayed4">
    </div>

    <div class="input-group txtComment4">
        <input type="text" id="txtComment4" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 147px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtComment4">
    </div>

    <div class="input-group txtFrom5">
        <input type="text" id="txtFrom5" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtFrom5">
    </div>

    <div class="input-group txtTo5">
        <input type="text" id="txtTo5" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtTo5">
    </div>

    <div class="input-group txtCpt5">
        <input type="text" id="txtCpt5" class="form-control-sm p-0 uc font10" maxlength="15" style="width: 68px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtCpt5">
    </div>

    <div class="input-group txtBal5">
        <input type="text" id="txtBal5" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 66px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtBal5">
    </div>

    <div class="input-group txtRazon5">
        <input type="text" id="txtRazon5" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 84px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtRazon5">
    </div>

    <div class="input-group txtPayDt5">
        <input type="text" id="txtPayDt5" class="form-control-sm px-1 py-0 font10" maxlength="10" style="width: 59px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtPayDt5">
    </div>

    <div class="input-group txtCkNo5">
        <input type="text" id="txtCkNo5" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 63px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtCkNo5">
    </div>

    <div class="input-group txtPayed5">
        <input type="text" id="txtPayed5" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 79px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtPayed5">
    </div>

    <div class="input-group txtComment5">
        <input type="text" id="txtComment5" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 147px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtComment5">
    </div>

    <div class="input-group txtFrom6">
        <input type="text" id="txtFrom6" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtFrom6">
    </div>

    <div class="input-group txtTo6">
        <input type="text" id="txtTo6" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtTo6">
    </div>

    <div class="input-group txtCpt6">
        <input type="text" id="txtCpt6" class="form-control-sm p-0 uc font10" maxlength="16" style="width: 68px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtCpt6">
    </div>

    <div class="input-group txtBal6">
        <input type="text" id="txtBal6" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 66px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtBal6">
    </div>

    <div class="input-group txtRazon6">
        <input type="text" id="txtRazon6" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 84px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtRazon6">
    </div>

    <div class="input-group txtPayDt6">
        <input type="text" id="txtPayDt6" class="form-control-sm px-1 py-0 font10" maxlength="10" style="width: 59px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtPayDt6">
    </div>

    <div class="input-group txtCkNo6">
        <input type="text" id="txtCkNo6" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 63px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtCkNo6">
    </div>

    <div class="input-group txtPayed6">
        <input type="text" id="txtPayed6" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 79px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtPayed6">
    </div>

    <div class="input-group txtComment6">
        <input type="text" id="txtComment6" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 147px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtComment6">
    </div>

    <div class="input-group txtFrom7">
        <input type="text" id="txtFrom7" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtFrom7">
    </div>

    <div class="input-group txtTo7">
        <input type="text" id="txtTo7" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtTo7">
    </div>

    <div class="input-group txtCpt7">
        <input type="text" id="txtCpt7" class="form-control-sm p-0 uc font10" maxlength="16" style="width: 68px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtCpt7">
    </div>

    <div class="input-group txtBal7">
        <input type="text" id="txtBal7" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 66px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtBal7">
    </div>

    <div class="input-group txtRazon7">
        <input type="text" id="txtRazon7" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 84px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtRazon7">
    </div>

    <div class="input-group txtPayDt7">
        <input type="text" id="txtPayDt7" class="form-control-sm px-1 py-0 font10" maxlength="10" style="width: 59px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtPayDt7">
    </div>

    <div class="input-group txtCkNo7">
        <input type="text" id="txtCkNo7" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 63px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtCkNo7">
    </div>

    <div class="input-group txtPayed7">
        <input type="text" id="txtPayed7" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 79px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtPayed7">
    </div>

    <div class="input-group txtComment7">
        <input type="text" id="txtComment7" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 147px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtComment7">
    </div>

    <div class="input-group txtFrom8">
        <input type="text" id="txtFrom8" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtFrom8">
    </div>

    <div class="input-group txtTo8">
        <input type="text" id="txtTo8" class="form-control-sm p-0 uc font10 text-center" maxlength="8"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtTo8">
    </div>

    <div class="input-group txtCpt8">
        <input type="text" id="txtCpt8" class="form-control-sm p-0 uc font10" maxlength="16" style="width: 68px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtCpt8">
    </div>

    <div class="input-group txtBal8">
        <input type="text" id="txtBal8" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 66px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtBal8">
    </div>

    <div class="input-group txtRazon8">
        <input type="text" id="txtRazon8" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 84px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtRazon8">
    </div>

    <div class="input-group txtPayDt8">
        <input type="text" id="txtPayDt8" class="form-control-sm px-1 py-0 font10" maxlength="10" style="width: 59px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtPayDt8">
    </div>

    <div class="input-group txtCkNo8">
        <input type="text" id="txtCkNo8" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 63px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtCkNo8">
    </div>

    <div class="input-group txtPayed8">
        <input type="text" id="txtPayed8" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 79px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtPayed8">
    </div>

    <div class="input-group txtComment8">
        <input type="text" id="txtComment8" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 147px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtComment8">
    </div>

    <div class="input-group txtFrom9">
        <input type="text" id="txtFrom9" class="form-control-sm p-0 uc font10 text-center" maxlength="9"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtFrom9">
    </div>

    <div class="input-group txtTo9">
        <input type="text" id="txtTo9" class="form-control-sm p-0 uc font10 text-center" maxlength="9"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtTo9">
    </div>

    <div class="input-group txtCpt9">
        <input type="text" id="txtCpt9" class="form-control-sm p-0 uc font10" maxlength="16" style="width: 68px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtCpt9">
    </div>

    <div class="input-group txtBal9">
        <input type="text" id="txtBal9" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 66px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtBal9">
    </div>

    <div class="input-group txtRazon9">
        <input type="text" id="txtRazon9" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 84px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtRazon9">
    </div>

    <div class="input-group txtPayDt9">
        <input type="text" id="txtPayDt9" class="form-control-sm px-1 py-0 font10" maxlength="10" style="width: 59px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtPayDt9">
    </div>

    <div class="input-group txtCkNo9">
        <input type="text" id="txtCkNo9" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 63px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtCkNo9">
    </div>

    <div class="input-group txtPayed9">
        <input type="text" id="txtPayed9" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 79px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtPayed9">
    </div>

    <div class="input-group txtComment9">
        <input type="text" id="txtComment9" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 147px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtComment9">
    </div>

    <div class="input-group txtFrom10">
        <input type="text" id="txtFrom10" class="form-control-sm p-0 uc font10 text-center" maxlength="9"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtFrom10">
    </div>

    <div class="input-group txtTo10">
        <input type="text" id="txtTo10" class="form-control-sm p-0 uc font10 text-center" maxlength="9"
            style="width: 46px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtTo10">
    </div>

    <div class="input-group txtCpt10">
        <input type="text" id="txtCpt10" class="form-control-sm p-0 uc font10" maxlength="16" style="width: 68px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtCpt10">
    </div>

    <div class="input-group txtBal10">
        <input type="text" id="txtBal10" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 66px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtBal10">
    </div>

    <div class="input-group txtRazon10">
        <input type="text" id="txtRazon10" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 84px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtRazon10">
    </div>

    <div class="input-group txtPayDt10">
        <input type="text" id="txtPayDt10" class="form-control-sm px-1 py-0 font10" maxlength="10" style="width: 59px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtPayDt10">
    </div>

    <div class="input-group txtCkNo10">
        <input type="text" id="txtCkNo10" class="form-control-sm px-1 py-0 font10 text-center" maxlength="10"
            style="width: 63px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtCkNo10">
    </div>

    <div class="input-group txtPayed10">
        <input type="text" id="txtPayed10" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 79px; text-align: right;" (change)="onChange_need2Save()" [(ngModel)]="e.txtPayed10">
    </div>

    <div class="input-group txtComment10">
        <input type="text" id="txtComment10" class="form-control-sm px-1 py-0 font10" maxlength="10"
            style="width: 147px;" (change)="onChange_need2Save()" [(ngModel)]="e.txtComment10">
    </div>

    <div class="input-group txtOtherReason1">
        <input type="text" id="txtOtherReason1" class="form-control-sm px-1 py-0 font10" style="width: 270px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtOtherReason1">
    </div>

    <div class="input-group txtOtherReason2">
        <input type="text" id="txtOtherReason2" class="form-control-sm px-1 py-0 font10" style="width: 270px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtOtherReason2">
    </div>

    <div class="input-group txtOtherReason3">
        <input type="text" id="txtOtherReason3" class="form-control-sm px-1 py-0 font10" style="width: 270px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtOtherReason3">
    </div>

    <div class="input-group txtOtherReason4">
        <input type="text" id="txtOtherReason4" class="form-control-sm px-1 py-0 font10" style="width: 270px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtOtherReason4">
    </div>

    <div class="input-group txtOtherReason5">
        <input type="text" id="txtOtherReason5" class="form-control-sm px-1 py-0 font10" style="width: 270px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtOtherReason5">
    </div>

    <div class="input-group txtOtherReason6">
        <input type="text" id="txtOtherReason6" class="form-control-sm px-1 py-0 font10" style="width: 270px;"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtOtherReason6">
    </div>

    <input type="text" id="txtOtherComment1" class="txtOtherComment1 px-1 py-0 font10" (change)="onChange_need2Save()"
        [(ngModel)]="e.txtOtherComment1">

    <input type="text" id="txtOtherComment2" class="txtOtherComment2 px-1 py-0 font10" (change)="onChange_need2Save()"
        [(ngModel)]="e.txtOtherComment2">

    <input type="text" id="txtOtherComment3" class="txtOtherComment3 px-1 py-0 font10" (change)="onChange_need2Save()"
        [(ngModel)]="e.txtOtherComment3">

    <input type="text" id="txtOtherComment4" class="txtOtherComment4 px-1 py-0 font10" (change)="onChange_need2Save()"
        [(ngModel)]="e.txtOtherComment4">

    <input type="text" id="txtOtherComment5" class="txtOtherComment5 px-1 py-0 font10" (change)="onChange_need2Save()"
        [(ngModel)]="e.txtOtherComment5">

    <input type="text" id="txtOtherComment6" class="txtOtherComment6 px-1 py-0 font10" (change)="onChange_need2Save()"
        [(ngModel)]="e.txtOtherComment6">

    <input type="text" id="txtOtherComment7" class="txtOtherComment7 px-1 py-0 font10" (change)="onChange_need2Save()"
        [(ngModel)]="e.txtOtherComment7">

    <input type="text" id="txtOtherComment8" class="txtOtherComment8 px-1 py-0 font10" (change)="onChange_need2Save()"
        [(ngModel)]="e.txtOtherComment8">

    <input type="text" id="txtOtherComment9a" class="txtOtherComment9a px-1 py-0 font10" (change)="onChange_need2Save()"
        [(ngModel)]="e.txtOtherComment9a">

    <input type="text" id="txtOtherComment9b" class="txtOtherComment9b px-1 py-0 font10" (change)="onChange_need2Save()"
        [(ngModel)]="e.txtOtherComment9b">

    <div class="txtOtherReasons">
        <textarea id="txtOtherReasons" class="font10" rows="3" cols="85" maxlength="500" (change)="onChange_need2Save()"
            [(ngModel)]="e.txtOtherReasons"></textarea>
    </div>

</div>