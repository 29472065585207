import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AppToastsService } from 'src/app/shared/app-toasts/app-toasts.service';
import { IReclaims, IReclaimsDet } from 'src/app/shared/interfaces/reclaims';
import { CkRegisterService } from '../ck-register.service';
import moment from 'moment';
import { DataMessageService } from 'src/app/shared/data-message.service';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-recl-xml-mmm-pmc-rev-b01162014',
    imports: [FormsModule],
    templateUrl: './recl-xml-mmm-pmc-rev-b01162014.component.html',
    styleUrls: ['./recl-xml-mmm-pmc-rev-b01162014.component.css']
})
export class ReclXmlMmmPmcRevB01162014Component implements OnDestroy {

  @Input() reclLineReasonEntered: any;
  @Input() reclaims: IReclaims[];
  @Input() reclaimsDet: IReclaimsDet[];
  @Input() idxSeld: number;
  @Input() reclFormImg64Seled: string;
  @Input() clrData: boolean = false;  // Set true after deleteing
  @Input() print: boolean = false;

  @Output() need2Save = new EventEmitter<string>();

  userLastNm: string;
  userFirstNm: string;
  currIndx: number = -1;
  e: any = {
    participant: '',
    location: '',
    type: '',
    opt3MPMC_1: '', // print only
    opt3MPMC_2: '', // print only
    opt3MPMC_3: '', // print only
    opt3MPMC_4: '', // print only
    opt3MPMC_5: '', // print only
    opt3MPMC_6: '', // print only
    opt3MPMC_7: '', // print only
    opt3MPMC_8: '', // print only
    txt3MPMC_ProvNm: '',
    txt3MPMC_TaxID: '',
    txt3MPMC_RendNPI: '',
    txt3MPMC_BillNPI: '',
    txt3MPMC_Tel: '',
    txt3MPMC_Email: '',

    txt3MPMC_Name1: '',
    txt3MPMC_ContrNo1: '',
    txt3MPMC_Dx1: '',
    txt3MPMC_ReclamNo1: '',
    txt3MPMC_Date1: '',
    txt3MPMC_PayDt1: '',
    txt3MPMC_Reason1: '',
    txt3MPMC_Comment1: '',

    txt3MPMC_Name2: '',
    txt3MPMC_ContrNo2: '',
    txt3MPMC_Dx2: '',
    txt3MPMC_ReclamNo2: '',
    txt3MPMC_Date2: '',
    txt3MPMC_PayDt2: '',
    txt3MPMC_Reason2: '',
    txt3MPMC_Comment2: '',

    txt3MPMC_Name3: '',
    txt3MPMC_ContrNo3: '',
    txt3MPMC_Dx3: '',
    txt3MPMC_ReclamNo3: '',
    txt3MPMC_Date3: '',
    txt3MPMC_PayDt3: '',
    txt3MPMC_Reason3: '',
    txt3MPMC_Comment3: '',

    txt3MPMC_Name4: '',
    txt3MPMC_ContrNo4: '',
    txt3MPMC_Dx4: '',
    txt3MPMC_ReclamNo4: '',
    txt3MPMC_Date4: '',
    txt3MPMC_PayDt4: '',
    txt3MPMC_Reason4: '',
    txt3MPMC_Comment4: '',

    txt3MPMC_Name5: '',
    txt3MPMC_ContrNo5: '',
    txt3MPMC_Dx5: '',
    txt3MPMC_ReclamNo5: '',
    txt3MPMC_Date5: '',
    txt3MPMC_PayDt5: '',
    txt3MPMC_Reason5: '',
    txt3MPMC_Comment5: '',

    txt3MPMC_Other_B: '',
    txt3MPMC_Other_C: '',
    txt3MPMC_Other: '',
    txt3MPMC_Comment: '',
    txt3MPMC_Date: '',
    txt3MPMC_SolicitorNm: '',

    reclCasNo: ''
  }

  constructor(private _ckRegisterService: CkRegisterService,
    private _appToastsService: AppToastsService,
    private _dataMessageService: DataMessageService,
  ) { }

  ngOnInit(): void {
    this._dataMessageService.currentUserLastNmStr.subscribe(userLastNmStr => this.userLastNmChange(userLastNmStr)); // Subscription 
    this._dataMessageService.currentUserFirstNmStr.subscribe(userFirstNmStr => this.userFirstNmChange(userFirstNmStr)); // Subscription 

    this.decodeXmlRecl();
    this.setReclaimDefaults();
    this.currIndx = 0;
    this.e.reclCasNo = this.reclaims[this.idxSeld].reclCasNo;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.createColReclaimXmlContent_developmentHelperRtn();  // <-- Use to get reclXml col contents value for this form
    if (changes.idxSeld) {
      this.e = {};  // Clear form data
      this.decodeXmlRecl();
      this.setReclaimDefaults();
      this.currIndx = 0;
    }
    // Check if @Input() reclLineReasonEntered & others have changed
    if (changes.reclLineReasonEntered && this.reclLineReasonEntered) {
      console.log(this.reclLineReasonEntered);
      if (this.reclLineReasonEntered.reason) {
        if (this.e.txt3MPMC_Comment) {
          this.e.txt3MPMC_Comment += ', ';
        }
        this.e.txt3MPMC_Comment += (this.reclLineReasonEntered.cpt
          + ' ' + this.reclLineReasonEntered.mods
          + ' ' + this.reclLineReasonEntered.from
          + ' ' + this.reclLineReasonEntered.reason.trim());
      }

      switch (this.currIndx) {
        case -1:
          this.currIndx = 0;
          break;
        case 0:
          this.e.txt3MPMC_Name1 = this.reclLineReasonEntered.name;
          this.e.txt3MPMC_ContrNo1 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_Dx1 = this.reclLineReasonEntered.dx;
          this.e.txt3MPMC_ReclamNo1 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Date1 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_PayDt1 = this.reclLineReasonEntered.payDt;
          this.e.txt3MPMC_Comment1 = (this.reclLineReasonEntered.cpt + ' ' + this.reclLineReasonEntered.mods).trim() + ' ' + this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 1:
          this.e.txt3MPMC_Name2 = this.reclLineReasonEntered.name;
          this.e.txt3MPMC_ContrNo2 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_Dx2 = this.reclLineReasonEntered.dx;
          this.e.txt3MPMC_ReclamNo2 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Date2 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_PayDt2 = this.reclLineReasonEntered.payDt;
          this.e.txt3MPMC_Comment2 = (this.reclLineReasonEntered.cpt + ' ' + this.reclLineReasonEntered.mods).trim() + ' ' + this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 2:
          this.e.txt3MPMC_Name3 = this.reclLineReasonEntered.name;
          this.e.txt3MPMC_ContrNo3 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_Dx3 = this.reclLineReasonEntered.dx;
          this.e.txt3MPMC_ReclamNo3 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Date3 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_PayDt3 = this.reclLineReasonEntered.payDt;
          this.e.txt3MPMC_Comment3 = (this.reclLineReasonEntered.cpt + ' ' + this.reclLineReasonEntered.mods).trim() + ' ' + this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 3:
          this.e.txt3MPMC_Name4 = this.reclLineReasonEntered.name;
          this.e.txt3MPMC_ContrNo4 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_Dx4 = this.reclLineReasonEntered.dx;
          this.e.txt3MPMC_ReclamNo4 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Date4 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_PayDt4 = this.reclLineReasonEntered.payDt;
          this.e.txt3MPMC_Comment4 = (this.reclLineReasonEntered.cpt + ' ' + this.reclLineReasonEntered.mods).trim() + ' ' + this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 4:
          this.e.txt3MPMC_Name5 = this.reclLineReasonEntered.name;
          this.e.txt3MPMC_ContrNo5 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_Dx5 = this.reclLineReasonEntered.dx;
          this.e.txt3MPMC_ReclamNo5 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Date5 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_PayDt5 = this.reclLineReasonEntered.payDt;
          this.e.txt3MPMC_Comment5 = (this.reclLineReasonEntered.cpt + ' ' + this.reclLineReasonEntered.mods).trim() + ' ' + this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        default:
      }
    }
    if (changes.clrData?.currentValue) {
      this.clearData();
    }
    if (changes.print?.currentValue) {
      this.printReclaim();
    }
    if (changes.reclaimsDet?.currentValue) {
      let d: number = this.reclaimsDet.findIndex(det => det.reclDetICN === this.reclaims[this.idxSeld].reclICN);
      if (d < 0) {
        d = 0;
      }
      this.e.txt3MPMC_Name1 = this.reclaims[this.idxSeld].reclPatNm;
      this.e.txt3MPMC_ContrNo1 = +this.reclaims[this.idxSeld].reclPS == 1 ? this.reclaims[0].reclContr1 : this.reclaims[0].reclContr2;
      this.e.txt3MPMC_Dx1 = this.reclaimsDet ? this.txlateDxPtrToDx(this.reclaimsDet[d].reclDetDxPtr) : '';
      this.e.txt3MPMC_ReclamNo1 = this.reclaimsDet[0].reclDetICN ? this.reclaimsDet[d].reclDetICN : this.reclaims[this.idxSeld].reclICN;
      this.e.txt3MPMC_Date1 = this.reclaimsDet ? this.reclaimsDet[d].reclDetFromDt : '';
      this.e.txt3MPMC_PayDt1 = this.reclaimsDet ? this.reclaimsDet[d].reclDetPayDt : '';
    }
  }

  userLastNmChange(userLastNmStr) {
    this.userLastNm = userLastNmStr;
  }

  userFirstNmChange(userFirstNmStr) {
    this.userFirstNm = userFirstNmStr;
  }

  formatCurrency(amount, symb = null, decimals = 2, decimalSeparator = ".", thousandsSeparator = ",") {
    const formattedAmount = amount.toFixed(decimals);
    const parts = formattedAmount.split(".");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
    const decimalPart = parts[1];
    return `${symb ? "$" : ""}${integerPart}${decimalSeparator}${decimalPart}`;
  }

  setReclaimDefaults(): void {
    const r: number = this.idxSeld;
    const d: number = this.reclaimsDet ? this.reclaimsDet.findIndex(det => det.reclDetICN === this.reclaims[r].reclICN) : -1;

    this.e.txt3MPMC_ProvNm = this.e.txt3MPMC_ProvNm ? this.e.txt3MPMC_ProvNm : this.reclaims[r].reclProv;
    this.e.txt3MPMC_BillNPI = this.e.txt3MPMC_BillNPI ? this.e.txt3MPMC_BillNPI : this.reclaims[r].reclNPI;
    this.e.txt3MPMC_RendNPI = this.e.txt3MPMC_RendNPI ? this.e.txt3MPMC_RendNPI : this.reclaimsDet && d > -1 ? this.reclaimsDet[d].reclDetProdNpi : '';
    this.e.txt3MPMC_TaxID = this.e.txt3MPMC_TaxID ? this.e.txt3MPMC_TaxID : this.reclaims[r].reclTaxId.replace(/\D*/g, '');
    this.e.txt3MPMC_Tel = this.e.txt3MPMC_Tel ? this.e.txt3MPMC_Tel : this.reclaims[r].reclProvTel;
    this.e.txt3MPMC_Email = this.e.txt3MPMC_Email ? this.e.txt3MPMC_Email : this.reclaims[r].reclProvEmail;
    this.e.txt3MPMC_Date = this.e.txt3MPMC_Date ? this.e.txt3MPMC_Date : moment(new Date()).format('MM/DD/YY');
    this.e.txt3MPMC_Name1 = this.e.txt3MPMC_Name1 ? this.e.txt3MPMC_Name1 : this.reclaims[r].reclPatNm;
    this.e.txt3MPMC_ContrNo1 = this.e.txt3MPMC_ContrNo1 ? this.e.txt3MPMC_ContrNo1 : +this.reclaims[r].reclPS == 1 ? this.reclaims[r].reclContr1 : this.reclaims[r].reclContr2;
    this.e.txt3MPMC_Dx1 = this.e.txt3MPMC_Dx1 ? this.e.txt3MPMC_Dx1 : this.reclaimsDet ? this.txlateDxPtrToDx(this.reclaimsDet[d].reclDetDxPtr) : '';
    this.e.txt3MPMC_ReclamNo1 = this.e.txt3MPMC_ReclamNo1 ? this.e.txt3MPMC_ReclamNo1 : this.reclaims[r].reclICN;
    this.e.txt3MPMC_Date1 = this.e.txt3MPMC_Date1 ? this.e.txt3MPMC_Date1 : this.reclaimsDet && d > -1 ? this.reclaimsDet[d].reclDetFromDt : '';
    this.e.txt3MPMC_PayDt1 = this.e.txt3MPMC_PayDt1 ? this.e.txt3MPMC_PayDt1 : this.reclaimsDet && d > -1 ? this.reclaimsDet[d].reclDetPayDt : '';
    this.e.txt3MPMC_SolicitorNm = this.e.txt3MPMC_SolicitorNm ? this.e.txt3MPMC_SolicitorNm : this.userLastNm + ', ' + this.userFirstNm;
  }

  decodeXmlRecl(): void {
    const r: number = this.idxSeld;

    if (r >= 0 && this.reclaims) {
      let parser = new DOMParser();
      let xmlDoc;
      if (this.reclaims[r].reclXml) {
        setTimeout(() => {
          this.reclaims[r].reclXml = this.fixReclXml(this.reclaims[r].reclXml);
        }, 0);
        xmlDoc = parser.parseFromString(this.reclaims[r].reclXml, "text/xml");
      } else {
        return;
      }

      for (let i = 0; i < xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes.length; i++) {
        let fullNod = xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes[i];
        let nod = xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes[i].nodeName;

        if (nod.match(/txt\w+/g) || nod.match(/chk\w+/g) || nod.match(/opt\w+/g)) {
          let val = fullNod.textContent;

          switch (nod) {
            case 'opt3MPMC_1':
              if (+val === 1 || val === 'True') {
                this.e.participant = 'true';
              }
              break;
            case 'opt3MPMC_2':
              if (+val === 1 || val === 'True') {
                this.e.participant = 'false';
              }
              break;
            case 'opt3MPMC_3':
              if (+val === 1 || val === 'True') {
                this.e.location = 'md'
              }
              break;
            case 'opt3MPMC_4':
              if (+val === 1 || val === 'True') {
                this.e.location = 'fac'
              }
              break;
            case 'opt3MPMC_5':
              if (+val === 1 || val === 'True') {
                this.e.location = 'other'
              }
              break;
            case 'opt3MPMC_6':
              if (+val === 1 || val === 'True') {
                this.e.type = 'aha'
              }
              break;
            case 'opt3MPMC_7':
              if (+val === 1 || val === 'True') {
                this.e.type = 'ca'
              }
              break;
            case 'opt3MPMC_8':
              if (+val === 1 || val === 'True') {
                this.e.type = 'other'
              }
              break;
            default:
              if (nod == 'txt3MPMC_Name') { // Correcting a change in a ReclXml field name causing unintended problem
                this.e.txt3MPMC_SolicitorNm = val.replace(/^%/, '');
                break;
              }
              if (nod.match(/txt\w+/g)) {
                this.e[nod] = val.replace(/^%/, '');
              }
          }
        }
      }
    }
  }

  ngOnDestroy() {
    this.currIndx = 0;
    this.e = undefined;
  }

  clearData(): void { // Clears all elements with default data.
    Object.keys(this.e).forEach(key => this.e[key] = '');
    this.setReclaimDefaults();
  }

  onChange_need2Save(): void {
    this.need2Save.emit('');
  }

  onClick(event): void {
    if (event && event.target.id) {
      const i = event.target.id.match(/\d$/g);
      if (i) {
        this.currIndx = +i[0] - 1;
      }
    }
  }

  reasonLnks(val) {
    switch (this.currIndx - 1) {
      case -2:
        this.currIndx = 0;
      case -1:
      case 0:
        if (this.e.txt3MPMC_Reason1 >= '' && !this.e.txt3MPMC_Reason1.toString().includes(val)) {
          if (this.e.txt3MPMC_Reason1 && !this.e.txt3MPMC_Reason1.toString().includes(val)) {
            this.e.txt3MPMC_Reason1 += ',' + val;
          } else {
            this.e.txt3MPMC_Reason1 = val;
          }
          let el: HTMLInputElement = <HTMLInputElement>document.getElementById('txt3MPMC_Reason1');
          el.value = this.e.txt3MPMC_Reason1;
          this.onChange_need2Save();  // Make reclaim saveable
        }
        break;
      case 1:
        if (this.e.txt3MPMC_Reason2 >= '' && !this.e.txt3MPMC_Reason2.toString().includes(val)) {
          if (this.e.txt3MPMC_Reason2) {
            this.e.txt3MPMC_Reason2 += ',' + val;
          } else {
            this.e.txt3MPMC_Reason2 = val;
          }
          let el: HTMLInputElement = <HTMLInputElement>document.getElementById('txt3MPMC_Reason2');
          el.value = this.e.txt3MPMC_Reason2;
          this.onChange_need2Save();  // Make reclaim saveable
        }
        break;
      case 2:
        if (this.e.txt3MPMC_Reason3 >= '' && !this.e.txt3MPMC_Reason3.toString().includes(val)) {
          if (this.e.txt3MPMC_Reason3) {
            this.e.txt3MPMC_Reason3 += ',' + val;
          } else {
            this.e.txt3MPMC_Reason3 = val;
          }
          let el: HTMLInputElement = <HTMLInputElement>document.getElementById('txt3MPMC_Reason3');
          el.value = this.e.txt3MPMC_Reason3;
          this.onChange_need2Save();  // Make reclaim saveable
        }
        break;
      case 3:
        if (this.e.txt3MPMC_Reason4 >= '' && !this.e.txt3MPMC_Reason4.toString().includes(val)) {
          if (this.e.txt3MPMC_Reason4) {
            this.e.txt3MPMC_Reason4 += ',' + val;
          } else {
            this.e.txt3MPMC_Reason4 = val;
          }
          let el: HTMLInputElement = <HTMLInputElement>document.getElementById('txt3MPMC_Reason4');
          el.value = this.e.txt3MPMC_Reason4;
          this.onChange_need2Save();  // Make reclaim saveable
        }
        break;
      case 4:
        if (this.e.txt3MPMC_Reason5 >= '' && !this.e.txt3MPMC_Reason5.toString().includes(val)) {
          if (this.e.txt3MPMC_Reason5) {
            this.e.txt3MPMC_Reason5 += ',' + val;
          } else {
            this.e.txt3MPMC_Reason5 = val;
          }
          let el: HTMLInputElement = <HTMLInputElement>document.getElementById('txt3MPMC_Reason5');
          el.value = this.e.txt3MPMC_Reason5;
          this.onChange_need2Save();  // Make reclaim saveable
        }
        break;
    }
  }

  fixReclXml(reclXml): string { // Caused by recent changes to ReclXml spec
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(reclXml, "text/xml");

    xmlDoc = this.addNewXmlElement('txt3MPMC_Name1', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Name2', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Name3', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Name4', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Name5', xmlDoc);

    xmlDoc = this.addNewXmlElement('txt3MPMC_ContrNo1', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_ContrNo2', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_ContrNo3', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_ContrNo4', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_ContrNo5', xmlDoc);

    xmlDoc = this.addNewXmlElement('txt3MPMC_Dx1', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Dx2', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Dx3', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Dx4', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Dx5', xmlDoc);

    xmlDoc = this.addNewXmlElement('txt3MPMC_ReclamNo1', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_ReclamNo2', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_ReclamNo3', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_ReclamNo4', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_ReclamNo5', xmlDoc);

    xmlDoc = this.addNewXmlElement('txt3MPMC_Date1', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Date2', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Date3', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Date4', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Date5', xmlDoc);

    xmlDoc = this.addNewXmlElement('txt3MPMC_PayDt1', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_PayDt2', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_PayDt3', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_PayDt4', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_PayDt5', xmlDoc);

    xmlDoc = this.addNewXmlElement('txt3MPMC_Reason1', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Reason2', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Reason3', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Reason4', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Reason5', xmlDoc);

    xmlDoc = this.addNewXmlElement('txt3MPMC_Comment1', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Comment2', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Comment3', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Comment4', xmlDoc);
    xmlDoc = this.addNewXmlElement('txt3MPMC_Comment5', xmlDoc);

    let serializer = new XMLSerializer();
    return serializer.serializeToString(xmlDoc);
  }

  addNewXmlElement(newElNm: string, xmlDoc: any): any {
    if (!xmlDoc.getElementsByTagName(newElNm)[0]) {
      let newElement = xmlDoc.createElement(newElNm);
      let newNode = xmlDoc.getElementsByTagName("ReclaimFormFields")[0];
      newNode.appendChild(newElement);
    }
    return xmlDoc;
  }

  printReclaim(): void {
    this.e.participant === 'true' ? this.e.opt3MPMC_1 = 'X' : this.e.opt3MPMC_1 = '';
    this.e.participant === 'false' ? this.e.opt3MPMC_2 = 'X' : this.e.opt3MPMC_2 = '';
    this.e.location === 'md' ? this.e.opt3MPMC_3 = 'X' : this.e.opt3MPMC_3 = '';
    this.e.location === 'fac' ? this.e.opt3MPMC_4 = 'X' : this.e.opt3MPMC_4 = '';
    this.e.location === 'other' ? this.e.opt3MPMC_5 = 'X' : this.e.opt3MPMC_5 = '';
    this.e.type === 'aha' ? this.e.opt3MPMC_6 = 'X' : this.e.opt3MPMC_6 = '';
    this.e.type === 'ca' ? this.e.opt3MPMC_7 = 'X' : this.e.opt3MPMC_7 = '';
    this.e.type === 'other' ? this.e.opt3MPMC_8 = 'X' : this.e.opt3MPMC_8 = '';

    let data: any = {
      "e": this.e,
    };
    let bdy = {
      "template":
      {
        "name": "/reclaimForms/mmm_pmc_anejo_B_rev_1-16-2014/mmm_pmc_anejo_B_rev_1-16-2014-html"
      }, "data": data,
      "options": { "reports": { "save": true } }
    };

    this._ckRegisterService.postRenderJsRpt(bdy).subscribe({
      next: blob => { this.backFromPrintRpt(blob) },
      error: (error: any) => { this.backFromServerError(error) }
    });
  }

  txlateDxPtrToDx(ptrs: string): string {
    if (ptrs) {
      const p = ptrs.match(/^[1-9,a,c,d]/)[0]; // Isolate 1st pointer
      if (p) {
        const pi = ptrs.substring(0, 1);
        switch (pi) {
          case '1':
            return this.reclaims[this.idxSeld].reclDx1;
          case '2':
            return this.reclaims[this.idxSeld].reclDx2;
          case '3':
            return this.reclaims[this.idxSeld].reclDx3;
          case '4':
            return this.reclaims[this.idxSeld].reclDx4;
          case '5':
            return this.reclaims[this.idxSeld].reclDx5;
          case '6':
            return this.reclaims[this.idxSeld].reclDx6;
          case '7':
            return this.reclaims[this.idxSeld].reclDx7;
          case '8':
            return this.reclaims[this.idxSeld].reclDx8;
          case '9':
            return this.reclaims[this.idxSeld].reclDx9;
          case 'a':
            return this.reclaims[this.idxSeld].reclDxa;
          case 'b':
            return this.reclaims[this.idxSeld].reclDxb;
          case 'c':
            return this.reclaims[this.idxSeld].reclDxc;
          default:
            return this.reclaims[this.idxSeld].reclDx1 + ' ' + this.reclaims[this.idxSeld].reclDx2 + ' ' + this.reclaims[this.idxSeld].reclDx3 + ' ' + this.reclaims[this.idxSeld].reclDx4;
        }
      }
    }
    return this.reclaims[this.idxSeld].reclDx1 + ' ' + this.reclaims[this.idxSeld].reclDx2 + ' ' + this.reclaims[this.idxSeld].reclDx3 + ' ' + this.reclaims[this.idxSeld].reclDx4;
  }

  backFromPrintRpt(blob: Blob): void {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      let iframe = "<iframe width='100%' height='100%' src='" + reader.result.toString() + "'></iframe>"
      let win = window.open();
      win.document.open();
      win.document.write(iframe);
      win.document.close();
    };
  }

  backFromServerError(error): void {
    this._appToastsService.updateDeadCenter(false); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
    this._appToastsService.show(error.message, { header: 'Error preparing report * Error preparando reporte', autohide: false, error: true });
  }

  createColReclaimXmlContent_developmentHelperRtn(): void {
    const reclFormDiv = document.getElementById('ReclaimFormFields');
    if (reclFormDiv) {
      let reclXml: string = '<ReclaimFormFields>';  // This id="ReclaimFormFields" must be set temporarily in top <div> for this to work
      const txts = reclFormDiv.querySelectorAll('input');
      txts.forEach(e => {
        reclXml += '<' + e.id + '></' + e.id + '>';
      });
      reclXml += '</ReclaimFormFields>';
      console.log('*** reclXml ***');
      console.log(reclXml);
    }
  }
}
