import { Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DataMessageService } from '../shared/data-message.service';
import { RecordService } from '../record/record.service';
import { AppToastsService } from '../shared/app-toasts/app-toasts.service';
import moment from 'moment';
import { ILocalProviders } from '../shared/interfaces/localProviders';
import { ILocalInsurances } from '../shared/interfaces/localInsurances';
import { ILocales } from '../shared/interfaces/locales';
import { ILocalFacilities } from '../shared/interfaces/localFacilities';
import { WebsocketService } from '../shared/websocket.service';
import { Subscription } from 'rxjs';
import { IRptAging } from '../shared/interfaces/rptAging';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'app-report-dlg',
  imports: [FormsModule],
  standalone: false,
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent {
  @ViewChild(MatMenu) menu: MatMenu;

  sn: string;
  engLang: boolean;
  sioSubscrpt: Subscription;
  waiting4Success: boolean = false;

  seldRpt: number = 0;
  seldFromDt: Date;
  seldToDt: Date;
  seldProvs: string[] = [];
  seldInses1: string[] = [];
  seldInses2: string[] = [];
  seldFacs: string[] = [];
  seldLocales: string[] = [];
  rptFromDt: string;
  rptToDt: string;

  options: any[] = [];
  rptOptions: any[] = [];

  provs: ILocalProviders[] = [...this._recordService.localProviders];
  inses: ILocalInsurances[] = [...this._recordService.localInsurances];
  facs: ILocalFacilities[] = [...this._recordService.localFacilities];
  locales: ILocales[] = [...this._recordService.locales];

  rptAgingData: IRptAging;
  rptAgingLoaded: boolean;

  constructor(
    private _dataMessageService: DataMessageService,
    private _recordService: RecordService,
    private _appToastsService: AppToastsService,
    private _websocketService: WebsocketService
  ) {
    this.initRptData();
  }

  ngOnInit() {
    // this._dataMessageService.currentSnStr.subscribe(snStr => this.snChange(snStr)); // Subscription looking for changes in sn
    this._dataMessageService.currentEngLangStr.subscribe(engLangStr => this.engLangChange(engLangStr)); // Subscription looking for changes in engLang

    this.options = [
      { 'value': 'ignorPayAftToDt', 'desc': (this.engLang ? 'Ignore payments after TO date' : 'Ignorar pagos después fecha HASTA') },
      { 'value': 'woAnyPay', 'desc': (this.engLang ? 'Only show claims with no payment' : 'Mostrar solo casos sin pago') },
      { 'value': 'deferSecndry', 'desc': (this.engLang ? 'Do not show sec. claim until pri. payed' : 'No mostrar deuda sec. hasta pago del pri.') },
      { 'value': 'officeOnly', 'desc': (this.engLang ? 'Office claims only' : 'Solo casos oficina') },
      { 'value': 'nonOfficeOnly', 'desc': (this.engLang ? 'Exclude office claims' : 'Excluir casos oficina') },
      { 'value': 'exclPvdo', 'desc': (this.engLang ? 'Exclude cash' : 'Excluir cash') }
    ];

    this.sioSubscrpt = this._websocketService.getMessages().subscribe((dataSet) => { // Sets listenning events
      console.log('%c' + 'dataSet ReportsComponent', 'color: green; background: yellow; font-size: 12px');
      console.dir(dataSet);

      if (dataSet?.error) {
        // TODO/: Error toast
      } else {
        if (dataSet?.length > 0) {

          if (dataSet[0].sqlProcNm?.startsWith('spRptAging2')) {
            const len = dataSet[0].sqlProcNm.length;
            const tabl = dataSet[0].sqlProcNm.substring(len - 3, len);
            switch (tabl) {
              case 'ins':
                this.rptAgingData.ins = dataSet;
                break;
              case 'clm':
                this.rptAgingData.clm = dataSet;
                break;
              case 'det':
                this.rptAgingData.det = dataSet;
                break;
              case 'pad':
                this.rptAgingData.pad = dataSet;
                break;
              case 'end':
                let tInLNm: string = this.engLang ? 'Totals' : 'Totales';
                let tag30: number = 0;
                let tag60: number = 0;
                let tag90: number = 0;
                let tag120: number = 0;
                let tag150: number = 0;
                let tag6m: number = 0;
                let tag1y: number = 0;
                let tag2y: number = 0;
                let tag3y: number = 0;
                let tbalance: number = 0;
                let tc30: number = 0;
                let tc60: number = 0;
                let tc90: number = 0;
                let tc120: number = 0;
                let tc150: number = 0;
                let tc6m: number = 0;
                let tc1y: number = 0;
                let tc2y: number = 0;
                let tc3y: number = 0;
                let ttCount: number = 0;

                this.rptAgingData.ins.forEach(obj => {
                  tag30 += +obj.ag30.replace(',', '');
                  tag60 += +obj.ag60.replace(',', '');
                  tag90 += +obj.ag90.replace(',', '');
                  tag120 += +obj.ag120.replace(',', '');
                  tag150 += +obj.ag150.replace(',', '');
                  tag6m += +obj.ag6m.replace(',', '');
                  tag1y += +obj.ag1y.replace(',', '');
                  tag2y += +obj.ag2y.replace(',', '');
                  tag3y += +obj.ag3y.replace(',', '');
                  tbalance += +obj.balance;
                  tc30 += +obj.c30.replace(',', '');
                  tc60 += +obj.c60.replace(',', '');
                  tc90 += +obj.c90.replace(',', '');
                  tc120 += +obj.c120.replace(',', '');
                  tc150 += +obj.c150.replace(',', '');
                  tc6m += +obj.c6m.replace(',', '');
                  tc1y += +obj.c1y.replace(',', '');
                  tc2y += +obj.c2y.replace(',', '');
                  tc3y += +obj.c3y.replace(',', '');
                  ttCount += +obj.tCount.replace(',', '');
                });
                this.rptAgingData.ins.push({
                  i2cKey: '',
                  InLNm: tInLNm,
                  InID: '',
                  reclTyp: '',
                  oInLNm: '',
                  ag30: tag30.toString(),
                  ag60: tag60.toString(),
                  ag90: tag90.toString(),
                  ag120: tag120.toString(),
                  ag150: tag150.toString(),
                  ag6m: tag6m.toString(),
                  ag1y: tag1y.toString(),
                  ag2y: tag2y.toString(),
                  ag3y: tag3y.toString(),
                  balance: tbalance,
                  c30: tc30.toString(),
                  c60: tc60.toString(),
                  c90: tc90.toString(),
                  c120: tc120.toString(),
                  c150: tc150.toString(),
                  c6m: tc6m.toString(),
                  c1y: tc1y.toString(),
                  c2y: tc2y.toString(),
                  c3y: tc3y.toString(),
                  tCount: ttCount.toString(),
                  expandCol: '',
                  printing: '',
                  sqlProcNm: ''
                });
                this.waiting4Success = false;
                this.rptAgingLoaded = true;

                console.log(this.rptAgingData);
                break;
              default:
            }
          }
        }
      }
    });

    this.provs.unshift({
      pKey: '0',
      alias: '(All/Todos)',
      provName: '',
      provIsCo: '0',
      npi: '',
      provTaxonomy: '',
      ssn: '',
      ein: '',
      taxId: '',
      provAcode: '',
      provTel: '',
      provCel: '',
      poAd1: '',
      poAd2: '',
      poCity: '',
      poSt: '',
      poZip: '',
      provAd1: '',
      provAd2: '',
      provCity: '',
      provSt: '',
      provZip: '',
      provFtpHost: '',
      provFtpUser: '',
      provFtpPw: '',
      lab: '0',
      email: ''
    });

    this.inses.unshift({
      pKey: '-1',
      alias: '(None/Ninguno)',
      name: '',
      payerId: '',
      payerId835: '',
      add1: '',
      add2: '',
      city: '',
      st: '',
      zip: '',
      s1type: '',
      s33bTaxonomy: '',
      s30BalDue: '',
      x837fmtNo: '0',
      x837clmFilInd: '',
      x837usePrvAsRenderAt2310B: '0',
      sqlProcNm: ''
    });
    this.inses.find(ins => +ins.pKey === 0).alias = '(All/Todos)';

    this.facs.unshift({
      pKey: '0',
      alias: '(All/Todos)',
      facNpi: '',
      facName: '',
      facAd1: '',
      facAd2: '',
      facCity: '',
      facSt: '',
      facZip: ''
    });

    this.locales.unshift({
      locName: '(All/Todas)',
      locNo: '0'
    });

    this.seldFromDt = new Date();
    this.seldToDt = new Date();
    this.seldProvs = ['0'];
    this.seldInses1 = ['0'];
    this.seldInses2 = ['0'];
    this.seldFacs = ['0'];
    this.seldLocales = ['0'];
  }

  initRptData(): void {
    this.rptAgingData = {
      ins: [],
      clm: [],
      det: [],
      pad: []
    };
  }

  snChange(snStr: string) { // for when sn changes
    this.sn = snStr;
  }
  engLangChange(engLangStr: string) { // for when language changes
    engLangStr === 'true' ? this.engLang = true : this.engLang = false;
  }

  trackByPkey(index: number, item: any): string | number | undefined {
    return item.pKey; // Assuming pKey is a unique identifier
  }

  optionSelected(opt: any): void {
    let arr = this.rptOptions.slice();
    if (!arr.find(itm => itm.value === opt.value)) {
      arr.push(opt);
      this.rptOptions = arr;

      if (this.rptAgingData.ins.length) { // Redisplay only if there is data
        this.fetchRpt();
      }
    }
  }

  removeOption(event, opt) {
    let arr = this.rptOptions.slice();
    const i = arr.findIndex(itm => itm.value === opt.value);
    if (i >= 0) {
      arr.splice(i, 1);
      this.rptOptions = arr;

      if (this.rptAgingData.ins.length) { // Redisplay only if there is data
        this.fetchRpt();
      }
    }
  }

  onChange_params(event): void {
    this.initRptData(); // Clear any shown data if report params changed
    // console.log('change', event);
  }

  seldProvsHead(): string {
    let nms: string = '';
    for (let p = 0; p < this.seldProvs.length; p++) {
      if (+this.seldProvs[p] === 0) {  // If All selected don't bother to iterate selected array
        nms = this.provs.find(p => +p.pKey == 0).alias;
        break;
      }

      if (nms) {
        nms += ',';
      }
      nms += this.seldProvs[p] + ' ' + (this.provs.find(pr => pr.pKey === this.seldProvs[p])).alias.substring(0, 5);
    };
    return nms;
  }

  seldFacsHead(): string {
    let nms: string = '';
    for (let f = 0; f < this.seldFacs.length; f++) {
      if (+this.seldFacs[f] === 0) {  // If All selected don't bother to iterate selected array
        nms = this.facs.find(p => +p.pKey == 0).alias;
        break;
      }

      if (nms) {
        nms += ',';
      }
      nms += this.seldFacs[f] + ' ' + (this.facs.find(pr => pr.pKey === this.seldFacs[f])).alias.substring(0, 5);
    };
    return nms;
  }

  seldLocalesHead(): string {
    let nms: string = '';
    for (let l = 0; l < this.seldLocales.length; l++) {
      if (+this.seldLocales[l] === 0) {  // If All selected don't bother to iterate selected array
        nms = this.locales.find(lc => +lc.locNo == 0).locName;
        break;
      }

      if (nms) {
        nms += ',';
      }
      nms += (this.locales.find(lc => +lc.locNo === +this.seldLocales[l])).locName.substring(0, 10);
    };
    return nms;
  }

  seldInsesHead(insArr: any[]): string {
    let nms: string = '';
    for (let i = 0; i < insArr.length; i++) {
      if (+insArr[i] === 0) {  // If All selected don't bother to iterate selected array
        nms = this.inses.find(seg => +seg.pKey == 0).alias;
        break;
      }

      if (nms) {
        nms += ',';
      }
      nms += insArr[i] + ' ' + (this.inses.find(seg => seg.pKey === insArr[i])).alias.substring(0, 5);
    };
    return nms;
  }

  fmtCurrency(amnt: number, symb: string = '') {
    const parts = amnt.toFixed(2).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return symb + parts.join('.');
  }

  fetchRpt() {
    this.rptAgingLoaded = false;
    this.initRptData();

    let errMsgEn: string[] = [];
    let errMsgSp: string[] = [];

    let frMom = moment(this.seldFromDt);
    let toMom = moment(this.seldToDt);

    if (!this.seldRpt) {
      errMsgEn.push('Select the desired report. ');
      errMsgSp.push('Seleccione el reporte deseado. ');
    }

    if (!frMom.isValid || !toMom.isValid || frMom.isAfter(toMom)) {
      errMsgEn.push('Verify dates from/to. "From" date cannot be greater than "To" date.');
      errMsgSp.push('Coteje fechas desde/hasta. Fecha "Hasta" no puede ser mayor que fecha "Desde".');
    }

    if (errMsgEn.length) {
      let errMsg;
      if (this.engLang) {
        errMsg = errMsgEn;
      } else {
        errMsg = errMsgSp;
      }

      errMsg.forEach(msg => {
        this._appToastsService.updateDeadCenter(false);
        this._appToastsService.show(msg, { header: (this.engLang ? 'Check the following:' : 'Coteje lo siguiente:'), autohide: true, warning: true });
      });
    } else {
      let qry: string;
      let fromDt = (moment(this.seldFromDt)).format('YYYYMMDD');
      let toDt = (moment(this.seldToDt)).format('YYYYMMDD');

      this.rptFromDt = frMom.format('MM/DD/YY');
      this.rptToDt = toMom.format('MM/DD/YY');

      if (this.seldProvs.find(itm => itm === '0')) {
        this.seldProvs = ['0'];
      }

      if (this.seldInses1.find(itm => itm === '0')) {
        this.seldInses1 = ['0'];
      } else if (this.seldInses1.find(itm => itm === '-1')) {
        this.seldInses1 = ['-1'];
      }

      if (this.seldInses2.find(itm => itm === '0')) {
        this.seldInses2 = ['0'];
      } else if (this.seldInses2.find(itm => itm === '-1')) {
        this.seldInses2 = ['-1'];
      }

      if (this.seldFacs.find(itm => itm === '0')) {
        this.seldFacs = ['0'];
      }

      if (this.seldLocales.find(itm => itm === '0')) {
        this.seldLocales = ['0'];
      }

      switch (this.seldRpt) {
        case 4:
          qry = "EXEC dbo.spRptAging2 @FromDt = '" + fromDt + "', @ToDt = '" + toDt + "'";

          if (this.seldLocales.length) {
            qry += ", @OfficeNo = '" + this.seldLocales.join(',') + "'";
          }

          if (this.seldProvs.length) {
            qry += ", @ProvID = '" + this.seldProvs.join(',') + "'";
          }

          if (this.seldFacs.length) {
            qry += ", @FacID = '" + this.seldFacs.join(',') + "'";
          }

          if (this.seldInses1.length) {
            qry += ", @Ins1ID = '" + this.seldInses1.join(',') + "'";
          }

          if (this.seldInses2.length) {
            qry += ", @Ins2ID = '" + this.seldInses2.join(',') + "'";
          }

          if (this.rptOptions.find(o => o.value === 'ignorPayAftToDt')) {
            qry += ", @IgnorPayAftToDt = 1"
          }

          if (this.rptOptions.find(o => o.value === 'woAnyPay')) {
            qry += ", @WoAnyPay = 1"
          }

          if (this.rptOptions.find(o => o.value === 'deferSecndry')) {
            qry += ", @DeferSecndry = 1"
          }

          if (this.rptOptions.find(o => o.value === 'exclPvdo')) {
            qry += ", @InclPvdo = 0"
          } else {
            qry += ", @InclPvdo = 1"
          }

          if (this.rptOptions.find(o => o.value === 'officeOnly') && this.rptOptions.find(o => o.value === 'nonOfficeOnly')) {
            qry += ", @OHA = 'X'" // Yeilds nothing
          } else if (this.rptOptions.find(o => o.value === 'officeOnly')) {
            qry += ", @OHA = 'O'"
          } else if (this.rptOptions.find(o => o.value === 'nonOfficeOnly')) {
            qry += ", @OHA = 'H'"
          }

          console.log(qry);

          window.scroll(0, 0);
          console.log('%c' + 'query ' + qry, 'color: green; background: yellow; font-size: 12px');
          this.waiting4Success = true;
          this._websocketService.sendChat('query', this.sn, qry);

          break;
      }
    }
  }

}

