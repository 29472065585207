<nav class="menu">
    <input type="checkbox" class="menu-open" name="menu-open" id="menu-open" (change)="onChange_mainMnu($event)" />
    <label class="menu-open-button" for="menu-open">
        <span class="hamburger hamburger-1"></span>
        <span class="hamburger hamburger-2"></span>
        <span class="hamburger hamburger-3"></span>
    </label>

    <button *ngIf="icon1" class="menu-item"> <i class="menu-glyph centerMe1" [class]="icon1"
            [ngbTooltip]="engLang ? ttEn1 : ttEs1" (click)="onClick_mnu1($event)" [innerHTML]="innHtml1"></i>
    </button>
    <button *ngIf="icon2" class="menu-item"> <i class="menu-glyph centerMe2" [class]="icon2"
            [ngbTooltip]="engLang ? ttEn2 : ttEs2" (click)="onClick_mnu2(mnuNo)" [innerHTML]="innHtml2"></i>
    </button>
    <button *ngIf="icon3" class="menu-item"> <i class="menu-glyph centerMe3" [class]="icon3"
            [ngbTooltip]="engLang ? ttEn3 : ttEs3" (click)="onClick_mnu3(mnuNo)" [innerHTML]="innHtml3"></i>
    </button>
    <button *ngIf="icon4" class="menu-item"> <i class="menu-glyph centerMe4" [class]="icon4"
            [ngbTooltip]="engLang ? ttEn4 : ttEs4" (click)="onClick_mnu4(mnuNo)" [innerHTML]="innHtml4"></i>
    </button>

</nav>

<!-- filters - FOR SHADOWS ONLY -->
<!-- Not working in iPad -->
<!-- <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
    <defs>
        <filter id="shadowed-goo">

            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
            <feGaussianBlur in="goo" stdDeviation="3" result="shadow" />
            <feColorMatrix in="shadow" mode="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 -0.2"
                result="shadow" />
            <feOffset in="shadow" dx="1" dy="1" result="shadow" />
            <feComposite in2="shadow" in="goo" result="goo" />
            <feComposite in2="goo" in="SourceGraphic" result="mix" />
        </filter>
        <filter id="goo">
            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
            <feComposite in2="goo" in="SourceGraphic" result="mix" />
        </filter>
    </defs>
</svg> -->