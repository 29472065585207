<p class="text-center">{{ reclFormDescSeled }}</p>
<div class="colContainer border">
    <div class="container colFlex">
        <div class="flex">
            <input id="opt0_1" type="radio" name="Plan_SSS" (change)="onChange_need2Save()" [(ngModel)]="e.opt0_plan"
                value="1">
            <label class="pl-1" for="opt0_1">(1) Plan de Salud del Gobierno</label>
        </div>

        <div class="flex">
            <input id="opt0_2" type="radio" name="Plan_SSS" (change)="onChange_need2Save()" [(ngModel)]="e.opt0_plan"
                value="2">
            <label class="pl-1" for="opt0_2">(2) Triple-S Salud</label>
        </div>

        <div class="flex">
            <input id="opt0_3" type="radio" name="Plan_SSS" (change)="onChange_need2Save()" [(ngModel)]="e.opt0_plan"
                value="5">
            <label class="pl-1" for="opt0_3">(5) BlueCard Program</label>
        </div>

        <div class="flex">
            <input id="opt0_4" type="radio" name="Plan_SSS" (change)="onChange_need2Save()" [(ngModel)]="e.opt0_plan"
                value="12">
            <label class="pl-1" for="opt0_4">(12) BCBS FEP</label>
        </div>

        <div class="flex">
            <button type="button" class="btn btn-link" style="font-size: smaller;"
                (click)="clearRadios()">Clear</button>
        </div>
    </div>
</div>

<span class="mb-0 pb-0 ml-3" style="font-size: 90%;">Sección 2 - Indicar la información que desea
    cambiar</span>
<div class="colContainer border">
    <div class="container colFlex">
        <div class="flex">
            <label class="mr-1" for="txt2_1">No. contrato:</label>
            <input id="txt2_1" type="text" name="Seccion_2" maxlength="20" (change)="onChange_need2Save()"
                [(ngModel)]="e.txt2_1">
        </div>
        <div class="flex">
            <label class="mr-1" for="txt2_2">Preautorización o Referido:</label>
            <input id="txt2_2" type="text" name="Seccion_2" maxlength="20" (change)="onChange_need2Save()"
                [(ngModel)]="e.txt2_2">
        </div>
    </div>
</div>

<span class="mb-0 pb-0 ml-3" style="font-size: 90%;">Sección 3 - Indicar la información que desea
    cambiar</span>
<div class="colContainer border">
    <div class="container colFlex" style="background-color: rgb(234, 227, 227);">
        <div class="flex">
            <label class="mr-1 ml-0" for="txt3_101">Línea:</label>
            <input id="txt3_101" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_101">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="med3_102">Desde:</label>
            <input id="med3_102" type="text" name="Seccion_3" maxlength="10" style="width: 6em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.med3_102">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="med3_103">Hasta:</label>
            <input id="med3_103" type="text" name="Seccion_3" maxlength="10" style="width: 6em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.med3_103">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="txt3_104">Lug:</label>
            <input id="txt3_104" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_104">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="txt3_105">Proced:</label>
            <input id="txt3_105" type="text" name="Seccion_3" maxlength="5" style="width: 4em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_105">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="txt3_106">Mods:</label>
            <input id="txt3_106" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class="mr-1"
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_106">
            <input id="txt3_107" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class="mr-1"
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_107">
            <input id="txt3_108" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class="mr-1"
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_108">
            <input id="txt3_109" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class="mr-1"
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_109">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="txt3_110">Diagn:</label>
            <input id="txt3_110" type="text" name="Seccion_3" maxlength="10" style="width: 5em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_110">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="txt3_111">Servs:</label>
            <input id="txt3_111" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_111">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="med3_112">NPI prod:</label>
            <input id="med3_112" type="text" name="Seccion_3" maxlength="10" style="width: 6em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.med3_112">
        </div>
    </div>

    <div class="container colFlex" style="background-color: rgb(203, 198, 198);">
        <div class="flex">
            <label class="mr-1 ml-0" for="txt3_201">Línea:</label>
            <input id="txt3_201" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_201">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="med3_202">Desde:</label>
            <input id="med3_202" type="text" name="Seccion_3" maxlength="10" style="width: 6em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.med3_202">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="med3_203">Hasta:</label>
            <input id="med3_203" type="text" name="Seccion_3" maxlength="10" style="width: 6em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.med3_203">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="txt3_204">Lug:</label>
            <input id="txt3_204" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_204">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="txt3_205">Proced:</label>
            <input id="txt3_205" type="text" name="Seccion_3" maxlength="5" style="width: 4em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_205">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="txt3_206">Mods:</label>
            <input id="txt3_206" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class="mr-1"
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_206">
            <input id="txt3_207" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class="mr-1"
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_207">
            <input id="txt3_208" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class="mr-1"
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_208">
            <input id="txt3_209" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class="mr-1"
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_209">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="txt3_210">Diagn:</label>
            <input id="txt3_210" type="text" name="Seccion_3" maxlength="10" style="width: 5em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_210">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="txt3_211">Servs:</label>
            <input id="txt3_211" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_211">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="med3_212">NPI prod:</label>
            <input id="med3_212" type="text" name="Seccion_3" maxlength="10" style="width: 6em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.med3_212">
        </div>
    </div>

    <div class="container colFlex" style="background-color: rgb(234, 227, 227);">
        <div class="flex">
            <label class="mr-1 ml-0" for="txt3_301">Línea:</label>
            <input id="txt3_301" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_301">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="med3_302">Desde:</label>
            <input id="med3_302" type="text" name="Seccion_3" maxlength="10" style="width: 6em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.med3_302">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="med3_303">Hasta:</label>
            <input id="med3_303" type="text" name="Seccion_3" maxlength="10" style="width: 6em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.med3_303">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="txt3_304">Lug:</label>
            <input id="txt3_304" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_304">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="txt3_305">Proced:</label>
            <input id="txt3_305" type="text" name="Seccion_3" maxlength="5" style="width: 4em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_305">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="txt3_306">Mods:</label>
            <input id="txt3_306" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class="mr-1"
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_306">
            <input id="txt3_307" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class="mr-1"
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_307">
            <input id="txt3_308" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class="mr-1"
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_308">
            <input id="txt3_309" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class="mr-1"
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_309">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="txt3_310">Diagn:</label>
            <input id="txt3_310" type="text" name="Seccion_3" maxlength="10" style="width: 5em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_310">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1" for="txt3_311">Servs:</label>
            <input id="txt3_311" type="text" name="Seccion_3" maxlength="2" style="width: 2em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.txt3_311">
        </div>
        <div class="flex">
            <label class="mr-1 ml-1d" for="med3_312">NPI prod:</label>
            <input id="med3_312" type="text" name="Seccion_3" maxlength="10" style="width: 6em;" class=""
                (change)="onChange_need2Save()" [(ngModel)]="e.med3_312">
        </div>
    </div>
</div>

<span class="mb-0 pb-0 ml-3" style="font-size: 90%;">Sección 4 - Otro Tipo de Ajustes</span>
<div class="colContainer border">
    <div class="container colFlex">
        <div class="flex">
            <input id="chk4_1" type="checkbox" name="Seccion_4" (change)="onChange_need2Save()" [(ngModel)]="e.chk4_1">
            <label class="ml-1 mt-1" for="chk4_1">Marcar si somete otro tipo ajuste</label>
        </div>
        <div class="flex">
            <label class="mr-1" for="txt4_2">Notas de Proceso:</label>
            <input id="txt4_2" type="text" name="Seccion_4" maxlength="10" style="width: 4em;"
                (change)="onChange_need2Save()" [(ngModel)]="e.txt4_2">
        </div>
        <div class="flex">
            <label class="mr-1" for="txt4_3">Notas de Proceso:</label>
            <input id="txt4_3" type="text" name="Seccion_4" maxlength="10" style="width: 4em;"
                (change)="onChange_need2Save()" [(ngModel)]="e.txt4_3">
        </div>
        <div class="flex">
            <label class="mr-1" for="med4_4">Total docs.incl.:</label>
            <input id="med4_4" type="text" name="Seccion_4" maxlength="10" style="width: 4em;"
                (change)="onChange_need2Save()" [(ngModel)]="e.med4_4">
        </div>
    </div>
</div>

<span class="mb-0 pb-0 ml-3" style="font-size: 90%;">Sección 5</span>
<div class="colContainer border">
    <textarea id="txt5_1" rows="3" cols="113" maxlength="500" (change)="onChange_need2Save()" [(ngModel)]="e.txt5_1"
        class="txt5_1"></textarea>
</div>
<!-- <pre>idxSeld = {{ idxSeld }}</pre> -->