/* src/app/shared/payment-form.component.css */
input.placeholder {
  text-align: center;
}
::-webkit-input-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
}
:-moz-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
}
::-moz-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
}
:-ms-input-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
}
table {
  table-layout: auto;
  border-collapse: collapse;
  width: 100%;
}
table td {
  border: 1px solid #ccc;
}
table .absorbing-column {
  width: 100%;
}
.black {
  -webkit-filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
  filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
}
.odd {
  background-color: #f5f9f8;
}
/*# sourceMappingURL=payment-form.component.css.map */
