<div class="card text-center">
    <div class="card-header">
        <div>
            {{ reclaims ? reclaims[idxSeld]?.reclPatNm: '' }} {{ engLang ? 'Claim': 'Caso:' }} {{
            reclaims ? reclaims[idxSeld]?.reclCasNo: '' }}
        </div>
        <div>
            <span style="font-size: smaller;">Ck #: {{ reclaims ? reclaims[idxSeld]?.reclCkNo: '' }}</span>
            <img src="/app/assets/images/clipboard-copy-small.png" class="mx-1" width="15px" height="15px"
                alt="Clipboard copy" style="cursor: pointer;"
                (click)="copy2ClipBoard(reclaims ? reclaims[idxSeld]?.reclCkNo: '','check #')">
        </div>
    </div>

    <div class="text-center">
        ICNs
        <select id="icns" type="text" (mousedown)="onMouseDown_captureIcnsCurrIndex($event)"
            (change)="onChange_icn($event);">
            <option *ngFor="let icn of icns; let idx = index;"
                [ngStyle]="{'background-color': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF')}">
                <span>{{ icn.icn }}</span>
                <!-- <span style="display: none;">&nbsp;&nbsp;{{ icn.i }}</span> -->
            </option>
        </select>
    </div>

    <div class="card-body">
        <div class="card-text">
            <div class="d-inline-flex" style="align-items: flex-start; gap: 7px; font-weight: 85%;">
                <div>
                    <div class="d-flex flex-column align-items-end">
                        <div class="min-ht">
                            {{ engLang ? 'Ins 1:': 'Seg 1:' }}
                        </div>
                        <div class="min-ht">
                            {{ engLang ? 'Contract No. 1:': 'Contrato No. 1:' }}
                        </div>
                        <div class="min-ht">
                            {{ engLang ? 'Group No. 1:': 'Grupo No. 1:' }}
                        </div>
                        <div class="min-ht">
                            Balance 1:
                        </div>
                    </div>
                </div>

                <div>
                    <div class="d-flex flex-column align-items-start">

                        <div class="min-ht"
                            [ngStyle]="{ 'font-weight': reclaims ? (reclaims[idxSeld]?.reclPS == '1' ? 'bold': 'normal'): 'normal' }">
                            {{ reclaims ? reclaims[idxSeld]?.reclIn1: '' }}
                            <span style="font-size: 60%;">{{ reclaims ? reclaims[idxSeld]?.reclIn1ID:
                                '' }}</span>
                            &nbsp;{{ reclaims ? reclaims[idxSeld]?.reclPayrID1: '' }}
                        </div>
                        <div class="min-ht">
                            {{ reclaims ? reclaims[idxSeld]?.reclContr1: '' }}
                        </div>
                        <div class="min-ht">
                            {{ reclaims ? reclaims[idxSeld]?.reclGrp1: '' }}
                        </div>
                        <div class="min-ht">
                            {{ reclaims ? (reclaims[idxSeld]?.bal1 | currency:'USD':'':'1.2-2') : ''}}
                        </div>
                    </div>
                </div>

                <div>
                    <div class="d-flex flex-column">
                        <div class="min-ht"></div>
                    </div>
                </div>

                <div>
                    <div class="d-flex flex-column align-items-end">
                        <div class="min-ht">
                            Prov:
                        </div>
                        <div class="min-ht">
                            NPI:
                        </div>
                        <div class="min-ht">
                            Ref:
                        </div>
                        <div class="min-ht">
                            Fac:
                        </div>
                    </div>
                </div>

                <div>
                    <div class="d-flex flex-column align-items-start">
                        <div class="min-ht">
                            {{ reclaims ? reclaims[idxSeld]?.reclProv: '' }}
                            <span style="font-size: 60%;">{{ reclaims ? reclaims[idxSeld]?.reclProvID:
                                '' }}</span>
                        </div>
                        <div class="min-ht">
                            {{ reclaims ? reclaims[idxSeld]?.reclNPI: '' }}
                        </div>
                        <div class="min-ht">
                            {{ reclaims ? reclaims[idxSeld]?.reclRefMD: '' }}
                        </div>
                        <div class="min-ht">
                            {{ reclaims ? reclaims[idxSeld]?.reclRefFac: '' }}
                        </div>
                    </div>
                </div>

                <div>
                    <div class="d-flex flex-column">
                        <div class="min-ht"></div>
                    </div>
                </div>

                <div>
                    <div class="d-flex flex-column align-items-end">
                        <div class="min-ht">
                            {{ engLang ? 'Ins 2:': 'Seg 2:' }}
                        </div>
                        <div class="min-ht">
                            {{ engLang ? 'Contract No. 2:': 'Contrato No. 2:' }}
                        </div>
                        <div class="min-ht">
                            {{ engLang ? 'Group No. 2:': 'Grupo No. 2:' }}
                        </div>
                        <div class="min-ht">
                            Balance 2:
                        </div>
                    </div>
                </div>

                <div>
                    <div class="d-flex flex-column align-items-start">
                        <div class="min-ht"
                            [ngStyle]="{ 'font-weight': reclaims ? (reclaims[idxSeld]?.reclPS == '2' ? 'bold': 'normal'): 'normal' }">
                            {{ reclaims ? reclaims[idxSeld]?.reclIn2: '' }}
                            <span style="font-size: 60%;">{{ reclaims ? reclaims[idxSeld]?.reclIn2ID:
                                '' }}</span>
                            &nbsp;{{ reclaims ? reclaims[idxSeld]?.reclPayrID2: '' }}
                        </div>
                        <div class="min-ht">
                            {{ reclaims ? reclaims[idxSeld]?.reclContr2: '' }}
                        </div>
                        <div class="min-ht">
                            {{ reclaims ? reclaims[idxSeld]?.reclGrp2: '' }}
                        </div>
                        <div class="min-ht">
                            {{ reclaims ? reclaims[idxSeld]?.bal2 : '0' | currency:'USD':'':'1.2-2'}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-sm-inline-flex" style="justify-content:space-evenly; font-weight: 85%;">

                <div style="min-height: 24px;">
                    Dxs: {{ reclaims ? reclaims[idxSeld]?.reclDx1: '' }}
                    &nbsp;{{ reclaims ? reclaims[idxSeld]?.reclDx2: '' }}
                    &nbsp;{{ reclaims ? reclaims[idxSeld]?.reclDx3: '' }}
                    &nbsp;{{ reclaims ? reclaims[idxSeld]?.reclDx4: '' }}
                    &nbsp;{{ reclaims ? reclaims[idxSeld]?.reclDx5: '' }}
                    &nbsp;{{ reclaims ? reclaims[idxSeld]?.reclDx6: '' }}
                    &nbsp;{{ reclaims ? reclaims[idxSeld]?.reclDx7: '' }}
                    &nbsp;{{ reclaims ? reclaims[idxSeld]?.reclDx8: '' }}
                    &nbsp;{{ reclaims ? reclaims[idxSeld]?.reclDx9: '' }}
                    &nbsp;{{ reclaims ? reclaims[idxSeld]?.reclDxa: '' }}
                    &nbsp;{{ reclaims ? reclaims[idxSeld]?.reclDxb: '' }}
                    &nbsp;{{ reclaims ? reclaims[idxSeld]?.reclDxc: '' }}
                </div>
                <div style="min-height: 24px;">
                    Auth. #: {{ reclaims ? reclaims[idxSeld]?.reclAuthNo: '' }}&nbsp;
                </div>
                <div style="min-height: 24px;">
                    Ref. #: {{ reclaims ? reclaims[idxSeld]?.reclRefNo: '' }}&nbsp;
                </div>
                <div style="min-height: 24px;">&nbsp;
                    {{ engLang ? 'Slot 19:': 'Encas 19:' }}{{ reclaims ? reclaims[idxSeld]?.recl19: ''
                    }}
                </div>
            </div>

            <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
        </div>
    </div>

    <table id="reclTbl" class="table table-bordered table-sm" style="font-size: 85%;">
        <thead>
            <tr style="background-color: #c685b1">
                <!-- <th style="display: none">
                            reclID
                        </th>
                        <th style="display: none">
                            reclDetID
                        </th>
                        <th style="display: none">
                            reclCasID
                        </th>
                        <th style="display: none">
                            reclType
                        </th> -->
                <th class="text-center">
                    S
                </th>
                <th>
                    {{ engLang ? 'Dates': 'Fechas' }}
                </th>
                <th>
                    CPT
                </th>
                <th>
                    Mods
                </th>
                <th>
                    {{ engLang ? 'Qty': 'Cant' }}
                </th>
                <th>
                    <div ngbTooltip="{{ engLang ? 'Usual Charges': 'Cargo Usual' }}" placement="left">
                        {{ engLang ? 'Submitted': 'Sometido' }}
                    </div>
                </th>
                <th>
                    {{ engLang ? 'Expected': 'Esperado' }}
                </th>
                <th>
                    {{ engLang ? 'Deductible': 'Deducible' }}
                </th>
                <th>
                    {{ engLang ? 'Coinsurance': 'Coaseguro' }}
                </th>
                <th>
                    <div ngbTooltip="{{ engLang ? 'Payed or adjusted': 'Pago o ajuste' }}" placement="left">
                        {{ engLang ? 'Payed': 'Pagado' }}
                    </div>
                </th>
                <th>
                    <div ngbTooltip="{{ engLang ? 'Pay Date': 'Fecha Pago' }}" placement="left">
                        {{ engLang ? 'Date': 'Fecha' }}
                    </div>
                </th>
                <th>
                    <div ngbTooltip="{{ engLang ? 'Adjust Notes': 'Notas Ajustes' }}" placement="left">
                        {{ engLang ? 'Adjust (CAS)': 'Ajustes (CAS)' }}
                    </div>
                </th>
                <th>
                    <div ngbTooltip="Internal Control No." placement="left">
                        ICN
                    </div>
                </th>
                <th *ngIf="reclaims && +reclaims[idxSeld]?.reclID && !reclaims[idxSeld]?.reclXml" class="text-center">
                    <i class="fa fa-trash"></i>
                </th>
                <th *ngIf="reclaims && +reclaims[idxSeld]?.reclID && !reclaims[idxSeld]?.reclXml" class="text-center">
                    <i class="fas fa-print"></i>
                </th>
            </tr>
        </thead>
        <tbody
            *ngFor="let det of reclaimsDet | filtrDetailsPipe: reclID: (reclaims ? reclaims[idxSeld]?.reclICN: null); let e = even; let o = odd; let r = index;">
            <tr style="cursor: pointer;" [ngClass]="highlightSelected(r)">
                <!-- <td style="display: none">
                            {{ det.reclID }}
                        </td>
                        <td style="display: none">
                            {{ det.reclDetID }}
                        </td>
                        <td style="display: none">
                            {{ det.reclCasID }}
                        </td>
                        <td style="display: none">
                            {{ det.reclType }}
                        </td> -->
                <td class="text-center">
                    <input type="checkbox" id="{{ 'chkReason' + r }}"
                        [checked]="(det.reclDetCodeSeled == '1' ? true: false)"
                        (change)="onChange_selectReclLineDet($event,det,r);">
                </td>
                <td
                    (click)="+det.need2Save ? open(reclaimExitWoSaveModal, { backdrop: 'static' }, 'reclaimExitWoSaveModal') : null">
                    {{ det.reclDetFromDt }}<span *ngIf="det.reclDetToDt">{{' - '}}</span>{{
                    det.reclDetToDt.substring(0,5) }}
                </td>
                <td
                    (click)="+det.need2Save ? open(reclaimExitWoSaveModal, { backdrop: 'static' }, 'reclaimExitWoSaveModal') : null">
                    {{ det.reclDetPcode }}
                </td>
                <td
                    (click)="+det.need2Save ? open(reclaimExitWoSaveModal, { backdrop: 'static' }, 'reclaimExitWoSaveModal') : null">
                    {{ det.reclDetMods }}
                </td>
                <td class="text-center"
                    (click)="+det.need2Save ? open(reclaimExitWoSaveModal, { backdrop: 'static' }, 'reclaimExitWoSaveModal') : null">
                    {{ det.reclDetQty }}
                </td>
                <td style="text-align: right;"
                    (click)="+det.need2Save ? open(reclaimExitWoSaveModal, { backdrop: 'static' }, 'reclaimExitWoSaveModal') : null">
                    {{ det.reclDetAllowed | currency:'USD':'':'1.2-2' }}
                </td>
                <td style="text-align: right;"
                    (click)="+det.need2Save ? open(reclaimExitWoSaveModal, { backdrop: 'static' }, 'reclaimExitWoSaveModal') : null">
                    {{ det.reclDetXpected | currency:'USD':'':'1.2-2' }}
                </td>
                <td style="text-align: right;"
                    (click)="+det.need2Save ? open(reclaimExitWoSaveModal, { backdrop: 'static' }, 'reclaimExitWoSaveModal') : null">
                    {{ det.reclDetDeduct | currency:'USD':'':'1.2-2' }}
                </td>
                <td style="text-align: right;"
                    (click)="+det.need2Save ? open(reclaimExitWoSaveModal, { backdrop: 'static' }, 'reclaimExitWoSaveModal') : null">
                    {{ det.reclDetCoins | currency:'USD':'':'1.2-2' }}
                </td>
                <td [ngStyle]="{'text-align': (det.reclDetPayed ? 'right': 'center')}"
                    (click)="+det.need2Save ? open(reclaimExitWoSaveModal, { backdrop: 'static' }, 'reclaimExitWoSaveModal') : null">
                    {{ det.reclDetPayed | currency:'USD':'':'1.2-2' }}
                    <i *ngIf="!det.reclDetPayed" class="fas fa-question-circle"
                        ngbTooltip="{{ engLang ? 'No payment asociated to this procedure and ck: ' + ckNo + ' was found.': 'No se encontró pago asociado con este procedimiento y ck: ' + ckNo + '.' }}"
                        style="color: red"></i>
                </td>
                <td>
                    {{ det.reclDetPayDt ? det.reclDetPayDt.substring(0,5): '' }}
                </td>
                <td>
                    <button class="btn btn-link small pt-0 pl-1" (click)="getCarcDescrFromAdj(det.reclDetAdjust)"
                        style="font-size: xx-small;">{{
                        det.reclDetAdjust }}</button>
                </td>
                <td>
                    {{ det.reclDetICN }}
                </td>
                <td *ngIf="det.reclDetXml" class="text-center">
                    <i class="fa fa-trash" style="cursor: pointer;" *ngIf="+det.reclID > 0 && +det.reclDetID > 0"
                        title="{{ engLang ? 'Delete reclaim for this CPT': 'Eliminar reclamo para este CPT' }}"
                        (click)="open(reclaimDeleteModal, { backdrop: 'static' }, 'reclaimDeleteModal');"></i>
                </td>
                <td *ngIf="det.reclDetXml" class="text-center">
                    <i class="fas fa-print" style="cursor: pointer;" *ngIf="+det.reclID > 0 && +det.reclDetID > 0"
                        title="{{ engLang ? 'Print': 'Imprimir' }}" (click)="printReclaim(r)"></i>
                </td>
            </tr>

            <tr [ngClass]="highlightSelected(r)">
                <th>

                </th>
                <th scope="row" (click)="null">
                    {{ engLang ? 'Reason:': 'Razón:' }}
                </th>
                <td colspan="4">
                    <input id="{{ 'linReason' + r }}" list="linReasons" class="form-control form-control-sm"
                        [ngModel]="det.reclDetReclaimReason" (change)="saveReclaims(false)" (click)="null"
                        style="font-size: 90%;" maxlength="80">
                    <datalist id="linReasons">
                        <option *ngFor="let linReason of reclLineReasons; let idx = index;"
                            [ngStyle]="{'background-color': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF')}"
                            [value]="linReason.reclLineReason">
                        </option>
                    </datalist>
                </td>
                <td colspan="7" style="text-align: left;">
                    <span style="font-style: italic; font-size: 12px;">&#8592; {{ engLang ? 'Explain adjustment reason
                        before
                        checking ':'Explique razón para ajuste antes de marcar ' }}&#8730;</span>
                </td>
            </tr>

        </tbody>
    </table>

    <div *ngIf="idxSeld > -1">
        <div *ngIf="reclTyp == '5'">
            <recl-xml-sss022309 (need2Save)="saveReclaims(false)" [idxSeld]="idxSeld" [reclaims]="reclaims"
                [reclaimsDet]="reclaimsDet" [reclLineReasonEntered]="reclLineReasonEntered" [print]="print">
            </recl-xml-sss022309>
        </div>

        <div *ngIf="reclTyp == '6'">
            <recl-xml-medicare-bretermination (need2Save)="saveReclaims(false)"
                [pCode]="reclaims[idxSeld]?.reclDetPcode" [dxCode]="dxCode" [mods]="reclaims[idxSeld]?.mods"
                [qty]="reclaims[idxSeld]?.reclDetQty" [usual]="reclaims[idxSeld]?.reclDetAllowed" [patZip]="reclPatZip"
                [npi]="reclaims[idxSeld]?.reclNPI">
            </recl-xml-medicare-bretermination>
        </div>

        <div *ngIf="reclTyp == '11'">
            <app-recl-xml-mmm-pmc-rev-b01162014 (need2Save)="saveReclaims(false)" [reclFormImg64Seled]="img64"
                [idxSeld]="idxSeld" [reclLineReasonEntered]="reclLineReasonEntered" [reclaims]="reclaims"
                [reclaimsDet]="reclaimsDet" [clrData]="clrReclData" [print]="print">
            </app-recl-xml-mmm-pmc-rev-b01162014>
        </div>

        <div *ngIf="reclTyp == '12'">
            <app-recl-xml-mmm-gobierno (need2Save)="saveReclaims(false)" [reclFormImg64Seled]="img64"
                [idxSeld]="idxSeld" [reclLineReasonEntered]="reclLineReasonEntered" [reclaims]="reclaims"
                [reclaimsDet]="reclaimsDet" [clrData]="clrReclData" [print]="print">
            </app-recl-xml-mmm-gobierno>
        </div>

        <div *ngIf="reclTyp == '14'">
            <app-recl-xml-triples-advantage-anejo1 (need2Save)="saveReclaims(false)" [reclFormImg64Seled]="img64"
                [idxSeld]="idxSeld" [reclLineReasonEntered]="reclLineReasonEntered" [reclaims]="reclaims"
                [reclaimsDet]="reclaimsDet" [clrData]="clrReclData" [print]="print">
            </app-recl-xml-triples-advantage-anejo1>
        </div>

        <div *ngIf="reclTyp == '16'">
            <app-recl-xml-sss-formulario-ajuste070114 (need2Save)="saveReclaims(false)" [reclFormImg64Seled]="img64"
                [idxSeld]="idxSeld" [reclLineReasonEntered]="reclLineReasonEntered" [reclaims]="reclaims"
                [reclaimsDet]="reclaimsDet" [clrData]="clrReclData" [print]="print">
            </app-recl-xml-sss-formulario-ajuste070114>
        </div>

        <div *ngIf="reclTyp == '17'">
            <app-recl-xml-humana-rev17112010 (need2Save)="saveReclaims(false)" [reclFormImg64Seled]="img64"
                [idxSeld]="idxSeld" [reclLineReasonEntered]="reclLineReasonEntered" [reclaims]="reclaims"
                [reclaimsDet]="reclaimsDet" [clrData]="clrReclData" [print]="print">
            </app-recl-xml-humana-rev17112010>
        </div>
    </div>

    <!-- <pre>{{ changeIcnObj | json2 }}</pre> -->

    <!-- <pre>{{ reclaimsDet | json }}</pre> -->
    <!-- <pre>{{ reclaims | json }}</pre> -->

    <!-- <pre>{{ 'idxSeld = '}}{{ idxSeld  }}</pre> -->
    <!-- <pre>{{ modalStack.length }} {{ modalStack }}</pre> -->
    <pre>{{ reclaims | json }}</pre>
    <!-- <pre>{{ reclaimFlds | json }}</pre> -->
    <!-- <pre>{{ img64 }}</pre> -->

</div>

<!-- modal reclaim form select ...................................................................... -->
<ng-template #reclaimFormSelectModal let-modal>
    <div class="modal-header p-1" style="background-color:  #d6deec;">
        <div>
            <h4 class="modal-title m-0">{{ engLang ? 'Select Reclaim Form': 'Seleccione Forma para Reclamo' }}</h4>
        </div>

        <div class="align-self-start">
            <button type="button" class="btn btn-sm btn-close m-0 p-0"
                (click)="modal.dismiss('closeReclaimFormSelectModal')"></button>
        </div>
    </div>

    <div class="modal-body m-0 p-0">

        <select id="selFrmRclTyp" class="form-select py-1" [(ngModel)]="reclTyp" style="width: 100%;"
            (change)="onChange_showForm($event.target.value)">
            <option *ngFor="let form of reclaimForms; let idx = index;" [value]="form.typ"
                [ngStyle]="{'background-color': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF')}">
                <span>{{ form.descr }}</span>
            </option>
        </select>
        <!-- <pre>{{reclaimForms | json2}}</pre> -->

        <div *ngIf="img64" class="text-center p-2">
            <img [src]="img64" width="450" height="450">
        </div>

        <!-- <pre>{{ reclTyp }}</pre> -->
        <!-- <pre>{{ reclaimForms | json }}</pre> -->

    </div>

    <div class="modal-footer justify-content-between" style="background-color: #d6deec;">
        <div class="small align-self-center">
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
        </div>

        <div>
            <!-- <input type="checkbox" id="memTyp" class="form-check-input" [(ngModel)]="memorizeReclTyp"
                [disabled]="!reclTyp || +reclTyp < 1">
            {{ engLang ? 'Memorize for Ins.': '¿Memorizar para Seg.' }}&nbsp;{{ reclIns }}? -->
        </div>

        <div class="align-self-center">
            <button type="button" class="btn btn-sm btn-outline-dark" (click)="modal.close('oK_reclaimFormSelectModal')"
                [disabled]="!reclTyp || +reclTyp < 1">Ok</button>
        </div>
    </div>

    <!-- <pre>{{ modalStack.length }}</pre> -->
    <!-- <pre>{{ modalStack }}</pre> -->

</ng-template>

<!-- modal reclaim delete ........................................................................... -->
<ng-template #reclaimDeleteModal let-modal>
    <div class="modal-header" style="background-color:  #d6deec;">
        <div>
            <h4 class="modal-title"><img src="app/assets/images/yellow-blue-green-question-marks.png" width="28"
                    height="28"></h4>
        </div>
        <div class="align-self-start">
            <button type="button" class="btn btn-sm btn-close p-0 m-0"
                (click)="modal.dismiss('closeReclaimDeleteModal')"></button>
        </div>
    </div>
    <div class="modal-body text-center">
        {{ engLang ? 'Delete Reclaim or Dispute ?': '¿ Eliminar el Reclamo o Disputa ?' }}
    </div>
    <div class="modal-footer justify-content-between" style="background-color: #d6deec;">
        <div style="font-size: 70%;">
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
        </div>
        <div style="font-size: 70%;">
            <button type="button" class="btn btn-sm btn-outline-dark"
                (click)="modal.close('oK_deleteReclaimModal')">Ok</button>
        </div>
    </div>

    <!-- <pre>{{ modalStack.length }}</pre> -->
    <!-- <pre>{{ modalStack }}</pre> -->

</ng-template>