<div class="container">

    <!-- Day View -->

    <div class="row justify-content-sm-center">

        <div class="container-fluid">

            <div>
                <div *ngIf="waiting4Response" class="text-center">
                    <img src="app/assets/images/waiting4Response.gif" width="42" height="42">
                </div>
                <div *ngIf="!waiting4Response" class="text-center">
                    <span style="font-weight: bold;">Agenda</span>
                </div>
                <div *ngIf="!waiting4Response" class="text-center">
                    <span style="font-size: smaller; font-style: italic; font-weight: bolder;">{{ displayDtDesc
                        }}</span>
                </div>
            </div>

            <div class="col col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <table class="table table-sm table-responsive-sm">
                    <!-- main table -->
                    <thead>
                        <tr>
                            <th style="text-align: center; vertical-align: middle;">
                                {{ engLang ? 'Calendar': 'Calendario' }}
                            </th>

                            <th style="text-align: center" class="my-0 py-0">

                                <div class="rowBox" style="align-items: center; align-content: none;">
                                    <div class="btn-group float-left">
                                        <label class="btn active btn-primary btn-xs">
                                            <input class="btn" type="radio"
                                                style="margin-top: 0; vertical-align: middle;" name="apptViewOptionsTop"
                                                id="showNotHiddenTop" autocomplete="off" checked
                                                (click)="showHiddenAll($event.target.id, $event.target.checked);"
                                                ngbTooltip="{{ engLang ? 'Click to show only appointments still waiting': 'Marque para solo mostrar citas no atendidas' }}" />
                                            <span style="font-size: x-small"> {{ engLang ? 'Waiting': 'En espera' }} {{
                                                waitingApptsCnt }} </span>
                                        </label>
                                        <label class="btn btn-primary btn-xs">
                                            <input class="btn" type="radio"
                                                style="margin-top: 0; vertical-align: middle;" name="apptViewOptionsTop"
                                                id="showAllTop" autocomplete="off"
                                                (click)="showHiddenAll($event.target.id, $event.target.checked);"
                                                ngbTooltip="{{ engLang ? 'Click to show all appointments': 'Marque para mostrar todas las citas' }}" />
                                            <span style="font-size: x-small"> {{ engLang ? 'All': 'Todos' }} {{
                                                waitingApptsCnt + finishedApptsCnt }} </span>
                                        </label>
                                    </div>

                                    <div class="btn-group mx-0 px-3 text-center">
                                        <i class="fas fa-print mt-2 pt-3 mb-1 pb-0 mx-0 px-0"
                                            style="font-size:12px; cursor: pointer;" *ngIf="!waiting4Response"
                                            ngbTooltip="{{ engLang ? 'Print': 'Imprimir' }}" (click)="printAppts()"></i>
                                    </div>

                                    <div class="btn-group float-right" style="cursor: pointer;">
                                        <button class="btn active btn-succes btn-xs"
                                            style="margin-top: 0; vertical-align: middle;" name="apptActionMnuTop"
                                            id="searchApptToggleTop" autocomplete="off"
                                            (click)="currentSrchResultsPg=1;open(apptSearchParmsModal, { backdrop: 'static', size: 'md', scrollable: true }, 'apptSearchParmsModal');"
                                            ngbTooltip="{{ engLang ? 'Click to search for appointments': 'Clic para buscar citas' }}">
                                            <i class="fa fa-search"></i>
                                            <span style="font-size: x-small"> {{ engLang ? 'Search': 'Buscar' }} </span>
                                        </button>
                                        <button class="btn btn-success btn-xs"
                                            style="margin-top: 0; vertical-align: middle;" name="apptActionMnuTop"
                                            id="newApptTop" autocomplete="off" (click)="newAppt();"
                                            ngbTooltip="{{ engLang ? 'Click to add a new appointments': 'Clic para añadir una cita nueva' }}">
                                            <i class="fa fa-file-o"></i>
                                            <span style="font-size: x-small"> {{ engLang ? 'New Appt.': 'Cita Nueva'
                                                }}</span>
                                        </button>
                                    </div>
                                </div>

                            </th>

                            <th style="text-align: center; vertical-align: middle;">
                                {{ engLang ? 'Resource': 'Recurso' }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>

                            <td>
                                <!-- calendar row ......................................................................................... -->
                                <div>
                                </div>
                                <table *ngIf="!monthTblOn" class="table table-bordered table-sm tdHover unselectable"
                                    style="table-layout: auto; cursor: default;">
                                    <!-- calendar table -->
                                    <thead style="border: inherit;">
                                        <tr style="background-color: #e8dec0;">
                                            <th (click)="nextPrevMonth(-1)" class="noRtBorder hover2GrayBkg"
                                                style="text-align: center;">
                                                <i class="fas fa-angle-left"></i>
                                            </th>
                                            <th (click)="nextPrevMonth(-3)" class="noLtBorder noRtBorder hover2GrayBkg"
                                                style="text-align: center;">
                                                <i class="fas fa-angle-double-left"></i>
                                            </th>
                                            <th colspan="3" style="text-align: center;" (click)="showCalYrMonths()"
                                                class="noLtBorder noRtBorder hover2GrayBkg">
                                                <span>{{ (engLang ? engMonth[currMo]:
                                                    spaMonth[currMo]) + ' ' }} {{ currYr }}</span>
                                            </th>
                                            <th (click)="nextPrevMonth(3)" class="noLtBorder noRtBorder hover2GrayBkg"
                                                style="text-align: center;">
                                                <i class="fas fa-angle-double-right"></i>
                                            </th>
                                            <th (click)="nextPrevMonth(1)" class="noLtBorder hover2GrayBkg"
                                                style="text-align: center;">
                                                <i class="fas fa-angle-right"></i>
                                            </th>
                                        </tr>
                                        <tr style="cursor: auto;">
                                            <th>{{engLang ? 'Mo': 'Lu'}}</th>
                                            <th>{{engLang ? 'Tu': 'Ma'}}</th>
                                            <th>{{engLang ? 'We': 'Mi'}}</th>
                                            <th>{{engLang ? 'Th': 'Ju'}}</th>
                                            <th>{{engLang ? 'Fr': 'Vi'}}</th>
                                            <th>{{engLang ? 'Sa': 'Sa'}}</th>
                                            <th>{{engLang ? 'Su': 'Do'}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let week of calWeek;" style="display: table-row;">
                                            <td *ngFor="let day of calDay[week]; let idx = index;" style="cursor: pointer; 
                                                    padding: 0 0 0 4px; 
                                                    margin: 0; 
                                                    height: 40px;"
                                                [ngStyle]="{'font-weight': (dayBold[week][idx] ? 'bold': 'normal'), 
                                                        'color': (dayBold[week][idx] ? (daySelected[week][idx] ? '#FFFFFF': '#000000'): '#ad91c4'), 
                                                        'font-size': (dayBold[week][idx] ? '100%': '99%'),
                                                        'background-color': (daySelected[week][idx] ? '#6e5582': '#FFFFFF')}"
                                                class="hover2GrayBkg radius6" (click)="selectDay(week,idx);">
                                                <div style="height: 20px; text-align: left; margin: 0; padding: 0;">
                                                    {{day}}
                                                </div>
                                                <div
                                                    style="text-align: center; margin: 0; padding: 5px 0 0 0; vertical-align: top;">
                                                    <span class="glyphicon glyphicon-ban-circle"
                                                        style="color: red;"></span>
                                                </div>
                                                <div
                                                    style="text-align: right; vertical-align: bottom; margin: 0; padding: 0; ">
                                                    <span
                                                        style="text-align: right; vertical-align: bottom; padding: 0 2px 0 0; margin: 0; font-size: xx-small; font-weight: normal; color: #1cb2b2">
                                                        {{dayTotals[week][idx]}}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- calendar table -->

                                <table *ngIf="monthTblOn" class="table table-bordered w-auto unselectable"
                                    style="font-weight: bold; cursor: pointer">
                                    <!-- months table -->
                                    <thead>
                                        <tr style="background-color: #e8dec0;">
                                            <th (click)="this.currYr = this.currYr - 1;"
                                                class="noRtBorder noBtBorder hover2GrayBkg  radius6"
                                                style="cursor: default;">
                                                <i class="fas fa-angle-left"></i>
                                            </th>
                                            <th colspan="2" style="text-align: center; cursor: auto;"
                                                class="noLtBorder noRtBorder noBtBorder">
                                                {{ currYr }}
                                            </th>
                                            <th (click)="this.currYr = this.currYr + 1;"
                                                class="noLtBorder noBtBorder hover2GrayBkg radius6"
                                                style="cursor: default;">
                                                <i class="fas fa-angle-right"></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="noLtBorder noRtBorder noBtBorder noTpBorder hover2GrayBkg radius6"
                                                (click)="selectMonthYear($event.target.innerHTML)">
                                                {{ engLang ? engMonth[0]: spaMonth[0] }}
                                            </td>
                                            <td class="noLtBorder noRtBorder noBtBorder noTpBorder hover2GrayBkg radius6"
                                                (click)="selectMonthYear($event.target.innerHTML)">
                                                {{ engLang ? engMonth[1]: spaMonth[1] }}
                                            </td>
                                            <td class="noLtBorder noRtBorder noBtBorder noTpBorder hover2GrayBkg radius6"
                                                (click)="selectMonthYear($event.target.innerHTML)">
                                                {{ engLang ? engMonth[2]: spaMonth[2] }}
                                            </td>
                                            <td class="noLtBorder noRtBorder noBtBorder noTpBorder hover2GrayBkg radius6"
                                                (click)="selectMonthYear($event.target.innerHTML)">
                                                {{ engLang ? engMonth[3]: spaMonth[3] }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="noLtBorder noRtBorder noBtBorder noTpBorder hover2GrayBkg radius6"
                                                (click)="selectMonthYear($event.target.innerHTML)">
                                                {{ engLang ? engMonth[4]: spaMonth[4] }}
                                            </td>
                                            <td class="noLtBorder noRtBorder noBtBorder noTpBorder hover2GrayBkg radius6"
                                                (click)="selectMonthYear($event.target.innerHTML)">
                                                {{ engLang ? engMonth[5]: spaMonth[5] }}
                                            </td>
                                            <td class="noLtBorder noRtBorder noBtBorder noTpBorder hover2GrayBkg radius6"
                                                (click)="selectMonthYear($event.target.innerHTML)">
                                                {{ engLang ? engMonth[6]: spaMonth[6] }}
                                            </td>
                                            <td class="noLtBorder noRtBorder noBtBorder noTpBorder hover2GrayBkg radius6"
                                                (click)="selectMonthYear($event.target.innerHTML)">
                                                {{ engLang ? engMonth[7]: spaMonth[7] }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="noLtBorder noRtBorder noTpBorder hover2GrayBkg radius6"
                                                (click)="selectMonthYear($event.target.innerHTML)">
                                                {{ engLang ? engMonth[8]: spaMonth[8] }}
                                            </td>
                                            <td class="noLtBorder noRtBorder noTpBorder hover2GrayBkg radius6"
                                                (click)="selectMonthYear($event.target.innerHTML)">
                                                {{ engLang ? engMonth[9]: spaMonth[9] }}
                                            </td>
                                            <td class="noLtBorder noRtBorder noTpBorder hover2GrayBkg radius6"
                                                (click)="selectMonthYear($event.target.innerHTML)">
                                                {{ engLang ? engMonth[10]: spaMonth[10] }}
                                            </td>
                                            <td class="noLtBorder noRtBorder noTpBorder hover2GrayBkg radius6"
                                                (click)="selectMonthYear($event.target.innerHTML)">
                                                {{ engLang ? engMonth[11]: spaMonth[11] }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- months table -->
                                <button id="btnToday" class="btn btn-light mt-0 pt-1" (click)="ngOnInit();">
                                    {{ engLang ? 'Today': 'Hoy'}}
                                </button>
                                <label for="btnToday" class="mt-2" style="float: right;">
                                    {{ clock }}
                                </label>

                                <div class="colBox mt-3" width="100%">
                                    <div class="text-center" style="cursor: pointer; font-weight: bold;"
                                        (click)="editGroup = !editGroup; grpIndexErr = 999;"
                                        ngbTooltip="{{ engLang ? 'Click to add/edit Groups': 'Clic añadir/editar Grupos'}}">
                                        {{ engLang ? 'Group': 'Grupo' }}
                                    </div>
                                    <div class="mt-2 table-responsive" style="height: 180px; width: 100%;">
                                        <table class="table table-borderless table-sm unselectable height: 155px;"
                                            style="border-top: 0;">

                                            <tbody class="mt-3"
                                                style="background-color: white; height: 100%; overflow-y: auto; overflow-x: hidden;"
                                                *ngFor="let grp of apptsGroups; let idx = index;">
                                                <tr *ngIf="(!editGroup && grp.pkID > 0) || editGroup"
                                                    style="font-size: smaller;"
                                                    [ngClass]="idx == selectedGrpIndex ? selectedGrpClass: (groupEditErr(idx) ? 'redBkg': '')"
                                                    (click)="selectGroupRw(idx)">
                                                    <!-- <td width="15%">{{ grp.pkID }}</td> -->
                                                    <td width="10%">
                                                        <div *ngIf="editGroup && (grp.pkID < 999)">
                                                            <i *ngIf="grp.pkID > '0'" class="fa fa-trash" id="clearTp"
                                                                (click)="deleteGroup(grp.pkID);"></i>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">
                                                        <div *ngIf="editGroup && (grp.pkID < 999)">
                                                            <input type="time" [(ngModel)]="grp.startTm"
                                                                id="st{{grp.pkID}}" style="width: 92px;" />
                                                        </div>
                                                        <div *ngIf="!editGroup">
                                                            {{ prepHoursMins12(grp.startTm,grp.endTm,idx) }}
                                                        </div>
                                                        <div *ngIf="editGroup && (grp.pkID < 999)">
                                                            <input type="time" [(ngModel)]="grp.endTm"
                                                                id="en{{grp.pkID}}" style="width: 92px;" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div *ngIf="editGroup && (grp.pkID < 999)">
                                                            <input type="text" [(ngModel)]="grp.name"
                                                                id="nm{{grp.pkID}}" style="width: 65px;"
                                                                (keyup)="onKeyUp_saveGroup($event, grp, idx)" />
                                                        </div>
                                                        <div *ngIf="!editGroup">
                                                            {{ grp.name }}
                                                        </div>
                                                    </td>
                                                    <td *ngIf="editGroup && (grp.pkID < 999)">
                                                        <i class="far fa-save" style="cursor: pointer;"
                                                            *ngIf="grpIsDirty(grp)"
                                                            (click)="saveGroup(grp, idx, $event);"></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- {{ editGroup }} -->
                                <!-- {{ apptsGroups | json }} -->
                                <!-- {{ apptsGroups.length }} -->
                            </td>

                            <td>
                                <!-- appointments row ........................................................................................ -->
                                <div class="table-responsive apptTabWrapper">
                                    <table id="apptTabl" class="table table-bordered table-sm unselectable">
                                        <!-- appointment table -->
                                        <thead class="disappear stickyTabHead" style="background-color: #e8dec0;">
                                            <tr>
                                                <!-- <th scope="col" style="display: none;">
                                                </th> -->
                                                <!-- <th scope="col" style="display: none;">
                                                </th> -->
                                                <th id="th2" scope="col" class="noBtBorder noRtBorder hover2GrayBkg"
                                                    style="cursor: default; text-align: center; width: 8%;">
                                                    <div>
                                                        <i class="far fa-clock"
                                                            ngbTooltip="{{ engLang ? 'Time': 'Hora' }}"
                                                            placement="right"></i>
                                                    </div>
                                                </th>
                                                <th id="th3" scope="col"
                                                    class="noLtBorder noBtBorder noRtBorder hover2GrayBkg"
                                                    style="cursor: default; text-align: center; width: 8%;">
                                                    <div>
                                                        <i class="fas fa-user-alt"
                                                            ngbTooltip="{{ engLang ? 'Name or Subject': 'Nombre o Asunto' }}"
                                                            placement="right"></i>
                                                    </div>
                                                </th>
                                                <th id="th4" scope="col" style="text-align: center;"
                                                    class="noLtBorder noBtBorder noRtBorder hover2GrayBkg"
                                                    style="cursor: default; text-align: center; width: 8%;">
                                                    <div>
                                                        <i class="fas fa-folder-open"
                                                            ngbTooltip="{{ engLang ? 'Record No.': 'No. Expediente' }}"
                                                            placement="right"></i>
                                                    </div>
                                                </th>
                                                <th id="th5" scope="col"
                                                    class="noLtBorder noBtBorder noRtBorder hover2GrayBkg"
                                                    style="cursor: default; text-align: center; width: 8%;">
                                                    <div>
                                                        <i class="fas fa-phone"
                                                            ngbTooltip="{{ engLang ? 'Telephone': 'Teléfono' }}"
                                                            placement="right"></i>
                                                    </div>
                                                </th>
                                                <th id="th6" scope="col"
                                                    class="noLtBorder noBtBorder noRtBorder hover2GrayBkg"
                                                    style="cursor: default; text-align: center; width: 8%;">
                                                    <div>
                                                        <i class="fas fa-thumbs-up"
                                                            ngbTooltip="{{ engLang ? 'Confirmed': 'Confirmado' }}"
                                                            placement="right"></i>
                                                    </div>
                                                </th>
                                                <th id="th7" scope="col"
                                                    class="noLtBorder noBtBorder noRtBorder hover2GrayBkg"
                                                    style="cursor: default; text-align: center; width: 8%;">
                                                    <div>
                                                        <span
                                                            ngbTooltip="{{ engLang ? 'Appointment Type': 'Tipo/Status de Cita' }}"
                                                            placement="right">{{ engLang ? ' Typ/Sta ': ' Tip/Sta '
                                                            }}</span>
                                                    </div>
                                                </th>
                                                <th id="th8" scope="col"
                                                    class="noLtBorder noBtBorder noRtBorder hover2GrayBkg"
                                                    style="cursor: default; width: 8%;">
                                                    <div style="display: flex; justify-content: center;"
                                                        ngbTooltip="{{ engLang ? 'Gender': 'Género' }}"
                                                        placement="right">
                                                        <div>
                                                            <i class="fas fa-male"></i>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-female"></i>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th id="th9" scope="col"
                                                    class="noLtBorder noBtBorder noRtBorder hover2GrayBkg"
                                                    style="cursor: default; text-align: center; width: 8%;">
                                                    <div>
                                                        <i class="fas fa-birthday-cake"
                                                            ngbTooltip="{{ engLang ? 'Age': 'Edad' }}"
                                                            placement="right"></i>
                                                    </div>
                                                </th>
                                                <th id="th10" scope="col" class="noLtBorder noBtBorder"
                                                    style="text-align: center; width: 8%;">
                                                    <div>
                                                        <i class="far fa-calendar-times"
                                                            ngbTooltip="{{ engLang ? 'Delete Appointment': 'Eliminar Cita' }}"
                                                            placement="right"></i>
                                                    </div>
                                                </th>
                                                <th id="th11" scope="col" class="noLtBorder noBtBorder hover2GrayBkg"
                                                    style="cursor: default; text-align: center; width: 8%;">
                                                    <div>
                                                        <i class="fa fa-umbrella"
                                                            ngbTooltip="{{ engLang ? 'Eligible Insurance': 'Seguro Elegible' }}"
                                                            placement="left"></i>
                                                    </div>
                                                </th>
                                                <th id="th12" scope="col" class="noBtBorder"
                                                    style="text-align: center; width: 8%;">
                                                    <div>
                                                        <i class="fas fa-pencil-alt"
                                                            ngbTooltip="{{ engLang ? 'Edit Appointment': 'Editar Cita' }}"
                                                            placement="left"></i>
                                                    </div>
                                                </th>
                                                <th id="th13" scope="col" class="noLtBorder noBtBorder"
                                                    style="text-align: center; min-width: 30px; width: 8%;">
                                                    <div>
                                                        <i class="fas fa-check"
                                                            ngbTooltip="{{ engLang ? 'Hide Finished Appointment': 'Esconder Cita Terminada' }}"
                                                            placement="left"></i>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody style="height: 600px; overflow-y: auto; overflow-x: hidden;">
                                            <tr *ngFor="let appt of appts | agendaPipe: selectedDayFilter: showHiddenFilter: grpFilter: grpStartTm: grpEndTm 
                                                | paginate: { id: 'agendaAppts', itemsPerPage: itemsPerPage, currentPage: currentPg }; let idx = index;"
                                                id="{{ appt.pKey }}">

                                                <!-- <td style="display:none;">
                                                    {{ appt.pKey }}
                                                </td> -->

                                                <!-- <td style="display:none;">
                                                    {{ appt.patID }}
                                                </td> -->

                                                <!-- <td>
                                                    {{ appt.end }}
                                                </td> -->

                                                <td class="apptTablTd" style="text-align: center; font-size: 80%;"
                                                    [ngStyle]="{'color': (appt.selected ? '#FFFFFF': '#000000'), 
                                                'background-color': (appt.selected ? '#6e5582': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF'))}"
                                                    (click)="selectAppointment(appt);">
                                                    {{ appt.h > 12 ? appt.h - 12: appt.h | number : '1.0-0' }}:{{ appt.m
                                                    |
                                                    number : '2.0-0' }}{{ appt.h >= 12 ? 'p': 'a' }}
                                                    <br />
                                                    <span style="font-size: xx-small"
                                                        [ngStyle]="{'color': appt.conflict ? 'red': '#000000'}">
                                                        {{ appt.duration }}
                                                    </span>
                                                </td>

                                                <td class="apptTablTd" style="text-align: left; font-size: 80%;"
                                                    [ngStyle]="{'color': (appt.selected ? '#FFFFFF': '#000000'), 
                                                'background-color': (appt.selected ? '#6e5582': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF'))}"
                                                    (click)="selectAppointment(appt);">
                                                    <span style="font-weight: bolder;">
                                                        {{ appt.summary }}
                                                    </span>
                                                    <br />
                                                    <span style="font-size: smaller;">
                                                        {{ appt.complaint }}
                                                    </span>
                                                </td>

                                                <td class="apptTablTd" style="text-align: center; font-size: 80%;"
                                                    [ngStyle]="{'color': (appt.selected ? '#FFFFFF': '#000000'), 
                                                'background-color': (appt.selected ? '#6e5582': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF'))}">
                                                    <button type="button" class="btn btn-link"
                                                        (click)="selectAppointment(appt);
                                                            patNmModalHd = appt.summary; casNoModalHd = 'Nuevo'; 
                                                            open(recordCaseModal, { backdrop: 'static', size: 'lg', scrollable: true }, 'recordCaseModal');">
                                                        <div *ngIf="+appt.patID > 0 && viewportWidth >= 992">
                                                            {{ appt.recNo }}
                                                            <br />
                                                            <small>{{ appt.recOffice +'-' + appt.recYr }}</small>
                                                        </div>
                                                        <div *ngIf="+appt.patID > 0 && viewportWidth < 992">
                                                            {{ appt.recNo + '-' + appt.recOffice +'-' + appt.recYr }}
                                                        </div>
                                                        <div *ngIf="+appt.patID == 0"
                                                            ngbTooltip="{{engLang ? 'Create the Record': 'Crear el Record'}}">
                                                            <i class="far fa-file"></i>
                                                        </div>
                                                    </button>
                                                    <div *ngIf="+appt.patID == 0">
                                                        <div *ngIf="viewportWidth >= 992">
                                                            {{ appt.recNo }}
                                                            <br />
                                                            <small>{{ appt.recOffice +'-' + appt.recYr }}</small>
                                                        </div>
                                                        <div *ngIf="viewportWidth < 992">
                                                            {{ appt.recNo + '-' + appt.recOffice +'-' + appt.recYr }}
                                                        </div>
                                                    </div>
                                                </td>

                                                <td class="apptTablTd" style="text-align: center; font-size: smaller;"
                                                    [ngStyle]="{'color': (appt.selected ? '#FFFFFF': '#000000'), 
                                                'background-color': (appt.selected ? '#6e5582': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF'))}"
                                                    (click)="selectAppointment(appt);">
                                                    {{ appt.patTelCell }}
                                                </td>

                                                <td class="apptTablTd" style="text-align: center;"
                                                    [ngStyle]="{'color': (appt.selected ? '#FFFFFF': '#000000'), 
                                                'background-color': (appt.selected ? '#6e5582': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF'))}"
                                                    (click)="selectAppointment(appt);">
                                                    <input type="checkbox" (change)="confirmAppt($event, appt);"
                                                        [checked]="appt.confirm" />
                                                    <!-- TODO  Persevere to db selected state -->
                                                </td>

                                                <td class="apptTablTd" style="text-align: center; font-size: 80%;"
                                                    [ngStyle]="{'color': (appt.selected ? '#FFFFFF': '#000000'), 
                                                'background-color': (appt.selected ? '#6e5582': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF'))}"
                                                    (click)="selectAppointment(appt);">
                                                    <div [ngStyle]="{'background-color': setColorAttr(appt.typeColor)}">
                                                        {{
                                                        appt.type }}</div>
                                                    <div
                                                        [ngStyle]="{'background-color': setColorAttr(appt.statusColor)}">
                                                        {{ appt.status && (appt.status.toLowerCase() == 'nada' ||
                                                        appt.status.toLowerCase()
                                                        == 'nothing') ? '': appt.status }}</div>
                                                </td>

                                                <td class="apptTablTd" style="text-align: center; font-size: 80%;"
                                                    [ngStyle]="{'color': (appt.selected ? '#FFFFFF': '#000000'), 
                                                'background-color': (appt.selected ? '#6e5582': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF'))}"
                                                    (click)="selectAppointment(appt);">
                                                    {{ appt.sex }}
                                                </td>

                                                <td class="apptTablTd" style="text-align: center; font-size: 80%;"
                                                    [ngStyle]="{'color': (appt.selected ? '#FFFFFF': '#000000'), 
                                                'background-color': (appt.selected ? '#6e5582': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF'))}"
                                                    (click)="selectAppointment(appt);">
                                                    {{ appt.age }}
                                                </td>

                                                <td class="apptTablTd" style="text-align: center;"
                                                    [ngStyle]="{'color': (appt.selected ? '#FFFFFF': '#000000'), 
                                            'background-color': (appt.selected ? '#6e5582': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF'))}"
                                                    (click)="selectAppointment(appt);">
                                                    <a *ngIf='idx % 2 == 0 ? true: false' href="#"
                                                        style="-webkit-text-stroke: 2px #e8e8e8; -webkit-filter: hue-rotate(180deg); filter: hue-rotate(180deg);"></a>
                                                    <a *ngIf='idx % 2 == 0 ? false: true' href="#"
                                                        style="-webkit-text-stroke: 2px #FFFFFF; -webkit-filter: hue-rotate(180deg); filter: hue-rotate(180deg);"></a>
                                                    <a (click)="deleteApptPrompt(appt.pKey);">
                                                        <i class="far fa-calendar-times"
                                                            style="color: red; cursor: pointer;"></i>
                                                    </a>
                                                </td>

                                                <td class="apptTablTd" style="text-align: center;"
                                                    [ngStyle]="{'color': (appt.selected ? '#FFFFFF': '#000000'), 
                                                'background-color': (appt.selected ? '#6e5582': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF'))}"
                                                    (click)="selectAppointment(appt);">
                                                    <input type="checkbox" [checked]="appt.eligib" />
                                                </td>

                                                <td class="apptTablTd" style="text-align: center;"
                                                    [ngStyle]="{'color': (appt.selected ? '#FFFFFF': '#000000'), 
                                                'background-color': (appt.selected ? '#6e5582': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF'))}"
                                                    (click)="selectAppointment(appt);">
                                                    <i class="fas fa-pencil-alt" (click)="editAppt(appt.pKey)"
                                                        style="color: black; cursor: pointer;"></i>
                                                </td>

                                                <td class="apptTablTd" style="text-align: center;"
                                                    [ngStyle]="{'color': (appt.selected ? '#FFFFFF': '#000000'), 
                                                'background-color': (appt.selected ? '#6e5582': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF'))}"
                                                    (click)="selectAppointment(appt);">
                                                    <input type="checkbox" [checked]="appt.hidden"
                                                        (change)="hideAppointment(appt);" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- appointment table -->

                                <div class="rowBox" style="align-items: center; align-content: none;">
                                    <div class="btn-group mt-2">
                                        <label class="btn active btn-primary btn-xs">
                                            <input class="btn" type="radio"
                                                style="margin-top: 0; vertical-align: middle;"
                                                name="apptViewOptionsBottom" id="showNotHiddenBottom" autocomplete="off"
                                                checked
                                                (click)="showHiddenAll($event.target.id, $event.target.checked);"
                                                ngbTooltip="{{ engLang ? 'Click to show only appointments still waiting': 'Marque para solo mostrar citas no atendidas' }}" />
                                            <span style="font-size: x-small"> {{ engLang ? 'Waiting': 'En espera' }} {{
                                                waitingApptsCnt }} </span>
                                        </label>
                                        <label class="btn btn-primary btn-xs">
                                            <input class="btn" type="radio"
                                                style="margin-top: 0; vertical-align: middle;"
                                                name="apptViewOptionsBottom" id="showAllBottom" autocomplete="off"
                                                (click)="showHiddenAll($event.target.id, $event.target.checked);"
                                                ngbTooltip="{{ engLang ? 'Click to show both appointments still waiting and finished': 'Marque para mostrar citas no atendidas y terminadas' }}" />
                                            <span style="font-size: x-small"> {{ engLang ? 'All': 'Todos' }} {{
                                                waitingApptsCnt + finishedApptsCnt }} </span>
                                        </label>
                                    </div>

                                    <div class="mt-3" style="font-size: smaller;">
                                        <pagination-controls id="agendaAppts" (pageChange)="currentPg = $event"
                                            directionLinks="false" previousLabel="" nextLabel="" autoHide="true">
                                        </pagination-controls>
                                    </div>

                                    <div class="btn-group">
                                        <button class="btn active btn-succes btn-xs"
                                            style="margin-top: 0; vertical-align: middle;" name="apptActionMnuBottom"
                                            id="searchApptToggleBotom" autocomplete="off"
                                            (click)="open(apptSearchParmsModal, { backdrop: 'static', size: 'md', scrollable: true }, 'apptSearchParmsModal');"
                                            ngbTooltip="{{ engLang ? 'Click to search for appointments': 'Clic para buscar citas' }}">
                                            <i class="fa fa-search"></i>
                                            <span style="font-size: x-small"> {{ engLang ? 'Search': 'Buscar' }} </span>
                                        </button>
                                        <button class="btn btn-success btn-xs"
                                            style="margin-top: 0; vertical-align: middle;" name="apptActionMnuBottom"
                                            id="newApptBottom" autocomplete="off" (click)="newAppt();"
                                            ngbTooltip="{{ engLang ? 'Click to add a new appointment': 'Clic para añadir cita nueva' }}">
                                            <i class="fa fa-file-o"></i>
                                            <span style="font-size: x-small"> {{ engLang ? 'New Appt.': 'Cita Nueva'
                                                }}</span>
                                        </button>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <!-- resource row ............................................................................................ -->
                                <div class="table-responsive apptTabWrapper">
                                    <table id="rescTabl" class="table table-bordered unselectable">
                                        <!-- resource table -->
                                        <thead class="stickyTabHead" style="background-color: #e8dec0;">
                                            <tr>
                                                <th class="noRtBorder noBtBorder hover2GrayBkg"
                                                    style="cursor: default; text-align: left;">
                                                    ID
                                                </th>
                                                <th class="noBtBorder noLtBorder noRtBorder hover2GrayBkg"
                                                    style="cursor: default; text-align: left;">
                                                    {{ engLang ? 'Name': 'Nombre' }}
                                                </th>
                                                <th>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody style="cursor: default; text-align: left; font-size: 80%;">
                                            <tr *ngFor="let resc of apptsRescs; let idx = index;"
                                                [ngStyle]="{'color': (resc.selected ? (resc.selected ? '#FFFFFF': '#000000'): '#000000'), 
                                                'background-color': (resc.selected ? '#6e5582': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF'))}">
                                                <td (click)="selectResource($event, $event.target.innerHTML);">
                                                    {{ resc.pKey }}
                                                </td>
                                                <td
                                                    (click)="selectResource($event, $event.target.parentNode.cells[0].innerHTML);">
                                                    {{ resc.name }}
                                                </td>
                                                <td
                                                    (click)="selectResource($event, $event.target.parentNode.cells[0].innerHTML);">
                                                    <img src="{{ resourceImageBase64(resc.image) }}"
                                                        alt="{{ resc.name }}  {{ engLang ? 'image': 'imágen'}}"
                                                        (click)="selectResource($event, $event.target.parentNode.parentNode.cells[0].innerHTML);">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- resource table -->
                            </td>

                        </tr>
                    </tbody>

                </table>
            </div>
            <!-- main table -->

            <!-- <pre>appts | json</pre> -->
            <!-- <pre>{{ appts | json }}</pre> -->
            <!-- <pre>{{ sn }}</pre> -->

        </div>

        <!-- modal search form ................................................................................................................ -->
        <ng-template #apptSearchParmsModal let-modal let-c="close" let-d="dismiss">
            <div class="modal-header" style="background-color: #E6E6FA;" (keyup)="escapeApptEditModal($event)">
                <div class="col-md-15" style="margin-top: -5px;">
                    <i class="fa fa-search"></i>
                    {{ engLang ? 'Scheduled Appointment Search': 'Búsqueda Citas Programadas'}}
                </div>
                <a class="close" style="padding-top: 0; margin-top: -10px;" (click)="modal.dismiss();">&times;</a>
                <!-- x upper right corner to close -->
            </div>

            <div class="modal-body">
                <fieldset>
                    <div>
                        <label for="apptNmSrch" style="font-size: 75%; margin: 5px 0 0 10px; padding-bottom: 0;"
                            [ngStyle]="{ 'color': labelDefaultClassForegColr }">
                            {{ engLang ? 'Patient\'s Name or appointment subject': 'Nombre paciente o asunto
                            cita' }}
                        </label>
                        <div class="input-group">
                            <input type="text" id="apptNmSrch" class="form-control input-lg" style="font-size: 90%;"
                                placeholder="{{engLang ? 'Last name [,] first name': 'Apellidos [,] nombre' }}"
                                ngbTooltip="{{engLang ? 'Last name [,] first name': 'Apellidos [,] nombre' }}"
                                maxlength="50" (keyup)="srchKeyPressEventHndlr($event);">
                            <div class="input-group-btn">
                                <a class="btn btn-link" (click)="getSearchResults('apptNmSrch', 'summary');">
                                    <i class="fas fa-search"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div>
                        <label for="apptTelSrch" style="font-size: 75%; margin: 5px 0 0 10px; padding-bottom: 0;"
                            [ngStyle]="{ 'color': labelDefaultClassForegColr }">
                            {{ engLang ? 'Telehone number': 'Número teléfono' }}
                        </label>
                        <div class="input-group">
                            <input type="text" id="apptTelSrch" class="form-control input-lg" style="font-size: 90%;"
                                placeholder="{{ engLang ? 'Telehone number': 'Número teléfono' }}"
                                ngbTooltip="{{ engLang ? 'Telehone number': 'Número teléfono' }}"
                                (keyup)="srchKeyPressEventHndlr($event);">
                            <div class="input-group-btn">
                                <a id="apptTelSrch0" class="btn btn-link input-lg"
                                    (click)="getSearchResults('apptTelSrch', 'tel');">
                                    <i class="fas fa-search"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div>
                        <label for="apptRecSrch" style="font-size: 75%; margin: 5px 0 0 10px; padding-bottom: 0;"
                            [ngStyle]="{ 'color': labelDefaultClassForegColr }">
                            {{ engLang ? 'Record No.': 'No. Record' }}
                        </label>
                        <div class="input-group">
                            <input type="text" id="apptRecSrch" class="form-control input-lg" style="font-size: 90%;"
                                placeholder="{{ engLang ? 'Record number': 'Número record' }}"
                                ngbTooltip="{{ engLang ? 'Record number': 'Número record' }}"
                                (keyup)="srchKeyPressEventHndlr($event);">
                            <div class="input-group-btn">
                                <a id="apptRecSrch0" class="btn btn-link input-lg"
                                    (click)="getSearchResults('apptRecSrch', 'rec');">
                                    <i class="fas fa-search"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div *ngIf="waiting4ResponseSrch" style="text-align: center;">
                    <span style="display: inline-block;">
                        <img src="app/assets/images/waiting4Response.gif" width="42" height="42">
                    </span>
                </div>

                <div *ngIf="apptsSearched && apptsSearched.length > 0">
                    <table id="apptSrchTabl" class="table table-responsive table-hover table-sm unselectable">
                        <thead style="font-size: 80%; position: sticky;">
                            <tr>
                                <th style="display: none;">
                                    pKey
                                </th>
                                <th>
                                    {{ engLang ? 'Date': 'Fecha' }}
                                </th>
                                <th>
                                    {{ engLang ? 'Patient/Subject': 'Paciente/Asunto' }}
                                </th>
                                <th>
                                    {{ engLang ? 'Tel': 'Tel' }}
                                </th>
                                <th>
                                    {{ engLang ? 'Rec': 'Rec' }}
                                </th>
                                <th>
                                    {{ engLang ? 'Resource': 'Recurso' }}
                                </th>
                            </tr>
                        </thead>
                        <tbody style="height: 400px; overflow-y: auto; overflow-x: hidden; cursor: pointer;">
                            <tr *ngFor="let apptSearched of apptsSearched 
                                | paginate: { id: 'agendaSrchResults', itemsPerPage: 6, currentPage: currentSrchResultsPg }; let idx = index;"
                                style="font-size: 77%;"
                                [ngStyle]="{'color': (idx % 2 == 0 ? '#000000': '#000000'), 'background-color': (idx % 2 == 0 ? '#fcfcfc': '#FFFFFF')}"
                                (click)="srchTablSelect($event);">
                                <td style="display: none;">
                                    {{ apptSearched.srchApptPKey }}
                                </td>
                                <td>
                                    {{ apptSearched.srchApptDt }}
                                </td>
                                <td>
                                    {{ apptSearched.srchApptDesc }}
                                </td>
                                <td>
                                    {{ apptSearched.srchApptTel }}
                                </td>
                                <td>
                                    {{ apptSearched.srchApptRec }}
                                </td>
                                <td>
                                    {{ apptSearched.srchApptRescNm }}
                                </td>
                                <td style="display: none;">
                                    {{ apptSearched.srchApptRescPKey }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="modal-footer rowBox" style="justify-content: space-between;"
                style="background-color: #E6E6FA; font-size: 70%; margin-top: 20px; align-items: center; align-content: none; flex-wrap: nowrap;">
                <div style="font-size: smaller;">
                    {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
                </div>
                <div class="mt-3" style="font-size: smaller;">
                    <pagination-controls id="agendaSrchResults" (pageChange)="currentSrchResultsPg = $event"
                        directionLinks="true" previousLabel="" nextLabel="" autoHide="true">
                    </pagination-controls>
                </div>
                <div style="font-size: smaller;">
                    <button id="closeSrchModal" type="button" class="btn btn-secondary btn-sm"
                        (click)="modal.dismiss();">
                        {{ engLang ? 'Close': 'Cerrar' }}
                    </button>
                </div>
            </div>
        </ng-template>

        <!-- modal form to add/edit an appointment ...................................................................................... -->
        <ng-template #apptEditModal let-modal let-c="close" let-d="dismiss">

            <div class="modal-header p-0" style="padding: 0; margin: 0; background-color: rgb(240,240,240);"
                (keyup)="escapeApptEditModal($event)">

                <table class="table table-sm my-0 py-0" (keyup)="escapeApptEditModal($event)">
                    <tr>
                        <td valign="center" width="50%">
                            <div>
                                <span *ngIf="appt.pKey == '0'">
                                    <img src="app/assets/images/newAppt.png" alt="New Appointment" height="25"
                                        width="25">
                                    {{ engLang ? 'New Appointment': 'Cita Nueva' }}
                                </span>
                                <span *ngIf="!(appt.pKey == '0')">
                                    <img src="app/assets/images/editAppt.png" alt="Edit Appointment" height="25"
                                        width="25">
                                    {{ engLang ? 'Existing Appointment': 'Cita Establecida' }}
                                </span>
                            </div>
                        </td>
                        <td valign="center" width="50%">
                            <div *ngIf="waiting4ResponseNewEditAppt" style="text-align: center;">
                                <span style="display: inline-block;">
                                    <img src="app/assets/images/waiting4Response.gif" alt="Waiting for response"
                                        width="42" height="42">
                                </span>
                            </div>
                            <div *ngIf="apptSavedOk" style="text-align: center;">
                                <span style="display: inline-block;">
                                    <img src="app/assets/images/green-check-mark-txparent-backg-20x20.png"
                                        alt="Saved Ok" width="42" height="42">
                                </span>
                            </div>
                        </td>
                    </tr>
                </table>

                <!-- x upper right corner to close -->
                <div style="margin-right: 13px;">
                    <button type="button" class="btn btn-sm btn-close" style="cursor: pointer; font-size: 75%;"
                        (click)="modal.close('x_closeApptEditModal')"></button>
                </div>
            </div>

            <div class="modal-body my-0 py-0">
                <table class="table table-sm table-responsive m-0 p-0">
                    <tr>
                        <td class="my-0 py-0">
                            <div class="text-center">
                                <label for="apptDate" style="font-size: 75%; margin: 0; padding: 0;"
                                    [ngStyle]="{'color': labelDefaultClassForegColr}">
                                    {{ engLang ? 'Date': 'Fecha' }}
                                </label>
                                <form class="form-inline">
                                    <div class="form-group">
                                        <input type="date" id="apptDate" class="form-control form-control-sm radius25"
                                            style="font-size: 80%; display: inline;" [value]="apptDtpSelected" (change)="apptDtpSelected = $event.target.value; 
                                            document.body.classList.add('user-is-tabbing');">
                                    </div>
                                </form>
                            </div>
                        </td>
                        <td class="my-0 py-0">
                            <div class="form-group">
                                <label for="apptDuration" style="font-size: 75%; margin: 0; padding: 0;"
                                    [ngStyle]="{'color': (durationErr ? 'red': labelDefaultClassForegColr)}">
                                    {{ engLang ? 'Duration': 'Duración' }}
                                </label>
                                <select type="text" id="apptDuration" name="duration"
                                    class="form-control form-control-sm radius25 text-center"
                                    style="font-size: 75%; margin: 0; padding-right: 0;"
                                    ngbTooltip="{{engLang ? 'Duration': 'Duración' }}" [(ngModel)]="selectedDuration"
                                    (ngModelChange)="selectOptionEvent('duration', $event)">
                                    <option *ngFor="let d of apptDurationMins; let idx = index;" dir="rtl"
                                        style="font-size: 75%; margin: 0; padding: 0;"
                                        [ngStyle]="{'background-color': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF')}"
                                        [selected]="d === selectedDuration" [ngValue]="d">
                                        {{d}}
                                    </option>
                                </select>
                            </div>
                        </td>
                        <td class="my-0 py-0">
                            <div class="form-group text-center">
                                <label for="apptResource" style="font-size: 75%; margin: 0; padding: 0;"
                                    [ngStyle]="{'color': (resourceErr ? 'red': labelDefaultClassForegColr)}">
                                    {{ engLang ? 'Resource': 'Recurso' }}
                                </label>

                                <select type="text" id="apptResource"
                                    class="show-tick form-control form-control-sm radius25"
                                    (ngModelChange)="selectOptionEvent('resource', $event);"
                                    [(ngModel)]="selectedResourceID"
                                    [ngStyle]="{'color': (selectedResourceColor ? getBestContrast(selectedResourceColor): '#000000'), 'background-color': (selectedResourceColor ? setColorAttr(selectedResourceColor): '#FFFFFF')}"
                                    style="font-size: 80%;" name="resource"
                                    ngbTooltip="{{engLang ? 'Resource': 'Recurso' }}">
                                    <option *ngFor="let resc of apptsRescs; let idx = index;" style="font-size: 75%;"
                                        [ngStyle]="{'color': (resc.selected ? (resc.selected ? '#FFFFFF': '#000000'): '#000000'), 'background-color': (resc.selected ? '#6e5582': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF'))}"
                                        [selected]="resc.selected === true" [ngValue]="resc.pKey">
                                        {{ resc.name }}
                                        <span style="font-size: small;">{{ resc.pKey }}</span>
                                    </option>
                                </select>
                            </div>
                        </td>
                        <td class="my-0 py-0">
                            <div class="form-group text-center">
                                <label for="apptTime" style="font-size: 75%; margin: 0; padding: 0;"
                                    [ngStyle]="{'color': (timeErr ? 'red': labelDefaultClassForegColr)}">
                                    {{ engLang ? 'Time': 'Hora' }}
                                </label>
                                <select type="text" id="apptTime" name="times"
                                    class="form-control form-control-sm radius25" dir="rtl" [(ngModel)]="selectedTime"
                                    ngbTooltip="{{engLang ? 'Time': 'Hora' }}"
                                    style="padding: 0; margin: 0; font-size: 80%;">
                                    <option *ngFor="let t of availableTimes; let idx = index;"
                                        [selected]="t.availableTimes == currApptTime"
                                        [ngStyle]="{'color': '#000000', 'background-color': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF')}"
                                        style="font-size: 75%;">
                                        {{ t.availableTimes }}
                                    </option>
                                </select>
                            </div>
                        </td>
                    </tr>
                </table>

                <div class="form-group">

                    <div class="text-center">
                        <label for="apptPatSrchParam" class="control-label" style="font-size: 75%;">
                            {{ engLang ? 'Last name (optional [,] plus first) or record no. or
                            tel. to find patient:':
                            'Apellidos (opcional [,] y nombre) o no. record o tel. para buscar
                            paciente:' }}
                        </label>
                    </div>

                    <div>
                        <input type="text" id="apptPatSrchParam" style="cursor: pointer;" #apptPatSrchParam
                            class="form-control form-control-sm radius25 placeholder"
                            placeholder="{{engLang ? '...to find patient...': '...para buscar paciente...' }}"
                            ngbTooltip="{{engLang ? 'Last name (optional [,] plus first) or record no. or tel. to find patient': 'Apellidos (opcional [,] y nombre) o no. record o tel. para buscar paciente' }}"
                            maxlength="50" (keyup.enter)="$event.stopPropagation(); pats4apptDropdown.open();"
                            (keyup)="srchPatientKeyUpEventHndlr($event);">
                    </div>

                    <div style="height: 20px;">
                        <div ngbDropdown #pats4apptDropdown="ngbDropdown" placement="auto">
                            <button ngbDropdownToggle id="findPat" type="button"
                                class="btn btn-default form-control form-control-sm radius25"
                                (click)="click2Find(apptPatSrchParam.id, apptPatSrchParam.value);">
                                <i ngbDropdownToggle class="fa fa-search"></i>
                                {{ engLang ? 'Find Patient': 'Buscar Paciente'}} <span class="caret"></span>
                            </button>
                            <ul ngbDropdownMenu class="form-control form-control-sm radius25 unselectable"
                                style="height: 400px; overflow: auto; background-color: rgb(240,240,240);">
                                <li *ngFor="let p of patientsFound4Appt; let idx = index;" class="radius25"
                                    style="padding: 0 10px 0 10px;" (click)="selectedPatient4Appt($event.target,idx);"
                                    [ngStyle]="{'color': '#000000', 'background-color': (idx % 2 == 0 ? '#f2e6ff': '#FFFFFF')}">
                                    <!-- #e8e8e8 light gray -->
                                    <a ngbDropdownItem style="cursor: pointer;"
                                        (click)="selectedPatient4Appt($event.target,idx);">
                                        <span>{{ p.fullNm }}</span>
                                        <span style="display: none;">{{ p.patID }}</span>
                                        <span style="font-size: smaller; float: right;">
                                            {{ p.recNo }}
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="text-center" style="font-size: 65%; margin-top: 10px; padding: 0; height: 10px;">
                        <span>
                            {{ engLang ? 'Record No.: ': 'Record No.: ' }} {{ setPatRecNo() }}
                        </span>
                    </div>

                    <!-- subject/summary -->
                    <div class="form-row">
                        <label for="apptSummary" class="control-label p-0" style="font-size: 75%; margin: 0 0 0 10px;"
                            [ngStyle]="{ 'color': (patLastNmErr ? 'red': labelDefaultClassForegColr) }">
                            {{ engLang ? 'Subject': 'Asunto' }}
                        </label>
                        <input type="text" id="apptSummary" name="apptSummary"
                            class="form-control form-control-sm radius25 placeholder"
                            style="font-weight: bold; margin-top: -5px; cursor: text;"
                            placeholder="{{engLang ? 'Enter patient\'s last & first names below or subject here': 'Entre apellidos y nombre paciente abajo o asunto aquí' }}"
                            maxlength="50" [(ngModel)]="appt.summary"
                            [ngStyle]="{ 'background-color': (!appt.summary || !appt2.summary ? '#FFFFFF': (appt.summary.trim() != appt2.summary.trim() ? '#ffeecc': '#FFFFFF')) }">
                    </div>

                    <!-- last, first names -->
                    <div class="form-row">
                        <div class="input-group">
                            <div class="input-group-append col-sm-6">
                                <label for="apptPatLastNm" class="control-label"
                                    style="font-size: 75%; margin: 0 0 0 10px; padding-bottom: 0;"
                                    [ngStyle]="{ 'color': (patLastNmErr ? 'red': labelDefaultClassForegColr) }">
                                    {{ engLang ? 'Last Name': 'Apellidos' }}
                                </label>
                                <input type="text" id="apptPatLastNm" name="apptPatLastNm"
                                    class="radius25Lt form-control form-control-sm" style="margin: -5px 0 0 0;"
                                    placeholder="{{engLang ? 'Last name': 'Apellidos' }}" maxlength="50"
                                    [(ngModel)]="appt.patLastNm" (keyup)="srchPatientKeyUpEventHndlr($event);">
                            </div>

                            <span class="input-group-text col-sm-1 text-center" style="height: 31px; margin-top: 16px;">
                                <i *ngIf="appt.sex=='F'" class="fa fa-female"
                                    ngbTooltip="{{ engLang ? 'Female': 'Femenino'}}"></i>
                                <i *ngIf="appt.sex=='M'" class="fa fa-male"
                                    ngbTooltip="{{ engLang ? 'Male': 'Masculino'}}"></i>
                            </span>

                            <div class="input-group-append col-sm-5">
                                <label for="apptPatFirstNm" class="control-label"
                                    style="font-size: 75%; margin: 0 0 0 10px; padding-bottom: 0;"
                                    [ngStyle]="{ 'color': (patFirstNmErr ? 'red': labelDefaultClassForegColr) }">
                                    {{ engLang ? 'First Name': 'Nombre' }}
                                </label>
                                <input type="text" id="apptPatFirstNm" name="apptPatFirstNm"
                                    class="radius25Rt form-control form-control-sm" style="margin: -5px 0 0 0;"
                                    placeholder="{{engLang ? 'First name': 'Nombre' }}" maxlength="50"
                                    [(ngModel)]="appt.patFirstNm" (keyup)="srchPatientKeyUpEventHndlr($event);">
                            </div>
                        </div>
                    </div>

                    <!-- dob, gender, email -->
                    <div class="form-row">

                        <div class="input-group" style="margin: -px 0 0 0;">
                            <div class="input-group-append col-md-3">
                                <label for="patDOB" id="patDOBlbl" class="control-label"
                                    style="font-size: 75%; margin: 5px 0 0 6px; padding-bottom: 0;"
                                    [ngStyle]="{ 'color': (patDOBErr ? 'red': labelDefaultClassForegColr) }">
                                    {{ engLang ? 'Birthdate': 'Nacimiento' }}
                                </label>
                                <input type="text" id="patDOB" name="patDOB" style="margin: -5px 0 0 0;"
                                    class="radius25Lt form-control form-control-sm"
                                    placeholder='{{engLang ? "Birthdate (month/day/year)": "(mes/día/año)"}}'
                                    ngbTooltip='{{engLang ? "Birthdate (month/day/year)": "Nacimiento (mes/día/año)"}}'
                                    [(ngModel)]="appt.patDOB" (focusout)="focusOutJqryElement($event);">
                            </div>

                            <span class="input-group-text col-md-1 text-center"
                                style="font-size: 75%; height: 31px; margin-top: 20px;"
                                ngbTooltip="{{ engLang ? 'Age': 'Edad' }}">
                                {{ appt.age }}
                            </span>

                            <div class="input-group-append col-md-2" style="margin-top: -5px;">
                                <label for="radioM" class="control-label"
                                    style="font-size: 75%; margin: 10px 0 0 20px; padding: 0; height: 5px;"
                                    [ngStyle]="{ 'color': (patSexErr ? 'red': labelDefaultClassForegColr) }">
                                    {{ engLang ? 'Gender': 'Género' }}
                                </label>
                                <div class="form-control form-control-sm"
                                    style="display: flex; flex-direction: row; justify-content: space-evenly; height: 31px; margin: -4px 0 0 0;">
                                    <div class="form-check form-check-inline p-0" style="margin: 0 0 0 16px;">
                                        <label for="radioM" class="form-check-label"
                                            style="margin: 0 0 0 7px; font-size: 75%;">
                                            M
                                        </label>
                                        <input type="radio" id="radioM" name="sexRadio" class="form-check-input"
                                            style="margin: -17px 0 0 -10px;"
                                            ngbTooltip="{{ engLang ? 'Male': 'Masculino' }}" [checked]="appt.sex=='M'"
                                            (click)="apptSexChange($event);">
                                    </div>
                                    <div class="form-check form-check-inline p-0" style="margin: 0 10px 0 24px;">
                                        <label for="radioF" class="form-check-label"
                                            style="margin: 0 0 0 7px; font-size: 75%;">
                                            F
                                        </label>
                                        <input type="radio" id="radioF" name="sexRadio" class="form-check-input"
                                            style="margin: -17px 0 0 -9px;"
                                            ngbTooltip="{{ engLang ? 'Female': 'Femenino' }}" [checked]="appt.sex=='F'"
                                            (click)="apptSexChange($event);">
                                    </div>
                                </div>
                            </div>

                            <div class="input-group-append col-sm-6">
                                <label for="patEmail" class="control-label" style="font-size: 75%; margin: 5px 0 0 6px;"
                                    [ngStyle]="{ 'color': (patEmailErr ? 'red': labelDefaultClassForegColr) }">
                                    Email
                                </label>
                                <input type="text" id="patEmail" name="patEmail" style="margin: -5px 0 0 0;"
                                    class="form-control form-control-sm radius25Rt" placeholder='Email'
                                    [(ngModel)]="appt.patEmail">
                            </div>
                        </div>
                    </div>

                    <!-- cell, home, work tels -->
                    <div class="form-row">
                        <label for="patTelCell" class="control-label p-0" style="font-size: 75%; margin: 0 0 0 10px;"
                            [ngStyle]="{ 'color': (patTelCellErr ? 'red': labelDefaultClassForegColr) }">
                            {{ engLang ? 'Cell Phone': 'Tel Celular' }}
                        </label>
                        <label for="patTelWork" class="control-label p-0" style="font-size: 75%; margin: 0 0 0 98px;"
                            [ngStyle]="{ 'color': (patTelWorkErr ? 'red': labelDefaultClassForegColr) }">
                            {{ engLang ? 'Work Phone': 'Tel Trabajo' }}
                        </label>
                        <label for="patTelHome" class="control-label p-0" style="font-size: 75%; margin: 0 0 0 102px;"
                            [ngStyle]="{ 'color': (patTelHomeErr ? 'red': labelDefaultClassForegColr) }">
                            {{ engLang ? 'Home Phone': 'Tel Casa' }}
                        </label>

                        <div class="input-group" style="margin: -5px 0 0 0;">
                            <div class="input-group-append col-sm-3">
                                <input type="text" id="patTelCell" name="patTelCell"
                                    class="radius25Lt form-control form-control-sm"
                                    placeholder='{{engLang ? "Cell Phone": "Tel Celular"}}'
                                    [(ngModel)]="appt.patTelCell" (focusout)="focusOutJqryElement($event);">
                            </div>

                            <span class="input-group-text col-sm-1" style="height: 31px;">
                            </span>

                            <div class="input-group-append col-sm-3">
                                <input type="text" id="patTelWork" name="patTelWork"
                                    class="form-control form-control-sm"
                                    placeholder='{{engLang ? "Work Phone": "Tel Trabajo"}}'
                                    [(ngModel)]="appt.patTelWork" maskPattern="(999) 999-9999"
                                    (focusout)="focusOutJqryElement($event);">
                            </div>

                            <span class="input-group-text col-sm-1" style="height: 31px;">
                            </span>

                            <div class="input-group-append col-sm-4">
                                <input type="text" id="patTelHome" name="patTelHome"
                                    class="form-control form-control-sm radius25Rt"
                                    placeholder='{{engLang ? "Home Phone": "Tel Casa"}}' [(ngModel)]="appt.patTelHome"
                                    (focusout)="focusOutJqryElement($event);">
                            </div>
                        </div>
                    </div>

                    <!-- Location = complaint -->
                    <div class="form-row">
                        <div class="form-group col-sm-12">
                            <label for="apptLocation" class="control-label" style="font-size: 75%; margin: 0 0 0 10px;"
                                [ngStyle]="{ 'color': labelDefaultClassForegColr }">
                                {{ engLang ? 'Will be shown on roster ': 'Se leerá en la lista' }}
                            </label>
                            <input type="text" id="apptLocation" name="apptLocation" style="margin: -4px 0 0 0;"
                                class="form-control form-control-sm radius25"
                                placeholder='{{engLang ? "Chief complaint": "Queja"}}'
                                ngbTooltip='{{engLang ? "Chief complaint": "Queja"}}' [(ngModel)]="appt.complaint">
                        </div>
                    </div>

                    <!-- appointment staus & type drop downs -->
                    <div class="form-row" style="display: flex; flex-direction: row; justify-content: space-between;">
                        <div ngbDropdown class="col-sm-6">
                            <button ngbDropdownToggle id="selType" type="button"
                                class="btn btn-secondary btn-sm form-control form-control-sm radius25 unselectable"
                                style="margin-top: 5px;">
                                {{ engLang ? 'Appointment Type': 'Tipo Cita'}}
                            </button>
                            <div ngbDropdownMenu class="unselectable" style="overflow: auto; padding: 0 10px 0 10px;">
                                <a ngbDropdownItem class="radius25 form-control form-control-sm"
                                    style="padding: 5px 10px 5px 10px; cursor: pointer;"
                                    *ngFor="let t of _help.apptTypes; let idx = index;"
                                    (click)="selectedType_Status('type', $event.target);"
                                    [ngStyle]="{'color': '#000000', 'background-color': (idx % 2 == 0 ? '#f2e6ff': '#FFFFFF')}">
                                    <span>
                                        {{ t.type }}
                                    </span>
                                    <span style="font-size: smaller; float: right;">
                                        {{ t.ID }}
                                    </span>
                                    <span style="display: none">
                                        {{ t.color }}
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div ngbDropdown class="col-sm-6">
                            <div>
                                <button ngbDropdownToggle id="selStatus" type="button"
                                    class="btn btn-secondary btn-sm form-control form-control-sm radius25 unselectable"
                                    style="margin-top: 5px;">
                                    {{ engLang ? 'Status': 'Status'}}
                                </button>
                                <div ngbDropdownMenu class="unselectable"
                                    style="overflow: auto; padding: 0 10px 0 10px;">
                                    <!-- #e8e8e8 light gray -->
                                    <a ngbDropdownItem class="radius25 form-control form-control-sm"
                                        style="padding: 5px 10px 5px 10px; cursor: pointer;"
                                        *ngFor="let s of _help.apptStatuses; let idx = index;"
                                        (click)="selectedType_Status('status', $event.target);"
                                        [ngStyle]="{'color': '#000000', 'background-color': (idx % 2 == 0 ? '#f2e6ff': '#FFFFFF')}">
                                        <span>
                                            {{ s.status }}
                                        </span>
                                        <span style="font-size: smaller; float: right;">
                                            {{ s.ID }}</span>
                                        <span style="display: none">
                                            {{ s.color }}
                                        </span>
                                        <span style="display: none">
                                            {{ s.hatch }}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- appointment staus & type selections -->
                    <div class="form-row">
                        <div class="input-group">
                            <div class="col-sm-5">
                                <input type="text" name="apptType"
                                    class="radius25Lt form-control form-control-sm unselectable"
                                    style="margin-left: 2px;"
                                    placeholder='{{engLang ? "Appointment Type": "Tipo Cita"}}'
                                    ngbTooltip='{{engLang ? "Appointment Type": "Tipo Cita"}}' [(ngModel)]="appt.type"
                                    [ngStyle]="{'color': getBestContrast(appt.typeColor), 'background-color': setColorAttr(appt.typeColor)}"
                                    (click)="onClick_activateDropDown($event,'selType','')">
                            </div>
                            <div class="col-sm-2 input-group-text">
                                <i class="fa fa-trash px-1" id="clearTp"
                                    ngbTooltip="{{ engLang ? 'Clear Type': 'Borrar Tipo' }}"
                                    (click)="clearTypeStatus($event.target);"></i>

                                <i class="fa fa-trash px-3" id="clearSt"
                                    ngbTooltip="{{ engLang ? 'Clear Status': 'Borrar Status' }}"
                                    (click)="clearTypeStatus($event.target);"></i>
                            </div>
                            <div class="col-sm-5">
                                <input type="text" name="apptStatus"
                                    class="radius25Rt form-control form-control-sm unselectable"
                                    style="margin-right: 2px;" placeholder='{{engLang ? "Status": "Status"}}'
                                    ngbTooltip='{{engLang ? "Appointment Status": "Status Cita"}}'
                                    [(ngModel)]="appt.status"
                                    [ngStyle]="{'color': getBestContrast(appt.statusColor), 'background-color': setColorAttr(appt.statusColor)}"
                                    [ngClass]="{
                                                                        'hatched': appt.statusHatch
                                                                      }"
                                    (click)="onClick_activateDropDown($event,'selStatus','')">
                            </div>
                        </div>
                    </div>

                    <!-- insurance 1 -->
                    <div class="form-row">
                        <label for="insAlias1" class="control-label" style="font-size: 75%; margin: 0 0 0 10px;"
                            [ngStyle]="{ 'color': (insId1Err ? 'red': labelDefaultClassForegColr) }">
                            {{ engLang ? 'Primary Insurance': 'Seguro Primario' }}
                        </label>

                        <div class="input-group" style="margin: -4px 0 0 0;">
                            <label for="insId1" class="control-label radius25Lt"
                                style="font-size: smaller; padding: 8px; height: 31px;"
                                [ngStyle]="{ 'background-color': (insId1Err ? 'red': labelDefaultClassForegColr) }">
                                ID
                            </label>
                            <input type="text" id="insId1" name="insId1"
                                class="input-group-append form-control form-control-sm px-1 col-sm-1"
                                style="font-size: smaller; height: 31px;" placeholder="ID-1"
                                ngbTooltip="{{engLang ? 'Insurance 1 ID': 'Seguro 1 ID' }}" [(ngModel)]="appt.insId1"
                                (focus)="clearInsuranceLstSelected();"
                                (keydown)="onKeyDown_insurance_dd_EventHndlr($event,true,'1');"
                                (keyup)="onKeyUp_insurance_dd_EventHndlr($event,true,'1');">

                            <span class="input-group-append form-control form-control-sm col-xs-1 text-center"
                                ngbTooltip="{{ engLang ? 'Clear Insurance 1': 'Borrar Seguro 1' }}"
                                style="background-color: #ecf9ec; display: inline; height: 31px;">
                                <i class="fa fa-trash" (click)="clearInsurance('1')"></i>
                            </span>

                            <div class="input-group-append" style="margin: -17px 0 0 0;">
                                <label for="insAlias1" class="control-label" style="font-size: 75%; margin: 0 0 0 10px;"
                                    [ngStyle]="{ 'color': (insId1Err ? 'red': labelDefaultClassForegColr) }">
                                    Alias
                                </label>
                                <input type="text" id="insAlias1" name="insAlias1" autocomplete="off" #insAlias1
                                    class="form-control form-control-sm col-sm-9"
                                    style="width: 286px; height: 31px; margin: -4px 0 0 0;"
                                    placeholder="{{engLang ? 'Insurance 1 alias': 'Seguro 1 alias' }}"
                                    [(ngModel)]="appt.insAlias1"
                                    (focus)="prepareLoad_InsuranceLstSelected_fromVal(undefined,'insAlias1');"
                                    (keydown)="onKeyDown_insurance_dd_EventHndlr($event, false, '1');"
                                    (keyup)="onKeyUp_insurance_dd_EventHndlr($event,false,'1');">
                            </div>

                            <!-- insurance 1 drop down -->
                            <div ngbDropdown placement="auto" #selIns1="ngbDropdown" style="height: 31px;">

                                <button id="selIns1" #btnIns2 ngbDropdownToggle
                                    class="input-group-append form-control form-control-sm radius25Rt"
                                    style="background-color: #ecf9ec; height: 31px;"
                                    ngbTooltip="{{ engLang ? 'Click for insurance list': 'Clic para listado seguros' }}"
                                    (click)="onClick_activateDropDown($event, 'selIns1', '1');"
                                    (focus)="onFocus_ddToggleBtn($event);" (keypress)="onKeyPress($event)">
                                    <i class="fa fa-search"></i>
                                </button>

                                <div ngbDropdownMenu class="unselectable dropdown-menu dropdown-menu-start"
                                    style="overflow-y: auto; max-height: 500px;" tabindex="-1">
                                    <a ngbDropdownItem *ngFor="let ins of insuranceLstSelected; let idx = index;"
                                        class="radius25 unselectable" style="cursor: pointer; padding: 0 10px 0 10px;"
                                        [ngStyle]="{'color': '#000000', 'background-color': (idx % 2 == 0 ? '#f2e6ff': '#FFFFFF')}"
                                        (click)="onClick_selectInsurance($event.target, ins);">
                                        <span style="font-size: 75%;">
                                            {{ ins.alias }}
                                        </span>
                                        <span style="font-size: smaller; float: right;">
                                            {{ ins.pKey }}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Contract & group 1 -->
                    <div class="form-row">
                        <div class="input-group">
                            <div class="input-group-append col-sm-6">
                                <label for="patContr1" class="control-label" style="font-size: 75%; margin-left: 10px;"
                                    [ngStyle]="{ 'color': labelDefaultClassForegColr }">
                                    {{ engLang ? 'Contract No. (1)': 'No. Contrato (1)' }}
                                </label>
                                <input type="text" id="patContr1" name="patContr1" style="margin: -5px 0 0 0;"
                                    class="form-control form-control-sm radius25Lt"
                                    placeholder="{{engLang ? 'Contract No. 1': 'No. Contrato 1' }}" maxlength="50"
                                    [(ngModel)]="appt.patContr1">
                            </div>

                            <span class="input-group-text col-sm-1" style="height: 31px; margin-top: 16px;">
                                <i class="fa fa-umbrella p-0 m-0 text-center"
                                    ngbTooltip="{{ engLang ? 'Eligible 2': 'Elegible 2' }}"></i>
                            </span>

                            <div class="input-group-append col-sm-5">
                                <label for="patGrp1" class="control-label" style="font-size: 75%; margin: 0 0 0 10px;"
                                    [ngStyle]="{ 'color': labelDefaultClassForegColr }">
                                    {{ engLang ? 'Group No. (1)': 'No. Grupo (1)' }}
                                </label>
                                <input type="text" name="patGrp1" style="margin: -5px 0 0 0;"
                                    class="form-control form-control-sm radius25Rt"
                                    placeholder="{{engLang ? 'Grupo No. 1': 'No. Grupo 1' }}" maxlength="50"
                                    [(ngModel)]="appt.patGrp1">
                            </div>
                        </div>
                    </div>

                    <!-- insurance 2 -->
                    <div class="form-row">
                        <label for="insAlias2" class="control-label" style="font-size: 75%; margin: 0 0 0 10px;"
                            [ngStyle]="{ 'color': (insId2Err ? 'red': labelDefaultClassForegColr) }">
                            {{ engLang ? 'Secondary Insurance': 'Seguro Secundario' }}
                        </label>

                        <div class="input-group" style="margin: -4px 0 0 0;">
                            <label for="insId2" class="control-label radius25Lt"
                                style="font-size: smaller; padding: 8px; height: 31px;"
                                [ngStyle]="{ 'background-color': (insId2Err ? 'red': labelDefaultClassForegColr) }">
                                ID
                            </label>
                            <input type="text" id="insId2" name="insId2"
                                class="input-group-append form-control form-control-sm px-1 col-sm-1"
                                style="font-size: smaller; height: 31px;" placeholder="ID-2"
                                ngbTooltip="{{engLang ? 'Insurance 2 ID': 'Seguro 2 ID' }}" [(ngModel)]="appt.insId2"
                                (focus)="clearInsuranceLstSelected();"
                                (keydown)="onKeyDown_insurance_dd_EventHndlr($event,true,'2');"
                                (keyup)="onKeyUp_insurance_dd_EventHndlr($event,true,'2');">

                            <span class="input-group-append form-control form-control-sm col-xs-1 text-center"
                                ngbTooltip="{{ engLang ? 'Clear Insurance 2': 'Borrar Seguro 2' }}"
                                style="background-color: #ecf9ec; display: inline; height: 31px;">
                                <i class="fa fa-trash" (click)="clearInsurance('2')"></i>
                            </span>

                            <div class="input-group-append" style="margin: -17px 0 0 0;">
                                <label for="insAlias2" class="control-label" style="font-size: 75%; margin: 0 0 0 10px;"
                                    [ngStyle]="{ 'color': (insId2Err ? 'red': labelDefaultClassForegColr) }">
                                    Alias
                                </label>
                                <input type="text" id="insAlias2" name="insAlias2" autocomplete="off" #insAlias2
                                    class="form-control form-control-sm col-sm-9"
                                    style="width: 286px; height: 31px; margin: -4px 0 0 0;"
                                    placeholder="{{engLang ? 'Insurance 2 alias': 'Seguro 2 alias' }}"
                                    [(ngModel)]="appt.insAlias2"
                                    (focus)="prepareLoad_InsuranceLstSelected_fromVal(undefined,'insAlias2');"
                                    (keydown)="onKeyDown_insurance_dd_EventHndlr($event, false, '2');"
                                    (keyup)="onKeyUp_insurance_dd_EventHndlr($event,false,'2');">
                            </div>

                            <!-- insurance 2 drop down -->
                            <div ngbDropdown placement="auto" #selIns2="ngbDropdown" style="height: 31px;">

                                <button id="selIns2" #btnIns2 ngbDropdownToggle
                                    class="input-group-append form-control form-control-sm radius25Rt"
                                    style="background-color: #ecf9ec; height: 31px;"
                                    ngbTooltip="{{ engLang ? 'Click for insurance list': 'Clic para listado seguros' }}"
                                    (click)="onClick_activateDropDown($event, 'selIns2', '2');"
                                    (focus)="onFocus_ddToggleBtn($event);" (keypress)="onKeyPress($event)">
                                    <i class="fa fa-search"></i>
                                </button>

                                <div ngbDropdownMenu class="unselectable dropdown-menu dropdown-menu-start"
                                    style="overflow-y: auto; max-height: 500px;" tabindex="-1">
                                    <a ngbDropdownItem *ngFor="let ins of insuranceLstSelected; let idx = index;"
                                        class="radius25 unselectable" style="cursor: pointer; padding: 0 10px 0 10px;"
                                        [ngStyle]="{'color': '#000000', 'background-color': (idx % 2 == 0 ? '#f2e6ff': '#FFFFFF')}"
                                        (click)="onClick_selectInsurance($event.target, ins);">
                                        <span style="font-size: 75%;">
                                            {{ ins.alias }}
                                        </span>
                                        <span style="font-size: smaller; float: right;">
                                            {{ ins.pKey }}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Contract & group 2 -->
                    <div class="form-row">
                        <div class="input-group">
                            <div class="input-group-append col-sm-6">
                                <label for="patContr2" class="control-label" style="font-size: 75%; margin-left: 10px;"
                                    [ngStyle]="{ 'color': labelDefaultClassForegColr }">
                                    {{ engLang ? 'Contract No. (2)': 'No. Contrato (2)' }}
                                </label>
                                <input type="text" id="patContr2" name="patContr2" style="margin: -5px 0 0 0;"
                                    class="form-control form-control-sm radius25Lt" style="margin-top: -5px;"
                                    placeholder="{{engLang ? 'Contract No. 2': 'No. Contrato 2' }}" maxlength="50"
                                    [(ngModel)]="appt.patContr2">
                            </div>

                            <span class="input-group-text col-sm-1" style="height: 31px; margin-top: 16px;">
                                <i class="fa fa-umbrella p-0 m-0 text-center"
                                    ngbTooltip="{{ engLang ? 'Eligible 2': 'Elegible 2' }}"></i>
                            </span>

                            <div class="input-group-append col-sm-5">
                                <label for="patGrp2" class="control-label" style="font-size: 75%; margin: 0 0 0 10px;"
                                    [ngStyle]="{ 'color': labelDefaultClassForegColr }">
                                    {{ engLang ? 'Group No. (2)': 'No. Grupo (2)' }}
                                </label>
                                <input type="text" name="patGrp2" class="form-control form-control-sm radius25Rt"
                                    style="margin-top: -5px;" placeholder="{{engLang ? 'Grupo No. 2': 'No. Grupo 2' }}"
                                    maxlength="50" [(ngModel)]="appt.patGrp2">
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="modal-footer rowBox my-1 py-0"
                style="justify-content: space-between; background-color: rgb(240,240,240);">
                <div>
                    <button id="closeAppt" type="button" class="btn btn-secondary"
                        (click)="modal.close('closeApptEditModal');">
                        {{ engLang ? 'Esc - Close': 'Esc - Cerrar' }}

                        <!-- <div> -->
                        <!-- <pre>{{ appt | json }}</pre> -->
                        <!-- <pre>{{ appt2 | json }}</pre> -->
                        <!-- </div> -->

                    </button>
                </div>
                <div *ngIf="apptSavedOk" style="text-align: center;">
                    <span style="display: inline-block;">
                        <img src="app/assets/images/green-check-mark-txparent-backg-20x20.png" alt="Saved Ok" width="42"
                            height="42">
                    </span>
                </div>
                <div>
                    <button id="saveNewEditAppt" type="button" class="btn btn-success" (click)="save();">
                        {{ engLang ? 'Save': 'Grabar' }}
                    </button>
                </div>
            </div>

            <!-- <pre>{{ selectedTime }}</pre> -->
            <!-- <pre>{{ 'insId1Err=' + insId1Err }}</pre> -->
            <!-- <pre>{{ currApptTime }}</pre> -->
            <!-- <pre>{{ availableTimes ? availableTimes[availableTimes.length -1].availableTimes: '' }}</pre> -->
            <!-- <pre>{{ availableTimes }}</pre> -->
            <!-- <pre>{{ availableTimes | json }}</pre> -->
            <!-- <pre>{{ appts | json }}</pre> -->
            <!-- <pre>{{ 'appt.pKey=' + appt.pKey }}</pre> -->
            <!-- <pre>{{ 'appt.statusHatch=' + appt.statusHatch }}</pre> -->
            <!-- <pre>{{ waiting4ResponseNewEditAppt }}</pre> -->

            <!-- <pre>{{ 'selectedTime=' + selectedTime }}</pre> -->

            <!-- <pre>{{ modalStack.length }}</pre> -->
            <!-- <pre>{{ modalStack }}</pre> -->
            <!-- <pre>{{ apptDtpSelected }}</pre> -->
            <!-- <pre>{{ insuranceLstSelected | json }} </pre> -->

        </ng-template>

        <!-- modal apptEdit exit w/o saving ................................................................................... -->
        <ng-template #apptExitWoSaveModal let-modal>
            <div class="modal-header" style="background-color:  #d6deec;">
                <div style="margin-top: -5px">
                    <span *ngIf="appt.pKey == '0'">
                        <img src="app/assets/images/newAppt.png" alt="New Appointment" height="25" width="25">
                        {{ engLang ? 'New Appointment': 'Cita Nueva' }}
                    </span>
                    <span *ngIf="!(appt.pKey == '0')">
                        <img src="app/assets/images/editAppt.png" alt="Edit Appointment" height="25" width="25">
                        {{ engLang ? 'Existing Appointment Edited': 'Cita Establecida Editada' }}
                    </span>
                </div>
            </div>

            <div class="modal-body mx-0 my-0 px-0 py-0">
                <div class="col-xs-12 text-center">
                    <span class="text-center" style="font-size: large;">
                        {{ engLang ? 'Exit without saving?': '¿Salir sin grabar?' }}
                    </span>
                </div>
            </div>

            <div class="modal-footer rowBox" style="background-color: #d6deec; justify-content: space-between;">
                <div>
                    <button id="yesBtn" type="button" class="btn btn-success"
                        (click)="modal.close('yes_apptExitWoSaveModal')">
                        {{ engLang ? 'Yes': 'Si' }}
                    </button>
                </div>
                <div>
                    <button id="noBtn" type="button" class="btn btn-secondary"
                        (click)="modal.close('no_apptExitWoSaveModal');">
                        No
                    </button>
                </div>
            </div>

            <!-- <pre>{{ modalStack.length }}</pre>
            <pre>{{ modalStack }}</pre> -->

        </ng-template>

        <!-- modal appt Delete  .......................................................................................... -->
        <ng-template #apptDeleteModal let-modal>
            <div class="modal-header" style="background-color: orange;">
                <h4>{{ engLang ? 'Delete appointment ?': '¿ Eliminar la cita ?' }}</h4>
            </div>

            <div class="modal-body">
                <div class="text-center">
                    <h4 class="text-center" style="font-size: large;">
                        {{ appt2.summary }}
                    </h4>
                    <div id="apptDelDtTm">

                    </div>
                </div>
            </div>

            <div class="modal-footer rowBox" style="background-color: orange;">
                <div>
                    <button id="yesBtn" type="button" class="btn btn-success"
                        (click)="modal.close('yes_apptDeleteModal')">
                        {{ engLang ? 'Yes': 'Si' }}
                    </button>
                </div>
                <div>
                    <button id="noBtn" type="button" class="btn btn-secondary"
                        (click)="modal.dismiss('no_apptDeleteModal');">
                        No
                    </button>
                </div>
            </div>

            <!-- <pre>{{ modalStack.length }}</pre>
            <pre>{{ modalStack }}</pre> -->

        </ng-template>

        <!-- modal message box  .......................................................................................... -->
        <ng-template #msgBoxModal let-modal>
            <div id="msgBoxModalHead" class="modal-header rowBox"
                style="background-color: lightcoral; flex-direction: column; align-items: center;">
                <!-- <div ></div> -->
            </div>

            <div class="modal-body">
                <div class="text-left ml-3">
                    <div id="msgBoxModalBody"></div>
                </div>
            </div>

            <div class="modal-footer" style="background-color: lightcoral;">
                <div class="ml-3">
                    <button id="msgBoxModalOkBtn" type="button" class="btn btn-success" (click)="modal.dismiss()">
                        Ok
                    </button>
                </div>
            </div>

            <!-- <pre>{{ modalStack.length }}</pre>
            <pre>{{ modalStack }}</pre> -->

        </ng-template>

        <!-- modal record/case ................................................................................... -->
        <ng-template #recordCaseModal let-modal>
            <div class="modal-header p-8" style="background-color: #ecead6;">
                <div>
                    Record: <strong>{{ patNmModalHd }}</strong> <br />
                    {{ engLang ? 'Claim: ': 'Caso: ' }}{{ casNoModalHd }}
                </div>
                <div>
                    <button type="button" class="btn btn-sm btn-close" style="margin-top: -30px;"
                        (click)="modal.dismiss('recordCaseModal')"></button>
                </div>
            </div>

            <div class="modal-body" #modalBody>
                <!-- <pre>
                    {{appts[currApptIdx] | json}}
                </pre> -->

                <app-record [activeTab]="2" xtPID={{appts[currApptIdx].patID}} [xtCID]="'0'"
                    xtLastNm={{appts[currApptIdx].patLastNm}} xtFirstNm={{appts[currApptIdx].patFirstNm}}
                    xtMidInit={{appts[currApptIdx].patMidNm}} xtSex={{appts[currApptIdx].sex}}
                    xtDob={{appts[currApptIdx].patDOB}} xtInsId1={{appts[currApptIdx].insId1}}
                    xtContr1={{appts[currApptIdx].patContr1}} xtGroup1={{appts[currApptIdx].patGrp1}}
                    xtInsId2={{appts[currApptIdx].insId2}} xtContr2={{appts[currApptIdx].patContr2}}
                    xtGroup2={{appts[currApptIdx].patGrp2}} xtEmail={{appts[currApptIdx].patEmail}}
                    xtTel={{appts[currApptIdx].patTelHome}} xtCell={{appts[currApptIdx].patTelCell}}
                    xtTelWk={{appts[currApptIdx].patTelWork}} xtRecNo="{{appts[currApptIdx].recNo}}"
                    xtRecOffice="{{appts[currApptIdx].recOffice}}" xtRecYr="{{appts[currApptIdx].recYr}}"
                    (setNewRecordNoEvent)="setNewRecordNo($event)">
                </app-record>
            </div>

            <div class="modal-footer d-sm-inline-flex justify-content-between p-8" style="background-color: #ecead6;">
                <div>
                    {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
                </div>
                <div>
                    <button type="button" class="btn btn-sm btn-outline-dark" *ngIf="!waiting4Response"
                        (click)="modal.close('oK_recordCaseModal')">{{ engLang ? 'Close': 'Cerrar' }}</button>
                </div>
            </div>

        </ng-template>

    </div>
</div>