import { Pipe, PipeTransform } from '@angular/core';
import { Imc } from 'src/app/shared/interfaces/matchCandidate';

@Pipe({
    name: 'mcFilterPipe',
    standalone: false
})
export class McFilterPipe implements PipeTransform {

  transform(input: Imc[], param: string, balZero: boolean, npi: string, payerId: string): Imc[] {
    if (!input) {
      return input;
    }

    return (input.filter(mc => {
      return (
        mc.mcParam == param
        && ((!balZero && mc.mcBalance && +mc.mcBalance !== 0) || (balZero && mc.mcBalance && +mc.mcBalance === 0))
        && ((!npi || (npi && npi == mc.mcNpi))
          || (!payerId || (payerId && payerId == mc.mcPayerId))
        )
      )
    })
    );
  }

}
