<div class="container">

    <div>

        <!-- <pre>
                    * payment-form.component.html *
                    userValidLogin = {{ userValidLogin }}
                    payPal = {{ payPal }}
                    submitBtnDisabled = {{ submitBtnDisabled }}
                    customer.sn = {{ customer.sn }}
                    customer.companyNm = {{ customer.companyNm }}
				    customer = {{ customer | json }}
				    newChargOrders = {{ newChargOrders | json }}
				    serviceItemPrices = {{ serviceItemPrices | json }}
				    payment = {{ payment | json }}
				    engLang = {{ engLang | json }}
                </pre> -->

        <fieldset>
            <div class="form-group" style="margin-bottom: 0;">
                <label for="sn" style="font-size: 70%; margin-bottom: 0;">{{ "SN:" }}
                </label>

                <popper-content (popperOnShown)="showPopr" placement="bottom">
                    <i class="fas fa-question-circle" (click)="onClick_showPopr()"></i>
                    <div content>
                        <img src="app/assets/images/MBDesktopSplashSN.png" width="400" height="200">
                    </div>
                </popper-content>

                &nbsp;
                <span style="font-size: 70%; font-weight: bold;">{{
                    customer.companyNm }}</span>

                <input type="text" id="sn" name="sn" class="form-control input-sm"
                    style="font-size: 80%; margin-bottom: 0;"
                    placeholder='{{engLang ? "Serial Number": "Número de Serie"}}'
                    title='{{engLang ? "Serial Number": "Número de Serie"}}' maxlength="15" [(ngModel)]="customer.sn"
                    [disabled]="userValidLogin || payPal" (blur)="getCustomerFromSN($event);"
                    (keyup)="keyupOnGetCustomerFromSN($event);">
                <div *ngIf="snErr" class="alert alert-danger">
                    {{engLang ? "Invalid SN": "Número de Serie inválido"}}
                </div>
            </div>

            <!-- ACH -->

            <div *ngIf="type == 'ach'">
                <div class="form-group" style="margin-bottom: 0;">
                    <label for="achBankNm" style="font-size: 70%; margin-bottom: 0;">{{ engLang ? "Bank
                        Name": "Nombre del Banco" }}</label>
                    <input type="text" id="achBankNm" name="achBankNm" class="form-control input-sm"
                        style="font-size: 80%; margin-bottom: 0;"
                        placeholder='{{engLang ? "Bank Name": "Nombre del Banco"}}'
                        title='{{engLang ? "Bank Name": "Nombre del Banco"}}' maxlength="50" [(ngModel)]="ach.bankNm"
                        [disabled]="ccDisabled">
                    <div *ngIf="achBankNmErr" class="alert alert-danger">
                        {{engLang ? "Invalid Bank Name": "Nombre del Banco inválido"}}
                    </div>
                </div>

                <div class="form-group" style="margin-bottom: 0;">
                    <label for="achBankBr" style="font-size: 70%; margin-bottom: 0;">{{ engLang ? "Bank
                        Branch": "Sucursal" }}</label>
                    <input type="text" id="achBankBr" name="achBankBr" class="form-control input-sm"
                        style="font-size: 80%; margin-bottom: 0;" placeholder='{{engLang ? "Bank Branch": "Sucursal"}}'
                        title='{{engLang ? "Bank Branch": "Sucursal"}}' maxlength="50" [(ngModel)]="ach.bankBr"
                        [disabled]="ccDisabled">
                    <div *ngIf="achBankBrErr" class="alert alert-danger">
                        {{engLang ? "Invalid Bank Branch": "Nombre Sucursal inválido"}}
                    </div>
                </div>

                <div class="form-group" style="margin-bottom: 0;">
                    <label for="achAcctNm" style="font-size: 70%; margin-bottom: 0;">{{ engLang ?
                        "Account
                        Holder": "Nombre en la Cuenta" }}</label>
                    <input type="text" id="achAcctNm" name="achAcctNm" class="form-control input-sm"
                        style="font-size: 80%; margin-bottom: 0;"
                        placeholder='{{engLang ? "Account Holder": "Nombre en la Cuenta"}}'
                        title='{{engLang ? "Account Holder": "Nombre en la Cuenta"}}' maxlength="50"
                        [(ngModel)]="ach.acctNm" [disabled]="ccDisabled">
                    <div *ngIf="achAcctNmErr" class="alert alert-danger">
                        {{engLang ? "Invalid Account Holder Name": "Nombre en la Cuenta inválido"}}
                    </div>
                </div>

                <div class="form-group" style="margin-bottom: 0;">
                    <label for="achAcctAddr1" style="font-size: 70%; margin-bottom: 0;">{{ engLang ?
                        "Account Holder Address 1": "Dirección en la Cuenta 1" }}</label>
                    <input type="text" id="achAcctAddr1" name="achAcctAddr1" class="form-control input-sm"
                        style="font-size: 80%; margin-bottom: 0;"
                        placeholder='{{engLang ? "Account Holder Address 1": "Dirección en la Cuenta 1"}}'
                        title='{{engLang ? "Account Holder Address 1": "Dirección en la Cuenta 1"}}' maxlength="50"
                        [(ngModel)]="ach.acctAddr1" [disabled]="ccDisabled">
                    <div *ngIf="achAcctAddr1Err" class="alert alert-danger">
                        {{engLang ? "Invalid Address": "Dirección inválida"}}
                    </div>
                </div>

                <div class="form-group" style="margin-bottom: 0;">
                    <label for="achAcctAddr2" style="font-size: 70%; margin-bottom: 0;">{{ engLang ?
                        "Account Holder Address 2": "Dirección en la Cuenta 2" }}</label>
                    <input type="text" id="achAcctAddr2" name="achAcctAddr2" class="form-control input-sm"
                        style="font-size: 80%; margin-bottom: 0;"
                        placeholder='{{engLang ? "Account Holder Address 2": "Dirección en la Cuenta 2"}}'
                        title='{{engLang ? "Account Holder Address 2": "Dirección en la Cuenta 2"}}' maxlength="50"
                        [(ngModel)]="ach.acctAddr2" [disabled]="ccDisabled">
                    <div *ngIf="achAcctAddr2Err" class="alert alert-danger">
                        {{engLang ? "Invalid Address": "Dirección inválida"}}
                    </div>
                </div>

                <div class="form-group" style="margin-bottom: 0;">
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="achAcctZip" type="text" style="font-size: 70%; margin-bottom: 0;">
                                {{ engLang ? "Zip Code": "Código Postal (Zip)" }}</label>
                        </div>
                        <div class="col-sm-6">
                            <label for="achAcctCity" style="font-size: 70%; margin-bottom: 0;">
                                {{ engLang ? "City": "Ciudad" }}</label>
                        </div>
                    </div>
                    <div class="input-group">
                        <input type="text" id="achAcctZip" name="achAcctZip" class="form-control input-sm"
                            style="font-size: 80%; margin-bottom: 0;"
                            placeholder='{{engLang ? "Zip Code": "Código Postal (Zip)"}}'
                            title='{{engLang ? "Zip Code": "Código Postal (Zip)"}}' maxlength="50"
                            [disabled]="ccDisabled" jqueryMaskededit maskPattern="99999-?9999" [value]="ach.acctZip"
                            (blur)="ach.acctZip = $event.target.value; getCityStFromZip(ach.acctZip,'acctZip');">
                        <div *ngIf="achAcctZipErr" class="alert alert-danger">
                            {{engLang ? "Invalid Zip Code": "Código Postal inválido"}}
                        </div>
                        <span class="input-group-addon"></span>
                        <input type="text" id="achAcctCity" name="achAcctCity" class="form-control input-sm"
                            style="font-size: 80%; margin-bottom: 0;" placeholder='{{engLang ? "City": "Ciudad"}}'
                            title='{{engLang ? "City": "Ciudad"}}' maxlength="50" [(ngModel)]="ach.acctCity"
                            [disabled]="ccDisabled">
                        <div *ngIf="achAcctCityErr" class="alert alert-danger">
                            {{engLang ? "Invalid City": "Ciudad inválida"}}
                        </div>
                    </div>
                </div>

                <div class="form-group" style="margin-bottom: 0;">
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="achAcctEmail" type="text" style="font-size: 70%; margin-bottom: 0;">
                                {{ engLang ? "Email Address": "Correo Electrónico" }}</label>
                        </div>
                        <div class="col-sm-6">
                            <label for="achAcctTel" style="font-size: 70%; margin-bottom: 0;">
                                {{ engLang ? "Phone": "Teléfono" }}</label>
                        </div>
                    </div>
                    <div class="input-group">
                        <input type="email" id="achAcctEmail" name="achAcctEmail" class="form-control input-sm"
                            style="font-size: 80%; margin-bottom: 0;"
                            placeholder='{{engLang ? "Email Address": "Correo Electrónico"}}'
                            title='{{engLang ? "Email Address": "Correo Electrónico"}}' maxlength="50"
                            [(ngModel)]="ach.acctEmail" [disabled]="ccDisabled">
                        <div *ngIf="achAcctEmailErr" class="alert alert-danger">
                            {{engLang ? "Invalid Email Address": "Correo Electrónico inválido"}}
                        </div>
                        <span class="input-group-addon"></span>
                        <input type="text" id="achAcctTel" name="achAcctTel" class="form-control input-sm"
                            style="font-size: 80%; margin-bottom: 0;" placeholder='{{engLang ? "Phone": "Teléfono"}}'
                            title='{{engLang ? "Phone": "Teléfono"}}' jqueryMaskededit maskPattern="(999) 999-9999"
                            [value]="ach.acctTel" (blur)="ach.acctTel = $event.target.value" [disabled]="ccDisabled">
                        <div *ngIf="achAcctTelErr" class="alert alert-danger">
                            {{engLang ? "Phone (10 digits)": "Teléfono (10 dígitos)"}}
                        </div>
                    </div>
                </div>

                <div class="form-group" style="margin-bottom: 0;">
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="achBankAcctNo" type="text" style="font-size: 70%; margin-bottom: 0;">
                                {{ engLang ? "Account No.": "No. de Cuenta" }}</label>
                        </div>
                        <div class="col-sm-6">
                            <label for="achAcctRtNo" style="font-size: 70%; margin-bottom: 0;">
                                {{ engLang ? "Bank Route No.": "No. de Ruta" }}</label>
                        </div>
                    </div>
                    <div class="input-group">
                        <input type="text" id="achBankAcctNo" name="achBankAcctNo" class="form-control input-sm"
                            style="font-size: 80%; margin-bottom: 0;"
                            placeholder='{{engLang ? "Account No.": "No. de Cuenta"}}'
                            title='{{engLang ? "Account No.": "No. de Cuenta"}}' maxlength="50" [(ngModel)]="ach.acctNo"
                            [disabled]="ccDisabled">
                        <div *ngIf="achAcctNoErr" class="alert alert-danger">
                            {{engLang ? "Invalid Account No.": "No. Cuenta inválido"}}
                        </div>
                        <span class="input-group-addon"></span>
                        <input type="text" id="achAcctRtNo" name="achAcctRtNo" class="form-control input-sm"
                            style="font-size: 80%; margin-bottom: 0;"
                            placeholder='{{engLang ? "Bank Route No.": "No. de Ruta"}}'
                            title='{{engLang ? "Bank Route No.": "No. de Ruta"}}' maxlength="50"
                            [(ngModel)]="ach.bankRtNo" [disabled]="ccDisabled">
                        <div *ngIf="achBankRtNoErr" class="alert alert-danger">
                            {{engLang ? "Invalid Bank Route No.": "No. Ruta inválido"}}
                        </div>
                    </div>
                </div>
            </div>

            <!-- Credit Card -->

            <div *ngIf="type == 'cc'">
                <div class="form-group" style="margin-bottom: 0;">
                    <label for="ccName" style="font-size: 70%; margin-bottom: 0;">{{ engLang ? "Name on
                        Credit Card": "Nombre en la Tarjeta de Crédito" }}</label>
                    <input type="text" id="ccName" name="ccName" class="form-control input-sm"
                        style="font-size: 80%; margin-bottom: 0;"
                        placeholder='{{engLang ? "Name on Credit Card": "Nombre en la Tarjeta de Crédito"}}'
                        title='{{engLang ? "Name on Credit Card": "Nombre en la Tarjeta Crédito"}}' maxlength="50"
                        [(ngModel)]="cc.name" [disabled]="achDisabled || payPal">
                    <div *ngIf="ccNameErr" class="alert alert-danger">
                        {{engLang ? "Invalid Character or Name on Credit Card": "Nombre en la Tarjeta de
                        Crédito o Caracter inválido"}}
                    </div>
                </div>

                <div *ngIf=false class="form-group" style="margin-bottom: 0;">
                    <label for="ccNumber" style="font-size: 70%; margin-bottom: 0;">{{ engLang ? "Credit
                        Card No.": "No. Tarjeta Crédito" }}</label>
                    <input type="text" id="ccNumber" name="ccNumber" class="form-control input-sm"
                        style="font-size: 80%; margin-bottom: 0;"
                        placeholder='{{engLang ? "Credit Card No.": "No. Tarjeta Crédito"}}'
                        title='{{engLang ? "Credit Card No.": "No. Tarjeta Crédito"}}'
                        [disabled]="achDisabled || payPal" jqueryMaskededit maskPattern="9999-9999-9999-999?9"
                        [value]="cc.number" (blur)="cc.number = $event.target.value">
                    <div *ngIf="ccNumberErr" class="alert alert-danger">
                        {{engLang ? "Invalid Credit Card No.": "No. Tarjeta de Crédito inválido"}}
                    </div>
                </div>

                <div *ngIf=false class="form-group" style="margin-bottom: 0;">
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="ccExp" type="text" style="font-size: 70%; margin-bottom: 0;">
                                {{ engLang ? 'Expiration (month/year)': 'Expiración (mes/año)'
                                }}</label>
                        </div>
                        <div class="col-sm-6">
                            <label for="ccCvv" style="font-size: 70%; margin-bottom: 0;">
                                {{ engLang ? 'Card Verification Value (CVV)': 'Verificación Tarjeta
                                (CVV)' }}</label>
                        </div>
                    </div>
                    <div class="input-group">
                        <input type="text" id="ccExp" name="ccExp" class="form-control input-sm"
                            style="float: left; font-size: 80%; margin-bottom: 0;"
                            placeholder='{{engLang ? "Expiration (month/year)": "Expiración (mes/año)"}}'
                            title='{{engLang ? "Expiration (month/year)": "Expiración (mes/año)"}}'
                            [disabled]="achDisabled || payPal" jqueryMaskededit maskPattern="99/99" [value]="cc.exp"
                            (blur)="cc.exp = $event.target.value">
                        <div *ngIf="ccExpErr" class="alert alert-danger">
                            {{engLang ? "Invalid Expiration - Month / Year": "Expiración inválida - Mes
                            /
                            Año"}}
                        </div>
                        <span class="input-group-addon"></span>
                        <input type="text" id="ccCvv" name="ccCvv" class="form-control input-sm"
                            style="float: left; font-size: 80%; margin-bottom: 0;"
                            placeholder='{{engLang ? "Card Verification Value (CVV)": "Verificación Tarjeta (CVV)"}}'
                            title='{{engLang ? "CVV": "Verificación Tarjeta (CVV)"}}' [disabled]="achDisabled || payPal"
                            jqueryMaskededit maskPattern="999?9" [value]="cc.cvv" (blur)="cc.cvv = $event.target.value">
                        <div *ngIf="ccCvvErr" class="alert alert-danger">
                            {{engLang ? "Invalid CVV": "Código CVV inválido"}}
                        </div>
                    </div>
                </div>

                <div class="form-group" style="margin-bottom: 0;">
                    <label for="ccBillAddr1" style="font-size: 70%; margin-bottom: 0;">{{ engLang ?
                        "Billing Statement Address 1": "Dirección Estado Cuenta 1" }}</label>
                    <input type="text" id="ccBillAddr1" name="ccBillAddr1" class="form-control input-sm"
                        style="font-size: 80%; margin-bottom: 0;"
                        placeholder='{{engLang ? "Billing Statement Address 1": "Dirección Estado Cuenta 1"}}'
                        title='{{engLang ? "Billing Statement Address 1": "Dirección Estado Cuenta 1"}}' maxlength="50"
                        [(ngModel)]="cc.billAddr1" [disabled]="achDisabled || payPal">
                    <div *ngIf="ccBillAddr1Err" class="alert alert-danger">
                        {{engLang ? "Invalid Account Address 1": "Dirección Cuenta 1 inválida"}}
                    </div>
                </div>

                <div class="form-group" style="margin-bottom: 0;">
                    <label for="ccBillAddr2" style="font-size: 70%; margin-bottom: 0;">{{ engLang ?
                        "Billing Statement Address 2": "Dirección Estado Cuenta 2" }}</label>
                    <input type="text" id="ccBillAddr2" name="ccBillAddr2" class="form-control input-sm"
                        style="font-size: 80%; margin-bottom: 0;"
                        placeholder='{{engLang ? "Billing Statement Address 2": "Dirección Estado Cuenta 2"}}'
                        title='{{engLang ? "Billing Statement Address 2": "Dirección Estado Cuenta 2"}}' maxlength="50"
                        [(ngModel)]="cc.billAddr2" [disabled]="achDisabled || payPal">
                    <div *ngIf="ccBillAddr2Err" class="alert alert-danger">
                        {{engLang ? "Invalid Account Address 2": "Dirección Cuenta 2 inválida"}}
                    </div>
                </div>

                <div class="form-group" style="margin-bottom: 0;">
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="ccBillZip" style="font-size: 70%; margin-bottom: 0;">
                                {{ engLang ? "Zip Code": "Código Postal (Zip)" }}</label>
                        </div>
                        <div class="col-sm-6">
                            <label for="ccBillCity" style="font-size: 70%; margin-bottom: 0;">
                                {{ engLang ? "City": "Ciudad" }}</label>
                        </div>
                    </div>
                    <div class="input-group">
                        <input type="text" id="ccBillZip" name="ccBillZip" class="form-control input-sm"
                            style="font-size: 80%; margin-bottom: 0;"
                            placeholder='{{engLang ? "Zip Code": "Código Postal (Zip)"}}'
                            title='{{engLang ? "Zip Code": "Código Postal (Zip)"}}' [disabled]="achDisabled || payPal"
                            jqueryMaskededit maskPattern="99999-?9999" [value]="cc.billZip"
                            (blur)="cc.billZip = $event.target.value; getCityStFromZip(cc.billZip,'billZip');">
                        <div *ngIf="ccBillZipErr" class="alert alert-danger">
                            {{engLang ? "Invalid Zip Code": "Código Postal inválido"}}
                        </div>
                        <span class="input-group-addon"></span>
                        <input type="text" id="ccBillCity" name="ccBillCity" class="form-control input-sm"
                            style="font-size: 80%; margin-bottom: 0;" placeholder='{{engLang ? "City": "Ciudad"}}'
                            title='{{engLang ? "City": "Ciudad"}}' maxlength="50" [disabled]="achDisabled || payPal"
                            [(ngModel)]="cc.billCity">
                        <div *ngIf="ccBillCityErr" class="alert alert-danger">
                            {{engLang ? "Invalid City": "Ciudad inválida"}}
                        </div>
                    </div>
                </div>

                <div class="form-group" style="margin-bottom: 0;">
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="ccBillEmail" style="font-size: 70%; margin-bottom: 0;">
                                {{ engLang ? "Email Address": "Correo Electrónico" }}</label>
                        </div>
                        <div class="col-sm-6">
                            <label for="ccBillTel" style="font-size: 70%; margin-bottom: 0;">
                                {{ engLang ? "Phone": "Teléfono" }}</label>
                        </div>
                    </div>
                    <div class="input-group">
                        <input type="email" id="ccBillEmail" class="form-control input-sm"
                            style="font-size: 80%; margin-bottom: 0;"
                            placeholder='{{engLang ? "Email Address": "Correo Electrónico"}}'
                            title='{{engLang ? "Email Address": "Correo Electrónico"}}' maxlength="50"
                            [(ngModel)]="cc.billEmail" [disabled]="achDisabled || payPal">
                        <div *ngIf="ccBillEmailErr" class="alert alert-danger">
                            {{engLang ? "Invalid Email Address": "Correo Electrónico inválido"}}
                        </div>
                        <span class="input-group-addon"></span>
                        <input type="text" id="ccBillTel" name="ccBillTel" class="form-control input-sm"
                            style="font-size: 80%; margin-bottom: 0;" placeholder='{{engLang ? "Phone": "Teléfono"}}'
                            title='{{engLang ? "Phone": "Teléfono"}}' jqueryMaskededit maskPattern="(999) 999-9999"
                            [value]="cc.billTel" (blur)="cc.billTel = $event.target.value"
                            [disabled]="achDisabled || payPal">
                        <div *ngIf="ccBillTelErr" class="alert alert-danger">
                            {{engLang ? "Phone (10 digits)": "Teléfono (10 dígitos)"}}
                        </div>
                    </div>
                </div>
            </div>

        </fieldset>

        <!-- Charge Table -->

        <div class="form-group" style="margin-top: 5px;">

            <table class="table-responsive"
                style="margin: 0 auto !important; float: none !important; border: 1px solid black;">
                <thead style="background-color: #d9e7e5;">
                    <tr>
                        <td style="padding: 5px;">
                            <img src="../app/assets/images/green-check-mark-txparent-backg-20x20.png" alt="Select"
                                width="10" height="10" class="black">
                        </td>
                        <td style="padding: 5px; font-size: 85%;">
                            {{engLang ? "Qty": "Cant"}}
                        </td>
                        <td style="padding: 5px; font-size: 85%; text-align: center;" class="absorbing-column">
                            {{engLang ? "Description": "Descripción"}}
                        </td>
                        <td style="padding: 5px; font-size: 85%; text-align: right">
                            {{engLang ? "Cost": "Costo"}}
                        </td>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of serviceItemPrices | selectedServiceItemPipe; let odd = odd;"
                        [ngClass]="{ odd: odd }">
                        <td style="display: none">
                            {{ item.pKey}}
                        </td>

                        <td style="padding: 5px; text-align: center; vertical-align: middle;"
                            [style.background]="noSelectColorErr">
                            <div class="checkbox">
                                <label>
                                    <input name="item.module" type="checkbox" [checked]="item.selected"
                                        (click)="item.selected = $event.target.checked; calculateTotal()"
                                        [disabled]="achDisabled || ccDisabled || payPal" style="margin-top: 15px;">
                                </label>
                            </div>
                        </td>

                        <td style="padding: 5px; font-size: 80%;">
                            <input id="itemQty" type="number" maxlength="2" size="2" style="width: 33px;" min="1"
                                max="99" step="1" [attr.value]="item.qty"
                                title="{{engLang ? 'Number of months.' : 'Cantidad de meses.'}}"
                                (change)="itemQtyChanged($event,item.pKey)"
                                [disabled]="achDisabled || ccDisabled || payPal" />
                            <!-- {{ item.qty }} -->
                        </td>

                        <td style="padding: 5px; font-size: 80%;">
                            <span *ngIf="engLang">{{item.descrEng}} </span>
                            <span *ngIf="!engLang">{{item.descrSpan}} </span>
                            <a *ngIf="item.infoLink" [attr.href]="item.infoLink" [target]="_blank" title="Info">
                                <i class="fas fa-question-circle"></i>
                            </a>
                        </td>

                        <td style="padding: 5px; font-size: 80%; text-align: right">
                            {{item.charge | number: '1.2-2'}}
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td></td>
                        <td style="padding: 5px; font-size: 95%; text-align: right">{{engLang ? "Total
                            to
                            pay:": "Total
                            cargo a pagar:"}}</td>
                        <td style="padding: 5px; font-size: 95%; text-align: right">{{total |
                            currency:'USD'}}</td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="noSelectColorErr" class="alert alert-danger">
                {{engLang ? "Select at least 1 option above": "Seleccione al menos 1 opción arriba"}}
            </div>
        </div>

    </div>

    <div>
        <div class="text-center">
            <button *ngIf="!payPal && !emailSent" class="btn btn-success" (click)="submitPayment()"
                id="btnPaymentSubmit" [disabled]="submitBtnDisabled || snErr" #btnPaymentSubmit>
                {{engLang ? "Submit Payment": "Someter Pago"}}
            </button>
        </div>
        <div *ngIf="type == 'cc' && payPal">
            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </div>
    </div>
    <div *ngIf="errMsg" class="alert alert-danger">
        {{ errMsg }}
    </div>
    <div *ngIf="emailSent" [class]="emailSentDlgClass" role="alert">
        <button type="button" class="btn btn-sm btn-close" data-dismiss="alert" aria-label="Close" (click)="closeAndReload()">
        </button>
        <h4 class="alert-heading">{{engLang ? "Thank you for your order!": "¡Gracias por su Orden!"}}
        </h4>
        <p *ngIf="engLang">We've sent you an email to <b>{{cc.billEmail || ach.acctEmail}}</b>.</p>
        <p *ngIf="!engLang">Le enviamos un correo electrónico a <b>{{cc.billEmail ||
                ach.acctEmail}}</b>.
        </p>

        <p *ngIf="engLang && emailErrMsg">But an error was encountered... <b>{{emailErrMsg}}</b>.</p>
        <p *ngIf="!engLang && emailErrMsg">Pero ocurrió un error... <b>{{emailErrMsg}}</b>.</p>

        <p class="text-center">
            <button type="button" class="btn btn-default mt-1"
                style="color: inherit; background-color:rgba(0,0,0,0) !important;" (click)="closeAndReload()">
                {{engLang ? "Close to restart": "Cerrar para comenzar"}}
            </button>
        </p>
    </div>

</div> <!-- container -->