import { Component, inject, Input, model, OnDestroy, OnInit, signal } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { ICustomer } from '../shared/interfaces/customer'
import { LoginService } from './login.service';
import { DataMessageService } from "../shared/data-message.service";
import { WebsocketService } from "../shared/websocket.service";
import { Subscription } from 'rxjs';
import { RecordService } from '../record/record.service';

@Component({
    selector: 'mb-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css'],
    providers: [LoginService],
    standalone: false
})
export class LoginComponent implements OnInit, OnDestroy {
    _recordService = inject(RecordService);

    userValidLogin = model(false);  // Signal to other components that user is valid
    sn = model('0000');
    engLang = false;//model(false);

    sioSubscr: Subscription;
    showPopr: boolean = false;
    // sn = signal<string>('0000');
    @Input() cust: ICustomer = {
        pKey: undefined,
        sn: undefined,
        email: undefined,
        userNm: undefined,
        password: undefined,
        companyNm: undefined,
        lastNm: undefined,
        firstNm: undefined,
        cellPhone: '',  // must initialize to avoid showing undefined inn input box
        otherPhone: '', // must initialize to avoid showing undefined inn input box
        title: undefined,
        typedSignature: undefined,
        signedDate: '', // must initialize to avoid showing undefined inn input box
    };

    signInErr: boolean = false;
    signInErrMsg: string;
    register: boolean = false;

    msgBoxShow: boolean = false;
    msgBoxMessage: string = undefined;
    msgBoxImgSrc: string = undefined;
    msgBoxImgAlt: string = undefined;
    msgBoxClose: boolean = true;
    desktopSN: string = undefined;
    waiting4Response: boolean = false;

    @Input() revs: [{
        rev: undefined,
        dt: undefined,
        descr: undefined
    }];

    constructor(
        private _loginService: LoginService,
        private _dataMessageService: DataMessageService,
        private _cookieService: CookieService,
        private _websocketService: WebsocketService
    ) { }

    ngOnInit(): void {
        this._dataMessageService.currentEngLangStr.subscribe(engLangStr => this.engLangChange(engLangStr)); // Subscription looking for changes in engLang
        this.cust.email = this._cookieService.get('userNmOrEmail');
        // TODO: Change the following to observable
        this._loginService.get_MB_DesktopRevisions()
            .subscribe(
                returnedData => this.backFromGet_MB_DesktopRevisions(returnedData),
                error => this.backFromCredsAuthenticatedError(error)    // for any http error 
            );

        this.sioSubscr = this._websocketService.getMessages().subscribe((dataSet) => { // Sets listenning for socketIo events

            console.log('%c' + 'dataSet in LoginComponent.ngOnInit()', 'color: green; background: yellow; font-size: 12px');
            console.log('dataSet', dataSet);

            if (dataSet.hasOwnProperty('error')) {  // Error generated at api server
                this.signInErrMsg = dataSet.error;
                this.signInErr = true;
            } else if (dataSet.length > 0 && dataSet[0].hasOwnProperty('errEng') && dataSet[0].errEng.length > 0) {
                this.signInErrMsg = this.signInErrMsg = this.engLang ? dataSet[0].errEng : dataSet[0].errSpan;
                this.signInErr = true;
            } else {
                for (var i = 0; i < dataSet.length; i++) {
                    Object.keys(dataSet[i]).forEach(key => {
                        dataSet[i][key] = dataSet[i][key].replace('%2f', '#');
                        dataSet[i][key] = dataSet[i][key].replace('%22', '\\');
                    });
                }

                if (dataSet?.length > 0) {

                    if (dataSet[0]?.sqlProcNm == 'spMB_Sio_Login') {    // No error => login succesfull
                        this._dataMessageService.changeSnStr(this.desktopSN);

                        if (this.desktopSN.length === 4 && this.desktopSN > '0000') {   // Sio only
                            const q = "Exec spMB_Sio_GetLists @sn = '" + this.desktopSN + "';Exec spMB_Sio_GetQ837 @sn = '" + this.desktopSN + "', @uID = '" + dataSet[0].mbUserID + "';"
                            console.log('%c' + 'q @ LoginComponent.ngOnInit() ' + q, 'color: black; background: #90EE90; font-size: 12px');
                            this._websocketService.sendChat('query', this.desktopSN, q); // Gets all lists
                        }

                        this.cust.sn = this.desktopSN;

                        this._dataMessageService.changeUserIdStr(dataSet[0].mbUserID);  // propagates value to subscribers
                        this._dataMessageService.changeUserLastNmStr(dataSet[0].mbUserLastNm);      // "                
                        this._dataMessageService.changeUserFirstNmStr(dataSet[0].mbUserFirstNm);    // "
                        this._dataMessageService.changeUserSecOptsStr(dataSet[0].mbUserSecOpts);    // "

                        let st: string = this.fxor(dataSet[0].siteX);    // Decode encripted site name
                        this._dataMessageService.changeSiteStr(st);  // propagates value to subscribers

                        st = this.fxor(dataSet[0].siteAd1X);    // Decode encripted site ad1
                        this._dataMessageService.changeSiteAd1Str(st);  // propagates value to subscribers

                        st = this.fxor(dataSet[0].siteAd2X);    // Decode encripted site ad2
                        this._dataMessageService.changeSiteAd2Str(st);  // propagates value to subscribers

                        st = this.fxor(dataSet[0].siteCtX);    // Decode encripted site city
                        this._dataMessageService.changeSiteCtStr(st);  // propagates value to subscribers

                        st = this.fxor(dataSet[0].siteStX);    // Decode encripted site state
                        this._dataMessageService.changeSiteStStr(st);  // propagates value to subscribers

                        st = this.fxor(dataSet[0].siteZpX);    // Decode encripted site zip
                        this._dataMessageService.changeSiteZpStr(st);  // propagates value to subscribers

                        st = this.fxor(dataSet[0].siteTl1X);    // Decode encripted site tel1
                        if (/\d{10}/g.test(st)) {
                            st = '(' + st.substring(0, 3) + ')' + st.substring(3, 6) + '-' + st.substring(6, 10);
                        }
                        this._dataMessageService.changeSiteTl1Str(st);  // propagates value to subscribers

                        st = this.fxor(dataSet[0].siteXt1X);    // Decode encripted site xtension 1
                        this._dataMessageService.changeSiteXt1Str(st);  // propagates value to subscribers

                        st = this.fxor(dataSet[0].siteTl2X);    // Decode encripted site tel2
                        if (/\d{10}/g.test(st)) {
                            st = '(' + st.substring(0, 3) + ')' + st.substring(3, 6) + '-' + st.substring(6, 10);
                        }
                        this._dataMessageService.changeSiteTl2Str(st);  // propagates value to subscribers

                        st = this.fxor(dataSet[0].siteXt2X);    // Decode encripted site xtension 2
                        this._dataMessageService.changeSiteXt2Str(st);  // propagates value to subscribers

                        st = this.fxor(dataSet[0].siteEm1X);
                        if (!/^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$|^\s*$/g.test(st)) {
                            st = '';  // fxor() failed
                        }
                        this._dataMessageService.changeSiteEm1Str(st);  // propagates value to subscribers

                        st = this.fxor(dataSet[0].siteEm2X);
                        if (!/^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$|^\s*$/g.test(st)) {
                            st = '';  // fxor() failed
                        }
                        this._dataMessageService.changeSiteEm2Str(st);  // propagates value to subscribers


                        this.signInErr = false;    // clear error div
                        this.sn.set(this.desktopSN)
                        this.userValidLogin.set(true);
                        // this.sioSubscr.unsubscribe();

                    } else if (dataSet[0].hasOwnProperty('locName')) {  // spMB_Web_Get_LocaleNames was called
                        this._recordService.localesArr(dataSet);

                    } else if (dataSet[0].hasOwnProperty('payerId')) {
                        this._recordService.localInsurancesArr(dataSet);

                    } else if (dataSet[0].hasOwnProperty('provName')) {
                        this._recordService.localProvidersArr(dataSet);

                    } else if (dataSet[0]?.sqlProcNm === 'spMB_Web_Get_FacilityList') {
                        this._recordService.localFacilitiesArr(dataSet);

                    } else if (dataSet[0]?.sqlProcNm == 'spMB_Web_Get_ReferringList') {
                        this._recordService.localReferringArr(dataSet);

                    } else if (dataSet[0].hasOwnProperty('prodName')) {
                        this._recordService.localProductorsArr(dataSet);

                    } else if (dataSet[0].hasOwnProperty('dxCode')) {
                        this._recordService.localICD10Arr(dataSet);

                    } else if (dataSet[0].hasOwnProperty('zipCode')) {
                        this._recordService.localZipCodesArr(dataSet);
                        this._recordService.paymntModesArr([
                            { value: '', name: '', pKey: '' },
                            { value: 'AMEX', name: '', pKey: '' },
                            { value: 'ATH', name: '', pKey: '' },
                            { value: 'Cash', name: '', pKey: '' },
                            { value: 'Cheque', name: '', pKey: '' },
                            { value: 'Corrección', name: '', pKey: '' },
                            { value: 'Discover', name: '', pKey: '' },
                            { value: 'MasterCard', name: '', pKey: '' },
                            { value: 'Otro', name: '', pKey: '' },
                            { value: 'Visa', name: '', pKey: '' }
                        ]);

                    } else if (dataSet[0]?.sqlProcNm == 'spMB_Sio_GetQ837') {
                        this._recordService.localQ837Arr(dataSet);

                    } else if (dataSet[0]?.sqlProcNm == 'spMB_Web_Get_Submitters') {
                        this._recordService.localSubmittersArr(dataSet);

                    } else if (dataSet[0]?.sqlProcNm == 'spMB_Web_Get_FormConfig') {
                        this._recordService.localFormConfigArr(dataSet);
                    }
                }
            }
            this.waiting4Response = false;
        });
    }

    ngOnDestroy(): void {
        this.sioSubscr.unsubscribe();
        console.log('%c' + 'UNSUSCRIBE-login', 'color: white; background: black; font-size: 10px');
    }

    backFromGet_MB_DesktopRevisions(returnedData): void {
        this.revs = returnedData;
    }

    newUserValidLoginStr(userValidLoginStr: string) {   // Called to generate a change in userValidLoginStr for other componnents
        this._dataMessageService.changeUserValidLoginStr(userValidLoginStr);
    }

    engLangChange(engLangStr: string) { // Subscribed to in ngOnInit() & called whenever engLangStr changes
        engLangStr === 'true' ? this.engLang = true : this.engLang = false;
    }

    signIn(): void {
        if (!this.cust.email || !this.cust.password) { // not a valid email or user name or password
            this.signInErrMsg = this.engLang ? "Verify Email or User Name and Password" : "Verifique Correo Elecrónico o Nombre Usuario y Contraseña"
            this.signInErr = true;  // show error div
            this.waiting4Response = false;
        } else {
            this.waiting4Response = true;
            this.signInErr = false; // clear error div

            if (this.desktopSN) {
                this.desktopSN = this.desktopSN.toUpperCase();
                const q = "EXEC spMB_Sio_Login @emailOrUserNm = '"
                    + this.cust.email.replace(/'/g, "''") + "', @pw = '" + this.cust.password.replace(/'/g, "''") + "', @sn = '" + this.desktopSN + "';"
                console.log('%c' + 'q @ LoginComponent.signIn():' + q, 'color: black; background: #90EE90; font-size: 12px');
                this._websocketService.sendChat('query', this.desktopSN, q);
            } else {
                this._loginService.getCredsAuthenticated(this.cust.email, 'null', 'null', this.cust.password, 'null', 'null')
                    .subscribe(
                        returnedData => this.backFromCredsAuthenticated(returnedData),
                        error => this.backFromCredsAuthenticatedError(error)    // for any http error 
                    );
            }
        }
    }

    backFromCredsAuthenticatedError(error: any): void {
        this.waiting4Response = false;
        this.userValidLogin.set(false);
        this.newUserValidLoginStr('false');
        this.signInErrMsg = error;
        this.signInErr = true;  // show error div
    }

    backFromCredsAuthenticated(returnedData: any): void {
        this.waiting4Response = false;
        let errEn = returnedData[0]['errEng'];
        let errSp = returnedData[0]['errSpan'];
        if (errEn.length == 0 && errSp.length == 0) {
            if (returnedData[0]["pKey"] > 0) {
                this.cust.pKey = returnedData[0]["pKey"];
                this.cust.sn = returnedData[0]["sn"];
                this.cust.email = returnedData[0]["email"];
                this.cust.userNm = returnedData[0]["userNm"];
                this.cust.companyNm = returnedData[0]["companyNm"];
                this.cust.lastNm = returnedData[0]["lastNm"];
                this.cust.firstNm = returnedData[0]["firstNm"];
                this.cust.cellPhone = returnedData[0]["cellPhone"];
                this.cust.cellPhone = returnedData[0]["otherPhone"];
                this.signInErr = false;    // clear error div
                this.userValidLogin.set(true);
                this.sioSubscr.unsubscribe();

                this.newUserValidLoginStr('true');
            } else {
                this.cust.pKey = undefined;
                this.signInErr = true;  // show error div
            }
        } else {
            this.signInErrMsg = this.engLang ? errEn : errSp;
            this.signInErr = true;  // show error div
        }
    }

    registerNewClient(): void {
        if (!this.cust.email || this.cust.email.match(/^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$|^\s*$/g)) {  // set a valid entered email/user_nm into register fields
            //this.newEmail = this.cust.email;
        } else {
            this.cust.userNm = this.cust.email;
        }
        this.register = !this.register;
    }

    keyPressHandleENTER(event: any): void {
        //console.log(event, event.keyCode, event.keyIdentifier);
        if (event.keyCode == 13) {
            this.signIn();
        }
    }

    rememberMe(): void {
        if (this.cust.email) {
            this._cookieService.set('userNmOrEmail', this.cust.email);
        } else {
            this._cookieService.delete('userNmOrEmail');
        }
    }

    onClick_showPopr(): void {
        this.showPopr = !this.showPopr;
        if (this.showPopr) {
            let coll = document.querySelector('div.popper-content');   // collection
            if (coll) {
                let popr = <HTMLDivElement>coll;
                popr.style.width = "425px";
            }
        }
    }

    fxor(s: string): string {
        if (s) {
            return [...s].map(char => String.fromCharCode(char.charCodeAt(0) ^ 0xFF)).join('');
        } else {
            return '';
        }
    }

}
