/* src/app/ck-register/ck-register-detail/ck-register-detail.component.css */
.w20 {
  width: 20px;
}
.w30 {
  width: 30px;
}
.w70 {
  width: 70px;
}
.w80 {
  width: 80px;
}
.w95 {
  width: 95px;
}
.w125 {
  width: 125px;
}
.oddCkDet {
  background-color: #f1f2f1;
}
.evenCkDet {
  background-color: #dad8d9;
}
.small {
  font-size: small;
}
.currency {
  width: inherit;
  text-align: right;
}
/*# sourceMappingURL=ck-register-detail.component.css.map */
