<div style="position: relative;">
    <!-- Use id="ReclaimFormFields" only to get column ReclFrmXml for table [ReclaimForms] with rtn createReclaimXml_developmentHelperRtn() -->
    <div>
        <img [src]="reclFormImg64Seled" alt="Form Image" width="100%" height="100%">
    </div>

    <!-- <optMMM></optMMM> -->
    <div class="form-check-input optMMM">
        <input type="radio" id="optMMM" name="a" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.plan" value="mmm">
    </div>

    <!-- <optPMC></optPMC> -->
    <div class="form-check-input optPMC">
        <input type="radio" id="optPMC" name="a" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.plan" value="pmc">
    </div>

    <!-- <txtMMM_PMC_Gob_ProvNm></txtMMM_PMC_Gob_ProvNm> -->
    <div class="txtMMM_PMC_Gob_ProvNm">
        <input type="text" id="txtMMM_PMC_Gob_ProvNm" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txtMMM_PMC_Gob_ProvNm" style="width: 420px">
    </div>

    <!-- <txtMMM_PMC_Gob_NPI></txtMMM_PMC_Gob_NPI> -->
    <div class="txtMMM_PMC_Gob_NPI">
        <input type="text" id="txtMMM_PMC_Gob_NPI" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txtMMM_PMC_Gob_NPI" style="width: 170px">
    </div>

    <!-- <txtMMM_PMC_Gob_Date></txtMMM_PMC_Gob_Date> -->
    <div class="txtMMM_PMC_Gob_Date">
        <input type="text" id="txtMMM_PMC_Gob_Date" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txtMMM_PMC_Gob_Date" style="width: 170px" mask="00/00/0000">
    </div>

    <!-- 1 -->
    <!-- <txt3MPMC_ContrNo1></txt3MPMC_ContrNo1> -->
    <div class="txt3MPMC_ContrNo1">
        <input type="text" id="txt3MPMC_ContrNo1" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo1" style="width: 135px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ReclamNo1></txt3MPMC_ReclamNo1> -->
    <div class="txt3MPMC_ReclamNo1">
        <input type="text" id="txt3MPMC_ReclamNo1" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo1" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Cpt1></txt3MPMC_Cpt1> -->
    <div class="txt3MPMC_Cpt1">
        <input type="text" id="txt3MPMC_Cpt1" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Cpt1" style="width: 142px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Date1></txt3MPMC_Date1> -->
    <div class="txt3MPMC_Date1">
        <input type="text" id="txt3MPMC_Date1" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date1" style="width: 82px; height: 20px;" class="text-center" mask="00/00/00">
    </div>

    <!-- <txt3MPMC_Bal1></txt3MPMC_Bal1> -->
    <div class="txt3MPMC_Bal1">
        <input type="text" id="txt3MPMC_Bal1" class="currcy" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Bal1" style="width: 80px; height: 20px;" size="10" maxlength="10" mask="separator.2"
            thousandSeparator=",">
    </div>

    <!-- <txt3MPMC_Reason1></txt3MPMC_Reason1> -->
    <div class="txt3MPMC_Reason1">
        <input type="text" id="txt3MPMC_Reason1" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason1" style="width: 275px; height: 20px;" maxlength="100" class="font10">
    </div>

    <!-- 2 -->
    <!-- <txt3MPMC_ContrNo2></txt3MPMC_ContrNo2> -->
    <div class="txt3MPMC_ContrNo2">
        <input type="text" id="txt3MPMC_ContrNo2" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo2" style="width: 135px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ReclamNo2></txt3MPMC_ReclamNo2> -->
    <div class="txt3MPMC_ReclamNo2">
        <input type="text" id="txt3MPMC_ReclamNo2" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo2" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Cpt2></txt3MPMC_Cpt2> -->
    <div class="txt3MPMC_Cpt2">
        <input type="text" id="txt3MPMC_Cpt2" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Cpt2" style="width: 142px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Date2></txt3MPMC_Date2> -->
    <div class="txt3MPMC_Date2">
        <input type="text" id="txt3MPMC_Date2" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date2" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Bal2></txt3MPMC_Bal2> -->
    <div class="txt3MPMC_Bal2">
        <input type="text" id="txt3MPMC_Bal2" class="currcy" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Bal2" style="width: 80px; height: 20px;" size="10" maxlength="10" mask="separator.2"
            thousandSeparator=",">
    </div>

    <!-- <txt3MPMC_Reason2></txt3MPMC_Reason2> -->
    <div class="txt3MPMC_Reason2">
        <input type="text" id="txt3MPMC_Reason2" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason2" style="width: 275px; height: 20px;" maxlength="100" class="font10">
    </div>

    <!-- 3 -->
    <!-- <txt3MPMC_ContrNo3></txt3MPMC_ContrNo3> -->
    <div class="txt3MPMC_ContrNo3">
        <input type="text" id="txt3MPMC_ContrNo3" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo3" style="width: 135px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ReclamNo3></txt3MPMC_ReclamNo3> -->
    <div class="txt3MPMC_ReclamNo3">
        <input type="text" id="txt3MPMC_ReclamNo3" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo3" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Cpt3></txt3MPMC_Cpt2> -->
    <div class="txt3MPMC_Cpt3">
        <input type="text" id="txt3MPMC_Cpt3" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Cpt3" style="width: 142px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Date3></txt3MPMC_Date3> -->
    <div class="txt3MPMC_Date3">
        <input type="text" id="txt3MPMC_Date3" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date3" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Bal3></txt3MPMC_Bal3> -->
    <div class="txt3MPMC_Bal3">
        <input type="text" id="txt3MPMC_Bal3" class="currcy" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Bal3" style="width: 80px; height: 20px;" size="10" maxlength="10" mask="separator.2"
            thousandSeparator=",">
    </div>

    <!-- <txt3MPMC_Reason3></txt3MPMC_Reason3> -->
    <div class="txt3MPMC_Reason3">
        <input type="text" id="txt3MPMC_Reason3" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason3" style="width: 275px; height: 20px;" maxlength="100" class="font10">
    </div>

    <!-- 4 -->
    <!-- <txt3MPMC_ContrNo4></txt3MPMC_ContrNo4> -->
    <div class="txt3MPMC_ContrNo4">
        <input type="text" id="txt3MPMC_ContrNo4" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo4" style="width: 135px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ReclamNo4></txt3MPMC_ReclamNo4> -->
    <div class="txt3MPMC_ReclamNo4">
        <input type="text" id="txt3MPMC_ReclamNo4" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo4" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Cpt4></txt3MPMC_Cpt4> -->
    <div class="txt3MPMC_Cpt4">
        <input type="text" id="txt3MPMC_Cpt4" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Cpt4" style="width: 142px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Date4></txt3MPMC_Date4> -->
    <div class="txt3MPMC_Date4">
        <input type="text" id="txt3MPMC_Date4" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date4" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Bal4></txt3MPMC_Bal4> -->
    <div class="txt3MPMC_Bal4">
        <input type="text" id="txt3MPMC_Bal4" class="currcy" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Bal4" style="width: 80px; height: 20px;" size="10" maxlength="10" mask="separator.2"
            thousandSeparator=",">
    </div>

    <!-- <txt3MPMC_Reason4></txt3MPMC_Reason4> -->
    <div class="txt3MPMC_Reason4">
        <input type="text" id="txt3MPMC_Reason4" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason4" style="width: 275px; height: 20px;" maxlength="100" class="font10">
    </div>

    <!-- 5 -->
    <!-- <txt3MPMC_ContrNo5></txt3MPMC_ContrNo5> -->
    <div class="txt3MPMC_ContrNo5">
        <input type="text" id="txt3MPMC_ContrNo5" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo5" style="width: 135px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ReclamNo5></txt3MPMC_ReclamNo5> -->
    <div class="txt3MPMC_ReclamNo5">
        <input type="text" id="txt3MPMC_ReclamNo5" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo5" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Cpt5></txt3MPMC_Cpt5> -->
    <div class="txt3MPMC_Cpt5">
        <input type="text" id="txt3MPMC_Cpt5" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Cpt5" style="width: 142px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Date5></txt3MPMC_Date5> -->
    <div class="txt3MPMC_Date5">
        <input type="text" id="txt3MPMC_Date5" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date5" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Bal5></txt3MPMC_Bal5> -->
    <div class="txt3MPMC_Bal5">
        <input type="text" id="txt3MPMC_Bal5" class="currcy" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Bal5" style="width: 80px; height: 20px;" size="10" maxlength="10" mask="separator.2"
            thousandSeparator=",">
    </div>

    <!-- <txt3MPMC_Reason5></txt3MPMC_Reason5> -->
    <div class="txt3MPMC_Reason5">
        <input type="text" id="txt3MPMC_Reason5" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason5" style="width: 275px; height: 20px;" maxlength="100" class="font10">
    </div>

    <!-- 6 -->
    <!-- <txt3MPMC_ContrNo6></txt3MPMC_ContrNo6> -->
    <div class="txt3MPMC_ContrNo6">
        <input type="text" id="txt3MPMC_ContrNo6" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo6" style="width: 135px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ReclamNo6></txt3MPMC_ReclamNo6> -->
    <div class="txt3MPMC_ReclamNo6">
        <input type="text" id="txt3MPMC_ReclamNo6" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo6" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Cpt6></txt3MPMC_Cpt6> -->
    <div class="txt3MPMC_Cpt6">
        <input type="text" id="txt3MPMC_Cpt6" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Cpt6" style="width: 142px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Date6></txt3MPMC_Date6> -->
    <div class="txt3MPMC_Date6">
        <input type="text" id="txt3MPMC_Date6" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date6" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Bal6></txt3MPMC_Bal6> -->
    <div class="txt3MPMC_Bal6">
        <input type="text" id="txt3MPMC_Bal6" class="currcy" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Bal6" style="width: 80px; height: 20px;" size="10" maxlength="10" mask="separator.2"
            thousandSeparator=",">
    </div>

    <!-- <txt3MPMC_Reason6></txt3MPMC_Reason6> -->
    <div class="txt3MPMC_Reason6">
        <input type="text" id="txt3MPMC_Reason6" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason6" style="width: 275px; height: 20px;" maxlength="100" class="font10">
    </div>

    <!-- 7 -->
    <!-- <txt3MPMC_ContrNo7></txt3MPMC_ContrNo7> -->
    <div class="txt3MPMC_ContrNo7">
        <input type="text" id="txt3MPMC_ContrNo7" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo7" style="width: 135px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ReclamNo7></txt3MPMC_ReclamNo7> -->
    <div class="txt3MPMC_ReclamNo7">
        <input type="text" id="txt3MPMC_ReclamNo7" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo7" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Cpt7></txt3MPMC_Cpt7> -->
    <div class="txt3MPMC_Cpt7">
        <input type="text" id="txt3MPMC_Cpt7" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Cpt7" style="width: 142px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Date7></txt3MPMC_Date7> -->
    <div class="txt3MPMC_Date7">
        <input type="text" id="txt3MPMC_Date7" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date7" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Bal7></txt3MPMC_Bal7> -->
    <div class="txt3MPMC_Bal7">
        <input type="text" id="txt3MPMC_Bal7" class="currcy" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Bal7" style="width: 80px; height: 20px;" size="10" maxlength="10" mask="separator.2"
            thousandSeparator=",">
    </div>

    <!-- <txt3MPMC_Reason7></txt3MPMC_Reason7> -->
    <div class="txt3MPMC_Reason7">
        <input type="text" id="txt3MPMC_Reason7" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason7" style="width: 275px; height: 20px;" maxlength="100" class="font10">
    </div>

    <!-- 8 -->
    <!-- <txt3MPMC_ContrNo8></txt3MPMC_ContrNo8> -->
    <div class="txt3MPMC_ContrNo8">
        <input type="text" id="txt3MPMC_ContrNo8" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo8" style="width: 135px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ReclamNo8></txt3MPMC_ReclamNo8> -->
    <div class="txt3MPMC_ReclamNo8">
        <input type="text" id="txt3MPMC_ReclamNo8" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo8" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Cpt8></txt3MPMC_Cpt8> -->
    <div class="txt3MPMC_Cpt8">
        <input type="text" id="txt3MPMC_Cpt8" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Cpt8" style="width: 142px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Date8></txt3MPMC_Date8> -->
    <div class="txt3MPMC_Date8">
        <input type="text" id="txt3MPMC_Date8" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date8" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Bal8></txt3MPMC_Bal8> -->
    <div class="txt3MPMC_Bal8">
        <input type="text" id="txt3MPMC_Bal8" class="currcy" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Bal8" style="width: 80px; height: 20px;" size="10" maxlength="10" mask="separator.2"
            thousandSeparator=",">
    </div>

    <!-- <txt3MPMC_Reason8></txt3MPMC_Reason8> -->
    <div class="txt3MPMC_Reason8">
        <input type="text" id="txt3MPMC_Reason8" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason8" style="width: 275px; height: 20px;" maxlength="100" class="font10">
    </div>

    <!-- 9 -->
    <!-- <txt3MPMC_ContrNo9></txt3MPMC_ContrNo9> -->
    <div class="txt3MPMC_ContrNo9">
        <input type="text" id="txt3MPMC_ContrNo9" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo9" style="width: 135px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ReclamNo9></txt3MPMC_ReclamNo9> -->
    <div class="txt3MPMC_ReclamNo9">
        <input type="text" id="txt3MPMC_ReclamNo9" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo9" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Cpt9></txt3MPMC_Cpt9> -->
    <div class="txt3MPMC_Cpt9">
        <input type="text" id="txt3MPMC_Cpt9" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Cpt9" style="width: 142px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Date9></txt3MPMC_Date9> -->
    <div class="txt3MPMC_Date9">
        <input type="text" id="txt3MPMC_Date9" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date9" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Bal9></txt3MPMC_Bal9> -->
    <div class="txt3MPMC_Bal9">
        <input type="text" id="txt3MPMC_Bal9" class="currcy" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Bal9" style="width: 80px; height: 20px;" size="10" maxlength="10" mask="separator.2"
            thousandSeparator=",">
    </div>

    <!-- <txt3MPMC_Reason9></txt3MPMC_Reason9> -->
    <div class="txt3MPMC_Reason9">
        <input type="text" id="txt3MPMC_Reason9" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason9" style="width: 275px; height: 20px;" maxlength="100" class="font10">
    </div>

    <!-- 10 -->
    <!-- <txt3MPMC_ContrNo10></txt3MPMC_ContrNo10> -->
    <div class="txt3MPMC_ContrNo10">
        <input type="text" id="txt3MPMC_ContrNo10" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo10" style="width: 135px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ReclamNo10></txt3MPMC_ReclamNo10> -->
    <div class="txt3MPMC_ReclamNo10">
        <input type="text" id="txt3MPMC_ReclamNo10" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo10" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Cpt10></txt3MPMC_Cpt10> -->
    <div class="txt3MPMC_Cpt10">
        <input type="text" id="txt3MPMC_Cpt10" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Cpt10" style="width: 142px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Date10></txt3MPMC_Date10> -->
    <div class="txt3MPMC_Date10">
        <input type="text" id="txt3MPMC_Date10" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date10" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Bal10></txt3MPMC_Bal10> -->
    <div class="txt3MPMC_Bal10">
        <input type="text" id="txt3MPMC_Bal10" class="currcy" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Bal10" style="width: 80px; height: 20px;" size="10" maxlength="10"
            mask="separator.2" thousandSeparator=",">
    </div>

    <!-- <txt3MPMC_Reason10></txt3MPMC_Reason10> -->
    <div class="txt3MPMC_Reason10">
        <input type="text" id="txt3MPMC_Reason10" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason10" style="width: 275px; height: 20px;" maxlength="100" class="font10">
    </div>

    <!-- 11 -->
    <!-- <txt3MPMC_ContrNo11></txt3MPMC_ContrNo11> -->
    <div class="txt3MPMC_ContrNo11">
        <input type="text" id="txt3MPMC_ContrNo11" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo11" style="width: 135px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ReclamNo11></txt3MPMC_ReclamNo11> -->
    <div class="txt3MPMC_ReclamNo11">
        <input type="text" id="txt3MPMC_ReclamNo11" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo11" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Cpt11></txt3MPMC_Cpt11> -->
    <div class="txt3MPMC_Cpt11">
        <input type="text" id="txt3MPMC_Cpt11" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Cpt11" style="width: 142px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Date11></txt3MPMC_Date11> -->
    <div class="txt3MPMC_Date11">
        <input type="text" id="txt3MPMC_Date11" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date11" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Bal11></txt3MPMC_Bal11> -->
    <div class="txt3MPMC_Bal11">
        <input type="text" id="txt3MPMC_Bal11" class="currcy" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Bal11" style="width: 80px; height: 20px;" size="10" maxlength="10"
            mask="separator.2" thousandSeparator=",">
    </div>

    <!-- <txt3MPMC_Reason11></txt3MPMC_Reason11> -->
    <div class="txt3MPMC_Reason11">
        <input type="text" id="txt3MPMC_Reason11" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason11" style="width: 275px; height: 20px;" maxlength="100" class="font10">
    </div>

    <!-- 12 -->
    <!-- <txt3MPMC_ContrNo12></txt3MPMC_ContrNo12> -->
    <div class="txt3MPMC_ContrNo12">
        <input type="text" id="txt3MPMC_ContrNo12" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo12" style="width: 135px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ReclamNo12></txt3MPMC_ReclamNo12> -->
    <div class="txt3MPMC_ReclamNo12">
        <input type="text" id="txt3MPMC_ReclamNo12" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo12" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Cpt12></txt3MPMC_Cpt12> -->
    <div class="txt3MPMC_Cpt12">
        <input type="text" id="txt3MPMC_Cpt12" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Cpt12" style="width: 142px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Date12></txt3MPMC_Date12> -->
    <div class="txt3MPMC_Date12">
        <input type="text" id="txt3MPMC_Date12" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date12" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Bal12></txt3MPMC_Bal12> -->
    <div class="txt3MPMC_Bal12">
        <input type="text" id="txt3MPMC_Bal12" class="currcy" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Bal12" style="width: 80px; height: 20px;" size="10" maxlength="10"
            mask="separator.2" thousandSeparator=",">
    </div>

    <!-- <txt3MPMC_Reason12></txt3MPMC_Reason12> -->
    <div class="txt3MPMC_Reason12">
        <input type="text" id="txt3MPMC_Reason12" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason12" style="width: 275px; height: 20px;" maxlength="100" class="font10">
    </div>

    <!-- 13 -->
    <!-- <txt3MPMC_ContrNo13></txt3MPMC_ContrNo13> -->
    <div class="txt3MPMC_ContrNo13">
        <input type="text" id="txt3MPMC_ContrNo13" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo13" style="width: 135px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ReclamNo13></txt3MPMC_ReclamNo13> -->
    <div class="txt3MPMC_ReclamNo13">
        <input type="text" id="txt3MPMC_ReclamNo13" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo13" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Cpt13></txt3MPMC_Cpt13> -->
    <div class="txt3MPMC_Cpt13">
        <input type="text" id="txt3MPMC_Cpt13" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Cpt13" style="width: 142px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Date13></txt3MPMC_Date13> -->
    <div class="txt3MPMC_Date13">
        <input type="text" id="txt3MPMC_Date13" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date13" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Bal13></txt3MPMC_Bal13> -->
    <div class="txt3MPMC_Bal13">
        <input type="text" id="txt3MPMC_Bal13" class="currcy" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Bal13" style="width: 80px; height: 20px;" size="10" maxlength="10"
            mask="separator.2" thousandSeparator=",">
    </div>

    <!-- <txt3MPMC_Reason13></txt3MPMC_Reason13> -->
    <div class="txt3MPMC_Reason13">
        <input type="text" id="txt3MPMC_Reason13" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason13" style="width: 275px; height: 20px;" maxlength="100" class="font10">
    </div>

    <!-- 14 -->
    <!-- <txt3MPMC_ContrNo14></txt3MPMC_ContrNo14> -->
    <div class="txt3MPMC_ContrNo14">
        <input type="text" id="txt3MPMC_ContrNo14" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo14" style="width: 145px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ReclamNo14></txt3MPMC_ReclamNo14> -->
    <div class="txt3MPMC_ReclamNo14">
        <input type="text" id="txt3MPMC_ReclamNo14" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo14" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Cpt14></txt3MPMC_Cpt14> -->
    <div class="txt3MPMC_Cpt14">
        <input type="text" id="txt3MPMC_Cpt14" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Cpt14" style="width: 142px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Date14></txt3MPMC_Date14> -->
    <div class="txt3MPMC_Date14">
        <input type="text" id="txt3MPMC_Date14" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date14" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Bal14></txt3MPMC_Bal14> -->
    <div class="txt3MPMC_Bal14">
        <input type="text" id="txt3MPMC_Bal14" class="currcy" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Bal14" style="width: 80px; height: 20px;" size="10" maxlength="10"
            mask="separator.2" thousandSeparator=",">
    </div>

    <!-- <txt3MPMC_Reason14></txt3MPMC_Reason14> -->
    <div class="txt3MPMC_Reason14">
        <input type="text" id="txt3MPMC_Reason14" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason14" style="width: 275px; height: 20px;" maxlength="100" class="font10">
    </div>

    <!-- <txtMMM_PMC_Gob_Tot></txtMMM_PMC_Gob_Tot> -->
    <div class="txtMMM_PMC_Gob_Tot">
        <input type="text" id="txtMMM_PMC_Gob_Tot" class="currcy" (click)="onClick($event)"
            (change)="onChange_need2Save()" [(ngModel)]="e.txtMMM_PMC_Gob_Tot" style="width: 80px; height: 20px;"
            size="10" maxlength="10" mask="separator.2" thousandSeparator=",">
    </div>

</div>