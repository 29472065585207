import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'casFoundPipe',
    standalone: false
})
export class CasFoundPipe implements PipeTransform {

    transform(input: any[], casIndxFound: string[]): any[] {
        if (!input) {
            return input;
        }
        return input.filter(obj => {
            if (casIndxFound.find(e => obj['indx'] == e)) {
                return obj;
            }
            return null;
        });
    }

}
