/* src/app/ck-register/recl-xml-medicareBRetermination/recl-xml-medicare-bretermination/recl-xml-medicare-bretermination.component.css */
.rowContainer {
  font-family: arial, sans-serif;
  font-size: small;
  margin: 0 0 1em 1em;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-start;
  gap: 20px;
}
.colContainer {
  margin: 0 0 0 0;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
}
.rowFlex {
  padding: 5px;
  text-align: center;
  position: relative;
  flex-basis: auto;
}
.zeroPadMar {
  padding: 0;
  margin: 0;
}
.border {
  border-style: solid;
}
input[type=text] {
  width: 5em;
}
/*# sourceMappingURL=recl-xml-medicare-bretermination.component.css.map */
