/* src/app/reports/reports.component.css */
.main {
  width: 100%;
  height: 700px;
  margin: 4px;
  border: 1px solid #555;
  background: #eee;
}
.selected {
  background-color: grey;
}
.report-actions {
  text-align: right;
}
.label,
.dt-hint {
  font-size: 10px;
}
.title {
  margin-right: 10px;
}
#fromDt,
#toDt {
  font-size: 11px;
}
.toolbar {
  background-color: inherit;
}
mat-toolbar {
  height: 15px;
  min-height: 15px;
}
mat-toolbar .mat-toolbar-row,
mat-toolbar .mat-toolbar-single-row {
  background-color: white;
}
.maticon {
  margin: 0 8px;
}
/*# sourceMappingURL=reports.component.css.map */
