<!-- ================ LOGIN FORM ============================================================== -->

<div class="container">

	<div style="display: flex; justify-content: center;" *ngIf="!register && !userValidLogin()">
		<div class="col-xs-1 col-sm-8 col-md-8 col-lg-6 col-xl-4">
			<fieldset>
				<h2 class="text-center">{{engLang ? "Sign In": "Iniciar Sesión"}} <span *ngIf="waiting4Response">
						<img src="app/assets/images/waiting4Response.gif" width="42" height="42"></span></h2>
				<hr class="colorgraph">
				<div class="form-group">
					<label for="email" style="font-size: 75%; margin-bottom: 0;">
						{{ engLang ? "Email Address or User Name": "Correo Electrónico o Nombre Usuario" }}
					</label>
					<input type="email" id="email" name="custEmail" class="form-control input-sm"
						placeholder='{{engLang ? "Email Address or User Name": "Correo Electrónico o Nombre Usuario"}}'
						autofocus maxlength="50" [(ngModel)]="cust.email" (keypress)="keyPressHandleENTER($event)"
						tabindex="1">
				</div>
				<div class="form-group">
					<div style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-items: end;">
						<div>
							<label for="desktopSN" style="font-size: 75%; margin-bottom: 0;">
								MedicalBillerDesktop S/N
								({{ engLang ? "Leave blank for MedicalBillerWeb" : "Deje vacío para MedicalBillerWeb"
								}})
								&nbsp;
							</label>
						</div>
						<div [popper]="popperSnContent" [popperShowOnStart]="false" [popperTrigger]="'click'"
							[popperHideOnClickOutside]="true" [popperHideOnScroll]="true" [popperPlacement]="'right'"
							[popperPositionFixed]="true">
							<i class="fas fa-question-circle" (click)="onClick_showPopr()" style="cursor: pointer"></i>
						</div>
					</div>
					<popper-content #popperSnContent>
						<img src="app/assets/images/MBDesktopSplashSN.png" width="400" height="200">
					</popper-content>

					<input type="text" id="desktopSN" name="desktopSN" class="form-control input-sm uppercase"
						placeholder='MedicalBillerDesktop S/N' maxlength="4" [(ngModel)]="desktopSN"
						(keypress)="keyPressHandleENTER($event)" tabindex="2">
				</div>
				<div *ngIf="signInErr" class="alert alert-danger">
					{{signInErrMsg}}
				</div>
				<div class="form-group">
					<label for="password" style="font-size: 75%; margin-bottom: 0;">
						{{ engLang ? "Password": "Contraseña" }}
					</label>
					<input type="password" id="password" name="custPassword" class="form-control input-sm"
						placeholder='{{engLang ? "Password": "Contraseña"}}' maxlength="50" [(ngModel)]="cust.password"
						(keypress)="keyPressHandleENTER($event)" tabindex="3">
				</div>
				<div class="row justify-content-center">
					<div class="col-xs-5 col-sm-6 col-md-6 col-lg-6 col-xl-6">
						<span class="button-checkbox">
							<button type="button" class="btn" data-color="info" (click)="rememberMe()"
								tabindex="6">{{engLang ? "Remember Me": "Recordar Usuario"}}</button>
							<input type="checkbox" name="remember_me" id="remember_me" checked="checked" class="d-none">
						</span>
					</div>
					<div class="col-xs-5 col-sm-6 col-md-6 col-lg-6 col-xl-6">
						<a href="" class="btn btn-link pull-right" tabindex="7">{{engLang ? "Forgot Password?": "¿Olvidó
							Contraseña?"}}</a>
					</div>
				</div>
				<hr class="colorgraph">
				<div class="row">
					<div class="col-xs-5 col-sm-6 col-md-6 col-lg-6 col-xl-6">
						<button name="signIn" class="btn btn-lg btn-success btn-block" (click)="signIn()"
							tabindex="4">{{engLang ? "Sign In": "Entrar"}}</button>
					</div>
					<div class="col-xs-5 col-sm-6 col-md-6 col-lg-6 col-xl-6">
						<button (click)="registerNewClient()" class="btn btn-lg btn-primary btn-block"
							tabindex="5">{{engLang ? "Register": "Regístrese"}}</button>
					</div>
				</div>
			</fieldset>
		</div>
	</div>

	<!-- <pre>
		{{ cust | json }}
		{{ desktopSN }}
	</pre> -->

	<div *ngIf="register">
		<ol class="breadcrumb">
			<li><a href="/">{{ engLang ? "Sign In>": "Iniciar Sesión>" }}</a></li>
			<li class="active">{{ engLang ? "Registration": "Registro" }}</li>
		</ol>

		<!-- register-newclient.component.ts -->
		<mb-registernewclient [engLang]="engLang" [cust]="cust">
		</mb-registernewclient>
	</div>


	<div *ngIf="userValidLogin()">

		<router-outlet></router-outlet>

	</div>