<table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortea($event)" class="mat-elevation-z8"
        multiTemplateDataRows>

        <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="InLNm" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{engLang ? ' Insurance ': '
                        Seguro '}} </th>
                <td mat-cell *matCellDef="let insurance" class="font10 p-1" (click)="onClick_expandIns(insurance)">
                        {{insurance.InLNm}} </td>
                <td mat-footer-cell *matFooterCellDef="let insurance"> {{tot.InLNm}} </td>
        </ng-container>

        <ng-container matColumnDef="InID">
                <th mat-header-cell *matHeaderCellDef class="text-center p-1"> ID(local) </th>
                <td mat-cell *matCellDef="let insurance" class="font10 p-1 text-center"
                        (click)="onClick_expandIns(insurance)"> {{insurance.InID}} </td>
                <td mat-footer-cell *matFooterCellDef="let insurance"> </td>
        </ng-container>

        <ng-container matColumnDef="ag30">
                <th mat-header-cell *matHeaderCellDef class="rtalign p-1"> 0-30 </th>
                <td mat-cell *matCellDef="let insurance" class="rtalign p-1" (click)="onClick_expandIns(insurance)">
                        {{insurance.ag30 |
                        currency:'USD':'':'1.2-2'}}
                        <br><span class="font10">{{insurance.c30}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef="let insurance" class="rtalign p-1"> {{tot.ag30 |
                        currency:'USD':'':'1.2-2'}} <br><span class="font10">{{tot.c30}}</span>
                </td>
        </ng-container>

        <ng-container matColumnDef="ag60">
                <th mat-header-cell *matHeaderCellDef class="rtalign p-1"> 31-60 </th>
                <td mat-cell *matCellDef="let insurance" class="rtalign p-1" (click)="onClick_expandIns(insurance)">
                        {{insurance.ag60 |
                        currency:'USD':'':'1.2-2'}}
                        <br><span class="font10">{{insurance.c60}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef="let insurance" class="rtalign p-1"> {{tot.ag60 |
                        currency:'USD':'':'1.2-2'}} <br><span class="font10">{{tot.c60}}</span>
                </td>
        </ng-container>

        <ng-container matColumnDef="ag90">
                <th mat-header-cell *matHeaderCellDef class="rtalign p-1"> 61-90 </th>
                <td mat-cell *matCellDef="let insurance" class="rtalign p-1" (click)="onClick_expandIns(insurance)">
                        {{insurance.ag90 |
                        currency:'USD':'':'1.2-2'}}
                        <br><span class="font10">{{insurance.c90}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef="let insurance" class="rtalign p-1"> {{tot.ag90 |
                        currency:'USD':'':'1.2-2'}} <br><span class="font10">{{tot.c90}}</span>
                </td>
        </ng-container>

        <ng-container matColumnDef="ag120">
                <th mat-header-cell *matHeaderCellDef class="rtalign p-1"> 91-120 </th>
                <td mat-cell *matCellDef="let insurance" class="rtalign p-1" (click)="onClick_expandIns(insurance)">
                        {{insurance.ag120 |
                        currency:'USD':'':'1.2-2'}}
                        <br><span class="font10">{{insurance.c120}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef="let insurance" class="rtalign p-1"> {{tot.ag120 |
                        currency:'USD':'':'1.2-2'}} <br><span class="font10">{{tot.c120}}</span>
                </td>
        </ng-container>

        <ng-container matColumnDef="ag150">
                <th mat-header-cell *matHeaderCellDef class="rtalign p-1"> 121-150 </th>
                <td mat-cell *matCellDef="let insurance" class="rtalign p-1" (click)="onClick_expandIns(insurance)">
                        {{insurance.ag150 |
                        currency:'USD':'':'1.2-2'}}
                        <br><span class="font10">{{insurance.c150}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef="let insurance" class="rtalign p-1"> {{tot.ag150 |
                        currency:'USD':'':'1.2-2'}} <br><span class="font10">{{tot.c150}}</span>
                </td>
        </ng-container>

        <ng-container matColumnDef="ag6m">
                <th mat-header-cell *matHeaderCellDef class="rtalign p-1"> 151-6m </th>
                <td mat-cell *matCellDef="let insurance" class="rtalign p-1" (click)="onClick_expandIns(insurance)">
                        {{insurance.ag6m |
                        currency:'USD':'':'1.2-2'}}
                        <br><span class="font10">{{insurance.c6m}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef="let insurance" class="rtalign p-1"> {{tot.ag6m |
                        currency:'USD':'':'1.2-2'}} <br><span class="font10">{{tot.c6m}}</span>
                </td>
        </ng-container>

        <ng-container matColumnDef="ag1y">
                <th mat-header-cell *matHeaderCellDef class="rtalign p-1"> 6m-1a </th>
                <td mat-cell *matCellDef="let insurance" class="rtalign p-1" (click)="onClick_expandIns(insurance)">
                        {{insurance.ag1y |
                        currency:'USD':'':'1.2-2'}}
                        <br><span class="font10">{{insurance.c1y}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef="let insurance" class="rtalign p-1"> {{tot.ag1y |
                        currency:'USD':'':'1.2-2'}} <br><span class="font10">{{tot.c1y}}</span>
                </td>
        </ng-container>

        <ng-container matColumnDef="ag2y">
                <th mat-header-cell *matHeaderCellDef class="rtalign p-1"> 1a-2a </th>
                <td mat-cell *matCellDef="let insurance" class="rtalign p-1" (click)="onClick_expandIns(insurance)">
                        {{insurance.ag2y |
                        currency:'USD':'':'1.2-2'}}
                        <br><span class="font10">{{insurance.c2y}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef="let insurance" class="rtalign p-1"> {{tot.ag2y |
                        currency:'USD':'':'1.2-2'}} <br><span class="font10">{{tot.c2y}}</span>
                </td>
        </ng-container>

        <ng-container matColumnDef="ag3y">
                <th mat-header-cell *matHeaderCellDef class="rtalign p-1"> 2a-3a </th>
                <td mat-cell *matCellDef="let insurance" class="rtalign p-1" (click)="onClick_expandIns(insurance)">
                        {{insurance.ag3y |
                        currency:'USD':'':'1.2-2'}}
                        <br><span class="font10">{{insurance.c3y}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef="let insurance" class="rtalign p-1"> {{tot.ag3y |
                        currency:'USD':'':'1.2-2'}} <br><span class="font10">{{tot.c3y}}</span>
                </td>
        </ng-container>

        <ng-container matColumnDef="balance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 0 0 0 32px;"> Deuda </th>
                <td mat-cell *matCellDef="let insurance" class="rtalign p-1" (click)="onClick_expandIns(insurance)">
                        {{insurance.balance |
                        currency:'USD':'':'1.2-2'}}
                        <br><span class="font10">{{insurance.tCount}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef="let insurance" class="rtalign p-1"> {{tot.balance |
                        currency:'USD':'':'1.2-2'}} <br><span class="font10">{{tot.tCount}}</span></td>
        </ng-container>

        <ng-container matColumnDef="printr">
                <th mat-header-cell *matHeaderCellDef style="padding: 0 0 0 16px;">
                        <div *ngIf="!insPrintAll" class="d-flex">
                                <div>
                                        <mat-icon class="maticon" style="cursor: pointer;"
                                                (click)="insPrintAll='true'; printRpt(undefined,false)">print</mat-icon>
                                </div>
                                <div>&nbsp;</div>
                                <div style="cursor: pointer;">
                                        <i class="far fa-file-excel"
                                                (click)="insPrintAll='true'; printRpt(undefined,true)"></i>
                                </div>
                        </div>
                        <img src="/app/assets/images/waiting4Response.gif" width="30" height="30" alt="spinner"
                                class="img-fluid" *ngIf="insPrintAll">
                </th>
                <td mat-cell *matCellDef="let insurance">
                        <div *ngIf="!insurance.printing" class="d-flex justify-content-between">
                                <div>
                                        <mat-icon class="maticon" style="cursor: pointer;"
                                                (click)="printRpt(insurance,false)">print</mat-icon>
                                </div>
                                <div>&nbsp;</div>
                                <div>
                                        <i class="far fa-file-excel" (click)="printRpt(insurance,true)"></i>
                                </div>
                        </div>
                        <img src="/app/assets/images/waiting4Response.gif" width="30" height="30" alt="spinner"
                                class="img-fluid" *ngIf="insurance.printing">
                </td>
                <td mat-footer-cell *matFooterCellDef="let insurance" class="p-1"></td>
        </ng-container>

        <ng-container matColumnDef="expandCol">
                <td mat-cell *matCellDef="let insurance" colspan="12">
                        <div *ngIf="clmExpanded.length && insurance.expandCol != ''">
                                <app-rpt-aging-claims [ins]="ins" [clm]="clmExpanded" [det]="detExpanded"
                                        [pad]="padExpanded"></app-rpt-aging-claims>
                        </div>
                </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="rptAgingDisplydCols; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: rptAgingDisplydCols;" class="insRow"
                [class.expandedRw]="isExpanded(row)"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandCol'];" [class.collapsedRow]="row.expandCol == ''">
        </tr>
        <tr mat-footer-row *matFooterRowDef="rptAgingDisplydCols"></tr>
</table>

<!-- <pre>{{ rptAgingData.ins | json }}</pre> -->