<div>

    <div class="d-flex justify-content-between" style="background-color: #cacafb;">
        <div class="pt-2">
            &nbsp; <!-- margin nor padding left working here ? -->
            <i class="fas fa-print small" placement="right" style="cursor: pointer;" (click)="print835check()"
                ngbTooltip="{{ engLang ? 'Print payment evidence': 'Imprimir evidencia del pago'}}"></i>
        </div>
        <div class="pt-2">
            <div class="d-flex justify-content-start pointer" (click)="sortClaims('srt835Nm');"
                ngbTooltip="{{ engLang ? 'Click to reorder': 'Clic para re-ordenar' }}" placement="right">
                <div class="srt835Nm small" hidden>
                    <i *ngIf="sortAsc" class="fas fa-arrow-up"></i>
                    <i *ngIf="!sortAsc" class="fas fa-arrow-down"></i>
                </div>
                <div>
                    {{ engLang ? 'Pat: ': 'Pac: '}}{{ cas.casPatLstNm }},&nbsp;{{ cas.casPatFstNm }}&nbsp;{{
                    cas.casPatMidNm }}
                </div>
            </div>
        </div>
        <div class="pt-2">
            <div class="d-flex justify-content-start pointer">
                <div class="srt835Cas small" (click)="sortClaims('srt835Cas');"
                    ngbTooltip="{{ engLang ? 'Click to reorder': 'Clic para re-ordenar' }}" placement="left" hidden>
                    <i *ngIf="sortAsc" class="fas fa-arrow-up"></i>
                    <i *ngIf="!sortAsc" class="fas fa-arrow-down"></i>
                </div>
                <div (click)="sortClaims('srt835Cas');"
                    ngbTooltip="{{ engLang ? 'Click to reorder': 'Clic para re-ordenar' }}" placement="left">
                    {{ engLang ? 'Claim: ': 'Caso: '}}
                </div>
                <div>
                    <button *ngIf="isIdValid(cas.casPatID)" type="button" class="btn btn-link small pt-0 pl-1"
                        (click)="xtPID = cas.casPatID; xtCID = cas.casID;
                        patNmModalHd = cas.casPatLstNm + ', ' + cas.casPatFstNm + ' ' + cas.casPatMidNm;
                        casNoModalHd = cas.casNo + (cas.casOfNo ? ' - ' + cas.casOfNo: '');
                        open(recordCaseModal, { backdrop: 'static', size: 'lg', scrollable: true }, 'recordCaseModal');">
                        {{ fmtCasNo(cas.casNo) }}<span *ngIf="cas.casOfNo">-{{ cas.casOfNo }}</span>
                    </button>
                    <button *ngIf="cas.casPsDesc == '???'" type="button" class="btn btn-warning btn-sm small p-0"
                        style="color: black; font-style: italic; cursor: pointer;"
                        ngbTooltip="{{ (cas.casPS=='1') ? 'Primario/Primary': (cas.casPS=='2' ? 'Secundario/Secondary': 'Caso desconocido - clic para parear/ Unknown claim - click to match') }}"
                        placement="bottom"
                        (click)="match835Payments2casesManual(cas); open(matchCaseManualModal, { backdrop: 'static', size: 'lg', scrollable: true }, 'matchCaseManualModal');">
                        {{ engLang ? 'Match': 'Parear' }}
                    </button>
                </div>
            </div>
            <div>
                <span *ngIf="cas.casPsDesc != '???'" style="color: green; font-weight: bold; font-style: italic;"
                    ngbTooltip="{{engLang ? 'Pri = primary, Sec = secondary': 'Pri = primario, Sec = secundario'}}">
                    {{ cas.casPsDesc + ': ' + cas.ins }}
                </span>

            </div>
        </div>
        <div class="pt-2">
            <div class="d-flex justify-content-start pointer">
                <div (click)="copy2ClipBoard(cas.casContNo,'Contract #')">
                    <img src="/app/assets/images/clipboard-copy-small.png" width="15px" height="15px"
                        alt="Clipboard copy">
                </div>
                <div>
                    <div class="d-flex justify-content-start" (click)="sortClaims('srt835Contr');"
                        ngbTooltip="{{ engLang ? 'Click to reorder': 'Clic para re-ordenar' }}" placement="left">
                        <div class="srt835Contr small pt-1" hidden>
                            <i *ngIf="sortAsc" class="fas fa-arrow-up"></i>
                            <i *ngIf="!sortAsc" class="fas fa-arrow-down"></i>
                        </div>
                        <div>
                            {{ engLang ? 'Contract #: ': 'Contrato #: '}}{{ cas.casContNo }}
                            &nbsp; <!-- Acts as right margin -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <table class="table table-striped table-sm">
        <thead class="small">
            <tr style="vertical-align: middle;">
                <th scope="col" class="text-left vmid">
                    <div class="d-flex flex-column justify-content-start">
                        <div>
                            {{ engLang ? 'From': 'Desde' }}
                        </div>
                        <div>
                            {{ engLang ? 'To': 'Hasta' }}
                        </div>
                    </div>
                </th>
                <th scope="col" class="vmid">CPT - Mods</th>
                <th scope="col" class="text-center vmid">{{ engLang ? 'POS': 'Lug' }}</th>
                <th scope="col" class="text-center vmid">RARC</th>
                <th scope="col" class="text-center vmid">{{ engLang ? 'Qty': 'Cant' }}</th>
                <th scope="col" class="text-center vmid">{{ engLang ? 'Adjust': 'Ajuste' }}</th>
                <th scope="col" class="rgtAlign vmid">{{ engLang ? 'Submitted': 'Sometido'}}</th>
                <th scope="col" class="rgtAlign vmid">{{ engLang ? 'Allowed': 'Permitido'}}</th>
                <th scope="col" class="rgtAlign vmid">Tax</th>
                <th scope="col" class="rgtAlign vmid">{{ engLang ? 'Payed': 'Pago'}}</th>
            </tr>
        </thead>
        <tbody class="small">
            <tr *ngFor="let svc of _835svc | casSeqNoPipe: casSeqNo: undefined; index as i" #row835detail>
                <td>
                    <div class="d-flex justify-content-start">
                        <div>
                            {{ svc.svcDtFrom ? svc.svcDtFrom.substring(4,6) + '-' + svc.svcDtFrom.substring(6,8) + '-' +
                            svc.svcDtFrom.substring(2,4): '' }}
                        </div>
                        <div>
                            {{ svc.svcDtTo ? svc.svcDtTo.substring(4,6) + '-' + svc.svcDtTo.substring(6,8) + '-' +
                            svc.svcDtTo.substring(2,4): '' }}
                        </div>
                    </div>
                </td>
                <td class="text-left">
                    {{ svc.svcProc }}&nbsp;{{ svc.svcMod1 }}&nbsp;{{ svc.svcMod2 }}&nbsp;{{ svc.svcMod3 }}&nbsp;{{
                    svc.svcMod4 }}
                </td>
                <td class="text-center">
                    {{ svc.svcRefLu }}
                </td>
                <td>
                    <ul>
                        <li *ngFor="let rarc of uniqueRarcs(svc.svcHeRem)" style="display: inline-block;">
                            <button type="button" class="btn btn-link small p-0 mx-1 my-0"
                                (click)="getCarcDescrFromAdj({ adjGrpCode: 'RARC', adjReasonCode: rarc })">
                                <span class="xSmallFont">
                                    {{ rarc }}
                                </span>
                            </button>
                        </li>
                    </ul>
                </td>
                <td class="text-center">
                    {{ svc.svcQty }}
                </td>
                <td class="p-0">
                    <ul>
                        <li *ngFor="let adj of _835adj | casSeqNoPipe: casSeqNo: svc.svcSeqNo; index as i">
                            <div class="d-inline-flex justify-content-between"
                                style="width: 100%; white-space: nowrap; overflow: hidden;">
                                <div style="margin-top: -2px;">
                                    <button type="button" class="btn btn-link small p-0" *ngIf="adj.adjReasonCode"
                                        (click)="getCarcDescrFromAdj(adj)">
                                        <span class="xSmallFont">
                                            {{ adj.adjGrpCode }}&nbsp;
                                            {{ adj.adjReasonCode }}
                                        </span>
                                    </button>
                                </div>
                                <div class="d-inline-flex justify-content-between" style="width: 100%;">
                                    <!--  style="padding-top: 2px;" -->
                                    <div>
                                        {{ adj.adjQty }}
                                    </div>
                                    <div>
                                        &nbsp;
                                    </div>
                                    <div class="rgtAlign">
                                        {{ adj.adjAmount | currency:'USD':'':'1.2-2' }}
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </td>
                <td>
                    <div class="currency">
                        {{ svc.svcBilledAmnt | currency:'USD':'':'1.2-2' }}
                    </div>
                </td>
                <td>
                    <div class="currency">
                        {{ svc.svcAllowAmnt | currency:'USD':'':'1.2-2' }}
                    </div>
                </td>
                <td>
                    <div class="currency">
                        {{ svc.svcTaxAmnt | currency:'USD':'':'1.2-2' }}
                    </div>
                </td>
                <td>
                    <div class="currency">
                        {{ svc.svcPayedAmnt | currency:'USD':'':'1.2-2' }}
                    </div>
                </td>
            </tr>

            <tr>
                <td class="small vmid" colspan="2">
                    <div (click)="copy2ClipBoard(cas.casPayICN, 'ICN')">
                        ICN:&nbsp;{{ cas.casPayICN }}
                        <img src="/app/assets/images/clipboard-copy-small.png" width="15px" height="15px"
                            alt="Clipboard copy" style="cursor: pointer;">
                    </div>
                </td>
                <td></td>
                <td colspan="2">
                    <div class="d-inline-flex justify-content-between">
                        <div class="form-check form-check-inline">
                            <input type="checkbox" id="chkAdj{{cas.indx}}" class="form-check-input"
                                [checked]="(cas.casAdjed == '1' ? true: false)"
                                [attr.disabled]="(cas.casPsDesc === '???' || +cas.casRecled || (+cas.casBal === 0 && cas.casAdjed !== '1')) ? true: null"
                                (click)="onChange_caseAdjust($event)">

                            <label for="chkAdj{{cas.indx}}" style="font-size: small" class="form-check-label"
                                ngbTooltip="{{ engLang ? 'Adjust': 'Ajustar' }}">&nbsp;A</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="checkbox" id="chkRecl{{cas.indx}}" class="form-check-input"
                                [checked]="+cas.casRecled ? true: false"
                                [disabled]="(cas.casPsDesc === '???' || +cas.casAdjed || (+cas.casBal && !+cas.casRecled))"
                                (change)="onChange_caseReclaim($event);">

                            <label for="chkRecl{{cas.indx}}" style="font-size: small" class="form-check-label"
                                ngbTooltip="{{ engLang ? 'Reclaim': 'Reclamar' }}">&nbsp;R</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="checkbox" id="chkCorr{{cas.indx}}" class="form-check-input"
                                [checked]="(cas.casCorrection == '1' ? true: false)"
                                [attr.disabled]="(cas.casPsDesc === '???') || (cas.casAdjed == '1' ? true: null)">

                            <label for="chkCorr{{cas.indx}}" style="font-size: small" class="form-check-label"
                                ngbTooltip="{{ engLang ? 'Correction': 'Corrección' }}">&nbsp;C</label>
                        </div>
                    </div>
                </td>
                <td colspan="2" class="xSmallFont vmid">{{ engLang ? 'Patient: ': 'Paciente: ' }} {{ cas.casPatRespAmnt
                    |
                    currency:'USD':'':'1.2-2' }}
                    <div class="small" style="display: inline; float: right; font-weight: bolder;">
                        <em>Balance:</em>
                    </div>
                </td>

                <!-- The following works aasigning next td contents a variable font-size as per length but it's a performance hog -->
                <!-- [ngStyle]="{ 'font-size': adjustFontSize('spanBal' + cas.casSeqNo + cas.casPS) }" -->
                <td colspan="2" class="vmid">
                    <span id="{{'spanBal' + cas.casSeqNo + cas.casPS}}" style="white-space: nowrap; overflow: hidden;">
                        <span [ngStyle]="{ 'color': (cas.casPsDesc === '???' ? 'red': 'black') }"
                            ngbTooltip="{{ engLang ? 'Current balance': 'Balance actual' }}">
                            {{ cas.casPsDesc === '???' ? cas.casPsDesc : cas.casBal | currency:'USD':'':'1.2-2' }}
                        </span>&nbsp;-&nbsp;
                        <span id="{{'spanPay' + cas.casSeqNo + cas.casPS}}"
                            [class]="{'strike': strikeCreditedPayment(cas.casSeqNo)}" ngbTooltip="{{
                        paymetSpanToolTipText(cas.casSeqNo,cas.casPS) }}">
                            {{ cas.casThisPayment | currency:'USD':'':'1.2-2' }}
                        </span>=
                    </span>
                </td>
                <td colspan="2" class="small vmid" style="text-align: right;">
                    <span [class]="balanceColrClass(cas)"
                        ngbTooltip="{{ balanceSpanToolTipText(cas.casSeqNo,cas.casPS) }}" placement="left">
                        {{ caseBalance(cas) == '???' ? cas.casPsDesc: caseBalance(cas) | currency:'USD':'':'1.2-2' }}
                    </span>
                </td>
            </tr>

            <tr class="small">
                <td class="p-0">
                    {{ engLang ? 'Other ins.:': 'Otro Seg.:'}}
                </td>
                <td colspan="4" class="p-0">
                    {{ cas.casCrossOverPayerNm }}
                </td>
                <td colspan="4" class="small p-0">
                    <div class="d-flex justify-content-between">
                        <div>
                            <button type="button" class="btn btn-link small p-0"
                                (click)="getCarcDescrFromAdj(cas.casMoa01)">
                                {{ cas.casMoa01 }}
                            </button>
                        </div>
                        <div>
                            <button type="button" class="btn btn-link small p-0"
                                (click)="getCarcDescrFromAdj(cas.casMoa02)">
                                {{ cas.casMoa02 }}
                            </button>
                        </div>
                        <div>
                            <button type="button" class="btn btn-link small p-0"
                                (click)="getCarcDescrFromAdj(cas.casMoa03)">
                                {{ cas.casMoa03 }}
                            </button>
                        </div>
                        <div>
                            <button type="button" class="btn btn-link small p-0"
                                (click)="getCarcDescrFromAdj(cas.casMoa04)">
                                {{ cas.casMoa04 }}
                            </button>
                        </div>
                        <div>
                            <button type="button" class="btn btn-link small p-0"
                                (click)="getCarcDescrFromAdj(cas.casMoa05)">
                                {{ cas.casMoa05 }}
                            </button>
                        </div>
                        <div>
                            <button type="button" class="btn btn-link small p-0"
                                (click)="getCarcDescrFromAdj(cas.casMoa06)">
                                {{ cas.casMoa06 }}
                            </button>
                        </div>
                        <div>
                            <button type="button" class="btn btn-link small p-0"
                                (click)="getCarcDescrFromAdj(cas.casMoa07)">
                                {{ cas.casMoa07 }}
                            </button>
                        </div>
                        <div>
                            <button type="button" class="btn btn-link small p-0"
                                (click)="getCarcDescrFromAdj(cas.casMoa08)">
                                {{ cas.casMoa08 }}
                            </button>
                        </div>
                        <div>
                            <button type="button" class="btn btn-link small p-0"
                                (click)="getCarcDescrFromAdj(cas.casMoa09)">
                                {{ cas.casMoa09 }}
                            </button>
                        </div>
                    </div>
                </td>
                <td></td>
            </tr>
        </tbody>
    </table>

    <!-- <pre>{{ modalStack.length }} {{ modalStack }}</pre> -->
    <!-- <pre>{{ cas | json }}</pre> -->
    <!-- <pre>{{ _835cas | json }}</pre> -->
    <!-- <pre>{{ _835svc | json }}</pre> -->
    <!-- <pre>{{ _835adj | json }}</pre> -->
    <!-- <pre>{{ cas | json }}</pre> -->

</div>

<!-- modal record/case ................................................................................... -->
<ng-template #recordCaseModal let-modal>
    <div class="modal-header p-8" style="background-color: #ecead6;">
        <div>
            Record: <strong>{{ patNmModalHd }}</strong> <br />
            {{ engLang ? 'Claim: ': 'Caso: ' }}{{ casNoModalHd }}
        </div>
        <div>
            <button type="button" class="btn btn-sm btn-close" style="margin-top: -30px;"
                (click)="modal.dismiss('recordCaseModal')"></button>
        </div>
    </div>

    <div class="modal-body" #modalBody>
        <app-record activeTab="2" xtPID="{{xtPID}}" xtCID="{{xtCID}}" [modalBody]="modalBody"></app-record>
    </div>

    <div class="modal-footer d-sm-inline-flex justify-content-between p-8" style="background-color: #ecead6;">
        <div>
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
        </div>
        <div>
            <button type="button" class="btn btn-sm btn-outline-dark" *ngIf="!waiting4Response"
                (click)="modal.close('oK_recordCaseModal')">{{ engLang ? 'Close': 'Cerrar' }}</button>
        </div>
    </div>

    <!-- <pre>{{ modalStack.length }}</pre> -->
    <!-- <pre>{{ modalStack }}</pre> -->
    <!-- <pre>{{ xtPID }}</pre>
        <pre>{{ xtCID }}</pre> -->
</ng-template>

<!-- modal manually match incoming case ........................................................................... -->
<ng-template #matchCaseManualModal let-modal>
    <div class="modal-header" style="background-color: #c7a3dc;">
        <div>
            <h4 class="modal-title">
                {{ engLang ? 'Could not pair these payments. Try pair claim manually.': 'Pagos no pareados.
                Trate parear caso manualmente.' }}
            </h4>
        </div>
        <div *ngIf="waiting4Response">
            <img src="app/assets/images/waiting4Response.gif" width="42" height="42" style="margin: -5px 0 0 -50px;">
        </div>
        <div style="margin: -30px 0 0 20px;">
            <button type="button" class="btn btn-sm btn-close" (click)="modal.dismiss('recordCaseModal')"></button>
        </div>
    </div>

    <div class="p-1">
        <div class="text-center">
            {{ engLang ? 'Select criteria to search by per the following received data:': 'Seleccione criterio
            para búsqueda según siguientes datos recibidos:' }}
        </div>
        <div>
            <div class="d-flex pl-3 justify-content-between">
                <div>
                    <input type="radio" id="matchLastNm" name="matchCrit" class="form-check-input"
                        (change)="onChange_MchLastNm(cas)" value="true" checked>

                    <label for="matchLastNm" class="small pr-1">{{
                        engLang ? 'Last name:': 'Apellidos:'
                        }}&nbsp;[<span style="font-weight: bold;">{{ cas.casPatLstNm
                            }}</span>]&nbsp;</label>
                </div>
                <div>
                    <input type="radio" id="matchFirstNm" name="matchCrit" class="form-check-input"
                        (change)="onChange_MchFirstNm()" value="true">

                    <label for="matchFirstNm" class="small pr-1">&nbsp;{{
                        engLang ? 'Name:': 'Nombre:' }} [<span style="font-weight: bold;">{{
                            cas.casPatFstNm
                            }}</span>]&nbsp;</label>
                </div>
                <div>
                    <input type="radio" id="matchContr" name="matchCrit" class="form-check-input"
                        (change)="onChange_MchContr()" value="true">

                    <label for="matchContr" class="small pr-1">&nbsp;{{ engLang ? 'Contract
                        #:':
                        'Contrato #: ' }}[<span style="font-weight: bold;">{{
                            cas.casContNo }}</span>]&nbsp;</label>
                </div>
                <div>
                    <input type="radio" id="matchCasNo" name="matchCrit" class="form-check-input"
                        (change)="onChange_MchCasNo()" value="true">

                    <label for="matchCasNo" class="small pr-1">&nbsp;{{ engLang ? 'Claim #:': 'Caso
                        #:'
                        }}&nbsp;[<span style="font-weight: bold;">{{ cas.casNo
                            }}</span>]</label>
                </div>
            </div>

            <div class="d-flex justify-content-around">
                <div>
                    <input type="checkbox" id="matchNpi" class="form-check-input" (click)="onClick_MchNpi()" checked>

                    <label for="matchNpi" style="font-size: small">&nbsp;NPI
                        Prov:&nbsp;[<span style="font-weight: bold;">{{ npi }}</span>]</label>
                </div>
                <div>
                    <input type="checkbox" id="matchPayerID" class="form-check-input" (click)="onClick_MchPayerID()"
                        checked>

                    <label for="matchPayerID" style="font-size: small">&nbsp;Payer
                        ID:&nbsp;[<span style="font-weight: bold;">{{ payerId }}</span>]</label>
                </div>
                <div>
                    <input type="checkbox" id="match0Balance" class="form-check-input" [(ngModel)]="mcBalZeroFiltr"
                        (change)="onChange_MchBalanceZero()">

                    <label for="match0Balance" style="font-size: small">&nbsp;Balance = 0.00</label>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-body p-1">
        <div class="pt-2">
            <table class="table table-striped table-sm" #tab835manualMatch>
                <thead class="small">
                    <th class="hide">casID</th>
                    <th scope="col" class="text-left vmid"><i class="fas fa-check"></i></th>
                    <th scope="col" class="text-center vmid">{{ engLang ? 'Last Nm': 'Apellidos' }}</th>
                    <th scope="col" class="text-center vmid">{{ engLang ? 'First Nm': 'Nombre' }}</th>
                    <th scope="col" class="text-center vmid">{{ engLang ? 'Contract #': 'Contrato #' }}</th>
                    <th scope="col" class="text-center vmid">{{ engLang ? 'Claim': 'Caso'}}</th>
                    <th scope="col" class="text-center vmid px-0"><span placement="right"
                            ngbTooltip="{{ engLang ? 'Primary = 1 Secondary = 2.': 'Primario = 1 Secundario = 2.' }}">½</span>
                    </th>
                    <th scope="col" class="text-center vmid">Prov</th>
                    <th scope="col" class="text-center vmid">{{ engLang ? 'Ins': 'Seg'}}</th>
                    <th scope="col" class="text-center vmid">{{ engLang ? 'Date': 'Fecha'}}</th>
                    <th scope="col" class="text-center vmid">{{ engLang ? 'Procedures': 'Procedimientos'}}</th>
                </thead>
                <tbody id="tab835manualMatchBody">
                    <tr *ngFor="let mc of mcs | mcFilterPipe: mcParamFiltr: mcBalZeroFiltr: mcNpiFiltr: mcPayerIdFiltr index as i"
                        class="small">
                        <td class="hide">
                            {{ mc.mcCasID }}
                        </td>
                        <td>
                            <input type="checkbox" id="{{ mc.mcCasID + '_' + mc.mcCasPS }}"
                                ngbTooltip="{{ engLang ? 'Select to match with this claim.': 'Seleccione para parear con este caso.' }}"
                                placement="right" (change)="onChange_MatchCase($event, mc)"
                                [(ngModel)]="mc['mcSelect']">
                        </td>
                        <td width="125px">
                            {{ mc.mcLstNm }}
                        </td>
                        <td width="75px">
                            {{ mc.mcFstNm }}<br />
                            <span *ngIf="mc.mcBalance >= .01 || mc.mcBalance <= -.01" class="xSmallFont"
                                [ngStyle]="{ 'color': redBalColr(mc.mcBalance) }">Bal:&nbsp;{{ toFloat(mc.mcBalance) |
                                currency:'USD':'':'1.2-2' }}</span>
                        </td>
                        <td>
                            {{ mc.mcContNo }}<br />
                            <span class="xSmallFont"
                                ngbTooltip="{{ engLang ? 'Previously paid': 'Pagado anteriormente'}}">{{
                                engLang ? 'Prev paid: ': 'Pago ant: ' }}{{
                                toFloat(mc.mcTotPayed) |
                                currency:'USD':'':'1.2-2' }}</span>
                        </td>
                        <td>
                            <button type="button" class="btn btn-link small px-0 py-0"
                                (click)="xtPID = mc.mcPatID; xtCID = mc.mcCasID;
                                        patNmModalHd = mc.mcLstNm + ', ' + mc.mcFstNm + ' ' + mc.mcMidNm;
                                        casNoModalHd = mc.mcCasNo + ' - ' + mc.mcCasOfNo;
                                        open(recordCaseModal, { backdrop: 'static', size: 'lg', scrollable: true }, 'recordCaseModal');">
                                {{ mc.mcCasNo }}<span *ngIf="mc.mcCasOfNo">-{{ mc.mcCasOfNo }}</span>
                            </button>
                        </td>
                        <td class="xSmallFont text-center px-0" width="10">
                            {{ mc.mcCasPS }}
                        </td>
                        <td width="70px">
                            {{ '(' + mc.mcProvID + ')' }}-<span class="xSmallFont">{{ mc.mcProv.substring(0,15)
                                }} {{ mc.mcNpi }}</span>
                        </td>
                        <td width="70px">
                            {{ '(' + mc.mcInsID + ')' }}-<span class="xSmallFont">{{ mc.mcIns.substring(0,15) }} {{
                                mc.mcPayerId }}</span>
                        </td>
                        <td class="xSmallFont">
                            {{ mc.mcCasDtFmt }}
                        </td>
                        <td>
                            <table class="table table-striped table-sm mcDets">
                                <tr *ngFor="let mcDet of mcsDet | detPcodeFilter : mc.mcCasID : mc.mcCasPS"
                                    placement="left" class="small"
                                    ngbTooltip="{{ engLang ? 'CPT-Mod Date Qty': 'CPT-Mod Desde Cant'}}">
                                    <td class="p-0 xSmallFont" width="10px">
                                        <div *ngIf="mcDet.mcDetMatched" class="text-center vmid">
                                            <i class="fas fa-check" style="color:green;"></i>
                                        </div>
                                        <div *ngIf="!mcDet.mcDetMatched" class="text-center vmid">
                                            <i class="fas fa-times" style="color:red;"></i>
                                        </div>
                                    </td>
                                    <td class="xSmallFont">
                                        {{ mcDet.mcDetPcode }}&nbsp;{{ mcDet.mcDetMods }}
                                    </td>
                                    <td class="xSmallFont">
                                        {{ mcDet.mcDetFromDtFmt }}
                                    </td>
                                    <td class="xSmallFont">
                                        {{ mcDet.mcDetQty }}
                                    </td>
                                    <td class="hide">
                                        {{ mcDet.mcDetCasID }}
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- <pre>mcs = {{ mcs | json }}</pre> -->
        <!-- <pre>mcsDet = {{ mcsDet | json }}</pre> -->

    </div>

    <div class="modal-footer justify-content-between" style="background-color: #c7a3dc;">
        <div style="font-size: 70%;">
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
        </div>
        <div>
            {{ matchedMcsArrCnt + (engLang ? ' candidates': ' candidatos') }}
        </div>
        <div>
            <button type="button" class="btn btn-sm btn-outline-dark" *ngIf="!waiting4Response"
                (click)="modal.close('oK_matchCaseModal')" [disabled]="enableMatchBtn()">{{ engLang ? 'Match': 'Parear'
                }}
            </button>
        </div>
    </div>

    <!-- <pre>{{ modalStack.length }}</pre> -->
    <!-- <pre>{{ modalStack }}</pre> -->

</ng-template>

<div *ngIf="adjustPromptModal">
    <app-claim-adjust-modal-prompt [engLang]="engLang" [adjustRemove]="adjustRemove" [undoPayment]=false
        (adjModalPromptResult)="adjustModalResult($event)">
    </app-claim-adjust-modal-prompt>
</div>