<div style="position: relative;">
    <!-- Use id="ReclaimFormFields" only to get column ReclFrmXml for table [ReclaimForms] with rtn createReclaimXml_developmentHelperRtn() -->

    <div>
        <img [src]="reclFormImg64Seled" alt="Form Image" width="100%" height="100%">
    </div>

    <!-- <txtHUMProvNm></txtHUMProvNm> -->
    <div class="txtHUMProvNm">
        <input type="text" id="txtHUMProvNm" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMProvNm"
            size="29">
    </div>

    <!-- <txtHUMNPI></txtHUMNPI> -->
    <div class="txtHUMNPI">
        <input type="text" id="txtHUMNPI" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMNPI" size="12">
    </div>

    <!-- <txtHUMDate></txtHUMDate> -->
    <div class="txtHUMDate">
        <input type="text" id="txtHUMDate" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMDate" size="12">
    </div>

    <!-- <txtHUMAd1></txtHUMAd1> -->
    <div class="txtHUMAd1">
        <input type="text" id="txtHUMAd1" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMAd1" size="45">
    </div>

    <!-- <txtHUMAd2></txtHUMAd2> -->
    <div class="txtHUMAd2">
        <input type="text" id="txtHUMAd2" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMAd2" size="45">
    </div>

    <!-- <txtHUMAd3></txtHUMAd3> -->
    <div class="txtHUMAd3">
        <input type="text" id="txtHUMAd3" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMAd3" size="45">
    </div>

    <!-- <txtProvEmail></txtProvEmail> -->
    <div class="txtProvEmail">
        <input type="text" id="txtProvEmail" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtProvEmail"
            size="45">
    </div>

    <!-- <optHUMMedicare></optHUMMedicare> -->
    <div class="form-check-input optHUMMedicare">
        <input type="radio" id="optHUMMedicare" name="a" (change)="onChange_need2Save($event)" [(ngModel)]="e.plan"
            value="medicare">
    </div>

    <!-- <optHUMComercial></optHUMComercial> -->
    <div class="form-check-input optHUMComercial">
        <input type="radio" id="optHUMComercial" name="a" (change)="onChange_need2Save($event)" [(ngModel)]="e.plan"
            value="comercial">
    </div>

    <!-- <optHUMReforma></optHUMReforma> -->
    <div class="form-check-input optHUMReforma">
        <input type="radio" id="optHUMReforma" name="a" (change)="onChange_need2Save($event)" [(ngModel)]="e.plan"
            value="reforma">
    </div>

    <!-- <chkHUMPFFS></chkHUMPFFS> -->
    <div class="form-check-input chkHUMPFFS">
        <input type="checkbox" id="chkHUMPFFS" (change)="onChange_need2Save($event)" [(ngModel)]="e.chkHUMPFFS">
    </div>

    <!-- <txtHUMContr1></txtHUMContr1> -->
    <div class="txtHUMContr1">
        <input type="text" id="txtHUMContr1" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMContr1"
            size="15">
    </div>

    <!-- <txtHUMIcn1></txtHUMIcn1> -->
    <div class="txtHUMIcn1">
        <input type="text" id="txtHUMIcn1" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMIcn1" size="18">
    </div>

    <!-- <txtHUMNm1></txtHUMNm1> -->
    <div class="txtHUMNm1">
        <input type="text" id="txtHUMNm1" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMNm1" size="16"
            [ngStyle]="{ 'display': e.plan === 'medicare' ? 'block' : 'none' }">
    </div>

    <!-- <txtHUMFrom1></txtHUMFrom1> -->
    <div class="txtHUMFrom1">
        <input type="text" id="txtHUMFrom1" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMFrom1" size="10"
            mask="90/90/00">
    </div>

    <!-- <txtHUMBal1></txtHUMBal1> -->
    <div class="txtHUMBal1">
        <input type="text" id="txtHUMBal1" class="currcy" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMBal1" size="10" maxlength="10" mask="separator.2" thousandSeparator=",">
    </div>

    <!-- <txtHUMCom1></txtHUMCom1> -->
    <div class="txtHUMCom1">
        <input type="text" id="txtHUMCom1" class="font9" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMCom1" size="58">
    </div>

    <!-- <txtHUMContr2></txtHUMContr2> -->
    <div class="txtHUMContr2">
        <input type="text" id="txtHUMContr2" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMContr2"
            size="15">
    </div>

    <!-- <txtHUMIcn2></txtHUMIcn2> -->
    <div class="txtHUMIcn2">
        <input type="text" id="txtHUMIcn2" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMIcn2" size="18">
    </div>

    <!-- <txtHUMNm2></txtHUMNm2> -->
    <div class="txtHUMNm2">
        <input type="text" id="txtHUMNm2" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMNm2" size="16"
            [ngStyle]="{ 'display': e.plan === 'medicare' ? 'block' : 'none' }">
    </div>

    <!-- <txtHUMFrom2></txtHUMFrom2> -->
    <div class="txtHUMFrom2">
        <input type="text" id="txtHUMFrom2" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMFrom2" size="10"
            mask="90/90/00">
    </div>

    <!-- <txtHUMBal2></txtHUMBal2> -->
    <div class="txtHUMBal2">
        <input type="text" id="txtHUMBal2" class="currcy" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMBal2" size="10" maxlength="10" mask="separator.2" thousandSeparator=",">
    </div>

    <!-- <txtHUMCom2></txtHUMCom2> -->
    <div class="txtHUMCom2">
        <input type="text" id="txtHUMCom2" class="font9" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMCom2" size="58">
    </div>

    <!-- <txtHUMContr3></txtHUMContr3> -->
    <div class="txtHUMContr3">
        <input type="text" id="txtHUMContr3" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMContr3"
            size="15">
    </div>

    <!-- <txtHUMIcn3></txtHUMIcn3> -->
    <div class="txtHUMIcn3">
        <input type="text" id="txtHUMIcn3" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMIcn3" size="18">
    </div>

    <!-- <txtHUMNm3></txtHUMNm3> -->
    <div class="txtHUMNm3">
        <input type="text" id="txtHUMNm3" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMNm3" size="16"
            [ngStyle]="{ 'display': e.plan === 'medicare' ? 'block' : 'none' }">
    </div>

    <!-- <txtHUMFrom3></txtHUMFrom3> -->
    <div class="txtHUMFrom3">
        <input type="text" id="txtHUMFrom3" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMFrom3" size="10"
            mask="90/90/00">
    </div>

    <!-- <txtHUMBal3></txtHUMBal3> -->
    <div class="txtHUMBal3">
        <input type="text" id="txtHUMBal3" class="currcy" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMBal3" size="10" maxlength="10" mask="separator.2" thousandSeparator=",">
    </div>

    <!-- <txtHUMCom3></txtHUMCom3> -->
    <div class="txtHUMCom3">
        <input type="text" id="txtHUMCom3" class="font9" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMCom3" size="58">
    </div>

    <!-- <txtHUMContr4></txtHUMContr4> -->
    <div class="txtHUMContr4">
        <input type="text" id="txtHUMContr4" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMContr4"
            size="15">
    </div>

    <!-- <txtHUMIcn4></txtHUMIcn4> -->
    <div class="txtHUMIcn4">
        <input type="text" id="txtHUMIcn4" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMIcn4" size="18">
    </div>

    <!-- <txtHUMNm4></txtHUMNm4> -->
    <div class="txtHUMNm4">
        <input type="text" id="txtHUMNm4" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMNm4" size="16"
            [ngStyle]="{ 'display': e.plan === 'medicare' ? 'block' : 'none' }">
    </div>

    <!-- <txtHUMFrom4></txtHUMFrom4> -->
    <div class="txtHUMFrom4">
        <input type="text" id="txtHUMFrom4" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMFrom4" size="10"
            mask="90/90/00">
    </div>

    <!-- <txtHUMBal4></txtHUMBal4> -->
    <div class="txtHUMBal4">
        <input type="text" id="txtHUMBal4" class="currcy" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMBal4" size="10" maxlength="10" mask="separator.2" thousandSeparator=",">
    </div>

    <!-- <txtHUMCom4></txtHUMCom4> -->
    <div class="txtHUMCom4">
        <input type="text" id="txtHUMCom4" class="font9" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMCom4" size="58">
    </div>

    <!-- <txtHUMContr5></txtHUMContr5> -->
    <div class="txtHUMContr5">
        <input type="text" id="txtHUMContr5" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMContr5"
            size="15">
    </div>

    <!-- <txtHUMIcn5></txtHUMIcn5> -->
    <div class="txtHUMIcn5">
        <input type="text" id="txtHUMIcn5" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMIcn5" size="18">
    </div>

    <!-- <txtHUMNm5></txtHUMNm5> -->
    <div class="txtHUMNm5">
        <input type="text" id="txtHUMNm5" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMNm5" size="16"
            [ngStyle]="{ 'display': e.plan === 'medicare' ? 'block' : 'none' }">
    </div>

    <!-- <txtHUMFrom5></txtHUMFrom5> -->
    <div class="txtHUMFrom5">
        <input type="text" id="txtHUMFrom5" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMFrom5" size="10"
            mask="90/90/00">
    </div>

    <!-- <txtHUMBal5></txtHUMBal5> -->
    <div class="txtHUMBal5">
        <input type="text" id="txtHUMBal5" class="currcy" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMBal5" size="10" maxlength="10" mask="separator.2" thousandSeparator=",">
    </div>

    <!-- <txtHUMCom5></txtHUMCom5> -->
    <div class="txtHUMCom5">
        <input type="text" id="txtHUMCom5" class="font9" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMCom5" size="58">
    </div>

    <!-- <txtHUMContr6></txtHUMContr6> -->
    <div class="txtHUMContr6">
        <input type="text" id="txtHUMContr6" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMContr6"
            size="15">
    </div>

    <!-- <txtHUMIcn6></txtHUMIcn6> -->
    <div class="txtHUMIcn6">
        <input type="text" id="txtHUMIcn6" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMIcn6" size="18">
    </div>

    <!-- <txtHUMNm6></txtHUMNm6> -->
    <div class="txtHUMNm6">
        <input type="text" id="txtHUMNm6" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMNm6" size="16"
            [ngStyle]="{ 'display': e.plan === 'medicare' ? 'block' : 'none' }">
    </div>

    <!-- <txtHUMFrom6></txtHUMFrom6> -->
    <div class="txtHUMFrom6">
        <input type="text" id="txtHUMFrom6" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMFrom6" size="10"
            mask="90/90/00">
    </div>

    <!-- <txtHUMBal6></txtHUMBal6> -->
    <div class="txtHUMBal6">
        <input type="text" id="txtHUMBal6" class="currcy" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMBal6" size="10" maxlength="10" mask="separator.2" thousandSeparator=",">
    </div>

    <!-- <txtHUMCom6></txtHUMCom6> -->
    <div class="txtHUMCom6">
        <input type="text" id="txtHUMCom6" class="font9" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMCom6" size="58">
    </div>

    <!-- <txtHUMContr7></txtHUMContr7> -->
    <div class="txtHUMContr7">
        <input type="text" id="txtHUMContr7" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMContr7"
            size="15">
    </div>

    <!-- <txtHUMIcn7></txtHUMIcn7> -->
    <div class="txtHUMIcn7">
        <input type="text" id="txtHUMIcn7" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMIcn7" size="18">
    </div>

    <!-- <txtHUMNm7></txtHUMNm7> -->
    <div class="txtHUMNm7">
        <input type="text" id="txtHUMNm7" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMNm7" size="16"
            [ngStyle]="{ 'display': e.plan === 'medicare' ? 'block' : 'none' }">
    </div>

    <!-- <txtHUMFrom7></txtHUMFrom7> -->
    <div class="txtHUMFrom7">
        <input type="text" id="txtHUMFrom7" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMFrom7" size="10"
            mask="90/90/00">
    </div>

    <!-- <txtHUMBal7></txtHUMBal7> -->
    <div class="txtHUMBal7">
        <input type="text" id="txtHUMBal7" class="currcy" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMBal7" size="10" maxlength="10" mask="separator.2" thousandSeparator=",">
    </div>

    <!-- <txtHUMCom7></txtHUMCom7> -->
    <div class="txtHUMCom7">
        <input type="text" id="txtHUMCom7" class="font9" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMCom7" size="58">
    </div>

    <!-- <txtHUMContr8></txtHUMContr8> -->
    <div class="txtHUMContr8">
        <input type="text" id="txtHUMContr8" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMContr8"
            size="15">
    </div>

    <!-- <txtHUMIcn8></txtHUMIcn8> -->
    <div class="txtHUMIcn8">
        <input type="text" id="txtHUMIcn8" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMIcn8" size="18">
    </div>

    <!-- <txtHUMNm8></txtHUMNm8> -->
    <div class="txtHUMNm8">
        <input type="text" id="txtHUMNm8" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMNm8" size="16"
            [ngStyle]="{ 'display': e.plan === 'medicare' ? 'block' : 'none' }">
    </div>

    <!-- <txtHUMFrom8></txtHUMFrom8> -->
    <div class="txtHUMFrom8">
        <input type="text" id="txtHUMFrom8" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMFrom8" size="10"
            mask="90/90/00">
    </div>

    <!-- <txtHUMBal8></txtHUMBal8> -->
    <div class="txtHUMBal8">
        <input type="text" id="txtHUMBal8" class="currcy" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMBal8" size="10" maxlength="10" mask="separator.2" thousandSeparator=",">
    </div>

    <!-- <txtHUMCom8></txtHUMCom8> -->
    <div class="txtHUMCom8">
        <input type="text" id="txtHUMCom8" class="font9" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMCom8" size="58">
    </div>

    <!-- <txtHUMContr9></txtHUMContr9> -->
    <div class="txtHUMContr9">
        <input type="text" id="txtHUMContr9" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMContr9"
            size="15">
    </div>

    <!-- <txtHUMIcn9></txtHUMIcn9> -->
    <div class="txtHUMIcn9">
        <input type="text" id="txtHUMIcn9" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMIcn9" size="18">
    </div>

    <!-- <txtHUMNm9></txtHUMNm9> -->
    <div class="txtHUMNm9">
        <input type="text" id="txtHUMNm9" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMNm9" size="16"
            [ngStyle]="{ 'display': e.plan === 'medicare' ? 'block' : 'none' }">
    </div>

    <!-- <txtHUMFrom9></txtHUMFrom9> -->
    <div class="txtHUMFrom9">
        <input type="text" id="txtHUMFrom9" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMFrom9" size="10"
            mask="90/90/00">
    </div>

    <!-- <txtHUMBal9></txtHUMBal9> -->
    <div class="txtHUMBal9">
        <input type="text" id="txtHUMBal9" class="currcy" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMBal9" size="10" maxlength="10" mask="separator.2" thousandSeparator=",">
    </div>

    <!-- <txtHUMCom9></txtHUMCom9> -->
    <div class="txtHUMCom9">
        <input type="text" id="txtHUMCom9" class="font9" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMCom9" size="58">
    </div>

    <!-- <txtHUMContr10></txtHUMContr10> -->
    <div class="txtHUMContr10">
        <input type="text" id="txtHUMContr10" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMContr10"
            size="15">
    </div>

    <!-- <txtHUMIcn10></txtHUMIcn10> -->
    <div class="txtHUMIcn10">
        <input type="text" id="txtHUMIcn10" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMIcn10" size="18">
    </div>

    <!-- <txtHUMNm10></txtHUMNm10> -->
    <div class="txtHUMNm10">
        <input type="text" id="txtHUMNm10" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMNm10" size="16"
            [ngStyle]="{ 'display': e.plan === 'medicare' ? 'block' : 'none' }">
    </div>

    <!-- <txtHUMFrom10></txtHUMFrom10> -->
    <div class="txtHUMFrom10">
        <input type="text" id="txtHUMFrom10" (change)="onChange_need2Save($event)" [(ngModel)]="e.txtHUMFrom10"
            size="10" mask="90/90/00">
    </div>

    <!-- <txtHUMBal10></txtHUMBal10> -->
    <div class="txtHUMBal10">
        <input type="text" id="txtHUMBal10" class="currcy" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMBal10" size="10" maxlength="10" mask="separator.2" thousandSeparator=",">
    </div>

    <!-- <txtHUMCom10></txtHUMCom10> -->
    <div class="txtHUMCom10">
        <input type="text" id="txtHUMCom10" class="font9" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txtHUMCom10" size="58">
    </div>

    <!-- <txtHUMBalTot></txtHUMBalTot> -->
    <div class="txtHUMBalTot" style="width: 106px;">
        <input type="text" id="txtHUMBalTot" [(ngModel)]="e.txtHUMBalTot" style="display: none;">

        <div style="text-align: right;">{{ +e.txtHUMBalTot | currency:'USD':'$':'1.2-2' }}</div>
    </div>

    <!-- <txtHUM_User></txtHUM_User> -->
    <div class="txtHUM_User font8">
        Preparado por
        <input type="text" id="txtHUM_User" style="border: none;" [ngModel]="e.txtHUM_User" readonly>
    </div>

    <!-- <txtHUMCasNo></txtHUMCasNo> -->
    <div class="txtHUMCasNo font8">
        Caso No.
        <input type="text" id="txtHUMCasNo" style="border: none;" [ngModel]="e.txtHUMCasNo" readonly>
    </div>

</div>