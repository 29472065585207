/* src/app/ck-register/recl-xml-sss-formulario-ajuste070114/recl-xml-sss-formulario-ajuste070114.component.css */
input {
  cursor: pointer;
  border: 1px solid orange;
}
.onechar {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}
.opt0_1 {
  position: absolute;
  top: 161px;
  left: 194px;
}
.opt0_2 {
  position: absolute;
  top: 161px;
  left: 440px;
}
.opt0_3 {
  position: absolute;
  top: 161px;
  left: 603px;
}
.opt0_4 {
  position: absolute;
  top: 161px;
  left: 789px;
}
.txt1_1 {
  position: absolute;
  top: 211px;
  left: 34px;
}
.txt1_2 {
  position: absolute;
  top: 211px;
  left: 431px;
}
.txt1_3 {
  position: absolute;
  top: 211px;
  left: 549px;
}
.txt1_4 {
  position: absolute;
  top: 260px;
  left: 34px;
}
.txt1_5 {
  position: absolute;
  top: 260px;
  left: 540px;
}
.txt2_1 {
  position: absolute;
  top: 331px;
  left: 34px;
}
.txt2_2 {
  position: absolute;
  top: 331px;
  left: 423px;
}
.txt3a_1 {
  position: absolute;
  top: 411px;
  left: 34px;
}
.txt3a_2 {
  position: absolute;
  top: 411px;
  left: 106px;
}
.txt3a_3 {
  position: absolute;
  top: 411px;
  left: 347px;
}
.txt3a_4 {
  position: absolute;
  top: 411px;
  left: 586px;
}
.txt3a_5 {
  position: absolute;
  top: 411px;
  left: 650px;
}
.txt3a_6 {
  position: absolute;
  top: 411px;
  left: 804px;
}
.txt3a_7 {
  position: absolute;
  top: 411px;
  left: 868px;
}
.txt3a_8 {
  position: absolute;
  top: 411px;
  left: 933px;
}
.txt3a_9 {
  position: absolute;
  top: 411px;
  left: 996px;
}
.txt3a_10 {
  position: absolute;
  top: 450px;
  left: 190px;
}
.txt3a_11 {
  position: absolute;
  top: 450px;
  left: 407px;
}
.txt3a_12 {
  position: absolute;
  top: 450px;
  left: 502px;
}
.txt3b_1 {
  position: absolute;
  top: 491px;
  left: 34px;
}
.txt3b_2 {
  position: absolute;
  top: 491px;
  left: 106px;
}
.txt3b_3 {
  position: absolute;
  top: 491px;
  left: 347px;
}
.txt3b_4 {
  position: absolute;
  top: 491px;
  left: 586px;
}
.txt3b_5 {
  position: absolute;
  top: 491px;
  left: 650px;
}
.txt3b_6 {
  position: absolute;
  top: 491px;
  left: 804px;
}
.txt3b_7 {
  position: absolute;
  top: 491px;
  left: 868px;
}
.txt3b_8 {
  position: absolute;
  top: 491px;
  left: 933px;
}
.txt3b_9 {
  position: absolute;
  top: 491px;
  left: 996px;
}
.txt3b_10 {
  position: absolute;
  top: 532px;
  left: 190px;
}
.txt3b_11 {
  position: absolute;
  top: 532px;
  left: 407px;
}
.txt3b_12 {
  position: absolute;
  top: 532px;
  left: 502px;
}
.txt3c_1 {
  position: absolute;
  top: 572px;
  left: 34px;
}
.txt3c_2 {
  position: absolute;
  top: 572px;
  left: 106px;
}
.txt3c_3 {
  position: absolute;
  top: 572px;
  left: 347px;
}
.txt3c_4 {
  position: absolute;
  top: 572px;
  left: 586px;
}
.txt3c_5 {
  position: absolute;
  top: 572px;
  left: 650px;
}
.txt3c_6 {
  position: absolute;
  top: 572px;
  left: 804px;
}
.txt3c_7 {
  position: absolute;
  top: 572px;
  left: 868px;
}
.txt3c_8 {
  position: absolute;
  top: 572px;
  left: 933px;
}
.txt3c_9 {
  position: absolute;
  top: 572px;
  left: 996px;
}
.txt3c_10 {
  position: absolute;
  top: 614px;
  left: 190px;
}
.txt3c_11 {
  position: absolute;
  top: 614px;
  left: 407px;
}
.txt3c_12 {
  position: absolute;
  top: 614px;
  left: 502px;
}
.txt4_1 {
  position: absolute;
  top: 668px;
  left: 33px;
}
.txt4_2 {
  position: absolute;
  top: 670px;
  left: 259px;
}
.txt4_3 {
  position: absolute;
  top: 670px;
  left: 421px;
}
.txt4_4 {
  position: absolute;
  top: 670px;
  left: 656px;
}
.txt5_1 {
  position: absolute;
  top: 730px;
  left: 34px;
  font-family:
    "Courier New",
    Courier,
    monospace;
  border: 1px solid orange;
}
/*# sourceMappingURL=recl-xml-sss-formulario-ajuste070114.component.css.map */
