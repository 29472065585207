import { Directive, ElementRef, Input } from '@angular/core';
declare var jQuery: any;

@Directive({
    selector: '[jqueryMaskededit]',
    inputs: ['mask: maskPattern'],
    standalone: false
})
export class JqueryMaskededitDirective {

    constructor(private el: ElementRef) { 
        this.el = el;
    }

set_mask(maskPattern: string) {
        jQuery(this.el.nativeElement).mask(maskPattern,{placeholder: " "});  // {placeholder:" "} replaces underscore _ for space
        // a maskPattern of "(999) 999-9999? x99999" would require only the first 10 digits of a phone number with extension being optional
    }
}