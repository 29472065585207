/* angular:styles/component:css;dbb8f80d0aead4150024d88e1023af7b1868495a36ea3cca1bfe6a8815da5ae6;C:\Users\medbi\source\repos\MBWeb7\src\app\shared\dd-ngbdropdown.component.ts */
.font90 {
  font-size: 90%;
}
.font75 {
  font-size: 75%;
}
.font50 {
  font-size: 50%;
}
.fontsmall {
  font-size: small;
}
.fontxsmall {
  font-size: x-small;
}
.fontxxsmall {
  font-size: xx-small;
}
.fontsmaller {
  font-size: smaller;
}
/*# sourceMappingURL=dd-ngbdropdown.component.css.map */
