/* src/app/agenda/agenda.component.css */
.apptTabWrapper {
  width: 100%;
  height: 620px;
  overflow-y: auto;
}
.stickyTabHead {
  position: sticky;
  top: 0;
}
.tdHover td:hover {
  background-color: #d0dae0;
}
.noRtBorder {
  border-right: 0px solid rgba(255, 255, 255, 0);
}
.noLtBorder {
  border-left: 0px solid rgba(255, 255, 255, 0);
}
.noBtBorder {
  border-bottom: 0px solid rgba(255, 255, 255, 0);
}
.noTpBorder {
  border-top: 0px solid rgba(255, 255, 255, 0);
}
*.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}
.hover2GrayBkg:hover {
  background-color: #e6e6e6 !important;
}
.radius6 {
  border-radius: 6px;
}
.radius25 {
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}
.radius25Lt {
  border-radius: 25px 0 0 25px !important;
}
.radius25Rt {
  border-radius: 0 25px 25px 0 !important;
}
ul li a {
  display: block-inline;
}
ul li a:hover,
ul li a:focus {
  font-weight: bold;
}
input.placeholder {
  text-align: center;
}
::-webkit-input-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
}
:-moz-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
}
::-moz-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
}
:-ms-input-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
}
.hatched {
  background:
    repeating-linear-gradient(
      135deg,
      black 0px,
      black 5px,
      transparent 0px,
      transparent 20px);
}
.labelHt {
  display: table-cell;
  vertical-align: middle;
  height: 25px;
  padding-top: 6px;
}
.rowBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: stretch;
  justify-content: space-between;
}
.colBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.selectedGrp {
  color: white;
  background-color: #6e5582;
}
.redBkg {
  background-color: red;
}
@media (max-width: 575px) {
  .alignLft-sm-max {
    text-align: left;
  }
  .datePadMarg-sm-max {
    padding: 0;
    margin: 0;
  }
  .durationPadMarg-sm-max {
    padding: 0;
    margin: 0;
  }
  .resourcePadMarg-sm-max {
    padding: 0;
    margin: 0;
  }
  .timePadMarg-sm-max {
    padding: 0;
    margin: 0;
  }
}
@media (min-width: 575px) {
  .alignCtr-sm-min {
    text-align: center;
  }
  .durationPadMarg-sm-min {
    padding: 0 20px 0 0;
    margin: 0 20px 0 0;
  }
  .resourcePadMarg-sm-min {
    padding: 0 20px 0 10px;
    margin: 0 20px 0 10px;
  }
  .timePadMarg-sm-min {
    padding: 0 0 0 25px;
    margin: 0 0 0 25px;
  }
}
@media (min-width: 768px) {
}
@media (max-width: 768px) {
  .datePadMarg-sm-max {
    padding: 0;
    margin: 0;
  }
  .durationPadMarg-sm-max {
    padding: 0;
    margin: 0;
  }
  .resourcePadMarg-sm-max {
    padding: 0;
    margin: 0;
  }
  .timePadMarg-sm-max {
    padding: 0;
    margin: 0;
  }
}
@media (min-width: 992px) {
  .marginTop20-lg-min {
    margin: 20px 0 0 0;
  }
}
@media only screen and (max-width: 992px), (min-device-width: 992px) and (max-device-width: 1024px) {
  .apptTabl,
  .apptTablTd {
    display: block;
  }
  .disappear {
    display: block;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .apptTabl tr {
    border: 1px solid #ccc;
  }
  .apptTabl td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  .apptTabl td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  td.apptTablTd:nth-of-type(1):before {
    font-family: "Font Awesome 5 Free";
    font-weight: normal;
    content: "\f017";
  }
  td.apptTablTd:nth-of-type(2):before {
    content: "Nombre: ";
    font-style: italic;
    font-weight: normal;
  }
  td.apptTablTd:nth-of-type(3):before {
    font-family: "Font Awesome 5 Free";
    content: "\f07c";
    font-weight: normal;
  }
  td.apptTablTd:nth-of-type(4):before {
    content: "Tel: ";
    font-style: italic;
    font-weight: normal;
    font-size: normal;
  }
  td.apptTablTd:nth-of-type(5):before {
    content: "Confirm: ";
    font-style: italic;
    font-weight: normal;
    font-size: smaller;
  }
  td.apptTablTd:nth-of-type(6):before {
    content: "Tipo/Stat: ";
    font-style: italic;
    font-weight: normal;
  }
  td.apptTablTd:nth-of-type(7):before {
    content: "Sex: ";
    font-style: italic;
    font-weight: normal;
  }
  td.apptTablTd:nth-of-type(8):before {
    content: "Edad: ";
    font-style: italic;
    font-weight: normal;
  }
  td.apptTablTd:nth-of-type(9):before {
    content: "Eliminar: ";
    font-style: italic;
    font-weight: normal;
    font-size: smaller;
  }
  td.apptTablTd:nth-of-type(10):before {
    content: "Elegible: ";
    font-style: italic;
    font-weight: normal;
    font-size: smaller;
  }
  td.apptTablTd:nth-of-type(11):before {
    content: "Editar: ";
    font-style: italic;
    font-weight: normal;
    font-size: smaller;
  }
  td.apptTablTd:nth-of-type(12):before {
    content: "Esconder: ";
    font-style: italic;
    font-weight: normal;
    font-size: smaller;
  }
}
/*# sourceMappingURL=agenda.component.css.map */
