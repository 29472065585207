/* src/app/ck-register/ck-register835-detail/ck-register835-detail.component.css */
.flexCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.small {
  font-size: small;
}
.xSmallFont {
  font-size: x-small;
}
.mediumFont {
  font-size: medium;
}
.vmid {
  vertical-align: middle;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: x-small;
}
.redBal {
  color: red;
}
.greenBal {
  color: green;
}
.blackBal {
  color: black;
}
.strike {
  text-decoration: line-through;
}
.pointer {
  cursor: pointer;
}
.srt835Nm,
.srt835Cas,
.srt835Contr {
  color: #b544b5;
}
.currency {
  width: 100%;
  text-align: right;
  display: inline-block;
}
.rgtAlign {
  text-align: right;
}
.hide {
  display: none;
}
/*# sourceMappingURL=ck-register835-detail.component.css.map */
