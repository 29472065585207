<!-- Ck Register Table -->

<div *ngIf="waiting4Response" class="box">
    <img src="app/assets/images/waiting4Response.gif" width="42" height="42">
</div>
<div *ngIf="!waiting4Response" class="box mx-0 px-0">
    <span class="mx-0 px-0"><b>{{ engLang ? 'Received Checks & Payments Register': 'Registro de Cheques y Pagos
            Recibidos' }}</b></span>
</div>

<div *ngIf="checks && checks.length > 0" class="text-center">
    <div>
        <span>{{ engLang ? 'Received': 'Recibido'}}: <strong>{{ totals.total | currency:'USD':'$':'1.2-2' }}</strong> {{
            engLang ?
            'in': 'en' }} {{ totals.count }} checks, {{ totals.credited | currency:'USD':'':'1.2-2' }} {{ engLang ?
            'credited': 'acreditado' }}, {{ totals.balance | currency:'USD':'':'1.2-2' }} {{ engLang ? 'pending':
            'pendiente' }}</span>
    </div>
</div>
<div class="table-responsive ckTabSize">
    <table id="ckRegTabl" class="table table-bordered table-sm unselectable">
        <thead class="disappear stickyCksTabHead">
            <tr>
                <!-- <th id="th0" scope="col">
                    ckgID
                </th> -->
                <th id="th1" scope="col">
                    <div class="box mb-1" style="margin: auto;">
                        {{ engLang ? 'Check No.': 'No. Cheque' }}
                    </div>
                    <div class="box mb-1" style="margin: auto;">
                        <input id="srchCkNo" type="text" (click)="onClick_srch($event);" [(ngModel)]="srchCkNo"
                            (keyup)="onKeyUp_srch($event);" (input)="clearChecks();"
                            placeholder="{{ engLang ? '...empty for all...': '...vacío para todos...' }}"
                            style="font-size: 90%;">
                    </div>
                    <div class="box mb-1" style="margin: auto;">
                        <button type="button" class="btn btn-success btn-xs" style="cursor: pointer;"
                            ngbTooltip="{{ engLang ? 'Search checks': 'Buscar cheques' }}" (click)="getCks()">
                            <i class="fas fa-search mr-1"></i>
                        </button>
                        &nbsp;
                        <button type="button" class="btn btn-primary btn-xs" style="cursor: pointer;"
                            ngbTooltip="{{ engLang ? 'Add new check': 'Añadir cheque nuevo' }}"
                            (click)="clearCkRegAddInputs(); chkgNo = srchCkNo; open(addCkModal, { backdrop: 'static' }, 'addCkModal');">
                            <i class="fa fa-plus">
                            </i>
                        </button>
                    </div>
                </th>
                <th id="th2" scope="col" style="width: 50px;">

                    <div class="d-sm-inline-flex flex-column justify-content-center align-items-center">
                        <div>
                            {{ engLang ? 'Dates': 'Fechas' }}
                        </div>

                        <div class="align-self-start">
                            <input type="radio" id="radCkDt" name="dateType" class="form-check-input"
                                ngbTooltip="{{ engLang ? 'Use payment date': 'Utilice fecha de pago' }}"
                                [checked]="true" (click)="srchDtType = '0';getCks();">&nbsp;
                            <label for="radCkDt">Cheque</label>
                        </div>
                        <div class="align-self-start">
                            <input type="radio" id="radRxDt" name="dateType" class="form-check-input"
                                ngbTooltip="{{ engLang ? 'Use received date': 'Utilice fecha recibido el pago' }}"
                                (click)="srchDtType = '1';getCks();">&nbsp;
                            <label for="radCkDt" class="pl-1">Recibido</label>
                        </div>

                        <div class="d-sm-inline-flex mt-2" style="width: 85px; height: 22px;">
                            <input id="srchFromDt" class="form-control" placeholder="mm/dd/yy" name="dtp"
                                #cfrom="ngModel" [(ngModel)]="srchFromDt" ngbDatepicker
                                parserFormatter="CustomDateParserFormatter"
                                (dateSelect)="srchFromDt = onDtpDateSelect($event,dfrom._inputValue,'srchFromDt')"
                                #dfrom="ngbDatepicker"
                                (blur)="srchFromDt = onDtpDateSelect($event,dfrom._inputValue,'srchFromDt')"
                                (keyup)="onKeyUp_srch($event);" style="font-size: 90%; padding: auto; width: 70px;"
                                (focus)="srchFromDt2 = srchFromDt"
                                [ngClass]="{ 'redColor' : invalidFrToDts(this.srchFromDt, this.srchToDt) }" />
                            <i class="far fa-calendar-times mt-1" (click)="dfrom.toggle()"></i>
                        </div>
                        <!-- {{ srchFromDt2 }} -->
                        <div class="d-sm-inline-flex mt-1" style="width: 85px; height: 22px;">
                            <input id="srchToDt" class="form-control" placeholder="mm/dd/yy" name="dtp" #cto="ngModel"
                                [(ngModel)]="srchToDt" ngbDatepicker parserFormatter="CustomDateParserFormatter"
                                (dateSelect)="srchToDt = onDtpDateSelect($event,dto._inputValue,'srchToDt')"
                                #dto="ngbDatepicker"
                                (blur)="srchToDt = onDtpDateSelect($event,dto._inputValue,'srchToDt')"
                                (keyup)="onKeyUp_srch($event);" style="font-size: 90%; padding: auto; width: 70px;"
                                (focus)="srchToDt2 = srchToDt"
                                [ngClass]="{ 'redColor' : invalidFrToDts(this.srchFromDt, this.srchToDt) }" />
                            <i class="far fa-calendar-times mt-1" (click)="dto.toggle()"></i>
                        </div>
                        <!-- {{ srchToDt2 }} -->
                    </div>
                </th>
                <th id="th3" scope="col" class="text-center">
                    Total$
                </th>
                <th id="th4" scope="col" class="text-center">
                    <div ngbTooltip="{{ engLang ? 'Break down per claim': 'Desglose por caso' }}">
                        <img src="app/assets/images/moneyDist.jpg" width="17" height="22"
                            alt="{{ engLang ? 'Break down per claim': 'Desglose por caso' }}" />
                    </div>
                </th>
                <th id="th5" scope="col" class="text-center">
                    {{ engLang ? 'Break Dn': 'Desglose' }}
                </th>
                <th id="th6" scope="col" class="text-center">

                    <div class="mx-0 px-0 my-0 py-0" [popper]="popper1" [popperShowOnStart]="false"
                        [popperTrigger]="'click'" [popperHideOnClickOutside]="true" [popperHideOnScroll]="true"
                        [popperPlacement]="'bottom'" [popperPositionFixed]="true"
                        ngbTooltip="{{ engLang ? 'Not credited to claims': 'No desglosado por casos' }}">
                        {{ engLang ? 'Pending ': 'Pendiente ' }}
                        <i class="fas fa-question-circle" (click)="onClick_showPopr1()" style="cursor: pointer;"></i>
                    </div>

                    <div class="text-center mx-0 px-0 my-0 py-0">
                        <popper-content #popper1>
                            <div content
                                style="background-color: white; border: 1px solid #ccc; padding: 10px; border-radius: 5px; font: smaller;">
                                <p *ngIf="engLang">
                                    Amount not credited to claims from check.<br />Click on the pending amount to
                                    manually credit payments from the corresponding check.
                                </p>
                                <p *ngIf="!engLang">
                                    Cantidad no acreditada a casos del cheque.<br />Click en la cantidad pendiente
                                    para manualmente acreditar pagos del cheque
                                    correspondiente.
                                </p>
                            </div>
                        </popper-content>
                    </div>

                </th>
                <th id="th7" scope="col" class="text-center">
                    <div class="mx-0 px-0 my-0 py-0">
                        NPI
                    </div>
                    <div class="text-center mx-0 px-0 my-0 py-0">

                        <div *ngIf="provsNPI?.length > 0" class="my-0 py-0">
                            <dd-ngbdropdown [labl]="srchNpi" [preVal]="srchNpi" [rawList]="provsNPI" [fontsmall]=true
                                [stylesCfg]="stylesCfg" [listTyp]="'provNpi'" [engLang]="engLang" [tt]="srchNpiTtip"
                                (ddnListSelectionClkd)="ddnListSelectionClkdForHeadFilter($event)"></dd-ngbdropdown>
                        </div>

                    </div>
                </th>
                <th id="th8" scope="col" class="text-center">
                    <div class="mx-0 px-0 my-0 py-0">
                        Payer ID
                    </div>
                    <div class="text-center mx-0 px-0 my-0 py-0">

                        <div *ngIf="insPayerID?.length > 0" class="my-0 py-0">
                            <dd-ngbdropdown [labl]="srchPayerID" [preVal]="srchPayerID" [rawList]="insPayerID"
                                [stylesCfg]="stylesCfg" [listTyp]="'insPayerID'" [engLang]="engLang"
                                [tt]="srchPayerIDttip" [fontsmall]=true
                                (ddnListSelectionClkd)="ddnListSelectionClkdForHeadFilter($event)"></dd-ngbdropdown>
                        </div>

                    </div>
                </th>
                <th id="th9" scope="col" class="text-center">
                    {{ engLang ? 'Received': 'Recibido' }}
                </th>
                <th id="th10" scope="col" class="text-center">
                    {{ engLang ? '835 File': 'Archivo 835' }}
                </th>
                <th class="text-center">
                    <i class="fas fa-print" placement="left"
                        ngbTooltip="{{ engLang ? 'Print file': 'Imprimir archivo'}}"></i>
                </th>
                <th id="th11" scope="col" class="text-center">
                    <div ngbTooltip="{{ engLang ? 'File date': 'Fecha archivo' }}">
                        {{ engLang ? 'Date 835': 'Fecha 835' }}
                    </div>
                </th>
            </tr>
        </thead>
        <tbody id="ckRegTablBody">
            <tr [id]="check.ckgID" (click)="ckSelectedId = check.ckgID"
                [ngClass]="(ckSelectedId == check.ckgID ? 'rowSelected' : { evenCksSrch : e, oddCksSrch : o })" *ngFor="let check of checks | ckRegisterPipe: checks: srchNpi: srchPayerID | paginate: { id: 'checks', itemsPerPage: 12, currentPage: currentPageCks };
                    let e = even; let o = odd; let c = index;">
                <!-- <td>
                    {{ check.ckgID }}
                </td> -->
                <td class="ckRegTablTd">
                    {{ check.ckgNo }}
                </td>
                <td class="ckRegTablTd ckDtStyle">
                    {{ check.ckDt }}
                </td>
                <td class="ckRegTablTd currStyle">
                    {{ check.ckTotal | currency:'USD':'':'1.2-2'}}
                </td>
                <td class="ckRegTablTd text-center">
                    <!-- <div style="display: flex; width: 500px;"> -->
                    <div class="tdDivBtn px-0 mx-0 py-0 my-0">
                        <div *ngIf="check.ckChildren!='0'">
                            <button (click)="onClick_showPayedClaimsDetails(check.ckgID);"
                                style="border: none; display: inline; margin: 0; padding: 0;">
                                <img src="app/assets/images/moneyDist.jpg" width="15" height="20"
                                    alt="{{ engLang ? 'Show break down': 'Ver desgloce' }}"
                                    ngbTooltip="{{ engLang ? 'Show break down': 'Ver desgloce' }}" />
                            </button>
                        </div>
                        <div *ngIf="check.ckChildren=='0' && !check.ckFilNm" class="px-0 mx-0 py-0 my-0">
                            <i class="fa fa-trash" style="cursor: pointer;"
                                title="{{ engLang ? 'Delete check': 'Eliminar cheque' }}" (click)="ckgID = check.ckgID;
                                        chkgNo = check.ckgNo;
                                        chkgDt = check.ckDt;
                                        chkgTotal = check.ckTotal;
                                        open(delCkModal, {backdrop: undefined}, 'delCkModal')"></i>
                        </div>
                    </div>
                </td>
                <td class="ckRegTablTd currStyle">
                    {{ check.ckCredited | currency:'USD':'':'1.2-2' }}
                </td>
                <td class="ckRegTablTd currStyle">
                    <a [routerLink]="[]"
                        (click)="selectedCk = check; onClick_showCreditPaymntsModal(check); currentPageClms = 1;
                                open(claimsModal, { backdrop: 'static', size: 'lg', scrollable: true }, 'claimsModal');"
                        [ngClass]="{ 'redColor' : isPending(check.ckBalance) }">{{ check.ckBalance |
                        currency:'USD':'':'1.2-2' }}</a>
                </td>
                <td class="ckRegTablTd text-left" style="cursor: pointer;" (click)="createCopyOfCheck(check)">

                    <div class="d-inline-block small">
                        <dd-ngbdropdown [labl]="check.ckNPI" [preVal]="check.ckNPI" [rawList]="provsNPI"
                            [stylesCfg]="stylesCfg" [listTyp]="'provNpi'" [engLang]="engLang" [fontsmall]=true
                            (ddnListSelectionClkd)="ddnListSelectionClkd($event,check.ckgID)"></dd-ngbdropdown>
                    </div>

                </td>
                <td class="ckRegTablTd" style="cursor: pointer;" (click)="createCopyOfCheck(check)">

                    <div class="d-inline-block">
                        <dd-ngbdropdown [labl]="check.ckPayerID" [preVal]="check.ckPayerID" [rawList]="insPayerID"
                            [stylesCfg]="stylesCfg" [listTyp]="'insPayerID'" [engLang]="engLang" [fontsmall]=true
                            (ddnListSelectionClkd)="ddnListSelectionClkd($event,check.ckgID)"></dd-ngbdropdown>
                    </div>

                </td>
                <td class="ckRegTablTd" width="80px;">
                    <div class="d-sm-inline-flex">
                        <input id="{{ 'ckRxDt_' + c }}" class="form-control form-control-sm px-1 py-1"
                            placeholder="mm/dd/yy" name="dtp" #crx="ngModel" [(ngModel)]="check.ckRxDt" ngbDatepicker
                            parserFormatter="CustomDateParserFormatter"
                            (dateSelect)="check.ckRxDt = onDtpDateSelect($event,drx._inputValue,false); ckPropValChange(check);"
                            #drx="ngbDatepicker" [ngClass]="{ 'redColor': invalidFrToDts(check.ckDt, check.ckRxDt) }"
                            (blur)="check.ckRxDt = onDtpDateSelect($event,drx._inputValue,false); ckPropValChange(check);"
                            style="font-size: 84%;" (focus)="createCopyOfCheck(check)"
                            (keyup)="onKeyUp_ckRxDt($event)" />
                        <i class="far fa-calendar-times mt-2" (click)="drx.toggle()"></i>
                    </div>
                </td>
                <td class="ckRegTablTd">
                    <button type="button" class="btn btn-link" style="font-size: small;" (click)="onClick_get835file(check,false); 
                            currentPageCas = 1;
                            sortAsc = true;
                            prevSrtClass = undefined;
                            ckgID = check.ckgID;
                            chkgNPI = check.ckNPI;
                            chkgPayerId = check.ckPayerID;
                            open(_835fileClaimsPaymentDetailModal
                            , { scrollable: true, backdrop: 'static', size: 'lg' }
                            , '_835fileClaimsPaymentDetailModal');">
                        {{ extractFilNm(check.ckFilNm) }}
                    </button>
                    <!-- {{ check.ckFilNm }} -->
                </td>
                <td>
                    <i class="fas fa-print" style="cursor: pointer" (click)="onClick_get835file(check,true);"></i>
                </td>
                <td class="ckRegTablTd" style="font-size: small;">
                    {{ check.ckFilDt }}
                </td>
            </tr>
        </tbody>
    </table>

    <!-- <pre>{{ modalStack }}</pre> -->
    <!-- <pre>{{ modalStack.length }}</pre> -->
    <!-- <pre>{{ckgID}}</pre> -->
    <!-- <pre>{{chkgNPI}}</pre> -->
    <!-- <pre>{{chkgPayerId}}</pre> -->
    <!-- <pre>{{chkgDt}}</pre> -->
    <!-- <pre>{{ checks | json }}</pre> -->
    <!-- <pre>{{ showZeroBal }}</pre> -->

</div>
<div class="small text-center pt-1">
    <pagination-controls id="checks" (pageChange)="currentPageCks = $event" directionLinks="true" previousLabel=""
        nextLabel="" autoHide="true">
    </pagination-controls>
</div>

<div *ngIf="showChildPayments">
    <app-ck-register-detail [ckgID]="chkgDetID" [ckgNo]="chkgNo" [ckgDt]="chkgDt" [sn]="sn" [engLang]="engLang"
        [payDt]="" [payAmnt]="" (payDeletedEvent)='updtCkCreditedAmnt($event)'
        (showChildPaymentsEvent)="toggleShowChildPayments($event)">
    </app-ck-register-detail>
</div>

<!-- modal 835 payments credited to claims per check ................................................ -->
<ng-template #_835fileClaimsPaymentDetailModal let-modal>
    <div class="modal-header p-1" style="background-color: #E6E6FA;">
        <div class="d-sm-inline-flex flex-column">
            <div>
                <h4 class="modal-title m-0">{{ engLang ? '835 Electronic Payments': '835 Pagos Electrónicos'}}
                </h4>
            </div>
            <div class="xsmall">
                <h5 class="m-0">{{ engLang ? 'Payer: ': 'Pagador: '}}{{ filPayerNm }}</h5>
            </div>
            <div class="xsmall">
                <h5 class="m-0">{{ engLang ? 'Payee: ': 'Tenedor: '}}{{ filPayeeNm }}</h5>
            </div>

            <div class="mt-0">
                <div>
                    <span class="small">{{ engLang ? 'File:
                        ': 'Archivo: '}}{{ filNm }}</span>
                    <img src="/app/assets/images/clipboard-copy-small.png" class="ml-1" width="15px" height="15px"
                        alt="Clipboard copy" style="cursor: pointer;"
                        (click)="copy2ClipBoard(filNm,'File name - Nombre archivo')">
                    &nbsp;
                    <span class="xsmall">{{ filDt }}</span>
                    &nbsp;
                    <strong>{{ filAmnt | currency:'USD':'$':'1.2-2' }}</strong>
                </div>
            </div>
        </div>

        <div class="d-sm-inline-flex flex-column" style="margin-top: -45px; margin-left: -450px;">
            <div *ngIf="waiting4Response">
                <img src="app/assets/images/waiting4Response.gif" width="42" height="42">
            </div>
        </div>

        <div class="align-self-start">
            <button type="button" class="btn btn-sm btn-close m-0 p-0"
                (click)="modal.dismiss('close_835paymentsModal')"></button>
        </div>
    </div>

    <app-ck-register-detail-modal-header [sn]="sn" [engLang]="engLang" [hdCkNo]="chkgNo" [hdCkDt]="chkgDt"
        [hdPendCnt]="pendCnt" [hdCkBal]="chkgBalance" [adjCnt]="adjCnt" [adjTotal]="adjTotal" [reclCnt]="reclCnt"
        [reclTotal]="reclTotal" [corrCnt]="corrCnt" [claims]="claimsPropsReduced" [topCases]="topCases"
        [showZeroBal]="showZeroBal" (search4Claims)="search4Claims($event)" (setCasIndxFound)="setCasIndxFound($event)"
        (setClaimSrchActive)="setClaimSrchActive($event)"
        (setClaimsAdjustedSrchActive)="setClaimsAdjustedSrchActive($event)"
        (setReclaimedSrchActive)="setReclaimedSrchActive($event)"
        (setClaimsPendingSrchActive)="setClaimsPendingSrchActive($event)"
        (toggleShowZeroBal)="toggleShowZeroBal($event)" [filNm]="filNm" [filDt]="filDt"
        [unMatchCnt]="unMatchedClaimCnt">
    </app-ck-register-detail-modal-header>

    <!-- <pre>{{ chkgNo }}</pre> -->
    <!-- <pre>{{ chkgDt }}</pre> -->
    <!-- <pre>{{ chkgBalance }}</pre> -->

    <div class="modal-body p-1" style="overflow-x: hidden;">

        <div
            *ngFor="let cas of _835cas | casFoundPipe: casIndxFound | paginate: { id: '_835Cas', itemsPerPage: 2, currentPage: currentPageCas }">

            <app-ck-register835-detail-component [sn]="sn" [userID]="userID" [engLang]="engLang" [cas]="cas"
                [_835svc]="_835svc" [sortAsc]="sortAsc" [prevSrtClass]="prevSrtClass" [casSeqNo]="cas.casSeqNo"
                [_835adj]="_835adj" [payDt]="chkgDt" [npi]="chkgNPI" [payerId]="chkgPayerId" [filNm]="filNm"
                [filDt]="filDt" [filAmnt]="filAmnt" [filPayerNm]="filPayerNm" [filPayeeNm]="filPayeeNm"
                [chkgNo]="chkgNo" [ckPayeeNpi]="_835ck[0].ckPayeeNpi" [ckPayerID]="_835ck[0].ckPayerID"
                [carcNotes]="carcNotes" [reclCkBxId]="reclCkBxId" [reclCkBxCked]="reclCkBxCked"
                (reSortedCasArr)="sort835Claims($event)" (unCreditedPaymentsCnt)="unCreditedPaymentsCnt($event)"
                (reclaim)="reclaim835($event)" (print835remittance)="print835remittance($event)"
                (updtTotAdjCnt)="updtTotAdjCnt($event)">
            </app-ck-register835-detail-component>

            <!-- <pre>{{ this._835fil | json }}</pre> -->
            <!-- <pre>{{ this._835ck | json }}</pre> -->
            <!-- <pre>{{ this._835cas | json }}</pre> -->
            <!-- <pre>{{ this._835svc | json }}</pre> -->
            <!-- <pre>{{ this._835adj | json }}</pre> -->
            <!-- <pre>{{ modalStack.length }}</pre> -->
            <!-- <pre>{{ modalStack }}</pre> -->
        </div>
    </div>

    <div class="modal-footer justify-content-between" style="background-color: #E6E6FA;">
        <div class="d-inline-block justify-content-start">
            <div>
                <span class="small">{{ _835cas?.length | currency:'USD':'':'1.0-0' }}{{ (engLang ? " claims": "
                    casos")
                    }}&nbsp;{{ _835svc?.length | currency:'USD':'':'1.0-0' }}{{ (engLang ? " payments": "
                    pagos")
                    }}</span>
            </div>
            <div class="small">
                {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
            </div>
        </div>

        <div class="small">
            <pagination-controls id="_835Cas" (pageChange)="currentPageCas = $event" directionLinks="true"
                previousLabel="" nextLabel="" autoHide="true">
            </pagination-controls>
        </div>

        <div>
            <button type="button" class="btn btn-outline-success" [disabled]="!+unCredPayCnt"
                (click)="open(save835paymentsModal, { backdrop: 'static' }, 'save835paymentsModal');">
                {{ engLang ? 'Save (' + unCredPayCnt + ') Electronic Payments'
                : 'Grabar (' + unCredPayCnt + ') Pagos Electrónicos' }}
            </button>
        </div>
    </div>
</ng-template>

<!-- modal prompt to delete a check from the register ............................................... -->
<ng-template #delCkModal let-modal>
    <div class="modal-header" style="background-color: #fca796;">
        <h4 class="modal-title">{{ engLang ? 'Delete Check ?': '¿ Eliminar Cheque ?' }}</h4>
        <button type="button" class="btn btn-sm btn-close" (click)="modal.dismiss('close')"></button>
    </div>
    <div class="modal-body">
        <table>
            <thead>
                <tr>
                    <th>
                        Check No.
                    </th>
                    <th>
                        {{ engLang ? 'Date': 'Fecha' }}
                    </th>
                    <th class="mr-3" style="float: right;">
                        {{ engLang ? 'Amount': 'Cantidad' }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        {{ chkgNo }}
                    </td>
                    <td>
                        {{ chkgDt }}
                    </td>
                    <td class="mr-3" style="float: right;">
                        {{ chkgTotal | currency:'USD':'':'1.2-2' }}
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- <pre>{{ modalStack.length }}</pre> -->
        <!-- <pre>{{ modalStack }}</pre> -->

    </div>
    <div class="modal-footer" style="background-color: #fca796; display: flex; justify-content: space-between;">
        <div style="font-size: 70%; flex-basis: 50%;">
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
        </div>
        <div>
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('oK_deleteCheck')">Ok</button>
        </div>
    </div>
</ng-template>

<!-- modal prompt to add a check to the register .................................................... -->
<ng-template #addCkModal let-modal let-c="close" let-d="dismiss">
    <div class="modal-header" style="background-color: #e2ede2;">
        <div>
            <h4 class="modal-title">{{ engLang ? 'Add Check': 'Añadir Cheque' }}</h4>
        </div>
        <div style="margin-top: -40px;">
            <button type="button" class="btn btn-sm btn-close" (click)="modal.dismiss('close')"></button>
        </div>
    </div>

    <div class="modal-body">
        <div class="text-center">
            <div class="d-sm-inline-flex flex-column align-items-end">
                <div class="p-1 h32">
                    <label for="chkgDt" style="font-size: 75%;" class="align-self-end">
                        {{ engLang ? 'Payment date (check):': 'Fecha del pago (cheque):' }}
                    </label>
                </div>
                <div class="p-1 h32">
                    <label for="chkgRxDt" style="font-size: 75%; width: 100%;" class="align-self-end">
                        {{ engLang ? 'Payment received date:': 'Fecha recibido el pago:' }}
                    </label>
                </div>
                <div class="p-1 h32">
                    <label for="chkgNo" style="font-size: 75%;" class="align-self-end">
                        {{ engLang ? 'Check/confirmation No.:': 'No. cheque/confirmación:' }}
                    </label>
                </div>
                <div class="p-1 h32">
                    <label for="chkgTotal" style="font-size: 75%;" class="align-self-end">
                        {{ engLang ? 'Total payment (gross):': 'Pago total (bruto):' }}
                    </label>
                </div>
                <div class="p-1 h32">
                    <label for="chkgTaxDed" style="font-size: 75%;" class="align-self-end">
                        {{ engLang ? 'Tax deduction:': 'Deducción Hacienda:' }}
                    </label>
                </div>
                <div class="p-1 h32">
                    <label for="chkgMedDed" style="font-size: 75%;" class="align-self-end">
                        {{ engLang ? 'Health plan deduction:': 'Deducción plan médico:' }}
                    </label>
                </div>
                <div class="p-1 h32">
                    <label for="chkgOtherDed" style="font-size: 75%;" class="align-self-end">
                        {{ engLang ? 'Other deduction:': 'Otra deducción:' }}
                    </label>
                </div>
            </div>

            <div class="d-sm-inline-flex flex-column">
                <div class="d-sm-inline-flex p-1 h32">
                    <input id="chkgDt" class="form-control p-1" placeholder="mm/dd/yy" name="dtp" #cckdt="ngModel"
                        [(ngModel)]="chkgDt" ngbDatepicker parserFormatter="CustomDateParserFormatter"
                        (dateSelect)="chkgDt = onDtpDateSelect($event,dckdt._inputValue,false)" #dckdt="ngbDatepicker"
                        (blur)="chkgDt = onDtpDateSelect($event,dckdt._inputValue,false)"
                        style="font-size: 90%; padding: auto; width: 80px;" ngbAutofocus
                        [ngClass]="{ 'redColor' : invalidFrToDts(this.chkgDt, this.chkgRxDt) }" />
                    <i class="far fa-calendar-times mt-2" (click)="dckdt.toggle()"></i>
                </div>
                <div class="d-sm-inline-flex p-1 h32">
                    <input id="chkgRxDt" class="form-control p-1" placeholder="mm/dd/yy" name="dtp" #cckrxdt="ngModel"
                        [(ngModel)]="chkgRxDt" ngbDatepicker parserFormatter="CustomDateParserFormatter"
                        (dateSelect)="chkgRxDt = onDtpDateSelect($event,dckrxdt._inputValue,false)"
                        #dckrxdt="ngbDatepicker" (blur)="chkgRxDt = onDtpDateSelect($event,dckrxdt._inputValue,false)"
                        style="font-size: 90%; padding: auto; width: 80px;" ngbAutofocus
                        [ngClass]="{ 'redColor' : invalidFrToDts(this.chkgDt, this.chkgRxDt) }" />
                    <i class="far fa-calendar-times mt-2" (click)="dckrxdt.toggle()"></i>
                </div>
                <div class="p-1 h32">
                    <input id="chkgNo" type="text" maxlength="30" [(ngModel)]="chkgNo" />
                </div>
                <div class="p-1 h32">
                    <input id="chkgTotal" type="text" maxlength="10" [(ngModel)]="chkgTotalInp" />
                </div>
                <div class="p-1 h32">
                    <input id="chkgTaxDed" type="text" maxlength="10" [(ngModel)]="chkgTaxDedInp" />
                </div>
                <div class="p-1 h32">
                    <input id="chkgMedDed" type="text" maxlength="10" [(ngModel)]="chkgMedDedInp" />
                </div>
                <div class="p-1 h32">
                    <input id="chkgOtherDed" type="text" maxlength="10" [(ngModel)]="chkgOtherDedInp" />
                </div>
            </div>
        </div>

        <div class="mt-3">
            <label for="chkgNPI" style="font-size: 75%;" class="mr-2">
                {{ engLang ? 'Provider NPI:': 'NPI proveedor:' }}
                &nbsp;
            </label>
            <span [popper]="popper2" [popperShowOnStart]="false" [popperTrigger]="'click'"
                [popperHideOnClickOutside]="true" [popperHideOnScroll]="true" [popperPlacement]="'bottom'"
                [popperPositionFixed]="true">
                <i class="fas fa-question-circle" (click)="onClick_showPopr2();" style="cursor: pointer;"></i>
            </span>

            <popper-content #popper2>
                <div content
                    style="background-color: white; border: 1px solid #ccc; padding: 10px; border-radius: 5px; font: smaller;">
                    <p *ngIf="engLang">
                        The billing provider NPI helps to exclude claims not eligble for this check's payments.
                    </p>
                    <p *ngIf="!engLang">El NPI del billing provider ayuda a eliminar casos no
                        elegibles para
                        pagos de este cheque.</p>
                </div>
            </popper-content>

            <select id="chkgNPI" type="text" (change)="onChange_selectNPI($event);">
                <option *ngFor="let pNpi of provsNPI; let idx = index;"
                    [ngStyle]="{'background-color': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF')}">
                    <span>{{ pNpi.ckgNpi }}</span>
                    &nbsp;&nbsp;
                    <span>{{ pNpi.ProvLNm }}</span>
                </option>
            </select>
        </div>

        <div class="mt-2">
            <label for="chkgPayerId" style="font-size: 75%;" class="mr-2">
                Payer ID:&nbsp;
            </label>
            <span [popper]="popper3" [popperShowOnStart]="false" [popperTrigger]="'click'"
                [popperHideOnClickOutside]="true" [popperHideOnScroll]="true" [popperPlacement]="'bottom'"
                [popperPositionFixed]="true">
                <i class="fas fa-question-circle" (click)="onClick_showPopr3();" style="cursor: pointer"></i>
            </span>

            <popper-content #popper3>
                <div content style="background-color: white; border: 1px solid #ccc; padding: 10px; border-radius: 5px; font:
                    smaller;">
                    <p *ngIf="engLang">
                        The insurance payer ID helps to exclude claims not eligble for this check's payments.
                    </p>
                    <p *ngIf="!engLang">El payer ID del seguro ayuda a eliminar casos no
                        elegibles
                        para pagos de
                        este cheque.</p>
                </div>
            </popper-content>

            <select id="chkgPayerId" type="text" (change)="onChange_selectPayerID($event);">
                <option *ngFor="let payID of insPayerID; let idx = index;"
                    [ngStyle]="{'background-color': (idx % 2 == 0 ? '#e8e8e8': '#FFFFFF')}">
                    <span>{{ payID.insPayID }}</span>
                    &nbsp;&nbsp;
                    <span>{{ payID.InLNm }}</span>
                </option>
            </select>
        </div>
    </div>

    <div class="modal-footer justify-content-between" style="background-color: #e2ede2;">
        <div style="font-size: 70%; flex-basis: 50%;">
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
        </div>
        <div>
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('oK_addCheck')">
                {{ engLang ? 'Save': 'Grabar' }}
            </button>
        </div>
    </div>

    <!-- <pre>{{ modalStack.length }}</pre> -->
    <!-- <pre>{{ modalStack }}</pre> -->

</ng-template>

<!-- modal claims to manually enter a check's payments ....................................................... -->
<ng-template #claimsModal let-modal let-c="close" let-d="dismiss">
    <div class="modal-header p-2" style="background-color: #c7e9f7;">
        <div>
            <div class="d-inline-flex">
                <div class="align-self-center">
                    <h4 class="modal-title m-0">{{ engLang ? 'Credit Payments Manually
                        (Candidate
                        Claims)': 'Acreditar Pagos Manualmente (Casos Candidatos)'
                        }}
                    </h4>
                </div>
                <div *ngIf="waiting4Response">
                    <img src="app/assets/images/waiting4Response.gif" width="42" height="42">
                </div>
            </div>
        </div>

        <div class="align-self-start">
            <button type="button" class="btn btn-sm btn-close" (click)="d('closeClaimsModal')"></button>
        </div>
    </div>

    <app-ck-register-detail-modal-header [sn]="sn" [engLang]="engLang" [hdCkNo]="chkgNo" [hdCkDt]="chkgDt"
        [hdCkBal]="chkgBalance" [adjCnt]="adjCnt" [adjTotal]="adjTotal" [reclCnt]="reclCnt" [reclTotal]="reclTotal"
        [corrCnt]="corrCnt" [claims]="claimsPropsReduced" [topCases]="topCases" [showZeroBal]="showZeroBal"
        (search4Claims)="search4Claims($event)" (setCasIndxFound)="setCasIndxFound($event)"
        (setClaimSrchActive)="setClaimSrchActive($event)"
        (setClaimsAdjustedSrchActive)="setClaimsAdjustedSrchActive($event)"
        (setReclaimedSrchActive)="setReclaimedSrchActive($event)"
        (setClaimsPendingSrchActive)="setClaimsPendingSrchActive($event)"
        (toggleShowZeroBal)="toggleShowZeroBal($event)">
    </app-ck-register-detail-modal-header>

    <div class="modal-body m-0 pt-2 py-1 pb-0">

        <table id="claimsTbl" class="table table-bordered table-sm" style="font-size: 85%;">
            <thead class="stickyClmsTabHead">
                <tr class="prevent-select">
                    <!-- <th scope="col">adjs</th> -->

                    <th id="nmClmTh" scope="col" class="cursorPtr" (click)="sortClaims('nmClmTh');">
                        <div class="rowBox" style="justify-content: space-between;"
                            ngbTooltip="{{ engLang ? 'Click to reorder': 'Clic para re-ordenar' }}" placement="right">
                            <div>
                                {{ engLang ? 'Patient': 'Paciente' }}
                            </div>
                            <div id="nmClmThSrt" class="noClassArrowGrp">
                                <i *ngIf="sortAsc" class="fas fa-arrow-up"></i>
                                <i *ngIf="!sortAsc" class="fas fa-arrow-down"></i>
                            </div>
                        </div>
                    </th>
                    <th id="dtClmTh" scope="col" class="cursorPtr" (click)="sortClaims('dtClmTh');">
                        <div class="rowBox" style="justify-content: space-between;"
                            ngbTooltip="{{ engLang ? 'Click to reorder': 'Clic para re-ordenar' }}" placement="right">
                            <div>
                                {{ engLang ? 'Date': 'Fecha' }}
                            </div>
                            <div id="dtClmThSrt" class="noClassArrowGrp" hidden>
                                <i *ngIf="sortAsc" class="fas fa-arrow-up"></i>
                                <i *ngIf="!sortAsc" class="fas fa-arrow-down"></i>
                            </div>
                        </div>
                    </th>
                    <th id="contrClmTh" scope="col" class="cursorPtr" (click)="sortClaims('contrClmTh');">
                        <div class="rowBox" style="justify-content: space-between;"
                            ngbTooltip="{{ engLang ? 'Click to reorder': 'Clic para re-ordenar' }}" placement="right">
                            <div>
                                {{ engLang ? 'Contract': 'Contrato' }}
                            </div>
                            <div id="contrClmThSrt" class="noClassArrowGrp" hidden>
                                <i *ngIf="sortAsc" class="fas fa-arrow-up"></i>
                                <i *ngIf="!sortAsc" class="fas fa-arrow-down"></i>
                            </div>
                        </div>
                    </th>
                    <th id="casClmTh" scope="col" class="cursorPtr" (click)="sortClaims('casClmTh');">
                        <div class="rowBox" style="justify-content: space-between;"
                            ngbTooltip="{{ engLang ? 'Click to reorder': 'Clic para re-ordenar' }}" placement="right">
                            <div>
                                {{ engLang ? 'Claim No.': 'Caso' }}
                            </div>
                            <div id="casClmThSrt" class="noClassArrowGrp" hidden>
                                <i *ngIf="sortAsc" class="fas fa-arrow-up"></i>
                                <i *ngIf="!sortAsc" class="fas fa-arrow-down"></i>
                            </div>
                        </div>
                    </th>
                    <th scope="col" class="text-center">
                        Balance
                    </th>
                    <th scope="col" class="text-center">
                        {{ engLang ? 'Payed': 'Pagado' }}
                    </th>
                    <th scope="col" class="text-center">
                        <i class="fas fa-undo"
                            ngbTooltip="{{ engLang ? 'Click to undo recent payments': 'Clic para deshacer pagos recientes' }}"
                            placement="left"></i>
                    </th>
                    <th scope="col">
                        &#189;
                    </th>
                    <th scope="col">
                    </th>
                    <th scope="col">
                        {{ engLang ? 'Insurance': 'Seguros' }}
                    </th>
                </tr>
            </thead>
            <tbody *ngFor="let claim of ckClaims | casFoundPipe: casIndxFound | paginate: { id: 'claims', itemsPerPage: 6, currentPage: currentPageClms }; 
                    let c = index; let e = even;" style="cursor: pointer;"
                [ngStyle]="{ 'background-color': e ? '#dbe9f4': '#FFFFFF' }">

                <tr id="{{ 'ctr' + claim.casID }}" ngbTooltip="{{ onMouseenter_setClaimToolTip(claim.casID) }}"
                    placement="top" tooltipClass="enterPaymentToolTip">
                    <!-- <td>
                        {{ claim.adjAmnt }}
                    </td> -->

                    <td>
                        <div class="colBox colBoxAlgnLft">
                            <div (click)="onClick_showProcDetail(claim.casID);">
                                {{ claim.patLstNm + "," }}
                            </div>
                            <div (click)="onClick_showProcDetail(claim.casID);">
                                {{ claim.patNm }}
                            </div>
                            <div style="align-self: flex-end;" ngbToolTip="{ engLang ? 'View claim':'Ver caso' }"
                                (click)="xtPID = claim.patID; xtCID = claim.casID;
                                    patNmModalHd = claim.patLstNm + ', ' + claim.patNm;
                                    casNoModalHd = claim.casNo + ' - ' + claim.casOfNo;
                                    open(recordCaseModal, { backdrop: 'static', size: 'lg', scrollable: true }, 'recordCaseModal');">
                                <i class="fas fa-folder-open"></i>
                            </div>
                        </div>
                    </td>
                    <td (click)="onClick_showProcDetail(claim.casID);">
                        <div class="colBox" style="align-items: center">
                            <!-- {{ claim.casDt }} -->
                            <div>
                                {{ claim.casDt.substring(0,5) }}
                            </div>
                            <div>
                                {{ claim.casDt.substring(6,8) }}
                            </div>
                        </div>
                    </td>
                    <td (click)="onClick_showProcDetail(claim.casID);">
                        {{ claim.casCont }}
                    </td>
                    <td (click)="onClick_showProcDetail(claim.casID);">
                        <div>
                            {{ claim.casNo }}
                        </div>
                        <div>
                            -{{ claim.casOfNo }}
                        </div>
                    </td>
                    <td (click)="onClick_showProcDetail(claim.casID);" style="text-align: right;"
                        [ngClass]="{ 'redColor' : isPending(claim.balance) }">
                        {{ claim.balance }}
                    </td>
                    <td (click)="onClick_showProcDetail(claim.casID);" style="text-align: right;">
                        {{ claim.totPayment }}
                    </td>
                    <td>
                        <i class="fas fa-undo" *ngIf="paymentUndoable(claim)"
                            (click)="onChange_payUndo(c, { 'casID': claim.casID, 'casProvID': claim.casProvID, 'ps': claim.ps, 'adjDetID': claim.adjDetID, 'balance': claim.balance })"></i>
                    </td>
                    <td (click)="onClick_showProcDetail(claim.casID);">
                        {{ claim.ps }}
                    </td>
                    <td>
                        <div class="form-check">
                            <input type="checkbox" id="chkAdj{{c}}" class="form-check-input"
                                [checked]="+claim.adjusted ? true: false" [disabled]="+claim.reclaimed"
                                (change)="onChange_caseAdjust(c, { 'casID': claim.casID, 'casProvID': claim.casProvID, 'ps': claim.ps, 'adjDetID': claim.adjDetID })">

                            <label for="chkAdj{{c}}" style="font-size: small" class="form-check-label"
                                ngbTooltip="{{ engLang ? 'Adjust': 'Ajustar' }}">A</label>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" id="chkRecl{{c}}" class="form-check-input"
                                [checked]="+claim.reclaimed ? true: false"
                                [disabled]="(+claim.adjusted || !claim.hasPayments || !+claim.balance)"
                                (change)="onChange_caseReclaim($event, claim.casID, claim.ps, claim.reclTyp, claim.insID);">

                            <label for="chkRecl{{c}}" style="font-size: small" class="form-check-label"
                                ngbTooltip="{{ engLang ? 'Reclaim': 'Reclamar' }}">R</label>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" id="chkCorr{{c}}" class="form-check-input"
                                [checked]="(claim.correction === '1' ? true: false)"
                                [attr.disabled]="(claim.adjusted == '1' || +claim.balance === 0? true: null)">

                            <label for="chkCorr{{c}}" style="font-size: small" class="form-check-label"
                                ngbTooltip="{{ engLang ? 'Correction': 'Corrección' }}">C</label>
                        </div>
                    </td>
                    <td (click)="onClick_showProcDetail(claim.casID);" style="font-size: smaller;">
                        <div class="colBox colBoxAlgnLft">
                            <div *ngIf="claim.ins">
                                {{ claim.ins }}
                            </div>
                            <div *ngIf="claim.oIns">
                                {{ claim.oIns }}
                            </div>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="(procs && procs[0].detCasID === claim.casID ? true: false)">
                    <td colspan="12">
                        <div class="text-center">
                            <span style="font-style: italic; font-weight: bold;">
                                {{ engLang ? 'Entered payments are saved by closing this claim clicking above or
                                on another claim.':
                                'Pagos entrados se grabarán al cerrar este caso cliqueando arriba o en otro caso.' }}
                            </span>
                        </div>
                        <table class="table table-bordered table-sm" style="font-size: 90%; background-color: #aa8737;">
                            <thead>
                                <tr>
                                    <th style="display: none">
                                        detCasID
                                    </th>
                                    <th>
                                        &#189;
                                    </th>
                                    <th>
                                        {{ engLang ? 'Dates': 'Fechas' }}
                                    </th>
                                    <th>
                                        {{ engLang ? 'Code': 'Código' }}
                                    </th>
                                    <th>
                                        {{ engLang ? 'Qty': 'Cant' }}
                                    </th>
                                    <th>
                                        Dx
                                    </th>
                                    <th>
                                        {{ engLang ? 'POS': 'Lug'}}
                                    </th>
                                    <th>
                                        {{ engLang ? 'Expect / Usual': 'Espera / Usual'}}
                                    </th>
                                    <th class="text-center">
                                        {{ engLang ? 'Payment': 'Pago' }}
                                    </th>
                                    <th class="text-center">
                                        ICN
                                    </th>
                                    <th>
                                        Desc
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let proc of procs; let p = index;"
                                [ngStyle]="{'background-color': (p % 2 == 0 ? '#b9b6ad': '#f3f1ed')}">
                                <!-- style="color: #b9b6ad"> -->
                                <tr>
                                    <td style="display: none">
                                        {{ proc.detCasID }}
                                    </td>
                                    <td>
                                        {{ proc.detPS }}
                                    </td>
                                    <td>
                                        <div class="colBox colBoxAlgnLft">
                                            <div>
                                                {{ proc.fromDt }}
                                            </div>
                                            <div>
                                                {{ proc.toDt }}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="colBox colBoxAlgnLft">
                                            <div>
                                                {{ proc.detPcode }}
                                            </div>
                                            <div *ngIf="proc.modifs">
                                                - {{ proc.mods }}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        {{ proc.detQty }}
                                    </td>
                                    <td>
                                        {{ proc.diags }}
                                    </td>
                                    <td class="text-center">
                                        {{ proc.detPos }}
                                    </td>
                                    <td>
                                        <div class="colBox colBoxAlgnLft">
                                            <div id="{{ 'xpt' + p }}" class="currency">
                                                <b>{{ proc.xpect | currency:'USD':'':'1.2-2' }}</b>
                                            </div>
                                            <div class="currency">
                                                {{ proc.usual | currency:'USD':'':'1.2-2' }}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngIf="proc.detPS == claim.ps" class="d-inline-flex">
                                            <div class="form-check">
                                                <input id="{{ 'chk' + p }}" type="checkbox"
                                                    class="form-check-input mt-2"
                                                    (change)="onChange_setPayedEqXpect($event,p,proc);"
                                                    (keydown)="onKeyPress_icn($event,p)">
                                            </div>
                                            <div>
                                                <input id="{{ 'pay' + p }}" type="text"
                                                    class="form-control form-control-sm currStyle"
                                                    (focus)="onFocus_decimal($event)"
                                                    (keypress)="onKeyPress_decimal($event,p)"
                                                    (blur)="onBlur_setDecimalValue($event,p)" [(ngModel)]="proc.payment"
                                                    size="6" maxlength="9">
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngIf="proc.detPS == claim.ps">
                                            <input id="{{ 'icn' + p }}" type="text" class="form-control form-control-sm"
                                                (focus)="onFocus_icn($event,p)" (keypress)="onKeyPress_icn($event,p)"
                                                (keydown)="onKeyPress_icn($event,p)" [(ngModel)]="proc.icn" size="10"
                                                maxlength="30">
                                        </div>
                                    </td>
                                    <td>
                                        {{ proc.detDesc }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- <pre>{{ sortAsc }}</pre> -->
        <!-- <pre>{{ modalStack.length }} {{ modalStack }}</pre> -->
        <!-- <pre>{{ ckClaims | json }}</pre> -->
        <!-- <pre>{{ undoPaysArr | json }}</pre> -->
        <!-- <pre>{{ casIndxFound | json }}</pre> -->

    </div>

    <div class="modal-footer justify-content-between" style="background-color: #c7e9f7;">

        <div class="small align-self-center">
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar' }}
        </div>
        <div class="mt-3">
            <pagination-controls id="claims" (pageChange)="currentPageClms = $event" directionLinks="true"
                previousLabel="" nextLabel="" autoHide="true">
            </pagination-controls>
        </div>
        <div *ngIf="ckClaims && ckClaims.length" class="small align-self-center">
            {{ ckClaims.length | currency:'USD':'':'1.0-0' }}{{ (engLang ? " claims": " casos") }}
        </div>
        <div class="align-self-center">
            <button type="button" class="btn btn-sm btn-outline-dark" (click)="c('closeClaimsModal')">{{
                engLang ?
                'Close': 'Cerrar' }}</button>
        </div>
    </div>
</ng-template>

<ng-template #reclaimsModalTmpl let-modal>
    <div class="modal-header" style="background-color:  #c1c1bf;">
        <div>
            <h4 class="modal-title mb-1">{{ engLang ? 'Reclaim Form': 'Forma para Reclamo' }}
            </h4>
        </div>
        <div *ngIf="spinner" class="modal-tit-center-div">
            <img src="app/assets/images/waiting4Response.gif" width="42" height="42" class="text-center">
        </div>
        <div class="align-self-start">
            <button type="button" class="btn btn-sm btn-close" (click)="closeReclaimsModal()"
                (keyup)="onKeyup_escape($event)"></button>
        </div>
    </div>

    <div class="modal-body m-0 p-0" (keyup)="onKeyup_escape($event)">
        <!-- DON'T CHANGE THIS PADDING AS IT WILL DISPLACE THE RECLAIM IMAGES -->

        <div *ngIf="reclCasIdSeled && reclPsSeled">
            <app-reclaim [sn]="sn" [engLang]="engLang" [casID]="reclCasIdSeled" [ps]="reclPsSeled" [ckNo]="chkgNo"
                [ckDt]="chkgDt" [reclTyp]="reclFormTypSeled" [saveToggle]="saveToggle" [deleteToggle]="deleteToggle"
                [insIdSeld]="reclInsID" [reclID]="reclID" [printToggle]="printToggle" [idxSeld]="reclIdxSeld"
                [icnsPrevIdx]="icnsPrevIdx" (closeEventEmitter)="closeReclaimComponent($event)"
                (enabSaveReclEventEmitter)="setEnabSaveReclBtn($event)"
                (resetSaveToggleEventEmitter)="resetSaveToggle($event)"
                (resetDeleteToggleEventEmitter)="resetDeleteToggle($event)"
                (showPrintDeleteBtnEventEmitter)="printDelete($event)"
                (ckUnckClmReclaimEventEmitter)="ckUnckReclaimCkBox($event)"
                (spinnerEventEmitter)="toggleSpinner($event)" (resetIcnsPrevIdxEventEmitter)="resetIcnsPrevIdx($event)">
            </app-reclaim>
        </div>

    </div>

    <div class="modal-footer justify-content-between" style="background-color: #c1c1bf;">
        <div style="font-size: 70%;">
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
        </div>
        <div *ngIf="+reclID" class="d-flex justify-content-evenly">
            <div *ngIf="!spinner">
                <button type="button" class="btn btn-secondary" (click)="deleteToggle=true">{{ engLang ?
                    'Delete':
                    'Eliminar' }}
                    <i class="fa fa-trash"></i>
                </button>
            </div>
            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<!-- spacer -->
            </div>
            <div>
                <button type="button" class="btn btn-success" (click)="print()">{{
                    engLang ? 'Print': 'Imprimir' }}
                    <i class="fas fa-print cursor-pointer"></i>
                </button>
            </div>
        </div>
        <div>
            <button type="button" class="btn btn-outline-dark" *ngIf="!spinner" [disabled]="!enabSaveRecl"
                (click)="saveToggle = true">{{ engLang ? 'Save': 'Grabar' }}</button>
        </div>
        <!-- <pre>{{ modalStack }}</pre> -->
    </div>
</ng-template>

<!-- modal record/case .............................................................................. -->
<ng-template #recordCaseModal let-modal>
    <div class="modal-header p-8" style="background-color: #ecead6;">
        <div>
            Record: <strong>{{ patNmModalHd }}</strong> <br />
            {{ engLang ? 'Claim: ': 'Caso: ' }}{{ casNoModalHd }}
        </div>
        <div>
            <button type="button" class="btn btn-sm btn-close" style="margin-top: -30px;"
                (click)="modal.dismiss('recordCaseModal')"></button>
        </div>
    </div>

    <div class="modal-body" #modalBody>
        <app-record [activeTab]="2" [xtPID]="xtPID" [xtCID]="xtCID" [modalBody]="modalBody"
            (setNewRecordNoEvent)="setNewRecordNo($event)">
        </app-record>
    </div>

    <div class="modal-footer d-sm-inline-flex justify-content-between p-8" style="background-color: #ecead6;">
        <div>
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
        </div>
        <div>
            <button type="button" class="btn btn-sm btn-outline-dark" *ngIf="!waiting4Response"
                (click)="modal.close('close_recordCaseModal')">{{ engLang ? 'Close': 'Cerrar' }}</button>
        </div>
    </div>

</ng-template>

<!-- Success toast template -->
<ng-template #successToast>
    <div class="text-center">
        <img src="app/assets/images/success-ok.png" width="35px" height="35px">
    </div>
</ng-template>

<!-- modal prompt to begin saving 835 payments to cases ............................................. -->
<ng-template #save835paymentsModal let-modal>
    <div class="modal-header" style="background-color: #fca796;">
        <h4 class="modal-title"><span *ngIf="!finished">{{ engLang ? 'Save ' + unCredPayCnt + ' Payments ?': '¿ Grabar '
                +
                unCredPayCnt + ' Pagos ?' }}</span></h4>
        <button type="button" class="btn btn-sm btn-close align-self-start" (click)="modal.dismiss('')"></button>
    </div>
    <div class="modal-body">
        <div *ngIf="!engLang && +unCredPayCnt > 0">
            <p>
                {{unCredPayCnt}} pagos electrónicos (835) <span *ngIf="!finished">serán</span><span
                    *ngIf="finished">fueron</span> acreditados a sus correspondientes casos.
            </p>
            <div *ngIf="!finished">
                <p>
                    Por favor:
                </p>
                <ul>
                    <li>
                        No cierre el navegador (browser)
                    </li>
                    <li>
                        No navegue hacia atrás <i class="far fa-arrow-alt-circle-left"></i>
                    </li>
                    <li>
                        Espere notificación de <strong>Terminado</strong>
                    </li>
                    <li>
                        Cliquee en <strong>Ok</strong> para comenzar el proceso y espere ...
                    </li>
                </ul>
            </div>
        </div>

        <div *ngIf="engLang && !finished">
            <p>
                {{unCredPayCnt}} electronic payments (835) <span *ngIf="!finished">will be</span><span
                    *ngIf="finished">were</span> credited to their corresponding claims.
            </p>
            <div *ngIf="!finished">
                <p>
                    Please:
                </p>
                <ul>
                    <li>
                        Do not close the browser
                    </li>
                    <li>
                        Do not click on Back <i class="far fa-arrow-alt-circle-left"></i>
                    </li>
                    <li>
                        Wait for the notification from the system of <strong>Finished</strong>
                    </li>
                    <li>
                        Click <strong>Ok</strong> now to start the process and wait ...
                    </li>
                </ul>
            </div>
        </div>

        <div *ngIf="waiting4Response && !finished" class="box">
            <img src="app/assets/images/waiting4Response.gif" width="42" height="42">
        </div>
        <div *ngIf="finished && !+unCredPayCnt" class="text-center" style="font-size: 24px;">
            <b>{{ engLang ? 'Finished!': '¡Terminado!'}}</b>
        </div>

        <!--
        <div *ngIf="!engLang && unCredPayCnt != 0 && !finished">
            <p>
                El cheque seleccionado no puede ser acreditado porque:
            </p>
            <div>
                <ul>
                    <li *ngIf="unMatchedClaims() != 0">
                        Contiene {{ unMatchedClaims() }} pagos que no son parte de esta facilidad o no fueron
                        encontrados ni
                        pareados a casos de esta facilidad.
                    </li>
                    <li *ngIf="previouslyCredited() !=0">
                        Contiene {{ previouslyCredited() }} pagos ya acreditados anteriormente.
                    </li>
                </ul>
            </div>
        </div>

        <div *ngIf="engLang && unCredPayCnt != 0 && !finished">
            <p>
                The selected check's payments cannot be saved because:
            </p>
            <div>
                <ul>
                    <li *ngIf="unMatchedClaims() != 0">
                        Contains {{ unMatchedClaims() }} payments that do not belong to this facility or they were not
                        found
                        or matched to claims from this facility.
                    </li>
                    <li *ngIf="previouslyCredited() !=0">
                        Contains {{ previouslyCredited() }} payments previously saved.
                    </li>
                </ul>
            </div>
        </div>
        -->
    </div>

    <div class="modal-footer justify-content-between" style="background-color: #fca796;">
        <div style="font-size: 70%;">
            {{ engLang ? 'Esc - Close without saving': 'Esc - Cerrar sin grabar'}}
        </div>
        <div>
            <button [disabled]="waiting4Response" type="button" class="btn btn-outline-dark"
                (click)="modal.close('oK_credit835Payments')">Ok</button>
        </div>
    </div>

    <!-- <pre>{{ modalStack }}</pre> -->
    <!-- <pre>{{ modalStack.length }}</pre> -->

</ng-template>

<!-- modal reclaim exit w/o saving .................................................................. -->
<ng-template #reclaimsExitWoSaveModal let-modal>
    <div class="modal-header" style="background-color:  #d6deec;">
        <div>
            <h4 class="modal-title"><img src="app/assets/images/yellow-blue-green-question-marks.png" width="28"
                    height="28"></h4>
        </div>
        <div class="align-self-start">
            <button type="button" class="btn btn-sm btn-close"
                (click)="modal.dismiss('closeReclaimExitWoSaveModal')"></button>
        </div>
    </div>
    <div class="modal-body text-center">
        {{ engLang ? 'Exit w/o Saving ?': '¿ Salir sin Grabar ?' }}
    </div>
    <div class="modal-footer justify-content-between" style="background-color: #d6deec;">
        <div style="font-size: 70%;">
            <!-- {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}} Diisable to avoid eternal loop when Esc reactivates background modal -->
        </div>
        <div>
            <button type="button" class="btn btn-outline-dark"
                (click)="modal.close('oK_exitWoSaveReclaimModal')">Ok</button>
        </div>
    </div>

    <!-- <pre>{{ modalStack.length }}</pre> -->
    <!-- <pre>{{ modalStack }}</pre> -->

</ng-template>

<div *ngIf="adjustPromptModal">
    <app-claim-adjust-modal-prompt [engLang]="engLang" [adjustRemove]="adjustRemove" [undoPayment]="undoPayment"
        (adjModalPromptResult)="adjustModalResult($event)">
    </app-claim-adjust-modal-prompt>
</div>

<!-- <pre>showZeroBal</pre>
<pre>{{showZeroBal}}</pre>
<pre>procs</pre>
<pre>{{ procs | json }}</pre>
<pre>ckClaims</pre>
<pre>{{ ckClaims | json }}</pre>
<pre>chkgDetID</pre>
<pre>{{ chkgDetID }}</pre>
<pre>{{ checks | json }}</pre>
-->

<!-- <pre>{{ provsNPI | json }}</pre>
<pre>srchCkNo</pre>
<pre>{{ srchCkNo }}</pre>
<pre>srchFromDt</pre>
<pre>{{ srchFromDt }}</pre>
<pre>srchToDt</pre>
<pre>{{ srchToDt }}</pre>
<pre>srchDtType</pre>
<pre>{{ srchDtType }}</pre>

<pre>{{ chkgNo }}</pre>
<pre>chkgDt</pre>
<pre>{{ chkgDt }}</pre>
<pre>chkgRxDt</pre>
<pre>{{ chkgRxDt }}</pre>

<pre>{{ chkgNo }}</pre>
<pre>{{ chkgTotal }}</pre>
<pre>{{ chkgTaxDed }}</pre> 
<pre>chkgBalance</pre>
<pre>{{ chkgBalance }}</pre>


<pre>{{ chkgMedDed }}</pre>
<pre>{{ chkgOtherDed }}</pre>
<pre>{{ chkgNPI }}</pre>
<pre>{{ chkgPayerId }}</pre>
-->

<!-- <pre>activeSqlProcs</pre>
<pre>{{activeSqlProcs | json}}</pre> -->