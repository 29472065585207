/* src/app/ck-register/recl-xml-mmm-pmc-rev-b01162014/recl-xml-mmm-pmc-rev-b01162014.component.css */
input {
  cursor: pointer;
  border: 1px solid orange;
}
.onechar {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}
.font10 {
  font-size: 10px;
}
.opt3MPMC_1 {
  position: absolute;
  top: 150px;
  left: 77px;
}
.opt3MPMC_2 {
  position: absolute;
  top: 150px;
  left: 204px;
}
.opt3MPMC_3 {
  position: absolute;
  top: 187px;
  left: 77px;
}
.opt3MPMC_4 {
  position: absolute;
  top: 208px;
  left: 77px;
}
.opt3MPMC_5 {
  position: absolute;
  top: 206px;
  left: 204px;
}
.txt3MPMC_Other_B {
  position: absolute;
  top: 206px;
  left: 325px;
}
.opt3MPMC_6 {
  position: absolute;
  top: 267px;
  left: 77px;
}
.opt3MPMC_7 {
  position: absolute;
  top: 288px;
  left: 77px;
}
.opt3MPMC_8 {
  position: absolute;
  top: 288px;
  left: 204px;
}
.txt3MPMC_Other_C {
  position: absolute;
  top: 287px;
  left: 325px;
}
.txt3MPMC_ProvNm {
  position: absolute;
  top: 138px;
  left: 630px;
}
.txt3MPMC_TaxID {
  position: absolute;
  top: 185px;
  left: 583px;
}
.txt3MPMC_RendNPI {
  position: absolute;
  top: 218px;
  left: 583px;
}
.txt3MPMC_BillNPI {
  position: absolute;
  top: 253px;
  left: 583px;
}
.txt3MPMC_Tel {
  position: absolute;
  top: 185px;
  left: 860px;
}
.txt3MPMC_Email {
  position: absolute;
  top: 218px;
  left: 860px;
}
.txt3MPMC_Name1 {
  position: absolute;
  top: 359px;
  left: 54px;
}
.txt3MPMC_ContrNo1 {
  position: absolute;
  top: 359px;
  left: 265px;
}
.txt3MPMC_Dx1 {
  position: absolute;
  top: 359px;
  left: 385px;
}
.txt3MPMC_ReclamNo1 {
  position: absolute;
  top: 359px;
  left: 480px;
}
.txt3MPMC_Date1 {
  position: absolute;
  top: 359px;
  left: 627px;
}
.txt3MPMC_PayDt1 {
  position: absolute;
  top: 359px;
  left: 709px;
}
.txt3MPMC_Reason1 {
  position: absolute;
  top: 359px;
  left: 791px;
}
.txt3MPMC_Comment1 {
  position: absolute;
  top: 359px;
  left: 855px;
}
.txt3MPMC_Name2 {
  position: absolute;
  top: 378px;
  left: 54px;
}
.txt3MPMC_ContrNo2 {
  position: absolute;
  top: 378px;
  left: 265px;
}
.txt3MPMC_Dx2 {
  position: absolute;
  top: 378px;
  left: 385px;
}
.txt3MPMC_ReclamNo2 {
  position: absolute;
  top: 378px;
  left: 480px;
}
.txt3MPMC_Date2 {
  position: absolute;
  top: 378px;
  left: 627px;
}
.txt3MPMC_PayDt2 {
  position: absolute;
  top: 378px;
  left: 709px;
}
.txt3MPMC_Reason2 {
  position: absolute;
  top: 378px;
  left: 791px;
}
.txt3MPMC_Comment2 {
  position: absolute;
  top: 378px;
  left: 855px;
}
.txt3MPMC_Name3 {
  position: absolute;
  top: 398px;
  left: 54px;
}
.txt3MPMC_ContrNo3 {
  position: absolute;
  top: 398px;
  left: 265px;
}
.txt3MPMC_Dx3 {
  position: absolute;
  top: 398px;
  left: 385px;
}
.txt3MPMC_ReclamNo3 {
  position: absolute;
  top: 398px;
  left: 480px;
}
.txt3MPMC_Date3 {
  position: absolute;
  top: 398px;
  left: 627px;
}
.txt3MPMC_PayDt3 {
  position: absolute;
  top: 398px;
  left: 709px;
}
.txt3MPMC_Reason3 {
  position: absolute;
  top: 398px;
  left: 791px;
}
.txt3MPMC_Comment3 {
  position: absolute;
  top: 398px;
  left: 855px;
}
.txt3MPMC_Name4 {
  position: absolute;
  top: 418px;
  left: 54px;
}
.txt3MPMC_ContrNo4 {
  position: absolute;
  top: 418px;
  left: 265px;
}
.txt3MPMC_Dx4 {
  position: absolute;
  top: 418px;
  left: 385px;
}
.txt3MPMC_ReclamNo4 {
  position: absolute;
  top: 418px;
  left: 480px;
}
.txt3MPMC_Date4 {
  position: absolute;
  top: 418px;
  left: 627px;
}
.txt3MPMC_PayDt4 {
  position: absolute;
  top: 418px;
  left: 709px;
}
.txt3MPMC_Reason4 {
  position: absolute;
  top: 418px;
  left: 791px;
}
.txt3MPMC_Comment4 {
  position: absolute;
  top: 418px;
  left: 855px;
}
.txt3MPMC_Name5 {
  position: absolute;
  top: 438px;
  left: 54px;
}
.txt3MPMC_ContrNo5 {
  position: absolute;
  top: 438px;
  left: 265px;
}
.txt3MPMC_Dx5 {
  position: absolute;
  top: 438px;
  left: 385px;
}
.txt3MPMC_ReclamNo5 {
  position: absolute;
  top: 438px;
  left: 480px;
}
.txt3MPMC_Date5 {
  position: absolute;
  top: 438px;
  left: 627px;
}
.txt3MPMC_PayDt5 {
  position: absolute;
  top: 438px;
  left: 709px;
}
.txt3MPMC_Reason5 {
  position: absolute;
  top: 438px;
  left: 791px;
}
.txt3MPMC_Comment5 {
  position: absolute;
  top: 438px;
  left: 855px;
}
.txt3MPMC_Other {
  position: absolute;
  top: 500px;
  left: 755px;
}
.txt3MPMC_Comment {
  position: absolute;
  top: 521px;
  left: 140px;
}
.txt3MPMC_SolicitorNm {
  position: absolute;
  top: 640px;
  left: 920px;
}
.txt3MPMC_SolicitorNm {
  position: absolute;
  top: 640px;
  left: 70px;
}
.txt3MPMC_Date {
  position: absolute;
  top: 640px;
  left: 920px;
}
.lnk1 {
  position: absolute;
  top: 462px;
  left: 50px;
}
.lnk2 {
  position: absolute;
  top: 493px;
  left: 50px;
}
.lnk3 {
  position: absolute;
  top: 462px;
  left: 202px;
}
.lnk4 {
  position: absolute;
  top: 493px;
  left: 202px;
}
.lnk5 {
  position: absolute;
  top: 462px;
  left: 378px;
}
.lnk6 {
  position: absolute;
  top: 493px;
  left: 378px;
}
.lnk7 {
  position: absolute;
  top: 462px;
  left: 577px;
}
.lnk8 {
  position: absolute;
  top: 493px;
  left: 577px;
}
.lnk9 {
  position: absolute;
  top: 462px;
  left: 776px;
}
/*# sourceMappingURL=recl-xml-mmm-pmc-rev-b01162014.component.css.map */
