import { Pipe, PipeTransform } from '@angular/core';
import { ICheck } from '../shared/interfaces/checks';

@Pipe({
    name: 'ckRegisterPipe',
    standalone: false
})
export class CkRegisterPipe implements PipeTransform {

  transform(input: any[]): ICheck[] {
    if (!input) {
      return input;
    }
    return input.filter(ck => {
      if (ck.hide) {
        return null;
      }
      return input;
    });
  }

}
