/* src/app/shared/reclaim/recl-xml-mmm-gobierno/recl-xml-mmm-gobierno.component.css */
input {
  cursor: pointer;
  border: 1px solid orange;
}
.currcy {
  text-align: right;
}
.font10 {
  font-size: 10px;
}
.optMMM {
  position: absolute;
  top: 148px;
  left: 698px;
}
.optPMC {
  position: absolute;
  top: 148px;
  left: 913px;
}
.txtMMM_PMC_Gob_ProvNm {
  position: absolute;
  top: 145px;
  left: 260px;
}
.txtMMM_PMC_Gob_NPI {
  position: absolute;
  top: 168px;
  left: 300px;
}
.txtMMM_PMC_Gob_Date {
  position: absolute;
  top: 168px;
  left: 750px;
}
.txt3MPMC_ContrNo1 {
  position: absolute;
  top: 386px;
  left: 120px;
}
.txt3MPMC_ReclamNo1 {
  position: absolute;
  top: 386px;
  left: 255px;
}
.txt3MPMC_Cpt1 {
  position: absolute;
  top: 386px;
  left: 400px;
}
.txt3MPMC_Date1 {
  position: absolute;
  top: 386px;
  left: 560px;
}
.txt3MPMC_Bal1 {
  position: absolute;
  top: 386px;
  left: 670px;
}
.txt3MPMC_Reason1 {
  position: absolute;
  top: 386px;
  left: 750px;
  width: 250px;
}
.txt3MPMC_ContrNo2 {
  position: absolute;
  top: 411px;
  left: 120px;
}
.txt3MPMC_ReclamNo2 {
  position: absolute;
  top: 411px;
  left: 255px;
}
.txt3MPMC_Cpt2 {
  position: absolute;
  top: 411px;
  left: 400px;
}
.txt3MPMC_Date2 {
  position: absolute;
  top: 411px;
  left: 560px;
}
.txt3MPMC_Bal2 {
  position: absolute;
  top: 411px;
  left: 670px;
}
.txt3MPMC_Reason2 {
  position: absolute;
  top: 411px;
  left: 750px;
  width: 250px;
}
.txt3MPMC_ContrNo3 {
  position: absolute;
  top: 436px;
  left: 120px;
}
.txt3MPMC_ReclamNo3 {
  position: absolute;
  top: 436px;
  left: 255px;
}
.txt3MPMC_Cpt3 {
  position: absolute;
  top: 436px;
  left: 400px;
}
.txt3MPMC_Date3 {
  position: absolute;
  top: 436px;
  left: 560px;
}
.txt3MPMC_Bal3 {
  position: absolute;
  top: 436px;
  left: 670px;
}
.txt3MPMC_Reason3 {
  position: absolute;
  top: 436px;
  left: 750px;
  width: 250px;
}
.txt3MPMC_ContrNo4 {
  position: absolute;
  top: 461px;
  left: 120px;
}
.txt3MPMC_ReclamNo4 {
  position: absolute;
  top: 461px;
  left: 255px;
}
.txt3MPMC_Cpt4 {
  position: absolute;
  top: 461px;
  left: 400px;
}
.txt3MPMC_Date4 {
  position: absolute;
  top: 461px;
  left: 560px;
}
.txt3MPMC_Bal4 {
  position: absolute;
  top: 461px;
  left: 670px;
}
.txt3MPMC_Reason4 {
  position: absolute;
  top: 461px;
  left: 750px;
  width: 250px;
}
.txt3MPMC_ContrNo5 {
  position: absolute;
  top: 487px;
  left: 120px;
}
.txt3MPMC_ReclamNo5 {
  position: absolute;
  top: 487px;
  left: 255px;
}
.txt3MPMC_Cpt5 {
  position: absolute;
  top: 487px;
  left: 400px;
}
.txt3MPMC_Date5 {
  position: absolute;
  top: 487px;
  left: 560px;
}
.txt3MPMC_Bal5 {
  position: absolute;
  top: 487px;
  left: 670px;
}
.txt3MPMC_Reason5 {
  position: absolute;
  top: 487px;
  left: 750px;
  width: 250px;
}
.txt3MPMC_ContrNo6 {
  position: absolute;
  top: 512px;
  left: 120px;
}
.txt3MPMC_ReclamNo6 {
  position: absolute;
  top: 512px;
  left: 255px;
}
.txt3MPMC_Cpt6 {
  position: absolute;
  top: 512px;
  left: 400px;
}
.txt3MPMC_Date6 {
  position: absolute;
  top: 512px;
  left: 560px;
}
.txt3MPMC_Bal6 {
  position: absolute;
  top: 512px;
  left: 670px;
}
.txt3MPMC_Reason6 {
  position: absolute;
  top: 512px;
  left: 750px;
  width: 250px;
}
.txt3MPMC_ContrNo7 {
  position: absolute;
  top: 537px;
  left: 120px;
}
.txt3MPMC_ReclamNo7 {
  position: absolute;
  top: 537px;
  left: 255px;
}
.txt3MPMC_Cpt7 {
  position: absolute;
  top: 537px;
  left: 400px;
}
.txt3MPMC_Date7 {
  position: absolute;
  top: 537px;
  left: 560px;
}
.txt3MPMC_Bal7 {
  position: absolute;
  top: 537px;
  left: 670px;
}
.txt3MPMC_Reason7 {
  position: absolute;
  top: 537px;
  left: 750px;
  width: 250px;
}
.txt3MPMC_ContrNo8 {
  position: absolute;
  top: 563px;
  left: 120px;
}
.txt3MPMC_ReclamNo8 {
  position: absolute;
  top: 563px;
  left: 255px;
}
.txt3MPMC_Cpt8 {
  position: absolute;
  top: 563px;
  left: 400px;
}
.txt3MPMC_Date8 {
  position: absolute;
  top: 563px;
  left: 560px;
}
.txt3MPMC_Bal8 {
  position: absolute;
  top: 563px;
  left: 670px;
}
.txt3MPMC_Reason8 {
  position: absolute;
  top: 563px;
  left: 750px;
  width: 250px;
}
.txt3MPMC_ContrNo9 {
  position: absolute;
  top: 588px;
  left: 120px;
}
.txt3MPMC_ReclamNo9 {
  position: absolute;
  top: 588px;
  left: 255px;
}
.txt3MPMC_Cpt9 {
  position: absolute;
  top: 588px;
  left: 400px;
}
.txt3MPMC_Date9 {
  position: absolute;
  top: 588px;
  left: 560px;
}
.txt3MPMC_Bal9 {
  position: absolute;
  top: 588px;
  left: 670px;
}
.txt3MPMC_Reason9 {
  position: absolute;
  top: 588px;
  left: 750px;
  width: 250px;
}
.txt3MPMC_ContrNo10 {
  position: absolute;
  top: 613px;
  left: 120px;
}
.txt3MPMC_ReclamNo10 {
  position: absolute;
  top: 613px;
  left: 255px;
}
.txt3MPMC_Cpt10 {
  position: absolute;
  top: 613px;
  left: 400px;
}
.txt3MPMC_Date10 {
  position: absolute;
  top: 613px;
  left: 560px;
}
.txt3MPMC_Bal10 {
  position: absolute;
  top: 613px;
  left: 670px;
}
.txt3MPMC_Reason10 {
  position: absolute;
  top: 613px;
  left: 750px;
  width: 250px;
}
.txt3MPMC_ContrNo11 {
  position: absolute;
  top: 638px;
  left: 120px;
}
.txt3MPMC_ReclamNo11 {
  position: absolute;
  top: 638px;
  left: 255px;
}
.txt3MPMC_Cpt11 {
  position: absolute;
  top: 638px;
  left: 400px;
}
.txt3MPMC_Date11 {
  position: absolute;
  top: 638px;
  left: 560px;
}
.txt3MPMC_Bal11 {
  position: absolute;
  top: 638px;
  left: 670px;
}
.txt3MPMC_Reason11 {
  position: absolute;
  top: 638px;
  left: 750px;
  width: 250px;
}
.txt3MPMC_ContrNo12 {
  position: absolute;
  top: 665px;
  left: 120px;
}
.txt3MPMC_ReclamNo12 {
  position: absolute;
  top: 665px;
  left: 255px;
}
.txt3MPMC_Cpt12 {
  position: absolute;
  top: 665px;
  left: 400px;
}
.txt3MPMC_Date12 {
  position: absolute;
  top: 665px;
  left: 560px;
}
.txt3MPMC_Bal12 {
  position: absolute;
  top: 665px;
  left: 670px;
}
.txt3MPMC_Reason12 {
  position: absolute;
  top: 665px;
  left: 750px;
  width: 250px;
}
.txt3MPMC_ContrNo13 {
  position: absolute;
  top: 690px;
  left: 120px;
}
.txt3MPMC_ReclamNo13 {
  position: absolute;
  top: 690px;
  left: 255px;
}
.txt3MPMC_Cpt13 {
  position: absolute;
  top: 690px;
  left: 400px;
}
.txt3MPMC_Date13 {
  position: absolute;
  top: 690px;
  left: 560px;
}
.txt3MPMC_Bal13 {
  position: absolute;
  top: 690px;
  left: 670px;
}
.txt3MPMC_Reason13 {
  position: absolute;
  top: 690px;
  left: 750px;
  width: 250px;
}
.txt3MPMC_ContrNo14 {
  position: absolute;
  top: 715px;
  left: 120px;
}
.txt3MPMC_ReclamNo14 {
  position: absolute;
  top: 715px;
  left: 255px;
}
.txt3MPMC_Cpt14 {
  position: absolute;
  top: 715px;
  left: 400px;
}
.txt3MPMC_Date14 {
  position: absolute;
  top: 715px;
  left: 560px;
}
.txt3MPMC_Bal14 {
  position: absolute;
  top: 715px;
  left: 670px;
}
.txt3MPMC_Reason14 {
  position: absolute;
  top: 715px;
  left: 750px;
  width: 250px;
}
.txtMMM_PMC_Gob_Tot {
  position: absolute;
  top: 739px;
  left: 670px;
}
/*# sourceMappingURL=recl-xml-mmm-gobierno.component.css.map */
