<div style="box-sizing: border-box;">
    <div class="boxPayModalHead">
        <div class="d-inline-block">
            <div>
                Check No.:
            </div>
            <div>
                {{ hdCkNo }}
            </div>
        </div>

        <div class="d-inline-block">
            <div>
                {{ engLang ? 'Date': 'Fecha' }}:
            </div>
            <div>
                {{ hdCkDt }}
            </div>
        </div>

        <div class="d-sm-inline-flex">
            <div class="d-inline-block">
                <div
                    ngbTooltip="{{ engLang ? 'Claims with pending payments to credit': 'Casos con pagos pendientes de acreditar' }}">
                    {{ engLang ? 'Pending:': 'Pendiente: ' }}<span *ngIf="hdPendCnt">({{ hdPendCnt }})</span>
                </div>
                <div [ngClass]="{ 'redColor' : isPending(hdCkBal) }" class="currency">
                    {{ hdCkBal | currency:'USD':'':'1.2-2' }}
                </div>
            </div>
        </div>

        <div class="d-sm-inline-flex">
            <div class="d-inline-block">
                <div style="width: 75px; text-align: right;">
                    Adj: ({{ adjCnt }})
                </div>
                <div class="currency">
                    {{ adjTotal | currency:'USD':'':'1.2-2' }}
                </div>
            </div>
            <div>
                &nbsp;
                <span *ngIf="showZeroBal" (click)="search4Claims($event);">
                    <i id="clmAdjSrch" *ngIf="!claimsAdjustedSrchActive" class="fa fa-search blueColor"
                        style="font-size: small; cursor: pointer;"></i>
                    <i *ngIf="claimsAdjustedSrchActive" class="far fa-times-circle" style="cursor: pointer;"></i>
                </span>
            </div>
        </div>

        <div class="d-sm-inline-flex">
            <div class="d-inline-block">
                <div style="width: 75px; text-align: right;">
                    Recl: ({{ reclCnt }})
                </div>
                <div class="currency">
                    {{ reclTotal | currency:'USD':'':'1.2-2' }}
                </div>
            </div>
            <div>
                &nbsp;
                <span (click)="search4Claims($event);">
                    <i id="reclmSrch" *ngIf="!reclaimedSrchActive" class="fa fa-search blueColor"
                        style="font-size:small; cursor: pointer;"></i>
                    <i *ngIf="reclaimedSrchActive" class="far fa-times-circle" style="cursor: pointer;"></i>
                </span>
            </div>
        </div>

        <div class="d-inline-block">
            <div>
                Corr: ({{ corrCnt }})
            </div>
            <div>
                <input id="chkZeroBal" type="checkbox" [(ngModel)]="showZeroBal" (change)="onClick_toggleZeroBal();"
                    ngbTooltip="{{ engLang ? 'Click to show claims with zero balance.': 'Mostrar casos con balance en cero.' }}">
                <label for="chkZeroBal">&nbsp;{{ engLang ? 'Zero balance': 'Cero balance' }}</label>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column justify-content-center">
        <div class="rowBox">
            <div>
                <label for="srchClaim" class="mt-2">
                    {{ engLang ? 'Search:': 'Buscar:'}}<span *ngIf="claimSrchActive">({{
                        casIndxFound?.length }})</span>&nbsp;
                </label>
            </div>
            <div>
                <input type="text" id="srchClaim" (keyup)="onKeyUp_srch($event);"
                    (click)="$event.target.select();claimSrchActive = false;" #srchClaim [(ngModel)]="srchClaimTxt"
                    ngbTooltip="{{ engLang ? 'Name, Contract or Claim No. to search.': 'Nombre, No. Contrato o Caso para buscar.' }}">
            </div>
            <div>
                &nbsp;<i *ngIf="!claimSrchActive" class="fa fa-search blueColor" style="cursor: pointer;"
                    (click)="search4Claims($event);"></i>
                <i *ngIf="claimSrchActive" class="far fa-times-circle"
                    (click)="srchClaim.value=''; search4Claims($event); srchClaim.focus();"></i>
            </div>
        </div>
        <div class="rowBox xsmall p-1" style="height: 1px;" *ngIf="+unMatchCnt">
            {{ engLang ? 'Unmatched payments: ':'Pagos no pareados: ' }}{{ unMatchCnt }}
        </div>
    </div>
</div>