<!-- ================ REGISTER FORM ============================================================== -->
<div class="container" style="background-color: #f9fafc;">
    <br /> <!-- use as a top margin -->
    <div style="margin-top: 0; margin-right: 10px; margin-left: 10px; margin-bottom: 0;">

        <div id="disclaimer">
            <!-- printable div -->

            <div class="page-header">
                <h2 class="text-center">{{engLang ? "New Registration": "Registro Nuevo"}}</h2>
            </div>

            <div class="form-group" style="margin-bottom: 0;">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="newEmail" style="font-size: 70%; margin-bottom: 0;" [ngClass]="{'alert alert-danger': custEmailErr }">
                            {{engLang ? "Email Address": "Correo Electrónico"}}
                        </label>
                    </div>
                    <div class="col-sm-6">
                        <label for="custUserNm" style="font-size: 70%; margin-bottom: 0;" [ngClass]="{'alert alert-danger': custUserNmErr }">
                            {{engLang ? "User Name": "Nombre Usuario"}}
                        </label>
                    </div>
                </div>
                <div class="input-group">
                    <input type="email" id="newEmail" name="newEmail" class="form-control input-sm"
                        style="font-size: 80%; margin-top: -3px;"
                        placeholder='{{engLang ? "Email Address": "Correo Electrónico"}}'
                        title='{{engLang ? "Email Address": "Correo Electrónico"}}' maxlength="50"
                        [ngClass]="{'alert alert-danger': custEmailErr }" [(ngModel)]="newEmail">
                    <span class="input-group-addon"></span>
                    <input type="text" id="custUserNm" name="custUserNm" class="form-control input-sm"
                        style="font-size: 80%; margin-top: -3px;"
                        placeholder='{{engLang ? "User Name (for login)": "Nombre Usuario (para login)"}}'
                        title='{{engLang ? "User Name": "Nombre Usuario"}}' maxlength="50"
                        [ngClass]="{'alert alert-danger': custUserNmErr }" [(ngModel)]="cust.userNm">
                </div>
            </div>

            <div class="form-group" style="margin-bottom: 0;">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="custPassword" style="font-size: 70%; margin-bottom: 0;" [ngClass]="{'alert alert-danger': custPasswordInvalidErr || custPasswordMismatchErr }">
                            {{engLang ? "Password": "Contraseña"}}
                        </label>
                    </div>
                    <div class="col-sm-6">
                        <label for="custPassword2" style="font-size: 70%; margin-bottom: 0;" [ngClass]="{'alert alert-danger': password2InvalidErr || custPasswordMismatchErr }">
                            {{engLang ? "Repeat Password": "Repita Contraseña"}}
                        </label>
                    </div>
                </div>
                <div class="input-group">
                    <input type="password" id="custPassword" name="password" class="form-control input-sm"
                        style="font-size: 80%; margin-bottom: -3px;"
                        placeholder='{{engLang ? "Password": "Contraseña"}}'
                        title='{{engLang ? "Password": "Contraseña"}}' maxlength="50"
                        [ngClass]="{'alert alert-danger': custPasswordInvalidErr || custPasswordMismatchErr }"
                        [(ngModel)]="cust.password">
                    <span class="input-group-addon"></span>
                    <input type="password" id="custPassword2" name="password2" class="form-control input-sm"
                        style="font-size: 80%; margin-bottom: -3px;"
                        placeholder='{{engLang ? "Repeat Password": "Repita Contraseña"}}'
                        title='{{engLang ? "Repeat Password": "Repita Contraseña"}}' maxlength="50"
                        [ngClass]="{'alert alert-danger': password2InvalidErr || custPasswordMismatchErr }"
                        [(ngModel)]="password2">
                </div>
            </div>

            <div class="form-group" style="margin-bottom: 0;">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="custCompanyNm" style="font-size: 70%; margin-bottom: 0;" [ngClass]="{'alert alert-danger': custCompanyNmErr }">
                            {{engLang ? "Company Name (optional)": "Nombre Compañía (opcional)"}}
                        </label>
                    </div>
                    <div class="col-sm-6">
                        <label for="custMbDesktopSN" style="font-size: 70%; margin-bottom: 0;" [ngClass]="{'alert alert-danger': custMbDesktopSNErr }">
                            {{engLang ? "MedicalBillerDesktop (local PC desktop) SN (serial no. i.e 012F)": "MedicalBillerDesktop (PC local) SN (número serie ej: 012F)"}}
                        </label>
                    </div>
                </div>
                <div class="input-group">
                    <input type="text" id="custCompanyNm" name="custCompanyNm" class="form-control input-sm"
                        style="font-size: 80%; margin-bottom: -3px;"
                        placeholder='{{engLang ? "Company Name (optional)": "Nombre Compañía (opcional)"}}'
                        title='{{engLang ? "Company Name (optional)": "Nombre Compañía (opcional)"}}' maxlength="50"
                        [ngClass]="{'alert alert-danger': custCompanyNmErr }" [(ngModel)]="custCompanyNm">
                    <span class="input-group-addon"></span>
                    <input type="text" id="custMbDesktopSN" name="custMbDesktopSN" class="form-control input-sm"
                        style="font-size: 80%; margin-bottom: -3px;"
                        placeholder='{{engLang ? "MedicalBiller (local PC desktop) SN (serial no.)": "MedicalBiller (PC local) SN (número serie)"}}'
                        title='{{engLang ? "MedicalBiller (local PC desktop) SN (serial no.)": "MedicalBiller (PC local) SN (número serie)"}}'
                        maxlength="4" [ngClass]="{'alert alert-danger': custMbDesktopSNErr }"
                        [(ngModel)]="custMbDesktopSN">
                </div>
            </div>

            <div class="form-group" style="margin-bottom: 0;">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="custLastNm" style="font-size: 70%; margin-bottom: 0;" [ngClass]="{'alert alert-danger': custLastNmErr }">
                            {{engLang ? "Last Name": "Apellidos"}}
                        </label>
                    </div>
                    <div class="col-sm-6">
                        <label for="custMbDesktopSN" style="font-size: 70%; margin-bottom: 0;" [ngClass]="{'alert alert-danger': custFirstNmErr }">
                            {{engLang ? "First Name": "Primer Nombre"}}
                        </label>
                    </div>
                </div>
                <div class="input-group">
                    <div class="input-group">
                        <input type="text" id="custLastNm" name="custLastNm" class="form-control input-sm"
                            style="font-size: 80%; margin-bottom: -3px;"
                            placeholder='{{engLang ? "Last Name": "Apellidos"}}'
                            title='{{engLang ? "Last Name": "Apellidos"}}' maxlength="50"
                            [ngClass]="{'alert alert-danger': custLastNmErr }" [(ngModel)]="custLastNm">
                        <span class="input-group-addon"></span>
                        <input type="text" id="custFirstNm" name="custFirstNm" class="form-control input-sm"
                            style="font-size: 80%; margin-bottom: -3px;"
                            placeholder='{{engLang ? "First Name": "Primer Nombre"}}'
                            title='{{engLang ? "First Name": "Primer Nombre"}}' maxlength="50"
                            [ngClass]="{'alert alert-danger': custFirstNmErr }" [(ngModel)]="custFirstNm">
                    </div>
                </div>
            </div>

            <div class="form-group" style="margin-bottom: 0;">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="custLastNm" style="font-size: 70%; margin-bottom: 0;" [ngClass]="{'alert alert-danger': custCellPhoneErr }">
                            {{engLang ? "Cell Phone": "Teléfono Celular o Movil"}}
                        </label>
                    </div>
                    <div class="col-sm-6">
                        <label for="custMbDesktopSN" style="font-size: 70%; margin-bottom: 0;" [ngClass]="{'alert alert-danger': custOtherPhoneErr }">
                            {{engLang ? "Other Phone (optional)": "Otro Teléfono (opcional)"}}
                        </label>
                    </div>
                </div>
                <div class="input-group">
                    <input type="text" id="custCellPhone" name="custCellPhone" class="form-control input-sm"
                        style="font-size: 80%; margin-bottom: -3px;"
                        placeholder='{{engLang ? "Cell Phone": "Teléfono Celular o Movil"}}'
                        title='{{engLang ? "Cell Phone": "Teléfono Celular o Movil"}}' jqueryMaskededit
                        maskPattern="(999) 999-9999" [ngClass]="{'alert alert-danger': custCellPhoneErr }"
                        [value]="cust.cellPhone" (blur)="cust.cellPhone = $event.target.value">
                    <span class="input-group-addon"></span>
                    <input type="text" id="custOtherPhone" name="custOtherPhone" class="form-control input-sm"
                        style="font-size: 80%; margin-bottom: -3px;"
                        placeholder='{{engLang ? "Other Phone (optional)": "Otro Teléfono (opcional)"}}'
                        title='{{engLang ? "Other Phone (optional)": "Otro Teléfono (opcional)"}}' jqueryMaskededit
                        maskPattern="(999) 999-9999" [ngClass]="{'alert alert-danger': custOtherPhoneErr }"
                        [value]="cust.otherPhone" (blur)="cust.otherPhone = $event.target.value">
                </div>
            </div>

            <br />
            <div class="page-header">
                <h2 class="text-center">{{engLang ? "Terms and Conditions": "Términos y Condiciones"}}</h2>
                <h5 class="text-center">{{engLang ? "(Sign and accept at the end)": "(Firme y acepte al final)"}}</h5>
            </div>


            <div *ngIf="engLang">
                <p style="text-align: center;"><strong> Software as a Service (“SAAS”) subscription agreement with
                        online terms
                        of
                        purchase and use.</strong></p>

                <p><strong>CUSTOMER: <span [style.color]="customerNameColor"><u>{{customerName}}</u></span></strong></p>

                <p><strong>CUSTOMER FULL LEGAL NAME: <span
                            [style.color]="customerFullLegalNameColor"><u>{{customerFullLegalName}}</u></span></strong>
                </p>

                <p><strong>SERVICE PROVIDER: MedicalComputerSystems/MedicalBiller/JLRosado</strong></p>

                <p><strong>Effective Date: <u>{{cust.signedDate}}</u></strong></p>

                <p><strong>Initial Term: Monthly renewal (unless otherwise specified) until cancelled by either party
                        upon
                        notification</strong> from the Effective Date. No prepaid months (or other period) will be
                    reimbursed
                    upon
                    cancellation.</p>

                <p>If you require any more information or have any questions about our site's disclaimer, please feel
                    free to
                    contact us
                    by email at medbiler&#64;gmail.com</p>

                <h2 style="text-align: center;">Disclaimers for MedicalBiller On Cloud and related websites</h2>

                <p>
                    All the information and software on these websites - MedicalBillerpr.com , MedicalBiller-pr.com ,
                    MedicalBiller.us - is published in good faith and for general information purpose only.
                    The Provider does not make any warranties about the completeness, reliability and accuracy of this
                    information
                    and or services.
                    <u>Any action you take upon the information you find on these websites is strictly at your own
                        risk.</u>
                    The Provider will not be liable for any losses and/or damages in connection with the use of any of
                    our
                    websites.
                </p>
                <p>
                    Some of the services offered in these websites involve Software as a Service (“SAAS”) agreements.
                    These services must be paid for by the Customer on a periodic and timely basis as per the
                    corresponding
                    service
                    sales transaction.
                    Upon completion or cancellation of such services as agreed by both parties the Client shall have NO
                    rights
                    to
                    any software, source code, services or data previously entered
                    and/or stored in our servers, or used in/from our servers unless previously agreed upon in a
                    separate
                    agreement.
                </p>
                <p>
                    The Provider in good faith has subcontracted and will subcontract good reputable companies for the
                    service
                    of
                    virtualization of hardware to be able to provide
                    the Customer with SAAS and cloud computing capabilities. However the Provider cannot and will not
                    guarantee
                    the
                    Customer any measure of up/down times nor response/latency intervals.
                    Data backup warranty arrangements between the Provider and infrastructure suppliers will not
                    necessarily
                    include
                    the Customer.
                </p>
                <p>
                    The Customer is responsible to provide at his/her own expense any hardware, materials, services,
                    electric
                    power,
                    internet service, personnel, etc., necessary to connect to the Provider websites.
                    No in person visits to or phone interaction with the Client will be required from the Provider.
                    Official
                    communication and notifications will be done through email (medbiler&#64;gmail.com,
                    medbiler&#64;hotmail.com).
                    Reasonable response time will be provided but no measure of quantitative response time is
                    guaranteed.
                </p>
                <p>From our website, Customer can visit other websites by following hyperlinks to such external sites.
                    While
                    Provider strives to
                    provide only quality links to useful and ethical websites, we have no control over the content and
                    nature of
                    these
                    sites. These links to other websites do not imply a recommendation for all the content found on
                    these sites.
                    Site
                    owners and content may change without notice and may occur before we have the opportunity to remove
                    a link
                    which
                    may
                    have gone 'bad'.
                </p>

                <p>Please be also aware that when you leave our website, other sites may have different privacy policies
                    and
                    terms
                    which
                    are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their
                    "Terms
                    of
                    Service" before engaging in any business or uploading any information.</p>

                <h2 style="text-align: center;">Consent</h2>

                <p>
                    By using any of our websites, you hereby consent to our disclaimer and agree to its terms.
                </p>
            </div>

            <div *ngIf="!engLang">
                <p style="text-align: center;"><strong> Programación Como Servicio (“SAAS”) acuerdo de subscripción con
                        términos
                        de
                        compra y uso.</strong></p>

                <p><strong>CLIENTE: <span [style.color]="customerNameColor"><u>{{customerName}}</u></span></strong></p>

                <p><strong>NOMBRE COMPLETO LEGAL CLIENTE: <span
                            [style.color]="customerFullLegalNameColor"><u>{{customerFullLegalName}}</u></span></strong>
                </p>

                <p><strong>PROVEEDOR SERVICIO: MedicalComputerSystems/MedicalBiller/JLRosado</strong></p>

                <p><strong>Fecha Efectiva: <u>{{cust.signedDate}}</u></strong></p>

                <p><strong>Término Inicial: Renovable mensual (si no hay otro acuerdo) hasta que se cancele por una de
                        las
                        partes
                        mediante notificación</strong> previa desde la Fecha Effectiva. Dinero no será devuelto por
                    meses (u
                    otro
                    periodo) prepagado al cancelar.</p>

                <p>Si usted necesita más información o tiene preguntas, por favor contáctenos por email a
                    medbiler&#64;gmail.com</p>

                <h2 style="text-align: center;">Descargo de Responsabilidad para MedicalBiller On Cloud y sus sitios
                    web.</h2>

                <p>
                    Toda información y programación en los sitios web - MedicalBillerpr.com , MedicalBiller-pr.com ,
                    MedicalBiller.us - se publica de buena fe y solo para propósitos de información general.
                    El Proveedor no garantiza veracidad, confiabilidad ni exactitud de esta información y/o servicios.
                    <u>Cualquier acción que usted tome basada en la información y servicios que utilice de estos sitios
                        web lo
                        hace
                        estrictamente a su propio riesgo.</u>
                    El Proveedor no será responsable por pérdidas o daños incurridos por el uso de cualquiera de estos
                    sitios
                    web.
                </p>
                <p>
                    Algunos de los servicios ofrecidos en estos sitios web incluyen acuerdos de "Software as a Service"
                    (“SAAS”)
                    ó
                    Programación Como Servicio.
                    Estos servicios deberán ser pagados por el Cliente de manera periódica y regularmente según
                    estipulado en la
                    transacción de venta.
                    Al terminar o cancelar estos servicios según acordado por ambas partes el Cliente NO tendrá derecho
                    a
                    ninguna
                    programación, código fuente,
                    servicios o data previamente entrada y/o almacenada en nuestros servidores ("servers"), o utilizada
                    en o
                    desde
                    nuestros servidores a menos que exista un acuerdo previo.
                </p>
                <p>
                    El Proveedor de buena fe ha subcontratado y subcontratará compañías competentes y de buena
                    reputación para
                    servicios de
                    virtualización de equipo ("hardware") para poder proveer al Cliente servicios de SAAS y computación
                    en la
                    nube
                    ("cloud computing"). Sin embargo el Proveedor no puede garantizar al Cliente medidas de tiempo
                    funcional
                    ("up/down times") o velocidad/tardanza de respuesta ("speed/latency").
                    Acuerdos de copias y resguardo de data ("data backups") entre el Proveedor y suplidores de
                    infraestructura
                    no
                    necesariamente
                    cubrirán al Cliente.
                </p>
                <p>
                    El Cliente será responsable de costear y proveer cualquier equipo, materiales, servicios,
                    electricidad,
                    servicio internet, personal, etc., necesarios para conectar a los sitios web del Proveedor.
                    No se requerirá del Proveedor visitas en persona ni llamadas telefónicas para interactuar con el
                    Cliente.
                    Comunicación oficial
                    y notificaciones se harán por email (medbiler&#64;gmail.com, medbiler&#64;hotmail.com).
                    Se responderá en un tiempo razonable pero no se garantiza respuesta en algún tiempo específico.
                </p>
                <p>Desde nuestros sitios web el Cliente puede visitar otros sitios siguiendo enlaces a los mismos.
                    Mientras que
                    el
                    Proveedor se esmera en proveer solamente enlaces a sitios de calidad y alta ética, no tenemos
                    control sobre
                    el
                    contenido de estos. El Cliente no debe asumir que estamos recomendando estos sitios web.
                    El contenido de estos puede cambiar súbitamente sin el conocimiento del Proveedor.
                </p>

                <p>Al visitar otros sitios web desde nuestro sitio las políticas de privacidad y términos de uso pueden
                    cambiar.
                </p>

                <h2 style="text-align: center;">Consentimiento</h2>

                <p>
                    Al utilizar cualquiera de nuestros sitios web, usted acepta nuestros términos y Descargo de
                    Responsabilidad
                    ("disclaimer") quedando de acuerdo con los mismos.
                </p>
            </div>

            <div class="row" [ngClass]="{'alert alert-danger': customerTypedSignatureErr}">
                <label for="custTypedSignature" class="col-sm-5 control-label">
                    {{engLang ? 'CUSTOMER:' : 'CLIENTE:'}}
                    <span style="color: red;" *ngIf="!engLang"> (Firme aquí con el teclado) </span>
                    <span style="color: red;" *ngIf="engLang"> (Sign here with the keyboard) </span>
                </label>
                <input id="custTypedSignature" name="custTypedSignature" class="col-md-4" type="text" style="text-decoration: underline;"
                    maxlength="50" [(ngModel)]="cust.typedSignature" value="">
                <br />
            </div>
            <br />

            <div class="row">
                <label for="custTitle" class="col-sm-1 control-label">
                    <span *ngIf="engLang">Title:</span>
                    <span *ngIf="!engLang">Título:</span>
                </label>
                <input id="custTitle" name="custTitle" class="col-sm-2" type="text" maxlength="20" [(ngModel)]="cust.title">
            </div>
            <br />

            <div class="row" [ngClass]="{'alert alert-danger': signedDateErr}">
                <label for="custSignedDate" class="col-sm-2 control-label">
                    <span *ngIf="!engLang">Fecha (mes/día/año): </span>
                    <span *ngIf="engLang">Date (month/day/year):</span>
                </label>
                <!-- cannot use [(ngModel)] in next <input...> when using jqueryMaskededit directive -->
                <input type="text" id="custSignedDate" name="custSignedDate" class="form-control input-sm col-sm-1" jqueryMaskededit
                    maskPattern="99/99/99" style="font-size: 80%;" [value]="cust.signedDate"
                    (blur)="cust.signedDate = $event.target.value">
                <br />
            </div>
            <br />
            <br />

            <div class="row" [ngClass]="{'alert alert-danger': acceptedErr}">
                <!-- *ngIf="!allowPrint" prevents an empty checkbox from printing -->
                <input *ngIf="!allowPrint" type="checkbox" id="chkAccept" style="margin-left: 20px; margin-bottom: 0;"
                    [(ngModel)]="accepted">
                <label for="chkAccept" style="margin-left: 10px; margin-top: -5px;">
                    <span *ngIf="engLang"> I accept all the Terms and Conditions.</span>
                    <span *ngIf="!engLang"> Acepto todos los Términos y Condiciones.</span>
                </label>
            </div>
        </div>

        <button type="button" class="btn btn-link" printSectionId="disclaimer" ngxPrint
            [disabled]="!allowPrint"><i class="fas fa-print"></i>{{engLang ? ' Print' : ' Imprimir'}}</button>
        <span *ngIf="waiting4Response">
            <img src="app/assets/images/waiting4Response.gif" width="42" height="42">
        </span>
        <button #proceedToRegister type="button" class="btn btn-success" style="border-radius: 4px;"
            (click)="register()">{{engLang ? 'Continue': 'Continuar'}}</button>
    </div>

    <div *ngIf="signInErr" class="alert alert-danger">
        {{signInErrMsg}}
    </div>

    <br /> <!-- use as a bottom margin -->
</div>

<!-- <pre>{{ cust | json }}</pre> -->