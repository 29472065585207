/* src/app/shared/q837/q837.component.css */
.rtalign {
  text-align: right;
}
.font9 {
  font-size: 9px;
}
.font10 {
  font-size: 10px;
}
.font11 {
  font-size: 11px;
}
.font16 {
  font-size: 16px;
}
.clmRow:hover {
  background-color: #e6e6e6 !important;
  cursor: pointer;
}
.error-bkg {
  font-style: italic;
  background-color: rgb(241, 202, 162);
}
.data-bkg {
  background-color: #E0FFE0;
}
.collapsedRow {
  display: none;
}
.errors {
  color: red;
}
.mouse-pointer {
  cursor: pointer;
}
.status-width {
  width: 20px;
}
.max-width-40 {
  max-width: 40px;
  width: 40px;
}
.max-width-30 {
  max-width: 30px;
  width: 30px;
}
.max-width-75 {
  max-width: 75px;
  width: 75px;
}
.max-width-130 {
  max-width: 130px;
  width: 130px;
}
/*# sourceMappingURL=q837.component.css.map */
