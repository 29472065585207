import { Component, ElementRef, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataMessageService } from '../../../shared/data-message.service';
import { IRptAgingClm } from '../../../shared/interfaces/rptAgingClm';
import { IRptAgingDet } from '../../../shared/interfaces/rptAgingDet';
import { IRptAgingPad } from '../../../shared/interfaces/rptAgingPad';
import { IRptAgingPay } from '../../../shared/interfaces/rptAgingPay';
import { MatMsgDlgComponent } from '../../../shared/mat-msg-dlg/mat-msg-dlg.component';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IRptAgingIns } from '../../../shared/interfaces/rptAgingIns';
import { AppToastsService } from '../../../shared/app-toasts/app-toasts.service';
import { CkRegister835DetailService } from '../../../ck-register/ck-register835-detail/ck-register835-detail.service';

@Component({
  selector: 'app-rpt-aging-claims',
  templateUrl: './rpt-aging-claims.component.html',
  styleUrls: ['../rpt-aging.component.css'],
  standalone: false
})
export class RptAgingClaimsComponent implements OnDestroy {
  @Input() ins: IRptAgingIns[] = [];  // All insurances
  @Input() clm: IRptAgingClm[] = [];  // All claims belonging to expanded insurance  
  @Input() det: IRptAgingDet[] = [];  // Procedures (CPT) & payments belonging to expanded insurance (all claims)
  @Input() pad: IRptAgingPad[] = [];  // Payment adjustments belonging to expanded insurance (all claims)

  @ViewChild('modalBody') modalBody: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('reclaimExitWoSaveModal') reclaimExitWoSaveModal: ElementRef;
  @ViewChild('reclaimsModalTmpl', { read: TemplateRef, static: true }) reclaimsModalTmpl: TemplateRef<any>;

  sn: string;
  engLang: boolean;
  modalStack: string[] = [];
  waiting4Response = false; // Spinner visibility

  // RecordCaseModal fields
  xtPID: string;
  xtCID: string;
  patNmModalHd: string;
  casNoModalHd: string;

  // Reclaim fields
  reclCasID: string;
  reclPS: string;
  reclCkNo: string;
  reclCkDt: string;
  reclICN: string;
  reclInsId: string;
  reclTyp: string;
  enabSaveRecl: boolean;
  saveToggle: boolean;
  deleteToggle: boolean;
  printToggle: boolean;
  reclID: string;
  reclaimCkBxEvent: any;
  reclaimForms: IReclForms[] = [];
  reclaimsModalRef: NgbModalRef;
  reclIdxSeld: number = -1; // Index in reclaims[] being edited
  changeIcnObj: { nxtIcnIdx: number, nxtReclID: string, prvIcnIdx: number };
  icnsPrevIdx: number = -1;

  dataSource: MatTableDataSource<any>;
  rptAgingClmDisplydCols: string[] = [
    'CasDt',
    'CasNo',
    'PatNm',
    'CasContNo',
    'ps',
    'otherIns',
    'cBalance',
    'dxs',
    'hasNote',
    'adjust',
    'reclaimed'
  ];
  detExpanded: IRptAgingDet[] = []; // Procedures (CPT) & payments belonging to expanded claim
  padExpanded: IRptAgingPad[] = []; // Payment adjustments belonging to expanded claim
  pay: IRptAgingPay[] = []; // Payment subset from detExpanded to pass on to pay component
  expandedClms: string[] = []; // Use to highlight claims clicked to expand

  spinner: boolean = true; // Spinner feedback 

  constructor(private _dataMessageService: DataMessageService,
    public _dialog: MatDialog,
    private _modalService: NgbModal,
    private _ckRegister835DetailService: CkRegister835DetailService,
    private _appToastsService: AppToastsService
  ) { }

  ngOnInit(): void {
    // this._dataMessageService.currentSnStr.subscribe(snStr => this.snChange(snStr)); // Subscription looking for changes in sn
    this._dataMessageService.currentEngLangStr.subscribe(engLangStr => this.engLangChange(engLangStr)); // Subscription looking for changes in engLang

    let arr: any[] = this.clm.slice();
    this.dataSource = new MatTableDataSource(arr);
    this.dataSource.sort = this.sort; // Always follow this.dataSource = ... with this to maintain header sort arrow synced
  }

  ngOnDestroy(): void {
    this.clm = undefined;
    this.det = undefined;
    this.pay = undefined;
    this.detExpanded = undefined;
    this.dataSource.data = undefined;
    this.rptAgingClmDisplydCols = undefined;
  }

  snChange(snStr: string) { // for when sn changes
    this.sn = snStr;
  }

  engLangChange(engLangStr: string) { // for when language changes
    engLangStr === 'true' ? this.engLang = true : this.engLang = false;
  }

  onClick_expandClaims(row): void {
    // console.log('row.expandCol', row.expandCol);
    // console.log('row', row);

    if (row.expandCol == '') {
      this.detExpanded = (this.det.filter(d => { return (d.DetCasID === row.CasID && ((+row.c2iKey && d.DetPS === row.ps) || !+row.c2iKey)) })).slice();  // +row.c2iKey='0' for PVDO
      row.expandCol = 'true';
      this.expandedClms.push(row.c2dKey);

      let arr: IRptAgingDet[] = [];
      arr = this.detExpanded.filter(d => { return +d.PayID });
      arr.forEach(a => {
        this.pay.push(  // Move payments included in detExpanded to new pay[] array for displaying
          {
            pkId: a.pkId,
            d2cKey: a.d2cKey,
            DetCasID: a.DetCasID,
            PayID: a.PayID,
            PayDetID: a.PayDetID,
            PayPS: a.PayPS,
            PayDt: a.PayDt,
            PayAmnt: a.PayAmnt,
            PayCkNo: a.PayCkNo,
            PayApCode: a.PayApCode,
            PayMode: a.PayMode,
            PayMemo: a.PayMemo
          }
        )
      });

      for (let i = this.detExpanded.length - 1; i >= 0; i--) {  // Delete included payments in detExpanded
        if (+this.detExpanded[i].PayID) {
          this.detExpanded.splice(i, 1);
        }
      }

      this.padExpanded = (this.pad.filter(a => a.a2cKey === row.c2dKey)).slice();
      // a2cKey = DetPS + '-' + LTRIM(STR(DetCasID))
      // c2dKey = ps + '-' + LTRIM(STR(CasID)) 
    } else {
      // this.detExpanded = [];
      // this.padExpanded = [];
      row.expandCol = '';
      this.pay = [];
      let i = this.expandedClms.findIndex(k => row.c2dKey === k);
      this.expandedClms.splice(i, 1);
    }
  }

  isExpanded(row: any): boolean {
    return this.expandedClms.includes(row.c2dKey);
  }

  hasPayments(claim: any): boolean {  // Use to disable claim level reclaim checkbox if no payments received
    if (this.det.find(d => d.d2cKey === claim.c2dKey && d.DetPS === claim.ps && d.PayAmnt && d.PayDt && d.PayCkNo)) {
      return true;
    }
    return false;
  }

  showCasNotes(event: any, claim: any) {
    event.stopPropagation();  // Don' expand or collapse the claim row
    if (claim.hasNote == 'True') {
      this._dialog.open(MatMsgDlgComponent, {
        data: {
          tit: 'Notas: ' + claim.PatNm + ' ' + (this.engLang ? 'Claim No:' : 'Caso No:') + claim.CasNo,
          msg: claim.NoteText,
          engLang: this.engLang
        }
      });
    }
  }

  onClick_reclaim(event, claim: IRptAgingClm) {
    this.reclaimCkBxEvent = event;  // Use to toggle checkbox back & forth
    event.preventDefault(); // Don't toggle yet, wait for saved result
    this.reclCasID = claim.CasID;
    this.reclPS = claim.ps;
    this.reclInsId = claim.c2iKey;
    this.reclTyp = (this.ins.find(i => i.i2cKey === claim.c2iKey)).reclTyp

    //TODO: Might need to change the following to arrays & be able to select from multiple posible payments in reclaim-component
    this.reclCkNo = (this.det.find(d => d.d2cKey === claim.c2dKey && d.PayPS === 'S' + claim.ps))?.PayCkNo; // Using ? in case there's no payment
    this.reclCkDt = (this.det.find(d => d.d2cKey === claim.c2dKey && d.PayPS === 'S' + claim.ps))?.PayDt;
    this.reclICN = (this.det.find(d => d.d2cKey === claim.c2dKey && d.PayPS === 'S' + claim.ps))?.PayApCode;
  }

  setEnabSaveReclBtn(event: any) {
    this.enabSaveRecl = event;
  }

  resetSaveToggle(event: any) {
    this.saveToggle = event;
  }

  resetDeleteToggle(event: any) {
    this.deleteToggle = event;
  }

  print() {
    this.printToggle = true;
    setTimeout(() => {
      this.printToggle = false;
    }, 3000); //3 sec debouncer
  }

  printDelete(event: any) {
    this.reclID = event;
  }

  ckUnckReclaimCkBox(event: any) {
    this.reclaimCkBxEvent.target.checked = +event;
  }
  // this.reclaimCkBk.checked = event.cked;
  // (this.dataSource.data.find(c => c.CasID === event.casID && c.ps === event.ps)).reclaimed = event.cked ? '1' : '';
  //this.dataSource[i].reclaimed = event.cked;

  closeReclaimComponent(event): void {
    // this.reclCasID = undefined;
    // this.reclPS = undefined;
    // this.reclCkNo = undefined;
    // this.reclCkDt = undefined;
    // this.reclICN = undefined;
    // this.reclTyp = undefined;

    if (event) {
      this.changeIcnObj = event;
    } else {
      this.changeIcnObj = undefined;
    }
    this.closeReclaimsModal();
  }

  onClick_adjust(event, claim) {
    event.preventDefault();
    if (claim.expandCol) {
      event.stopPropagation();
    }
  }

  sortea(sort) {
    if (!sort.active || sort.direction === '') {  // No active sort or sort disabled, reset your data source & "sort" (see below)
      let arr: any[] = this.clm.slice();
      this.dataSource = new MatTableDataSource(arr);
      this.dataSource.sort = this.sort; // Always follow this.dataSource = ... with this to maintain header sort arrow synced
      return;
    }

    let arr2sort: any[] = this.clm.slice();
    if (sort.direction === 'asc') {
      if (sort.active === 'cBalance') {
        arr2sort.sort(this.sortBy_balance_Asc);
      }
      if (sort.active === 'CasContNo') {
        arr2sort.sort(this.sortBy_contract_Asc);
      }
      if (sort.active === 'PatNm') {
        arr2sort.sort(this.sortBy_name_Asc);
      }
    } else {
      if (sort.active === 'cBalance') {
        arr2sort.sort(this.sortBy_balance_Dsc);
      }
      if (sort.active === 'CasContNo') {
        arr2sort.sort(this.sortBy_contract_Dsc);
      }
      if (sort.active === 'PatNm') {
        arr2sort.sort(this.sortBy_name_Dsc);
      }
    }
    this.clm = arr2sort.slice();
    this.dataSource = new MatTableDataSource(this.clm);
    this.dataSource.sort = this.sort; // Always follow this.dataSource = ... with this to maintain header sort arrow/state synced
  }

  sortBy_balance_Asc(a, b): number {
    if (+a.cBalance < +b.cBalance) {
      return 1;
    }
    if (+a.cBalance > +b.cBalance) {
      return -1;
    }
    return 0;
  }

  sortBy_balance_Dsc(a, b): number {
    if (+a.cBalance > +b.cBalance) {
      return 1;
    }
    if (+a.cBalance < +b.cBalance) {
      return -1;
    }
    return 0;
  }

  sortBy_contract_Asc(a, b): number {
    if (a.CasContNo < b.CasContNo) {
      return 1;
    }
    if (a.CasContNo > b.CasContNo) {
      return -1;
    }
    return 0;
  }

  sortBy_contract_Dsc(a, b): number {
    if (a.CasContNo > b.CasContNo) {
      return 1;
    }
    if (a.CasContNo < b.CasContNo) {
      return -1;
    }
    return 0;
  }

  sortBy_name_Asc(a, b): number {
    if (a.PatNm < b.PatNm) {
      return 1;
    }
    if (a.PatNm > b.PatNm) {
      return -1;
    }
    return 0;
  }

  sortBy_name_Dsc(a, b): number {
    if (a.PatNm > b.PatNm) {
      return 1;
    }
    if (a.PatNm < b.PatNm) {
      return -1;
    }
    return 0;
  }

  open(content, opts, modalNm) {
    this.modalStack.push(modalNm);

    const modalRef = this._modalService.open(content, opts);
    modalRef.result.then((result) => {    // Save, Ok, etc
      console.log('result', result);

      if (result === 'oK_exitWoSaveReclaimModal') {
        this.enabSaveRecl = false;
        if (this.changeIcnObj) {
          this.reclIdxSeld = this.changeIcnObj.nxtIcnIdx; // This will change idxSeld all the way down to the reclaim form component
          this.reclID = this.changeIcnObj.nxtReclID;
          this.icnsPrevIdx = -1;
          this.changeIcnObj = undefined;
        } else {
          this.reclaimsModalRef.dismiss();
        }

        this.reclaimsModalRef.dismiss('close_reclaimsModal');
      }

    }, (reason) => {    // dismiss, X, Close, Esc
      console.log('reason', reason);
      console.log('modalStack', this.modalStack);
      switch (this.modalStack[this.modalStack.length - 1]) {
        case 'reclaimExitWoSaveModal':
          this.icnsPrevIdx = this.changeIcnObj ? (this.changeIcnObj.prvIcnIdx > -1 ? this.changeIcnObj.prvIcnIdx : -1) : -1;
          break;
        case 'reclaimsModalTmpl':
          this.reclIdxSeld = -1;
          this.reclTyp = undefined;
          break;
        case 'close_recordCaseModal':
          // Do some cleanup
          break;
        default:
      }

      this.modalStack.pop();
      this.waiting4Response = false;
    });
  }

  resetIcnsPrevIdx(event) {
    this.icnsPrevIdx = -1;
  }

  openReclModal(content: any, opts: any, nm: any) {
    this.reclaimsModalRef = this._modalService.open(content, opts);
    this.reclaimsModalRef.result.then((result: any) => {

    }, (reason: any) => { // Handle dismissal

    });
  }

  closeReclaimsModal() {
    if (this.enabSaveRecl) {
      this.open(this.reclaimExitWoSaveModal, { backdrop: 'static' }, 'reclaimExitWoSaveModal');
    } else {
      if (!this.changeIcnObj) {
        this.reclaimsModalRef.dismiss('');
      } else {
        this.reclIdxSeld = this.changeIcnObj.nxtIcnIdx;
        this.reclID = this.changeIcnObj.nxtReclID;  // Causes change in reclaim component
        this.changeIcnObj = undefined;
      }
    }
  }

  onKeyup_escape(event) { // Looking for Escape to exit modal - not using keyboard: true option
    event.stopPropagation();
    if (event.key === 'Escape') {
      this.closeReclaimsModal();
    }
  }

  setNewRecordNo(event: any): void {
    // To keep track of changes in rec no - office - yr if changed in CaseRecord Modal - NOT USED here
  }

  toggleSpinner(event: boolean) {
    // To deal with, NG0100: Expression has changed after it was checked
    // From https://v17.angular.io/errors/NG0100
    setTimeout(() => {
      this.spinner = event;
    }, 0);
  }
}
