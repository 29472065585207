import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'recl-xml-medicare-bretermination',
    templateUrl: './recl-xml-medicare-bretermination.component.html',
    styleUrls: ['./recl-xml-medicare-bretermination.component.css'],
    standalone: false
})
export class ReclXmlMedicareBReterminationComponent implements OnInit {

  @Input() reclXml: string;
  @Input() pCode: string;
  @Input() dxCode: string;
  @Input() mods: string;
  @Input() qty: string;
  @Input() usual: string;
  @Input() patZip: string;
  @Input() npi: string;

  @Output() need2Save = new EventEmitter<string>();

  ngOnInit(): void {

  }

  clearRadios(): void { // Clears all radio bittons.
    let opts = document.querySelectorAll('input[name="grpRadios"]');
    opts.forEach(e => {
      e['checked'] = false;
    });
    this.onChange_need2Save();
  }

  onChange_need2Save(): void {
    this.need2Save.emit('');
  }

  onClick_pcode(): void {
    let txtBx = <HTMLInputElement>document.querySelector('#txt_1');
    if (!txtBx.value && this.pCode) {
      txtBx.value = this.pCode;
      this.onChange_need2Save();
    }
  }

  onClick_mods(): void {
    let txtBx = <HTMLInputElement>document.querySelector('#txt_3');
    if (!txtBx.value && this.mods) {
      txtBx.value = this.mods;
      this.onChange_need2Save();
    }
  }

  onClick_npi(): void {
    let txtBx = <HTMLInputElement>document.querySelector('#txt_5');
    if (!txtBx.value && this.npi) {
      txtBx.value = this.npi;
      this.onChange_need2Save();
    }
  }

  onClick_qty(): void {
    let txtBx = <HTMLInputElement>document.querySelector('#med_1');
    if (!txtBx.value && this.qty) {
      txtBx.value = this.qty;
      this.onChange_need2Save();
    }
  }

  onClick_usual(): void {
    let txtBx = <HTMLInputElement>document.querySelector('#txt_8');
    if (!txtBx.value && this.usual) {
      txtBx.value = this.usual.match(/^\d{0,3}[,]?\d{0,3}[,]?\d{0,3}\.\d{2}/g)[0];
      this.onChange_need2Save();
    }
  }

  onClick_patZip(): void {
    let txtBx = <HTMLInputElement>document.querySelector('#med_5');
    if (!txtBx.value && this.patZip) {
      txtBx.value = this.patZip;
      this.onChange_need2Save();
    }
  }

}
