import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DataMessageService } from '../data-message.service';
import { ISite } from '../interfaces/site';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subscription, take } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Json2Pipe } from '../json2.pipe';
import { CommonModule } from '@angular/common';
import { IQ837 } from '../interfaces/q837';
import { X12UtilsService } from '../x12-utils.service';
import { WebsocketService } from '../websocket.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalService } from '../modal.service';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { ILocalProviders } from '../interfaces/localProviders';
import { ILocalInsurances } from '../interfaces/localInsurances';
import { QparamsComponent } from '../qparams/qparams.component';
import moment from 'moment';
import { HelperRtnsComponent } from '../helper-rtns.component';
import { RecordService } from '../../record/record.service';
import { SubmitterComponent } from '../submitter/submitter.component';
import { ILocalSubmitters } from '../interfaces/submitter';
import { AppToastsService } from '../app-toasts/app-toasts.service';
import { ILocalFormConfig } from '../interfaces/localFormConfig';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-q837',
  templateUrl: './q837.component.html',
  styleUrls: ['./q837.component.css'],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatPaginatorModule,
    QparamsComponent,
    SubmitterComponent,
    Json2Pipe
  ]
})
export class Q837Component {
  @Input() sn: string;
  @Input() engLang: boolean;
  @Input() userID: string;
  @Input() currCasID: string; // To distinguish current case on screen if applicable
  @Input() changeQ837: boolean;  // A boolean just to trigger ngOnChanges(...) as needed
  @Input() localProviders: ILocalProviders[];
  @Input() localInsurances: ILocalInsurances[];
  @Input() pgIndex: number;
  @Input() pgSize: number;
  @Output() changeQ837ToggelEvEm: EventEmitter<boolean> = new EventEmitter();
  @Output() setS837cnts: EventEmitter<any> = new EventEmitter();
  @Output() showSubmitterClick: EventEmitter<boolean> = new EventEmitter();
  @Output() openRecordCaseClick: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('q837paginator') q837paginator: MatPaginator;

  sioSubscrpt: Subscription;
  spinner: boolean = false; // Turns on spinner while io is active
  s837errCnt: number = 0;
  s837ErrArr: string[];
  qClms: IQ837[];
  delm: string = '>~>';
  patID: string;
  casID: string;
  dataSourceQ837: MatTableDataSource<IQ837, MatPaginator>;
  displydCols: string[] = [
    'chk',
    'open',
    'patLst',
    'patFst',
    'casDt',
    'casContr',
    'casNo',
    'usual',
    'xpect',
    'prov',
    'plan',
    'ps',
    'stat',
    'delete'
  ];
  tot = {
    'cked': '',
    'usual': '0',
    'xpect': '0',
    'errs': '0'
  }
  showSubmitter: boolean;
  subProv: string = '';  // Only used as input to submitter component
  subIns: string = '';
  sub: ILocalSubmitters;
  showQparams: boolean;

  site: ISite = {
    pk: undefined,
    nm: undefined,
    ad1: undefined,
    ad2: undefined,
    ct: undefined,
    st: undefined,
    zp: undefined,
    tl1: undefined,
    xt1: undefined,
    tl2: undefined,
    xt2: undefined,
    em1: undefined,
    em2: undefined
  }

  clms: IQ837[] = [];
  lastUsedParam: IQ837LastUsedParams = {
    fromDt: null,
    toDt: null,
    provs: '',
    inses: '',
    ckSubDt: '',
    subDt: null
  }

  provs: IProvs[] = []; // Abridged copy for selects elsewhere
  inses: IInses[] = [];  // Abridged copy for selects elsewhere

  deleCasID: string;
  delePS: string;
  showInitPg: boolean = true; // To show the page where the current case is on initially if it is in this.qClms array

  constructor(
    private _dmsg: DataMessageService,
    private _x12Service: X12UtilsService,
    private _websocketService: WebsocketService,
    private _modalService: ModalService,
    private _help: HelperRtnsComponent,
    private _recordService: RecordService,
    private _toastService: AppToastsService
  ) { }

  ngOnInit(): void {
    this._dmsg.currentSiteStr.subscribe(siteStr => this.siteChange(siteStr)); // Subscription looking for changes in siteName
    this._dmsg.currentSiteAd1Str.subscribe(siteAd1Str => this.siteAd1Change(siteAd1Str)); // Subscription looking for changes in siteAd1
    this._dmsg.currentSiteAd2Str.subscribe(siteAd2Str => this.siteAd2Change(siteAd2Str)); // Subscription looking for changes in siteAd2
    this._dmsg.currentSiteCtStr.subscribe(siteCtStr => this.siteCtChange(siteCtStr)); // Subscription looking for changes in siteCt
    this._dmsg.currentSiteStStr.subscribe(siteStStr => this.siteStChange(siteStStr)); // Subscription looking for changes in siteSt
    this._dmsg.currentSiteZpStr.subscribe(siteZpStr => this.siteZpChange(siteZpStr)); // Subscription looking for changes in siteZp
    this._dmsg.currentSiteTl1Str.subscribe(siteTl1Str => this.siteTl1Change(siteTl1Str)); // Subscription looking for changes in siteTl1
    this._dmsg.currentSiteXt1Str.subscribe(siteXt1Str => this.siteXt1Change(siteXt1Str)); // Subscription looking for changes in siteXt1
    this._dmsg.currentSiteTl2Str.subscribe(siteTl2Str => this.siteTl2Change(siteTl2Str)); // Subscription looking for changes in siteTl2
    this._dmsg.currentSiteXt2Str.subscribe(siteXt2Str => this.siteXt2Change(siteXt2Str)); // Subscription looking for changes in siteXt2

    this.sioSubscrpt = this._websocketService.getMessages().subscribe((dataSet) => { // Sets listenning events

      console.log('%c' + 'dataSet Q837Component', 'color: green; background: yellow; font-size: 14px');
      console.log(dataSet[0]?.sqlProcNm, dataSet);

      if (dataSet[0]?.sqlProcNm === 'spMB_Sio_GetQ837') {
        // Refresh recently created Q837
        // this._recordService.localQ837Arr(dataSet); <-- Being done in app.component
        this.qClms = this._recordService.localQ837;
        this.spinner = false;

      } else if (dataSet[0]?.sqlProcNm === 'spMB_Sio_Q837ChkUnchk') {
        const clm = this.qClms.find(c => +c.pKey === +dataSet[0].pKey);
        if (clm) {
          if (+dataSet[0].chk) {
            this._recordService.s837ckedCnt = (+this._recordService.s837ckedCnt + 1).toString();
            clm.chk = '1';
          } else {
            this._recordService.s837ckedCnt = (+this._recordService.s837ckedCnt - 1).toString();
            clm.chk = '0';
          }
        }
        this.spinner = false;

      } else if (dataSet[0]?.sqlProcNm === 'spMB_Sio_DeleteFromQ837') {
        if (+dataSet[0].rowCnt) {
          if (dataSet[0].casID === '-1') {
            const newArr = this.qClms.filter(c => { return c.stat });
            this._recordService.localQ837Arr(newArr);
            this.qClms = this._recordService.localQ837;
          } else {
            if (+dataSet[0].rowCnt === 1) {
              let i = this.qClms.findIndex(ci => +ci.casID === +dataSet[0].casID && +ci.ps === +dataSet[0].ps);
              this.qClms.splice(i, 1);
              this._recordService.localQ837Arr(this.qClms); // Updates totals
              this.qClms = this._recordService.localQ837;
            }
          }
          this.prepareDataSource();
        }
        this.spinner = false;

      } else if (dataSet[0]?.sqlProcNm === 'spMB_Sio_CreateQ837') {
        const tdtMom = moment(new Date);
        let q: string = '';
        dataSet.forEach(d => {
          q += "Exec spMB_Sio_Save2Q837 @sn = '" + this.sn
            + "', @casID = '" + d.casID
            + "', @casDt = '" + d.casDt
            + "', @patID = '" + d.patID
            + "', @ps = '" + (d.cps === '1' ? 'P' : 'S')
            + "', @txDt = '" + tdtMom.format('YYYY-MM-DD HH:mm:ss')
            + "', @uID = '" + this.userID
            + "', @s837 = '" + this._help.escApos(d.clm837)
            + "', @sErrs = ''"
            + ";";
        });

        // Do not change the order of these queries
        q += 'Exec spMB_Sio_GetQ837 @sn = "' + this.sn + '", @uID = "' + this.userID + '";';
        q += "Exec spMB_Sio_SaveQ837LastUsedParams @sn = '" + this.sn
          + "', @uID = '" + this.userID
          + "', @q837fromDt = '" + moment(this.lastUsedParam.fromDt, 'YYYY-MM-DD').format('YYYY-MM-DD')
          + "', @q837toDt = '" + moment(this.lastUsedParam.toDt, 'YYYY-MM-DD').format('YYYY-MM-DD')
          + "', @q837provs = '" + (this.lastUsedParam.provs ? this.lastUsedParam.provs : '')
          + "', @q837inses = '" + (this.lastUsedParam.inses ? this.lastUsedParam.inses : '')
          + "', @q837ckSubDt = '" + (+this.lastUsedParam.ckSubDt ? '1' : '0')
          + "', @q837subDt = '" + (moment(this.lastUsedParam.subDt, 'YYYY-MM-DD').isValid() ? moment(this.lastUsedParam.subDt).format('YYYY-MM-DD') : 'null')
          + "';";
        console.log('%c' + 'q @ ngOnInit():' + q, 'color: black; background: #90EE90; font-size: 12px');
        this._websocketService.sendChat('query', this.sn, q);

      } else if (dataSet[0]?.sqlProcNm === 'spMB_Sio_GetQ837LastUsedParams') {
        if (+dataSet[0].pKey) {
          this.lastUsedParam.fromDt = moment(dataSet[0].q837fromDt, 'MM/DD/YYYY').isValid() ? moment(dataSet[0].q837fromDt, 'MM/DD/YYYY').format('YYYY-MM-DD') : null;
          this.lastUsedParam.toDt = moment(dataSet[0].q837toDt, 'MM/DD/YYYY').isValid() ? moment(dataSet[0].q837toDt, 'MM/DD/YYYY').format('YYYY-MM-DD') : null;
          this.lastUsedParam.provs = dataSet[0].q837provs;
          this.lastUsedParam.inses = dataSet[0].q837inses;
          this.lastUsedParam.ckSubDt = (+dataSet[0].q837ckSubDt).toString();
          this.lastUsedParam.subDt = moment(dataSet[0].q837subDt, 'MM/DD/YYYY').isValid() ? moment(dataSet[0].q837subDt, 'MM/DD/YYYY').format('YYYY-MM-DD') : null;
        }

      } else if (dataSet[0]?.sqlProcNm === 'spMB_Sio_SaveSubmitter') {
        if (+dataSet[0].SubID) {
          const sub = { ...dataSet[0] }
          const n: number = this._recordService.localConfig.findIndex(fc => fc.provID === sub.provID && fc.insID === sub.insID);
          if (n > -1) {
            this._recordService.localConfig[n].subID = sub.SubID;  // Update locally cached values
          } else {
            const fc: ILocalFormConfig = { // Temp obj for now
              'forID': null,
              'provID': dataSet[0].provID,
              'insID': dataSet[0].insID,
              'name': null,
              'pin': null,
              'clia': null,
              'mamoCert': null,
              'x2': null,
              'npi': null,
              'ssnId': null,
              'einId': null,
              'add1': null,
              'add2': null,
              'city': null,
              'st': null,
              'zip': null,
              'acode': null,
              'tel': null,
              'omitNm182': null,
              'altNpi': null,
              'subID': dataSet[0].SubID
            }
            this._recordService.localConfig.push(fc);
          }

          delete sub.provID;  // Extra properties not in ILocalSubmitters
          delete sub.insID;
          delete sub.action;

          const i: number = this._recordService.localSubmitters.findIndex(s => s.SubID === dataSet[0].SubID);
          if (i < 0) {  // Not found so insert
            this._recordService.localSubmitters.push(sub);
          } else {
            this._recordService.localSubmitters[i] = sub;
          }
        }

      } else if (dataSet[0]?.sqlProcNm === 'spMB_Sio_Save2Q837') {


      }

      this.changeQ837ToggelEvEm.emit(true);
    });

    this.spinner = true;
    const q = 'Exec spMB_Sio_GetQ837LastUsedParams @sn = "' + this.sn + '", @uID = "' + this.userID + '";Exec spMB_Sio_GetQ837 @sn = "' + this.sn + '", @uID = "' + this.userID + '";';
    console.log('%c' + 'q @ ngOnInit():' + q, 'color: black; background: #90EE90; font-size: 12px');
    this._websocketService.sendChat('query', this.sn, q);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.qClms && changes.changeQ837) {
      console.log('changes.qClms', this.qClms);
      this.prepareDataSource()
    }
  }

  ngOnDestroy() {
    this.sioSubscrpt.unsubscribe();
    this.qClms.forEach(c => c.expandCol = '');
    this.provs = undefined;
    this.inses = undefined;
  }

  prepareDataSource() {
    let arr: IQ837[] = this.qClms.slice();
    const regex = new RegExp(this.delm + '$', "g");
    let tusual: number = 0;
    let txpect: number = 0;
    let tcked: number = 0;
    let terrs: number = 0;
    arr.forEach(e => {  // Prepare errors for display & totals
      e['errArr'] = e.sErrs.replace(regex, '').split(this.delm);
      if (e.sErrs) {
        terrs += 1;
      }
      tusual += +e.usual;
      txpect += +e.xpect;
      tcked += +e.chk;
    });
    this.tot.usual = tusual.toFixed(2);
    this.tot.xpect = txpect.toFixed(2);
    this.tot.cked = tcked.toString();
    this.tot.errs = terrs.toString();

    if (this.showInitPg && +this.currCasID >= 0) { // See if the case opened if any is in this.qClms array
      const currIndx = arr.findIndex(i => +i.casID === +this.currCasID);
      if (currIndx > -1) {  // If so always how the page it is on first
        this.pgIndex = Math.floor(currIndx / (this.q837paginator?.pageSize ?? 10));
        this.showInitPg = false; // Otherwise it will keep scrolling to the same claim & stuck to the same page index
      }
    }

    if (!Number.isInteger(this.pgIndex)) {
      this.pgIndex = this.q837paginator?.pageIndex ?? 0;
    } else {
      this.q837paginator.pageIndex = this.pgIndex;
    }
    if (!Number.isInteger(this.pgSize)) {
      this.pgSize = this.q837paginator?.pageSize ?? 10;
    } else {
      this.q837paginator.pageSize = this.pgSize;
    }
    const i1: number = this.pgIndex * this.pgSize;
    const i2: number = i1 + this.pgSize;

    this.dataSourceQ837 = new MatTableDataSource(arr.slice(i1, i2));
    this.dataSourceQ837.sort = this.sort; // Always follow this.dataSourceQ837 = ... with this to maintain header sort arrow synced
  }

  onPageChange_q837(event: PageEvent) {
    this.pgIndex = event.pageIndex;
    this.pgSize = event.pageSize;
    // if (this.pgIndex * this.pgSize > event.length) {
    //   this.pgSize = event.length;
    // }
    this.prepareDataSource();
  }

  siteChange(siteStr: string) { // for when site changes
    this.site.nm = siteStr;
  }

  siteAd1Change(siteAd1Str: string) { // for when site changes
    this.site.ad1 = siteAd1Str;
  }

  siteAd2Change(siteAd2Str: string) { // for when site changes
    this.site.ad2 = siteAd2Str;
  }

  siteCtChange(siteCtStr: string) { // for when site changes
    this.site.ct = siteCtStr;
  }

  siteStChange(siteStStr: string) { // for when site changes
    this.site.st = siteStStr;
  }

  siteZpChange(siteZpStr: string) { // for when site changes
    this.site.zp = siteZpStr;
  }

  siteTl1Change(siteTl1Str: string) { // for when site changes
    this.site.tl1 = siteTl1Str;
  }

  siteXt1Change(siteXt1Str: string) { // for when site changes
    this.site.xt1 = siteXt1Str;
  }

  siteTl2Change(siteTl2Str: string) { // for when site changes
    this.site.tl2 = siteTl2Str;
  }

  siteXt2Change(siteXt2Str: string) { // for when site changes
    this.site.xt2 = siteXt2Str;
  }

  siteEm1Change(siteEm1Str: string) { // for when site changes
    this.site.em1 = siteEm1Str;
  }

  sortea(sort) {

  }

  onClick_expandErrs(clm: IQ837) {
    if (clm.sErrs) {
      if (!clm.expandCol) {
        clm.expandCol = 'true';
      } else {
        clm.expandCol = '';
      }
    } else if (clm.s837) {
      if (!clm.expandCol) {
        clm.expandCol = 'true';
      } else {
        clm.expandCol = '';
      }
    }
  }

  onClick_delete(clm) {
    if (clm) {
      this.deleCasID = clm.casID;
      this.delePS = clm.ps;
    }
    const prompt = this.engLang ? (clm ? 'Delete claim from queue ?' : 'Delete entire queue ?') : (clm ? '¿ Eliminar caso de la fila ?' : '¿ Eliminar toda la fila ?');
    const title = this.engLang ? 'Confirm' : 'Confirme';
    const btn_primary = true;
    const btn_default = true;
    let ok: string = this.engLang ? 'Yes' : 'Sí';
    let cancel = 'No';

    this._modalService.confirm(prompt, title, btn_primary, btn_default, ok, cancel)
      .pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(response => {
        if (response) {
          this.spinner = true;
          const cid = clm ? clm.casID : '-1';
          const ps = clm ? clm.ps : '';
          this._x12Service.delete837(this.sn, ps, cid, this.userID);
        } else {
          // Nada
        }
        this.deleCasID = undefined;
        this.delePS = undefined;
      });
  }

  onChange_chk(event, clm) {
    if (clm.sErrs === '') {
      this._x12Service.chkUnchk837(this.sn, clm.pKey);
      this.spinner = true;
    } else {
      event.source.checked = !event.checked;  // Revert chkbox state
      event.stopPropagation();
      event.preventDefault();
    }
  }

  onClick_showSubmitter() {
    this.subProv = '';
    this.subIns = '';
    this.showSubmitter = true;
  }

  onClick_showQparams() { // Get parameters for 837 file creation
    this.provs = this.localProviders.map(p => ({ pKey: p.pKey, alias: p.alias }));
    this.provs.unshift({
      pKey: '0',
      alias: '(All/Todos)'
    });
    this.provs.push({
      pKey: '-1',
      alias: '(Clear)'
    });

    this.inses = this.localInsurances.map(i => ({ pKey: i.pKey, alias: i.alias }));
    const idx = this.inses.findIndex(i => +i.pKey === 0);
    this.inses.splice(idx, 1);
    this.inses.unshift({
      pKey: '0',
      alias: '(All/Todos)'
    });
    this.inses.push({
      pKey: '-1',
      alias: '(Clear)'
    });
    this.showQparams = true;
  }

  open(content, opts: any, modalNm: string) {
    this._modalService.open(content, opts)
      .pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(response => {
        if (response) {
          console.log(response);
          return true;
        } else {
          return false;
        }
      });
  }

  closeQparamsDlg(event) {
    console.log('event', event);
    if (event) {
      moment(event.form.fromDt).isValid() ? this.lastUsedParam.fromDt = moment(event.form.fromDt).format('YYYY-MM-DD') : '';
      moment(event.form.toDt).isValid() ? this.lastUsedParam.toDt = moment(event.form.toDt).format('YYYY-MM-DD') : '';
      this.lastUsedParam.provs = event.form.provsSelectCtrl.join(',');
      this.lastUsedParam.inses = event.form.insesSelectCtrl.join(',');
      +event.form.ckSubDt ? this.lastUsedParam.ckSubDt = '1' : this.lastUsedParam.ckSubDt = '0';

      if (moment(event.form.toDt).isValid()) {
        this.lastUsedParam.subDt = moment(event.form.subDt).format('YYYY-MM-DD');
      } else {
        this.lastUsedParam.subDt = null;
      }
      this.spinner = true;
    }
    this.showQparams = false; // Hides the dialog
  }

  closeSubmitterDlg(event) {
    if (event) {
      if (event.action === 'save') {
        this._x12Service.saveSubmitter(this.sn, event.form);
      }
    }
    this.showSubmitter = false;
  }

  onClick_transmit837(save2disk: boolean) {
    const prompt = save2disk ? (this.engLang ? 'Save 837 file to local system ?' : '¿ Grabar archivo 837 al sistema local ?') : (this.engLang ? 'Transmit to Clearing House(CH) or Insurance ?' : '¿ Transmitir al Clearing House(CH) o Seguro ?');
    const title = this.engLang ? 'Confirm' : 'Confirme';
    const btn_primary = true;
    const btn_default = true;
    let ok: string = this.engLang ? 'Yes' : 'Sí';
    let cancel = 'No';

    this._modalService.confirm(prompt, title, btn_primary, btn_default, ok, cancel)
      .pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(response => {
        if (response) {
          this.tx837(save2disk);
        } else {
          // Nada
        }
      });
  }

  tx837(save2disk: boolean): void {
    const isaTestFlag = environment.isaTestFlag; // Make sure it is P for Production
    let processed: { casIdPs: string, filNm: string, txStat: string, filNo: string }[] = []; // Holds processed claims
    let rxStat: { message: string, errCnt: string, filNo: string }[] = [];  // To keep track of all statuses received with first file on the bottom & most recent on top - assumes they will com in order of transmitted.
    let rxStatFilCnt: number = 0;
    let provID: string = undefined;
    let prov: string;
    let insID: string = undefined;
    let ins: string;
    let isa13: string;
    let sub: ILocalSubmitters;
    let filCnt: number = 0;
    let casCnt: number = 0;
    let tdtMom: any;
    let tdt: string;
    let filNm: string = '';
    let filNmToasted: string[] = []; // Use to filter c.filNm displayed in toasts and not repeat

    for (let i = 0; i < this._recordService.localQ837.length; i++) {  // Outer loop
      let x12Data: string = ''; // File data
      let firstClmInBatch: boolean = true; // A batch is a particular provID/insID combination
      let hlCnt: number = 2;  // Increments for each claim
      provID = undefined; // New batch when undefined
      insID = undefined;
      this.subProv = '';  // Only used as input to submitter component
      this.subIns = '';

      for (let c = 0; c < this._recordService.localQ837.length; c++) {  // Inner loop
        let clm: IQ837 = this._recordService.localQ837[c];  // Claim to process
        if (+clm.chk) { // Do only checked items
          if (!processed.length || !processed.some(e => e.casIdPs === clm.casID + clm.ps)) {  // Initiallly empty or claim never processed
            if (!provID) {  // New batch to prepare
              provID = clm.provID;
              prov = clm.prov;
              this.subProv = `(${provID}) ${prov}`;
              insID = clm.insID;
              ins = clm.plan;
              this.subIns = `(${insID}) ${ins}`;
            }

            if (clm.provID === provID && clm.insID === insID) { // Current batch

              if (firstClmInBatch) {
                firstClmInBatch = false;  // So as not to repeat ISA,GS,...
                const conf: ILocalFormConfig = this._recordService.localConfig.find(fc => fc.provID === clm.provID && fc.insID === clm.insID);
                if (conf && +conf.subID) {  // If a submitter record has already been assigned...
                  sub = this._recordService.localSubmitters.find(s => s.SubID === conf.subID);  // Fins submitter
                  if (sub && sub.SubIDrem && sub.SubIsa07 && sub.SubIsa08 && sub.SubGs02 && sub.SubGs03) {  // Is submitter minimum complete ?
                    const momDt = moment(new Date());
                    isa13 = (momDt.year() % 10).toString() + moment(momDt).format('DDHHmmss'); // ISA13 - Interchange Control Number - A unique control number for this interchange, which should match the IEA02 control number in the IEA segment (Interchange Control Trailer).
                    x12Data += this._x12Service.isaGsEnvelope(sub, momDt, isa13, clm.plan, clm.insPayID, '837P', isaTestFlag);
                    const prov: ILocalProviders = this._recordService.localProviders.find(p => p.pKey === provID);
                    x12Data += this._x12Service.billingProvider(prov, this.engLang)
                  } else {  // Prompt for submitter/clearing house account
                    this.sub = sub;
                    this.showSubmitter = true;
                    return;
                  }
                } else {  // Prompt for submitter/clearing house account
                  this.showSubmitter = true;
                  return;
                }

                tdtMom = moment(new Date);  // Today's date as moment object
              }
              x12Data += `\nHL*${hlCnt}*1*22*0~`
              hlCnt += 1;
              x12Data += clm.s837;

              tdt = tdtMom.format('(M-D h:mm:ss)');
              filNm = '';
              if (isaTestFlag === 'T') {
                filNm = 'TEST-MedicalBiller-test '
              }
              filNm += '(' + provID + ')' + prov.substring(0, 15) + ' - ' + '(' + insID + ')' + ins.substring(0, 15) + tdt + '.txt'

              processed.push({ casIdPs: clm.casID + clm.ps, filNm: filNm, txStat: clm.status.txMsg, filNo: filCnt.toString() });  // Preserve claim as processed
              casCnt += 1;
              this._recordService.s837ckedCnt = (+this._recordService.s837ckedCnt - 1).toString();  // Decrement total cked claims since the check goes off when processed
            }
          }
        }
      } // Next claim

      if (x12Data) {  // If there is file data ?

        const segCnt: number = x12Data.match(/~/g).length - 1;
        x12Data += `\nSE*${segCnt}*${isa13}~`
          + '\nGE*1*1~'
          + `\nIEA*1*${isa13}~`;

        // File 837 is ready for txmission
        filCnt += 1;
        console.log('x12_File_Data', x12Data);

        if (save2disk) {
          const blob = new Blob([x12Data], { type: 'text/plain' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filNm;
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          this._x12Service.sendInmeX837(this.sn, sub.SubUser, sub.SubPw, filNm, x12Data)
            .subscribe({
              next: (data: any) => {
                console.log('response-from-clearinghouse', data);
                let qry: string = '';
                let via = '?';
                if (data.includes('inmediata.com')) {
                  via = 'Inmediata';
                  const parser = new DOMParser();
                  const xmlDoc = parser.parseFromString(data, "text/xml");
                  console.log('xmlDoc-from-clearinghouse', xmlDoc);
                  const errCnt: string = this.extractXmlNodeTxtContent(xmlDoc, 'ErrorCount');
                  console.log('errCnt-in-response-from-clearinghouse', errCnt);
                  const message: string = this.extractXmlNodeTxtContent(xmlDoc, 'Message');
                  console.log('message-from-clearinghouse', message);
                  rxStat.push({ message: message, errCnt: errCnt, filNo: rxStatFilCnt.toString() });
                  rxStatFilCnt++;

                  console.log('rxStat', rxStat);
                  console.log('rxStatFilCnt', rxStatFilCnt);
                  console.log('processed', processed);

                  processed.forEach(clmSnt => {
                    let sentClm: IQ837 = this._recordService.localQ837.find(q => q.casID + q.ps === clmSnt.casIdPs);
                    if (sentClm) {
                      sentClm.chk = '0';  // Unchk selected to send ckbox
                      const i = rxStat.findIndex(rx => rx.filNo === processed.find(p => p.casIdPs === (sentClm.casID + sentClm.ps)).filNo);
                      if (i > -1) { // Update status
                        sentClm.status.txOk = +rxStat[i].errCnt === 0 ? '1' : '-1';
                        sentClm.status.txMsg = `${rxStat[i].message}`;
                      }

                      qry += "Exec spMB_Sio_Save2Q837 @sn = '" + this.sn
                        + "', @casID = '" + clmSnt.casIdPs.substring(0, clmSnt.casIdPs.length - 1)
                        + "', @ps = '" + clmSnt.casIdPs.substring(clmSnt.casIdPs.length - 1, clmSnt.casIdPs.length)
                        + "', @stat = '" + JSON.stringify(sentClm.status)
                        + "', @txDt = '" + tdtMom.format('YYYY-MM-DD HH:mm:ss')
                        + "', @filNm = '" + clmSnt.filNm
                        + "', @uID = '" + this.userID
                        + "';"

                      if (!filNmToasted.includes(clmSnt.filNm)) {  // To not repeat toast with same filNm
                        this._toastService.updateDeadCenter(true);
                        const hdr = errCnt ? `${errCnt} Errors` : 'Ok';
                        if (+errCnt) {
                          this._toastService.show(
                            `${clmSnt.filNm}\n${sentClm.status.txMsg}`,
                            {
                              header: (
                                hdr
                              ), autohide: false, error: true
                            }
                          );
                        } else {
                          this._toastService.show(
                            (clmSnt.filNm),
                            {
                              header: (
                                hdr
                              ), autohide: false, success: true
                            }
                          );
                        }
                        filNmToasted.push(clmSnt.filNm);
                      }
                    }
                  });
                  // Maybe other clearinghouses here!
                }

                if (qry) {
                  console.log('%c' + 'q @ tx837:' + qry, 'color: black; background: #90EE90; font-size: 12px');
                  this._websocketService.sendChat('query', this.sn, qry);
                }
              },
              error: (err: any) => {
                this._toastService.updateDeadCenter(true);
                this._toastService.show(
                  (this.engLang ? "x837 File response to request not received."
                    : 'No se obtuvo respuesta al someter archivo x837.'),
                  {
                    header: (
                      err.displayMsg
                    ), autohide: true, error: true
                  }
                );
              }
            });
        }
        this.saveX12toX12Register(filNm, isa13, x12Data, tdtMom);
      }
    } // Look for next file

    let msg: string;
    if (filCnt) {
      msg = this.engLang ? `${filCnt} Files transmitted, ${casCnt} claims.` : `${filCnt} Archivos transmitido, ${casCnt} casos.`;
    } else {
      msg = this.engLang ? 'Nothing transmitted.' : 'Nada transmitido.';
    }
    this._toastService.show(
      (msg),
      {
        header: (
          this.engLang ? 'Finished!' : '¡Terminado!'
        ), autohide: false, warning: true
      }
    );
  }

  saveX12toX12Register(filNm: string, isa13: string, x12Data: string, tdtMom: any) {
    const q = "Exec spMB_Sio_SaveX12Register @sn = '" + this.sn
      + "', @fileType = '837P"
      + "', @fileDt = '" + tdtMom.format('YYYY-MM-DD HH:mm:ss')
      + "', @fileNm = '" + filNm
      + "', @iSA13_ControlNo = '" + isa13
      + "', @iSA10 = '" + x12Data.substring(77, 81)
      + "', @iSA09 = '" + x12Data.substring(70, 76)
      + "',@fileContent = '" + this._help.escApos(x12Data) + "';"
    console.log('%c' + 'q @ tx837:' + q, 'color: black; background: #90EE90; font-size: 12px');
    this._websocketService.sendChat('query', this.sn, q);
  }

  extractXmlNodeTxtContent(node, name): string {
    let textContent = '';
    if (node.childNodes && node.childNodes.length > 0) {
      for (let child of node.childNodes) {
        if (child.tagName === name) {
          textContent = child.textContent.trim();
          return textContent;
        }
      }
      for (let child of node.childNodes) {
        textContent = this.extractXmlNodeTxtContent(child, name);
        if (textContent) {
          return textContent;
        }
      }
    }
  }

  onClick_openRecordCase(clm: IQ837) {  // Load into record component for editing
    const event: any = { patID: clm.patID, casID: clm.casID, ps: clm.ps, pgIndex: this.pgIndex, pgSize: this.pgSize };
    this.openRecordCaseClick.emit(event);
  }

}
