import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IReclaims } from 'src/app/shared/interfaces/reclaims';

@Component({
    selector: 'app-recl-medicare-part-b-redetermination-fcso',
    templateUrl: './recl-medicare-part-b-redetermination-fcso.component.html',
    styleUrls: ['./recl-medicare-part-b-redetermination-fcso.component.css'],
    standalone: false
})
export class ReclMedicarePartBRedeterminationFcsoComponent {

  @Input() reclFormImg64Seled: string;
  @Input() reclIdxSeld: number;
  @Input() reclaims: IReclaims[];

  @Output() need2Save = new EventEmitter<string>();

  chk_1: string;
  chk_2: string;
  chk_3: string;
  chk_4: string;
  chk_5: string;
  chk_6: string;
  chk_7: string;
  opt_1: string;
  opt_2: string;
  opt_3: string;
  opt_4: string;
  opt_5: string;
  txt_1: string;
  txt_2: string;
  txt_3: string;
  txt_4: string;
  txt_5: string;
  txt_6: string;
  med_1: string;
  med_2: string;
  txt_8: string;
  txt_9: string;
  med_5: string;
  med_6: string;
  txt_7: string;

}
