<!-- modal payments credited to claims per check .................................................... -->
<ng-template #ckRegClaimsPaymentDetailModal let-modal>
    <div class="modal-header" style="background-color: #E6E6FA;">
        <div class="d-inline-flex flex-column">
            <div>
                <h4 class="modal-title m-0">{{ engLang ? 'Payments Credited to Claims': 'Pagos Acreditados a Casos' }}
                </h4>
            </div>
            <div>
                <h5 class="m-0">Ck:&nbsp;{{ ckgNo }}</h5>
            </div>
            <div>
                <h6 class="m-0">{{ ckgDt }}</h6>
            </div>
        </div>
        <div style="margin-top: -40px;">
            <button type="button" class="btn btn-sm btn-close"
                (click)="modal.dismiss('oK_closePayDetailModal')"></button>
        </div>
    </div>
    <div *ngIf="ckgID" class="modal-body px-1 py-0">

        <div>

            <div *ngIf="waiting4Response" class="box">
                <img src="app/assets/images/waiting4Response.gif" width="42" height="42">
            </div>
            <div *ngIf="!waiting4Response" class="box" style="background-color: #9eaccc;">
                <span class="mx-0 px-0"><b>{{ engLang ? 'Claims(' + tCasCnt + ')/Procedures Payed': 'Casos(' + 
                        tCasCnt + ')/Procedimientos Pagados'
                        }}</b>&nbsp;{{ '(' + ckDetails.length + ')' }}&nbsp;{{ tPayAmnt | currency:'USD':'$':'1.2-2'
                    }}</span>
            </div>

            <div class="table-responsive">
                <table class="table table-bordered table-sm unselectable small" style="background-color: #9eaccc;">
                    <thead>
                        <tr>
                            <!-- <th style="display: none;">
                                CkDetID
                            </th> -->
                            <!-- <th style="display: none;">
                                CkDetCkgID
                            </th>
                            <th style="display: none;">
                                CkDetPayID
                            </th> -->
                            <th class="w125">
                                {{ engLang ? 'Patient': 'Paciente' }}
                            </th>
                            <th class="w30">
                                {{ engLang ? 'Contract(s)': 'Contrato(s)' }}
                            </th>
                            <th class="w30 text-center">
                                {{ engLang ? 'Claim': 'Caso' }}
                            </th>
                            <th class="w20 text-center">
                                {{ engLang ? 'PS': 'FP' }}
                            </th>
                            <th class="w70 text-right">
                                {{ engLang ? 'Payed': 'Pago' }}
                            </th>
                            <th class="w70 text-center">
                                {{ engLang ? 'Date': 'Fecha' }}
                            </th>
                            <th>
                                <!-- <i class="fa fa-trash" style="cursor: pointer;"
                                    title="{{ engLang ? 'Delete (only manual) payment': 'Eliminar pago (solo manuales)' }}"></i> -->
                            </th>
                            <th class="w70 text-center">
                                {{ 'Proced' }}
                            </th>
                            <th class="w20 text-center">
                                {{ 'Mod' }}
                            </th>
                            <th class="w70 text-center">
                                {{ engLang ? 'From': 'Desde' }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ckDetail of ckDetails 
                            | paginate: { id: 'ckDetsPag', itemsPerPage: 6, currentPage: currentPageCksDet }; 
                            let e = even; let o = odd; let d = index;" [ngClass]="{ evenCkDet : e, oddCkDet : o }">
                            <!-- <td style="display: none;">
                                {{ ckDetail.CkDetID }}
                            </td> -->
                            <!-- <td style="display: none;">
                                {{ ckDetail.CkDetCkgID }}
                            </td>
                            <td style="display: none;">
                                {{ ckDetail.CkDetPayID }}
                            </td> -->
                            <td>
                                {{ ckDetail.PatNm }}
                            </td>
                            <td class="w30">
                                <div>
                                    {{ ckDetail.CasCont1 }}
                                </div>
                                <div>
                                    {{ ckDetail.CasCont2 }}
                                </div>
                            </td>
                            <td class="w30">
                                {{ ckDetail.CasNo }}
                            </td>
                            <td class="w20 text-center">
                                {{ ckDetail.PayPS }}
                            </td>
                            <td class="w70 currency">
                                <strong>{{ ckDetail.PayAmnt }}</strong>
                            </td>
                            <td class="w70 text-center">
                                {{ ckDetail.PayDt }}
                            </td>
                            <td class="w20 text-center">
                                <!-- Don't allow deletion of 835 payments -->
                                <div *ngIf="ckDetail.allowDelete == '1'"
                                    (click)="onClick_promptDeletePayment(ckDetail.CkDetID);">
                                    <i class="fa fa-trash" style="cursor: pointer;"
                                        title="{{ engLang ? 'Delete payment': 'Eliminar pago' }}"></i>
                                </div>
                            </td>
                            <td class="w70 text-center">
                                {{ ckDetail.DetPcode }}
                            </td>
                            <td class="w20">
                                {{ ckDetail.DetMod }}
                            </td>
                            <td class="w70 text-center">
                                {{ ckDetail.FromDt }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </div>
    <div class="modal-footer small"
        style="background-color: #E6E6FA; justify-content: space-between; flex-wrap: nowrap;">
        <div>
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
        </div>
        <div class="pt-3" style="flex-shrink: 0;">
            <pagination-controls id="ckDetsPag" (pageChange)="currentPageCksDet = $event" directionLinks="true"
                previousLabel="" nextLabel="" autoHide="true">
            </pagination-controls>
        </div>
        <div>
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('oK_closePayDetailModal')">{{
                engLang ? 'Close': 'Cerrar' }}</button>
        </div>
    </div>

    <!-- <pre>{{ modalStack }}</pre> -->
    <!-- <pre>{{ modalStack.length }}</pre> -->

</ng-template>

<!-- modal case payment & adjustments prompt for delete form .......................................................................................................................... -->
<ng-template #ckRegClaimsPaymentDeleteModal let-modal>
    <div class="modal-header" style="background-color:  #fca796;">
        <div class="d-inline-block">
            <div>
                <h4 class="modal-title">
                    {{ engLang ? 'Payment and adjustments to be deleted': 'Pago y ajustes a ser eliminados' }}
                </h4>
            </div>
            <div>
                {{ engLang ? 'Claim No.: ': 'Caso No.: ' }}{{ ckDetails[seledCkDetIndx]?.CasNo }}
            </div>
            <div>
                {{ ckDetails[seledCkDetIndx]?.PatNm }}
            </div>
        </div>

        <button type="button" class="btn-close" (click)="modal.dismiss('x')" style="margin-top: -50px;"></button>
    </div>
    <div class="modal-body">

        <table>
            <thead>
                <tr>
                    <th class="px-3">
                        {{ engLang ? 'Date': 'Fecha' }}
                    </th>
                    <th class="px-3">
                        {{ engLang ? 'Amount': 'Cantidad' }}
                    </th>
                    <th class="px-3">
                        Desc
                    </th>
                    <th class="small px-3">
                        {{ engLang ? 'Mode': 'Modo' }}
                    </th>
                </tr>
            </thead>
            <tbody *ngFor="let payAdjDetail of payAdjDetails; let e=even; let o=odd; let i=index;">
                <tr>
                    <td class="px-3">
                        {{ payAdjDetail.dt }}
                    </td>
                    <td class="currency px-3">
                        {{ payAdjDetail.amnt | currency:'USD':'':'1.2-2' }}
                    </td>
                    <td class="px-3">
                        {{ payAdjDetail.desc }}
                    </td>
                    <td class="small px-3">
                        {{ payAdjDetail.mode }}
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
    <div class="modal-footer" style="background-color:  #fca796; display: flex; justify-content: space-between;">
        <div style="font-size: 70%;">
            {{ engLang ? 'Esc - Cancel': 'Esc - Cancela'}}
        </div>
        <div>
            <button type="button" class="btn btn-outline-dark"
                (click)="onClick_deletePayment(); modal.close('oK_closeModal')">Ok</button>
        </div>
    </div>
</ng-template>

<!-- <pre>{{ ckDetails | json }}</pre> -->
<!-- <pre>{{ payAdjDetails | json }}</pre> -->