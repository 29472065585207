import { ChangeDetectionStrategy, Component, EventEmitter, Inject, inject, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ValidatorFn } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ILocalSubmitters } from '../interfaces/submitter';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ILocalProviders } from '../interfaces/localProviders';
import { ILocalInsurances } from '../interfaces/localInsurances';
import { X12UtilsService } from '../x12-utils.service';
import { WebsocketService } from '../websocket.service';
import { Subscription } from 'rxjs';
import { Json2Pipe } from '../json2.pipe';
import { RecordService } from '../../record/record.service';

@Component({
  selector: 'app-submitter',
  imports: [
    CommonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitterComponent {
  @Input() sn: string;
  @Input() engLang: boolean;
  @Input() prov: string = '';
  @Input() ins: string = '';
  @Input() subs: ILocalSubmitters[];
  @Input() sub: ILocalSubmitters;
  @Input() provs: ILocalProviders[];
  @Input() inss: ILocalInsurances[];

  @Output() closeSubmitterDlg: EventEmitter<any> = new EventEmitter();

  readonly dialog = inject(MatDialog);

  sioSubscrpt: Subscription;

  constructor(
    private _websocketService: WebsocketService,
    private _recordService: RecordService,
  ) { }

  ngOnInit() {

    this.sioSubscrpt = this._websocketService.getMessages().subscribe((dataSet) => { // Sets listenning events

      console.log('%c' + 'dataSet SubmitterComponent', 'color: green; background: yellow; font-size: 14px');
      console.log(dataSet[0]?.sqlProcNm, dataSet);

      if (dataSet[0]?.sqlProcNm === 'spMB_Sio_DeleteSubmitter') {
        if (dataSet[0].action === 'unlink') {
          const frmConf = this._recordService.localConfig.filter(fc => fc.provID === dataSet[0].provID && fc.insID === dataSet[0].insID);
          frmConf.forEach(f => f.subID = null);
        } else if (+dataSet[0].SubID && +dataSet[0].rowsDeleted === 1) {
          const i: number = this._recordService.localSubmitters.findIndex(s => s.SubID === dataSet[0].SubID);
          if (i > -1) {
            this._recordService.localSubmitters.splice(i, 1);
          }
        }
      }
    });

    const dialogRef = this.dialog.open(SubmitterDialog, {
      data: {
        sn: this.sn,
        engLang: this.engLang,
        prov: this.prov,
        ins: this.ins,
        subs: this.subs.map(sub => {  // Check if provID & insID is '0' and update it to ''
          const updatedProvID = sub.provID === '0' ? '' : sub.provID;
          const updatedInsID = sub.insID === '0' ? '' : sub.insID;
          return { ...sub, provID: updatedProvID, insID: updatedInsID };
        }),
        sub: this.sub,
        provs: this.provs,
        inss: this.inss
      },
      disableClose: true,
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Submitter Dialog result: ${result}`);
      if (result) {
        // send json of submitter data in next statement as oposed to '' when closing
      }
      this.closeSubmitterDlg.emit(result);  // Return execution to parent component
    });

    // console.log('subs', this.subs);
  }

  ngOnDestroy() {
    this.sioSubscrpt.unsubscribe();
  }
}

export function isaIdQualifierValidator(control: AbstractControl): ValidationErrors | null {
  const validValues = ['01', '14', '20', '27', '28', '29', '30', '33', 'ZZ'];
  const value = control.value?.toUpperCase(); // Without .toUpperCase() a keyboard input of ZZ yields an error

  // Check if the value is in the validValues array
  if (validValues.includes(value)) {
    return null;  // Return null if the value is valid (no error)
  }

  return { invalidIsaQualifier: true, validValues: validValues.join(', ') };  // Return error if the value is not valid
}

export function optionalPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      // Allow null or empty values
      return null;
    }
    // Validate 10-digit phone numbers
    return /^\d{10}$/.test(value) ? null : { invalidPhone: true };
  };
}

@Component({
  selector: 'submitter-dialog',
  templateUrl: './submitter.dialog.html',
  styleUrls: ['./submitter.dialog.css'],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
    Json2Pipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitterDialog {

  subIndx: number = -1;  // Selected subs[] index
  provID: string = '';
  dProv: string = this.data.prov; // Local display prov
  insID: string = '';
  dIns: string = this.data.ins;  // Local display ins
  emptyForm: any = {
    "SubID": null,
    "SubVia": null,
    "SubLastNm": null,
    "SubFstNm": null,
    "SubIDrem": null,
    "SubIsa05": null,
    "SubIsa08": null,
    "SubIsa07": null,
    "SubGs02": null,
    "SubGs03": null,
    "SubContact": null,
    "SubContTel": null,
    "SubContFax": null,
    "SubContEmail": null,
    "SubUser": null,
    "SubPw": null,
    "provID": "",
    "insID": ""
  }

  form = this._fb.group({
    SubID: [this.data.sub?.SubID],
    SubVia: [this.data.sub?.SubVia, [
      Validators.required,
      Validators.maxLength(35)
    ]
    ],
    SubLastNm: [this.data.sub?.SubLastNm, [
      Validators.required,
      Validators.maxLength(35)]
    ],
    SubFstNm: [this.data.sub?.SubFstNm,
    [Validators.maxLength(25)]
    ],
    SubIDrem: [this.data.sub?.SubIDrem, [
      Validators.required,
      Validators.maxLength(15)]
    ],
    SubIsa05: [this.data.sub?.SubIsa05, [
      Validators.required,
      isaIdQualifierValidator]
    ],
    SubIsa08: [this.data.sub?.SubIsa08, [
      Validators.required,
      Validators.maxLength(15)]
    ],
    SubIsa07: [this.data.sub?.SubIsa07, [
      Validators.required,
      isaIdQualifierValidator]
    ],
    SubGs02: [this.data.sub?.SubGs02, [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(15)]
    ],
    SubGs03: [this.data.sub?.SubGs03, [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(15)]
    ],
    SubContact: [this.data.sub?.SubContact, [
      Validators.required,
      Validators.maxLength(34)]
    ],
    SubContTel: [this.data.sub?.SubContTel.trim(), [
      Validators.required,
      optionalPhoneValidator()]
    ],
    SubContFax: [this.data.sub?.SubContFax.trim(), [
      optionalPhoneValidator()]
    ],
    SubContEmail: [this.data.sub?.SubContEmail, [
      Validators.maxLength(50),
      Validators.email]
    ],
    SubUser: [this.data.sub?.SubUser,
    [Validators.maxLength(20)]
    ],
    SubPw: [this.data.sub?.SubPw,
    [Validators.maxLength(20)]
    ],
    provID: [''],
    insID: ['']
  }, { updateOn: 'blur' });

  get SubID() {
    return this.form.get('SubID');
  }

  get SubVia() {
    return this.form.get('SubVia');
  }

  get SubIsa05() {
    return this.form.get('SubIsa05');
  }

  get SubIsa07() {
    return this.form.get('SubIsa07');
  }

  get SubIsa08() {
    return this.form.get('SubIsa08');
  }

  get SubGs02() {
    return this.form.get('SubGs02');
  }

  get SubGs03() {
    return this.form.get('SubGs03');
  }

  get SubContTel() {
    return this.form.get('SubContTel');
  }

  get SubContFax() {
    return this.form.get('SubContFax');
  }

  get SubUser() {
    return this.form.get('SubUser');
  }

  get SubPw() {
    return this.form.get('SubPw');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:
      {
        sn: string,
        engLang: boolean,
        prov: string,
        ins: string,
        subs: ILocalSubmitters[],
        sub: ILocalSubmitters,
        provs: ILocalProviders[],
        inss: ILocalInsurances[]
      },
    private _fb: FormBuilder,
    private _dlgRef: MatDialogRef<SubmitterDialog>,
    private _x12Service: X12UtilsService
  ) {
    this.form.markAllAsTouched();  // Marks all controls as touched to show validation messages
    this.form.updateValueAndValidity(); // Ensures the form updates its status and validators
    this.setProvInsIDs(data?.sub?.SubID, 0);
  }

  onClick_save() {
    const result = { form: this.form.value, action: 'save' };
    this._dlgRef.close(result);
  }

  onClick_delete(action: string) {
    this._x12Service.deleteSubmitter(this.data.sn, this.form.value.SubID, action, this.provID, this.insID);
    this.onClick_new();
    if (action === 'unlink') {
      this._dlgRef.close(undefined);
    }
  }

  onClick_new() {
    this.form.setValue(this.emptyForm);
    this.dProv = '';
    this.dIns = '';
  }

  onChange_subID(event) {
    this.SubContTel.updateValueAndValidity(); // Needed or a false error may be displayed when changing data
    this.SubContFax.updateValueAndValidity();

    const i = this.data.subs.findIndex(e => e.SubID === event.value);
    this.form.controls.SubID.setValue(this.data.subs[i].SubID);
    this.form.controls.SubVia.setValue(this.data.subs[i].SubVia);
    this.form.controls.SubLastNm.setValue(this.data.subs[i].SubLastNm);
    this.form.controls.SubFstNm.setValue(this.data.subs[i].SubFstNm);
    this.form.controls.SubIsa05.setValue(this.data.subs[i].SubIsa05);
    this.form.controls.SubIDrem.setValue(this.data.subs[i].SubIDrem);
    this.form.controls.SubIsa07.setValue(this.data.subs[i].SubIsa07);
    this.form.controls.SubIsa08.setValue(this.data.subs[i].SubIsa08);
    this.form.controls.SubGs02.setValue(this.data.subs[i].SubGs02);
    this.form.controls.SubGs03.setValue(this.data.subs[i].SubGs03);
    this.form.controls.SubContact.setValue(this.data.subs[i].SubContact);
    this.form.controls.SubContTel.setValue(this.data.subs[i].SubContTel);
    this.form.controls.SubContFax.setValue(this.data.subs[i].SubContFax);
    this.form.controls.SubContEmail.setValue(this.data.subs[i].SubContEmail);
    this.form.controls.SubUser.setValue(this.data.subs[i].SubUser);
    this.form.controls.SubPw.setValue(this.data.subs[i].SubPw);
    this.subIndx = i;

    this.setProvInsIDs(event.value, i);
  }

  setProvInsIDs(subID: string, i: number) {
    if (this.data.prov && this.data.ins) { // Input as subProv in q837 component
      this.dProv = this.data.prov;  // Descriptive display on top
      this.provID = this.data.prov?.replace(/\D*/g, '');  // Prioritize incoming prov which contains provID
      this.dIns = this.data.ins;  // Descriptive display on top
      this.insID = this.data.ins?.replace(/\D*/g, '');  // Prioritize incoming ins which contains provID
    } else {  // Try and get dProv & dIns from form config
      this.dProv = '';
      this.provID = '';
      this.dIns = '';
      this.insID = '';
      if (+subID > -1 && +this.data.subs[i].provID && +this.data.subs[i].insID) {
        const prov = this.data.provs.find(p => p.pKey === this.data.subs[i].provID);
        if (prov) {
          this.dProv = '(' + prov.pKey + ') ' + prov.alias;
          this.provID = prov.pKey
        } else {
          this.dProv = '';
          this.provID = '';
        }
        const ins = this.data.inss.find(x => +x.pKey === +this.data.subs[i].insID);
        if (ins) {
          this.dIns = '(' + ins.pKey + ') ' + ins.alias;
          this.insID = ins.pKey;
        } else {
          this.dIns = '';
          this.dIns = '';
        }
      }
    }

    this.form.controls.provID.setValue(this.provID);
    this.form.controls.insID.setValue(this.insID);
  }

  toUpperCase(event: any) {
    event.target.value = event.target.value.toUpperCase();
  }

  onClick_inmediata() {
    const isa06 = '660610220'
    this.form.controls.SubIsa05.setValue('30');
    this.form.controls.SubIsa07.setValue('30');
    this.form.controls.SubIsa08.setValue(isa06);
    this.form.controls.SubGs02.setValue(isa06);
    this.form.controls.SubGs03.setValue(isa06);
  }

  //Inmetiata credentials:
  // Maria mtnz: U= Clinicad, P= Dr40cl
  // Juarbe : U= charlesj, P= Kakiss12
}
