<div style="overflow: auto; max-height: 590px; margin-bottom: 15px;"> <!-- Needed for stick header to work -->
    <h4 class="text-center m-0" style="font-style: italic;">{{engLang ? 'Claims': 'Casos'}}{{' (' + clm.length +
        ')'}}</h4>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortea($event)" class="clmTable"
        multiTemplateDataRows>

        <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="CasDt">
            <th mat-header-cell *matHeaderCellDef class="font10"> {{engLang ? ' Posted ': ' Posteado '}}
            </th>
            <td mat-cell *matCellDef="let claim" class="font10"> {{claim.CasDt}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="CasNo">
            <th mat-header-cell *matHeaderCellDef class="font10 text-center"> {{engLang ? ' Claim ': ' Caso '}} </th>
            <td mat-cell *matCellDef="let claim" class="font10 text-center">
                <button mat-button class="link-button"
                    (click)="xtPID = claim.PatID; xtCID = claim.CasID;
                patNmModalHd = claim.PatNm;
                casNoModalHd = claim.CasNo;
                open(recordCaseModal, { backdrop: 'static', size: 'lg', scrollable: true }, 'recordCaseModal', $event);">
                    {{claim.CasNo}}
                </button>
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="CasContNo">
            <th mat-header-cell *matHeaderCellDef class="font10" mat-sort-header> {{engLang ? 'Contract No.': 'No.
                Contrato'}} </th>
            <td mat-cell *matCellDef="let claim" class="font10"> {{claim.CasContNo}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="PatNm">
            <th mat-header-cell *matHeaderCellDef class="font10 text-center" mat-sort-header> {{engLang ? 'Patient':
                'Paciente'}}
            </th>
            <td mat-cell *matCellDef="let claim" class="font10"> {{claim.PatNm}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="ps">
            <th mat-header-cell *matHeaderCellDef class="font10"> ½ </th>
            <td mat-cell *matCellDef="let claim" class="font10"> {{claim.ps}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="cBalance">
            <th mat-header-cell *matHeaderCellDef class="rtalign font10" mat-sort-header> Balance </th>
            <td mat-cell *matCellDef="let claim" class="rtalign font10"> {{claim.cBalance |
                currency:'USD':'':'1.2-2'}}</td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="dxs">
            <th mat-header-cell *matHeaderCellDef class="font10"> Dx </th>
            <td mat-cell *matCellDef="let claim" class="font10">{{claim.dxs}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="hasNote">
            <th mat-header-cell *matHeaderCellDef class="font10 text-center"> {{engLang ? 'Notes': 'Notas'}} </th>
            <td mat-cell *matCellDef="let claim" class="text-center">
                <button mat-button (click)="showCasNotes($event,claim)">
                    <span *ngIf="claim.hasNote === 'True'">
                        <!-- {{claim.hasNote}} -->
                        <!-- <img src="../../../../app/assets/images/docs.png" width="15" height="15" alt="notas"> -->
                        <img src="../../../../app/assets/images/file.png" width="15" height="15" alt="notas">
                    </span>
                </button>
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="otherIns">
            <th mat-header-cell *matHeaderCellDef class="font10"> {{engLang ? 'Other Ins': 'Otro Seg'}} </th>
            <td mat-cell *matCellDef="let claim" class="font10"> {{claim.otherIns}}</td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="expandCol">
            <td mat-cell *matCellDef="let claim" [attr.colspan]="rptAgingClmDisplydCols.length">
                <div *ngIf="detExpanded.length && claim.expandCol != ''">
                    <app-rpt-aging-details [det]="detExpanded" [pay]="pay" [pad]="padExpanded"></app-rpt-aging-details>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="adjust">
            <th mat-header-cell *matHeaderCellDef class="font10 text-center"> {{engLang ? 'Adjust': 'Ajustar'}} </th>
            <td mat-cell *matCellDef="let claim" class="text-center">
                <mat-checkbox color="primary" class="font10" [(ngModel)]="claim.adjust"
                    (click)="onClick_adjust($event,claim)"></mat-checkbox>
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="reclaimed">
            <th mat-header-cell *matHeaderCellDef class="font10 text-center"> {{engLang ? 'Reclaim': 'Reclamar'}} </th>
            <td mat-cell *matCellDef="let claim" class="text-center">
                <mat-checkbox color="primary" class="font10" [(ngModel)]="claim.reclaimed"
                    [disabled]="!hasPayments(claim)"
                    (click)="onClick_reclaim($event,claim);openReclModal(reclaimsModalTmpl, { backdrop: 'static', size: 'xl', scrollable: true, keyboard: false }, 'reclaimsModalTmpl')">
                </mat-checkbox>
            </td>
            <!-- [(ngModel)]="setReclaimed(claim.reclaimed)" -->
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="rptAgingClmDisplydCols; sticky: true" class="clmRow"></tr>
        <tr mat-row *matRowDef="let row; columns: rptAgingClmDisplydCols;" class="clmRow"
            (click)="onClick_expandClaims(row)" [class.expandedRw]="isExpanded(row)"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandCol'];" [class.collapsedRow]="row.expandCol == ''">
        </tr>
        <tr mat-footer-row *matFooterRowDef="rptAgingClmDisplydCols" class="m-3"
            style="background-color: #ffffff; display: none;"></tr>
    </table>
</div>
<!-- <pre>{{ rptAgingData.ins | json }}</pre> -->

<ng-template #reclaimsModalTmpl let-modal>
    <div class="modal-header" style="background-color:  #c1c1bf;">
        <div>
            <h4 class="modal-title mb-1">{{ engLang ? 'Reclaim Form': 'Forma para Reclamo' }}
            </h4>
        </div>
        <div class="align-self-start">
            <button type="button" class="btn btn-sm btn-close" (click)="closeReclaimsModal()"
                (keyup)="onKeyup_escape($event)"></button>
        </div>
    </div>

    <div class="modal-body m-0 p-0" (keyup)="onKeyup_escape($event)">
        <!-- DON'T CHANGE THIS PADDING AS IT WILL DISPLACE THE RECLAIM IMAGES -->

        <div *ngIf="reclCasID && reclPS">
            <app-reclaim [sn]="sn" [engLang]="engLang" [casID]="reclCasID" [ps]="reclPS" [ckNo]="reclCkNo"
                [ckDt]="reclCkDt" [reclTyp]="reclTyp" [saveToggle]="saveToggle" [deleteToggle]="deleteToggle"
                [insIdSeld]="reclInsId" [reclID]="reclID" [printToggle]="printToggle" [idxSeld]="reclIdxSeld"
                [icnsPrevIdx]="icnsPrevIdx" (closeEventEmitter)="closeReclaimComponent($event)"
                (enabSaveReclEventEmitter)="setEnabSaveReclBtn($event)"
                (resetSaveToggleEventEmitter)="resetSaveToggle($event)"
                (resetDeleteToggleEventEmitter)="resetDeleteToggle($event)"
                (showPrintDeleteBtnEventEmitter)="printDelete($event)"
                (ckUnckClmReclaimEventEmitter)="ckUnckReclaimCkBox($event)"
                (spinnerEventEmitter)="toggleSpinner($event)" (resetIcnsPrevIdxEventEmitter)="resetIcnsPrevIdx($event)">
            </app-reclaim>
        </div>

    </div>

    <div class="modal-footer justify-content-between" style="background-color: #c1c1bf;">
        <div style="font-size: 70%;">
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
        </div>
        <div *ngIf="+reclID" class="d-flex justify-content-evenly">
            <div *ngIf="!spinner">
                <button type="button" class="btn btn-secondary" (click)="deleteToggle=true">{{ engLang ?
                    'Delete':
                    'Eliminar' }}
                    <i class="fa fa-trash"></i>
                </button>
            </div>
            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<!-- spacer -->
            </div>
            <div>
                <button type="button" class="btn btn-success" (click)="print()">{{
                    engLang ? 'Print': 'Imprimir' }}
                    <i class="fas fa-print cursor-pointer"></i>
                </button>
            </div>
        </div>
        <div>
            <button type="button" class="btn btn-outline-dark" *ngIf="!spinner" [disabled]="!enabSaveRecl"
                (click)="saveToggle = true">{{ engLang ? 'Save': 'Grabar' }}</button>
        </div>
        {{ modalStack }}
    </div>
</ng-template>

<!-- modal reclaim exit w/o saving .................................................................. -->
<ng-template #reclaimExitWoSaveModal let-modal>
    <div class="modal-header" style="background-color:  #d6deec;">
        <div>
            <h4 class="modal-title">{{ engLang ? 'Exit w/o Saving ?': '¿ Salir sin Grabar ?' }}</h4>
        </div>
        <div class="align-self-start">
            <button type="button" class="btn btn-sm btn-close"
                (click)="modal.dismiss('closeReclaimExitWoSaveModal')"></button>
        </div>
    </div>
    <div class="modal-body m-0 p-0">

    </div>
    <div class="modal-footer justify-content-between" style="background-color: #d6deec;">
        <div style="font-size: 70%;">
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
        </div>
        <div>
            <button type="button" class="btn btn-outline-dark" *ngIf="!waiting4Response"
                (click)="modal.close('oK_exitWoSaveReclaimModal')">Ok</button>
        </div>
    </div>

    <!-- <pre>{{ modalStack.length }}</pre> -->
    <pre>{{ modalStack }}</pre>

</ng-template>

<!-- modal record/case ................................................................................... -->
<ng-template #recordCaseModal let-modal>
    <div class="modal-header p-8" style="background-color: #ecead6;">
        <div>
            Record: <strong>{{ patNmModalHd }}</strong> <br />
            {{ engLang ? 'Claim: ': 'Caso: ' }}{{ casNoModalHd }}
        </div>
        <div>
            <button type="button" class="btn btn-sm btn-close" style="margin-top: -30px;"
                (click)="modal.dismiss('recordCaseModal')"></button>
        </div>
    </div>

    <div class="modal-body" #modalBody>
        <app-record [activeTab]="2" [xtPID]="xtPID" [xtCID]="xtCID" [modalBody]="modalBody"
            (setNewRecordNoEvent)="setNewRecordNo($event)">
        </app-record>
    </div>

    <div class="modal-footer d-sm-inline-flex justify-content-between p-8" style="background-color: #ecead6;">
        <div>
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
        </div>
        <div>
            <button type="button" class="btn btn-sm btn-outline-dark" *ngIf="!waiting4Response"
                (click)="modal.close('close_recordCaseModal')">{{ engLang ? 'Close': 'Cerrar' }}</button>
        </div>
    </div>

</ng-template>