/* src/app/ck-register/ck-register.component.css */
.redColor {
  color: red;
}
.blueColor {
  color: #0000FF;
}
.redBackgColr {
  background-color: red;
}
.oddCksSrch {
  background-color: #c8d6ca;
}
.evenCksSrch {
  background-color: #efdee7;
}
.oddReclSrch {
  background-color: #e8c3dc;
}
.evenReclSrch {
  background-color: #e7d5de;
}
table {
  width: 100%;
  border-collapse: collapse;
}
thead th {
  font-size: 80%;
  vertical-align: middle;
}
tbody td {
  vertical-align: middle;
}
tbody tr:hover {
  background-color: #EBECCD;
}
.colBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.colBoxAlgnLft {
  align-items: flex-start;
}
.colBoxAddCkLabels {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 20.5px;
}
.colBoxAddCkInputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
}
.rowBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.boxPayModalHead {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
}
.ckDtStyle {
  text-align: center;
}
.currStyle {
  text-align: right;
}
.currency {
  width: 100%;
  text-align: right;
}
.stickyCksTabHead {
  position: sticky;
  top: 0;
  background-color: #aca594;
  z-index: 5;
}
.ckTabSize {
  width: 100%;
  height: 550px;
  overflow-y: auto;
}
.stickyClmsTabHead {
  position: sticky;
  top: 0;
  background-color: rgb(230 217 231);
  font-size: 135%;
}
.rowSelected {
  background-color: #a5b1a2;
}
.cursorPtr {
  cursor: pointer;
}
.prevent-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.small {
  font-size: small;
}
.xsmall {
  font-size: x-small;
}
.xxsmall {
  font-size: xx-small;
}
#ckRegTablBody td {
  height: 30px;
}
.h32 {
  height: 32.19px;
}
.modal-tit-center-div {
  margin: 0 auto;
  width: 42px;
  padding: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (max-width: 992px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  .disappear {
    display: block;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #ckRegTabl tr {
    border: 5px solid #ccc;
  }
  #ckRegTabl td {
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    height: 34px;
  }
  #ckRegTabl td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    height: 34px;
  }
  td.ckRegTablTd:nth-of-type(2):before {
    content: "No. Cheque: ";
    font-style: italic;
    font-weight: normal;
  }
  td.ckRegTablTd:nth-of-type(3):before {
    content: "Fecha: ";
    font-style: italic;
    font-weight: normal;
  }
  td.ckRegTablTd:nth-of-type(4):before {
    content: "Total$: ";
    font-style: italic;
    font-weight: normal;
    text-align: left;
  }
  td.ckRegTablTd:nth-of-type(6):before {
    content: "Desglosado$: ";
    font-style: italic;
    font-weight: normal;
    text-align: left;
  }
  td.ckRegTablTd:nth-of-type(7):before {
    content: "Pendiente$: ";
    font-style: italic;
    font-weight: normal;
    text-align: left;
  }
  td.ckRegTablTd:nth-of-type(8):before {
    content: "NPI: ";
    font-style: italic;
    font-weight: normal;
  }
  td.ckRegTablTd:nth-of-type(9):before {
    content: "Payer ID: ";
    font-style: italic;
    font-weight: normal;
  }
  td.ckRegTablTd:nth-of-type(10):before {
    content: "Recibido: ";
    font-style: italic;
    font-weight: normal;
  }
  td.ckRegTablTd:nth-of-type(11):before {
    content: "Archivo 835: ";
    font-style: italic;
    font-weight: normal;
  }
  td.ckRegTablTd:nth-of-type(12):before {
    content: "Fecha 835: ";
    font-style: italic;
    font-weight: normal;
  }
  .tdDivBtn {
    width: 500px;
    display: inline-flex;
  }
  .ckDtStyle {
    text-align: left;
    padding-left: "";
    padding-right: "";
  }
  @media only screen and (max-width: 550px) {
    select {
      width: 150px;
    }
    input {
      width: 150px;
    }
  }
}
/*# sourceMappingURL=ck-register.component.css.map */
