<ng-template #claimAdjustConfirmModal let-modal>
    <div class="modal-header pl-1 pt-1 pr-0 pb-0" style="background-color:  #ecead6;">
        <h4 class="modal-title p-0 my-2">
            {{ adjustRemove ? ( engLang ? "Undo adjustment ?": "¿ Remover ajuste ?" ) :
            ( undoPayment ? ( engLang ? "Remove last payment ?": "¿ Remover último pago ?" ):
            ( engLang ? "Adjust claim to zero balance ?": "¿ Ajustar facrura a cero balance ?" )) }} </h4>
        <button type="button" class="btn btn-sm btn-close font9 p-0" style="margin-top: -32px;"
            (click)="modal.dismiss('x')"></button>
    </div>

    <div class="modal-body">
        {{ adjustRemove ? ( engLang ? "The selected adjustment will be removed and the balance will be restored." :
        "El ajuste seleccionado será removido y el balance de la factura restaurado." ) :
        ( undoPayment ? ( englang ? 'The last payment recently entered will be deleted.':
        "El último pago recién entrado será eliminado." ):
        ( engLang ? "The claim's balance will be set to $0.00" :
        "El balance de la factura se llevará a $0.00")) }}
    </div>

    <div class="modal-footer justify-content-between p-0" style="background-color: #ecead6;">
        <div class="font9">
            {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
        </div>
        <div>
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('ok')">Ok</button>
        </div>
    </div>

    <!-- <pre>adjustRemove = {{adjustRemove}}</pre> -->
    <!-- <pre>undoPayment = {{undoPayment}}</pre> -->
</ng-template>