/* src/app/shared/reclaim/recl-xml-humana-rev17112010/recl-xml-humana-rev17112010.component.css */
input {
  cursor: pointer;
  border: 1px solid orange;
}
.font8 {
  font-size: 8px;
}
.font9 {
  font-size: 9px;
}
.txtHUMProvNm {
  position: absolute;
  top: 195px;
  left: 211px;
}
.txtHUMNPI {
  position: absolute;
  top: 195px;
  left: 720px;
}
.txtHUMDate {
  position: absolute;
  top: 195px;
  left: 940px;
}
.txtHUMAd1 {
  position: absolute;
  top: 234px;
  left: 180px;
}
.txtHUMAd2 {
  position: absolute;
  top: 265px;
  left: 180px;
}
.txtHUMAd3 {
  position: absolute;
  top: 295px;
  left: 180px;
}
.txtProvEmail {
  position: absolute;
  top: 325px;
  left: 180px;
}
.optHUMMedicare {
  position: absolute;
  top: 234px;
  left: 676px;
}
.optHUMComercial {
  position: absolute;
  top: 234px;
  left: 858px;
}
.optHUMReforma {
  position: absolute;
  top: 234px;
  left: 1025px;
}
.chkHUMPFFS {
  position: absolute;
  top: 304px;
  left: 904px;
}
.currcy {
  text-align: right;
}
.txtHUMContr1 {
  position: absolute;
  top: 475px;
  left: 45px;
}
.txtHUMIcn1 {
  position: absolute;
  top: 475px;
  left: 193px;
}
.txtHUMNm1 {
  position: absolute;
  top: 475px;
  left: 360px;
}
.txtHUMFrom1 {
  position: absolute;
  top: 475px;
  left: 540px;
}
.txtHUMBal1 {
  position: absolute;
  top: 475px;
  left: 671px;
  max-width: 200px;
}
.txtHUMCom1 {
  position: absolute;
  top: 475px;
  left: 780px;
}
.txtHUMContr2 {
  position: absolute;
  top: 504px;
  left: 45px;
}
.txtHUMIcn2 {
  position: absolute;
  top: 504px;
  left: 193px;
}
.txtHUMNm2 {
  position: absolute;
  top: 504px;
  left: 360px;
}
.txtHUMFrom2 {
  position: absolute;
  top: 504px;
  left: 540px;
}
.txtHUMBal2 {
  position: absolute;
  top: 504px;
  left: 671px;
  max-width: 200px;
}
.txtHUMCom2 {
  position: absolute;
  top: 504px;
  left: 780px;
}
.txtHUMContr3 {
  position: absolute;
  top: 533px;
  left: 45px;
}
.txtHUMIcn3 {
  position: absolute;
  top: 533px;
  left: 193px;
}
.txtHUMNm3 {
  position: absolute;
  top: 533px;
  left: 360px;
}
.txtHUMFrom3 {
  position: absolute;
  top: 533px;
  left: 540px;
}
.txtHUMBal3 {
  position: absolute;
  top: 533px;
  left: 671px;
  max-width: 200px;
}
.txtHUMCom3 {
  position: absolute;
  top: 533px;
  left: 780px;
}
.txtHUMContr4 {
  position: absolute;
  top: 562px;
  left: 45px;
}
.txtHUMIcn4 {
  position: absolute;
  top: 562px;
  left: 193px;
}
.txtHUMNm4 {
  position: absolute;
  top: 562px;
  left: 360px;
}
.txtHUMFrom4 {
  position: absolute;
  top: 560px;
  left: 540px;
}
.txtHUMBal4 {
  position: absolute;
  top: 560px;
  left: 671px;
  max-width: 200px;
}
.txtHUMCom4 {
  position: absolute;
  top: 560px;
  left: 780px;
}
.txtHUMContr5 {
  position: absolute;
  top: 589px;
  left: 45px;
}
.txtHUMIcn5 {
  position: absolute;
  top: 589px;
  left: 193px;
}
.txtHUMNm5 {
  position: absolute;
  top: 589px;
  left: 360px;
}
.txtHUMFrom5 {
  position: absolute;
  top: 588px;
  left: 540px;
}
.txtHUMBal5 {
  position: absolute;
  top: 588px;
  left: 671px;
  max-width: 200px;
}
.txtHUMCom5 {
  position: absolute;
  top: 588px;
  left: 780px;
}
.txtHUMContr6 {
  position: absolute;
  top: 618px;
  left: 45px;
}
.txtHUMIcn6 {
  position: absolute;
  top: 618px;
  left: 193px;
}
.txtHUMNm6 {
  position: absolute;
  top: 618px;
  left: 360px;
}
.txtHUMFrom6 {
  position: absolute;
  top: 617px;
  left: 540px;
}
.txtHUMBal6 {
  position: absolute;
  top: 617px;
  left: 671px;
  max-width: 200px;
}
.txtHUMCom6 {
  position: absolute;
  top: 617px;
  left: 780px;
}
.txtHUMContr7 {
  position: absolute;
  top: 645px;
  left: 45px;
}
.txtHUMIcn7 {
  position: absolute;
  top: 645px;
  left: 193px;
}
.txtHUMNm7 {
  position: absolute;
  top: 645px;
  left: 360px;
}
.txtHUMFrom7 {
  position: absolute;
  top: 646px;
  left: 540px;
}
.txtHUMBal7 {
  position: absolute;
  top: 646px;
  left: 671px;
  max-width: 200px;
}
.txtHUMCom7 {
  position: absolute;
  top: 646px;
  left: 780px;
}
.txtHUMContr8 {
  position: absolute;
  top: 675px;
  left: 45px;
}
.txtHUMIcn8 {
  position: absolute;
  top: 675px;
  left: 193px;
}
.txtHUMNm8 {
  position: absolute;
  top: 675px;
  left: 360px;
}
.txtHUMFrom8 {
  position: absolute;
  top: 674px;
  left: 540px;
}
.txtHUMBal8 {
  position: absolute;
  top: 674px;
  left: 671px;
  max-width: 200px;
}
.txtHUMCom8 {
  position: absolute;
  top: 674px;
  left: 780px;
}
.txtHUMContr9 {
  position: absolute;
  top: 703px;
  left: 45px;
}
.txtHUMIcn9 {
  position: absolute;
  top: 703px;
  left: 193px;
}
.txtHUMNm9 {
  position: absolute;
  top: 703px;
  left: 360px;
}
.txtHUMFrom9 {
  position: absolute;
  top: 702px;
  left: 540px;
}
.txtHUMBal9 {
  position: absolute;
  top: 702px;
  left: 671px;
  max-width: 200px;
}
.txtHUMCom9 {
  position: absolute;
  top: 702px;
  left: 780px;
}
.txtHUMContr10 {
  position: absolute;
  top: 732px;
  left: 45px;
}
.txtHUMIcn10 {
  position: absolute;
  top: 732px;
  left: 193px;
}
.txtHUMNm10 {
  position: absolute;
  top: 732px;
  left: 360px;
}
.txtHUMFrom10 {
  position: absolute;
  top: 731px;
  left: 540px;
}
.txtHUMBal10 {
  position: absolute;
  top: 731px;
  left: 671px;
  max-width: 200px;
}
.txtHUMCom10 {
  position: absolute;
  top: 731px;
  left: 780px;
}
.txtHUMBalTot {
  position: absolute;
  top: 760px;
  left: 671px;
  max-width: 200px;
}
.txtHUM_User {
  position: absolute;
  top: 835px;
  left: 700px;
}
.txtHUMCasNo {
  position: absolute;
  top: 835px;
  left: 950px;
}
/*# sourceMappingURL=recl-xml-humana-rev17112010.component.css.map */
