/* src/app/shared/message-dialog.component.css */
.evenIcd10 {
  background-color: #f7c6bc;
}
.oddIcd10 {
  background-color: #fae7a5;
}
.rowsHover tr:hover {
  background-color: #1ecfd6;
}
.warning {
  background-color: orange;
}
/*# sourceMappingURL=message-dialog.component.css.map */
