/* src/app/shared/submitter/submitter.dialog.css */
.ucase {
  text-transform: uppercase;
}
.select-width {
  width: 100%;
}
.select-style {
  background-color: red;
}
.label-div {
  padding-top: 16px;
  width: 225px;
  height: 1px;
}
mat-form-field {
  width: 245px;
}
.new {
  font-size: 17px;
  cursor: pointer;
}
/*# sourceMappingURL=submitter.dialog.css.map */
