<div style="overflow: auto; max-height: 590px; margin-bottom: 10px;"> <!-- Needed for stick header to work -->
    <h4 class="text-center mb-0" style="font-style: italic;">{{engLang ? 'Procedures': 'Procedimientos'}}{{' (' +
        det.length + ')'}}</h4>
    <table mat-table [dataSource]="dataSource" class="detTable" multiTemplateDataRows>

        <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="dtFrom">
            <th mat-header-cell *matHeaderCellDef class="font10"> {{engLang ? ' From ': ' Desde '}}
            </th>
            <td mat-cell *matCellDef="let det" class="font10"> {{det.dtFrom}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="DetQty">
            <th mat-header-cell *matHeaderCellDef class="font10 text-center"> {{engLang ? ' Qty ': ' Cant '}} </th>
            <td mat-cell *matCellDef="let det" class="font10 text-center"> {{det.DetQty}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="DetPos">
            <th mat-header-cell *matHeaderCellDef class="font10 text-center"> {{engLang ? 'POS': 'Lu'}} </th>
            <td mat-cell *matCellDef="let det" class="font10 text-center"> {{det.DetPos}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="DetPcode">
            <th mat-header-cell *matHeaderCellDef class="font10 text-center"> CPT
            </th>
            <td mat-cell *matCellDef="let det" class="font10 text-center"> {{det.DetPcode}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="mods">
            <th mat-header-cell *matHeaderCellDef class="font10"> Mods </th>
            <td mat-cell *matCellDef="let det" class="font10"> {{det.mods}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="DetDx">
            <th mat-header-cell *matHeaderCellDef class="font10 text-center"> Dx </th>
            <td mat-cell *matCellDef="let det" class="font10 text-center"> {{det.DetDx}}</td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="DetUsual">
            <th mat-header-cell *matHeaderCellDef class="rtalign font10"> Usual </th>
            <td mat-cell *matCellDef="let det" class="rtalign font10">{{det.DetUsual | currency:'USD':'':'1.2-2'}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="DetXpect">
            <th mat-header-cell *matHeaderCellDef class="rtalign font10"> {{engLang ? 'Expect': 'Espera'}} </th>
            <td mat-cell *matCellDef="let det" class="rtalign font10">{{det.DetXpect | currency:'USD':'':'1.2-2'}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="DetPvdo">
            <th mat-header-cell *matHeaderCellDef class="rtalign font10"> {{engLang ? 'Coins': 'Coaseg'}} </th>
            <td mat-cell *matCellDef="let det" class="rtalign font10">{{det.DetPvdo | currency:'USD':'':'1.2-2'}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="prod">
            <th mat-header-cell *matHeaderCellDef class="font10"> {{engLang ? 'Rend': 'Prod'}} </th>
            <td mat-cell *matCellDef="let det" class="font10">{{det.prod}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="DetDesc">
            <th mat-header-cell *matHeaderCellDef class="font10"> Descr </th>
            <td mat-cell *matCellDef="let det" class="font10">{{det.DetDesc}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="rptAgingDetDisplydCols; sticky: true" class="detRow"></tr>
        <tr mat-row *matRowDef="let row; columns: rptAgingDetDisplydCols;" class="detRow"
            (click)="onClick_expandDets(row)"></tr>
        <!-- <tr mat-row *matRowDef="let row; columns: ['expandCol'];" [class.collapsedRow]="row.expandCol == ''">
        </tr> -->
        <tr mat-footer-row *matFooterRowDef="rptAgingDetDisplydCols;" style="display: none;"></tr>
    </table>

    <!-- Payments table -->

    <div style="overflow: auto; max-height: 590px;"> <!-- Needed for stick header to work -->
        <h4 class="text-center mb-0" style="font-style: italic;">{{engLang ? 'Payments': 'Pagos'}}{{' (' +
            pay.length + ')'}}</h4>
        <table mat-table [dataSource]="dataSourcePay" class="payTable" multiTemplateDataRows>

            <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

            <ng-container matColumnDef="PayPS">
                <th mat-header-cell *matHeaderCellDef class="font10 text-center"> {{engLang ? ' PS ': ' FP '}}
                </th>
                <td mat-cell *matCellDef="let pay" class="font10 text-center"> {{pay.PayPS}} </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <ng-container matColumnDef="PayDt">
                <th mat-header-cell *matHeaderCellDef class="font10 text-center"> {{engLang ? ' Date ': ' Fecha '}}
                </th>
                <td mat-cell *matCellDef="let pay" class="font10 text-center"> {{pay.PayDt}} </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <ng-container matColumnDef="PayAmnt">
                <th mat-header-cell *matHeaderCellDef class="font10 rtalign"> {{engLang ? 'Amount': 'Pagado'}} </th>
                <td mat-cell *matCellDef="let pay" class="font10 rtalign"> {{pay.PayAmnt | currency:'USD':'':'1.2-2'}}
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <ng-container matColumnDef="PayCkNo">
                <th mat-header-cell *matHeaderCellDef class="font10 text-center"> Ck/Voucher/EFT </th>
                <td mat-cell *matCellDef="let pay" class="font10 text-center"> {{pay.PayCkNo}} </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <ng-container matColumnDef="PayApCode">
                <th mat-header-cell *matHeaderCellDef class="font10 text-center"> ICN </th>
                <td mat-cell *matCellDef="let pay" class="font10 text-center"> {{pay.PayApCode}} </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <ng-container matColumnDef="PayMode">
                <th mat-header-cell *matHeaderCellDef class="font10 text-center"> Mode </th>
                <td mat-cell *matCellDef="let pay" class="font10 text-center"> {{pay.PayMode}}</td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <ng-container matColumnDef="PayMemo">
                <th mat-header-cell *matHeaderCellDef class="font10"> Memo </th>
                <td mat-cell *matCellDef="let pay" class="font10">{{pay.PayMemo}} </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="rptAgingPayDisplydCols; sticky: true" class="payRow"></tr>
            <tr mat-row *matRowDef="let row; columns: rptAgingPayDisplydCols;" class="payRow"></tr>
            <tr mat-footer-row *matFooterRowDef="rptAgingPayDisplydCols;" style="display: none;"></tr>
        </table>

        <!-- CARC/RARC -->

        <div style="overflow: auto; max-height: 590px;"> <!-- Needed for stick header to work -->
            <h4 class="text-center mb-0" style="font-style: italic;">{{engLang ? 'Adjustment Notes (CARC - RARC)':
                'Notas de Ajsutes (CARC/RARC)'}}{{' (' +
                pad.length + ')'}}</h4>
            <table mat-table [dataSource]="dataSourcePad" class="padTable" multiTemplateDataRows>

                <ng-container matColumnDef="PayAdjGrp">
                    <th mat-header-cell *matHeaderCellDef class="font10 text-center"> {{engLang ? 'Group': 'Grupo'}}
                    </th>
                    <td mat-cell *matCellDef="let pad" class="font10 text-center"> {{pad.PayAdjGrp}} </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <ng-container matColumnDef="PayAdjReason">
                    <th mat-header-cell *matHeaderCellDef class="font10 text-center"> {{engLang ? 'Reason': 'Razón'}}
                    </th>
                    <td mat-cell *matCellDef="let pad" class="font10 text-center">
                        <button mat-button class="link-button" (click)="getCarcDescrFromAdj(pad)">
                            {{pad.PayAdjReason}}
                        </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <ng-container matColumnDef="PayAdjAmnt">
                    <th mat-header-cell *matHeaderCellDef class="font10 rtalign"> {{engLang ? 'Amount': 'Monto'}} </th>
                    <td mat-cell *matCellDef="let pad" class="font10 rtalign"> {{pad.PayAdjAmnt |
                        currency:'USD':'':'1.2-2'}} </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <ng-container matColumnDef="cptMod">
                    <th mat-header-cell *matHeaderCellDef class="font10 text-center"> CPT </th>
                    <td mat-cell *matCellDef="let pad" class="font10 text-center"> {{pad.cptMod}} </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <ng-container matColumnDef="PayAdjHeRem">
                    <th mat-header-cell *matHeaderCellDef class="font10 text-center"> Remark </th>
                    <td mat-cell *matCellDef="let pad" class="font10">
                        <button mat-button class="link-button" (click)="getRarcDesr(pad.PayAdjHeRem)">
                            {{pad.PayAdjHeRem}}
                        </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="rptAgingPadDisplydCols; sticky: true" class="padRow"></tr>
                <tr mat-row *matRowDef="let row; columns: rptAgingPadDisplydCols;" class="padRow"></tr>
                <tr mat-footer-row *matFooterRowDef="rptAgingPadDisplydCols;" style="display: none;"></tr>

            </table>
        </div>
    </div>
</div>
<!-- <pre>{{ rptAgingData.ins | json }}</pre> -->