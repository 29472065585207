import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-ck-register-detail-modal-header',
    templateUrl: './ck-register-detail-modal-header.component.html',
    styleUrls: ['../ck-register.component.css'],
    standalone: false
})
export class CkRegisterDetailModalHeaderComponent {
  @Input() sn: string;
  @Input() engLang: boolean;
  @Input() hdCkNo: string;
  @Input() hdCkDt: string;
  @Input() hdCkBal: string;
  @Input() hdPendCnt: string;
  @Input() adjCnt: string;
  @Input() adjTotal: string;
  @Input() reclCnt: string;
  @Input() reclTotal: string;
  @Input() corrCnt: string;
  @Input() unMatchCnt: string = '0';
  @Input() claims: any[]; // Reduced copy of claims for searching porpuses
  @Input() topCases: number;
  @Input() showZeroBal: boolean;
  @Input() filNm: string;  // To be retuned to CkRegister835DetailComponent
  @Input() filDt: string; // To be retuned to CkRegister835DetailComponent
  @Output() setCasIndxFound: EventEmitter<any> = new EventEmitter()
  @Output() setClaimSrchActive: EventEmitter<any> = new EventEmitter()
  @Output() setClaimsAdjustedSrchActive: EventEmitter<any> = new EventEmitter();
  @Output() setReclaimedSrchActive: EventEmitter<any> = new EventEmitter();
  @Output() setClaimsPendingSrchActive: EventEmitter<any> = new EventEmitter();
  @Output() toggleShowZeroBal: EventEmitter<any> = new EventEmitter();

  srchClaimTxt: string = '';
  claimSrchActive: boolean = false;
  claimsAdjustedSrchActive: boolean = false;
  reclaimedSrchActive: boolean = false;
  claimsPendingSrchActive: boolean = false;
  casIndxFound: string[];  // Holds indx of cases found in a search

  constructor() { };

  ngOnDestroy() {
    this.toggleShowZeroBal.emit({
      'showZeroBal': false,
      'filNm': '',
      'filDt': '',
      'clrZeroBal': true
    });
  }

  onKeyUp_srch(event: any): void {
    this.claimSrchActive = false;
    event.target.value = event.target.value.toUpperCase();
    if (event.key == 'Enter' || event.target.value.match(/[']\w/gi)) {
      if (event.target.id == 'srchClaim') {
        this.casIndxFound = [];  // this.clrCasIDsFound.emit();
        this.claimSrchActive = false;
        this.setClaimSrchActive.emit(this.claimSrchActive);
      }

      if (event.key == 'Enter') {
        if (event.target.id == 'srchClaim') {
          if (!this.srchClaimTxt) {
            return;
          }
          this.search4Claims(event);
        }
      }
    }
  }

  search4Claims(event): void {  // Fills this.casIndxFound[] with indx of cases matching search criteria
    if (this.claimSrchActive || this.claimsAdjustedSrchActive || this.reclaimedSrchActive || this.claimsPendingSrchActive) {  // Clicked X instead of looking glass
      this.srchClaimTxt = '';
      this.claimSrchActive = false;
      this.setClaimSrchActive.emit(this.claimSrchActive);
      this.claimsAdjustedSrchActive = false;
      this.setClaimsAdjustedSrchActive.emit(this.claimsAdjustedSrchActive);
      this.reclaimedSrchActive = false;
      this.setReclaimedSrchActive.emit(this.reclaimedSrchActive);
      this.claimsPendingSrchActive = false;
      this.setClaimsPendingSrchActive.emit(this.claimsPendingSrchActive);
      this.casIndxFound = [];
      this.claims.forEach(obj => {  // To make all claims visible
        this.casIndxFound.push(obj.indx);
      });
      this.setCasIndxFound.emit(this.casIndxFound);
      return;
    }

    this.casIndxFound = undefined;  // To initate a new search
    let coma; // Comma delimiter position
    if (this.claims?.length) {  // Do only if the claims table has rows
      let result = [];  // Search results

      if (event?.target.id == 'clmAdjSrch') {
        result = this.claims.filter(itm => { return (itm.adjusted == '1'); });
        this.claimsAdjustedSrchActive = true;
        this.setClaimsAdjustedSrchActive.emit(this.claimsAdjustedSrchActive);
      } else if (event?.target.id == 'reclmSrch') {
        result = this.claims.filter(itm => { return (itm.reclaimed == '1'); });
        this.reclaimedSrchActive = true;
        this.setReclaimedSrchActive.emit(this.reclaimedSrchActive);
      } else if (event?.target.id == 'clmPendSrch') {
        result = this.claims.filter(itm => { return (itm.casPsDesc == '???'); });
        this.claimsPendingSrchActive = true;
        this.setClaimsPendingSrchActive.emit(this.claimsPendingSrchActive);
      } else {

        if (this.srchClaimTxt.match(/^[a-zA-Z ]*[ ]*[,]{0,1}[ ]*[a-zA-Z ]*[ ]*[a-zA-Z]{0,1}$/gmi)) { // Name searches, no digits
          coma = this.srchClaimTxt.indexOf(',');
          let lst: string;
          let fst: string;

          switch (coma) {
            case -1:
              lst = this.srchClaimTxt.trim();
              result = this.claims.filter(itm => { return (itm.patLstNm.includes(lst) || itm.patLstNm.includes(lst.toUpperCase())); });
              break;
            case 0:
              fst = this.srchClaimTxt.substring(1).trim();
              result = this.claims.filter(itm => { return (itm.patNm.includes(fst) || itm.patNm.includes(fst.toUpperCase())); });
              break;
            default:
              lst = this.srchClaimTxt.substring(0, coma).trim();
              fst = this.srchClaimTxt.substring(1 + coma).trim();
              result = this.claims.filter(itm => {
                return (
                  (itm.patLstNm.includes(lst) || itm.patLstNm.includes(lst.toUpperCase()))
                  && (itm.patNm.includes(fst) || itm.patNm.includes(fst.toUpperCase()))
                );
              });
          }
        } else {  // Contract No & cas No searches
          result = this.claims.filter(itm => {
            return (
              itm.casCont.toUpperCase().trim().endsWith(this.srchClaimTxt.toUpperCase().trim()) || itm.casNo.includes(this.srchClaimTxt)
            );
          });
        }
        if (this.srchClaimTxt) {  // Set true only if text was entered
          this.claimSrchActive = true;
        } else {
          this.claimSrchActive = false;
        }
        this.setClaimSrchActive.emit(this.claimSrchActive);
      }

      if (result.length == 0) { // Hide all claims if nothing found
        this.casIndxFound = [];
      } else {
        for (let i = 0; i < result.length; i++) {
          if (!this.casIndxFound) {
            this.casIndxFound = [];
          }
          this.casIndxFound.push(result[i].indx.trim());
        }
      }
      this.setCasIndxFound.emit(this.casIndxFound);
    }
  }

  onClick_toggleZeroBal(): void {
    this.claimsAdjustedSrchActive = false;
    this.claimsPendingSrchActive = false;
    this.toggleShowZeroBal.emit({
      'showZeroBal': this.showZeroBal,
      'filNm': this.filNm,
      'filDt': this.filDt,
      'clrZeroBal': false
    });
  }

  isPending(balance): boolean {
    if (parseFloat(balance) > 0) {
      return true;
    }
    return false;
  }

}

