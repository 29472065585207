/* src/app/externalTemplates/signature-capture/signature-capture.component.css */
::-webkit-input-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
  text-align: center;
}
:-moz-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
  text-align: center;
}
:-ms-input-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
  text-align: center;
}
.demogrBackgColr {
  background-color: #CBF0FE;
}
.insurBackgColr {
  background-color: #DFF0D8;
}
.contcBackgColr {
  background-color: #E9DBEE;
}
.conditBackgColr {
  background-color: #FCCECD;
}
.operatBackgColr {
  background-color: #E1E1E8;
}
.allergyBackgColr {
  background-color: #DDE7EC;
}
.rxBackgColr {
  background-color: #E9ECDD;
}
.borders {
  border: 1px solid rgba(201, 76, 76, 0.3);
}
.silverBordr {
  border: 1px solid silver;
}
.rad10 {
  border-radius: 10px;
}
.cmplBackgColr {
  background-color: #DCDAEF;
}
.thumb {
  height: 75px;
  border: 1px solid #000;
  margin: 10px 5px 0 0;
}
/*# sourceMappingURL=signature-capture.component.css.map */
