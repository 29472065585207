import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-mat-msg-dlg',
    templateUrl: './mat-msg-dlg.component.html',
    styleUrls: ['./mat-msg-dlg.component.css'],
    standalone: false
})
export class MatMsgDlgComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {}) { }

}
