/* src/app/ck-register/recl-xml-sss022309/recl-xml-sss022309.component.css */
.container {
  font-family: arial, sans-serif;
  font-size: smaller;
  margin: 0 3em 1em 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
.colContainer {
  margin: 0 1em 0 1em;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
}
.flex {
  padding-top: 10px;
  text-align: center;
  position: relative;
  flex-basis: auto;
}
.colFlex {
  padding: 0;
  margin: 0;
}
.border {
  border-style: solid;
}
.txt5_1 {
  font-family:
    "Courier New",
    Courier,
    monospace;
  margin: auto;
  width: 100%;
  margin-bottom: 4px;
}
/*# sourceMappingURL=recl-xml-sss022309.component.css.map */
