/* src/app/ck-register/recl-xml-triples-advantage-anejo1/recl-xml-triples-advantage-anejo1.component.css */
input {
  cursor: pointer;
  border: 1px solid orange;
}
.uc {
  text-transform: uppercase;
}
.font10 {
  font-size: 10px;
}
.optAHM {
  position: absolute;
  top: 149px;
  left: 899px;
}
.optSSS {
  position: absolute;
  top: 149px;
  left: 995px;
}
.txtProvNmSSS {
  position: absolute;
  top: 134px;
  left: 228px;
}
.txtProvNoSSS {
  position: absolute;
  top: 147px;
  left: 551px;
}
.txtDateSSS {
  position: absolute;
  top: 140px;
  left: 741px;
}
.txtPatNmSSS {
  position: absolute;
  top: 210px;
  left: 95px;
}
.txtContrSSS {
  position: absolute;
  top: 210px;
  left: 271px;
}
.txtFrom1 {
  position: absolute;
  top: 236px;
  left: 369px;
}
.txtTo1 {
  position: absolute;
  top: 236px;
  left: 415px;
}
.txtCpt1 {
  position: absolute;
  top: 236px;
  left: 462px;
}
.txtBal1 {
  position: absolute;
  top: 236px;
  left: 532px;
}
.txtRazon1 {
  position: absolute;
  top: 236px;
  left: 601px;
}
.txtPayDt1 {
  position: absolute;
  top: 236px;
  left: 685px;
}
.txtCkNo1 {
  position: absolute;
  top: 236px;
  left: 745px;
}
.txtPayed1 {
  position: absolute;
  top: 236px;
  left: 809px;
}
.txtComment1 {
  position: absolute;
  top: 236px;
  left: 887px;
}
.txtFrom2 {
  position: absolute;
  top: 260px;
  left: 369px;
}
.txtTo2 {
  position: absolute;
  top: 260px;
  left: 415px;
}
.txtCpt2 {
  position: absolute;
  top: 260px;
  left: 462px;
}
.txtBal2 {
  position: absolute;
  top: 260px;
  left: 532px;
}
.txtRazon2 {
  position: absolute;
  top: 260px;
  left: 601px;
}
.txtPayDt2 {
  position: absolute;
  top: 260px;
  left: 685px;
}
.txtCkNo2 {
  position: absolute;
  top: 260px;
  left: 745px;
}
.txtPayed2 {
  position: absolute;
  top: 260px;
  left: 809px;
}
.txtComment2 {
  position: absolute;
  top: 260px;
  left: 887px;
}
.txtFrom3 {
  position: absolute;
  top: 283px;
  left: 369px;
}
.txtTo3 {
  position: absolute;
  top: 283px;
  left: 415px;
}
.txtCpt3 {
  position: absolute;
  top: 283px;
  left: 462px;
}
.txtBal3 {
  position: absolute;
  top: 283px;
  left: 532px;
}
.txtRazon3 {
  position: absolute;
  top: 283px;
  left: 601px;
}
.txtPayDt3 {
  position: absolute;
  top: 283px;
  left: 685px;
}
.txtCkNo3 {
  position: absolute;
  top: 283px;
  left: 745px;
}
.txtPayed3 {
  position: absolute;
  top: 283px;
  left: 809px;
}
.txtComment3 {
  position: absolute;
  top: 283px;
  left: 887px;
}
.txtFrom4 {
  position: absolute;
  top: 306px;
  left: 369px;
}
.txtTo4 {
  position: absolute;
  top: 306px;
  left: 415px;
}
.txtCpt4 {
  position: absolute;
  top: 306px;
  left: 462px;
}
.txtBal4 {
  position: absolute;
  top: 306px;
  left: 532px;
}
.txtRazon4 {
  position: absolute;
  top: 306px;
  left: 601px;
}
.txtPayDt4 {
  position: absolute;
  top: 306px;
  left: 685px;
}
.txtCkNo4 {
  position: absolute;
  top: 306px;
  left: 745px;
}
.txtPayed4 {
  position: absolute;
  top: 306px;
  left: 809px;
}
.txtComment4 {
  position: absolute;
  top: 306px;
  left: 887px;
}
.txtFrom5 {
  position: absolute;
  top: 330px;
  left: 369px;
}
.txtTo5 {
  position: absolute;
  top: 330px;
  left: 415px;
}
.txtCpt5 {
  position: absolute;
  top: 330px;
  left: 462px;
}
.txtBal5 {
  position: absolute;
  top: 330px;
  left: 532px;
}
.txtRazon5 {
  position: absolute;
  top: 330px;
  left: 601px;
}
.txtPayDt5 {
  position: absolute;
  top: 330px;
  left: 685px;
}
.txtCkNo5 {
  position: absolute;
  top: 330px;
  left: 745px;
}
.txtPayed5 {
  position: absolute;
  top: 330px;
  left: 809px;
}
.txtComment5 {
  position: absolute;
  top: 330px;
  left: 887px;
}
.txtFrom6 {
  position: absolute;
  top: 352px;
  left: 369px;
}
.txtTo6 {
  position: absolute;
  top: 352px;
  left: 415px;
}
.txtCpt6 {
  position: absolute;
  top: 352px;
  left: 462px;
}
.txtBal6 {
  position: absolute;
  top: 352px;
  left: 532px;
}
.txtRazon6 {
  position: absolute;
  top: 352px;
  left: 601px;
}
.txtPayDt6 {
  position: absolute;
  top: 352px;
  left: 685px;
}
.txtCkNo6 {
  position: absolute;
  top: 352px;
  left: 745px;
}
.txtPayed6 {
  position: absolute;
  top: 352px;
  left: 809px;
}
.txtComment6 {
  position: absolute;
  top: 352px;
  left: 887px;
}
.txtFrom7 {
  position: absolute;
  top: 372px;
  left: 369px;
}
.txtTo7 {
  position: absolute;
  top: 372px;
  left: 415px;
}
.txtCpt7 {
  position: absolute;
  top: 372px;
  left: 462px;
}
.txtBal7 {
  position: absolute;
  top: 372px;
  left: 532px;
}
.txtRazon7 {
  position: absolute;
  top: 372px;
  left: 601px;
}
.txtPayDt7 {
  position: absolute;
  top: 372px;
  left: 685px;
}
.txtCkNo7 {
  position: absolute;
  top: 372px;
  left: 745px;
}
.txtPayed7 {
  position: absolute;
  top: 372px;
  left: 809px;
}
.txtComment7 {
  position: absolute;
  top: 372px;
  left: 887px;
}
.txtFrom8 {
  position: absolute;
  top: 394px;
  left: 369px;
}
.txtTo8 {
  position: absolute;
  top: 394px;
  left: 415px;
}
.txtCpt8 {
  position: absolute;
  top: 394px;
  left: 462px;
}
.txtBal8 {
  position: absolute;
  top: 394px;
  left: 532px;
}
.txtRazon8 {
  position: absolute;
  top: 394px;
  left: 601px;
}
.txtPayDt8 {
  position: absolute;
  top: 394px;
  left: 685px;
}
.txtCkNo8 {
  position: absolute;
  top: 394px;
  left: 745px;
}
.txtPayed8 {
  position: absolute;
  top: 394px;
  left: 809px;
}
.txtComment8 {
  position: absolute;
  top: 394px;
  left: 887px;
}
.txtFrom9 {
  position: absolute;
  top: 416px;
  left: 369px;
}
.txtTo9 {
  position: absolute;
  top: 416px;
  left: 415px;
}
.txtCpt9 {
  position: absolute;
  top: 416px;
  left: 462px;
}
.txtBal9 {
  position: absolute;
  top: 416px;
  left: 532px;
}
.txtRazon9 {
  position: absolute;
  top: 416px;
  left: 601px;
}
.txtPayDt9 {
  position: absolute;
  top: 416px;
  left: 685px;
}
.txtCkNo9 {
  position: absolute;
  top: 416px;
  left: 745px;
}
.txtPayed9 {
  position: absolute;
  top: 416px;
  left: 809px;
}
.txtComment9 {
  position: absolute;
  top: 416px;
  left: 887px;
}
.txtFrom10 {
  position: absolute;
  top: 438px;
  left: 369px;
}
.txtTo10 {
  position: absolute;
  top: 438px;
  left: 415px;
}
.txtCpt10 {
  position: absolute;
  top: 438px;
  left: 462px;
}
.txtBal10 {
  position: absolute;
  top: 438px;
  left: 532px;
}
.txtRazon10 {
  position: absolute;
  top: 438px;
  left: 601px;
}
.txtPayDt10 {
  position: absolute;
  top: 438px;
  left: 685px;
}
.txtCkNo10 {
  position: absolute;
  top: 438px;
  left: 745px;
}
.txtPayed10 {
  position: absolute;
  top: 438px;
  left: 809px;
}
.txtComment10 {
  position: absolute;
  top: 438px;
  left: 887px;
}
.txtOtherReason1 {
  position: absolute;
  top: 470px;
  left: 320px;
}
.txtOtherReason2 {
  position: absolute;
  top: 490px;
  left: 320px;
}
.txtOtherReason3 {
  position: absolute;
  top: 510px;
  left: 320px;
}
.txtOtherReason4 {
  position: absolute;
  top: 530px;
  left: 320px;
}
.txtOtherReason5 {
  position: absolute;
  top: 550px;
  left: 320px;
}
.txtOtherReason6 {
  position: absolute;
  top: 570px;
  left: 320px;
}
.txtOtherComment1 {
  position: absolute;
  top: 484px;
  left: 870px;
  height: 15px;
  width: 160px;
}
.txtOtherComment2 {
  position: absolute;
  top: 499px;
  left: 870px;
  height: 15px;
  width: 160px;
}
.txtOtherComment3 {
  position: absolute;
  top: 514px;
  left: 870px;
  height: 15px;
  width: 160px;
}
.txtOtherComment4 {
  position: absolute;
  top: 529px;
  left: 870px;
  height: 15px;
  width: 160px;
}
.txtOtherComment5 {
  position: absolute;
  top: 544px;
  left: 870px;
  height: 15px;
  width: 160px;
}
.txtOtherComment6 {
  position: absolute;
  top: 559px;
  left: 870px;
  height: 15px;
  width: 160px;
}
.txtOtherComment7 {
  position: absolute;
  top: 579px;
  left: 870px;
  height: 15px;
  width: 160px;
}
.txtOtherComment8 {
  position: absolute;
  top: 594px;
  left: 870px;
  height: 15px;
  width: 160px;
}
.txtOtherComment9a {
  position: absolute;
  top: 619px;
  left: 684px;
  height: 15px;
  width: 349px;
}
.txtOtherComment9b {
  position: absolute;
  top: 640px;
  left: 605px;
  height: 20px;
  width: 429px;
}
.txtOtherReasons {
  position: absolute;
  top: 595px;
  left: 95px;
}
/*# sourceMappingURL=recl-xml-triples-advantage-anejo1.component.css.map */
