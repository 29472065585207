<div class="modal-header" [ngClass]="{'warning': msgBoxTitle == 'Aviso' || msgBoxTitle == 'Warning'}">
	<div>
		<h4>
			<span>{{ msgBoxTitle }}</span>
		</h4>
	</div>
	<div *ngIf="msgBoxImgSrc">
		<img src="{{ msgBoxImgSrc }}" alt="{{ msgBoxImgAlt }}" width="{{ msgBoxImgWidth }}"
			height="{{ msgBoxImgHeight }}">
	</div>
	<div style="margin-top: -22px;">
		<button style="cursor: pointer;" class="btn btn-close" (click)="_activeModal.close(false);"></button>
	</div>
</div>

<div class="modal-body" [ngStyle]="{'background-color': bodyBackgColr}" style="word-wrap: break-word;">
	<!-- #e6e6e6 -->
	<p style="margin-bottom: 0;" [innerHTML]="msgBoxMessage | safe: 'html'">
		<!-- the message goes here -->
	</p>
	<div *ngIf="codeSearchResults?.length">
		<input type="text" id="inStrKeys" style="margin-top: -120px; padding-top: 0;" size="50"
			(keyup)="inStrSearch($event, codeSearchResults)">{{ subTotalFound }}
	</div>

	<div *ngIf="resultsView?.length">
		<table style="font-size: 80%;" class="table table-bordered table-sm rowsHover">
			<thead>
				<th style="display: none;">pKey</th>
				<th>{{engLang ? 'Code' : 'Código'}}</th>
				<th>{{engLang ? 'Description' : 'Descripción'}}</th>
				<th [ngStyle]="{ 'display': codeType=='Dx' ? '': 'none' }" class="text-center">
					{{engLang ? 'Year' : 'Año'}}</th>

				<th *ngIf="codeType=='Pc'" class="text-right">Usual</th>
				<th *ngIf="codeType=='Pc'" class="text-right">{{ engLang ? 'Expect' : 'Espera'}}</th>
				<th *ngIf="codeType=='Pc'" class="text-right">{{ engLang ? 'Coins' : 'Coaseg'}}</th>

				<th class="text-center">
					<i class="fas fa-check"></i>
				</th>
			</thead>
			<tr *ngFor="let result of resultsView | paginate: { id: 'codesSearchPging', itemsPerPage: 15, currentPage: currentPage }; let e = even; let o = odd;"
				[ngClass]="{ evenIcd10 : e, oddIcd10 : o }" tabindex="0">

				<td style="display: none;">{{ result.pKey }}</td>

				<td>{{ result.code }}
					<span *ngIf="codeType=='Pc'"> {{ result.mod }} {{ result.mod2 }} {{ result.mod3 }}
						{{ result.mod4 }}</span>
				</td>

				<td>{{ result.desc }}</td>

				<td [ngStyle]="{ 'display': (codeType=='Dx') ? '': 'none' }" class="text-center">
					{{ result.yr }}</td>

				<td [ngStyle]="{ 'display': (result.visible && codeType=='Pc') ? '': 'none' }" class="text-right">
					{{ result.usual | currency:'USD':'':'1.2-2' }}</td>
				<td [ngStyle]="{ 'display': (result.visible && codeType=='Pc') ? '': 'none' }" class="text-right">
					{{ result.xpect | currency:'USD':'':'1.2-2' }}</td>
				<td [ngStyle]="{ 'display': (result.visible && codeType=='Pc') ? '': 'none' }" class="text-right">
					{{ result.pvdo | currency:'USD':'':'1.2-2' }}</td>

				<td class="text-center">
					<input type="checkbox" (click)="onClick_SelectCode($event, result);">
				</td>

			</tr>
		</table>
	</div>

	<div *ngIf="imgSrc && imgSrc.length" class="text-center">
		<img [src]="imgSrc" class="img-fluid" />
	</div>
</div>

<div class="modal-footer d-flex-row justify-content-between" [ngClass]="{'warning': msgBoxTitle == 'Aviso' || msgBoxTitle == 'Warning'}">
	<div style="font-size: smaller;">
		{{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
	</div>
	<div class="mt-4" style="font-size: smaller;">
		<pagination-controls id="codesSearchPging" (pageChange)="currentPage = $event" directionLinks="true"
			previousLabel="" nextLabel="" autoHide="true">
		</pagination-controls>
	</div>
	<div>
		<button class="btn btn-outline-primary" (click)="_activeModal.close(returndObj ? returndObj : true);">
			{{"Ok"}}
		</button>
	</div>
</div>