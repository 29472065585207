/* src/app/shared/floating-menu/floating-menu.component.css */
.menu {
  filter: url(#shadowed-goo);
}
.menu-item,
.menu-open-button {
  background: red;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  position: absolute;
  color: white;
  text-align: center;
  line-height: 10px;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
}
.menu-glyph {
  font-size: 10px;
}
.menu-open {
  display: none;
}
.hamburger {
  width: 12px;
  height: 1px;
  background: white;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -11px;
  margin-top: 0;
  transition: transform 200ms;
}
.hamburger-1 {
  transform: translate3d(5.5px, -4px, 0);
}
.hamburger-2 {
  transform: translate3d(5.5px, 0, 0);
}
.hamburger-3 {
  transform: translate3d(5.5px, 4px, 0);
}
.menu-open:checked + .menu-open-button .hamburger-1 {
  transform: translate3d(5.5px, 0, 0) rotate(45deg);
}
.menu-open:checked + .menu-open-button .hamburger-2 {
  transform: translate3d(0, 0, 0) scale(0.1, 1);
}
.menu-open:checked + .menu-open-button .hamburger-3 {
  transform: translate3d(5.5px, 0, 0) rotate(-45deg);
}
.menu {
  position: absolute;
  width: 650px;
  box-sizing: border-box;
  font-size: 20px;
}
.menu-item:hover {
  background: red;
  color: black;
}
.menu-item:nth-child(3) {
  transition-duration: 180ms;
}
.menu-item:nth-child(4) {
  transition-duration: 180ms;
}
.menu-item:nth-child(5) {
  transition-duration: 180ms;
}
.menu-item:nth-child(6) {
  transition-duration: 180ms;
}
.menu-open-button {
  z-index: 2;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 400ms;
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
}
.menu-open-button:hover {
  transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}
.menu-open:checked + .menu-open-button {
  transition-timing-function: linear;
  transition-duration: 200ms;
  transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}
.menu-open:checked ~ .menu-item {
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.menu-open:checked ~ .menu-item:nth-child(3) {
  transition-duration: 190ms;
  transform: translate3d(110px, 0, 0);
}
.menu-open:checked ~ .menu-item:nth-child(4) {
  transition-duration: 290ms;
  transform: translate3d(220px, 0, 0);
}
.menu-open:checked ~ .menu-item:nth-child(5) {
  transition-duration: 390ms;
  transform: translate3d(330px, 0, 0);
}
.menu-open:checked ~ .menu-item:nth-child(6) {
  transition-duration: 490ms;
  transform: translate3d(440px, 0, 0);
}
.centerMe1 {
  margin-left: 0;
  margin-top: 0;
}
.centerMe2 {
  margin-left: 0;
}
.centerMe3 {
  margin-left: 0;
  margin-top: 0;
}
.centerMe4 {
  margin-left: 0;
}
button {
  border: none;
}
/*# sourceMappingURL=floating-menu.component.css.map */
