/* src/app/shared/dwt.component.css */
#dwtMenu,
#dwtcontrolContainer,
#dwtClose {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 10px;
  padding-bottom: 7px;
}
i {
  cursor: pointer;
}
/*# sourceMappingURL=dwt.component.css.map */
