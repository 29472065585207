/* src/app/login/login.component.css */
input.placeholder {
  text-align: center;
}
::-webkit-input-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
  text-align: center;
}
:-moz-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
  text-align: center;
}
::-moz-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
}
:-ms-input-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}
/*# sourceMappingURL=login.component.css.map */
