/* src/app/reports/rpt-aging/rpt-aging.component.css */
.font10 {
  font-size: 10px;
}
.rtalign {
  text-align: right;
}
.collapsedRow {
  display: none;
}
.clmTable {
  background-color: #f5f7f8;
}
.detTable {
  background-color: #e2effa;
}
.payTable {
  background-color: #e0f1e0;
}
.padTable {
  background-color: #fae1e1;
}
.insRow:hover,
.clmRow:hover,
.detRow:hover,
.payRow:hover,
.padRow:hover {
  background-color: #e6e6e6 !important;
  cursor: pointer;
}
.clmRow,
.detRow,
.payRow,
.padRow {
  height: 25px;
}
.expandedRw {
  font-style: italic;
  background-color: rgb(241, 202, 162);
}
.link-button {
  background: none;
  border: none;
  padding: 0;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
}
.link-button:hover {
  text-decoration: none;
}
.link-button:focus {
  outline: none;
}
/*# sourceMappingURL=rpt-aging.component.css.map */
