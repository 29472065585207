/* src/app/record/record.component.css */
input[type=text] {
  text-transform: uppercase;
}
input.no-uppercase {
  text-transform: none;
}
img[src=""] {
  display: none;
}
.pseudoLink {
  cursor: pointer;
  color: blue;
}
#q837Btn {
  position: fixed;
  right: 20px;
  border-radius: 50%;
  background-color: #69af39;
  color: white;
}
#q837Tx {
  position: fixed;
  right: 20px;
  border-radius: 50%;
  background-color: #b853a8;
  color: white;
}
.material-symbols-outlined {
  font-variation-settings:
    "FILL" 0,
    "wght" 400,
    "GRAD" 0,
    "opsz" 24;
}
#q837Rx {
  position: fixed;
  right: 20px;
  border-radius: 50%;
  background-color: rgba(189, 162, 106, 0.918);
  color: white;
}
#back2srchBtn {
  position: fixed;
  right: 20px;
  border-radius: 50%;
  background-color: #c0779b;
  color: white;
  display: none;
}
#scrollUpBtn,
#scrollDnBtn {
  position: fixed;
  right: 20px;
  border-radius: 50%;
  background-color: #7aaad4;
  color: white;
}
#scrollUpBtn::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -6px;
  width: 100%;
  height: .5px;
  background-color: lightgray;
}
#scrollDnBtn::before {
  content: "";
  position: absolute;
  left: 0;
  top: -6px;
  width: 100%;
  height: .5px;
  background-color: lightgray;
}
#scrollBtn:hover {
  background-color: #555;
}
th {
  font-size: 80%;
}
td {
  font-size: 75%;
  white-space: pre;
}
.sectionTit {
  font-size: 90%;
  font-style: italic;
}
.recordTable tr:focus {
  background-color: rgb(184, 176, 176);
  outline: 0;
}
.recordTable tr:hover {
  background-color: #ffff99;
}
.caseTable tr:focus {
  background-color: rgb(184, 176, 176);
  outline: 0;
}
.caseTable tr:hover {
  background-color: #add9df;
}
.detTable tr:focus {
  background-color: rgb(250, 248, 248);
  outline: 0;
}
.detTable tr:hover {
  background-color: #63ee86;
}
.evenRecSrch {
  background-color: #faf2eb;
}
.oddRecSrch {
  background-color: #f2ebfa;
}
.evenCasSrch {
  background-color: #ebf2ea;
}
.oddCasSrch {
  background-color: #d4dca9;
}
.oddDetSrch {
  background-color: #c8d6ca;
}
.evenDetSrch {
  background-color: #e7d5de;
}
.evenDx {
  background-color: #c4b5c4;
}
.oddDx {
  background-color: #d3cacc;
}
.even567 {
  background-color: #ebf2ea;
}
.odd567 {
  background-color: #d4dca9;
}
.evenImgs {
  background-color: white;
}
.oddImgs {
  background-color: rgb(252, 255, 255);
}
.selectedCase {
  background-color: lightyellow;
}
.red {
  color: red;
}
.green {
  color: green;
}
.redBkg {
  background-color: #f38989;
}
.edited {
  color: rgb(241, 178, 82);
}
.label2bottom label {
  margin: 0;
  padding: 0;
  position: initial;
  top: 0%;
  left: 0%;
  -ms-transform: translate(0%, 70%);
  transform: translate(0%, 70%);
}
.menuHt {
  line-height: 30px;
}
.cursorPtr {
  cursor: pointer;
}
.currency {
  width: 100%;
  text-align: right;
  display: inline-block;
}
.font10 {
  font-size: 10px;
}
.ital {
  font-style: italic;
}
.ltjustify {
  width: 100%;
  display: inline-block;
}
.table-bordered td {
  border: none;
  border-right: solid 1px #ccc;
}
#paymntModesDrpDn {
  min-width: 65px;
}
.dropdown-menu a:hover {
  color: #fff;
  background-color: #b91773;
  border-color: #fff;
}
.scrollable-menu {
  height: 400%;
  overflow-x: hidden;
}
*.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}
i,
.ptr,
#scanLic,
#scanID1,
#scanID2,
#scanRec,
#scanCas {
  cursor: pointer;
}
.rowBox {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.nogap {
  gap: unset;
}
.flexRowColBox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}
input[name=modifier] {
  min-width: 20px;
  max-width: 28px;
  width: 28px;
  text-align: center;
}
td.w20,
th.w20,
td.w20dx,
th.w20dx {
  width: 20px;
  max-width: 20px;
  min-width: 20px;
}
td.w27,
th.w27 {
  width: 27px;
  max-width: 27px;
  min-width: 27px;
}
td.w29,
th.w29 {
  width: 29px;
  max-width: 29px;
  min-width: 29px;
}
td.w30,
th.w30 {
  width: 30px;
  max-width: 30px;
  min-width: 30px;
}
td.w40,
th.w40 {
  width: 40px;
  max-width: 40px;
  min-width: 40px;
}
td.w38hx,
th.w38hx {
  width: 38px;
  max-width: 38px;
  min-width: 38px;
  font-size: 12px;
  margin: auto;
}
td.w50,
th.w50,
td.w50hx,
th.w50hx {
  width: 50px;
  max-width: 50px;
  min-width: 50px;
}
td.w54 {
  width: 54px;
  max-width: 54px;
}
th.w54 {
  min-width: 54px;
}
td.w56 {
  width: 56px;
  min-width: 56px;
  max-width: 56px;
}
th.w56 {
  min-width: 56px;
}
td.w60,
th.w60,
td.w60dx,
th.w60dx {
  width: 60px;
  max-width: 60px;
  min-width: 60px;
}
td.w65,
th.w65,
td.w65dx,
th.w65dx,
td.w65pc,
th.w65pc,
td.w65py,
th.w65py {
  width: 65px;
  max-width: 65px;
  min-width: 65px;
}
td.w50hx,
th.w50hx {
  width: 50px;
  max-width: 50px;
  min-width: 50px;
}
td.w70,
th.w70 {
  width: 70px;
  max-width: 70px;
  min-width: 70px;
}
td.w74,
th.w74 {
  width: 74px;
  max-width: 74px;
  min-width: 74px;
}
td.w77 {
  width: 77px;
  max-width: 77px;
}
th.w77 {
  min-width: 77px;
}
td.w80,
th.w80 {
  width: 80px;
  max-width: 80px;
  min-width: 80px;
}
td.w81,
th.w81 {
  width: 81px;
  max-width: 81px;
  min-width: 81px;
}
td.w86,
th.w86 {
  width: 86px;
  max-width: 86px;
  min-width: 86px;
}
th.w99,
td.w99 {
  width: 99px;
  max-width: 99px;
}
td.w96 {
  width: 70px;
  max-width: 70px;
  min-width: 70px;
  padding-left: 0;
  padding-right: 8px;
  text-align: center;
}
th.w102,
td.w102 {
  width: 102px;
  max-width: 102px;
  min-width: 102px;
}
td.w111,
th.w111 {
  width: 111px;
  max-width: 111px;
  min-width: 111px;
}
td.w366,
th.w366 {
  width: 366px;
  max-width: 366px;
  min-width: 366px;
}
td.w128,
th.w128 {
  width: 128px;
  max-width: 128px;
  min-width: 128px;
}
td.w136 {
  width: 136px;
  max-width: 136px;
}
th.w136 {
  min-width: 136px;
}
.w89 {
  width: 89px;
  max-width: 89px;
  min-width: 89px;
}
td.w157,
th.w157 {
  width: 157px;
  max-width: 157px;
  min-width: 157px;
}
td.w160 {
  width: 160px;
  max-width: 160px;
}
th.w160 {
  min-width: 160px;
}
td.w178,
th.w178 {
  width: 178px;
  max-width: 178px;
  min-width: 178px;
}
td.w120,
th.w120 {
  width: 110px;
  max-width: 110px;
  min-width: 110px;
}
th.w183,
td.w183 {
  width: 183px;
  max-width: 183px;
  min-width: 183px;
}
td.w200 {
  width: 200px;
  max-width: 200px;
}
th.w200 {
  min-width: 200px;
}
td.w241 {
  width: 241px;
  max-width: 241px;
}
th.w241 {
  min-width: 241px;
}
td.w435,
th.w435 {
  width: 452px;
  max-width: 452px;
  min-width: 452px;
}
td.w534 {
  width: 534px;
  max-width: 534px;
}
th.w534 {
  min-width: 534px;
}
.font75 {
  font-size: 75%;
}
.font12 {
  font-size: 12px;
}
.font85 {
  font-size: 85%;
  margin: 3px 2px;
  padding-left: 3px;
  padding-right: 0;
}
.font90 {
  font-size: 90%;
}
.w60,
td.w86,
td.w120,
td.w178 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#hTabl {
  border-collapse: collapse;
}
.blueColor {
  color: #0000FF;
}
.rtalign {
  text-align: right;
}
.ltalign {
  text-align: left;
}
.font10 {
  font-size: 10px;
}
.font11 {
  font-size: 11px;
}
.clmRow:hover {
  background-color: #e6e6e6 !important;
  cursor: pointer;
}
.error-bkg {
  font-style: italic;
  background-color: rgb(241, 202, 162);
}
.data-bkg {
  background-color: rgba(230, 223, 165, 0.479);
  font-size: 13px;
}
.collapsedRow {
  display: none;
}
.errors {
  color: red;
}
.mouse-pointer {
  cursor: pointer;
}
.status-width {
  width: 20px;
}
.no-borders {
  border: none;
  border-width: 0;
  border-style: none;
}
@media only screen and (max-width: 1024px) {
  .hide {
    display: none;
  }
  .disappear {
    display: none;
  }
  .font90 {
    font-size: 70%;
  }
  #hTabl,
  #hTabl tbody,
  #hTabl th,
  #hTabl td,
  #hTabl tr {
    display: block;
  }
  #hTabl tr {
    border: 1px solid #ccc;
  }
  #hTabl td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 75%;
  }
  #hTabl td:before {
    position: absolute;
    top: 1px;
    left: 2px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
  }
  td.hTablTd:nth-of-type(1):before {
    content: "Fecha: ";
    font-style: italic;
    font-weight: bolder;
  }
  td.hTablTd:nth-of-type(2):before {
    content: "Prov: ";
    font-style: italic;
    font-weight: normal;
  }
  td.hTablTd:nth-of-type(3):before {
    content: "Ins: ";
    font-style: italic;
    font-weight: normal;
  }
  td.hTablTd:nth-of-type(4):before {
    content: "Bal 1: ";
    font-style: italic;
    font-weight: normal;
  }
  td.hTablTd:nth-of-type(5):before {
    content: "Bal 2: ";
    font-style: italic;
    font-weight: normal;
  }
  td.hTablTd:nth-of-type(6):before {
    content: "Bal P: ";
    font-style: italic;
    font-weight: normal;
  }
  td.hTablTd:nth-of-type(7):before {
    content: "Caso: ";
    font-style: italic;
    font-weight: normal;
  }
  td.w50,
  th.w50,
  td.w60,
  th.w60,
  td.w65,
  th.w65,
  td.w80,
  th.w80 {
    width: 150px;
    max-width: 150px;
    min-width: 150px;
  }
  td.w435,
  th.w435 {
    width: 92px;
    max-width: 92px;
    min-width: 92px;
  }
  td.w40hx,
  th.w40hx {
    width: 40px;
    max-width: 40px;
    min-width: 40px;
    margin: auto;
  }
  td.w96,
  th.w96 {
    width: 30px;
    max-width: 30px;
    min-width: 30px;
    padding: 4px;
  }
  .xxsmall {
    font-size: 8px;
  }
  .currency {
    width: 70px;
    text-align: right;
    display: inline-block;
  }
  .ltjustify {
    width: 60%;
    text-align: left;
    display: inline-block;
    padding: 1px 2px;
  }
}
@media only screen and (max-width: 1200px) {
  td.w40,
  th.w40 {
    width: 35px;
    max-width: 35px;
    min-width: 35px;
  }
  td.w86,
  th.w86 {
    width: 65px;
    max-width: 65px;
    min-width: 65px;
  }
  td.w96 {
    width: 70px;
    max-width: 70px;
    min-width: 70px;
  }
  td.w128,
  th.w128 {
    width: 108px;
    max-width: 108px;
    min-width: 108px;
  }
  td.w178,
  th.w178 {
    width: 85px;
    max-width: 85px;
    min-width: 85px;
  }
  td.w120,
  th.w120 {
    width: 90px;
    max-width: 90px;
    min-width: 90px;
  }
  .font90 {
    font-size: 80%;
  }
  @media only screen and (max-width: 576px) {
    .font576 {
      font-size: 75%;
    }
    .flexRowColBox {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
/*# sourceMappingURL=record.component.css.map */
