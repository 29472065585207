import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-floating-menu',
    templateUrl: './floating-menu.component.html',
    styleUrls: ['./floating-menu.component.css'],
    standalone: false
})
export class FloatingMenuComponent {

  @Input() engLang: boolean;
  @Input() mnuNo: string;
  @Input() icon1: string;
  @Input() ttEn1: string;
  @Input() ttEs1: string;
  @Input() innHtml1: string = '';
  @Input() icon2: string;
  @Input() ttEn2: string;
  @Input() ttEs2: string;
  @Input() innHtml2: string = '';
  @Input() icon3: string;
  @Input() ttEn3: string;
  @Input() ttEs3: string;
  @Input() innHtml3: string = '';
  @Input() icon4: string;
  @Input() ttEn4: string;
  @Input() ttEs4: string;
  @Input() innHtml4: string = '';

  @Output() mnu1ClikdEvnt: EventEmitter<string> = new EventEmitter();
  @Output() mnu2ClikdEvnt: EventEmitter<string> = new EventEmitter();
  @Output() mnu3ClikdEvnt: EventEmitter<string> = new EventEmitter();
  @Output() mnu4ClikdEvnt: EventEmitter<string> = new EventEmitter();
  @Output() closeEvnt: EventEmitter<boolean> = new EventEmitter;

  bypassInitialOpenMnusClick: boolean;

  ngAfterViewInit(): void {
    // Code to be executed after the view has been fully initialized
    // console.log('Component view is fully rendered');

    this.bypassInitialOpenMnusClick = true; // To avoid closing main menu after initial open
    setTimeout(() => {  // To avoid 'ExpressionChangedAfterItHasBeenCheckedError'
      const ckbx: HTMLInputElement = <HTMLInputElement>document.getElementById('menu-open');
      ckbx?.click();
    });
  }

  onChange_mainMnu(event) {
    if (!this.bypassInitialOpenMnusClick) { // Click it to close
      const ckbx: HTMLInputElement = event?.target;
      if (!ckbx?.checked) {
        this.closeEvnt.emit(true);
      }
    }
    this.bypassInitialOpenMnusClick = false;  // To be able to close this menu on this instance
  }

  onClick_mnu1(event) {
    this.mnu1ClikdEvnt.emit(event);
    const mmnu: HTMLInputElement = <HTMLInputElement>document.getElementById('menu-open');
    if (mmnu) {
      this.closeEvnt.emit(true);
      mmnu.click(); // Close the menu
    }
  }

  onClick_mnu2(event) {
    this.mnu2ClikdEvnt.emit(event);
    const mmnu: HTMLInputElement = <HTMLInputElement>document.getElementById('menu-open');
    if (mmnu) {
      this.closeEvnt.emit(true);
      mmnu.click(); // Close the menu
    }
  }

  onClick_mnu3(event) {
    this.mnu3ClikdEvnt.emit(event);
    const mmnu: HTMLInputElement = <HTMLInputElement>document.getElementById('menu-open');
    if (mmnu) {
      this.closeEvnt.emit(true);
      mmnu.click(); // Close the menu
    }
  }

  onClick_mnu4(event) {
    this.mnu4ClikdEvnt.emit(event);
    const mmnu: HTMLInputElement = <HTMLInputElement>document.getElementById('menu-open');
    if (mmnu) {
      this.closeEvnt.emit(true);
      mmnu.click(); // Close the menu
    }
  }

}
