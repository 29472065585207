import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { IReclaims, IReclaimsDet } from 'src/app/shared/interfaces/reclaims';
import { CkRegisterService } from '../ck-register.service';
import { AppToastsService } from 'src/app/shared/app-toasts/app-toasts.service';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-recl-xml-triples-advantage-anejo1',
    imports: [FormsModule],
    templateUrl: './recl-xml-triples-advantage-anejo1.component.html',
    styleUrls: ['./recl-xml-triples-advantage-anejo1.component.css']
})
export class ReclXmlTriplesAdvantageAnejo1Component implements OnDestroy {

  @Input() reclaims: IReclaims[];
  @Input() reclaimsDet: IReclaimsDet[];
  @Input() idxSeld: number;
  @Input() reclFormImg64Seled: string;
  @Input() reclLineReasonEntered: any;
  @Input() clrData: boolean = false;  // Set true after deleteing
  @Input() print: boolean = false;

  @Output() need2Save = new EventEmitter<string>();

  currIndx: number = 0;
  e: any = {
    opt_plan: '',
    optAHM: '',
    optSSS: '',
    txtProvNmSSS: '',
    txtProvNoSSS: '',
    txtDateSSS: '',
    txtPatNmSSS: '',
    txtContrSSS: '',
    txtFrom1: '',
    txtTo1: '',
    txtCpt1: '',
    txtBal1: '',
    txtRazon1: '',
    txtPayDt1: '',
    txtCkNo1: '',
    txtPayed1: '',
    txtComment1: '',
    txtFrom2: '',
    txtTo2: '',
    txtCpt2: '',
    txtBal2: '',
    txtRazon2: '',
    txtPayDt2: '',
    txtCkNo2: '',
    txtPayed2: '',
    txtComment2: '',
    txtFrom3: '',
    txtTo3: '',
    txtCpt3: '',
    txtBal3: '',
    txtRazon3: '',
    txtPayDt3: '',
    txtCkNo3: '',
    txtPayed3: '',
    txtComment3: '',
    txtFrom4: '',
    txtTo4: '',
    txtCpt4: '',
    txtBal4: '',
    txtRazon4: '',
    txtPayDt4: '',
    txtCkNo4: '',
    txtPayed4: '',
    txtComment4: '',
    txtFrom5: '',
    txtTo5: '',
    txtCpt5: '',
    txtBal5: '',
    txtRazon5: '',
    txtPayDt5: '',
    txtCkNo5: '',
    txtPayed5: '',
    txtComment5: '',
    txtFrom6: '',
    txtTo6: '',
    txtCpt6: '',
    txtBal6: '',
    txtRazon6: '',
    txtPayDt6: '',
    txtCkNo6: '',
    txtPayed6: '',
    txtComment6: '',
    txtFrom7: '',
    txtTo7: '',
    txtCpt7: '',
    txtBal7: '',
    txtRazon7: '',
    txtPayDt7: '',
    txtCkNo7: '',
    txtPayed7: '',
    txtComment7: '',
    txtFrom8: '',
    txtTo8: '',
    txtCpt8: '',
    txtBal8: '',
    txtRazon8: '',
    txtPayDt8: '',
    txtCkNo8: '',
    txtPayed8: '',
    txtComment8: '',
    txtFrom9: '',
    txtTo9: '',
    txtCpt9: '',
    txtBal9: '',
    txtRazon9: '',
    txtPayDt9: '',
    txtCkNo9: '',
    txtPayed9: '',
    txtComment9: '',
    txtFrom10: '',
    txtTo10: '',
    txtCpt10: '',
    txtBal10: '',
    txtRazon10: '',
    txtPayDt10: '',
    txtCkNo10: '',
    txtPayed10: '',
    txtComment10: '',
    txtOtherReason1: '',
    txtOtherReason2: '',
    txtOtherReason3: '',
    txtOtherReason4: '',
    txtOtherReason5: '',
    txtOtherReason6: '',
    txtOtherComment1: '',
    txtOtherComment2: '',
    txtOtherComment3: '',
    txtOtherComment4: '',
    txtOtherComment5: '',
    txtOtherComment6: '',
    txtOtherComment7: '',
    txtOtherComment8: '',
    txtOtherComment9a: '',
    txtOtherComment9b: '',
    txtOtherReasons: ''
  }

  constructor(private _ckRegisterService: CkRegisterService,
    private _appToastsService: AppToastsService
  ) { }

  ngOnInit(): void {
    this.setReclaimDefaults();
    if (this.reclaims[this.idxSeld].reclXml.match(/>\w+</g)) { // If xml has data
      this.decodeXmlRecl();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.createReclaimXml_developmentHelperRtn();  // <-- Use to create reclXml col value for this form

    // Check if @Input() reclLineReasonEntered & others have changed
    if (changes.reclLineReasonEntered && this.reclLineReasonEntered) {
      console.log(this.reclLineReasonEntered);
      if (this.reclLineReasonEntered.reason) {
        if (this.e.txtOtherReasons) {
          this.e.txtOtherReasons += ', ';
        }
        this.e.txtOtherReasons += (this.reclLineReasonEntered.cpt
          + ' ' + this.reclLineReasonEntered.mods
          + ' ' + this.reclLineReasonEntered.from
          + ' ' + this.reclLineReasonEntered.reason.trim());
      }

      this.currIndx++;
      switch (this.currIndx) {
        case 1:
          this.e.txtFrom1 = this.reclLineReasonEntered.from;
          this.e.txtTo1 = this.reclLineReasonEntered.to;
          this.e.txtCpt1 = (this.reclLineReasonEntered.cpt + ' ' + this.reclLineReasonEntered.mods).trim();
          this.e.txtPayed1 = this.formatCurrency(+this.reclLineReasonEntered.payed);  // Payment per cpt
          this.e.txtPayDt1 = this.reclLineReasonEntered.payDt;
          this.e.txtCkNo1 = this.reclLineReasonEntered.ckNo;
          this.e.txtBal1 = this.formatCurrency(+this.reclLineReasonEntered.bal);
          // this.e.txtRazon1 = this.reclLineReasonEntered.reason;
          break;
        case 2:
          this.e.txtFrom2 = this.reclLineReasonEntered.from;
          this.e.txtTo2 = this.reclLineReasonEntered.to;
          this.e.txtCpt2 = this.reclLineReasonEntered.cpt;
          this.e.txtPayed2 = this.formatCurrency(+this.reclLineReasonEntered.payed);  // Payment per cpt
          this.e.txtPayDt2 = this.reclLineReasonEntered.payDt;
          this.e.txtCkNo2 = this.reclLineReasonEntered.ckNo;
          this.e.txtBal2 = this.formatCurrency(+this.reclLineReasonEntered.bal);
          // this.e.txtRazon2 = this.reclLineReasonEntered.reason;
          break;
        case 3:
          this.e.txtFrom3 = this.reclLineReasonEntered.from;
          this.e.txtTo3 = this.reclLineReasonEntered.to;
          this.e.txtCpt3 = this.reclLineReasonEntered.cpt;
          this.e.txtPayed3 = this.formatCurrency(+this.reclLineReasonEntered.payed);  // Payment per cpt
          this.e.txtPayDt3 = this.reclLineReasonEntered.payDt;
          this.e.txtCkNo3 = this.reclLineReasonEntered.ckNo;
          this.e.txtBal3 = this.formatCurrency(+this.reclLineReasonEntered.bal);
          // this.e.txtRazon3 = this.reclLineReasonEntered.reason;
          break;
        case 4:
          this.e.txtFrom4 = this.reclLineReasonEntered.from;
          this.e.txtTo4 = this.reclLineReasonEntered.to;
          this.e.txtCpt4 = this.reclLineReasonEntered.cpt;
          this.e.txtPayed4 = this.formatCurrency(+this.reclLineReasonEntered.payed);  // Payment per cpt
          this.e.txtPayDt4 = this.reclLineReasonEntered.payDt;
          this.e.txtCkNo4 = this.reclLineReasonEntered.ckNo;
          this.e.txtBal4 = this.formatCurrency(+this.reclLineReasonEntered.bal);
          // this.e.txtRazon4 = this.reclLineReasonEntered.reason;
          break;
        case 5:
          this.e.txtFrom5 = this.reclLineReasonEntered.from;
          this.e.txtTo5 = this.reclLineReasonEntered.to;
          this.e.txtCpt5 = this.reclLineReasonEntered.cpt;
          this.e.txtPayed5 = this.formatCurrency(+this.reclLineReasonEntered.payed);  // Payment per cpt
          this.e.txtPayDt5 = this.reclLineReasonEntered.payDt;
          this.e.txtCkNo5 = this.reclLineReasonEntered.ckNo;
          this.e.txtBal5 = this.formatCurrency(+this.reclLineReasonEntered.bal);
          // this.e.txtRazon5 = this.reclLineReasonEntered.reason;
          break;
        case 6:
          this.e.txtFrom6 = this.reclLineReasonEntered.from;
          this.e.txtTo6 = this.reclLineReasonEntered.to;
          this.e.txtCpt6 = this.reclLineReasonEntered.cpt;
          this.e.txtPayed6 = this.formatCurrency(+this.reclLineReasonEntered.payed);  // Payment per cpt
          this.e.txtPayDt6 = this.reclLineReasonEntered.payDt;
          this.e.txtCkNo6 = this.reclLineReasonEntered.ckNo;
          this.e.txtBal6 = this.formatCurrency(+this.reclLineReasonEntered.bal);
          // this.e.txtRazon6 = this.reclLineReasonEntered.reason;
          break;
        case 7:
          this.e.txtFrom7 = this.reclLineReasonEntered.from;
          this.e.txtTo7 = this.reclLineReasonEntered.to;
          this.e.txtCpt7 = this.reclLineReasonEntered.cpt;
          this.e.txtPayed7 = this.formatCurrency(+this.reclLineReasonEntered.payed);  // Payment per cpt
          this.e.txtPayDt7 = this.reclLineReasonEntered.payDt;
          this.e.txtCkNo7 = this.reclLineReasonEntered.ckNo;
          this.e.txtBal7 = this.formatCurrency(+this.reclLineReasonEntered.bal);
          // this.e.txtRazon7 = this.reclLineReasonEntered.reason;
          break;
        case 8:
          this.e.txtFrom8 = this.reclLineReasonEntered.from;
          this.e.txtTo8 = this.reclLineReasonEntered.to;
          this.e.txtCpt8 = this.reclLineReasonEntered.cpt;
          this.e.txtPayed8 = this.formatCurrency(+this.reclLineReasonEntered.payed);  // Payment per cpt
          this.e.txtPayDt8 = this.reclLineReasonEntered.payDt;
          this.e.txtCkNo8 = this.reclLineReasonEntered.ckNo;
          this.e.txtBal8 = this.formatCurrency(+this.reclLineReasonEntered.bal);
          // this.e.txtRazon8 = this.reclLineReasonEntered.reason;
          break;
        case 9:
          this.e.txtFrom9 = this.reclLineReasonEntered.from;
          this.e.txtTo9 = this.reclLineReasonEntered.to;
          this.e.txtCpt9 = this.reclLineReasonEntered.cpt;
          this.e.txtPayed9 = this.formatCurrency(+this.reclLineReasonEntered.payed);  // Payment per cpt
          this.e.txtPayDt9 = this.reclLineReasonEntered.payDt;
          this.e.txtCkNo9 = this.reclLineReasonEntered.ckNo;
          this.e.txtBal9 = this.formatCurrency(+this.reclLineReasonEntered.bal);
          // this.e.txtRazon9 = this.reclLineReasonEntered.reason;
          break;
        case 10:
          this.e.txtFrom10 = this.reclLineReasonEntered.from;
          this.e.txtTo10 = this.reclLineReasonEntered.to;
          this.e.txtCpt10 = this.reclLineReasonEntered.cpt;
          this.e.txtPayed10 = this.formatCurrency(+this.reclLineReasonEntered.payed);  // Payment per cpt
          this.e.txtPayDt10 = this.reclLineReasonEntered.payDt;
          this.e.txtCkNo10 = this.reclLineReasonEntered.ckNo;
          this.e.txtBal10 = this.formatCurrency(+this.reclLineReasonEntered.bal);
          // this.e.txtRazon10 = this.reclLineReasonEntered.reason;
          break;
        default:
      }
    }
    if (changes.clrData?.currentValue) {
      this.clearData();
    }
    if (changes.print?.currentValue) {
      this.printReclaim();
    }
  }

  formatCurrency(amount, symb = null, decimals = 2, decimalSeparator = ".", thousandsSeparator = ",") {
    const formattedAmount = amount.toFixed(decimals);
    const parts = formattedAmount.split(".");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
    const decimalPart = parts[1];
    return `${symb ? "$" : ""}${integerPart}${decimalSeparator}${decimalPart}`;
  }

  setReclaimDefaults(): void {
    const r: number = this.idxSeld;

    this.e.txtProvNmSSS = this.reclaims[r].reclProv;
    this.e.txtProvNoSSS = this.reclaims[r].reclNPI;
    this.e.txtDateSSS = moment(new Date()).format('MM/DD/YY');
    this.e.txtPatNmSSS = this.reclaims[r].reclPatNm;
    this.e.txtContrSSS = +this.reclaims[r].reclPS == 1 ? this.reclaims[r].reclContr1 : this.reclaims[r].reclContr2;
  }

  decodeXmlRecl(): void {
    const r: number = this.idxSeld;

    if (r >= 0 && this.reclaims && (this.reclaims[r].reclXml || this.reclaimsDet[r].reclDetXml)) {
      let parser = new DOMParser();
      let xmlDoc;
      if (this.reclaims[r].reclXml) {
        xmlDoc = parser.parseFromString(this.reclaims[0].reclXml, "text/xml");
      } else if (this.reclaimsDet[r].reclDetXml) {  // UNTESTED
        xmlDoc = parser.parseFromString(this.reclaimsDet[r].reclDetXml, "text/xml");
      } else {
        return;
      }

      console.log('xmlDoc', xmlDoc);

      for (let i = 0; i < xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes.length; i++) {
        let fullNod = xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes[i];
        let nod = xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes[i].nodeName;

        if (nod.match(/txt\w+/g) || nod.match(/chk\w+/g) || nod.match(/opt\w+/g)) {
          let val = fullNod.textContent;

          switch (nod) {
            case 'optAHM':
              if (+val) {
                this.e.opt_plan = 'AHM';
              }
              break;
            case 'optSSS':
              if (+val) {
                this.e.opt_plan = 'SSS'
              }
              break;
            case 'optTripleS':
              if (val == 'True') {
                this.e.opt_plan = 'SSS'
              }
              break;
            case 'txtCommentSSS1':  // Translation from previous version
              this.e.txtComment1 = val.replace(/^%/, '');
              break
            case 'txtCommentSSS2':  // Translation from previous version
              this.e.txtComment2 = val.replace(/^%/, '');
              break
            case 'txtCommentSSS3':  // Translation from previous version
              this.e.txtComment3 = val.replace(/^%/, '');
              break
            case 'txtCommentSSS4':  // Translation from previous version
              this.e.txtComment4 = val.replace(/^%/, '');
              break
            case 'txtCommentSSS5':  // Translation from previous version
              this.e.txtComment5 = val.replace(/^%/, '');
              break
            case 'txtCommentSSS6':  // Translation from previous version
              this.e.txtComment6 = val.replace(/^%/, '');
              break
            case 'txtCommentSSS7':  // Translation from previous version
              this.e.txtComment7 = val.replace(/^%/, '');
              break
            case 'txtCommentSSS8':  // Translation from previous version
              this.e.txtComment8 = val.replace(/^%/, '');
              break
            case 'txtCommentSSS9':  // Translation from previous version
              this.e.txtComment9 = val.replace(/^%/, '');
              break
            default:
              if (nod.match(/txt\w+/g)) {
                this.e[nod] = val.replace(/^%/, ''); // Remove initial % used in previous version
              }
              break;
          }
        }
      }
    }
  }

  ngOnDestroy() {
    this.currIndx = 0;
    this.e = undefined;
  }

  clearData(): void { // Clears all elements with default data.
    Object.keys(this.e).forEach(key => this.e[key] = '');
    this.setReclaimDefaults();
  }

  onChange_need2Save(): void {
    this.need2Save.emit('');
  }

  printReclaim(): void {
    let data: any = {
      "e": this.e,
    };
    let bdy = {
      "template":
      {
        "name": "/reclaimForms/sss_advantage_anejo1/sss_advantage_anejo1-html"
      }, "data": data,
      "options": { "reports": { "save": true } }
    };

    this._ckRegisterService.postRenderJsRpt(bdy).subscribe({
      next: blob => { this.backFromPrintRpt(blob) },
      error: (error: any) => { this.backFromServerError(error) }
    });
  }

  backFromPrintRpt(blob: Blob): void {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      let iframe = "<iframe width='100%' height='100%' src='" + reader.result.toString() + "'></iframe>"
      let win = window.open();
      win.document.open();
      win.document.write(iframe);
      win.document.close();
    };
  }

  backFromServerError(error): void {
    this._appToastsService.updateDeadCenter(false); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
    this._appToastsService.show(error.message, { header: 'Error preparing report * Error preparando reporte', autohide: false, error: true });
  }

  createReclaimXml_developmentHelperRtn(): void {
    let reclXml: string = '<ReclaimFormFields>';
    const reclFormDiv = document.getElementById('ReclaimFormFields');
    const txts = reclFormDiv.querySelectorAll('input');
    txts.forEach(e => {
      reclXml += '<' + e.id + '></' + e.id + '>';
    });
    reclXml += '</ReclaimFormFields>';
    console.log('*** reclXml ***');
    console.log(reclXml);
  }

}
