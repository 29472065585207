<div style="position: relative;">
    <img [src]="reclFormImg64Seled" alt="Form Image" width="100%" height="100%">

    <div class="form-check-input opt0_1" ngbTooltip="(1) Plan de Salud del Gobierno">
        <input type="radio" id="opt0_1" name="plan" (change)="onChange_need2Save($event)" [(ngModel)]="e.opt0_plan"
            value="1">
    </div>

    <div class="form-check-input opt0_2" ngbTooltip="(2) Triple-S Salud">
        <input type="radio" id="opt0_2" name="plan" (change)="onChange_need2Save($event)" [(ngModel)]="e.opt0_plan"
            value="2">
    </div>

    <div class="form-check-input opt0_3" ngbTooltip="(5) BlueCard Program">
        <input type="radio" id="opt0_3" name="plan" (change)="onChange_need2Save($event)" [(ngModel)]="e.opt0_plan"
            value="3">
    </div>

    <div class="form-check-input opt0_4" ngbTooltip="(12) BCBS FEP">
        <input type="radio" id="opt0_4" name="plan" (change)="onChange_need2Save($event)" [(ngModel)]="e.opt0_plan"
            value="4">
    </div>

    <div class="input-group txt1_1"> <!-- ICN -->
        <input type="text" id="txt1_1_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_1_1">
        <input type="text" id="txt1_1_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_1_2">
        <input type="text" id="txt1_1_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_1_3">
        <input type="text" id="txt1_1_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_1_4">
        <input type="text" id="txt1_1_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_1_5">
        <input type="text" id="txt1_1_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_1_6">
        <input type="text" id="txt1_1_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_1_7">
        <input type="text" id="txt1_1_8" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_1_8">
        <input type="text" id="txt1_1_9" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_1_9">
        <input type="text" id="txt1_1_10" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_1_10">
        <input type="text" id="txt1_1_11" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_1_11">
        <input type="text" id="txt1_1_12" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_1_12">
        <input type="text" id="txt1_1_13" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_1_13">
    </div>

    <div class="input-group txt1_2">
        <input type="text" id="txt1_2_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_2_1">
        <input type="text" id="txt1_2_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_2_2">
        <input type="text" id="txt1_2_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_2_3">
    </div>

    <div class="input-group txt1_3">
        <input type="text" id="txt1_3_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_3_1">
        <input type="text" id="txt1_3_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_3_2">
        <input type="text" id="txt1_3_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_3_3">
        <input type="text" id="txt1_3_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_3_4">
        <input type="text" id="txt1_3_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_3_5">
        <input type="text" id="txt1_3_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_3_6">
        <input type="text" id="txt1_3_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_3_7">
        <input type="text" id="txt1_3_8" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_3_8">
        <input type="text" id="txt1_3_9" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_3_9">
        <input type="text" id="txt1_3_10" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_3_10">
        <input type="text" id="txt1_3_11" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_3_11">
        <input type="text" id="txt1_3_12" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_3_12">
        <input type="text" id="txt1_3_13" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_3_13">
    </div>

    <div class="input-group txt1_4">
        <input type="text" id="txt1_4_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_4_1">
        <input type="text" id="txt1_4_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_4_2">
        <input type="text" id="txt1_4_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_4_3">
        <input type="text" id="txt1_4_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_4_4">
        <input type="text" id="txt1_4_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_4_5">
        <input type="text" id="txt1_4_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_4_6">
        <input type="text" id="txt1_4_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_4_7">
        <input type="text" id="txt1_4_8" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_4_8">
        <input type="text" id="txt1_4_9" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_4_9">
        <input type="text" id="txt1_4_10" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt1_4_10">
    </div>

    <div class="input-group txt1_5">
        <input type="text" id="txt1_5_1" (change)="onChange_need2Save($event)" class="form-control-sm" maxlength="50"
            size="30" [(ngModel)]="e.txt1_5_1">
    </div>

    <div class="input-group txt2_1">
        <input type="text" id="txt2_1_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_1_1">
        <input type="text" id="txt2_1_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_1_2">
        <input type="text" id="txt2_1_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_1_3">
        <input type="text" id="txt2_1_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_1_4">
        <input type="text" id="txt2_1_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_1_5">
        <input type="text" id="txt2_1_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_1_6">
        <input type="text" id="txt2_1_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_1_7">
        <input type="text" id="txt2_1_8" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_1_8">
        <input type="text" id="txt2_1_9" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_1_9">
        <input type="text" id="txt2_1_10" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_1_10">
        <input type="text" id="txt2_1_11" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_1_11">
        <input type="text" id="txt2_1_12" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_1_12">
        <input type="text" id="txt2_1_13" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_1_13">
    </div>

    <div class="input-group txt2_2">
        <input type="text" id="txt2_2_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_2_1">
        <input type="text" id="txt2_2_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_2_2">
        <input type="text" id="txt2_2_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_2_3">
        <input type="text" id="txt2_2_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_2_4">
        <input type="text" id="txt2_2_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_2_5">
        <input type="text" id="txt2_2_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_2_6">
        <input type="text" id="txt2_2_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_2_7">
        <input type="text" id="txt2_2_8" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_2_8">
        <input type="text" id="txt2_2_9" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_2_9">
        <input type="text" id="txt2_2_10" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_2_10">
        <input type="text" id="txt2_2_11" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_2_11">
        <input type="text" id="txt2_2_12" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_2_12">
        <input type="text" id="txt2_2_13" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_2_13">
        <input type="text" id="txt2_2_14" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_2_14">
        <input type="text" id="txt2_2_15" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt2_2_15">
    </div>

    <div class="input-group txt3a_1">
        <input type="text" id="txt3a_1_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_1_1">
        <input type="text" id="txt3a_1_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_1_2">
    </div>

    <div class="input-group txt3a_2">
        <input type="text" id="txt3a_2_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_2_1">
        <input type="text" id="txt3a_2_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_2_2">
        <input type="text" id="txt3a_2_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_2_3">
        <input type="text" id="txt3a_2_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_2_4">
        <input type="text" id="txt3a_2_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_2_5">
        <input type="text" id="txt3a_2_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_2_6">
        <input type="text" id="txt3a_2_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_2_7">
        <input type="text" id="txt3a_2_8" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_2_8">
    </div>

    <div class="input-group txt3a_3">
        <input type="text" id="txt3a_3_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_3_1">
        <input type="text" id="txt3a_3_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_3_2">
        <input type="text" id="txt3a_3_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_3_3">
        <input type="text" id="txt3a_3_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_3_4">
        <input type="text" id="txt3a_3_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_3_5">
        <input type="text" id="txt3a_3_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_3_6">
        <input type="text" id="txt3a_3_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_3_7">
        <input type="text" id="txt3a_3_8" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_3_8">
    </div>

    <div class="input-group txt3a_4">
        <input type="text" id="txt3a_4_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_4_1">
        <input type="text" id="txt3a_4_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_4_2">
    </div>

    <div class="input-group txt3a_5">
        <input type="text" id="txt3a_5_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_5_1">
        <input type="text" id="txt3a_5_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_5_2">
        <input type="text" id="txt3a_5_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_5_3">
        <input type="text" id="txt3a_5_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_5_4">
        <input type="text" id="txt3a_5_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_5_5">
    </div>

    <div class="input-group txt3a_6">
        <input type="text" id="txt3a_6_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_6_1">
        <input type="text" id="txt3a_6_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_6_2">
    </div>

    <div class="input-group txt3a_7">
        <input type="text" id="txt3a_7_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_7_1">
        <input type="text" id="txt3a_7_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_7_2">
    </div>

    <div class="input-group txt3a_8">
        <input type="text" id="txt3a_8_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_8_1">
        <input type="text" id="txt3a_8_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_8_2">
    </div>

    <div class="input-group txt3a_9">
        <input type="text" id="txt3a_9_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_9_1">
        <input type="text" id="txt3a_9_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_9_2">
    </div>

    <div class="input-group txt3a_10">
        <input type="text" id="txt3a_10_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_10_1">
        <input type="text" id="txt3a_10_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_10_2">
        <input type="text" id="txt3a_10_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_10_3">
        <input type="text" id="txt3a_10_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_10_4">
        <input type="text" id="txt3a_10_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_10_5">
        <input type="text" id="txt3a_10_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_10_6">
        <input type="text" id="txt3a_10_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_10_7">
    </div>

    <div class="input-group txt3a_11">
        <input type="text" id="txt3a_11_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_11_1">
        <input type="text" id="txt3a_11_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_11_2">
        <input type="text" id="txt3a_11_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_11_3">
    </div>

    <div class="input-group txt3a_12">
        <input type="text" id="txt3a_12_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_12_1">
        <input type="text" id="txt3a_12_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_12_2">
        <input type="text" id="txt3a_12_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_12_3">
        <input type="text" id="txt3a_12_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_12_4">
        <input type="text" id="txt3a_12_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_12_5">
        <input type="text" id="txt3a_12_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_12_6">
        <input type="text" id="txt3a_12_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_12_7">
        <input type="text" id="txt3a_12_8" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_12_8">
        <input type="text" id="txt3a_12_9" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_12_9">
        <input type="text" id="txt3a_12_10" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3a_12_10">
    </div>

    <div class="input-group txt3b_1">
        <input type="text" id="txt3b_1_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_1_1">
        <input type="text" id="txt3b_1_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_1_2">
    </div>

    <div class="input-group txt3b_2">
        <input type="text" id="txt3b_2_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_2_1">
        <input type="text" id="txt3b_2_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_2_2">
        <input type="text" id="txt3b_2_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_2_3">
        <input type="text" id="txt3b_2_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_2_4">
        <input type="text" id="txt3b_2_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_2_5">
        <input type="text" id="txt3b_2_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_2_6">
        <input type="text" id="txt3b_2_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_2_7">
        <input type="text" id="txt3b_2_8" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_2_8">
    </div>

    <div class="input-group txt3b_3">
        <input type="text" id="txt3b_3_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_3_1">
        <input type="text" id="txt3b_3_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_3_2">
        <input type="text" id="txt3b_3_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_3_3">
        <input type="text" id="txt3b_3_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_3_4">
        <input type="text" id="txt3b_3_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_3_5">
        <input type="text" id="txt3b_3_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_3_6">
        <input type="text" id="txt3b_3_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_3_7">
        <input type="text" id="txt3b_3_8" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_3_8">
    </div>

    <div class="input-group txt3b_4">
        <input type="text" id="txt3b_4_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_4_1">
        <input type="text" id="txt3b_4_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_4_2">
    </div>

    <div class="input-group txt3b_5">
        <input type="text" id="txt3b_5_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_5_1">
        <input type="text" id="txt3b_5_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_5_2">
        <input type="text" id="txt3b_5_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_5_3">
        <input type="text" id="txt3b_5_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_5_4">
        <input type="text" id="txt3b_5_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_5_5">
    </div>

    <div class="input-group txt3b_6">
        <input type="text" id="txt3b_6_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_6_1">
        <input type="text" id="txt3b_6_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_6_2">
    </div>

    <div class="input-group txt3b_7">
        <input type="text" id="txt3b_7_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_7_1">
        <input type="text" id="txt3b_7_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_7_2">
    </div>

    <div class="input-group txt3b_8">
        <input type="text" id="txt3b_8_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_8_1">
        <input type="text" id="txt3b_8_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_8_2">
    </div>

    <div class="input-group txt3b_9">
        <input type="text" id="txt3b_9_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_9_1">
        <input type="text" id="txt3b_9_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_9_2">
    </div>

    <div class="input-group txt3b_10">
        <input type="text" id="txt3b_10_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_10_1">
        <input type="text" id="txt3b_10_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_10_2">
        <input type="text" id="txt3b_10_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_10_3">
        <input type="text" id="txt3b_10_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_10_4">
        <input type="text" id="txt3b_10_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_10_5">
        <input type="text" id="txt3b_10_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_10_6">
        <input type="text" id="txt3b_10_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_10_7">
    </div>

    <div class="input-group txt3b_11">
        <input type="text" id="txt3b_11_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_11_1">
        <input type="text" id="txt3b_11_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_11_2">
        <input type="text" id="txt3b_11_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_11_3">
    </div>

    <div class="input-group txt3b_12">
        <input type="text" id="txt3b_12_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_12_1">
        <input type="text" id="txt3b_12_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_12_2">
        <input type="text" id="txt3b_12_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_12_3">
        <input type="text" id="txt3b_12_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_12_4">
        <input type="text" id="txt3b_12_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_12_5">
        <input type="text" id="txt3b_12_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_12_6">
        <input type="text" id="txt3b_12_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_12_7">
        <input type="text" id="txt3b_12_8" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_12_8">
        <input type="text" id="txt3b_12_9" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_12_9">
        <input type="text" id="txt3b_12_10" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3b_12_10">
    </div>

    <div class="input-group txt3c_1">
        <input type="text" id="txt3c_1_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_1_1">
        <input type="text" id="txt3c_1_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_1_2">
    </div>

    <div class="input-group txt3c_2">
        <input type="text" id="txt3c_2_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_2_1">
        <input type="text" id="txt3c_2_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_2_2">
        <input type="text" id="txt3c_2_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_2_3">
        <input type="text" id="txt3c_2_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_2_4">
        <input type="text" id="txt3c_2_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_2_5">
        <input type="text" id="txt3c_2_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_2_6">
        <input type="text" id="txt3c_2_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_2_7">
        <input type="text" id="txt3c_2_8" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_2_8">
    </div>

    <div class="input-group txt3c_3">
        <input type="text" id="txt3c_3_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_3_1">
        <input type="text" id="txt3c_3_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_3_2">
        <input type="text" id="txt3c_3_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_3_3">
        <input type="text" id="txt3c_3_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_3_4">
        <input type="text" id="txt3c_3_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_3_5">
        <input type="text" id="txt3c_3_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_3_6">
        <input type="text" id="txt3c_3_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_3_7">
        <input type="text" id="txt3c_3_8" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_3_8">
    </div>

    <div class="input-group txt3c_4">
        <input type="text" id="txt3c_4_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_4_1">
        <input type="text" id="txt3c_4_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_4_2">
    </div>

    <div class="input-group txt3c_5">
        <input type="text" id="txt3c_5_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_5_1">
        <input type="text" id="txt3c_5_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_5_2">
        <input type="text" id="txt3c_5_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_5_3">
        <input type="text" id="txt3c_5_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_5_4">
        <input type="text" id="txt3c_5_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_5_5">
    </div>

    <div class="input-group txt3c_6">
        <input type="text" id="txt3c_6_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_6_1">
        <input type="text" id="txt3c_6_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_6_2">
    </div>

    <div class="input-group txt3c_7">
        <input type="text" id="txt3c_7_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_7_1">
        <input type="text" id="txt3c_7_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_7_2">
    </div>

    <div class="input-group txt3c_8">
        <input type="text" id="txt3c_8_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_8_1">
        <input type="text" id="txt3c_8_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_8_2">
    </div>

    <div class="input-group txt3c_9">
        <input type="text" id="txt3c_9_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_9_1">
        <input type="text" id="txt3c_9_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_9_2">
    </div>

    <div class="input-group txt3c_10">
        <input type="text" id="txt3c_10_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_10_1">
        <input type="text" id="txt3c_10_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_10_2">
        <input type="text" id="txt3c_10_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_10_3">
        <input type="text" id="txt3c_10_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_10_4">
        <input type="text" id="txt3c_10_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_10_5">
        <input type="text" id="txt3c_10_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_10_6">
        <input type="text" id="txt3c_10_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_10_7">
    </div>

    <div class="input-group txt3c_11">
        <input type="text" id="txt3c_11_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_11_1">
        <input type="text" id="txt3c_11_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_11_2">
        <input type="text" id="txt3c_11_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_11_3">
    </div>

    <div class="input-group txt3c_12">
        <input type="text" id="txt3c_12_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_12_1">
        <input type="text" id="txt3c_12_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_12_2">
        <input type="text" id="txt3c_12_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_12_3">
        <input type="text" id="txt3c_12_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_12_4">
        <input type="text" id="txt3c_12_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_12_5">
        <input type="text" id="txt3c_12_6" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_12_6">
        <input type="text" id="txt3c_12_7" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_12_7">
        <input type="text" id="txt3c_12_8" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_12_8">
        <input type="text" id="txt3c_12_9" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_12_9">
        <input type="text" id="txt3c_12_10" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt3c_12_10">
    </div>

    <div class="input-group txt4_1">
        <input type="text" id="txt4_1_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt4_1_1">
    </div>

    <div class="input-group txt4_2">
        <input type="text" id="txt4_2_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt4_2_1">
        <input type="text" id="txt4_2_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt4_2_2">
        <input type="text" id="txt4_2_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt4_2_3">
        <input type="text" id="txt4_2_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt4_2_4">
        <input type="text" id="txt4_2_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt4_2_5">
    </div>

    <div class="input-group txt4_3">
        <input type="text" id="txt4_3_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt4_3_1">
        <input type="text" id="txt4_3_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt4_3_2">
        <input type="text" id="txt4_3_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt4_3_3">
        <input type="text" id="txt4_3_4" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt4_3_4">
        <input type="text" id="txt4_3_5" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt4_3_5">
    </div>

    <div class="input-group txt4_4">
        <input type="text" id="txt4_4_1" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt4_4_1">
        <input type="text" id="txt4_4_2" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt4_4_2">
        <input type="text" id="txt4_4_3" (change)="onChange_need2Save($event)" class="form-control-sm onechar"
            maxlength="1" [(ngModel)]="e.txt4_4_3">
    </div>

    <div class="txt5_1">
        <textarea id="txt5_1_1" rows="3" cols="113" maxlength="500" (change)="onChange_need2Save($event)"
            [(ngModel)]="e.txt5_1_1"></textarea>
    </div>

    <div style="display: none;">
        <input id="txt2_1" type="text" [(ngModel)]="e.txt2_1">
        <input id="txt2_2" type="text" [(ngModel)]="e.txt2_2">

        <input id="txt3_101" type="text" [(ngModel)]="e.txt3_101">
        <input id="med3_102" type="text" [(ngModel)]="e.med3_102">
        <input id="med3_103" type="text" [(ngModel)]="e.med3_103">
        <input id="txt3_104" type="text" [(ngModel)]="e.txt3_104">
        <input id="txt3_105" type="text" [(ngModel)]="e.txt3_105">
        <input id="txt3_106" type="text" [(ngModel)]="e.txt3_106">
        <input id="txt3_107" type="text" [(ngModel)]="e.txt3_107">
        <input id="txt3_108" type="text" [(ngModel)]="e.txt3_108">
        <input id="txt3_109" type="text" [(ngModel)]="e.txt3_109">
        <input id="txt3_110" type="text" [(ngModel)]="e.txt3_110">
        <input id="txt3_111" type="text" [(ngModel)]="e.txt3_111">
        <input id="med3_112" type="text" [(ngModel)]="e.med3_112">

        <input id="txt3_201" type="text" [(ngModel)]="e.txt3_201">
        <input id="med3_202" type="text" [(ngModel)]="e.med3_202">
        <input id="med3_203" type="text" [(ngModel)]="e.med3_203">
        <input id="txt3_204" type="text" [(ngModel)]="e.txt3_204">
        <input id="txt3_205" type="text" [(ngModel)]="e.txt3_205">
        <input id="txt3_206" type="text" [(ngModel)]="e.txt3_206">
        <input id="txt3_207" type="text" [(ngModel)]="e.txt3_207">
        <input id="txt3_208" type="text" [(ngModel)]="e.txt3_208">
        <input id="txt3_209" type="text" [(ngModel)]="e.txt3_209">
        <input id="txt3_210" type="text" [(ngModel)]="e.txt3_210">
        <input id="txt3_211" type="text" [(ngModel)]="e.txt3_211">
        <input id="med3_212" type="text" [(ngModel)]="e.med3_212">

        <input id="txt3_301" type="text" [(ngModel)]="e.txt3_301">
        <input id="med3_302" type="text" [(ngModel)]="e.med3_302">
        <input id="med3_303" type="text" [(ngModel)]="e.med3_303">
        <input id="txt3_304" type="text" [(ngModel)]="e.txt3_304">
        <input id="txt3_305" type="text" [(ngModel)]="e.txt3_305">
        <input id="txt3_306" type="text" [(ngModel)]="e.txt3_306">
        <input id="txt3_307" type="text" [(ngModel)]="e.txt3_307">
        <input id="txt3_308" type="text" [(ngModel)]="e.txt3_308">
        <input id="txt3_309" type="text" [(ngModel)]="e.txt3_309">
        <input id="txt3_310" type="text" [(ngModel)]="e.txt3_310">
        <input id="txt3_311" type="text" [(ngModel)]="e.txt3_311">
        <input id="med3_312" type="text" [(ngModel)]="e.med3_312">

        <input id="chk4_1" type="checkbox" [(ngModel)]="e.chk4_1">
        <input id="txt4_2" type="text" [(ngModel)]="e.txt4_2">
        <input id="txt4_3" type="text" [(ngModel)]="e.txt4_3">
        <input id="med4_4" type="text" [(ngModel)]="e.med4_4">

        <input id="txt5_1" type="text" [(ngModel)]="e.txt5_1">
    </div>

    <!-- {{ reclIdxSeld }} -->
    <!-- {{ reclaims | json }} -->
    <!-- txt5_1 = {{ txt5_1_1 }} , -->
    <!-- {{ reclLineReasonEntered }} -->
    <!-- {{ e | json2 }} -->

</div>