import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AppToastsService } from '../../../shared/app-toasts/app-toasts.service';
import { DataMessageService } from '../../../shared/data-message.service';
import { IRptAgingDet } from '../../../shared/interfaces/rptAgingDet';
import { IRptAgingPad } from '../../../shared/interfaces/rptAgingPad';
import { IRptAgingPay } from '../../../shared/interfaces/rptAgingPay';
import { CkRegister835DetailService } from '../../../ck-register/ck-register835-detail/ck-register835-detail.service';

@Component({
  selector: 'app-rpt-aging-details',
  templateUrl: './rpt-aging-details.component.html',
  styleUrls: ['../rpt-aging.component.css'],
  standalone: false
})
export class RptAgingDetailsComponent {
  @Input() det: IRptAgingDet[] = [];
  @Input() pay: IRptAgingPay[] = [];  // All case payments
  @Input() pad: IRptAgingPad[] = [];

  sn: string;
  engLang: boolean;
  dataSource: MatTableDataSource<any>;
  dataSourcePay: MatTableDataSource<any>;
  dataSourcePad: MatTableDataSource<any>;
  rptAgingDetDisplydCols: string[] = [
    'dtFrom',
    'DetQty',
    'DetPos',
    'DetPcode',
    'mods',
    'DetDx',
    'DetUsual',
    'DetXpect',
    'DetPvdo',
    'prod',
    'DetDesc'
  ];
  rptAgingPayDisplydCols: string[] = [
    "PayPS",
    "PayDt",
    "PayAmnt",
    "PayCkNo",
    "PayApCode",
    "PayMode",
    "PayMemo",
  ];
  rptAgingPadDisplydCols: string[] = [
    "PayAdjGrp",
    "PayAdjReason",
    "PayAdjAmnt",
    "cptMod",
    "PayAdjHeRem"
  ];
  procPay: IRptAgingPay[] = []; // Payments per CPT

  constructor(private _dataMessageService: DataMessageService,
    private _appToastsService: AppToastsService,
    private _ckRegister835DetailService: CkRegister835DetailService
  ) { }

  ngOnInit(): void {
    // this._dataMessageService.currentSnStr.subscribe(snStr => this.snChange(snStr)); // Subscription looking for changes in sn
    this._dataMessageService.currentEngLangStr.subscribe(engLangStr => this.engLangChange(engLangStr)); // Subscription looking for changes in engLang

    let arr: any[] = this.det.slice();
    this.dataSource = new MatTableDataSource(arr);

    arr = this.pay.slice();
    this.dataSourcePay = new MatTableDataSource(arr);

    arr = this.pad.slice();
    this.dataSourcePad = new MatTableDataSource(arr);
  }

  snChange(snStr: string) { // for when sn changes
    this.sn = snStr;
  }
  engLangChange(engLangStr: string) { // for when language changes
    engLangStr === 'true' ? this.engLang = true : this.engLang = false;
  }

  onClick_expandDets(row): void {
    console.log('onClick_expandDets(row)', 'Will probably end up using this to shoe a component for Proc codes prices editing');
    console.log('pay', this.pay);
    this.procPay = [];
    // if (row.expandCol == '') {
    //   this.procPay = (this.pay.filter(p => { p.pkId === row.pkId })).slice();
    //   row.expandCol = 'true';
    // } else {
    //   row.expandCol = '';
    // }
    console.log('procPay', this.procPay);
    console.log('row', row);
  }

  getCarcDescrFromAdj(adj: any): void { // Get adj CARC data from Sql table [CARC]
    if (!adj) {
      return;
    }

    this._appToastsService.updateDeadCenter(false); // Show toast @ top of viewport
    let grpCodeDesc: string = adj.PayAdjGrp ? (adj.PayAdjGrp + ' = ' + this._ckRegister835DetailService.carcGroupCodes.find(obj => obj.code == adj.PayAdjGrp).descr) : adj;
    let reasonCode: string = adj.PayAdjReason ? adj.PayAdjReason : adj;
    this._ckRegister835DetailService.getCarcDescrFromReasonCode(reasonCode)
      .subscribe({
        next: data => {
          data.forEach(itm => {
            this._appToastsService.updateDeadCenter(false);
            this._appToastsService.show(adj.PayAdjReason + ' = ' + itm.descr, { header: grpCodeDesc, autohide: false, success: true });
          });
        },
        error: (err: any) => {
          this._appToastsService.updateDeadCenter(false);
          this._appToastsService.show(err.displayMsg, { header: 'ERROR', autohide: false, error: true });
          return;
        }
      });
  }

  getRarcDesr(rarcs): void {
    if (!rarcs) {
      return;
    }

    const rarcArr = rarcs.split(" ");
    for (let i = 0; i < rarcArr.length; i++) {
      this.getCarcDescrFromAdj({ 'PayAdjGrp': 'RARC', PayAdjReason: rarcArr[i] });
    }
  }

}
