<h2 mat-dialog-title>
    {{data.tit}}
</h2>

<div style="overflow: auto; max-height: 500px;">
    <mat-dialog-content>
        <pre>
            {{data.msg}}
        </pre>
        <div *ngIf="data.fileSave">
            <mat-form-field appearance="fill">
                <mat-label>File Upload</mat-label>
                <input matInput placeholder="Select a file" [value]="fileName">
                <button mat-icon-button matSuffix (click)="fileInput.click()">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                        fill="#5f6368">
                        <path
                            d="M240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v240h-80v-200H520v-200H240v640h360v80H240Zm638 15L760-183v89h-80v-226h226v80h-90l118 118-56 57Zm-638-95v-640 640Z" />
                    </svg>
                </button>
            </mat-form-field>
            <input type="file" (change)="onFileSelected($event)" #fileInput hidden>
            <!-- <input type="file" id="fileupload"> -->
            <!-- <input ngfSelect type="file" accept="accept" maxSize="1024" capturePaste="1" /> -->
            <!-- <button *ngIf="files.length" type="button" (click)="files.length = 0">clear</button> -->
        </div>
    </mat-dialog-content>
</div>

<mat-dialog-actions class="mat-dlg-close">
    <button mat-button mat-dialog-close>
        {{data.engLang ? 'Close': 'Cerrar' }}
    </button>
</mat-dialog-actions>