/* src/app/app.component.css */
body {
  background: #fff;
}
.navbar,
.dropdown-menu {
  background: #879ab7;
  border: none;
}
.nav > li > a,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a,
.dropdown-menu > li {
  border-bottom: 3px solid transparent;
}
.nav > li > a:focus,
.nav > li > a:hover,
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  border-bottom: 3px solid transparent;
  background: rgba(154, 154, 154, 0.27);
}
.navbar a,
.dropdown-menu > li > a,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.navbar-toggle {
  color: #fff;
}
.dropdown-menu {
  -webkit-box-shadow: none;
  box-shadow: none;
}
nav li:nth-child(1) a span:hover,
nav li:nth-child(1) a span.active {
  border-bottom: #b6f423 3px solid;
}
nav li:nth-child(2) a span:hover,
nav li:nth-child(2) a span.active {
  border-bottom: #82e2ea 3px solid;
}
nav li:nth-child(3) a span:hover,
nav li:nth-child(3) a span.active {
  border-bottom: #f8b42c 3px solid;
}
nav li:nth-child(4) a span:hover,
nav li:nth-child(4) a span.active {
  border-bottom: #fd594a 3px solid;
}
nav li:nth-child(5) a span:hover,
nav li:nth-child(5) a span.active {
  border-bottom: #e8479d 3px solid;
}
nav li:nth-child(6) a span:hover,
nav li:nth-child(6) a span.active {
  border-bottom: #a12eeb 3px solid;
}
nav li:nth-child(7) a span:hover,
nav li:nth-child(7) a span.active {
  border-bottom: #4785d9 3px solid;
}
nav li:nth-child(8) a span:hover,
nav li:nth-child(8) a span.active {
  border-bottom: #2aed9a 3px solid;
}
.navbar-toggle .icon-bar {
  color: #fff;
  background: #000000;
}
nav li ul li:nth-child(1) span:hover,
ul li:nth-child(1) a span.active {
  border-bottom: #b6f423 3px solid;
}
nav li ul li:nth-child(2) span:hover,
nav li:nth-child(2) a div a:nth-child(2) span.active {
  border-bottom: #82e2ea 3px solid;
}
nav li ul li:nth-child(3) a span:hover,
ul li:nth-child(3) a span.active {
  border-bottom: #f8b42c 3px solid;
}
nav li ul li:nth-child(4) a span:hover,
ul li:nth-child(4) a span.active {
  border-bottom: #fd594a 3px solid;
}
nav li ul li:nth-child(5) a span:hover,
ul li:nth-child(5) a span.active {
  border-bottom: #e8479d 3px solid;
}
nav li ul li:nth-child(6) a span:hover,
ul li:nth-child(6) a span.active {
  border-bottom: #a12eeb 3px solid;
}
nav li ul li:nth-child(7) a span:hover,
ul li:nth-child(7) a span.active {
  border-bottom: #4785d9 3px solid;
}
nav li ul li:nth-child(8) a span:hover,
ul li:nth-child(8) a span.active {
  border-bottom: #2aed9a 3px solid;
}
a {
  text-decoration: none;
}
.colorgraph {
  height: 5px;
  border-top: 0;
  background: #c4e17f;
  border-radius: 5px;
}
.oddRev {
  color: white;
  background-color: #879ab7;
}
.evenRev {
  color: black;
  background-color: #e9ecef;
}
.rowBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.noselect {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.activeLink {
  color: black !important;
}
@media (min-width: 768px) {
  .my-nav-bar-ht {
    height: 44px;
  }
}
/*# sourceMappingURL=app.component.css.map */
