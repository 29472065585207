<mat-drawer-container class="main">
    <mat-drawer mode="side" opened>
        {{ engLang ? 'Reports': 'Reportes' }}
        <mat-nav-list>
            <a mat-list-item [ngClass]="{ 'selected': seldRpt === 1 }" (click)="seldRpt = 1">
                <span>{{ engLang ? 'Daily Transactions': 'Cuadre Diario' }}</span>
            </a>

            <a mat-list-item [ngClass]="{ 'selected': seldRpt === 2 }" (click)="seldRpt = 2">
                <span>{{ engLang ? 'Expected vs. Received': 'Esperado vs. Cobrado' }}</span>
            </a>

            <a mat-list-item [ngClass]="{ 'selected': seldRpt === 3 }" (click)="seldRpt = 3">
                <span>{{ engLang ? 'Income & Adjustments': 'Cobros y Ajustes' }}</span>
            </a>

            <a mat-list-item [ngClass]="{ 'selected': seldRpt === 4 }" (click)="seldRpt = 4">
                <span>{{ engLang ? 'Aging': 'Aging' }}</span>
            </a>

            <a mat-list-item [ngClass]="{ 'selected': seldRpt === 5 }" (click)="seldRpt = 5">
                <span>{{ engLang ? 'Production': 'Producción' }}</span>
            </a>

            <a mat-list-item [ngClass]="{ 'selected': seldRpt === 6 }" (click)="seldRpt = 6">
                <span>{{ engLang ? 'Re-claims/Disputes': 'Reclamos' }}</span>
            </a>

            <a mat-list-item [ngClass]="{ 'selected': seldRpt === 7 }" (click)="seldRpt = 7">
                <span>{{ engLang ? 'Claims List': 'Listado de Casos' }}</span>
            </a>

            <a mat-list-item [ngClass]="{ 'selected': seldRpt === 8 }" (click)="seldRpt = 8">
                <span>{{ engLang ? 'Patient List': 'Listado de Records' }}</span>
            </a>
        </mat-nav-list>
    </mat-drawer>

    <mat-drawer-content>
        <mat-card>
            <mat-card-header>
                <mat-card-title class="title">{{ engLang ? 'Parameters': 'Parámetros'}}</mat-card-title>
                <mat-card-subtitle>{{ engLang ? 'Dates': 'Fechas' }}</mat-card-subtitle>

                <mat-form-field style="width: 155px">
                    <mat-label class="label">{{ engLang ? 'From': 'Desde' }}</mat-label>
                    <input matInput [matDatepicker]="fromPicker" id="fromDt" [(ngModel)]="seldFromDt"
                        (dateChange)="onChange_params($event)">
                    <mat-hint class="dt-hint">{{ engLang ? 'mo/day/year': 'mes/dia/año' }}</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="fromPicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromPicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field style="width: 155px">
                    <mat-label class="label">{{ engLang ? 'To': 'Hasta' }}</mat-label>
                    <input matInput [matDatepicker]="toPicker" id="toDt" [(ngModel)]="seldToDt"
                        (dateChange)="onChange_params($event)">
                    <mat-hint class="dt-hint">{{ engLang ? 'mo./day/year': 'mes/dia/año' }}</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="toPicker"></mat-datepicker-toggle>
                    <mat-datepicker #toPicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field style="width: 155px">
                    <mat-label class="label">Provs</mat-label>
                    <mat-select class="label" multiple matTooltip="{{ seldProvs }}" [(ngModel)]="seldProvs"
                        (selectionChange)="onChange_params($event)">
                        <mat-option *ngFor="let prov of provs; trackBy: trackByProv" [value]="prov.pKey"
                            class="label">{{ prov.pKey + ' ' + prov.alias }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style="width: 155px">
                    <mat-label class="label">{{ engLang ? 'Ins 1': 'Seg 1' }}</mat-label>
                    <mat-select class="label" multiple matTooltip="{{ seldInses1 }}" [(ngModel)]="seldInses1"
                        (selectionChange)="onChange_params($event)">
                        <mat-option *ngFor="let ins of inses; trackBy: trackByPkey" [value]="ins.pKey" class="label">
                            {{ ins.pKey + ' ' + ins.alias }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style="width: 155px">
                    <mat-label class="label">{{ engLang ? 'Ins 2': 'Seg 2' }}</mat-label>
                    <mat-select class="label" multiple matTooltip="{{ seldInses2 }}" [(ngModel)]="seldInses2"
                        (selectionChange)="onChange_params($event)">
                        <mat-option *ngFor="let ins of inses; trackBy: trackByPkey" [value]="ins.pKey" class="label">{{
                            ins.pKey + ' ' + ins.alias }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style="width: 155px">
                    <mat-label class="label">{{ engLang ? 'Facility': 'Facilidad' }}</mat-label>
                    <mat-select class="label" multiple matTooltip="{{ seldFacs }}" [(ngModel)]="seldFacs"
                        (selectionChange)="onChange_params($event)">
                        <mat-option *ngFor="let fac of facs; trackBy: trackByPkey" [value]="fac.pKey" class="label">{{
                            fac.pKey + ' ' + fac.alias }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style="width: 155px">
                    <mat-label class="label">Office</mat-label>
                    <mat-select class="label" multiple matTooltip="{{ seldLocales }}" [(ngModel)]="seldLocales"
                        (selectionChange)="onChange_params($event)">
                        <mat-option *ngFor="let locale of locales;" [value]="locale.locNo" class="label">{{
                            locale.locName }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card-header>

            <mat-card-actions class="d-flex justify-content-between">
                <div>
                    <mat-toolbar class="toolbar">
                        <mat-icon [matMenuTriggerFor]="menu">menu</mat-icon>&nbsp;
                        <button mat-button>{{ engLang ? 'Options': 'Opciones' }}</button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item *ngFor="let option of options" (click)="optionSelected(option)"
                                style="cursor: pointer;">{{
                                option.desc }}</button>
                        </mat-menu>

                        <mat-chip-grid #chipGrid>
                            <mat-chip-row *ngFor="let option of rptOptions" [value]="option.value" class="label">
                                {{ option.desc }}
                                <mat-icon matChipRemove (click)="removeOption($event, option)">cancel</mat-icon>
                            </mat-chip-row>
                            <input [matChipInputFor]="chipGrid" tabindex="-1" [readonly]="true" />
                            <!-- Must have this here or an focused RTErr happens-->
                        </mat-chip-grid>
                    </mat-toolbar>
                </div>

                <div>
                    <div *ngIf="waiting4Success" class="btn-success">
                        <img src="../app/assets/images/waiting4Response.gif" width="30" height="30" alt="spinner"
                            class="img-fluid">
                    </div>

                </div>

                <div>
                    <!-- Trying to center spinner above -->
                </div>

                <div>
                    <!-- Trying to center spinner above -->
                </div>

                <div>
                    <!-- Trying to center spinner above -->
                </div>

                <div>

                </div>

                <div class="d-sm-flex justify-content-between">
                    <div *ngIf="rptAgingLoaded" class="pt-2">
                        <!-- <i class="fas fa-print pt-3"></i> -->
                        <mat-toolbar>
                            <mat-toolbar-row>
                                <!-- <mat-icon class="maticon" style="cursor: pointer;"
                                    >print</mat-icon>
                                <mat-icon class="maticon" style="cursor: pointer;">send</mat-icon> -->
                            </mat-toolbar-row>
                        </mat-toolbar>
                    </div>

                    <div>
                        <button mat-raised-button class="label" color="primary" (click)="fetchRpt()">
                            {{ engLang ? 'Prepare Report': 'Preparar Reporte' }}
                        </button>
                    </div>
                </div>
            </mat-card-actions>
        </mat-card>

        <!-- <pre>{{ rptAgingLoaded }}</pre> -->
        <!-- <pre>{{ rptAgingData.ins | json }}</pre> -->
        <div *ngIf="rptAgingLoaded">
            <app-rpt-aging [rptAgingData]="rptAgingData" [rptFromDt]="rptFromDt" [rptToDt]="rptToDt"
                [rptOptions]="rptOptions" [seldProvsHead]="seldProvsHead()" [seldInses1Head]="seldInsesHead(seldInses1)"
                [seldInses2Head]="seldInsesHead(seldInses2)" [seldFacsHead]="seldFacsHead()"
                [seldLocalesHead]="seldLocalesHead()"></app-rpt-aging>
        </div>
    </mat-drawer-content>

</mat-drawer-container>