<div id="container">

    <div *ngIf="scannerFound">
        <select *ngIf="!bWASM" id="sources" class="form-select form-select-sm mb-2">

        </select>
    </div>

    <div id="dwtMenu" class="my-0 py-0">
        <div>
            <i class="fas fa-folder-open" title="{{ engLang ? 'Open image': 'Abrir imagen' }}"
                (click)="openImage();"></i>
        </div>

        <div *ngIf="DWObject && DWObject.SelectAllImages().length > 0" style="font-size: xx-small; cursor: pointer;"
            (click)="acceptImage();">Ok
            <i class="far fa-thumbs-up" title="{{ engLang ? 'Accept image(s)': 'Aceptar imagen(es)' }}"></i>
        </div>

        <div *ngIf="scannerFound" style="font-size: xx-small; cursor: pointer;" (click)="acquireImage();">{{ engLang ?
            'Scan':
            'Escanear' }}
            <img *ngIf="!bWASM" src="app/assets/images/scanner-pngrepo-com.png"
                title="{{ engLang ? 'Scan': 'Escanear' }}" width="25" height="45" alt="scanner" class="img-fluid" />
        </div>

    </div>

    <div id="dwtcontrolContainer" class="pt-1 pb-0 mt-1 mb-0">
    </div>

    <div id="dwtClose">
        <i class="far fa-window-close" style="cursor: pointer;"
            title="{{ engLang ? 'Close Scanner': 'Cerrar Escaner' }}" (click)="closeScanners();"></i>
    </div>

    <div>
        <input id="result64" type="hidden" (click)="emitDoc()" />
    </div>

</div>