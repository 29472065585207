/* src/app/shared/qparams/qparams.dialog.css */
.label,
.dt-hint {
  font-size: 10px;
}
.font9 {
  font-size: 9px;
}
.bot-border {
  border-bottom: .5px solid #E0E0E0;
}
/*# sourceMappingURL=qparams.dialog.css.map */
