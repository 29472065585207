<div class="mat-typography mat-app-background" [formGroup]="form">
    <h2 mat-dialog-title>
        {{data.engLang ? '837 File Creation Parameters' : 'Parámetros Para Fila Archivo 837'}}
    </h2>

    <mat-dialog-content [formGroup]="form" class="text-center">

        <div class="d-flex flex-column">
            <div>
                <mat-form-field appearance="fill">
                    <mat-label class="label">{{data.engLang ? 'Claim date FROM': 'Fecha caso DESDE'}}</mat-label>
                    <input matInput placeholder="{{data.engLang ? 'mo/day/year': 'mes/dia/año'}}"
                        formControlName="fromDt" [matDatepicker]="fromDtPickr">
                    <mat-datepicker-toggle matSuffix [for]="fromDtPickr">
                    </mat-datepicker-toggle>
                    <mat-datepicker #fromDtPickr>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field appearance="fill">
                    <mat-label class="label">{{data.engLang ? 'Claim date TO': 'Fecha caso HASTA'}}</mat-label>
                    <input matInput placeholder="{{data.engLang ? 'mo/day/year': 'mes/dia/año'}}" formControlName="toDt"
                        [matDatepicker]="toDtPickr">
                    <mat-datepicker-toggle matSuffix [for]="toDtPickr">
                    </mat-datepicker-toggle>
                    <mat-datepicker #toDtPickr>
                    </mat-datepicker>
                </mat-form-field>

                <mat-error *ngIf="isDateRangeInvalid && form.dirty" class="label">
                    {{engLang ? 'FROM date greater than TO date': 'Fecha DESDE mayor que HASTA'}}
                </mat-error>
            </div>

            <div>
                <mat-form-field appearance="fill">
                    <mat-label class="label">Provs</mat-label>
                    <mat-select id="provs" class="label" multiple matTooltip="{{ pIDsToolTip }}"
                        formControlName="provsSelectCtrl" (selectionChange)="onChange_params($event)">
                        <mat-option *ngFor="let prov of data.provs; trackBy: trackByProv" [value]="prov.pKey"
                            class="label bot-border">{{ prov.alias + ' (' }}<span class="font9">{{prov.pKey}}</span>{{
                            ')'
                            }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field appearance="fill">
                    <mat-label class="label">Plans</mat-label>
                    <mat-select id="inses" class="label" multiple matTooltip="{{ iIDsToolTip }}"
                        formControlName="insesSelectCtrl" (selectionChange)="onChange_params($event)">
                        <mat-option *ngFor="let ins of data.inses; trackBy: trackByIns" [value]="ins.pKey"
                            class="label bot-border">{{ ins.alias + ' (' }}<span class="font9">{{ins.pKey}}</span>{{ ')'
                            }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div>
                <mat-checkbox color="primary" formControlName="ckSubDt" (change)="onChange_ckSubDt($event)">
                    {{data.engLang ? 'Check Claims Submitted Date': 'Cotejar Fecha Sometido'}}
                </mat-checkbox>
            </div>

            <div [ngStyle]="{display: ckSubDt.value ? 'block': 'none'}">
                <mat-form-field appearance="fill">
                    <mat-label class="label">{{data.engLang ? 'Submitted date to check': 'Fecha sometido a
                        cotejar'}}</mat-label>
                    <input matInput placeholder="{{data.engLang ? 'mo/day/year': 'mes/dia/año'}}"
                        formControlName="subDt" [matDatepicker]="subDtPickr">
                    <mat-datepicker-toggle matSuffix [for]="subDtPickr">
                    </mat-datepicker-toggle>
                    <mat-datepicker #subDtPickr>
                    </mat-datepicker>
                </mat-form-field>

            </div>
        </div>

    </mat-dialog-content>

    <!-- <div>{{form.value | json2}}</div>
    <pre>{{form.valid}}</pre> -->

    <div class="d-flex justify-content-between">
        <div>
            <mat-dialog-actions align="start">
                <button mat-raised-button mat-dialog-close>Cancel</button>
            </mat-dialog-actions>
        </div>
        <div>
            <mat-dialog-actions align="end">
                <button mat-raised-button [mat-dialog-close]="true" color="primary" (click)="onClick_continue()"
                    [disabled]="!form.valid">{{data.engLang ? 'Create queue' : 'Crear fila'}}</button>
            </mat-dialog-actions>
        </div>
    </div>

</div>