import { Pipe, PipeTransform } from '@angular/core';
import { IReclaimsDet } from '../interfaces/reclaims';

@Pipe({
  name: 'filtrDetailsPipe',
  standalone: true
})
export class FiltrDetailsPipe implements PipeTransform {

  transform(reclaimsDet: IReclaimsDet[], ID: string, icn: string): IReclaimsDet[] {
    console.log('icn', icn);
    if (!reclaimsDet || +ID < 0) {
      return reclaimsDet;
    }

    return reclaimsDet.filter((det) => +det.reclDetReclID === +ID && (det.reclDetICN === icn || +det.reclDetID > 0));
  }

}
