<div class="col-xs-12">

  <!-- Then put toasts within -->
  <div id="sioNotif" class="toast" role="alert" aria-live="assertive" aria-atomic="true"
      data-autohide="false">
      <div class="toast-header">
          <img src="error.png" class="rounded mr-2" alt="..." width="25px" data-bind="visible: showErr">
          <img src="success.jpg" class="rounded mr-2" alt="..." width="25px" data-bind="visible: !showErr()">
          <strong id="toastHead" class="mr-auto">Heading</strong>
          <small></small>
          <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
              <span>&times;</span>
          </button>
      </div>
      <div id="toastBody" class="toast-body">
          toast message.
      </div>
  </div>
</div>

<div class="col-xs-12">
  <h6 id="siteNm" class="text-center"></h6>
  <div class=" text-center" style="margin-top: -15px; padding-top: 0;"><small><span id="infoTel"
              class="text-center"></span></small>
      &nbsp;<small id="infoEmail" class="text-center"></small>
  </div>
  <div class="text-center"><em id="subTit">Favor completar y firmar abajo para su cita m&eacute;dica.</em>
  </div>
</div>

<div class="needs-validation col-xs-12" novalidate>

  <div class="my-1 demogrBackgColr borders rad10 col-xs-12">
      <div class="form-group">
          <label for="inputLast" class="col-form-label col-form-label-sm ml-2"><span
                  class="custom-control-inline">Last/Apellidos<i
                      class="fas fa-hand-point-left"></i></span></label>
          <input type="text" class="form-control form-control-sm mt-0 text-uppercase" placeholder="Last name"
              id="inputLast" maxlength="30" data-bind="value: lastNm">
          <em class="customMessage" data-bind="validationMessage: lastNm" style="color: red;"></em>
      </div>

      <div class="form-group">
          <label for="inputFirst" class="col-form-label col-form-label-sm ml-2"><span
                  class="custom-control-inline">Name/Nombre<i
                      class="fas fa-hand-point-left"></i></span></label>
          <input type="text" class="form-control form-control-sm text-uppercase" placeholder="First name"
              id="inputFirst" maxlength="30" data-bind="value: firstNm">
          <em class="customMessage" data-bind="validationMessage: firstNm" style="color: red;"></em>
      </div>

      <div class="form-group">
          <label for="inputInit" class="col-form-label col-form-label-sm ml-2">Init/Inic</label>
          <input type="text" class="form-control form-control-sm text-uppercase" placeholder="Inicial"
              id="inputInit" maxlength="1" data-bind="value: initNm">
          <em class="customMessage" data-bind="validationMessage: initNm" style="color: red;"></em>
      </div>

      <div class="form-group">
          <label for="inputAge" class="col-form-label col-form-label-sm ml-2"><span
                  class="custom-control-inline">Age/Edad<i class="fas fa-hand-point-left"></i></span></label>
          <input type="text" class="form-control form-control-sm" id="inputAge" style="font-size: smaller;"
              maxlength="3" placeholder="Age" data-bind="value: age" size="3">
          <em class="customMessage" data-bind="validationMessage: age" style="color: red;"></em>
      </div>

      <div class="form-group">
          <label for="male" class="col-form-label col-form-label-sm ml-2"><span
                  class="custom-control-inline">Gender/G&eacute;nero<i
                      class="fas fa-hand-point-left"></i></span></label><br />
          <div class="text-center">
              <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="male" name="gender" class="custom-control-input" value="M"
                      data-bind="checked: gender">
                  <label class="custom-control-label" for="male">M</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="female" name="gender" class="custom-control-input" value="F"
                      data-bind="checked: gender">
                  <label class="custom-control-label" for="female">F</label>
              </div>
              <small class="customMessage" data-bind="validationMessage: gender" style="color: red;"></small>
          </div>
      </div>

      <div class="form-group">
          <label for="inputZip" class="col-form-label col-form-label-sm ml-2"><span
                  class="custom-control-inline">Zip<i class="fas fa-hand-point-left"></i></span></label>
          <input type="text" class="form-control form-control-sm" placeholder="Zip" id="inputZip"
              maxlength="5" data-bind="value: zip">
          <em class="customMessage" data-bind="validationMessage: zip" style="color: red;"></em>
      </div>

      <div class="text-center">
          <div>Sign/Firme <span id="spanSignType"></span></div>
          <canvas id="signCanvas" width="280" height="125"
              style="border:1px solid #000000; background-color: lightyellow;"></canvas>
      </div>

      <br>
      <div class=" text-center">
          <button id="btnClear" type="button" class="btn btn-secondary btn-sm"
              onclick="clearCanvas()">Clear</button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button id="btnSubmit" type="button" class="btn btn-primary btn-sm">Someter</button>
      </div>

      <br />
  </div>
  <footer class="blockquote-footer text-center" style="margin-top: -22px;">by <cite>JLRosado
          www.MedicalBiller.us
          medbiler&#64;gmail.com</cite>
  </footer>

  <input type="text" id="sn" name="sn">
  <input type="text" id="patID" name="patID">
  <input type="text" id="casID" name="casID">
  <input type="text" id="signType" name="signType">
  <input type="text" id="lang" name="lang">
  <br>
  <input type="text" id="ln" name="ln">
  <input type="text" id="fn" name="fn">
  <input type="text" id="mi" name="mi">
  <input type="text" id="ag" name="ag">
  <input type="text" id="sx" name="sx">
  <input type="text" id="zp" name="zp">
</div>