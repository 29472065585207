import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

import { MatDialog } from '@angular/material/dialog';
import { MatMsgDlgComponent } from './mat-msg-dlg/mat-msg-dlg.component';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor(private _dialog: MatDialog) { }

  agingExportToExcel(data: any[], carcs: any[], fileName: string, title: string, engLang: boolean): void {
    // Append a Totals row to data
    let a: string = 'Totals:';
    let b: string = '';
    let c: number = 0;
    let d: number = 0;
    let e: number = 0;
    let f: number = 0;
    let g: number = 0;
    let h: number = 0;
    let i: number = 0;
    let j: number = 0;
    let k: number = 0;
    let l: number = 0;
    let m: number = 0;
    let n: number = 0;
    let o: number = 0;
    let p: number = 0;
    let q: number = 0;
    let r: number = 0;
    let s: number = 0;
    let t: number = 0;
    let u: number = 0;
    let v: number = 0;
    let w: string = '';
    let x: string = '';
    let y: string = '';
    let z: string = '';
    let aa: string = '';
    let ab: number = 0;
    let ac: string = '';
    let ad: string = '';
    let ae: string = '';
    let af: string = '';
    let ag: string = '';
    let ah: string = '';
    let ai: string = '';
    let aj: string = '';
    let ak: string = '';
    let al: string = '';
    let am: string = '';
    let an: number = 0;
    let ao: number = 0;
    let ap: number = 0;
    let aq: string = '';
    let ar: string = '';
    let as: string = '';
    let at: string = '';
    let au: string = '';
    let av: string = '';
    let aw: string = '';
    let ax: string = '';
    let ay: string = '';
    let az: string = '';
    let ba: string = '';
    let bb: string = '';
    let bc: string = '';
    let bd: number = 0;
    let be: string = '';
    let bf: string = '';

    let inId: string = '';
    data.forEach(ele => {
      ab += +ele['Balance'];
      an += +ele['Usual'];
      ao += +ele['Esperado'];
      ap += +ele['Coins'];
      bd += +ele['Adj Amount'];

      if (inId !== ele['Local ID']) {
        c += +ele['0-30 Bal'];
        d += +ele['31-60 Bal'];
        e += +ele['61-90 Bal'];
        f += +ele['91-120 Bal'];
        g += +ele['121-150 Bal'];
        h += +ele['151-6m Bal'];
        i += +ele['6m-1y Bal'];
        j += +ele['1y-2y Bal'];
        k += +ele['2y-3y Bal'];
        l += +ele['Total Bal'];
        m += +ele['0-30 Clms'];
        n += +ele['31-60 Clms'];
        o += +ele['61-90 Clms'];
        p += +ele['91-120 Clms'];
        q += +ele['121-150 Clms'];
        r += +ele['151-6m Clms'];
        s += +ele['6m-1y Clms'];
        t += +ele['1y-2y Clms'];
        u += +ele['2y-3y Clms'];
        v += +ele['Total Clms'];
        inId = ele['Local ID'];
      } else {
        ele['Plan'] = '';
        ele['Local ID'] = '';
        ele['0-30 Bal'] = '';
        ele['31-60 Bal'] = '';
        ele['61-90 Bal'] = '';
        ele['91-120 Bal'] = '';
        ele['121-150 Bal'] = '';
        ele['151-6m Bal'] = '';
        ele['6m-1y Bal'] = '';
        ele['1y-2y Bal'] = '';
        ele['2y-3y Bal'] = '';
        ele['Total Bal'] = '';
        ele['0-30 Clms'] = '';
        ele['31-60 Clms'] = '';
        ele['61-90 Clms'] = '';
        ele['91-120 Clms'] = '';
        ele['121-150 Clms'] = '';
        ele['151-6m Clms'] = '';
        ele['6m-1y Clms'] = '';
        ele['1y-2y Clms'] = '';
        ele['2y-3y Clms'] = '';
        ele['Total Clms'] = '';
      }
    });
    data.push({
      "Plan": a,
      "Local ID": b,
      "0-30 Bal": c,
      "31-60 Bal": d,
      "61-90 Bal": e,
      "91-120 Bal": f,
      "121-150 Bal": g,
      "151-6m Bal": h,
      "6m-1y Bal": i,
      "1y-2y Bal": j,
      "2y-3y Bal": k,
      "Total Bal": l,
      "0-30 Clms": m.toString(),  // .toString() causes the value not to be aligned right as for type number
      "31-60 Clms": n.toString(),
      "61-90 Clms": o.toString(),
      "91-120 Clms": p.toString(),
      "121-150 Clms": q.toString(),
      "151-6m Clms": r.toString(),
      "6m-1y Clms": s.toString(),
      "1y-2y Clms": t.toString(),
      "2y-3y Clms": u.toString(),
      "Total Clms": v.toString(),
      "Posted": w,
      "Claim No": x,
      "Contract No": y,
      "Patient": z,
      "Claim Prim/Sec": aa,
      "Balance": ab,
      "Diags.": ac,
      "Other Insurance": ad,
      "Days old": ae,
      "Reclaimed": af,
      "Adjusted": ag,
      "Bill to Prim/Sec": ah,
      "From": ai,
      "Qty": aj,
      "POS": ak,
      "CPT": al,
      "Modifs": am,
      "Usual": an,
      "Esperado": ao,
      "Coins": ap,
      "Rendering": aq,
      "Descr": ar,
      "Dx pointr": as,
      "Pay from Prim/Sec": at,
      "Payed Dt": au,
      "Payed Amnt": av,
      "Ck No.": aw,
      "ICN": ax,
      "Pay Mode": ay,
      "Memo": az,
      "Pay Dt": ba,
      "CPT-Mod": bb,
      "Adj Remark": bc,
      "Adj Amount": bd,
      "Adj Reason Code": be,
      "Adj Group Code": bf
    });

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();

    // Convert empty data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet([{}]);

    // Append the empty worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    workbook.Props = {
      Title: title,
      Author: "MedicalBiller.us/JLRosado",
      CreatedDate: new Date()
    };

    // Add a title row to the worksheet
    const titleRow = [{ A: title }];
    XLSX.utils.sheet_add_json(worksheet, titleRow, { skipHeader: true, origin: 'A1' });

    // Adjust the data to start from the second row
    XLSX.utils.sheet_add_json(worksheet, data, { origin: 'A2' });

    // Apply thousand separator format to currency cols
    const currencyCols = ['C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'AB', 'AN', 'AO', 'AP', 'AV', 'BE', 'AF', 'AG'];
    currencyCols.forEach(col => {
      for (let row = 2; row < data.length + 3; row++) {
        const cellRef = `${col}${row}`;
        if (worksheet[cellRef]) {
          if (row == 2) {
            worksheet[cellRef].t = 's'; // Set the cell type to string text <==== NOT WORKING
            // console.log('cellRef', cellRef);
            // console.log('worksheet[cellRef]', worksheet[cellRef]);
          } else {
            if (col === 'AF' || col === 'AG') { // Reclaimed, Adjusted ckbox
              const cellRef = `${col}${row}`;
              // console.log('cellRef', cellRef);
              const val = worksheet[cellRef].v;
              // console.log('val', val);
              if (val == 1) {
                worksheet[cellRef] = { t: 's', v: '☑' }; // Use the checked checkbox character
              }
            } else {
              worksheet[cellRef].t = 'n'; // Set the cell type to numeric
              worksheet[cellRef].z = '#,##0.00'; // Apply the thousand separator format
            }
          }
        }
      }
    });

    // Calculate column widths
    const colWidths = [];
    data.forEach(row => {
      Object.keys(row).forEach((key, idx) => {
        const value = row[key] ? row[key].toString().trim().length : 10;
        colWidths[idx] = 10;  //value; //Math.max(colWidths[idx] || 10, value); <= fixed to 10 seems to wok best
      });
    });

    // Set column widths
    worksheet['!cols'] = colWidths.map(width => ({ wch: width }));

    const currentLastRow = data.length + 3; // +1 to account for the header row +1 title +1 cpyright

    // Rename carc object propertie
    carcs.forEach(c => {
      c['ReasonCode'] = c['code'];
      delete c['code'];
      c['Descr'] = c['descr'];
      delete c['descr'];
    });

    // Append new data carcs[] to the existing worksheet
    XLSX.utils.sheet_add_json(worksheet, carcs, {
      skipHeader: false,
      origin: { r: 1 + currentLastRow, c: 0 } // Append starting after the existing data + 1 empty row divider
    });

    // Set copyright
    worksheet[data.length + carcs.length + 2] = {}; // Create a new row object at the next available index
    worksheet[XLSX.utils.encode_cell({ c: 0, r: data.length + 2 })] = { v: 'by MedicalBiller.us medbiler@gmail.com' }; // Set cell values

    const newRange = XLSX.utils.decode_range(worksheet['!ref'] || '');
    if (data.length + 4 + carcs.length > newRange.e.r) {
      worksheet['!ref'] = XLSX.utils.encode_range({ s: newRange.s, e: { c: newRange.e.c, r: data.length + 4 } });
    }

    // Generate and download the Excel file
    XLSX.writeFile(workbook, `${fileName}.xlsx`);

    this._dialog.open(MatMsgDlgComponent, {
      data: {
        tit: engLang ? 'Success!' : '¡Exito!',
        msg: engLang ? 'File: ' + fileName + ' downloaded.' : 'Archivo: ' + fileName + ' descargado.',
        engLang: engLang
      }
    });
  }

}
