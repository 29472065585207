<div class="container">
  <div class="fs-5">
    <div class="rowBox nogap justify-content-center pt-1">
      <div>
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-tabs font576"
          (navChange)="beforeTabChange($event)">
          <li [ngbNavItem]="1" *ngIf="!+this.xtPID"> <!-- To make this choice invisible when in modal window -->
            <a ngbNavLink>{{ engLang ? 'Search': 'Búsqueda' }}</a>
          </li>

          <li [ngbNavItem]="2">
            <a ngbNavLink [ngStyle]="+pat.patID ? { 'background-color': '#F1E5AC' }:{ 'background-color': null }"
              ngbTooltip="{{ (pat.lastNm + (pat.firstNm ? ', ' : '') + pat.firstNm + ' ' + (pat.recNo ? (pat.recNo + (pat.recLocale ? ' - ' + pat.recLocale : '') + (pat.recYr ? ' - ' + pat.recYr: '')) : '')).trim() }}">Record</a>
          </li>

          <li [ngbNavItem]="3">
            <a ngbNavLink>{{ engLang ? 'Documents': 'Documentos' }}</a>
          </li>

          <li [ngbNavItem]="4">
            <a ngbNavLink style="display: flex; align-items: flex-start; flex-direction: row;">
              <div>837</div>
              <div class="font10 ital badge rounded-pill align-self-start d-flex flex-column"
                style="color: green; margin-top: 0;"
                ngbTooltip="{{engLang ? 'Claims ready to send in 837 file': 'Casos listos a ser enviados en archivo 837'}}">
                {{_recordService.s837ckedCnt}}
                <div class="font10 ital badge rounded-pill align-self-start" style="color: red;"
                  ngbTooltip="{{engLang ? 'Errors': 'Errores'}}" placement="bottom">
                  {{+_recordService.s837errCnt ? _recordService.s837errCnt: ''}}
                </div>
              </div>

            </a>
          </li>

          <li [ngbNavItem]="5" *ngIf="debugData">
            <a ngbNavLink style="color: orangered">Debug Data</a>
          </li>

          <!-- Other Tabs/Functions -->

        </ul>
      </div>
    </div>

    <!-- <div *ngIf="deError" class="text-center alert alert-danger">
      {{ deError }}
    </div>   Using Toast for now -->

    <div *ngIf="showQ837">
      <app-q837 [currCasID]="selectedCase[selectedCaseIndx].casID" [changeQ837]="changeQ837"
        [localProviders]="_recordService.localProviders" [localInsurances]="_recordService.localInsurances" [sn]="sn"
        [userID]="userID" [engLang]="engLang" [pgIndex]="q837pgIndex" [pgSize]="q837pgSize"
        (changeQ837ToggelEvEm)="changeQ837Toggle($event)" (openRecordCaseClick)="isQ837RecordCaseEdited($event)">
      </app-q837>
    </div>

    <ng-template ngbNavContent [ngIf]="activeTab == 1">
      <!--Record search-->
      <!--Record search-->
      <!--Record search-->
      <!--Record search-->
      <!--Record search-->

      <div class="table-responsive">
        <table id="sTable" class="table table-sm table-bordered font90">
          <thead>
            <tr style="background-color: #faf2eb;">
              <th class="w178 text-center cursorPtr"
                (click)="onClick_findRecord({value: srchLast.value, id: 'srchLast'});">
                {{ engLang ? 'Last Name or Claim #': 'Apellidos ó Caso #' }}
                <i class="fas fa-search blueColor"></i>
                <span *ngIf="fetchingSrchResLastNm">
                  <img src="/app/assets/images/waiting4Response.gif" width="20" height="20"
                    alt="Waiting for response..." style="float:right;">
                </span>
              </th>

              <th class="w86 text-center">
                {{ engLang ? 'Name ': 'Nombre ' }}
              </th>

              <th class="w20 text-center hide">
                Sx
              </th>

              <th class="w96 text-center">
                <div ngbTooltip="{{engLang ? 'Date of Birth': 'Fecha nacimiento'}}">
                  {{ engLang ? 'DOB': 'Nace' }}
                </div>
              </th>

              <th class="w81 text-center cursorPtr" [hidden]="showCasesHx"
                (click)="onClick_findRecord({value: srchTel.value, id: 'srchTel'});">
                {{ 'Tel' }}
                <i class="fas fa-search blueColor"></i>
                <span *ngIf="fetchingSrchResTel">
                  <img src="/app/assets/images/waiting4Response.gif" width="20" height="20"
                    alt="Waiting for response..." style="float:right;">
                </span>
              </th>

              <th class="w89 text-center cursorPtr"
                (click)="onClick_findRecord({value: srchRecord.value, id: 'srchRecord'});">
                {{ 'Record ' }}
                <i class="fas fa-search blueColor"></i>
                <span *ngIf="fetchingSrchResRecNo">
                  <img src="/app/assets/images/waiting4Response.gif" width="20" height="20"
                    alt="Waiting for response...">
                </span>
              </th>

              <th class="text-center w96 cursorPtr" [hidden]="showCasesHx"
                (click)="onClick_findRecord({value: dsrch._inputValue, id: 'srchCasDt'});">
                <div ngbTooltip="{{engLang ? 'Claim or posted date': 'Fecha del caso o posteo'}}">
                  {{ engLang ? 'Posted': 'Posteo' }}
                  <i class="fas fa-search blueColor"></i>
                  <span *ngIf="fetchingSrchResCasDt">
                    <img src="/app/assets/images/waiting4Response.gif" width="20" height="20"
                      alt="Waiting for response..." style="float:right;">
                  </span>
                </div>
              </th>

              <th class="w120 text-center cursorPtr" [hidden]="showCasesHx"
                (click)="onClick_findRecord({value: srchContNo.value, id: 'srchContNo'});">
                <div ngbTooltip="{{ engLang ? 'Contract #': '# Contrato' }}">Contr
                  <i class="fas fa-search blueColor"></i>
                </div>
                <span *ngIf="fetchingSrchResContNo">
                  <img src="/app/assets/images/waiting4Response.gif" width="20" height="20"
                    alt="Waiting for response..." style="float:right;">
                </span>
              </th>

              <th class="w40 text-center">
                <i class="fas fa-folder-open" ngbTooltip="{{engLang ? 'Open record': 'Abrir record'}}"></i>
              </th>

              <th class="w435 text-center" [hidden]="!showCasesHx">
                {{ engLang ? 'Last Claims': 'Ultimos Casos' }}
                <!-- Covers hiden columns when expanding to view cases -->
              </th>

              <th class="w40 text-center" (click)="onClick_Expand2ShowCases(undefined);">
                <i class="fas fa-sitemap"
                  ngbTooltip="{{engLang ? 'Closes Claim History - Allows new search' : 'Cierra Historial de Casos - permite nueva búsqueda'}}"
                  [ngStyle]="showCasesHx ? {'color' : '#0000FF'} : {'color' : 'black'}"></i>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr style="background-color: #ebcaad;">
              <td class="w178">
                <input id="srchLast" class="form-control font90" type="text" maxlength="35" [disabled]="showCasesHx"
                  style="padding: 1px 2px;" (keyup)="onKeyUp_srch($event,{value: srchLast.value, id: 'srchLast'});"
                  #srchLast [(ngModel)]="lastUsedParam.lastNm"
                  placeholder="{{engLang ? '...last name to search...': '...apellidos para buscar...' }}"
                  ngbTooltip="{{engLang ? 'Search by last[,] first': 'Buscar por apellido[,] nombre'}}"
                  placement="right">
              </td>

              <td class="w86">
              </td>

              <td class="w20 hide">
              </td>

              <td class="w96">
              </td>

              <td class="w81" [hidden]="showCasesHx">
                <input id="srchTel" class="form-control font90" type="text" maxlength="8" style="padding: 1px 2px;"
                  (keyup)="onKeyUp_srch($event,{value: srchTel.value, id: 'srchTel'});" #srchTel
                  [(ngModel)]="lastUsedParam.telNo"
                  placeholder="{{engLang ? '...tel to search...': '...tel a buscar...' }}"
                  ngbTooltip="{{engLang ? 'Omit area code': 'Omita el código de área para buscar'}}">
              </td>

              <td class="w89 text-center">
                <input id="srchRecord" class="form-control font90 text-center" type="text" maxlength="15"
                  [disabled]="showCasesHx" (keyup)="onKeyUp_srch($event,{value: srchRecord.value, id: 'srchRecord'});"
                  #srchRecord [(ngModel)]="lastUsedParam.recNo"
                  placeholder="{{engLang ? '...rec # to search...': '...rec # a buscar...' }}"
                  style="padding: 1px 2px;">
              </td>

              <td class="w96 cursorPtr" [hidden]="showCasesHx">
                <div class="d-sm-inline-flex" style="padding: 1px 2px;">
                  <input id="srchCasDt" class="form-control font90" style="padding: 1px 2px;"
                    [(ngModel)]="lastUsedParam.casDt" placeholder="mm/dd/yy" name="dtp" #csrch="ngModel" ngbDatepicker
                    parserFormatter="CustomDateParserFormatter"
                    (dateSelect)="onClick_findRecord({value: dsrch._inputValue, id: 'srchCasDt'})"
                    #dsrch="ngbDatepicker"
                    (keyup)="onKeyUp_srch($event,{value: dsrch._inputValue, id: 'srchCasDt'});" />
                  <i class="far fa-calendar-times mt-1" (click)="dsrch.toggle()"></i>
                </div>
              </td>

              <td class="w120" [hidden]="showCasesHx">
                <input id="srchContNo" class="form-control font90" type="text" maxlength="15"
                  (keyup)="onKeyUp_srch($event,{value: srchContNo.value, id: 'srchContNo'});" #srchContNo
                  [(ngModel)]="lastUsedParam.contrNo"
                  placeholder="{{engLang ? '...contract to search...': '...contrato a buscar...' }}"
                  style="padding: 1px 2px;">
              </td>

              <td class="w40">
                <input id="topRecordsRows" type="number" class="form-control" maxlength="2" size="2"
                  style="font-size: 80%; padding: 1px 0 0 1px;" min="1" max="99" [disabled]="showCasesHx" step="5"
                  [(ngModel)]="lastUsedParam.topRecRows" (change)="onChange_topRows2Read($event)"
                  ngbTooltip="{{engLang ? 'Number of records to read while searching': 'Cantidad records a leer buscando'}}" />
              </td>

              <td class="w435" [hidden]="!showCasesHx">
                <!-- Covers hidden columns when expanding to view cases -->
              </td>

              <td class="w40">
                <input id="topCasesRows" type="number" class="form-control" maxlength="3" size="3"
                  style="font-size: 80%; padding: 1px 0 0 1px;" min="1" max="999" step="5"
                  [(ngModel)]="lastUsedParam.topCasRows" (change)="onChange_topRows2Read($event)"
                  ngbTooltip="{{engLang ? 'Number of claims for history': 'Cantidad de casos para historial'}}" />
              </td>
            </tr>

            <tr *ngFor="let record of records; let e = even; let o = odd; let r = index;" [attr.id]="record.pat.patID"
              [ngClass]="{ evenRecSrch : e, oddRecSrch : o }"
              [ngStyle]="openedRecSrchPid === +record.pat.patID ? { 'background-color': '#B48CC8' }: {}">
              <!-- tabindex in conjunction with CSS tbody tr:focus {} allows row focusing -->

              <td class="w178" style="text-align: left;">
                {{ record.pat.lastNm }}
              </td>

              <td class="w86" style="text-align: left;">
                {{ record.pat.firstNm }}
              </td>

              <td class="w20 hide" style="text-align: center;">
                {{ record.pat.sex }}
              </td>

              <td class="w96">
                {{ classExist('sTable','portrate') ? dob2YrDigits(record.pat.dob): record.pat.dob }}
              </td>

              <td class="w81" style="text-align: center;" [hidden]="showCasesHx">
                {{ classExist('sTable','portrate') ? telWoAcode(record.pat.tel): record.pat.tel }}
              </td>

              <td class="w89 text-center">
                <!-- {{ record.pat.recNo }} - {{ record.pat.recOffice }} - {{ record.pat.recYr }} -->
                {{ classExist('sTable','portrate') ? recNoWoYr(record.pat.recNo + '-' + +record.pat.recOffice
                + '-' +
                record.pat.recYr): record.pat.recNo + '-' + +record.pat.recOffice + '-' + record.pat.recYr }}
              </td>

              <td class="w96" style="text-align: center;" [hidden]="showCasesHx">
                {{ record.pat.casDt }}
              </td>

              <td class="w120" [hidden]="showCasesHx">
                {{ record.pat.contNo }}
              </td>

              <td class="w40" style="text-align: center; cursor: pointer; color: #0000EE;"
                (click)="onClick_openRecordCase(record.pat.patID,0);">
                <div *ngIf="record.pat.patID">
                  <span style="display: none;">{{ record.pat.patID }}</span>
                  <i class="fas fa-folder-open"></i>
                </div>
              </td>

              <td class="w435" [hidden]="!showCasesHx">

                <!-- case hx table -->
                <!-- case hx table -->
                <!-- case hx table -->
                <!-- case hx table -->
                <!-- case hx table -->

                <div style="max-height: 600px; overflow-y: scroll; overflow-x: hidden;">
                  <table id="hTabl" class="table table-sm table-bordered caseTable">
                    <thead class="disappear" style="position: sticky; top: 0;">
                      <tr class="disappear" style="background-color: #bf9d7a;">

                        <th class="w50">
                          {{ engLang ? 'Date' : 'Fecha' }}
                        </th>
                        <th class="w50 text-center">
                          {{ 'Prov' }}
                        </th>
                        <th class="w50 text-center">
                          {{ engLang ? 'Ins' : 'Segs' }}
                        </th>
                        <th class="w65 text-end">
                          {{ 'Bal 1' }}
                        </th>
                        <th class="w65 text-end">
                          {{ 'Bal 2' }}
                        </th>
                        <th class="w65 text-end">
                          {{ 'Bal P' }}
                        </th>
                        <th class="w80 text-center">
                          {{ engLang ? 'Case No.' : 'Caso No.' }}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let case of record.pat.casesHx; let e = even; let o = odd; let c = index;"
                        [ngClass]="highLiteSrchedCase(case.casNo, case.casOfNo) ? 'selectedCase' : { evenCasSrch : e, oddCasSrch : o }"
                        style="font-size: 100%; cursor: pointer;"
                        (click)="onClick_openRecordCase(case.casPatID,case.casID);">
                        <!-- tabindex in conjunction with CSS tbody tr:focus {} allows row focusing -->

                        <td class="hTablTd w50" [ngStyle]="{'display': !c && ipadPortrait ? 'none': ''}"
                          style="padding: 1px 2px;">
                          <span class="ltjustify">
                            {{ case.casDate }}
                          </span>
                        </td>

                        <td class="hTablTd w60" [ngStyle]="{ 'display': !c && ipadPortrait ? 'none': ''}"
                          style="padding: 1px 2px;">
                          <span class="ltjustify">
                            {{ c == 0 ? '' : (case.casProvID ? ' (' + case.casProvID + ')': '') + (case.casProvAlias ?
                            case.casProvAlias: '') }}
                          </span>
                        </td>

                        <td class="hTablTd w60" [ngStyle]="{ 'display': !c && ipadPortrait ? 'none': ''}"
                          style="padding: 1px 2px;">
                          <div class="ltjustify"
                            ngbTooltip="{{ c == 0 ? '' : (case.casI1ID > 0 ? ' (' + case.casI1ID + ')': '') + (case.casIns1Alias ? case.casIns1Alias: '') }} 
                            {{ c == 0 ? '' : (case.casI2ID > 0 ? ', (' + case.casI2ID + ')': '') + (case.casIns2Alias ? case.casIns2Alias: '') }}">
                            {{ c == 0 ? '' : (case.casI1ID > 0 ? ' (' + case.casI1ID + ')': '') + (case.casIns1Alias ?
                            case.casIns1Alias: '') }}
                            <br>
                            {{ c == 0 ? '' : (case.casI2ID > 0 ? ' (' + case.casI2ID + ')': '') + (case.casIns2Alias ?
                            case.casIns2Alias: '') }}
                          </div>
                        </td>

                        <td class="hTablTd w65" [ngStyle]="{ 'display': !c && ipadPortrait ? 'none': ''}"
                          style="padding: 1px 2px;" [ngClass]="getOverDueColor(case.casBal1, case.casDate)">
                          <span class=" ltjustify">
                            <span class="currency">
                              <!-- {{ '1234567.00' | currency:'USD':'':'1.2-2' }} -->
                              {{ case.casBal1 | currency:'USD':'':'1.2-2' }}
                            </span>
                          </span>
                        </td>

                        <td class="hTablTd w65" [ngStyle]="{ 'display': !c && ipadPortrait ? 'none': ''}"
                          style="padding: 1px 2px;" [ngClass]="getOverDueColor(case.casBal2, case.casDate)">
                          <span class=" ltjustify">
                            <span class="currency">
                              <!-- {{ '1.23' | currency:'USD':'':'1.2-2' }} -->
                              {{ case.casBal2 | currency:'USD':'':'1.2-2' }}
                            </span>
                          </span>
                        </td>

                        <td class="hTablTd w65" [ngStyle]="{ 'display': !c && ipadPortrait ? 'none': ''}"
                          style="padding: 1px 2px;" [ngClass]="getOverDueColor(case.casBalP, case.casDate)">
                          <span class=" ltjustify">
                            <span class="currency">
                              <!-- {{ '0.00' | currency:'USD':'':'1.2-2' }} -->
                              {{ case.casBalP | currency:'USD':'':'1.2-2' }}
                            </span>
                          </span>
                        </td>

                        <td class="hTablTd w80" style="padding: 1px 2px;">
                          <div *ngIf="c > 0" class="rtalign">
                            {{ case.casNo }}-{{ case.casOfNo }}
                          </div>
                          <div *ngIf="c === 0" class="rtalign"
                            ngbTooltip="{{engLang ? 'Click opens record in a new claim': 'Clic abre record en caso nuevo'}}">
                            <i class="far fa-file"></i>
                            <br />
                            <span *ngIf="c == 0">{{engLang ? 'New': 'Nuevo'}}</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table> <!-- case hx table -->
                </div>
                <!-- <pre>{{ record | json }}</pre> -->
                <!-- <pre>{{ record.pat.casesHx | json }}</pre> -->
              </td>

              <td class="w40" (click)="onClick_Expand2ShowCases(record.pat.patID);"
                style="text-align: center; cursor: pointer; color: #0000EE;">
                <div *ngIf="record.pat.patID && !fetchingSrchCases">
                  <i class="fas fa-sitemap"></i>
                </div>
                <div *ngIf="fetchingSrchCases">
                  <img src="/app/assets/images/waiting4Response.gif" width="20" height="20"
                    alt="Waiting for response...">
                </div>
              </td>
            </tr>
          </tbody>
        </table> <!--Record search-->
      </div>

      <div *ngIf="records.length - 1" style="font-size: 60%; margin-top: -20px;" class="text-center p-0">
        {{ records.length >= 99 ? '99+' : records.length }}
        items
        <!-- <div>
          <pre>{{ lastUsedParam | json }}</pre>
        </div> -->
      </div>
    </ng-template>

    <ng-template ngbNavContent [ngIf]="activeTab == 2">
      <!--Record-->
      <!--Record-->
      <!--Record-->
      <!--Record-->
      <!--Record-->

      <div>
        <div *ngIf="deError" class="text-center btn-danger">
          {{ deError }}
        </div>

        <div *ngIf="waiting4Success" class="text-center btn-success">
          <img src="/app/assets/images/waiting4Response.gif" width="20" height="20" alt="saving" class="img-fluid"
            *ngIf="saveStatus">
          {{ ' ' + saveStatus + ' ' }}
          <img src="/app/assets/images/waiting4Response.gif" width="20" height="20" alt="saving" class="img-fluid"
            *ngIf="waiting4Success">
        </div>

        <div *ngIf="warning" class="text-center btn-warning">
          {{ ' ' + warning + ' ' }}
        </div>

        <div> <!-- Top Save/Delete Menu -->
          <table class="table table-bordered table-sm mb-0 pb-0 mt-1 unselectable">
            <tr class="menuHt ptr">
              <td style="background: #f7d9d3; width:33%;" (click)="deleteRecordCase('R');">
                <div class="text-center">
                  <span>{{ engLang ? 'Delete ' : 'Eliminar ' }}</span>
                  <i class="fa fa-trash"></i>
                  <span> Record</span>
                </div>
              </td>
              <td style="background: #b6c6d4; width:33%;"
                (click)="abortEdits(undefined, true, false, undefined, undefined)">
                <div class="text-center">
                  <span>{{ engLang ? 'New ' : 'Record ' }}</span>
                  <i class="far fa-file"></i>
                  <span>{{ engLang ? ' Record' : ' Nuevo' }}</span>
                </div>
              </td>
              <td style="background: #f7f7b0; width:33%;" (click)="saveRecord(false);">
                <div class="text-center">
                  <i class="far fa-save"></i>
                  <span>{{ engLang ? ' Save' : ' Grabar' }}</span>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="flexRowColBox table-responsive">
          <div *ngIf="!hideBillHx">
            <!--Billing Hx-->
            <!--Billing Hx-->
            <!--Billing Hx-->
            <!--Billing Hx-->
            <!--Billing Hx-->

            <div class="text-center sectionTit">
              {{ engLang ? 'Billing History ': 'Historial Facturación ' }}({{ selectedCase[selectedCaseIndx]?.hxCasCnt
              }})
            </div>
            <div class="my-0 py-0 text-center">
              <i class="fas fa-print" style="font-size:12px" ngbTooltip="{{ engLang ? 'Print': 'Imprimir' }}"></i>
            </div>

            <div class="table-responsive" style="height: 344px; overflow-y: auto;">
              <table class="table table-sm table-bordered font90 billHx">
                <thead class="table-dark" style="font-size: 80%; height: 29px;">
                  <tr>
                    <th scope="col" class="w38hx pb-2 px-0 m-0  no-borders"
                      style="position: sticky; top: 0; z-index: 2;">{{
                      engLang ?
                      'Date': 'Fecha' }}</th>
                    <th scope="col" class="w50hx pb-2 px-0 m-0  no-borders text-center"
                      style="position: sticky; top: 0; z-index: 2;">
                      Prov
                    </th>
                    <th scope="col" class="w50hx pb-2 px-0 m-0  no-borders text-center"
                      style="position: sticky; top: 0; z-index: 2;">{{
                      engLang ? 'Ins': 'Segs' }}</th>
                    <th scope="col" class="w50hx pb-2 py-0 m-0  no-borders text-end"
                      style="position: sticky; top: 0; z-index: 2;">Bal
                    </th>
                    <th scope="col" class="w40hx pb-2 px-0 m-0  no-borders text-center"
                      style="position: sticky; top: 0; z-index: 2;">{{
                      engLang ? 'Case': 'Caso' }}</th>
                  </tr>
                </thead>
                <tbody *ngFor="let case of selectedCase | filterNewCasePipe; let e = even; let o = odd; let c = index;">
                  <tr style="cursor: pointer;"
                    [ngClass]="selectedCaseIndx == c ? { 'selectedCase' : 'e', 'selectedCase' : 'o' } : { evenDetSrch : e, oddDetSrch : o }"
                    (click)="abortEdits($event, false, true, case.casPatID, case.casID)">

                    <td class="w38hx p-0 m-0 text-center">
                      <div>
                        {{ case.casDate?.substring(0,5) }}
                      </div>
                      <div>
                        {{ case.casDate?.substring(6,9) }}
                      </div>
                    </td>
                    <td class="w50hx" style="padding: 1px 2px;">
                      <div style="font-size: smaller; line-height: 1.1;">
                        <span class="text-center">{{ (case.casProvID && c ? '(' + case.casProvID + ')': '') }}</span>
                      </div>
                      <div style="font-size: xx-small; white-space: pre-wrap; line-height: 1.1;">
                        {{ !c ? '' : (case.casProvAlias ? case.casProvAlias.substring(0,20): '') }}
                      </div>
                    </td>
                    <td class="w50hx" style="padding: 1px 2px;">
                      <div
                        style="font-size: xx-small; line-height: 1.1; white-space: pre-wrap; word-break: break-word;">
                        {{ (+case.casI1ID && c ? '(' + case.casI1ID + ')': '') }}
                        {{ !c ? '' : (case.casIns1Alias ? case.casIns1Alias.substring(0,15): '') }}
                      </div>
                      <div
                        style="font-size: xx-small; line-height: 1.1; white-space: pre-wrap; word-break: break-word;">
                        {{ (+case.casI2ID && c ? '(' + case.casI2ID + ')': '') }}
                        {{ !c ? '' : (case.casIns2Alias ? case.casIns2Alias.substring(0,15): '') }}
                      </div>
                    </td>
                    <td class="w50hx text-end p-0 m-0">
                      <div [ngClass]="getOverDueColor(case.casBal1, case.casDate)" style="font-size: smaller;">
                        {{ c == 0 ? '': case.casBal1 | currency:'USD':'':'1.2-2' }}
                      </div>
                      <div [ngClass]="getOverDueColor(case.casBal2, case.casDate)" style="font-size: smaller;">
                        {{ c == 0 ? '': case.casBal2 | currency:'USD':'':'1.2-2' }}
                      </div>
                      <div [ngClass]="getOverDueColor(case.casBalP, case.casDate)" style="font-size: smaller;">
                        {{ c == 0 ? '': case.casBalP | currency:'USD':'':'1.2-2' }}
                      </div>
                    </td>
                    <td class="w40hx p-0 m-0">
                      <div class="text-center">
                        <span *ngIf="c > 0" style="font-size: 12px;">{{ case.casNo }}</span>
                        <i *ngIf="c == 0" class="far fa-file"></i>
                        <div *ngIf="c == 0" style="font-size: 12px;">
                          {{ engLang ? 'New': 'Nuevo'}}
                        </div>
                      </div>
                      <div class="text-center" style="font-size: 10px;">
                        {{ case.casOfNo }}
                      </div>
                    </td>
                  </tr>

                  <!-- <tr>
                    <td colspan="5">
                      <pre>{{ case | json }}</pre>
                    </td>
                  </tr> -->

                </tbody>
              </table>
            </div>
          </div>

          <div>
            <!--Demographics-->
            <!--Demographics-->
            <!--Demographics-->
            <!--Demographics-->
            <!--Demographics-->

            <div class="text-center sectionTit">
              {{ engLang ? 'Demographic': 'Demográfico' }}
            </div>
            <div class="text-center">
              <i class="fas fa-print text-center ml-1 mt-0" style="font-size:12px" *ngIf="!waiting4PatImg2Load"
                ngbTooltip="{{ engLang ? 'Print': 'Imprimir' }}" (click)="printDemographics()"></i>
              <img src="/app/assets/images/waiting4Response.gif" width="20" height="20" alt="printing..."
                *ngIf="waiting4PatImg2Load">
            </div>

            <div class="pt-1">
              <table class="table table-bordered table-sm m-0 p-0" style="background: #f7f8f6;">

                <tr class="m-0 p-0">
                  <td class="m-0 p-0" colspan="2">

                    <div class="rowBox">

                      <div>
                        <label for="recNo" [ngClass]="{ 'edited' : pat.recNo != patOrg.recNo }">
                          {{ "Record #:" }}
                        </label>
                        <input type="text" id="recNo" class="form-control p-1" placeholder='{{ "Record No. "}}'
                          style="font-size: 95%;" ngbTooltip='{{ "Record No.:" }}' maxlength="6" [(ngModel)]="pat.recNo"
                          [ngClass]="errsRec.recNo ? { 'redBkg': errsRec.recNo } : { 'edited' : pat.recNo != patOrg.recNo }"
                          size="8">
                      </div>

                      <div>
                        <label for="recLocale" [ngClass]="{ 'edited' : pat.recLocale != patOrg.recLocale }">
                          {{ engLang ? "Locale:": "Local:" }}
                          <img src="app/assets/images/presets-200.png" width="15px" height="15px" alt="preset"
                            ngbTooltip='{{ engLang ? "Preset" : "Fijar" }}'
                            (click)="saveDeleteCookie('pat.recLocale', pat.recLocale)">
                        </label>
                        <select id="recLocale" class="form-control p-1" style="font-size: 95%;"
                          ngbTooltip='{{ engLang ? "Locale" : "Local" }}' [(ngModel)]="pat.recLocale"
                          [ngClass]="{ 'edited' : pat.recLocale != patOrg.recLocale }">
                          <option *ngFor="let loc of this._recordService.locales;" [ngValue]="loc.locNo">{{
                            loc.locName
                            }}</option>
                        </select>
                      </div>

                      <div>
                        <label for="recYr" [ngClass]="{ 'edited' : pat.recYr != patOrg.recYr }">
                          {{ engLang ? "Yr:": "Año:" }}
                          <img src="app/assets/images/presets-200.png" width="15px" height="15px" alt="preset"
                            ngbTooltip='{{ engLang ? "Preset" : "Fijar" }}'
                            (click)="saveDeleteCookie('pat.recYr', pat.recYr)">
                        </label>
                        <input type="text" name="recYr" id="recYr" class="form-control text-center p-1"
                          style="font-size: 95%;" ngbTooltip='{{ engLang ? "Year" : "Año" }}' maxlength="2"
                          [(ngModel)]="pat.recYr"
                          [ngClass]="errsRec.recYr ? { 'redBkg': errsRec.recYr } : { 'edited' : pat.recYr != patOrg.recYr }"
                          size="2">
                      </div>

                      <div>
                        <label for="otherRecNo" [ngClass]="{ 'edited' : pat.otherRecNo != patOrg.otherRecNo }">
                          {{ engLang ? "Other Rec #:": "Otro Rec:" }}
                        </label>
                        <input type="text" id="otherRecNo" class="form-control p-1"
                          placeholder='{{ engLang ? "Other Record #" : "Otro # Record" }}'
                          style="font-size: 95%; display: block;"
                          ngbTooltip='{{ engLang ? "Other Record #" : "Otro # Record" }}' maxlength="10"
                          [ngClass]="{ 'edited' : pat.otherRecNo != patOrg.otherRecNo }" [(ngModel)]="pat.otherRecNo"
                          size="8">
                      </div>

                      <div>
                        <label for="recDob" style="display: block;" [ngClass]="{ 'edited' : pat.dob != patOrg.dob }">
                          {{ engLang ? "Birth:": "Nace:" }}
                        </label>
                        <div class="d-sm-inline-flex">
                          <input id="recDob" class="form-control"
                            style="width: 80px; height: 30px; font-size: 95%; padding: 6px 2px 6px 2px;"
                            [(ngModel)]="pat.dob" placeholder="mm/dd/yyyy" name="dtp" #cdob="ngModel" ngbDatepicker
                            [ngClass]="errsRec.dob ? { 'redBkg': errsRec.dob } : { 'edited' : pat.dob != patOrg.dob }"
                            #ddob="ngbDatepicker" (blur)="enterDate($event, 'pat.dob')" />
                          <i class="far fa-calendar-times mt-2" (click)="ddob.toggle()"></i>
                        </div>
                      </div>

                      <div>
                        <label style="display: block;" [ngClass]="{ 'edited' : pat.sex != patOrg.sex }">
                          {{ engLang ? "Gender:": "Género:" }}
                          <img src="app/assets/images/presets-200.png" width="15px" height="15px" alt="preset"
                            ngbTooltip='{{ engLang ? "Preset" : "Fijar" }}'
                            (click)="saveDeleteCookie('pat.sex', pat.sex)">
                        </label>
                        <div class="d-flex flex-column justify-content-center align-items-center">
                          <div>
                            <input type="radio" id="recSexM" name="recSex" class="pl-1" [value]="M"
                              [checked]="pat.sex == 'M'" (click)="pat.sex = 'M';">
                            <label for="recSexM"
                              [ngClass]="errsRec.sex ? { 'redBkg': errsRec.sex } : { 'edited' : pat.sex != patOrg.sex }">
                              M</label>
                          </div>
                          <div>
                            <input type="radio" id="recSexF" name="recSex" class="pl-1" [value]="F"
                              [checked]="pat.sex == 'F'" (click)="pat.sex = 'F';">
                            <label for="recSexF"
                              [ngClass]="errsRec.sex ? { 'redBkg': errsRec.sex } : { 'edited' : pat.sex != patOrg.sex }">
                              F</label>
                          </div>
                        </div>
                      </div>

                    </div>

                  </td>
                  <td style="cursor: pointer;"
                    (click)="tmpStr=(engLang ? 'HIPAA Signature': 'Firma HIPAA'); tmpSign = pat.hSign; open(signCaptureModal, { backdrop: 'static', size: 'md', scrollable: false }, 'signCaptureModal'); signCanvas($event)">
                    <div class="d-inline-flex flex-column justify-content-top align-items-center">
                      <div>
                        <label class="pseudoLink" style="display: block;">
                          {{ engLang ? 'HIPAA Signed': 'Firma HIPAA' }}
                        </label>
                      </div>
                      <div>
                        <img [src]="hSignB64" width="100" height="50" style="border:1px solid lightgray;" />
                      </div>
                      <div class="font10" [ngClass]="{ 'edited' : pat.hSignDt != patOrg.hSignDt }">
                        {{ pat.hSignDt }}
                      </div>
                    </div>
                  </td>
                </tr>

                <tr class="m-0 p-0">
                  <td class="m-0 p-0" colspan="2">

                    <div class="rowBox">

                      <div>
                        <label for="recLast" style="display: block;"
                          [ngClass]="{ 'edited': pat.lastNm != patOrg.lastNm }">
                          {{ engLang ? "Last Name:": "Apellidos:" }}
                        </label>
                        <input type="text" id="recLast" class="form-control p-1"
                          placeholder='{{ engLang ? "Last Name" : "Apellidos" }}'
                          ngbTooltip='{{ engLang ? "Last Name" : "Apellidos" }}' maxlength="20"
                          style="font-weight: bolder; display: block;" [(ngModel)]="pat.lastNm"
                          [ngClass]="errsRec.last ? { 'redBkg': errsRec.last } : { 'edited': pat.lastNm != patOrg.lastNm }"
                          size="20">
                      </div>

                      <div>
                        <label for="recFirst" style="display: block;"
                          [ngClass]="{ 'edited': pat.firstNm != patOrg.firstNm }">
                          {{ engLang ? "First Name:": "Nombre:" }}
                        </label>
                        <input type="text" id="recFirst" class="form-control p-1"
                          placeholder='{{ engLang ? "First Name" : "Nombre" }}'
                          ngbTooltip='{{ engLang ? "First Name" : "Nombre" }}' maxlength="20"
                          style="font-weight: bolder; display: block;" [(ngModel)]="pat.firstNm"
                          [ngClass]="errsRec.first ? { 'redBkg': errsRec.first } : { 'edited': pat.firstNm != patOrg.firstNm }"
                          size="20">
                      </div>

                      <div>
                        <label for="recInit" style="display: block;"
                          [ngClass]="{ 'edited': pat.midInit != patOrg.midInit }">
                          {{ engLang ? "Init:": "Inic:" }}
                        </label>
                        <input type="text" id="recInit" class="form-control p-1 text-center"
                          style="font-weight: bolder;" ngbTooltip='{{ engLang ? "Mid Initial" : "Inicial" }}'
                          maxlength="1" [(ngModel)]="pat.midInit"
                          [ngClass]="errsRec.init ? { 'redBkg': errsRec.init } : { 'edited': pat.midInit != patOrg.midInit }"
                          size="1">
                      </div>

                      <div>
                        <label style="display: block;">
                          {{ engLang ? "Age:": "Edad:" }}
                        </label>
                        <input type="text" value="{{ pat.age }}" class="form-control p-1 text-center" tabindex="-1"
                          style="font-size: 95%; display: block;" readonly size="8">
                      </div>
                    </div>

                  </td>
                </tr>

                <tr class="m-0 p-0">
                  <td class="m-0 p-0" colspan="2">

                    <div class="rowBox">

                      <div>
                        <label for="recAdd1" style="display: block;" [ngClass]="{ 'edited': pat.add1 != patOrg.add1 }">
                          {{ engLang ? "Address 1:" : "Dirección 1:" }}
                        </label>
                        <input type="text" id="recAdd1" class="form-control p-1"
                          placeholder='{{ engLang ? "Address 1" : "Dirección 1" }}'
                          ngbTooltip='{{ engLang ? "Address 1" : "Dirección 1" }}' maxlength="30"
                          style="font-size: 95%; display: block;"
                          [ngClass]="errsRec.addr1 ? { 'redBkg': errsRec.addr1 } : { 'edited': pat.add1 != patOrg.add1 }"
                          [(ngModel)]="pat.add1" (keydown.Tab)="onKeyDown_focus($event,recAdd2.id);" #recAdd1 size="30">
                      </div>

                      <div>
                        <label for="recCell" style="display: block;" [ngClass]="{ 'edited': pat.cell != patOrg.cell }">
                          {{ engLang ? "Cellular:" : "Celular:" }}
                        </label>
                        <input type="text" id="recCell" class="form-control p-1"
                          placeholder='{{ engLang ? "Cell" : "Tel Cel" }}'
                          ngbTooltip='{{ engLang ? "Cell" : "Tel Cel" }}' maxlength="13"
                          style="font-size: 95%; display: block;"
                          [ngClass]="errsRec.cell ? { 'redBkg': errsRec.cell } : { 'edited': pat.cell != patOrg.cell }"
                          [(ngModel)]="pat.cell" (keyup)="preFixPrAreaCode($event)"
                          (keydown.Tab)="onKeyDown_focus($event, recTel.id);"
                          (blur)="pat.cell = fmtTel($event.target.value);" #recCell size="15">
                      </div>
                      <div class="mt-3 pt-2 ml-1">
                        <label for="recCell"><i class="fas fa-mobile-alt"></i></label>
                      </div>

                      <div>
                        <label for="recEmail" style="display: block;"
                          [ngClass]="{ 'edited': pat.email != patOrg.email }">
                          {{ "Email:" }}
                        </label>
                        <input type="text" id="recEmail" class="form-control p-1 no-uppercase" placeholder="Email"
                          maxlength="50" style="font-size: 95%; display: block;" [(ngModel)]="pat.email"
                          (keydown.Tab)="onKeyDown_focus($event, recNotes.id);"
                          [ngClass]="errsRec.email ? { 'redBkg': errsRec.email } : { 'edited': pat.email != patOrg.email }"
                          #recEmail size="24">
                      </div>
                    </div>

                  </td>
                </tr>

                <tr class="m-0 p-0">
                  <td class="m-0 p-0">

                    <div class="rowBox">

                      <div>
                        <label for="recAdd2" style="display: block;" [ngClass]="{ 'edited': pat.add2 != patOrg.add2 }">
                          {{ engLang ? "Address 2:" : "Dirección 2:" }}
                        </label>
                        <input type="text" id="recAdd2" class="form-control p-1"
                          placeholder='{{ engLang ? "Address 2" : "Dirección 2" }}'
                          ngbTooltip='{{ engLang ? "Address 2" : "Dirección 2" }}' maxlength="30"
                          style="font-size: 95%; display: block;"
                          [ngClass]="errsRec.addr2 ? { 'redBkg': errsRec.addr2 } : { 'edited': pat.add2 != patOrg.add2 }"
                          [(ngModel)]="pat.add2" (keydown.Tab)="onKeyDown_focus($event,recZip.id);" #recAdd2 size="30">
                      </div>

                      <div>
                        <label for="recTel" style="display: block;" [ngClass]="{ 'edited': pat.tel != patOrg.tel }">
                          {{ engLang ? "Telephone:" : "Teléfono:" }}
                        </label>
                        <input type="text" id="recTel" class="form-control p-1"
                          placeholder='{{ engLang ? "Telephone" : "Teléfono" }}'
                          ngbTooltip='{{ engLang ? "Telephone" : "Teléfono" }}' maxlength="13"
                          style="font-size: 95%; display: block;"
                          [ngClass]="recTelErr ? { 'redBkg': recTelErr } : { 'edited': pat.tel != patOrg.tel }"
                          [(ngModel)]="pat.tel" (keyup)="preFixPrAreaCode($event)"
                          (keydown.Tab)="onKeyDown_focus($event, recEmail.id);"
                          (blur)="pat.tel = fmtTel($event.target.value)" #recTel size="15">
                      </div>
                      <div class="mt-3 pt-2 ml-1">
                        <label for="recTel"><i class="fas fa-phone"></i></label>
                      </div>
                    </div>
                  </td>

                  <td class="my-0 py-0 mx-1 px-0" rowspan="2">
                    <div class="rowBox">

                      <div>
                        <label for="recNotes" style="display: block;"
                          [ngClass]="{ 'edited': pat.notes != patOrg.notes }">
                          {{ engLang ? "Notes:" : "Notas:" }}
                        </label>
                        <textarea rows="4" id="recNotes" class="form-control p-1"
                          style="font-size: 95%; display: block;" placeholder='{{ engLang ? "Notes" : "Notas" }}'
                          ngbTooltip='{{ engLang ? "Notes" : "Notas" }}' maxlength="150" [(ngModel)]="pat.notes"
                          [ngClass]="{ 'edited': pat.notes != patOrg.notes }"
                          (keydown.Tab)="onKeyDown_focus($event, casDat.id);" #recNotes>
                          </textarea>
                      </div>
                    </div>

                  </td>
                </tr>

                <tr class="m-0 p-0">
                  <td class="m-0 p-0">

                    <div class="rowBox">

                      <div>
                        <label for="recZip" style="display: block;"
                          [ngClass]="errsRec.zip ? { 'redBkg': errsRec.zip } : { 'edited': pat.zip != patOrg.zip }">
                          Zip
                          <img src="app/assets/images/presets-200.png" width="15px" height="15px" alt="preset"
                            ngbTooltip='{{ engLang ? "Preset" : "Fijar" }}'
                            (click)="saveDeleteCookie('pat.zip', pat.zip)">
                        </label>
                        <input type="text" id="recZip" class="form-control p-1" #recZip
                          placeholder='{{ engLang ? "Zip Code" : "Código Postal" }}'
                          ngbTooltip='{{ engLang ? "Zip Code" : "Código Postal" }}' maxlength="10"
                          style="font-size: 95%; display: block;" [(ngModel)]="pat.zip"
                          (blur)="getCityStfromZip(recZip.value)"
                          [ngClass]="errsRec.zip ? { 'redBkg': errsRec.zip } : { 'edited': pat.zip != patOrg.zip }"
                          size="10">
                      </div>

                      <div>
                        <label for="recCity" style="display: block;"
                          [ngClass]="recCityErr ? { 'redBkg': recCityErr } : { 'edited': pat.city != patOrg.city }">
                          {{ engLang ? "City:" : "Ciudad:" }}
                        </label>
                        <input type="text" id="recCity" class="form-control p-1"
                          placeholder='{{ engLang ? "City" : "Ciudad" }}' ngbTooltip='{{ engLang ? "City" : "Ciudad" }}'
                          maxlength="20" style="font-size: 95%; display: block;"
                          [ngClass]="recCityErr ? { 'redBkg': recCityErr } : { 'edited': pat.city != patOrg.city }"
                          [(ngModel)]="pat.city" size="30">
                      </div>

                      <div>
                        <label for="recState" style="display: block;" [ngClass]="{ 'edited': pat.st != patOrg.st }">
                          {{ engLang ? "St:" : "Est:" }}
                        </label>
                        <select id="recState" class="form-control text-center p-1"
                          ngbTooltip='{{ engLang ? "State" : "Estado" }}' style="font-size: 95%; display: block;"
                          [ngClass]="{ 'edited': pat.st != patOrg.st }" [(ngModel)]="pat.st"
                          (keydown.Tab)="onKeyDown_focus($event,recCell.id);" #recState>
                          <option *ngFor="let st of _help.usStates;" [ngValue]="st">{{ st }}</option>
                        </select>
                      </div>

                    </div>

                  </td>
                </tr>

                <tr class="my-0 py-0">
                  <td class="my-0 py-0" colspan="2">

                    <div class="rowBox nogap justify-content-between">
                      <div>
                        <label>
                          {{ engLang ? "Created:": "Creado:" }} {{ pat.created }}
                        </label>
                      </div>
                      <div>
                        <label ngbTooltip="{{engLang ? 'Owed by patient': 'Adeudado por paciente'}}">
                          {{ engLang ? "Owed:": "Deuda:" }} <span
                            [ngStyle]="{ color: +pat.pvdoBal > .009 ? 'red' : 'black' }">{{
                            pat.pvdoBal | currency:'USD':'$':'1.2-2' }}</span>
                        </label>
                      </div>
                      <div>
                        <label>
                          {{ engLang ? "Updated:": "Actualizado:" }} {{ pat.updated }}
                        </label>
                      </div>
                    </div>

                  </td>
                </tr>

              </table>
            </div>

            <div class="text-center sectionTit" ngbTooltip="{{engLang ? 'For future claim': 'Para casos futuros'}}">
              <i class="fas fa-caret-down" *ngIf="!showRecIns" (click)="showHideRecIns()"></i>
              <i class="fas fa-caret-up" *ngIf="showRecIns" (click)="showHideRecIns()"></i>
              {{ engLang ?
              'Insurance': 'Seguros' }}-Record
              <i class="fas fa-caret-up" *ngIf="showRecIns" (click)="showHideRecIns()"></i>
              <i class="fas fa-caret-down" *ngIf="!showRecIns" (click)="showHideRecIns()"></i>
            </div>

            <div *ngIf="showRecIns">
              <!--Insurance-Record-->
              <!--Insurance-Record-->
              <!--Insurance-Record-->
              <!--Insurance-Record-->
              <!--Insurance-Record-->

              <table class="table table-bordered table-sm" style="background: #f7f8f6;">

                <tr class="m-0 p-0">
                  <td class="m-0 p-0">

                    <div class="rowBox">

                      <div>
                        <label for="patInsId1" style="display: block;"
                          [ngClass]="{ 'edited': pat.insId1 != patOrg.insId1 }">
                          ID:
                          <img src="app/assets/images/presets-200.png" width="15px" height="15px" alt="preset"
                            ngbTooltip='{{ engLang ? "Preset" : "Fijar" }}'
                            (click)="saveDeleteCookie('pat.insId1', pat.insId1)">
                        </label>
                        <input type="text" id="patInsId1" class="form-control px-0 text-center"
                          placeholder="{{ 'ID-1' }}" ngbTooltip="{{engLang ? 'Insurance 1 ID': 'Seguro 1 ID' }}"
                          style="font-size: 95%; display: block; width: 45px; height: 31px;" [(ngModel)]="pat.insId1"
                          (keydown)="srchListKeyDownEventHndlr2FilterTab($event,true,'patIns1Alias');"
                          (keyup)="srchListKeyUpEventHndlr($event,true,'patIns1Alias');"
                          [ngClass]="errsRec.insId1 ? { 'redBkg': errsRec.insId1 } : { 'edited': pat.insId1 != patOrg.insId1 }"
                          size="5">
                      </div>

                      <div class="mt-4" ngbTooltip="{{ engLang ? 'Clear Insurance 1': 'Borrar Seguro 1' }}"
                        tabindex="-1">
                        <i class="fa fa-trash" (click)="onClick_clearInsurance('patIns1Alias')"></i>
                      </div>

                      <div>
                        <label for="patIns1Alias" style="display: block;"
                          [ngClass]="{ 'edited': pat.insAlias1 != patOrg.insAlias1 }">
                          Alias:
                        </label>
                        <input type="text" id="patIns1Alias" class="form-control p-1"
                          placeholder="{{engLang ? 'Insurance 1 alias': 'Seguro 1 alias' }}"
                          ngbTooltip="{{engLang ? 'Insurance 1 alias': 'Seguro 1 alias' }}"
                          style="font-size: 95%; display: block;" [(ngModel)]="pat.insAlias1"
                          [ngClass]="errsRec.insId1 ? { 'redBkg': errsRec.insId1 } : { 'edited': pat.insId1 != patOrg.insId1 }"
                          (keydown)="srchListKeyDownEventHndlr2FilterTab($event,false,'patIns1Alias');"
                          (keyup)="srchListKeyUpEventHndlr($event,false,'patIns1Alias');" #patIns1Alias>
                      </div>

                      <div class="d-inline-block mt-4" (keydown.Tab)="onKeyDown_focus($event, recContr1.id);" #insId1>
                        <dd-ngbdropdown [preVal]="pat.insId1" [srchStr]=patIns1Alias.value
                          [rawList]="_recordService.localInsurances" [stylesCfg]="stylesCfg" [listTyp]="'pInsID1'"
                          [engLang]="engLang" (ddnListSelectionClkd)="ddnListSelectionClkd($event)"
                          [tt]="engLang ? 'Search by alias': 'Buscar por alias'"></dd-ngbdropdown>
                      </div>

                    </div>

                  </td>

                  <td class="m-0 p-0">

                    <div class="rowBox">

                      <div>
                        <label for="patInsId2" style="display: block;"
                          [ngClass]="{ 'edited': pat.insId2 != patOrg.insId2 }">
                          ID:
                          <img src="app/assets/images/presets-200.png" width="15px" height="15px" alt="preset"
                            ngbTooltip='{{ engLang ? "Preset" : "Fijar" }}'
                            (click)="saveDeleteCookie('pat.insId2', pat.insId2)">
                        </label>
                        <input type="text" id="patInsId2" class="form-control px-0 text-center"
                          placeholder="{{ 'ID-2' }}" ngbTooltip="{{engLang ? 'Insurance 2 ID': 'Seguro 2 ID' }}"
                          style="font-size: 95%; display: block; width: 45px; height: 31px;" [(ngModel)]="pat.insId2"
                          #patInsId2 (keydown)="srchListKeyDownEventHndlr2FilterTab($event,true,'patIns2Alias');"
                          (keyup)="srchListKeyUpEventHndlr($event,true,'patIns2Alias');"
                          [ngClass]="errsRec.insId2 ? { 'redBkg': errsRec.insId2 } : { 'edited': pat.insId1 != patOrg.insId1 }"
                          size="5">
                      </div>

                      <div class="mt-4" ngbTooltip="{{ engLang ? 'Clear Insurance 2': 'Borrar Seguro 2' }}"
                        tabindex="-1">
                        <i class="fa fa-trash" (click)="onClick_clearInsurance('patIns2Alias')"></i>
                      </div>

                      <div>
                        <label for="patIns2Alias" style="display: block;"
                          [ngClass]="{ 'edited': pat.insAlias2 != patOrg.insAlias2 }">
                          Alias:
                        </label>
                        <input type="text" id="patIns2Alias" class="form-control p-1"
                          placeholder="{{engLang ? 'Insurance 2 alias': 'Seguro 2 alias' }}"
                          ngbTooltip="{{engLang ? 'Insurance 2 alias': 'Seguro 2 alias' }}"
                          style="font-size: 95%; display: block;"
                          [ngClass]="errsRec.insId2 ? { 'redBkg': errsRec.insId2 } : { 'edited': pat.insId2 != patOrg.insId2 }"
                          [(ngModel)]="pat.insAlias2"
                          (keydown)="srchListKeyDownEventHndlr2FilterTab($event,false,'patIns2Alias');"
                          (keyup)="srchListKeyUpEventHndlr($event,false,'patIns2Alias');" #patIns2Alias>
                      </div>

                      <div id="insId2" class="d-inline-block mt-4"
                        (keydown.Tab)="onKeyDown_focus($event, recContr2.id);">

                        <dd-ngbdropdown [preVal]="pat.insId2" [srchStr]=patIns2Alias.value
                          [rawList]="_recordService.localInsurances" [stylesCfg]="stylesCfg" [listTyp]="'pInsID2'"
                          [engLang]="engLang" (ddnListSelectionClkd)="ddnListSelectionClkd($event)"
                          [tt]="engLang ? 'Search by alias': 'Buscar por alias'"></dd-ngbdropdown>
                      </div>

                    </div>

                  </td>
                </tr>

                <tr class="m-0 p-0">
                  <td class="m-0 p-0">

                    <div>
                      <label for="recContr1" style="display: block;"
                        [ngClass]="{ 'edited': pat.contr1 != patOrg.contr1 }">
                        {{ engLang ? "Contract No. 1:" : "No. Contrato 1:" }}
                      </label>
                      <input type="text" id="recContr1" class="form-control"
                        placeholder='{{ engLang ? "Contract No. 1" : "No. Contrato 1" }}'
                        ngbTooltip='{{ engLang ? "Contract No. 1" : "No. Contrato 1" }}' maxlength="30"
                        style="font-size: 95%; display: block;"
                        [ngClass]="errsRec.contr1 ? { 'redBkg': errsRec.contr1 } : { 'edited': pat.contr1 != patOrg.contr1 }"
                        [(ngModel)]="pat.contr1" (blur)="onBlur_copyRecIns2CasIns('1')"
                        (keydown.Tab)="onKeyDown_focus($event, recGroup1.id);" #recContr1 size="20">
                    </div>
                  </td>

                  <td class="m-0 p-0">
                    <div>
                      <label for="recContr2" style="display: block;"
                        [ngClass]="{ 'edited': pat.contr2 != patOrg.contr2 }">
                        {{ engLang ? "Contract No. 2:" : "No. Contrato 2:" }}
                      </label>
                      <input type="text" id="recContr2" class="form-control"
                        placeholder='{{ engLang ? "Contract No. 2" : "No. Contrato 2" }}'
                        ngbTooltip='{{ engLang ? "Contract No. 2" : "No. Contrato 2" }}' maxlength="30"
                        style="font-size: 95%; display: block;"
                        [ngClass]="errsRec.contr2 ? { 'redBkg': errsRec.contr2 } : { 'edited': pat.contr2 != patOrg.contr2 }"
                        [(ngModel)]="pat.contr2" (blur)="onBlur_copyRecIns2CasIns('2')"
                        (keydown.Tab)="onKeyDown_focus($event, recGroup2.id);" #recContr2 size="20">
                    </div>

                  </td>
                </tr>

                <tr class="m-0 p-0">
                  <td class="m-0 p-0">
                    <div class="rowBox">
                      <div>
                        <label for="recGroup1" style="display: block;"
                          [ngClass]="{ 'edited': pat.group1 != patOrg.group1 }">
                          {{ engLang ? "Group No. 1:" : "No. Grupo 1:" }}
                        </label>
                        <input type="text" id="recGroup1" class="form-control"
                          ngbTooltip='{{ engLang ? "Group No. 1" : "No. Grupo 1" }}' maxlength="32"
                          style="font-size: 95%; display: block;"
                          placeholder='{{ engLang ? "Group No. 1" : "No. Grupo 1" }}'
                          [ngClass]="{ 'edited': pat.group1 != patOrg.group1 }" [(ngModel)]="pat.group1"
                          (blur)="onBlur_copyRecIns2CasIns('1')" #recGroup1 size="20">
                      </div>

                      <div>
                        <label for="recDed1" style="display: block;" [ngClass]="{ 'edited': pat.ded1 != patOrg.ded1 }">
                          {{ engLang ? "Deductible 1" : "Deducible 1" }}
                        </label>
                        <input type="text" id="recDed1" class="form-control px-0 text-center"
                          placeholder='{{ engLang ? "Deductible 1" : "Deducible 1" }}'
                          ngbTooltip='{{ engLang ? "Deductible 1" : "Deducible 1" }}'
                          style="font-size: 95%; display: block;" [ngClass]="{ 'edited': pat.ded1 != patOrg.ded1 }"
                          [(ngModel)]="pat.ded1" (blur)="onBlur_copyRecIns2CasIns('1')" maxlength="10"
                          (keydown.Tab)="onKeyDown_focus($event, 'patInsId2');" size="10">

                      </div>
                    </div>
                  </td>

                  <td class="m-0 p-0">
                    <div class="rowBox">
                      <div>
                        <label for="recGroup2" style="display: block;"
                          [ngClass]="{ 'edited': pat.group2 != patOrg.group2 }">
                          {{ engLang ? "Group No. 2:" : "No. Grupo 2:" }}
                        </label>
                        <input type="text" id="recGroup2" class="form-control"
                          ngbTooltip='{{ engLang ? "Group No. 2" : "No. Grupo 2" }}' maxlength="30"
                          style="font-size: 95%; display: block;"
                          placeholder='{{ engLang ? "Group No. 2" : "No. Grupo 2" }}'
                          [ngClass]="{ 'edited': pat.group2 != patOrg.group2 }" [(ngModel)]="pat.group2"
                          (blur)="onBlur_copyRecIns2CasIns('2')" #recGroup2 size="20">
                      </div>

                      <div>
                        <label for="recDed2" style="display: block;" [ngClass]="{ 'edited': pat.ded2 != patOrg.ded2 }">
                          {{ engLang ? "Deductible 2" : "Deducible 2" }}
                        </label>
                        <input type="text" id="recDed2" class="form-control px-0 text-center"
                          placeholder='{{ engLang ? "Deductible 2" : "Deducible 2" }}'
                          ngbTooltip='{{ engLang ? "Deductible 2" : "Deducible 2" }}'
                          style="font-size: 95%; display: block;" [ngClass]="{ 'edited': pat.ded2 != patOrg.ded2 }"
                          [(ngModel)]="pat.ded2" (blur)="onBlur_copyRecIns2CasIns('2')" maxlength="10"
                          (keydown.Tab)="onKeyDown_focus($event, casDat.id);" size="10">

                      </div>
                    </div>
                  </td>
                </tr>

                <tr class="m-0 p-0">
                  <td class="m-0 p-0">
                    <div class="rowBox">

                      <div>
                        <label for="recLastNm1" style="display: block;"
                          [ngClass]="{ 'edited': pat.lastNm1 != patOrg.lastNm1 }">
                          {{ engLang ? "Last Name": "Apellidos" }} Tarjeta
                        </label>
                        <input type="text" id="recLastNm1" class="form-control p-1"
                          placeholder='{{ engLang ? "Last Name" : "Apellidos" }}'
                          ngbTooltip='{{ engLang ? "Last Name" : "Apellidos" }}' maxlength="20"
                          style="font-size: 95%; display: block;" [(ngModel)]="pat.lastNm1"
                          [ngClass]="errsRec.lastIns1 ? { 'redBkg': errsRec.lastIns1 } : { 'edited': pat.lastNm1 != patOrg.lastNm1 }"
                          (blur)="onBlur_copyRecIns2CasIns('1')" size="20">
                      </div>

                      <div>
                        <label for="recFirstNm1" style="display: block;"
                          [ngClass]="{ 'edited': pat.firstNm1 != patOrg.firstNm1 }">
                          {{ engLang ? "First Name": "Nombre" }} Tarjeta
                        </label>
                        <input type="text" id="recFirstNm1" class="form-control p-1"
                          placeholder='{{ engLang ? "First Name" : "Nombre" }}'
                          ngbTooltip='{{ engLang ? "First Name" : "Nombre" }}' maxlength="20"
                          style="font-size: 95%; display: block;" [(ngModel)]="pat.firstNm1"
                          [ngClass]="errsRec.firstIns1 ? { 'redBkg': errsRec.firstIns1 } : { 'edited': pat.firstNm1 != patOrg.firstNm1 }"
                          (blur)="onBlur_copyRecIns2CasIns('1')" size="15">
                      </div>

                      <div>
                        <label for="recMidInit1" style="display: block;"
                          [ngClass]="{ 'edited': pat.midInit1 != patOrg.midInit1 }">
                          {{ engLang ? "Init:": "Inic:" }}
                        </label>
                        <input type="text" id="recMidInit1" class="form-control p-1"
                          ngbTooltip='{{ engLang ? "Mid Initial" : "Inicial" }} Tarjeta'
                          style="font-size: 95%; display: block;" maxlength="1" [(ngModel)]="pat.midInit1"
                          [ngClass]="errsRec.initIns1 ? { 'redBkg': errsRec.initIns1 } : { 'edited': pat.midInit1 != patOrg.midInit1 }"
                          (keydown.Tab)="onKeyDown_focus($event, patInsId2.id);" (blur)="onBlur_copyRecIns2CasIns('1')"
                          size="1">
                      </div>
                    </div>
                  </td>
                  <td class="m-0 p-0">
                    <div class="rowBox">

                      <div>
                        <label for="recLastNm2" style="display: block;"
                          [ngClass]="{ 'edited': pat.lastNm2 != patOrg.lastNm2 }">
                          {{ engLang ? "Last Name": "Apellidos" }} Tarjeta
                        </label>
                        <input type="text" id="recLastNm2" class="form-control p-1"
                          placeholder='{{ engLang ? "Last Name" : "Apellidos" }}'
                          ngbTooltip='{{ engLang ? "Last Name" : "Apellidos" }}' maxlength="20"
                          style="font-size: 95%; display: block;" [(ngModel)]="pat.lastNm2"
                          [ngClass]="errsRec.lastIns2 ? { 'redBkg': errsRec.lastIns2 } : { 'edited': pat.lastNm2 != patOrg.lastNm2 }"
                          (blur)="onBlur_copyRecIns2CasIns('2')" size="20">
                      </div>

                      <div>
                        <label for="recFirstNm2" style="display: block;"
                          [ngClass]="{ 'edited': pat.firstNm2 != patOrg.firstNm2 }">
                          {{ engLang ? "First Name": "Nombre" }} Tarjeta
                        </label>
                        <input type="text" id="recFirstNm2" class="form-control p-1"
                          placeholder='{{ engLang ? "First Name" : "Nombre" }}'
                          ngbTooltip='{{ engLang ? "First Name" : "Nombre" }}' maxlength="20"
                          style="font-size: 95%; display: block;" [(ngModel)]="pat.firstNm2"
                          [ngClass]="errsRec.firstIns2 ? { 'redBkg': errsRec.firstIns2 } : { 'edited': pat.firstNm2 != patOrg.firstNm2 }"
                          (blur)="onBlur_copyRecIns2CasIns('2')" size="15">
                      </div>

                      <div>
                        <label for="recMidInit2" style="display: block;"
                          [ngClass]="{ 'edited': pat.midInit2 != patOrg.midInit2 }">
                          {{ engLang ? "Init:": "Inic:" }}
                        </label>
                        <input type="text" id="recMidInit2" class="form-control p-1"
                          ngbTooltip='{{ engLang ? "Mid Initial" : "Inicial" }} Tarjeta'
                          style="font-size: 95%; display: block;" maxlength="1" [(ngModel)]="pat.midInit2"
                          [ngClass]="errsRec.initIns2 ? { 'redBkg': errsRec.initIns2 } : { 'edited': pat.midInit2 != patOrg.midInit2 }"
                          (keydown.Tab)="onKeyDown_focus($event, casDat.id);" (blur)="onBlur_copyRecIns2CasIns('2')"
                          size="1">
                      </div>
                    </div>
                  </td>
                </tr>

              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- <div>
        ###########
        <pre>{{ selectedCase[selectedCaseIndx].sofSign }}</pre>
        <pre>{{ selectedCase[selectedCaseIndx].sofSignDt }}</pre>
        ###########
      </div> -->

      <div>
        <!--Case-->
        <!--Case-->
        <!--Case-->
        <!--Case-->
        <!--Case-->

        <br>
        <div class="d-flex justify-content-between">
          <div>
            <!-- Nothing here -->
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>

          <div>
            <div class="text-center sectionTit">
              {{ engLang ? 'Case No:': 'Caso No:' }}
              &nbsp;
              <span *ngIf="selectedCase[selectedCaseIndx]?.casNo" style="font-weight: bolder;">{{
                selectedCase[selectedCaseIndx].casNo }}-{{ selectedCase[selectedCaseIndx].casOfNo }}
              </span>
              <span *ngIf="!selectedCase[selectedCaseIndx]?.casNo" style="font-weight: bolder;">
                {{ engLang ? 'New': 'Nuevo' }}
              </span>
            </div>

            <div class="text-center pt-0">
              <label for="casDat" class="pt-1 font85"
                [ngClass]="{ 'edited' : selectedCase[selectedCaseIndx]?.casDat != casOrg?.casDat }">
                {{ engLang ? 'Case Date:': 'Fecha del Caso:' }}
                <img src="app/assets/images/presets-200.png" width="15px" height="15px" alt="preset"
                  ngbTooltip='{{ engLang ? "Preset" : "Fijar" }}'
                  (click)="saveDeleteCookie('casDat', selectedCase[selectedCaseIndx].casDat)">
              </label>

              <div class="d-inline-flex" *ngIf="selectedCase[selectedCaseIndx]">
                <input id="casDat" class="form-control p-1" style="width: 90px;" placeholder="mm/dd/yy" name="dtp"
                  #casDat #cdat="ngModel" [(ngModel)]="selectedCase[selectedCaseIndx].casDate" ngbDatepicker
                  parserFormatter="CustomDateParserFormatter"
                  (dateSelect)="selectedCase[selectedCaseIndx].casDate = onDtpDateSelect('casDat',ddat._inputValue,undefined,undefined,selectedCase[selectedCaseIndx])"
                  #ddat="ngbDatepicker"
                  [ngClass]="errsCas.dat ? { 'redBkg': errsCas.dat } : { 'edited' : selectedCase[selectedCaseIndx].casDat != casOrg?.casDat }"
                  (blur)="selectedCase[selectedCaseIndx].casDate = onDtpDateSelect('casDat',ddat._inputValue,undefined,undefined,selectedCase[selectedCaseIndx])" />
                <i class="far fa-calendar-times mt-2" (click)="ddat.toggle()"></i>
              </div>
            </div>

            <div class="text-center" style="height: 16px;"
              *ngIf="selectedCaseIndx > 0 && selectedCase[selectedCaseIndx]?.casDtCated">
              <small class="form-text text-muted m-0" style="font-size: 60%;">
                {{ engLang ? 'Created: ': 'Creado: '}}{{ selectedCase[selectedCaseIndx].casDtCated }}
              </small>
            </div>
          </div>

          <div (click)="tmpStr=(engLang ? 'Visit Signature': 'Firma Encuentro'); tmpSign = selectedCase[selectedCaseIndx].sofSign; open(signCaptureModal, { backdrop: 'static',
            size: 'md', scrollable: false }, 'signCaptureModal'); signCanvas($event)"
            style="margin-top: -20px; cursor: pointer;">
            <div class="d-inline-flex flex-column justify-content-top align-items-center">
              <div>
                <label class="pseudoLink" style="display: block; font-size: 12px;">
                  {{ engLang ? 'Visit Signature': 'Firma Encuentro' }}
                </label>
              </div>
              <div>
                <img [src]="sofSignB64" width="100" height="50" style="border:1px solid lightgray;" />
              </div>
              <div class="font10"
                [ngClass]="{ 'edited' : selectedCase[selectedCaseIndx]?.sofSignDt != casOrg?.sofSignDt }">
                <!-- Added optional chaining above to avoid RTErr(2/14/24) ERROR TypeError: Cannot read properties of undefined (reading 'sofSignDt') -->
                <span>{{ selectedCase[selectedCaseIndx]?.sofSignDt }}</span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <table class="table table-bordered table-sm" style="background: #faf0e7;">

            <tr class="m-0 p-0">
              <td class="m-0 p-0">

                <div class="rowBox" *ngIf="selectedCase[selectedCaseIndx]"> <!-- Dont render if not ready -->

                  <div>
                    <label for="casProvId" style="display: block;"
                      [ngClass]="{ 'edited' : selectedCase[selectedCaseIndx].casProvID != casOrg?.casProvID }">
                      ID:
                      <img src="app/assets/images/presets-200.png" width="15px" height="15px" alt="preset"
                        ngbTooltip='{{ engLang ? "Preset" : "Fijar" }}'
                        (click)="saveDeleteCookie('casProvID', selectedCase[selectedCaseIndx].casProvID)">
                    </label>
                    <input type="text" id="casProvId" class="form-control px-0 text-center" placeholder="ID"
                      ngbTooltip="{{engLang ? 'Provider (local) ID': 'ID (local) Proveedor' }}"
                      style="font-size: 95%; display: block; width: 45px; height: 31px;"
                      [ngClass]="errsCas.provId ? { 'redBkg': errsCas.provId } : { 'edited' : selectedCase[selectedCaseIndx].casProvID != casOrg?.casProvID }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casProvID"
                      (keydown)="srchListKeyDownEventHndlr2FilterTab($event,true,'casProvAlias');"
                      (keyup)="srchListKeyUpEventHndlr($event,true,'casProvAlias');" size="5">
                  </div>

                  <div class="mt-4" ngbTooltip="{{ engLang ? 'Clear Provider': 'Borrar Proveedor' }}" tabindex="-1">
                    <i class="fa fa-trash" (click)="onClick_clearProvider()"></i>
                  </div>

                  <div class="m-0 p-0">
                    <label for="casProvAlias" style="display: block;"
                      [ngClass]="{ 'edited' : selectedCase[selectedCaseIndx].casProvID != casOrg?.casProvID }">
                      Prov:
                    </label>
                    <input type="text" id="casProvAlias" class="form-control p-1"
                      placeholder="{{ engLang ? 'Provider alias': 'Proveedor alias' }}"
                      ngbTooltip="{{engLang ? 'Provider alias': 'Proveedor alias'}}"
                      style="font-size: 95%; display: block;"
                      [ngClass]="errsCas.provId ? { 'redBkg': errsCas.provId } : { 'edited' : selectedCase[selectedCaseIndx].casProvID != casOrg?.casProvID }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casProvAlias"
                      (keydown)="srchListKeyDownEventHndlr2FilterTab($event,false,'casProvAlias');"
                      (keyup)="srchListKeyUpEventHndlr($event,false,'casProvAlias');" #casProvAlias size="20">
                  </div>

                  <div class="d-inline-block mt-4">
                    <dd-ngbdropdown [preVal]="selectedCase[selectedCaseIndx].casProvID" [srchStr]=casProvAlias.value
                      [rawList]="_recordService.localProviders" [stylesCfg]="stylesCfg" [listTyp]="'provID'"
                      [engLang]="engLang" [height]="'600px'" (ddnListSelectionClkd)="ddnListSelectionClkd($event)"
                      [tt]=" engLang ? 'Search by alias': 'Buscar por alias' "></dd-ngbdropdown>
                  </div>

                </div>
              </td>
              <td class="m-0 p-0">

                <div class="rowBox" *ngIf="selectedCase[selectedCaseIndx]"> <!-- Dont render if not ready -->

                  <div>
                    <label for="casRefID" style="display: block;"
                      [ngClass]="{ 'edited' : selectedCase[selectedCaseIndx].casRefID != casOrg?.casRefID }">
                      ID:
                      <img src="app/assets/images/presets-200.png" width="15px" height="15px" alt="preset"
                        ngbTooltip='{{ engLang ? "Preset" : "Fijar" }}'
                        (click)="saveDeleteCookie('casRefID', selectedCase[selectedCaseIndx].casRefID)">
                    </label>
                    <input type="text" id="casRefID" class="form-control px-0 text-center"
                      style="font-size: 95%; display: block; width: 45px; height: 31px;" placeholder="{{ 'ID' }}"
                      ngbTooltip="{{engLang ? 'Referring (local) ID': 'ID (local) Refiere' }}"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casRefID"
                      [ngClass]="errsCas.refID ? { 'redBkg': errsCas.refID } : { 'edited': selectedCase[selectedCaseIndx].casRefID != casOrg?.casRefID }"
                      readonly tabindex="-1" size="5">
                  </div>

                  <div class="mt-4" ngbTooltip="{{ engLang ? 'Clear Referring': 'Borrar Refiere' }}" tabindex="-1">
                    <i class="fa fa-trash" (click)="onClick_clearReferring()"></i>
                  </div>

                  <div class="col-sm-5 col-md-5 col-lg-7 col-xl-7 mx-0 px-0">
                    <label for="casRefAlias" style="display: block;">
                      Ref:
                    </label>
                    <input type="text" id="casRefAlias" class="form-control p-1"
                      placeholder="{{engLang ? 'Last[,] First': 'Apellidos[,] Nombre' }}"
                      ngbTooltip="{{engLang ? 'Referring': 'Refiere' }}" style="font-size: 95%; display: block;"
                      (keydown)="srchListKeyDownEventHndlr2FilterTab($event,false,'casRefAlias');"
                      (keyup)="srchListKeyUpEventHndlr($event,false,'casRefAlias');" #casRefAlias size="20">
                  </div>

                  <div class="d-inline-block mt-4">
                    <dd-ngbdropdown [preVal]="selectedCase[selectedCaseIndx].casRefID" [srchStr]=casRefAlias.value
                      [rawList]="_recordService.localReferring" [stylesCfg]="stylesCfg" [listTyp]="'refNpi'"
                      [engLang]="engLang" [height]="'600px'" [width]="'300px'"
                      (ddnListSelectionClkd)="ddnListSelectionClkd($event)"
                      [tt]="engLang ? 'Search by last[,] first': 'Buscar por apellido[,] nombre'"></dd-ngbdropdown>
                  </div>

                </div>

              </td>
              <td class="m-0 p-0">

                <div class="rowBox" *ngIf="selectedCase[selectedCaseIndx]"> <!-- Dont render if not ready -->

                  <div>
                    <label for="casFacId" style="display: block;"
                      [ngClass]="{ 'edited' : selectedCase[selectedCaseIndx].casFacID != casOrg?.casFacID }">
                      ID:
                      <img src="app/assets/images/presets-200.png" width="15px" height="15px" alt="preset"
                        ngbTooltip='{{ engLang ? "Preset" : "Fijar" }}'
                        (click)="saveDeleteCookie('casFacID', selectedCase[selectedCaseIndx].casFacID)">
                    </label>
                    <input type="text" id="casFacId" class="form-control px-0 text-center" #casFacId
                      style="font-size: 95%; display: block; width: 45px; height: 31px;" placeholder="{{ 'ID' }}"
                      ngbTooltip="{{engLang ? 'Facility (local) ID': 'ID (local) Facilidad' }}"
                      [ngClass]="errsCas.facId ? { 'redBkg': errsCas.facId } : { 'edited': selectedCase[selectedCaseIndx].casFacID != casOrg?.casFacID }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casFacID"
                      (keydown)="srchListKeyDownEventHndlr2FilterTab($event,true,'casFacAlias');"
                      (keyup)="srchListKeyUpEventHndlr($event,true,'casFacID');" size="5">
                  </div>

                  <div class="mt-4" ngbTooltip="{{ engLang ? 'Clear Referring': 'Borrar Refiere' }}" tabindex="-1">
                    <i class="fa fa-trash" (click)="onClick_clearFacility()"></i>
                  </div>

                  <div>
                    <label for="casFacAlias" style="display: block;">
                      Fac:
                    </label>
                    <input type="text" id="casFacAlias" class="form-control p-1"
                      placeholder="{{engLang ? 'Facility alias': 'Facilidad alias' }}"
                      ngbTooltip="{{engLang ? 'Facility alias': 'Facilidad alias' }}"
                      style="font-size: 95%; display: block;"
                      [ngClass]="errsCas.facId ? { 'redBkg': errsCas.facId } : { 'edited': selectedCase[selectedCaseIndx].casFacID != casOrg?.casFacID }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casFacAlias"
                      (keydown)="srchListKeyDownEventHndlr2FilterTab($event,false,'casFacAlias');"
                      (keyup)="srchListKeyUpEventHndlr($event,false,'casFacAlias');" #casFacAlias size="20">
                  </div>

                  <div class="d-inline-block mt-4">
                    <dd-ngbdropdown [preVal]="selectedCase[selectedCaseIndx].casFacID" [srchStr]=casFacAlias.value
                      [rawList]="_recordService.localFacilities" [stylesCfg]="stylesCfg" [listTyp]="'facID'"
                      [engLang]="engLang" (ddnListSelectionClkd)="ddnListSelectionClkd($event)"
                      [tt]="engLang ? 'Search by alias': 'Buscar por alias'"></dd-ngbdropdown>
                  </div>

                </div>

              </td>
            </tr>
            <tr class="m-0 p-0">
              <td class="m-0 p-0">

                <div class="rowBox" *ngIf="selectedCase[selectedCaseIndx]"> <!-- Dont render if not ready -->
                  <div>
                    <label for="casAuthNo" class="xxsmall" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casAuthNo != casOrg?.casAuthNo }">
                      {{ engLang ? "Authoriz:" : "Autoriz:" }}
                    </label>
                    <input type="text" id="casAuthNo" class="form-control p-1" #casAuthNo
                      placeholder='{{ engLang ? "Authorization:" : "Autorización:" }}'
                      ngbTooltip='{{ engLang ? "Authorization No.:" : "No. Autorización:" }}' maxlength="25"
                      style="font-size: 95%; display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casAuthNo != casOrg?.casAuthNo }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casAuthNo" size="15">
                  </div>

                  <div>
                    <label for="casRefNo" class="xxsmall" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casRefNo != casOrg?.casRefNo }">
                      {{ engLang ? "Referral#:" : "#Referido:" }}
                    </label>
                    <input type="text" id="casRefNo" class="form-control p-1"
                      placeholder='{{ engLang ? "Referral:" : "Referido:" }}'
                      ngbTooltip='{{ engLang ? "Referral No.:" : "No. Referido:" }}' maxlength="25"
                      style="font-size: 95%; display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casRefNo != casOrg?.casRefNo }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casRefNo"
                      (keydown.Tab)="onKeyDown_focus($event, casAdmDt.id)" size="15">
                  </div>
                </div>

              </td>
              <td colspan="2" class="m-0 p-0">

                <div class="rowBox" *ngIf="selectedCase[selectedCaseIndx]"> <!-- Dont render if not ready -->

                  <div>
                    <label for="casRefNPI" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casRefNPI != casOrg?.casRefNPI }">
                      {{ engLang ? "Ref NPI:" : "NPI Ref:" }}
                    </label>
                    <input type="text" id="casRefNPI" class="form-control p-1" placeholder='NPI' ngbTooltip='NPI'
                      maxlength="10" style="font-size: 95%; display: block;"
                      [ngClass]="errsCas.refNpi ? { 'redBkg': errsCas.refNpi } : { 'edited': selectedCase[selectedCaseIndx].casRefNPI != casOrg?.casRefNPI }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casRefNPI" (blur)="ckDigitNPI(casRefNPI.value)"
                      #casRefNPI size="10">
                  </div>

                  <div>
                    <label for="casRefLastNm" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casRefLastNm != casOrg?.casRefLastNm }">
                      {{ engLang ? "Ref Last:" : "Apellidos Ref:" }}
                    </label>
                    <input type="text" id="casRefLastNm" class="form-control p-1"
                      placeholder='{{ engLang ? "Last Name Referring" : "Apellidos Refiere" }}'
                      ngbTooltip='{{ engLang ? "Last Name Referring" : "Apellidos Refiere" }}' maxlength="20"
                      style="font-size: 95%; display: block;"
                      [ngClass]="errsCas.refLastNm ? { 'redBkg': errsCas.refLastNm } : { 'edited': selectedCase[selectedCaseIndx].casRefLastNm != casOrg?.casRefLastNm }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casRefLastNm" size="20">
                  </div>

                  <div>
                    <label for="casRefFirstNm" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casRefFirstNm != casOrg?.casRefFirstNm }">
                      {{ engLang ? "Ref Name:" : "Nombre Ref:" }}
                    </label>
                    <input type="text" id="casRefFirstNm" class="form-control p-1"
                      placeholder='{{ engLang ? "First Name Referring" : "Nombre Refiere" }}'
                      ngbTooltip='{{ engLang ? "First Name Referring" : "Nombre Refiere" }}' maxlength="20"
                      style="font-size: 95%; display: block;"
                      [ngClass]="errsCas.refFirstNm ? { 'redBkg': errsCas.refFirstNm } : { 'edited': selectedCase[selectedCaseIndx].casRefFirstNm != casOrg?.casRefFirstNm }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casRefFirstNm"
                      (keydown.Tab)="onKeyDown_focus($event, casFacId.id)" size="15">
                  </div>

                  <div>
                    <label for="casAdmDt" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casAdmDt != casOrg?.casAdmDt }">
                      {{ engLang ? "Admitted:" : "Admitido:" }}
                    </label>
                    <div class="d-sm-inline-flex">
                      <input id="casAdmDt" class="form-control p-2" placeholder="mm/dd/yy" name="dtp" #cfrom="ngModel"
                        #casAdmDt style="width: 80px; height: 30px; font-size: 95%;"
                        [(ngModel)]="selectedCase[selectedCaseIndx].casAdmDtYy" ngbDatepicker
                        parserFormatter="CustomDateParserFormatter"
                        (dateSelect)="selectedCase[selectedCaseIndx].casAdmDtYy = onDtpDateSelect('casAdmDt',dadm._inputValue,undefined,undefined,selectedCase[selectedCaseIndx])"
                        #dadm="ngbDatepicker"
                        [ngClass]="errsCas.admDt ? { 'redBkg': errsCas.admDt } : { 'edited': selectedCase[selectedCaseIndx].casAdmDt != casOrg?.casAdmDt }"
                        (blur)="selectedCase[selectedCaseIndx].casAdmDtYy = onDtpDateSelect('casAdmDt',dadm._inputValue,undefined,undefined,selectedCase[selectedCaseIndx])" />
                      <i class="far fa-calendar-times mt-2" (click)="dadm.toggle()"></i>
                    </div>
                  </div>

                  <div>
                    <label for="casDiscDt" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casDiscDt != casOrg?.casDiscDt }">
                      {{ engLang ? "Discharged:" : "Alta:" }}
                    </label>
                    <div class="d-sm-inline-flex">
                      <input id="casDiscDt" class="form-control p-2" placeholder="mm/dd/yy" name="dtp" #cfrom="ngModel"
                        style="width: 80px; height: 30px; font-size: 95%;"
                        [(ngModel)]="selectedCase[selectedCaseIndx].casDiscDtYy" ngbDatepicker
                        parserFormatter="CustomDateParserFormatter"
                        (dateSelect)="selectedCase[selectedCaseIndx].casDiscDtYy = onDtpDateSelect('casDiscDt',ddisc._inputValue,undefined,undefined,selectedCase[selectedCaseIndx])"
                        #ddisc="ngbDatepicker"
                        [ngClass]="errsCas.discDt ? { 'redBkg': errsCas.discDt } : { 'edited': selectedCase[selectedCaseIndx].casDiscDt != casOrg?.casDiscDt }"
                        (blur)="selectedCase[selectedCaseIndx].casDiscDtYy = onDtpDateSelect('casDiscDt',ddisc._inputValue,undefined,undefined,selectedCase[selectedCaseIndx])" />
                      <i class="far fa-calendar-times mt-2" (click)="ddisc.toggle()"></i>
                    </div>
                  </div>

                </div>
              </td>
            </tr>

          </table>
        </div>

      </div>

      <div>
        <!--Insurance-Case-->
        <!--Insurance-Case-->
        <!--Insurance-Case-->
        <!--Insurance-Case-->
        <!--Insurance-Case-->

        <div class="text-center sectionTit" ngbTooltip="{{engLang ? 'For this claim': 'Para este caso'}}">
          {{ engLang ? 'Insurance-Case': 'Seguros-Caso' }}
        </div>

        <div>
          <table class="table table-bordered table-sm" style="background: #fcfceb;">

            <tr class="my-0 py-0">
              <td class="my-0 py-0">

                <div class="input-group my-1 py-0" *ngIf="selectedCase[selectedCaseIndx]">
                  <!-- Dont render if not ready -->

                  <div class="col-sm-3 col-md-2 col-lg-1 col-xl-1 mx-0 px-0">
                    <label for="casI1ID" class="label2bottom mb-0 pb-0" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casI1ID != casOrg?.casI1ID }">
                      ID:&nbsp;<i class="fa fa-umbrella" *ngIf="!eligibSpin1"
                        [ngStyle]="{ 'color': (+selectedCase[selectedCaseIndx].eligib1 === 1 ? 'green':(+selectedCase[selectedCaseIndx].eligib1 === -1?'red':'')),'display':(!+selectedCase[selectedCaseIndx].eligib1)?'none':'inline-block'}"></i>
                      <img src="app/assets/images/waiting4Response.gif" width="20px" height="20px"
                        alt="waiting for eligibility" *ngIf="eligibSpin1">
                    </label>
                    <input type="text" id="casI1ID" class="form-control px-0 text-center" #casI1ID
                      placeholder="{{ 'ID-1' }}" ngbTooltip="{{engLang ? 'Insurance 1 ID': 'Seguro 1 ID' }}"
                      style="font-size: 95%; display: block;" [(ngModel)]="selectedCase[selectedCaseIndx].casI1ID"
                      (keydown)="srchListKeyDownEventHndlr2FilterTab($event,true,'casIns1Alias');"
                      (keyup)="srchListKeyUpEventHndlr($event,true,'casIns1Alias');"
                      [ngClass]="errsCas.i1ID ? { 'redBkg': errsCas.i1ID } : { 'edited': selectedCase[selectedCaseIndx].casI1ID != casOrg?.casI1ID }"
                      size="5">
                  </div>

                  <span class="mt-3 pt-2 px-1" ngbTooltip="{{ engLang ? 'Clear Insurance 1': 'Borrar Seguro 1' }}"
                    tabindex="-1">
                    <i class="fa fa-trash" (click)="onClick_clearInsurance('casIns1Alias')"></i>
                  </span>

                  <div class="col-sm-4 col-md-7 col-lg-9 col-xl-9 mx-0 px-0">
                    <label for="casIns1Alias" class="label2bottom mb-0 pb-0" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casIns1Alias != casOrg?.casIns1Alias }">
                      Alias:
                    </label>
                    <input type="text" id="casIns1Alias" class="form-control p-1"
                      placeholder="{{engLang ? 'Insurance 1 alias': 'Seguro 1 alias' }}"
                      ngbTooltip="{{engLang ? 'Insurance 1 alias': 'Seguro 1 alias' }}"
                      style="font-size: 95%; display: block;"
                      [ngClass]="errsCas.i1ID ? { 'redBkg': errsCas.i1ID } : { 'edited': selectedCase[selectedCaseIndx].casI1ID != casOrg?.casI1ID }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casIns1Alias"
                      (keydown)="srchListKeyDownEventHndlr2FilterTab($event,false,'casIns1Alias');"
                      (keyup)="srchListKeyUpEventHndlr($event,false,'casIns1Alias');" #casIns1Alias size="20">
                  </div>

                  <span class="mt-3 pl-1" style="height: 31px;">

                    <div class="d-inline-block" (keydown.Tab)="onKeyDown_focus($event, casContr1.id);">

                      <dd-ngbdropdown [preVal]="selectedCase[selectedCaseIndx].casI1ID" [srchStr]=casIns1Alias.value
                        [rawList]="_recordService.localInsurances" [stylesCfg]="stylesCfg" [listTyp]="'cInsID1'"
                        [engLang]="engLang" (ddnListSelectionClkd)="ddnListSelectionClkd($event)"
                        [tt]="engLang ? 'Search by alias': 'Buscar por alias'"></dd-ngbdropdown>
                    </div>

                  </span>
                </div>

              </td>

              <td class="my-0 py-0">

                <div class="input-group my-1 py-0" *ngIf="selectedCase[selectedCaseIndx]">
                  <!-- Dont render if not ready -->

                  <div class="col-sm-3 col-md-2 col-lg-1 col-xl-1 mx-0 px-0">
                    <label for="casI2ID" class="label2bottom mb-0 pb-0" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casI2ID != casOrg?.casI2ID }">
                      ID:&nbsp;<i class="fa fa-umbrella" *ngIf="!eligibSpin2"
                        [ngStyle]="{ 'color': (+selectedCase[selectedCaseIndx].eligib2 === 1 ? 'green':(+selectedCase[selectedCaseIndx].eligib2 === -1?'red':'')),'display':(!+selectedCase[selectedCaseIndx].eligib2)?'none':'inline-block'}"></i>
                      <img src="app/assets/images/waiting4Response.gif" width="20px" height="20px"
                        alt="waiting for eligibility" *ngIf="eligibSpin2">
                    </label>
                    <input type="text" id="casI2ID" class="form-control px-0 text-center" #casI2ID
                      placeholder="{{ 'ID-2' }}" ngbTooltip="{{engLang ? 'Insurance 2 ID': 'Seguro 2 ID' }}"
                      style="font-size: 95%; display: block;" [(ngModel)]="selectedCase[selectedCaseIndx].casI2ID"
                      (keydown)="srchListKeyDownEventHndlr2FilterTab($event,true,'casIns2Alias');"
                      (keyup)="srchListKeyUpEventHndlr($event,true,'casIns2Alias');"
                      [ngClass]="errsCas.i2ID ? { 'redBkg': errsCas.i2ID } : { 'edited': selectedCase[selectedCaseIndx].casI2ID != casOrg?.casI2ID }"
                      size="5">
                  </div>

                  <span class="mt-3 pt-2 px-1" ngbTooltip="{{ engLang ? 'Clear Insurance 2': 'Borrar Seguro 2' }}"
                    tabindex="-1">
                    <i class="fa fa-trash" (click)="onClick_clearInsurance('casIns2Alias')"></i>
                  </span>

                  <div class="col-sm-4 col-md-7 col-lg-9 col-xl-9 mx-0 px-0">
                    <label for="casIns2Alias" class="label2bottom mb-0 pb-0" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casIns2Alias != casOrg?.casIns2Alias }">
                      Alias:
                    </label>
                    <input type="text" id="casIns2Alias" class="form-control p-1"
                      placeholder="{{engLang ? 'Insurance 2 alias': 'Seguro 2 alias' }}"
                      ngbTooltip="{{engLang ? 'Insurance 2 alias': 'Seguro 2 alias' }}"
                      style="font-size: 95%; display: block;"
                      [ngClass]="errsCas.i2ID ? { 'redBkg': errsCas.i2ID } : { 'edited': selectedCase[selectedCaseIndx].casI2ID != casOrg?.casI2ID }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casIns2Alias"
                      (keydown)="srchListKeyDownEventHndlr2FilterTab($event,false,'casIns2Alias');"
                      (keyup)="srchListKeyUpEventHndlr($event,false,'casIns2Alias');" #casIns2Alias size="20">
                  </div>

                  <span class="mt-3 pl-1" style="height: 31px;">

                    <div class="d-inline-block" (keydown.Tab)="onKeyDown_focus($event, casContr2.id);">

                      <dd-ngbdropdown [preVal]="selectedCase[selectedCaseIndx].casI2ID" [srchStr]=casIns2Alias.value
                        [rawList]="_recordService.localInsurances" [stylesCfg]="stylesCfg" [listTyp]="'cInsID2'"
                        [engLang]="engLang" (ddnListSelectionClkd)="ddnListSelectionClkd($event)"
                        [tt]="engLang ? 'Search by alias': 'Buscar por alias'"></dd-ngbdropdown>
                    </div>

                  </span>
                </div>

              </td>
            </tr>

            <tr class="m-0 p-0">
              <td class="m-0 p-0">
                <div class="rowBox" *ngIf="selectedCase[selectedCaseIndx]"> <!-- Dont render if not ready -->
                  <div>
                    <label for="casContr1" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casCont1 != casOrg?.casCont1 }">
                      {{ engLang ? "Contract No. 1:" : "No. Contrato 1:" }}
                    </label>
                    <input type="text" id="casContr1" class="form-control" #casContr1
                      placeholder='{{ engLang ? "Contract No. 1" : "No. Contrato 1" }}'
                      ngbTooltip='{{ engLang ? "Contract No. 1" : "No. Contrato 1" }}' maxlength="30"
                      style="font-size: 95%; display: block;"
                      [ngClass]="errsCas.contr1 ? { 'redBkg': errsCas.contr1 } : { 'edited': selectedCase[selectedCaseIndx].casCont1 != casOrg?.casCont1 }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casCont1"
                      (keydown.Tab)="onKeyDown_focus($event, casGroup1.id);" size="20">
                  </div>

                  <div>
                    <label for="casGroup1" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casGrp1 != casOrg?.casGrp1 }">
                      {{ engLang ? "Group No. 1:" : "No. Grupo 1:" }}
                    </label>
                    <input type="text" id="casGroup1" class="form-control" #casGroup1
                      ngbTooltip='{{ engLang ? "Group No. 1" : "No. Grupo 1" }}' maxlength="30"
                      style="font-size: 95%; display: block;"
                      placeholder='{{ engLang ? "Group No. 1" : "No. Grupo 1" }}'
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casGrp1 != casOrg?.casGrp1 }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casGrp1" size="20">
                  </div>

                  <div>
                    <label for="casDed1" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casDed1 != casOrg?.casDed1 }">
                      {{ engLang ? "Deductible 1:" : "Deducible 1:" }}
                    </label>
                    <input type="text" id="casDed1" class="form-control px-0 text-center"
                      placeholder='{{ engLang ? "Deductible 1" : "Deducible 1" }}'
                      ngbTooltip='{{ engLang ? "Deductible 1" : "Deducible 1" }}'
                      style="font-size: 95%; display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casDed1 != casOrg?.casDed1 }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casDed1" maxlength="10"
                      (keydown.Tab)="onKeyDown_focus($event, casI2ID.id);" size="10">

                  </div>
                </div>
              </td>

              <td class="m-0 p-0">
                <div class="rowBox" *ngIf="selectedCase[selectedCaseIndx]"> <!-- Dont render if not ready -->
                  <div>
                    <label for="casContr2" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casCont2 != casOrg?.casCont2 }">
                      {{ engLang ? "Contract No. 2:" : "No. Contrato 2:" }}
                    </label>
                    <input type="text" id="casContr2" class="form-control" #casContr2
                      placeholder='{{ engLang ? "Contract No. 2" : "No. Contrato 2" }}'
                      ngbTooltip='{{ engLang ? "Contract No. 2" : "No. Contrato 2" }}' maxlength="30"
                      style="font-size: 95%; display: block;"
                      [ngClass]="errsCas.contr2 ? { 'redBkg': errsCas.contr2 } : { 'edited': selectedCase[selectedCaseIndx].casCont2 != casOrg?.casCont2 }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casCont2"
                      (keydown.Tab)="onKeyDown_focus($event, casGroup2.id);" size="20">
                  </div>

                  <div>
                    <label for="casGroup2" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casGrp2 != casOrg?.casGrp2 }">
                      {{ engLang ? "Group No. 2:" : "No. Grupo 2:" }}
                    </label>
                    <input type="text" id="casGroup2" class="form-control" #casGroup2
                      ngbTooltip='{{ engLang ? "Group No. 2" : "No. Grupo 2" }}' maxlength="30"
                      style="font-size: 95%; display: block;"
                      placeholder='{{ engLang ? "Group No. 2" : "No. Grupo 2" }}'
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casGrp2 != casOrg?.casGrp2 }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casGrp2" size="20">
                  </div>

                  <div>
                    <label for="casDed2" class="label2bottom mb-0 pb-0" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casDed2 != casOrg?.casDed2 }">
                      {{ engLang ? "Deductible 2:" : "Deducible 2:" }}
                    </label>
                    <input type="text" id="casDed2" class="form-control px-0 text-center"
                      placeholder='{{ engLang ? "Deductible 2" : "Deducible 2" }}'
                      ngbTooltip='{{ engLang ? "Deductible 2" : "Deducible 2" }}'
                      style="font-size: 95%; display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casDed2 != casOrg?.casDed2 }"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casDed2" maxlength="10"
                      (keydown.Tab)="onKeyDown_focus($event, 'dxC1');" size="10">

                  </div>
                </div>
              </td>
            </tr>

            <tr class="m-0 p-0">
              <td class="m-0 p-0">
                <div class="rowBox" *ngIf="selectedCase[selectedCaseIndx]"> <!-- Dont render if not ready -->

                  <div>
                    <label for="casLastIns1" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casILastIns1 != casOrg?.casILastIns1 }">
                      {{ engLang ? "Last Name Card:": "Apellidos Tarjeta:" }}
                    </label>
                    <input type="text" id="casLastIns1" class="form-control p-1"
                      placeholder='{{ engLang ? "Last Name" : "Apellidos" }}'
                      ngbTooltip='{{ engLang ? "Last Name" : "Apellidos" }}' maxlength="20"
                      style="font-size: 95%; display: block;" [(ngModel)]="selectedCase[selectedCaseIndx].casILastIns1"
                      [ngClass]="errsCas.lastIns1 ? { 'redBkg': errsCas.lastIns1 } : { 'edited': selectedCase[selectedCaseIndx].casILastIns1 != casOrg?.casILastIns1 }"
                      size="20">
                  </div>

                  <div>
                    <label for="casFirstIns1" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casIFirstIns1 != casOrg?.casIFirstIns1 }">
                      {{ engLang ? "First Name Card:": "Nombre Tarjeta:" }}
                    </label>
                    <input type="text" id="casFirstIns1" class="form-control p-1"
                      placeholder='{{ engLang ? "First Name" : "Nombre" }}'
                      ngbTooltip='{{ engLang ? "First Name" : "Nombre" }}' maxlength="20"
                      style="font-size: 95%; display: block;" [(ngModel)]="selectedCase[selectedCaseIndx].casIFirstIns1"
                      [ngClass]="errsCas.firstIns1 ? { 'redBkg': errsCas.firstIns1 } : { 'edited': selectedCase[selectedCaseIndx].casIFirstIns1 != casOrg?.casIFirstIns1 }"
                      size="15">
                  </div>

                  <div>
                    <label for="casMidInitIns1" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casIMidIns1 != casOrg?.casIMidIns1 }">
                      {{ engLang ? "Init:": "Inic:" }}
                    </label>
                    <input type="text" id="casMidInitIns1" class="form-control p-1"
                      ngbTooltip='{{ engLang ? "Mid Initial" : "Inicial" }} Tarjeta'
                      style="font-size: 95%; display: block;" maxlength="1"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casIMidIns1"
                      (keydown.Tab)="onKeyDown_focus($event, casI2ID.id)"
                      [ngClass]="errsCas.initIns1 ? { 'redBkg': errsCas.initIns1 } : { 'edited': selectedCase[selectedCaseIndx].casIMidIns1 != casOrg?.casIMidIns1 }"
                      size="1">
                  </div>
                </div>
              </td>
              <td class="m-0 p-0">
                <div class="rowBox" *ngIf="selectedCase[selectedCaseIndx]"> <!-- Dont render if not ready -->

                  <div>
                    <label for="casLastIns2" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casILastIns2 != casOrg?.casILastIns2 }">
                      {{ engLang ? "Last Name": "Apellidos" }} Tarjeta
                    </label>
                    <input type="text" id="casLastIns2" class="form-control p-1"
                      placeholder='{{ engLang ? "Last Name" : "Apellidos" }}'
                      ngbTooltip='{{ engLang ? "Last Name" : "Apellidos" }}' maxlength="20"
                      style="font-size: 95%; display: block;" [(ngModel)]="selectedCase[selectedCaseIndx].casILastIns2"
                      [ngClass]="errsCas.lastIns2 ? { 'redBkg': errsCas.lastIns2 } : { 'edited': selectedCase[selectedCaseIndx].casILastIns2 != casOrg?.casILastIns2 }"
                      size="20">
                  </div>

                  <div>
                    <label for="casFirstIns2" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casIFirstIns2 != casOrg?.casIFirstIns2 }">
                      {{ engLang ? "First Name": "Nombre" }} Tarjeta
                    </label>
                    <input type="text" id="casFirstIns2" class="form-control p-1"
                      placeholder='{{ engLang ? "First Name" : "Nombre" }}'
                      ngbTooltip='{{ engLang ? "First Name" : "Nombre" }}' maxlength="20"
                      style="font-size: 95%; display: block;" [(ngModel)]="selectedCase[selectedCaseIndx].casIFirstIns2"
                      [ngClass]="errsCas.firstIns2 ? { 'redBkg': errsCas.firstIns2 } : { 'edited': selectedCase[selectedCaseIndx].casIFirstIns2 != casOrg?.casIFirstIns2 }"
                      size="15">
                  </div>

                  <div>
                    <label for="casMidInitIns2" style="display: block;"
                      [ngClass]="{ 'edited': selectedCase[selectedCaseIndx].casIMidIns2 != casOrg?.casIMidIns2 }">
                      {{ engLang ? "Init:": "Inic:" }}
                    </label>
                    <input type="text" id="casMidInitIns2" class="form-control p-1"
                      ngbTooltip='{{ engLang ? "Mid Initial" : "Inicial" }} Tarjeta'
                      style="font-size: 95%; display: block;" maxlength="1"
                      [(ngModel)]="selectedCase[selectedCaseIndx].casIMidIns2"
                      [ngClass]="errsCas.initIns2 ? { 'redBkg': errsCas.initIns2 } : { 'edited': selectedCase[selectedCaseIndx].casIMidIns2 != casOrg?.casIMidIns2 }"
                      size="1">
                  </div>
                </div>
              </td>
            </tr>

          </table>
        </div>
      </div>

      <div>
        <!-- Dx -->
        <!-- Dx -->
        <!-- Dx -->
        <!-- Dx -->
        <!-- Dx -->

        <div *ngIf="selectedCase[selectedCaseIndx]"> <!-- Dont render if not ready -->
          <div class="text-center sectionTit">
            Dx
            <span *ngIf="fetchingDxCodesDesc" class="pl-1">
              <img src="app/assets/images/waiting4Response.gif" width="20px" height="20px" alt="waiting">
            </span>
          </div>

          <div class="table-responsive">
            <table class="table table-sm table-bordered table-hover mb-1">
              <thead class="table-dark">
                <tr style="font-size: smaller;">
                  <th class="w30 text-center">
                    <div ngbTooltip="{{engLang ? 'Dx Pointer': 'Puntero Dx'}}" placement="bottom">
                      Dx
                    </div>
                  </th>
                  <th class="w65dx text-center">
                    {{engLang ? 'Code' : 'Código'}}
                  </th>
                  <th class="text-center">
                    {{engLang ? 'Description' : 'Descripción'}}
                  </th>
                  <th class="w20dx"></th>
                  <th class="w20dx"></th>
                  <th class="w20dx"></th>
                </tr>
              </thead>
              <tbody *ngFor="let dx of selectedCase[selectedCaseIndx].dxs; let e = even; let o = odd; let d = index;">
                <tr class="py-0 my-0" [ngClass]="{ evenDetSrch : e, oddDetSrch : o }">
                  <!-- tabindex in conjunction with CSS tbody tr:focus {} allows row focusing -->

                  <td class="w30 p-2">
                    {{ dx.indx }}
                  </td>

                  <td class="w65dx p-0 m-0">
                    <input type="text" class="form-control font85" id="dxC{{ dx.indx }}"
                      placeholder="{{ engLang ? 'ICD10' : 'ICD10' }}"
                      [ngClass]="errsDx[d]?.code ? { 'redBkg': errsDx[d].code } : { 'edited': dx.code != casOrg?.dxs[d]?.code }"
                      [(ngModel)]="dx.code" (keyup)="onKeyUp_SearchICD10($event, true);" size="7">
                  </td>

                  <td class="p-0 m-0">
                    <input type="text" class="form-control font85" id="dxD{{ dx.indx }}"
                      placeholder="{{ engLang ? 'Description' : 'Descripción' }}" [(ngModel)]="dx.desc"
                      (keyup)="onKeyUp_SearchICD10($event, false);" tabindex="-1" size="60">
                  </td>

                  <td w20dx>
                    <i class="fa fa-trash font85 p-2" (click)="onClick_DeleteDx(d);"
                      ngbTooltip="{{ engLang ? 'Delete': 'Eliminar' }}"></i>
                  </td>

                  <td w20dx>
                    <i class="fas fa-long-arrow-alt-down font85 p-2" (click)="onClick_MoveDxDown(d);"
                      ngbTooltip="{{ engLang ? 'Move down': 'Mover abajo' }}"></i>
                  </td>

                  <td w20dx>
                    <i class="fas fa-long-arrow-alt-up font85 p-2" (click)="onClick_MoveDxUp(d);"
                      ngbTooltip="{{ engLang ? 'Move up': 'Mover arriba' }}"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="d-flex justify-content-between" style="height: 5px;">
            <div>
              <div *ngIf="!showMnu1">
                <button id="showMnu1" (click)="onClick_showMnu1($event)"
                  style="border-radius: 50%; border: none; background-color: red; margin-left: -5px; padding: 0; width: 27px; height: 27px; text-align: center;">
                  <i class="material-icons"
                    style="font-size: 20px; text-align: center; color: white; padding-top: 2px;">menu</i>
                </button>
              </div>
              <div *ngIf="showMnu1">
                <app-floating-menu [engLang]="engLang" [mnuNo]="1" [icon1]="floatMnu1_1.icon"
                  [ttEn1]="floatMnu1_1.ttEng" [ttEs1]="floatMnu1_1.ttEsp" [innHtml3]="floatMnu1_1.html"
                  (mnu1ClikdEvnt)="onClick_fltMnuSave($event)" [icon2]="floatMnu1_2.icon" [innHtml3]="floatMnu1_2.html"
                  [ttEn2]="floatMnu1_2.ttEng" [ttEs2]="floatMnu1_2.ttEsp" (mnu2ClikdEvnt)="onClick_fltMnuPrint($event)"
                  [icon3]="floatMnu1_3.icon" [innHtml3]="floatMnu1_3.html" [ttEn3]="floatMnu1_3.ttEng"
                  [ttEs3]="floatMnu1_3.ttEsp" (mnu3ClikdEvnt)="onClick_fltMnuTxmit($event)" [icon4]="floatMnu1_4.icon"
                  [innHtml4]="floatMnu1_4.html" [ttEn4]="floatMnu1_4.ttEng" [ttEs4]="floatMnu1_4.ttEsp"
                  (mnu4ClikdEvnt)="onClick_fltMnuTxmit('1')" (closeEvnt)="onClose_mainMnu($event)"></app-floating-menu>
              </div>
            </div>
            <div>
              <span class="p-0 m-0" ngbTooltip="{{engLang ? 'More Dx codes': 'Más códigos Dx'}}"
                *ngIf="(this.selectedCase[this.selectedCaseIndx].dxs.length < 12);"><i class="far fa-plus-square"
                  (click)="onClick_AddDx();"></i></span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="selectedCase[selectedCaseIndx]"> <!-- Dont render if not ready -->
        <!--Proc Codes 1-->
        <!--Proc Codes 1-->
        <!--Proc Codes 1-->
        <!--Proc Codes 1-->
        <!--Proc Codes 1-->

        <div class="text-center sectionTit">
          {{ engLang ? 'Procedures': 'Procedimientos' }}
          <div class="text-center p-0 m-0" style="font-size: 50%; height: 1px;">({{
            selectedCase[selectedCaseIndx].procs1.length }})</div>
        </div>

        <div class="d-flex justify-content-between">
          <div style="font-size: 70%;"
            ngbTooltip="({{ selectedCase[selectedCaseIndx].casI1ID }}) {{ selectedCase[selectedCaseIndx].casIns1Alias }}">
            {{ engLang ? 'Ins 1:': 'Seg 1:' }}
            ({{ selectedCase[selectedCaseIndx].casI1ID }})
            <span style="font-size: smaller">{{ selectedCase[selectedCaseIndx].casIns1Alias?.substring(0,25)
              }}</span>
          </div>

          <div class="text-center">
            <img src="/app/assets/images/waiting4Response.gif" width="15" height="15" alt="printing1"
              class="img-fluid align-top" *ngIf="waiting4Print1">
          </div>

          <div style="font-size: 70%;"
            ngbTooltip="({{ selectedCase[selectedCaseIndx].casProvID }}) {{ selectedCase[selectedCaseIndx].casProvAlias }}">
            Prov: ({{ selectedCase[selectedCaseIndx].casProvID }})
            <span style="font-size: smaller">{{ selectedCase[selectedCaseIndx].casProvAlias.substring(0,25)
              }}</span>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-bordered table-hover table-sm mb-1">
            <thead class="table-dark">
              <tr style="font-size: smaller;">
                <!-- <th style="display: none;"></th>
                <th style="display: none;"></th>
                <th style="display: none;"></th> -->

                <th class="w74 text-center p-0 m-0">
                  {{engLang ? 'Dates' : 'Fechas'}}
                </th>
                <th class="w128 text-center p-0 m-0">
                  {{engLang ? 'Code' : 'Código'}}
                </th>
                <th class="w29 text-center p-0 m-0">
                  {{engLang ? 'Pos' : 'Lug' }}
                </th>
                <th class="w65pc text-center p-0 m-0">
                  {{engLang ? 'Qty' : 'Cant' }}
                </th>
                <th class="w40 text-center p-0 m-0">
                  Dx
                </th>
                <th class="w56 text-center p-0 m-0">
                  {{engLang ? 'Usual' : 'Usual'}}
                </th>
                <th class="w56 text-center p-0 m-0">
                  {{engLang ? 'Expect' : 'Espera'}}
                </th>
                <th class="w56 text-center p-0 m-0">
                  {{engLang ? 'Coins' : 'Coaseg'}}
                </th>
                <th class="w99 text-center p-0 m-0">
                  {{engLang ? 'Rendr' : 'Prod'}}
                </th>
                <th class="text-center p-0 m-0">
                  {{engLang ? 'Description' : 'Descripción'}}
                </th>
              </tr>
            </thead>

            <tbody
              *ngFor="let proc of selectedCase[selectedCaseIndx].procs1; let e = even; let o = odd; let p = index;">
              <!-- Must check parent ...cases before iterating through child procs -->

              <tr class="py-0 my-0" [ngClass]="{ evenDetSrch : e, oddDetSrch : o }">
                <!-- tabindex in conjunction with CSS tbody tr:focus {} allows row focusing -->

                <!-- <td style="display: none;">
                  {{ proc.detID }}
                </td>
                <td style="display: none;">
                  {{ proc.detCasID }}
                </td>
                <td style="display: none;">
                  {{ proc.ps }}
                </td> -->

                <td class="w74 p-0 m-0">
                  <div>
                    <div class="d-sm-inline-flex">
                      <input id="{{ 'frDt1_' + p }}" class="form-control font85" placeholder="mm/dd/yy" name="dtp"
                        #cfrom="ngModel" [(ngModel)]="proc.fromDtYy" ngbDatepicker
                        parserFormatter="CustomDateParserFormatter"
                        (dateSelect)="proc.fromDtYy = onDtpDateSelect('frDt',dfrom._inputValue,p,'1',proc)"
                        #dfrom="ngbDatepicker"
                        [ngClass]="errsProc1[p]?.frDt ? { 'redBkg': errsProc1[p].frDt } : { 'edited': proc.fromDtYy != casOrg?.procs1[p].fromDtYy }"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (blur)="proc.fromDtYy = onDtpDateSelect('frDt',dfrom._inputValue,p,'1',proc)"
                        [disabled]="toBeDeletedDisabled(proc.detID)" />
                      <i class="far fa-calendar-times mt-2" (click)="dfrom.toggle()"></i>
                    </div>
                  </div>
                  <div>
                    <div class="d-sm-inline-flex">
                      <input id="{{ 'toDt1_' + p }}" class="form-control font85" placeholder="mm/dd/yy" name="dtp"
                        #cto="ngModel" [(ngModel)]="proc.toDtYy" ngbDatepicker
                        parserFormatter="CustomDateParserFormatter"
                        (dateSelect)="proc.toDtYy = onDtpDateSelect('toDt',dto._inputValue,p,'1',proc)"
                        #dto="ngbDatepicker"
                        [ngClass]="errsProc1[p]?.toDt ? { 'redBkg': errsProc1[p].toDt } : { 'edited': proc.toDtYy != casOrg?.procs1[p].toDtYy }"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (blur)="proc.toDtYy = onDtpDateSelect('toDt',dto._inputValue,p,'1',proc)"
                        [disabled]="toBeDeletedDisabled(proc.detID)" />
                      <i class="far fa-calendar-times mt-2" (click)="dto.toggle()"></i>
                    </div>
                  </div>
                </td>

                <td class="w128 text-center p-0 m-0">
                  <div class="d-sm-inline-flex flex-column justify-content-start ml-1">
                    <div class="d-sm-inline-flex justify-content-start mx-0">
                      <input type="text" id="{{'proc1_' + p }}" class="form-control font85 ml-1 pl-1"
                        placeholder="{{ engLang ? 'CPT' : 'CPT' }}" style="width: 50px;"
                        [ngClass]="{ 'edited': proc.code != casOrg?.procs1[p].code }" [(ngModel)]="proc.code"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (keyup)="onKeyUp_SearchPcode($event);" maxlength="6" size="5"
                        [disabled]="toBeDeletedDisabled(proc.detID)">

                      <input type="checkbox" id="{{'print1_' + p }}" class="mt-2 mx-4 form-check-input"
                        [checked]="proc.print" (change)="proc.print = !proc.print"
                        [disabled]="toBeDeletedDisabled(proc.detID)">
                    </div>

                    <div class="d-sm-inline-flex justify-content-between mx-0">
                      <input type="text" id="{{'modI1_' + p }}" name="modifier" class="px-1 mr-0 form-control font85"
                        placeholder="{{ engLang ? 'Mod' : 'Mod' }}"
                        [ngClass]="{ 'edited': proc.mod1 != casOrg?.procs1[p].mod1 }" [(ngModel)]="proc.mod1"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (keyup)="onKeyUp_SearchPcode($event);" maxlength="2" size="2"
                        [disabled]="toBeDeletedDisabled(proc.detID)">
                      <input type="text" id="{{'modII1_' + p }}" name="modifier" class="px-1 mr-0 form-control font85"
                        placeholder="{{ engLang ? 'M2' : 'M2' }}"
                        [ngClass]="{ 'edited': proc.mod2 != casOrg?.procs1[p].mod2 }" [(ngModel)]="proc.mod2"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (keyup)="onKeyUp_SearchPcode($event);" maxlength="2" size="2"
                        [disabled]="toBeDeletedDisabled(proc.detID)">
                      <input type="text" id="{{'modIII1_' + p }}" name="modifier" class="px-1 mr-0 form-control font85"
                        placeholder="{{ engLang ? 'M3' : 'M3' }}"
                        [ngClass]="{ 'edited': proc.mod3 != casOrg?.procs1[p].mod3 }" [(ngModel)]="proc.mod3"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (keyup)="onKeyUp_SearchPcode($event);" maxlength="2" size="2"
                        [disabled]="toBeDeletedDisabled(proc.detID)">
                      <input type="text" id="{{'modIV1_' + p }}" name="modifier" class="px-1 mr-0 form-control font85"
                        placeholder="{{ engLang ? 'M4' : 'M4' }}"
                        [ngClass]="{ 'edited': proc.mod4 != casOrg?.procs1[p].mod4 }" [(ngModel)]="proc.mod4"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (keyup)="onKeyUp_SearchPcode($event);" maxlength="2" size="2"
                        [disabled]="toBeDeletedDisabled(proc.detID)">
                    </div>
                  </div>
                </td>

                <td class="w29 text-center p-0 m-0">
                  <input type="text" id="{{'pos1_' + p }}" maxlength="2"
                    class="text-center px-0 pt-1 mx-0 form-control font85" placeholder="{{ engLang ? 'Pos' : 'Lu' }}"
                    [ngClass]="errsProc1[p]?.pos ? { 'redBkg': errsProc1[p].pos } : { 'edited': proc.pos != casOrg?.procs1[p].pos }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [(ngModel)]="proc.pos" size="2" [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w65pc text-start p-0 m-0">
                  <input type="number" id="{{ 'qty1_' + p }}" step="any" class="px-1 pt-1 form-control font85"
                    placeholder="{{ engLang ? 'Qty' : 'Cant' }}"
                    [ngClass]="errsProc1[p]?.qty ? { 'redBkg' : errsProc1[p].qty } : { 'edited': proc.qty != casOrg?.procs1[p].qty }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [(ngModel)]="proc.qty" (ngModelChange)="calcCaseTotals()" size="6"
                    [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w40 p-0 m-0">
                  <input type="text" maxlength="4" class="text-left pt-1 px-1 form-control font85"
                    placeholder="{{ engLang ? 'Pointer' : 'Puntero' }}"
                    [ngClass]="errsProc1[p]?.dx ? { 'redBkg' : errsProc1[p].dx } : { 'edited': proc.dx != casOrg?.procs1[p].dx }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [(ngModel)]="proc.dx" size="1" [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w56 m-0 p-0 text-center">
                  <input type="text" id="{{'usual1_' + p }}" maxlength="9" class="text-end form-control font85"
                    placeholder="{{ engLang ? 'Usual' : 'Usual' }}"
                    [ngClass]="errsProc1[p]?.usual ? { 'redBkg': errsProc1[p].usual } : { 'edited': proc.usual != casOrg?.procs1[p].usual }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [ngModel]="proc.usual | currency:'USD':'':'1.2-2'" (keypress)="onKeyPress_decimal($event)"
                    (blur)="proc.usual = onBlur_setDecimalValue($event); calcCaseTotals();"
                    [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w56 m-0 p-0">
                  <input type="text" id="{{'xpect1_' + p }}" maxlength="9" class="text-end form-control font85"
                    placeholder="{{ engLang ? 'Expected' : 'Esperado' }}"
                    [ngClass]="errsProc1[p]?.xpect ? { 'redBkg': errsProc1[p].xpect } : { 'edited': proc.xpect != casOrg?.procs1[p].xpect }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [ngModel]="proc.xpect | currency:'USD':'':'1.2-2'" (keypress)="onKeyPress_decimal($event)"
                    (blur)="proc.xpect = onBlur_setDecimalValue($event); calcCaseTotals();"
                    [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w56 m-0 p-0">
                  <input type="text" id="{{'pvdo1_' + p }}" maxlength="9" class="text-end form-control font85"
                    placeholder="{{ engLang ? 'Coinsurance' : 'Coaseguro' }}"
                    [ngClass]="errsProc1[p]?.pvdo ? { 'redBkg': errsProc1[p].pvdo } : { 'edited': proc.pvdo != casOrg?.procs1[p].pvdo }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [ngModel]="proc.pvdo | currency:'USD':'':'1.2-2'" (keypress)="onKeyPress_decimal($event)"
                    (blur)="proc.pvdo = onBlur_setDecimalValue($event); calcCaseTotals();"
                    [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w99 p-0 m-0">
                  <div class="d-inline-block font12">
                    <dd-ngbdropdown [labl]="proc.prod.substring(0,10)" [preVal]="proc.prod" [srchStr]=""
                      [fontsmall]=true [rawList]="this._recordService.localProductors" [stylesCfg]="stylesCfg"
                      [listTyp]="'prodID1'" [engLang]="engLang" [height]="'400px'"
                      (ddnListSelectionClkd)="ddnListSelectionClkd($event,p)"
                      [tt]=" engLang ? 'Rendering prov by alias': 'Productores por alias' "></dd-ngbdropdown>
                  </div>
                </td>

                <td class="text-center p-0 m-0">
                  <input type="text" id="{{'desc1_' + p }}" class=" form-control font75" style="margin-top: 3px;"
                    placeholder="{{ engLang ? 'Description' : 'Descripción' }}" maxlength="50"
                    [ngClass]="errsProc1[p]?.desc ? { 'redBkg': errsProc1[p].desc } : { 'edited': proc.desc != casOrg?.procs1[p].desc }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [(ngModel)]="proc.desc" (keyup)="onKeyUp_SearchPcode($event);" ngbTooltip={{proc.desc}}
                    tabindex="-1" [disabled]="toBeDeletedDisabled(proc.detID)">

                  <div class="d-flex justify-content-between">
                    <div class="d-flex justify-content-start">
                      <div [ngStyle]="{ 'display': (+proc.rx ? '' : 'none') }">
                        <input type="text" mask="00009-0009-00" id="{{'rxNDC1_' + p }}" maxlength="13"
                          class="px-1 form-control font85" placeholder="National Drug Code"
                          ngbTooltip="National Drug Code" style="width: 100px; min-width: 100px;"
                          [ngClass]="errsProc1[p]?.rxNDC ? { 'redBkg': errsProc1[p].rxNDC } : { 'edited': proc.rxNDC != casOrg?.procs1[p].rxNDC }"
                          [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                          [(ngModel)]="proc.rxNDC" [disabled]="toBeDeletedDisabled(proc.detID)">
                      </div>
                      <div [ngStyle]="{ 'display': (+proc.rx ? '' : 'none') }">
                        <input type="text" mask="99999" id="{{'rxQty1_' + p }}" maxlength="6"
                          class="px-1 form-control font85" placeholder="{{ engLang ? 'Units': 'Unidades' }}"
                          ngbTooltip="{{ engLang ? 'Units': 'Unidades' }}" style="width: 45px; min-width: 45px;"
                          [ngClass]="errsProc1[p]?.rxQty ? { 'redBkg': errsProc1[p].rxQty } : { 'edited': proc.rxQty != casOrg?.procs1[p].rxQty }"
                          [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                          [(ngModel)]="proc.rxQty" [disabled]="toBeDeletedDisabled(proc.detID)">
                      </div>
                      <div [ngStyle]="{ 'display': (+proc.rx ? '' : 'none') }">
                        <input type="text" id="{{'rxUM1_' + p }}" maxlength="2" class="px-1 form-control font85"
                          placeholder="{{ engLang ? 'Unit of Measure': 'Medida' }}"
                          ngbTooltip="{{ engLang ? 'Unit of Measure': 'Medida' }}" style="width: 30px; min-width: 30px;"
                          [ngClass]="errsProc1[p]?.rxUM ? { 'redBkg': errsProc1[p].rxUM } : { 'edited': proc.rxUM != casOrg?.procs1[p].rxUM }"
                          [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                          [(ngModel)]="proc.rxUM" [disabled]="toBeDeletedDisabled(proc.detID)">
                      </div>
                      <div [ngStyle]="{ 'display': (+proc.rx ? '' : 'none') }">
                        <input type="text" id="{{'rxNo1_' + p }}" maxlength="48" class="px-1 form-control font85"
                          placeholder="{{ engLang ? 'Prescription No.': 'No. de receta' }}"
                          ngbTooltip="{{ engLang ? 'Prescription No.': 'No. de receta' }}"
                          style="width: 100px; min-width: 100px;"
                          [ngClass]="errsProc1[p]?.rxNo ? { 'redBkg': errsProc1[p].rxNo } : { 'edited': proc.rxNo != casOrg?.procs1[p].rxNo }"
                          [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                          [(ngModel)]="proc.rxNo" [disabled]="toBeDeletedDisabled(proc.detID)">
                      </div>
                    </div>
                    <div class="d-flex gap-3 mt-auto mb-auto">
                      <div>
                        <i class="fa fa-undo mr-3 pr-3 red" (click)="onClick_DeleteProc('1', p)"
                          [ngStyle]="{ 'display': (+proc.detID < 0 ? '' : 'none') }"
                          ngbTooltip="{{ engLang ? 'Restore': 'Restaurar' }}"></i>
                        <i class="fa fa-trash mr-3 pr-3" (click)="onClick_DeleteProc('1', p)"
                          [ngStyle]="{ 'display': (+proc.detID < 0 ? 'none' : '') }"
                          ngbTooltip="{{ engLang ? 'Delete': 'Eliminar' }}"></i>
                      </div>
                      <div>
                        <i class="far fa-copy ml-3 pl-3" ngbTooltip="{{ engLang ? 'Duplicate': 'Duplicar' }}"
                          (click)="onClick_CopyProc('1',p)"
                          [ngStyle]="{ 'display': (+proc.detID < 0 ? 'none' : '') }"></i>
                      </div>
                      <div><!-- spacer --></div>
                    </div>
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="d-flex justify-content-between" style="height: 5px;">
        <div *ngIf="selectedCase[selectedCaseIndx]?.procs2.length">
          <div *ngIf="!showMnu2">
            <button id="showMnu2" (click)="onClick_showMnu2($event)"
              style="border-radius: 50%; border: none; background-color: red; margin-left: -5px; padding: 0; width: 27px; height: 27px; text-align: center;">
              <i class="material-icons"
                style="font-size: 20px; text-align: center; color: white; padding-top: 2px;">menu</i>
            </button>
          </div>
          <div *ngIf="showMnu2">
            <app-floating-menu [engLang]="engLang" [mnuNo]="2" [icon1]="floatMnu2_1.icon" [ttEn1]="floatMnu2_1.ttEng"
              [ttEs1]="floatMnu2_1.ttEsp" [innHtml3]="floatMnu2_1.html" (mnu1ClikdEvnt)="onClick_fltMnuSave($event)"
              [icon2]="floatMnu2_2.icon" [innHtml3]="floatMnu2_2.html" [ttEn2]="floatMnu2_2.ttEng"
              [ttEs2]="floatMnu2_2.ttEsp" (mnu2ClikdEvnt)="onClick_fltMnuPrint($event)" [icon3]="floatMnu2_3.icon"
              [innHtml3]="floatMnu2_3.html" [ttEn3]="floatMnu2_3.ttEng" [ttEs3]="floatMnu2_3.ttEsp"
              (mnu3ClikdEvnt)="onClick_fltMnuTxmit($event)" [icon4]="floatMnu2_4.icon" [innHtml4]="floatMnu2_4.html"
              [ttEn4]="floatMnu2_4.ttEng" [ttEs4]="floatMnu2_4.ttEsp" (mnu4ClikdEvnt)="onClick_fltMnuTxmit('2')"
              (closeEvnt)="onClose_mainMnu($event)"></app-floating-menu>
          </div>
        </div>
        <div *ngIf="!selectedCase[selectedCaseIndx]?.procs2.length">
          <!-- Just s spacer -->
        </div>
        <div>
          <span class="p-0 m-0"><i class="far fa-plus-square"
              ngbTooltip="{{engLang ? 'More CPT codes primary': 'Más códigos CPT primario'}}"
              (click)="onClick_addProc('1');"></i></span>
        </div>
      </div>

      <div *ngIf="selectedCase[selectedCaseIndx]"> <!-- Dont render if not ready -->
        <!--Proc Codes 2-->
        <!--Proc Codes 2-->
        <!--Proc Codes 2-->
        <!--Proc Codes 2-->
        <!--Proc Codes 2-->

        <div class="d-flex justify-content-between mt-3 pt-1">
          <div style="font-size: 70%;"
            ngbTooltip="({{ selectedCase[selectedCaseIndx].casI2ID }}) {{ selectedCase[selectedCaseIndx].casIns2Alias }}">
            {{ engLang ? 'Ins 2:': 'Seg 2:' }}
            ({{ selectedCase[selectedCaseIndx].casI2ID }})
            <span style="font-size: smaller">{{ selectedCase[selectedCaseIndx].casIns2Alias?.substring(0,25)
              }}</span>
          </div>

          <div style="margin: 0 auto; left: 50%; position: absolute;">
            <div class="p-0 m-0" style="font-style: italic; font-size: 45%; height: 1px;" *ngIf="!waiting4Print2">({{
              selectedCase[selectedCaseIndx].procs2.length }})
              <img src="/app/assets/images/waiting4Response.gif" width="15" height="15" alt="printing2"
                class="img-fluid align-top" *ngIf="waiting4Print2">
            </div>
          </div>

          <div style="font-size: 70%;"
            ngbTooltip="({{ selectedCase[selectedCaseIndx].casProvID }}) {{ selectedCase[selectedCaseIndx].casProvAlias }}">
            Prov: ({{ selectedCase[selectedCaseIndx].casProvID }})
            <span style="font-size: smaller">{{ selectedCase[selectedCaseIndx].casProvAlias.substring(0,25)
              }}</span>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-bordered table-hover table-sm">
            <thead class="table-dark">
              <tr style="font-size: smaller;">

                <!-- <th style="display: none;"></th>
                <th style="display: none;"></th>
                <th style="display: none;"></th> -->

                <th class="w74 text-center p-0 m-0">
                  {{engLang ? 'Dates' : 'Fechas'}}
                </th>
                <th class="w128 text-center p-0 m-0">
                  {{engLang ? 'Code' : 'Código'}}
                </th>
                <th class="w29 text-center p-0 m-0">
                  {{engLang ? 'Pos' : 'Lug' }}
                </th>
                <th class="w65pc text-center p-0 m-0">
                  {{engLang ? 'Qty' : 'Cant' }}
                </th>
                <th class="w40 text-center p-0 m-0">
                  Dx
                </th>
                <th class="w56 text-center p-0 m-0">
                  {{engLang ? 'Usual' : 'Usual'}}
                </th>
                <th class="w56 text-center p-0 m-0">
                  {{engLang ? 'Expect' : 'Espera'}}
                </th>
                <th class="w56 text-center p-0 m-0">
                  {{engLang ? 'Coins' : 'Coaseg'}}
                </th>
                <th class="w99 text-center p-0 m-0">
                  {{engLang ? 'Rendr' : 'Prod'}}
                </th>
                <th class="text-center p-0 m-0">
                  {{engLang ? 'Description' : 'Descripción'}}
                </th>
              </tr>
            </thead>

            <tbody *ngFor="let proc of selectedCase[selectedCaseIndx].procs2; let e = even; let o = odd; let p = index;"
              style="overflow-x: hidden; overflow-y: auto; height: auto;">
              <!-- Must check parent ...cases before iterating through child procs -->

              <tr class="py-0 my-0" [ngClass]="{ evenDetSrch : e, oddDetSrch : o }">
                <!-- tabindex in conjunction with CSS tbody tr:focus {} allows row focusing -->

                <!-- <td style="display: none;">
                  {{ proc.detID }}
                </td>
                <td style="display: none;">
                  {{ proc.detCasID }}
                </td>
                <td style="display: none;">
                  {{ proc.ps }}
                </td> -->

                <td class="w74 p-0 m-0">
                  <div>
                    <div class="d-sm-inline-flex">
                      <input id="{{ 'frDt2_' + p }}" class="form-control font85" placeholder="mm/dd/yy" name="dtp"
                        #cfrom="ngModel" [(ngModel)]="proc.fromDtYy" ngbDatepicker
                        parserFormatter="CustomDateParserFormatter"
                        (dateSelect)="proc.fromDtYy = onDtpDateSelect('frDt',dfrom._inputValue,p,'2',proc)"
                        #dfrom="ngbDatepicker"
                        [ngClass]="errsProc2[p]?.frDt ? { 'redBkg': errsProc2[p].frDt } : { 'edited': proc.fromDtYy != casOrg?.procs2[p].fromDtYy }"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (blur)="proc.fromDtYy = onDtpDateSelect('frDt',dfrom._inputValue,p,'2',proc)"
                        [disabled]="toBeDeletedDisabled(proc.detID)" />
                      <i class="far fa-calendar-times mt-2" (click)="dfrom.toggle()"></i>
                    </div>
                  </div>
                  <div class="d-sm-inline-flex">
                    <input id="{{ 'toDt2_' + p }}" class="form-control font85" placeholder="mm/dd/yy" name="dtp"
                      #cto="ngModel" [(ngModel)]="proc.toDtYy" ngbDatepicker parserFormatter="CustomDateParserFormatter"
                      (dateSelect)="proc.toDtYy = onDtpDateSelect('toDt',dto._inputValue,p,'2',proc)"
                      #dto="ngbDatepicker"
                      [ngClass]="errsProc2[i]?.toDt ? { 'redBkg': errsProc2[i].toDt } : { 'edited': proc.toDtYy != casOrg?.procs2[p].toDtYy }"
                      [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                      (blur)="proc.toDtYy = onDtpDateSelect('toDt',dto._inputValue,p,'2',proc)"
                      [disabled]="toBeDeletedDisabled(proc.detID)" />
                    <i class="far fa-calendar-times mt-2" (click)="dto.toggle()"></i>
                  </div>
                </td>

                <td class="w128 text-center p-0 m-0">
                  <div class="d-sm-inline-flex flex-column justify-content-start ml-1">
                    <div class="d-sm-inline-flex justify-content-start mx-0">
                      <input type="text" id="{{'proc2_' + p }}" class="form-control font85 ml-1 pl-1"
                        placeholder="{{ engLang ? 'CPT' : 'CPT' }}" style="width: 50px;"
                        [ngClass]="{ 'edited': proc.code != casOrg?.procs2[p].code }" [(ngModel)]="proc.code"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (keyup)="onKeyUp_SearchPcode($event);" maxlength="6" size="5"
                        [disabled]="toBeDeletedDisabled(proc.detID)">

                      <input type="checkbox" id="{{'print2_' + p }}" class="mt-2 mx-4 form-check-input"
                        [checked]="proc.print" (change)="proc.print = !proc.print">
                    </div>

                    <div class="d-sm-inline-flex justify-content-between mx-0">
                      <input type="text" id="{{'modI2_' + p }}" name="modifier" class="px-1 mr-0 form-control font85"
                        placeholder="{{ engLang ? 'Mod' : 'Mod' }}"
                        [ngClass]="{ 'edited': proc.mod1 != casOrg?.procs2[p].mod1 }" [(ngModel)]="proc.mod1"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (keyup)="onKeyUp_SearchPcode($event);" maxlength="2" size="2"
                        [disabled]="toBeDeletedDisabled(proc.detID)">
                      <input type="text" id="{{'modII2_' + p }}" name="modifier" class="px-1 mr-0 form-control font85"
                        placeholder="{{ engLang ? 'M2' : 'M2' }}"
                        [ngClass]="{ 'edited': proc.mod2 != casOrg?.procs2[p].mod2 }" [(ngModel)]="proc.mod2"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (keyup)="onKeyUp_SearchPcode($event);" maxlength="2" size="2"
                        [disabled]="toBeDeletedDisabled(proc.detID)">
                      <input type="text" id="{{'modIII2_' + p }}" name="modifier" class="px-1 mr-0 form-control font85"
                        placeholder="{{ engLang ? 'M3' : 'M3' }}"
                        [ngClass]="{ 'edited': proc.mod3 != casOrg?.procs2[p].mod3 }" [(ngModel)]="proc.mod3"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (keyup)="onKeyUp_SearchPcode($event);" maxlength="2" size="2"
                        [disabled]="toBeDeletedDisabled(proc.detID)">
                      <input type="text" id="{{'modIV2_' + p }}" name="modifier" class="px-1 mr-0 form-control font85"
                        placeholder="{{ engLang ? 'M4' : 'M4' }}"
                        [ngClass]="{ 'edited': proc.mod4 != casOrg?.procs2[p].mod4 }" [(ngModel)]="proc.mod4"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (keyup)="onKeyUp_SearchPcode($event);" maxlength="2" size="2"
                        [disabled]="toBeDeletedDisabled(proc.detID)">
                    </div>
                  </div>
                </td>

                <td class="w29 text-center p-0 m-0">
                  <input type="text" id="{{'pos2_' + p }}" maxlength="2"
                    class="text-center px-0 pt-1 mx-0 form-control font85" placeholder="{{ engLang ? 'Pos' : 'Lu' }}"
                    [ngClass]="errsProc2[i]?.pos ? { 'redBkg': errsProc2[i].pos } : { 'edited': proc.pos != casOrg?.procs2[p].pos }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [(ngModel)]="proc.pos" size="2" [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w65pc text-start p-0 m-0">
                  <input type="number" id="{{ 'qty2_' + p }}" step="any" class="px-1 pt-1 form-control font85"
                    placeholder="{{ engLang ? 'Qty' : 'Cant' }}"
                    [ngClass]="errsProc2[i]?.qty ? { 'redBkg' : errsProc2[i].qty } : { 'edited': proc.qty != casOrg?.procs2[p].qty }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [(ngModel)]="proc.qty" [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w40 p-0 m-0">
                  <input type="text" maxlength="4" class="text-left pt-1 px-1 form-control font85"
                    placeholder="{{ engLang ? 'Pointer' : 'Puntero' }}"
                    [ngClass]="errsProc2[i]?.dx ? { 'redBkg' : errsProc2[i].dx } : { 'edited': proc.dx != casOrg?.procs2[p].dx }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [(ngModel)]="proc.dx" [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w56 m-0 p-0">
                  <input type="text" id="{{'usual2_' + p }}" maxlength="9" class="text-end px-1 form-control font85"
                    placeholder="{{ engLang ? 'Usual' : 'Usual' }}"
                    [ngClass]="errsProc2[i]?.usual ? { 'redBkg': errsProc2[i].usual } : { 'edited': proc.usual != casOrg?.procs2[p].usual }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [ngModel]="proc.usual | currency:'USD':'':'1.2-2'" (keypress)="onKeyPress_decimal($event)"
                    (blur)="proc.usual = onBlur_setDecimalValue($event); calcCaseTotals();"
                    [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w56 m-0 p-0">
                  <input type="text" id="{{'xpect2_' + p }}" maxlength="9" class="text-end px-1 form-control font85"
                    placeholder="{{ engLang ? 'Expected' : 'Esperado' }}"
                    [ngClass]="errsProc1 && errsProc2[i]?.xpect ? { 'redBkg': errsProc2[i].xpect } : { 'edited': proc.xpect != casOrg?.procs2[p].xpect }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [ngModel]="proc.xpect | currency:'USD':'':'1.2-2'" (keypress)="onKeyPress_decimal($event)"
                    (blur)="proc.xpect = onBlur_setDecimalValue($event); calcCaseTotals();"
                    [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w56 m-0 p-0">
                  <input type="text" id="{{'pvdo2_' + p }}" maxlength="9" class="text-end px-1 form-control font85"
                    placeholder="{{ engLang ? 'Coinsurance' : 'Coaseguro' }}"
                    [ngClass]="errsProc2[i]?.pvdo ? { 'redBkg': errsProc2[i].pvdo } : { 'edited': proc.pvdo != casOrg?.procs2[p].pvdo }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [ngModel]="proc.pvdo | currency:'USD':'':'1.2-2'" (keypress)="onKeyPress_decimal($event)"
                    (blur)="proc.pvdo = onBlur_setDecimalValue($event); calcCaseTotals();"
                    [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w99 p-0 m-0">
                  <div class="d-inline-block font85">
                    <dd-ngbdropdown [labl]="proc.prod.substring(0,10)" [preVal]="proc.prod" [srchStr]=""
                      [fontsmall]=true [rawList]="this._recordService.localProductors" [stylesCfg]="stylesCfg"
                      [listTyp]="'prodID2'" [engLang]="engLang" [height]="'400px'"
                      (ddnListSelectionClkd)="ddnListSelectionClkd($event,p)"
                      [tt]=" engLang ? 'Search by alias': 'Buscar por alias' "></dd-ngbdropdown>
                  </div>
                </td>

                <td class="text-center p-0 m-0">
                  <input type="text" id="{{'desc2_' + p }}" class="form-control font75" style="margin-top: 3px;"
                    placeholder="{{ engLang ? 'Description' : 'Descripción' }}" maxlength="50"
                    [ngClass]="errsProc2[i]?.desc ? { 'redBkg': errsProc2[i].desc } : { 'edited': proc.desc != casOrg?.procs2[p].desc }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [(ngModel)]="proc.desc" (keyup)="onKeyUp_SearchPcode($event);" ngbTooltip={{proc.desc}}
                    tabindex="-1" [disabled]="toBeDeletedDisabled(proc.detID)">

                  <div class="d-flex justify-content-between">
                    <div class="d-flex justify-content-start">
                      <div [ngStyle]="{ 'display': (+proc.rx ? '' : 'none') }">
                        <input type="text" mask="00009-0009-00" id="{{'rxNDC2_' + p }}" maxlength="13"
                          class="px-1 form-control font85" placeholder="National Drug Code"
                          ngbTooltip="National Drug Code" style="width: 100px; min-width: 100px;"
                          [ngClass]="errsProc2[i]?.rxNDC ? { 'redBkg': errsProc2[i].rxNDC } : { 'edited': proc.rxNDC != casOrg?.procs2[p].rxNDC }"
                          [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                          [ngModel]="proc.rxNDC" [disabled]="toBeDeletedDisabled(proc.detID)">
                      </div>
                      <div [ngStyle]="{ 'display': (+proc.rx ? '' : 'none') }">
                        <input type="text" mask="99999" id="{{'rxQty2_' + p }}" maxlength="6"
                          class="px-1 form-control font85" placeholder="{{ engLang ? 'Units': 'Unidades' }}"
                          ngbTooltip="{{ engLang ? 'Units': 'Unidades' }}" style="width: 45px; min-width: 45px;"
                          [ngClass]="errsProc2[i]?.rxQty ? { 'redBkg': errsProc2[i].rxQty } : { 'edited': proc.rxQty != casOrg?.procs2[p].rxQty }"
                          [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                          [ngModel]="proc.rxQty" [disabled]="toBeDeletedDisabled(proc.detID)">
                      </div>
                      <div [ngStyle]="{ 'display': (+proc.rx ? '' : 'none') }">
                        <input type="text" id="{{'rxUM2_' + p }}" maxlength="2" class="px-1 form-control font85"
                          placeholder="{{ engLang ? 'Unit of Measure': 'Medida' }}"
                          ngbTooltip="{{ engLang ? 'Unit of Measure': 'Medida' }}" style="width: 30px; min-width: 30px;"
                          [ngClass]="errsProc2[i]?.rxUM ? { 'redBkg': errsProc2[i].rxUM } : { 'edited': proc.rxUM != casOrg?.procs2[p].rxUM }"
                          [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                          [ngModel]="proc.rxUM" [disabled]="toBeDeletedDisabled(proc.detID)">
                      </div>
                      <div [ngStyle]="{ 'display': (+proc.rx ? '' : 'none') }">
                        <input type="text" id="{{'rxNo2_' + p }}" maxlength="48" class="px-1 form-control font85"
                          placeholder="{{ engLang ? 'Prescription No.': 'No. de receta' }}"
                          ngbTooltip="{{ engLang ? 'Prescription No.': 'No. de receta' }}"
                          style="width: 100px; min-width: 100px;"
                          [ngClass]="errsProc2[i]?.rxNo ? { 'redBkg': errsProc2[i].rxNo } : { 'edited': proc.rxNo != casOrg?.procs2[p].rxNo }"
                          [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                          [ngModel]="proc.rxNo" [disabled]="toBeDeletedDisabled(proc.detID)">
                      </div>
                    </div>
                    <div class="d-flex gap-3 mt-auto mb-auto">
                      <div>
                        <i class="fa fa-undo mr-3 pr-3 red" (click)="onClick_DeleteProc('2', p)"
                          [ngStyle]="{ 'display': (+proc.detID < 0 ? '' : 'none') }"
                          ngbTooltip="{{ engLang ? 'Restore': 'Restaurar' }}"></i>
                        <i class="fa fa-trash mr-3 pr-3" (click)="onClick_DeleteProc('2', p)"
                          [ngStyle]="{ 'display': (+proc.detID < 0 ? 'none' : '') }"
                          ngbTooltip="{{ engLang ? 'Delete': 'Eliminar' }}"></i>
                      </div>
                      <div>
                        <i class="far fa-copy ml-3 pl-3" ngbTooltip="{{ engLang ? 'Duplicate': 'Duplicar' }}"
                          (click)="onClick_CopyProc('2',p)"
                          [ngStyle]="{ 'display': (+proc.detID < 0 ? 'none' : '') }"></i>
                      </div>
                      <div><!-- spacer --></div>
                    </div>
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="d-flex justify-content-between" style="height: 5px;">
        <div *ngIf="selectedCase[selectedCaseIndx]?.procs0.length">
          <div *ngIf="!showMnu3">
            <button id="showMnu3" (click)="onClick_showMnu3($event)"
              style="border-radius: 50%; border: none; background-color: red; margin-left: -5px; padding: 0; width: 27px; height: 27px; text-align: center;">
              <i class="material-icons"
                style="font-size: 20px; text-align: center; color: white; padding-top: 2px;">menu</i>
            </button>
          </div>
          <div *ngIf="showMnu3">
            <app-floating-menu [engLang]="engLang" [mnuNo]="3" [icon1]="floatMnu3_1.icon" [ttEn1]="floatMnu3_1.ttEng"
              [ttEs1]="floatMnu3_1.ttEsp" [innHtml3]="floatMnu3_1.html" (mnu1ClikdEvnt)="onClick_fltMnuSave($event)"
              [icon2]="floatMnu3_2.icon" [innHtml3]="floatMnu3_2.html" [ttEn2]="floatMnu3_2.ttEng"
              [ttEs2]="floatMnu3_2.ttEsp" (mnu2ClikdEvnt)="onClick_fltMnuPrint($event)" [icon3]="floatMnu3_3.icon"
              [innHtml3]="floatMnu3_3.html" [ttEn3]="floatMnu3_3.ttEng" [ttEs3]="floatMnu3_3.ttEsp"
              (mnu3ClikdEvnt)="onClick_fltMnuTxmit($event)" [icon4]="floatMnu3_4.icon" [innHtml4]="floatMnu3_4.html"
              [ttEn4]="floatMnu3_4.ttEng" [ttEs4]="floatMnu3_4.ttEsp" (mnu4ClikdEvnt)="onClick_fltMnuTxmit($event)"
              (closeEvnt)="onClose_mainMnu($event)"></app-floating-menu>
          </div>
        </div>
        <div *ngIf="!selectedCase[selectedCaseIndx]?.procs0.length">
          <!-- Just s spacer -->
        </div>
        <div>
          <span class="mr-0 pr-0 mb-2" style="float: right;"><i class="far fa-plus-square"
              ngbTooltip="{{engLang ? 'More CPT codes secondary': 'Más códigos CPT secundario'}}"
              (click)="onClick_addProc('2');"></i></span>
        </div>
      </div>

      <div *ngIf="selectedCase[selectedCaseIndx]"> <!-- Dont render if not ready -->
        <!--Proc Codes P-->
        <!--Proc Codes P-->
        <!--Proc Codes P-->
        <!--Proc Codes P-->
        <!--Proc Codes P-->

        <div class="text-center sectionTit">
          {{ engLang ? "Patient's Charges": "Cargos Privados" }}
        </div>

        <div class="d-flex justify-content-between">
          <div>
          </div>

          <div>
          </div>

          <div>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-bordered table-hover table-sm mt-2">
            <thead class="table-dark">
              <tr style="font-size: smaller;">
                <!-- <th style="display: none;"></th>
                <th style="display: none;"></th>
                <th style="display: none;"></th> -->

                <th class="w74 text-center p-0 m-0">
                  {{engLang ? 'Dates' : 'Fechas'}}
                </th>
                <th class="w128 text-center p-0 m-0">
                  {{engLang ? 'Code' : 'Código'}}
                </th>
                <th class="w29 text-center p-0 m-0">
                  {{engLang ? 'Pos' : 'Lug' }}
                </th>
                <th class="w65pc text-center p-0 m-0">
                  {{engLang ? 'Qty' : 'Cant' }}
                </th>
                <th class="w40 text-center p-0 m-0">
                  Dx
                </th>
                <th class="w56 text-center p-0 m-0">
                  {{engLang ? 'Coins' : 'Cargo'}}
                </th>
                <th class="w99 text-center p-0 m-0">
                  {{engLang ? 'Rendr' : 'Prod'}}
                </th>
                <th class="text-center p-0 m-0">
                  {{engLang ? 'Description' : 'Descripción'}}
                </th>
              </tr>
            </thead>

            <tbody *ngFor="let proc of selectedCase[selectedCaseIndx].procs0; let e = even; let o = odd; let p = index;"
              style="overflow-x: hidden; overflow-y: auto; height: auto;">

              <tr class="py-0 my-0" [ngClass]="{ evenDetSrch : e, oddDetSrch : o }">
                <!-- tabindex in conjunction with CSS tbody tr:focus {} allows row focusing -->
                <!-- <td style="display: none;">
                  {{ proc.detID }}
                </td>
                <td style="display: none;">
                  {{ proc.detCasID }}
                </td>
                <td style="display: none;">
                  {{ proc.ps }}
                </td> -->

                <td class="w74 p-0 m-0">
                  <div>
                    <div class="d-sm-inline-flex">
                      <input id="{{ 'frDt0_' + p }}" class="form-control font85" placeholder="mm/dd/yy" name="dtp"
                        #cfrom="ngModel" [(ngModel)]="proc.fromDtYy" ngbDatepicker
                        parserFormatter="CustomDateParserFormatter"
                        (dateSelect)="proc.fromDtYy = onDtpDateSelect('frDt',dfrom._inputValue,p,'0',proc)"
                        #dfrom="ngbDatepicker"
                        [ngClass]="errsProc0[p]?.frDt ? { 'redBkg': errsProc0[p].frDt } : { 'edited': proc.fromDtYy != casOrg?.procs0[p].fromDtYy }"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (blur)="proc.fromDtYy = onDtpDateSelect('frDt',dfrom._inputValue,p,'0',proc)"
                        [disabled]="toBeDeletedDisabled(proc.detID)" />
                      <i class="far fa-calendar-times mt-2" (click)="dfrom.toggle()"></i>
                    </div>
                  </div>
                  <div class="d-sm-inline-flex">
                    <input id="{{ 'toDt0_' + p }}" class="form-control font85" placeholder="mm/dd/yy" name="dtp"
                      #cto="ngModel" [(ngModel)]="proc.toDtYy" ngbDatepicker parserFormatter="CustomDateParserFormatter"
                      (dateSelect)="proc.toDtYy = onDtpDateSelect('toDt',dto._inputValue,p,'0',proc)"
                      #dto="ngbDatepicker"
                      [ngClass]="errsProc0[p]?.toDt ? { 'redBkg': errsProc0[p].toDt } : { 'edited': proc.toDtYy != casOrg?.procs0[p].toDtYy }"
                      [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                      (blur)="proc.toDtYy = onDtpDateSelect('toDt',dto._inputValue,p,'0',proc)"
                      [disabled]="toBeDeletedDisabled(proc.detID)" />
                    <i class="far fa-calendar-times mt-2" (click)="dto.toggle()"></i>
                  </div>
                </td>

                <td class="w128 text-center p-0 m-0">
                  <div class="d-sm-inline-flex flex-column justify-content-start mx-0">
                    <input type="text" id="{{'proc0_' + p }}" class="form-control font85"
                      placeholder="{{ engLang ? 'CPT' : 'CPT' }}" style="width: 50px;"
                      [ngClass]="{ 'edited': proc.code != casOrg?.procs0[p].code }" [(ngModel)]="proc.code"
                      [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                      [(ngModel)]="proc.code" (keyup)="onKeyUp_SearchPcode($event);" maxlength="6" size="5"
                      [disabled]="toBeDeletedDisabled(proc.detID)">

                    <div class="d-sm-inline-flex justify-content-between mx-0">
                      <input type="text" id="{{'modI0_' + p }}" name="modifier" class="px-1 mr-0 form-control font85"
                        placeholder="{{ engLang ? 'Mod' : 'Mod' }}"
                        [ngClass]="{ 'edited': proc.mod1 != casOrg?.procs0[p].mod1 }" [(ngModel)]="proc.mod1"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (keyup)="onKeyUp_SearchPcode($event);" maxlength="2" size="2"
                        [disabled]="toBeDeletedDisabled(proc.detID)">
                      <input type="text" id="{{'modII0_' + p }}" name="modifier" class="px-1 mr-0 form-control font85"
                        placeholder="{{ engLang ? 'M2' : 'M2' }}"
                        [ngClass]="{ 'edited': proc.mod2 != casOrg?.procs0[p].mod2 }" [(ngModel)]="proc.mod2"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (keyup)="onKeyUp_SearchPcode($event);" maxlength="2" size="2"
                        [disabled]="toBeDeletedDisabled(proc.detID)">
                      <input type="text" id="{{'modIII0_' + p }}" name="modifier" class="px-1 mr-0 form-control font85"
                        placeholder="{{ engLang ? 'M3' : 'M3' }}"
                        [ngClass]="{ 'edited': proc.mod3 != casOrg?.procs0[p].mod3 }" [(ngModel)]="proc.mod3"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (keyup)="onKeyUp_SearchPcode($event);" maxlength="2" size="2"
                        [disabled]="toBeDeletedDisabled(proc.detID)">
                      <input type="text" id="{{'modIV0_' + p }}" name="modifier" class="px-1 mr-0 form-control font85"
                        placeholder="{{ engLang ? 'M4' : 'M4' }}"
                        [ngClass]="{ 'edited': proc.mod4 != casOrg?.procs0[p].mod4 }" [(ngModel)]="proc.mod4"
                        [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                        (keyup)="onKeyUp_SearchPcode($event);" maxlength="2" size="2"
                        [disabled]="toBeDeletedDisabled(proc.detID)">
                    </div>
                  </div>
                </td>

                <td class="w29 text-center p-0 m-0">
                  <input type="text" id="{{'pos0_' + p }}" maxlength="2"
                    class="text-center px-0 pt-1 mx-0 form-control font85" placeholder="{{ engLang ? 'Pos' : 'Lu' }}"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [(ngModel)]="proc.pos" size="2" [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w65pc text-start p-0 m-0">
                  <input type="number" id="{{ 'qty0_' + p }}" step="any" class="px-1 pt-1 form-control font85"
                    placeholder="{{ engLang ? 'Qty' : 'Cant' }}"
                    [ngClass]="errsProc0[p]?.qty ? { 'redBkg' : errsProc0[p].qty } : { 'edited': proc.qty != casOrg?.procs0[p].qty }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [(ngModel)]="proc.qty" (ngModelChange)="calcCaseTotals()" size="6"
                    [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w40 p-0 m-0">
                  <input type="text" maxlength="4" class="text-left pt-1 px-1 form-control font85"
                    placeholder="{{ engLang ? 'Pointer' : 'Puntero' }}"
                    [ngClass]="errsProc0[p]?.dx ? { 'redBkg' : errsProc0[p].dx } : { 'edited': proc.dx != casOrg?.procs0[p].dx }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [(ngModel)]="proc.dx" size="1" [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w56 m-0 p-0">
                  <input type="text" id="{{'pvdo0_' + p }}" maxlength="9" class="text-end px-1 form-control font85"
                    placeholder="{{ engLang ? 'Private' : 'Privado' }}"
                    [ngClass]="errsProc0[p]?.pvdo ? { 'redBkg': errsProc0[p].pvdo } : { 'edited': proc.pvdo != casOrg?.procs0[p].pvdo }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [ngModel]="proc.pvdo | currency:'USD':'':'1.2-2'" (keypress)="onKeyPress_decimal($event)"
                    (blur)="proc.pvdo = onBlur_setDecimalValue($event); calcCaseTotals();"
                    [disabled]="toBeDeletedDisabled(proc.detID)">
                </td>

                <td class="w99 p-0 m-0">
                  <div class="d-inline-block font85">
                    <dd-ngbdropdown [labl]="proc.prod.substring(0,10)" [preVal]="proc.prod" [srchStr]=""
                      [fontsmall]=true [rawList]="this._recordService.localProductors" [stylesCfg]="stylesCfg"
                      [listTyp]="'prodID0'" [engLang]="engLang" [height]="'400px'"
                      (ddnListSelectionClkd)="ddnListSelectionClkd($event,p)"
                      [tt]=" engLang ? 'Search by alias': 'Buscar por alias' "></dd-ngbdropdown>
                  </div>
                </td>

                <td class="text-center p-0 m-0">
                  <input type="text" id="{{'desc0_' + p }}" class="form-control font75" style="margin-top: 3px;"
                    placeholder="{{ engLang ? 'Description' : 'Descripción' }}" maxlength="50"
                    [ngClass]="errsProc0[p]?.desc ? { 'redBkg': errsProc0[p].desc } : { 'edited': proc.desc != casOrg?.procs0[p].desc }"
                    [ngStyle]="{ 'text-decoration-line': (proc.detID < 0 ? 'line-through' : ''), 'color': (proc.detID < 0 ? 'red': '') }"
                    [(ngModel)]="proc.desc" (keyup)="onKeyUp_SearchPcode($event);" ngbTooltip={{proc.desc}}
                    tabindex="-1" [disabled]="toBeDeletedDisabled(proc.detID)">

                  <div class="d-flex justify-content-between">
                    <div><!-- spacer --></div>
                    <div class="d-flex gap-3 mt-auto mb-auto">
                      <div>
                        <i class="fa fa-undo mr-3 pr-3 red" (click)="onClick_DeleteProc('0', p)"
                          [ngStyle]="{ 'display': (+proc.detID < 0 ? '' : 'none') }"
                          ngbTooltip="{{ engLang ? 'Restore': 'Restaurar' }}"></i>

                        <i class="fa fa-trash mr-3 pr-3" (click)="onClick_DeleteProc('0', p)"
                          [ngStyle]="{ 'display': (+proc.detID >= 0 ? '' : 'none') }"
                          ngbTooltip="{{ engLang ? 'Delete': 'Eliminar' }}"></i>
                      </div>
                      <div>
                        <i class="far fa-copy ml-3 pl-3" ngbTooltip="{{ engLang ? 'Duplicate': 'Duplicar' }}"
                          [ngStyle]="{ 'display': (proc.detID < 0 ? 'none' : '') }"
                          (click)="onClick_CopyProc('0',p);"></i>
                      </div>
                      <div><!-- spacer --></div>
                    </div>
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <span class="mr-0 pr-0 mb-2" style="float: right;"><i class="far fa-plus-square"
            ngbTooltip="{{engLang ? 'More CPT codes private': 'Más códigos CPT privado'}}"
            (click)="onClick_addProc('0');"></i></span>
      </div>

      <div *ngIf="selectedCase[selectedCaseIndx]"> <!-- Dont render if not ready -->
        <!--Payments-->
        <!--Payments-->
        <!--Payments-->
        <!--Payments-->
        <!--Payments-->

        <div class="text-center sectionTit">
          {{ engLang ? 'Payments' : 'Pagos' }}
          <div class="text-center p-0 m-0" style="font-size: 50%; height: 1px;">({{
            selectedCase[selectedCaseIndx].pays.length }})</div>
        </div>

        <div class="table-responsive" style="width: 100%;">
          <table class="table table-bordered table-hover table-sm mt-2">
            <thead class="table-dark">
              <tr style="font-size: smaller;">
                <th style="display: none;"></th>
                <th style="display: none;"></th>
                <th style="display: none;"></th>
                <th class="w27 text-center p-0 m-0">
                  {{engLang ? 'PS' : 'FP'}}
                </th>
                <th class="w74 text-center p-0 m-0">
                  {{engLang ? 'Date' : 'Fecha'}}
                </th>
                <th class="w65py text-center p-0 m-0">
                  {{engLang ? 'Amount' : 'Pago'}}
                </th>
                <th class="w99 text-center p-0 m-0">
                  {{engLang ? 'Mode' : 'Modo' }}
                </th>
                <th class="w157 text-center p-0 m-0">
                  {{engLang ? 'Cheque No' : 'Check No' }}
                </th>
                <th class="w157 text-center p-0 m-0">
                  <div
                    ngbTooltip="{{engLang ? 'Payment internal control no. from payer': 'No. de control interno del pago según pagador'}}">
                  </div>
                  ICN
                </th>
                <th class="w183 text-center p-0 m-0">
                  Memo
                </th>
              </tr>
            </thead>

            <tbody *ngFor="let pay of selectedCase[selectedCaseIndx].pays; let e = even; let o = odd; let p = index;"
              style="overflow-x: hidden; overflow-y: hidden;">

              <tr class="py-0 my-0" [ngClass]="{ evenDetSrch : e, oddDetSrch : o }">
                <!-- tabindex in conjunction with CSS tbody tr:focus {} allows row focusing -->
                <td style="display: none;">
                  {{ pay.payID }}
                </td>
                <td style="display: none;">
                  {{ pay.payCasID }}
                </td>
                <td style="display: none;">
                  {{ pay.payDetID }}
                </td>

                <td class="w27 p-0 m-0">
                  <input type="text" class="form-control text-center font85" placeholder="{{ engLang ? 'PS' : 'FP' }}"
                    value="{{ pay.payPS }}" maxlength="2"
                    [ngClass]="errsPay && errsPay[p]?.fp ? { 'redBkg': payPayPSErr[p] } : { 'edited': !+pay.payID }"
                    [(ngModel)]="pay.payPS" [readonly]="pay.payID > 0" size="2">
                </td>

                <td class="w74 p-0 m-0">
                  <div class="d-sm-inline-flex">
                    <input class="form-control font85" placeholder="mm/dd/yy" name="dtp" #cpay="ngModel"
                      [(ngModel)]="pay.payDtYy" ngbDatepicker parserFormatter="CustomDateParserFormatter"
                      (dateSelect)="pay.payDtYy = onDtpDateSelect('payDt',dpay._inputValue,p,undefined,pay)"
                      #dpay="ngbDatepicker"
                      [ngClass]="errsPay && errsPay[p]?.dt ? { 'redBkg': payPayDtErr[p] } : { 'edited': !+pay.payID || !pay.payDt }"
                      (blur)="pay.payDtYy = onDtpDateSelect('payDt',dpay._inputValue,p,undefined,pay)" />
                    <i class="far fa-calendar-times mt-2" (click)="dpay.toggle()"></i>
                  </div>
                </td>

                <td class="w65py p-0 m-0">
                  <input type="text" maxlength="9" class="text-end px-1 form-control font85"
                    placeholder="{{ engLang ? 'Amount' : 'Pago' }}"
                    [ngClass]="errsPay && errsPay[p]?.amnt ? { 'redBkg': payPayAmntErr[p] } : { 'edited': !+pay.payID }"
                    [ngModel]="pay.payAmnt | currency:'USD':'':'1.2-2'" (keypress)="onKeyPress_decimal($event)"
                    (blur)="pay.payAmnt = onBlur_setDecimalValue($event); calcCaseTotals();" [readonly]="pay.payID > 0"
                    size="6">
                </td>

                <td class="w99 p-0 m-0">
                  <div class="dropdown dropdown-menu-start dropleft py-0 my-1 mx-1">

                    <div class="d-inline-block font85">
                      <dd-ngbdropdown [preVal]="pay.payMode" [labl]="pay.payMode" [width]="'50px'" [srchStr]=""
                        [font75]=true [rawList]="paymntModes" [stylesCfg]="stylesCfg" [listTyp]="'payMode'"
                        [engLang]="engLang" (ddnListSelectionClkd)="ddnListSelectionClkd($event,p)"
                        [tt]="engLang ? 'Select pay mode': 'Modo de pago'"></dd-ngbdropdown>
                    </div>

                  </div>
                </td>

                <td class="w157 p-0 m-0">
                  <input type="text" maxlength="10" class=" text-center form-control font85"
                    placeholder="{{ engLang ? 'Check No' : 'No Cheque' }}" [ngClass]="{ 'edited': !+pay.payID }"
                    [(ngModel)]="pay.payCkNo" [readonly]="pay.payID > 0" size="20">
                </td>

                <td class="w157 text-center p-0 m-0">
                  <input type="text" class="text-center form-control font85" placeholder="ICN"
                    [ngClass]="{ 'edited': !+pay.payID }" [(ngModel)]="pay.payApCode" [readonly]="pay.payID > 0"
                    size="10">
                </td>

                <td class="w183 text-center p-0 m-0">
                  <input type="text" class="text-left form-control font75 mt-1" placeholder="Memo"
                    [ngClass]="{ 'edited': !+pay.payID }" [(ngModel)]="pay.payMemo" [readonly]="pay.payID > 0"
                    maxlength="50">
                  <div>
                    <span *ngIf="!+pay.payID" style="float: left;">
                      <i class="fa fa-trash" (click)="onClick_DeletePaymnt(p)"></i>
                    </span>
                    <i class="far fa-copy"></i>
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <span class="mr-0 pr-0 mb-2" style="float: right;"><i class="far fa-plus-square"
            ngbTooltip="{{engLang ? 'More payments': 'Más pagos'}}" (click)="onClick_addPaymnt()"></i></span>
      </div>
      <!-- <pre>{{ selectedCase[selectedCasID].pays | json }}</pre> -->

      <div *ngIf="selectedCase[selectedCaseIndx]"> <!-- Dont render if not ready -->
        <!--Totals-->
        <!--Totals-->
        <!--Totals-->
        <!--Totals-->
        <!--Totals-->

        <div class="text-center sectionTit">
          <span>{{ engLang ? 'Totals' : 'Totales' }}</span>
        </div>

        <div class="table-responsive" style="width: 100%;">
          <table class="table table-sm table-bordered table-hover table-striped">
            <thead class="table-dark">
              <tr>
                <th class="w30"></th>
                <th class="w366 text-center p-0 m-0">
                  {{ engLang ? 'Submitted' : 'Sometido' }}
                </th>
                <th class="w40 text-center">
                  <div ngbTooltip="{{ engLang ? 'Reclaim/Dispute/Adjust': 'Reclamo/Disputa/Ajuste' }}">
                    Recl
                  </div>
                </th>
                <th class="w102 text-center p-0 m-0">
                  {{ 'Balance' }}
                </th>
                <th class="w102 text-center p-0 m-0">
                  {{ engLang ? 'Charges' : 'Cargos' }}
                </th>
                <th class="w102 text-center p-0 m-0">
                  {{engLang ? 'Payed' : 'Pagos' }}
                </th>
                <th class="w102 text-center p-0 m-0">
                  {{ engLang ? 'Adjust(+)' : 'Ajuste(+)' }}
                </th>
                <th class="w102 text-center p-0 m-0">
                  {{engLang ? 'Adjust(-)' : 'Ajuste(-)' }}
                </th>
              </tr>
            </thead>

            <tbody style="overflow-x: hidden; overflow-y: hidden;">
              <tr>
                <td class="w70 text-start">
                  {{ engLang ? 'Ins(1)' : 'Seg(1)' }}
                </td>

                <td class="w366 m-0 p-0">
                  <div class="d-flex justify-content-between p-1">
                    <div>
                      {{ selectedCase[selectedCaseIndx].casSubDt1 }}
                    </div>
                    <div>
                      {{ selectedCase[selectedCaseIndx].casSubT1 }}
                    </div>
                    <div
                      (click)="ps='1'; open(submittedHxShowModal, { backdrop: 'static', scrollable: true }, 'submittedHxShowModal')">
                      <i class="fas fa-history"></i>
                    </div>
                  </div>
                </td>

                <td class="w40 text-center">
                  <!-- {{selectedCase[selectedCaseIndx].recl1}} -->
                  <input type="checkbox" id="recl1" class="text-center form-check-input"
                    ngModel="+selectedCase[selectedCaseIndx].recl1" (change)="openReclaim('1')"
                    [disabled]="disableReclaim('1')">
                </td>

                <td class="w102 text-end m-0 p-0">
                  <div
                    [ngClass]="getOverDueColor(selectedCase[selectedCaseIndx].casBal1, selectedCase[selectedCaseIndx].casDate)">
                    <strong>
                      {{ selectedCase[selectedCaseIndx].casBal1 | currency:'USD':'':'1.2-2' }}
                    </strong>
                  </div>
                </td>

                <td class="w102 text-end  m-0 p-0">
                  <div>
                    {{ selectedCase[selectedCaseIndx].casChg1 | currency:'USD':'':'1.2-2' }}
                  </div>
                </td>

                <td class="w102 text-end  m-0 p-0">
                  <div>
                    {{ selectedCase[selectedCaseIndx].casPay1 | currency:'USD':'':'1.2-2' }}
                  </div>
                </td>

                <td class="w102 text-end  m-0 p-0">
                  <div>
                    {{ selectedCase[selectedCaseIndx].casAdjP1 | currency:'USD':'':'1.2-2' }}
                  </div>
                </td>

                <td class="w102 text-end  m-0 p-0">
                  <div>
                    {{ selectedCase[selectedCaseIndx].casAdjN1 | currency:'USD':'':'1.2-2' }}
                  </div>
                </td>
              </tr>

              <tr>
                <td class="w70 text-start">
                  {{ engLang ? 'Ins(2)' : 'Seg(2)' }}
                </td>

                <td class="w366 text-center m-0 p-0"
                  (click)="ps='2'; open(submittedHxShowModal, { backdrop: 'static', scrollable: true }, 'submittedHxShowModal')">
                  <div class="d-flex justify-content-between p-1">
                    <div>
                      {{ selectedCase[selectedCaseIndx].casSubDt2 }}
                    </div>
                    <div>
                      {{ selectedCase[selectedCaseIndx].casSubT2 }}
                    </div>
                    <div>
                      <i class="fas fa-history"></i>
                    </div>
                  </div>
                </td>

                <td class="w40 text-center">
                  <input type="checkbox" id="recl2" class="text-center form-check-input"
                    [checked]="+selectedCase[selectedCaseIndx].recl2" (change)="openReclaim('2')"
                    [disabled]="disableReclaim('2')">
                </td>

                <td class="w102 text-end m-0 p-0">
                  <div
                    [ngClass]="getOverDueColor(selectedCase[selectedCaseIndx].casBal2, selectedCase[selectedCaseIndx].casDate)">
                    <strong>
                      {{ selectedCase[selectedCaseIndx].casBal2 | currency:'USD':'':'1.2-2' }}
                    </strong>
                  </div>
                </td>

                <td class="w102 text-end m-0 p-0">
                  <div>
                    {{ selectedCase[selectedCaseIndx].casPay2 | currency:'USD':'':'1.2-2' }}
                  </div>
                </td>

                <td class="w102 text-end m-0 p-0">
                  <div>
                    {{ selectedCase[selectedCaseIndx].casChg2 | currency:'USD':'':'1.2-2' }}
                  </div>
                </td>

                <td class="w102 text-end m-0 p-0">
                  <div>
                    {{ selectedCase[selectedCaseIndx].casAdjP2 | currency:'USD':'':'1.2-2' }}
                  </div>
                </td>

                <td class="w102 text-end m-0 p-0">
                  <div>
                    {{ selectedCase[selectedCaseIndx].casAdjN2 | currency:'USD':'':'1.2-2' }}
                  </div>
                </td>
              </tr>

              <tr>
                <td class="w70 text-start">
                  {{ engLang ? 'Private' : 'Privado' }}
                </td>

                <td class="w366 text-center m-0 p-0"
                  (click)="ps='0'; open(submittedHxShowModal, { backdrop: 'static', scrollable: true }, 'submittedHxShowModal')">
                  <div class="d-flex justify-content-between p-1">
                    <div>
                      {{ selectedCase[selectedCaseIndx].casSubDtP }}
                    </div>
                    <div>
                      {{ selectedCase[selectedCaseIndx].casSubTP }}
                    </div>
                    <div>
                      <i class="fas fa-history"></i>
                    </div>
                  </div>
                </td>

                <td class="w40"></td>

                <td class="w102 text-end m-0 p-0">
                  <div
                    [ngClass]="getOverDueColor(selectedCase[selectedCaseIndx].casBalP, selectedCase[selectedCaseIndx].casDate)">
                    <strong>
                      {{ selectedCase[selectedCaseIndx].casBalP | currency:'USD':'':'1.2-2' }}
                    </strong>
                  </div>
                </td>

                <td class="w102 text-end m-0 p-0">
                  <div>
                    {{ selectedCase[selectedCaseIndx].casPayP | currency:'USD':'':'1.2-2' }}
                  </div>
                </td>

                <td class="w102 text-end m-0 p-0">
                  <div>
                    {{ selectedCase[selectedCaseIndx].casChgP | currency:'USD':'':'1.2-2' }}
                  </div>
                </td>

                <td class="w102 text-end m-0 p-0">
                  <div>
                  </div>
                </td>

                <td class="w102 text-end m-0 p-0">
                  <div>
                    {{ selectedCase[selectedCaseIndx].casAdjNP | currency:'USD':'':'1.2-2' }}
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Go Top/Bottom Arrows-->
      <button class="btn btn-floating btn-lg" [ngStyle]="{ bottom: winVertCtrUp, right: winHorzCtr }" (click)="goTop()"
        id="scrollUpBtn"><i class="fas fa-arrow-up"></i></button>
      <button class="btn btn-floating btn-lg" [ngStyle]="{ bottom: winVertCtrDn, right: winHorzCtr }"
        (click)="goBottom()" id="scrollDnBtn"><i class="fas fa-arrow-down"></i></button>
      <button *ngIf="!modalBody" class="btn btn-floating btn-lg" [ngStyle]="{ bottom: winVertBack2srch }"
        (click)="beforeTabChange({ nextId: 1, activeId: 2, preventDefault: function  })" id="back2srchBtn"><i
          class="fas fa-search blueColor"></i></button>

      <!-- Go back to top -->
      <div class="text-center">
        <input id="lastTab" type="text" class="m-0 p-0" (keydown.Tab)="onKeyDown_focus($event, 'recNo');" maxlen="0"
          size="0" style="width: 1px; height: 1px;">
      </div>

      <!-- Modals -->
      <!-- Modals -->
      <!-- Modals -->
      <!-- Modals -->
      <!-- Modals -->

      <ng-template #submittedHxShowModal let-modal let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4>{{ engLang ? 'Submission Hx': 'Historial de envíos' }}</h4>
          <a class="btn btn-close" style="margin-top: -32px;" (click)="d('Cross click close');"></a>
          <!-- x upper right corner to close -->
        </div>

        <div class="modal-body">
          <table class="table table-sm">
            <thead>
              <th scope="col" class="text-center">
                {{ engLang ? 'Date':'Fecha' }}
              </th>
              <th scope="col" class="text-center">
                {{ engLang ? 'Type': 'Tipo' }}
              </th>
              <th scope="col" class="text-center">
                {{ engLang ? 'User': 'Usuario' }}
              </th>
            </thead>
            <tbody
              *ngFor="let sub of selectedCase[selectedCaseIndx].submitted | psPipe: ps; let e = even; let o = odd; let s = index;">
              <tr [ngStyle]="{ 'color': (e ? 'white': 'black'), 'background-color': (e ? 'black': 'white') }">
                <td>
                  {{ sub.subDt }}
                </td>
                <td>
                  {{ sub.subDtType }}
                </td>
                <td>
                  {{ sub.subDtUser }}
                </td>
                <!-- TODO: Display 835, 277, TA1, 997 Status codes from [X12_Register] -->
              </tr>
            </tbody>
          </table>
          <!-- <pre>{{ selectedCase[selectedCaseIndx].submitted | json }}</pre> -->
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-light" (click)="c('Close click')">Close</button>
        </div>
      </ng-template>

      <ng-template #signCaptureModal let-modal let-c="close" let-d="dismiss">
        <div class="modal-header">
          <div>
            <h4>{{ tmpStr }}</h4>
          </div>
          <div>
            <a class="btn btn-close btn-sm" (click)="d('X-closeModal');" style="margin-top: -32px;"></a>
            <!-- x upper right corner to close -->
          </div>
        </div>

        <div class="modal-body text-center" id="signCnvModalHeadDiv">
          <canvas id="signCanvas" width="422.4" height="125.8"
            [ngStyle]="{'pointer-events': (tmpSign || noSigning ? 'none' : 'auto')}"
            style="border: 1px solid #000000; background-color: lightyellow;"></canvas>
        </div>

        <div class="modal-footer justify-content-between">
          <div>
            <i class="fa fa-trash" (click)="clearCanvas();"
              ngbTooltip="{{ engLang ? 'Erase signature': 'Borrar firma' }}"></i>
          </div>
          <div>
            <button type="button" class="btn btn-light btn-sm" (click)="reqSignViaSms()"
              [disabled]="!isTelOk(pat.cell) || tmpSign || noSigning"
              [ngStyle]="{'cursor': isTelOk(pat.cell) ? 'pointer': 'not-allowed'}">{{
              (engLang ? 'Send request to sign to: ':
              'Enviar solicitud para firma al: ') + (pat.cell ? fmtTel(pat.cell): '(000)000-0000 ')}}</button>
          </div>
          <div>
            <button type="button" class="btn btn-success btn-sm" [disabled]="tmpSign ? true: false"
              (click)="acceptSignature() ? d('close-modal'): false">{{ engLang ? 'Accept':
              'Aceptar' }}</button>
          </div>
        </div>
      </ng-template>

      <div>
        <table class="table table-bordered table-sm mb-0 pb-0 mt-1 unselectable">
          <tr class="menuHt">
            <td class="ptr" style="background: #f7d9d3; width:33%;" (click)="deleteRecordCase('C');">
              <div class="text-center">
                <span>{{ engLang ? 'Delete ' : 'Eliminar ' }}</span>
                <i class="fa fa-trash"></i>
                <span>{{ engLang ? ' Case' : ' Caso' }}</span>
              </div>
            </td>
            <td style="background: #b6c6d4; width:33%;">
              <div class="text-center">
                <span>{{ engLang ? 'New ' : 'Caso ' }}</span>
                <i class="far fa-file ptr" (click)="abortEdits($event, false, true, undefined, undefined)"></i>
                <span>{{ engLang ? ' Case' : ' Nuevo' }}</span>
              </div>
            </td>
            <td class="ptr" style="background: #f7f7b0; width:33%;" (click)="saveRecord(false);">
              <div class="text-center">
                <i class="far fa-save"></i>
                <span>{{ engLang ? ' Save' : ' Grabar' }}</span>
              </div>
            </td>
          </tr>
        </table>
        <footer class="text-center">
          <p style="font-size: xx-small; color: lightgrey;">&copy; 2023-2024 JLRosado. All rights reserved.</p>
        </footer>

      </div>
    </ng-template>


    <ng-template ngbNavContent [ngIf]="activeTab == 3">
      <!--Documents-->
      <!--Documents-->
      <!--Documents-->
      <!--Documents-->
      <!--Documents-->

      <div class="col col-sm-12">
        <div *ngIf="deError" class="text-center btn-danger">
          {{ deError }}
        </div>
        <div *ngIf="waiting4Success" class="text-center btn-success">
          <img src="/app/assets/images/waiting4Response.gif" width="20" height="20" alt="saving" class="img-fluid"
            *ngIf="saveStatus">
          {{ ' ' + saveStatus + ' ' }}
          <img src="/app/assets/images/waiting4Response.gif" width="20" height="20" alt="saving" class="img-fluid"
            *ngIf="waiting4Success">
        </div>

        <div>
          <table class="table table-bordered table-sm mb-0 pb-0 mt-1 unselectable">
            <tr class="menuHt ptr">
              <td style="background: #f7d9d3; width:33%;" (click)="deleteRecordCase('R');">
                <div class="text-center">
                  <span>{{ engLang ? 'Delete ' : 'Eliminar ' }}</span>
                  <i class="fa fa-trash"></i>
                  <span> Record</span>
                </div>
              </td>
              <td style="background: #b6c6d4; width:33%;"
                (click)="abortEdits(undefined, true, false, undefined, undefined)">
                <div class="text-center">
                  <span>{{ engLang ? 'New ' : 'Record ' }}</span>
                  <i class="far fa-file"></i>
                  <span>{{ engLang ? ' Record' : ' Nuevo' }}</span>
                </div>
              </td>
              <td style="background: #f7f7b0; width:33%;" (click)="saveRecord(false);">
                <div
                  *ngIf="!this.scanLic && !this.scanID1 && !this.scanID2 && !this.scanPhoto && !this.scanRec && !this.scanCas"
                  class="text-center">
                  <i class="far fa-save"></i>
                  <span>{{ engLang ? ' Save' : ' Grabar' }}</span>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <p class="text-center mb-1" style="font-size: 90%;">{{ pat.lastNm }}{{ (pat.lastNm ? ',': '') }}
          {{ pat.firstNm }} {{ pat.midInit }}
          <span style="font-size: 70%;">Sex: </span>{{ pat.sex }}
          <span style="font-size: 70%;">{{ engLang ? 'Age:' : 'Edad:' }} </span>{{ pat.age }}
          <span style="font-size: 70%;">Rec No: </span>{{ pat.recNo }}
        </p>
        <div class="row" *ngIf="!waiting4Docs">
          <div class="col-sm-4 text-center mb-0 border" *ngIf="!scanLic">
            <span class="sectionTit">ID
              <img src="app/assets/images/scanner-pngrepo-com.png" ngbTooltip="{{ engLang ? 'Scan': 'Escanear' }}"
                width="20" height="40" alt="scanner" class="img-fluid ml-2 mr-0 pr-0" id="scanLic"
                (click)="scanDoc($event);" />
              <!-- <i class="fas fa-print ml-3" ngbTooltip="{{ engLang ? 'Print': 'Imprimir' }}"
                (click)="printImg('licImg');"></i> -->
              <i *ngIf="patImg.licPkey > 0" id="licView" class="far fa-eye ml-3"
                ngbTooltip="{{ engLang ? 'View': 'Ver' }}" (click)="onClick_showImg($event)"></i>
            </span>
            <!-- <img [src]="patImg.lic" id="licImg" class="img-fluid mb-0" *ngIf="patImg.lic" /> -->
            <div class="d-flex mt-0">
              <div class="mx-auto p-2">
                <span style="font-size: 70%;">{{ patImg.licDt }}</span>
              </div>
              <!-- <div class="p-2" *ngIf="patImg.lic">
                <i class="fa fa-trash" style="font-size: 75%;" ngbTooltip="{{ engLang ? 'Delete': 'Eliminar' }}"
                  (click)="onClick_deleteImg($event);" id="delImgLic"></i>
              </div> -->
            </div>
          </div>

          <div class="col-sm-4 text-center mb-0" *ngIf="scanLic">
            <dwt-scan (scannedDoc)="onScannedDoc($event)"></dwt-scan>
          </div>

          <div class="col-sm-4 text-center mb-0 border" *ngIf="!scanID1">
            <span class="sectionTit">{{ engLang ? 'Card 1': 'Tarjeta 1' }}
              <img src="app/assets/images/scanner-pngrepo-com.png" ngbTooltip="{{ engLang ? 'Scan': 'Escanear' }}"
                width="20" height="40" alt="scanner" class="img-fluid ml-2 mr-0 pr-0" id="scanID1"
                (click)="scanDoc($event);" />
              <!-- <i class="fas fa-print ml-3" ngbTooltip="{{ engLang ? 'Print': 'Imprimir' }}" (click)="printImg('id1Img')"></i> -->
              <i *ngIf="patImg.id1Pkey > 0" id="id1View" class="far fa-eye ml-3"
                ngbTooltip="{{ engLang ? 'View': 'Ver' }}" (click)="onClick_showImg($event)"></i>
            </span>
            <!-- <img [src]="patImg.id1" id="id1Img" class="img-fluid mb-0" *ngIf="patImg.id1" /> -->
            <div class="d-flex justify-content-center mt-0">
              <div class="mx-auto p-2">
                <span style="font-size: 70%;">{{ patImg.id1Dt }}</span>
              </div>
              <!-- <div class="p-2" *ngIf="patImg.id1">
                <i class="fa fa-trash" style="font-size: 75%;" ngbTooltip="{{ engLang ? 'Delete': 'Eliminar' }}"
                  (click)="onClick_deleteImg($event);" id="delImgID1"></i>
              </div> -->
            </div>
          </div>

          <div class="col-sm-4 text-center mb-0" *ngIf="scanID1">
            <dwt-scan (scannedDoc)="onScannedDoc($event)"></dwt-scan>
          </div>

          <div class="col col-sm-4 text-center mb-0 border" *ngIf="!scanID2">
            <span class="sectionTit">{{ engLang ? 'Card 2': 'Tarjeta 2' }}
              <img src="app/assets/images/scanner-pngrepo-com.png" ngbTooltip="{{ engLang ? 'Scan': 'Escanear' }}"
                width="20" height="40" alt="scanner" class="img-fluid ml-2 mr-0 pr-0" id="scanID2"
                (click)="scanDoc($event);" />
              <!-- <i class="fas fa-print ml-3" ngbTooltip="{{ engLang ? 'Print': 'Imprimir' }}" (click)="printImg('id2Img')"></i> -->
              <i *ngIf="patImg.id2Pkey > 0" id="id2View" class="far fa-eye ml-3"
                ngbTooltip="{{ engLang ? 'View': 'Ver' }}" (click)="onClick_showImg($event)"></i></span>
            <!-- <img [src]="patImg.id2" id="id2Img" class="img-fluid mb-0" *ngIf="patImg.id2" /> -->
            <div class="d-flex mt-0">
              <div class="mx-auto p-2">
                <span style="font-size: 70%;">{{ patImg.id2Dt }}</span>
              </div>
              <!-- <div class="p-2" *ngIf="patImg.id2">
                <i class="fa fa-trash" style="font-size: 75%;" ngbTooltip="{{ engLang ? 'Delete': 'Eliminar' }}"
                  (click)="onClick_deleteImg($event);" id="delImgID2"></i>
              </div> -->
            </div>
          </div>

          <div class="col-sm-4 text-center mb-0" *ngIf="scanID2">
            <dwt-scan (scannedDoc)="onScannedDoc($event)"></dwt-scan>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4 text-center mb-0" *ngIf="!scanRec">
            <span class="sectionTit">{{ engLang ? 'Record Documents': 'Documentos Record' }}
              <img src="app/assets/images/scanner-pngrepo-com.png" ngbTooltip="{{ engLang ? 'Scan': 'Escanear' }}"
                width="20" height="40" alt="scanner" class="img-fluid ml-2 mr-0 pr-0" id="scanRec"
                (click)="scanDoc($event);" />
            </span>
          </div>

          <div class="col-sm-4 text-center mb-0" *ngIf="scanRec">
            <dwt-scan (scannedDoc)="onScannedDoc($event)"></dwt-scan>
          </div>

          <div class="col-sm-4 offset-sm-4 text-center mb-0" *ngIf="!scanCas">
            <span class="sectionTit">{{ engLang ? 'Case Documents': 'Documentos Caso' }}
              <img src="app/assets/images/scanner-pngrepo-com.png" ngbTooltip="{{ engLang ? 'Scan': 'Escanear' }}"
                width="20" height="40" alt="scanner" class="img-fluid ml-2 mr-0 pr-0" id="scanCas"
                (click)="scanDoc($event);" />
            </span>
          </div>

          <div class="col-sm-4 offset-sm-4 text-center mb-0" *ngIf="scanCas">
            <dwt-scan (scannedDoc)="onScannedDoc($event)"></dwt-scan>
          </div>
        </div>

        <div *ngIf="waiting4Docs || waiting4Img" class="mt-3 text-center">
          <img src="app/assets/images/waiting4Response.gif" width="25" height="25" alt="waiting4Docs"
            class="img-fluid" />
        </div>

        <div *ngIf="!waiting4Docs" class="mt-3 text-center">
          <div class="text-center mb-0 pb-0">
            <span class="mb-0 pb-0 sectionTit">{{ engLang ? 'Documents & Images':
              'Documentos e
              Imágenes' }}</span>
          </div>
          <div class="text-center mt-0 pt-0">
            <span class="mt-0 pt-0" style="font-size: 60%;">{{ engLang ? '(Last Cases))':
              '(Ultimos Casos)' }}</span>
          </div>
          <table class="table table-sm table-hover table-responsive-sm">
            <thead style="background-color: rgb(227, 240, 240);">
              <tr>
                <th scope="col" style="display: none;">docID</th>
                <th scope="col" style="display: none;">docCasID</th>
                <th scope="col">{{ engLang ? 'Date': 'Fecha' }}</th>
                <th scope="col">Desc</th>
                <th scope="col">{{ engLang ? 'Case No': 'No Caso' }}</th>
                <th scope="col"></th>
                <th scope="col" style="display: none;"></th>
                <th scope="col" style="display: none;"></th>
              </tr>
            </thead>
            <tbody *ngFor="let img of images; let e = even; let o = odd; let i = index;"
              [ngClass]="{ oddImgs: o, evenImgs: e }">
              <tr (mouseenter)="onMouseEnter_images($event);"
                [ngStyle]="{ 'background-color': (img.docID == 0 ? '#cdeac0': '') }">
                <td style="display: none;">{{ img.docCasID }}</td>
                <td style="display: none;">{{ img.docID }}</td>
                <td>{{ img.docDt }}</td>
                <td><input class="mx-0 px-1" type="text" maxlength="50" style="border: none;" [(ngModel)]="img.docDesc"
                    (keyup)="onKeyUp_imgDesc($event);" />
                </td>
                <!-- TODO: Ck docCasPatID instead of undefined in line 3624 -->
                <td style="color: #4285F4"
                  [ngStyle]="{ 'font-weight': (img.docCasID == selectedCase[selectedCaseIndx].casID ? 'bold': 'normal'), 'cursor': 'pointer' }"
                  (click)="abortEdits($event, false, true, img.docCasID, undefined)">{{ img.docCasNo }}
                </td>
                <td>
                  <i class="far fa-eye" ngbTooltip="{{ engLang ? 'View': 'Ver' }}"
                    (click)="onClick_showImg($event)"></i>
                </td>
                <td style="display: none;">
                  {{ img.docCRUD }}
                </td>
                <td style="display: none;">
                  {{ img.docBase64 }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row"> <!-- Top Save/Delete Menu -->
          <table class="table table-bordered table-sm mb-0 pb-0 mt-1 unselectable">
            <tr class="menuHt ptr">
              <td style="background: #f7d9d3; width:33%;" (click)="deleteRecordCase('C');">
                <div class="text-center">
                  <span>{{ engLang ? 'Delete ' : 'Eliminar ' }}</span>
                  <i class="fa fa-trash"></i>
                  <span>{{ engLang ? ' Case' : ' Caso' }}</span>
                </div>
              </td>
              <td style="background: #b6c6d4; width:33%;">
                <div class="text-center">
                  <span>{{ engLang ? 'New ' : 'Caso ' }}</span>
                  <i class="far fa-file"></i>
                  <span>{{ engLang ? ' Case' : ' Nuevo' }}</span>
                </div>
              </td>
              <td class="ptr" style="background: #f7f7b0; width:33%;" (click)="saveRecord(false);">
                <div
                  *ngIf="!this.scanLic && !this.scanID1 && !this.scanID2 && !this.scanPhoto && !this.scanRec && !this.scanCas"
                  class="text-center">
                  <i class="far fa-save"></i>
                  <span>{{ engLang ? ' Save' : ' Grabar' }}</span>
                </div>
              </td>
            </tr>
          </table>
        </div>

      </div>
    </ng-template>

    <ng-template ngbNavContent [ngIf]="activeTab === 5">

      <app-data-view-debug [label]="debugLabel" [data]="debugData" [comp]="debugComp"></app-data-view-debug>

    </ng-template>
  </div>
</div>

<ng-template #errors> <!-- Shows data entry errors upon save() -->
  <div>
    <ul class="m-0" *ngFor="let msg of errsMsgs; let e = even; let o = odd; let m = index;">
      <li>{{ msg }}</li>
    </ul>
  </div>
</ng-template>

<ng-template #reclaimsModalTmpl let-modal>
  <div class="modal-header" style="background-color:  #c1c1bf;">
    <div>
      <h4 class="modal-title mb-1">{{ engLang ? 'Reclaim Form': 'Forma para Reclamo' }}
      </h4>
    </div>
    <div *ngIf="spinner" class="modal-tit-center-div">
      <img src="app/assets/images/waiting4Response.gif" width="42" height="42" class="text-center">
    </div>
    <div class="align-self-start">
      <button type="button" class="btn btn-sm btn-close" (click)="closeReclaimsModal()"
        (keyup)="onKeyup_escape($event)"></button>
    </div>
  </div>

  <div class="modal-body m-0 p-0" (keyup)="onKeyup_escape($event)">
    <!-- DON'T CHANGE THIS PADDING AS IT WILL DISPLACE THE RECLAIM IMAGES -->

    <div *ngIf="reclCasIdSeled && reclPsSeled">
      <app-reclaim [sn]="sn" [engLang]="engLang" [casID]="reclCasIdSeled" [ps]="reclPsSeled"
        [reclTyp]="reclFormTypSeled" [saveToggle]="saveToggle" [deleteToggle]="deleteToggle" [insIdSeld]="reclInsID"
        [reclID]="reclID" [printToggle]="printToggle" [idxSeld]="reclIdxSeld" [icnsPrevIdx]="icnsPrevIdx"
        (closeEventEmitter)="closeReclaimComponent($event)" (enabSaveReclEventEmitter)="setEnabSaveReclBtn($event)"
        (resetSaveToggleEventEmitter)="resetSaveToggle($event)"
        (resetDeleteToggleEventEmitter)="resetDeleteToggle($event)"
        (showPrintDeleteBtnEventEmitter)="printDelete($event)"
        (ckUnckClmReclaimEventEmitter)="ckUnckReclaimCkBox($event)" (spinnerEventEmitter)="toggleSpinner($event)"
        (resetIcnsPrevIdxEventEmitter)="resetIcnsPrevIdx($event)">
      </app-reclaim>
    </div>

  </div>

  <div class="modal-footer justify-content-between" style="background-color: #c1c1bf;">
    <div style="font-size: 70%;">
      {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}}
    </div>
    <div *ngIf="+reclID" class="d-flex justify-content-evenly">
      <div *ngIf="!spinner">
        <button type="button" class="btn btn-secondary" (click)="deleteToggle=true">{{ engLang ?
          'Delete':
          'Eliminar' }}
          <i class="fa fa-trash"></i>
        </button>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<!-- spacer -->
      </div>
      <div>
        <button type="button" class="btn btn-success" (click)="print()">{{
          engLang ? 'Print': 'Imprimir' }}
          <i class="fas fa-print cursor-pointer"></i>
        </button>
      </div>
    </div>
    <div>
      <button type="button" class="btn btn-outline-dark" *ngIf="!spinner" [disabled]="!enabSaveRecl"
        (click)="saveToggle = true">{{ engLang ? 'Save': 'Grabar' }}</button>
    </div>
    <!-- <pre>{{ modalStack }}</pre> -->
  </div>
</ng-template>

<!-- modal reclaim exit w/o saving .................................................................. -->
<ng-template #reclaimsExitWoSaveModal let-modal>
  <div class="modal-header" style="background-color:  #d6deec;">
    <div>
      <h4 class="modal-title"><img src="app/assets/images/yellow-blue-green-question-marks.png" width="28" height="28">
      </h4>
    </div>
    <div class="align-self-start">
      <button type="button" class="btn btn-sm btn-close"
        (click)="modal.dismiss('closeReclaimExitWoSaveModal')"></button>
    </div>
  </div>
  <div class="modal-body text-center">
    {{ engLang ? 'Exit w/o Saving ?': '¿ Salir sin Grabar ?' }}
  </div>
  <div class="modal-footer justify-content-between" style="background-color: #d6deec;">
    <div style="font-size: 70%;">
      <!-- {{ engLang ? 'Esc - Close': 'Esc - Cerrar'}} Diisable to avoid eternal loop when Esc reactivates background modal -->
    </div>
    <div>
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('oK_exitWoSaveReclaimModal')">Ok</button>
    </div>
  </div>
</ng-template>



<!--
<div *ngIf="showExtHmlTst">
  <app-signature-capture [queryString]="qsTst"></app-signature-capture>
</div>
-->

<!-- DATA DEBUG -->

<!-- <hr>
<pre>patImg</pre>
<pre>{{ patImg | json }}</pre>

<hr>
<pre>images</pre>
<pre>{{ images.length }}</pre>
<pre>{{ images | json }}</pre>

<hr>
<pre>localDedcodes ({{ localDedcodes.length }})</pre>
<pre>{{ localDedcodes | json }}</pre>

-->

<!-- <hr>
<pre>selectedCase[selectedCaseIndx]</pre>
<pre>{{ selectedCaseIndx }}</pre>
<pre>{{ selectedCase[selectedCaseIndx] | json }}</pre>
<pre>"casOrg?"</pre>
<pre>{{ casOrg | json }}</pre> -->

<!-- <hr>
<pre>patImg</pre>
<pre> {{ patImg | json }}</pre> -->

<!-- <hr>
<pre>images</pre>
<pre>{{ images.length }}</pre>
<pre>{{ images | json }}</pre> -->

<!-- <hr>
<pre>userSecOpts</pre>
<pre>{{ userSecOpts | json }}</pre>

<hr>
<pre>activeTab</pre>
<pre>{{ activeTab }}</pre>
<pre>{{ activeId }}</pre>
<pre>{{ nextId }}</pre>
<hr>
<pre>locales</pre>
<pre>{{ locales.length }}</pre>

<hr>
<pre>_recordService.localInsurances</pre>
<pre>{{ _recordService.localInsurances.length }}</pre>

<hr>
<pre>_recordService.localProviders</pre>
<pre>{{ _recordService.localProviders.length }}</pre>
<pre>{{ _recordService.localProviders | json }}</pre>

<hr>
<pre>_recordService.localFacilities</pre>
<pre>{{ _recordService.localFacilities.length }}</pre>

<hr>
<pre>this._recordService.localProductors</pre>
<pre>{{ this._recordService.localProductors.length }}</pre>

<hr>
<pre>_recordService.localReferring</pre>
<pre>{{ _recordService.localReferring.length }}</pre>

<hr>
<pre>_recordService.localICD10</pre>
<pre>{{ _recordService.localICD10.length }}</pre>

<hr>
<pre>localZipCodes</pre>
<pre>{{ localZipCodes.length }}</pre>

<hr>
<pre>localConfig</pre>
<pre>{{ localConfig.length }}</pre>
<pre>{{ localConfig | json }}</pre>

<hr>
<pre>localPcodes</pre>
<pre>{{ localPcodes.length }}</pre>

<hr>
<pre>casOrg?</pre>
<pre>{{ casOrg? | json }}</pre>

<hr>
<pre>pat</pre>
<pre> {{ pat | json }}
  </pre> -->

<!--  <hr>

  <pre>site.nm = {{ site.nm }}</pre>
  <pre>sn = {{ sn }}</pre>
  <pre>userID = {{ userID }}</pre>
  <pre>userLastNm = {{ userLastNm }}</pre>
  <pre>userLastNm = {{ userFirstNm }}</pre>

  <hr>
  <pre>showCasesHx = {{ showCasesHx }}</pre>

  <hr>
  <pre>casesHxRefresh = {{ casesHxRefresh }}</pre>

<hr>
<pre>showProcs = {{ showProcs }}</pre>

  <hr>
  <pre>selectedCaseIndx = {{ selectedCaseIndx }}</pre>
  <pre>selectedCase.length = {{ selectedCase.length }}</pre>

  <hr>
  <pre>records.length = {{ records.length }}</pre>
  <pre>{{ records | json }} </pre>

  <hr>
  <pre>selectedCase = {{ selectedCase | json }}</pre>

  <hr>
  <pre>pat</pre>
  <pre> {{ pat | json }}</pre>

  <hr>
  <pre>patOrg</pre>
  <pre> {{ patOrg | json }}</pre>

  <hr>
  <pre>casOrg?</pre>
  <pre> {{ casOrg? | json }}</pre>

  <hr>
  <pre>errsCas.provId = {{ errsCas.provId }}</pre>

  <hr>
  <pre>_recordService.localICD10</pre>
  <pre>{{ _recordService.localICD10 | json }}</pre>

  <hr>
  <pre>localPcodes ({{ localPcodes.length }})</pre>
  <pre>{{ localPcodes | json }}</pre>

  <hr>
  <pre>localDedcodes</pre>
  <pre>{{ localDedcodes | json }}</pre>

  <hr>
  <pre>localXcecodes</pre>
  <pre>{{ localXcecodes | json }}</pre>

  <hr>
  <pre>results</pre>
  <pre>{{ results | json }}</pre>

  <hr>
  <pre>localZipCodes</pre>
  <pre>{{ localZipCodes | json }}</pre>
 -->