<div style="position: relative;">
    <!-- Use id="ReclaimFormFields" only to get column ReclFrmXml for table [ReclaimForms] with rtn createReclaimXml_developmentHelperRtn() -->
    <div>
        <img [src]="reclFormImg64Seled" alt="Form Image" width="100%" height="100%">
    </div>

    <!-- <opt3MPMC_1></opt3MPMC_1> -->
    <div class="form-check-input opt3MPMC_1">
        <input type="radio" id="opt3MPMC_1" name="a" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.participant" value="true">
    </div>

    <!-- <opt3MPMC_2></opt3MPMC_2> -->
    <div class="form-check-input opt3MPMC_2">
        <input type="radio" id="opt3MPMC_2" name="a" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.participant" value="false">
    </div>

    <!-- <opt3MPMC_3></opt3MPMC_3> -->
    <div class="form-check-input opt3MPMC_3">
        <input type="radio" id="opt3MPMC_3" name="b" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.location" value="md">
    </div>

    <!-- <opt3MPMC_4></opt3MPMC_4> -->
    <div class="form-check-input opt3MPMC_4">
        <input type="radio" id="opt3MPMC_4" name="b" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.location" value="fac">
    </div>

    <!-- <opt3MPMC_5></opt3MPMC_5> -->
    <div class="form-check-input opt3MPMC_5">
        <input type="radio" id="opt3MPMC_5" name="b" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.location" value="other">
    </div>

    <!-- <txt3MPMC_Other_B></txt3MPMC_Other_B> -->
    <div class="txt3MPMC_Other_B">
        <input type="text" id="txt3MPMC_Other_B" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Other_B" style="width: 150px">
    </div>

    <!-- <opt3MPMC_6></opt3MPMC_6> -->
    <div class="form-check-input opt3MPMC_6">
        <input type="radio" id="opt3MPMC_6" name="c" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.type" value="aha">
    </div>

    <!-- <opt3MPMC_7></opt3MPMC_7> -->
    <div class="form-check-input opt3MPMC_7">
        <input type="radio" id="opt3MPMC_7" name="c" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.type" value="ca">
    </div>

    <!-- <opt3MPMC_8></opt3MPMC_8> -->
    <div class="form-check-input opt3MPMC_8">
        <input type="radio" id="opt3MPMC_8" name="c" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.type" value="other">
    </div>

    <!-- <txt3MPMC_Other_C></txt3MPMC_Other_C> -->
    <div class="txt3MPMC_Other_C">
        <input type="text" id="txt3MPMC_Other_C" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Other_C" style="width: 150px">
    </div>

    <!-- <txt3MPMC_ProvNm></txt3MPMC_ProvNm> -->
    <div class="txt3MPMC_ProvNm">
        <input type="text" id="txt3MPMC_ProvNm" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ProvNm" style="width: 420px">
    </div>

    <!-- <txt3MPMC_TaxID></txt3MPMC_TaxID> -->
    <div class="txt3MPMC_TaxID">
        <input type="text" id="txt3MPMC_TaxID" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_TaxID" style="width: 170px">
    </div>

    <!-- <txt3MPMC_RendNPI></txt3MPMC_RendNPI> -->
    <div class="txt3MPMC_RendNPI">
        <input type="text" id="txt3MPMC_RendNPI" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_RendNPI" style="width: 170px">
    </div>

    <!-- <txt3MPMC_BillNPI></txt3MPMC_BillNPI> -->
    <div class="txt3MPMC_BillNPI">
        <input type="text" id="txt3MPMC_BillNPI" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_BillNPI" style="width: 170px">
    </div>

    <!-- <txt3MPMC_Tel></txt3MPMC_Tel> -->
    <div class="txt3MPMC_Tel">
        <input type="text" id="txt3MPMC_Tel" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Tel" style="width: 170px">
    </div>

    <!-- <txt3MPMC_Email></txt3MPMC_Email> -->
    <div class="txt3MPMC_Email">
        <input type="text" id="txt3MPMC_Email" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Email" style="width: 170px">
    </div>

    <!-- <txt3MPMC_Name1></txt3MPMC_Name1> -->
    <div class="txt3MPMC_Name1">
        <input type="text" id="txt3MPMC_Name1" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Name1" style="width: 210px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ContrNo1></txt3MPMC_ContrNo1> -->
    <div class="txt3MPMC_ContrNo1">
        <input type="text" id="txt3MPMC_ContrNo1" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo1" style="width: 120px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Dx1></txt3MPMC_Dx1> -->
    <div class="txt3MPMC_Dx1">
        <input type="text" id="txt3MPMC_Dx1" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Dx1" style="width: 95px; height: 20px;" class="font10">
    </div>

    <!-- <txt3MPMC_ReclamNo1></txt3MPMC_ReclamNo1> -->
    <div class="txt3MPMC_ReclamNo1">
        <input type="text" id="txt3MPMC_ReclamNo1" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo1" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Date1></txt3MPMC_Date1> -->
    <div class="txt3MPMC_Date1">
        <input type="text" id="txt3MPMC_Date1" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date1" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_PayDt1></txt3MPMC_PayDt1> -->
    <div class="txt3MPMC_PayDt1">
        <input type="text" id="txt3MPMC_PayDt1" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_PayDt1" style="width: 83px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Reason1></txt3MPMC_Reason1> -->
    <div class="txt3MPMC_Reason1">
        <input type="text" id="txt3MPMC_Reason1" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason1" style="width: 65px; height: 20px;" class="text-center font10">
    </div>

    <!-- <txt3MPMC_Comment1></txt3MPMC_Comment1> -->
    <div class="txt3MPMC_Comment1">
        <input type="text" id="txt3MPMC_Comment1" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Comment1" style="width: 210px; height: 20px;" class="font10">
    </div>

    <!-- <txt3MPMC_Name2></txt3MPMC_Name2> -->
    <div class="txt3MPMC_Name2">
        <input type="text" id="txt3MPMC_Name2" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Name2" style="width: 210px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ContrNo2></txt3MPMC_ContrNo2> -->
    <div class="txt3MPMC_ContrNo2">
        <input type="text" id="txt3MPMC_ContrNo2" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo2" style="width: 120px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Dx2></txt3MPMC_Dx2> -->
    <div class="txt3MPMC_Dx2">
        <input type="text" id="txt3MPMC_Dx2" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Dx2" style="width: 95px; height: 20px;" class="font10">
    </div>

    <!-- <txt3MPMC_ReclamNo2></txt3MPMC_ReclamNo2> -->
    <div class="txt3MPMC_ReclamNo2">
        <input type="text" id="txt3MPMC_ReclamNo2" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo2" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Date2></txt3MPMC_Date2> -->
    <div class="txt3MPMC_Date2">
        <input type="text" id="txt3MPMC_Date2" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date2" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_PayDt2></txt3MPMC_PayDt2> -->
    <div class="txt3MPMC_PayDt2">
        <input type="text" id="txt3MPMC_PayDt2" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_PayDt2" style="width: 83px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Reason2></txt3MPMC_Reason2> -->
    <div class="txt3MPMC_Reason2">
        <input type="text" id="txt3MPMC_Reason2" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason2" style="width: 65px; height: 20px;" class="text-center font10">
    </div>

    <!-- <txt3MPMC_Comment2></txt3MPMC_Comment2> -->
    <div class="txt3MPMC_Comment2">
        <input type="text" id="txt3MPMC_Comment2" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Comment2" style="width: 210px; height: 20px;" class="font10">
    </div>

    <!-- <txt3MPMC_Name3></txt3MPMC_Name3> -->
    <div class="txt3MPMC_Name3">
        <input type="text" id="txt3MPMC_Name3" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Name3" style="width: 210px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ContrNo3></txt3MPMC_ContrNo3> -->
    <div class="txt3MPMC_ContrNo3">
        <input type="text" id="txt3MPMC_ContrNo3" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo3" style="width: 120px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Dx3></txt3MPMC_Dx3> -->
    <div class="txt3MPMC_Dx3">
        <input type="text" id="txt3MPMC_Dx3" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Dx3" style="width: 95px; height: 20px;" class="font10">
    </div>

    <!-- <txt3MPMC_ReclamNo3></txt3MPMC_ReclamNo3> -->
    <div class="txt3MPMC_ReclamNo3">
        <input type="text" id="txt3MPMC_ReclamNo3" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo3" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Date3></txt3MPMC_Date3> -->
    <div class="txt3MPMC_Date3">
        <input type="text" id="txt3MPMC_Date3" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date3" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_PayDt3></txt3MPMC_PayDt3> -->
    <div class="txt3MPMC_PayDt3">
        <input type="text" id="txt3MPMC_PayDt3" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_PayDt3" style="width: 83px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Reason3></txt3MPMC_Reason3> -->
    <div class="txt3MPMC_Reason3">
        <input type="text" id="txt3MPMC_Reason3" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason3" style="width: 65px; height: 20px;" class="text-center font10">
    </div>

    <!-- <txt3MPMC_Comment3></txt3MPMC_Comment3> -->
    <div class="txt3MPMC_Comment3">
        <input type="text" id="txt3MPMC_Comment3" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Comment3" style="width: 210px; height: 20px;" class="font10">
    </div>

    <!-- <txt3MPMC_Name4></txt3MPMC_Name4> -->
    <div class="txt3MPMC_Name4">
        <input type="text" id="txt3MPMC_Name4" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Name4" style="width: 210px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ContrNo4></txt3MPMC_ContrNo4> -->
    <div class="txt3MPMC_ContrNo4">
        <input type="text" id="txt3MPMC_ContrNo4" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo4" style="width: 120px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Dx4></txt3MPMC_Dx4> -->
    <div class="txt3MPMC_Dx4">
        <input type="text" id="txt3MPMC_Dx4" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Dx4" style="width: 95px; height: 20px;" class="font10">
    </div>

    <!-- <txt3MPMC_ReclamNo4></txt3MPMC_ReclamNo4> -->
    <div class="txt3MPMC_ReclamNo4">
        <input type="text" id="txt3MPMC_ReclamNo4" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo4" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Date4></txt3MPMC_Date4> -->
    <div class="txt3MPMC_Date4">
        <input type="text" id="txt3MPMC_Date4" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date4" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_PayDt4></txt3MPMC_PayDt4> -->
    <div class="txt3MPMC_PayDt4">
        <input type="text" id="txt3MPMC_PayDt4" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_PayDt4" style="width: 83px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Reason4></txt3MPMC_Reason4> -->
    <div class="txt3MPMC_Reason4">
        <input type="text" id="txt3MPMC_Reason4" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason4" style="width: 65px; height: 20px;" class="text-center font10">
    </div>

    <!-- <txt3MPMC_Comment4></txt3MPMC_Comment4> -->
    <div class="txt3MPMC_Comment4">
        <input type="text" id="txt3MPMC_Comment4" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Comment4" style="width: 210px; height: 20px;" class="font10">
    </div>

    <!-- <txt3MPMC_Name5></txt3MPMC_Name5> -->
    <div class="txt3MPMC_Name5">
        <input type="text" id="txt3MPMC_Name5" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Name5" style="width: 210px; height: 20px;">
    </div>

    <!-- <txt3MPMC_ContrNo5></txt3MPMC_ContrNo5> -->
    <div class="txt3MPMC_ContrNo5">
        <input type="text" id="txt3MPMC_ContrNo5" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ContrNo5" style="width: 120px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Dx5></txt3MPMC_Dx5> -->
    <div class="txt3MPMC_Dx5">
        <input type="text" id="txt3MPMC_Dx5" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Dx5" style="width: 95px; height: 20px;" class="font10">
    </div>

    <!-- <txt3MPMC_ReclamNo5></txt3MPMC_ReclamNo5> -->
    <div class="txt3MPMC_ReclamNo5">
        <input type="text" id="txt3MPMC_ReclamNo5" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_ReclamNo5" style="width: 147px; height: 20px;">
    </div>

    <!-- <txt3MPMC_Date5></txt3MPMC_Date5> -->
    <div class="txt3MPMC_Date5">
        <input type="text" id="txt3MPMC_Date5" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date5" style="width: 82px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_PayDt5></txt3MPMC_PayDt5> -->
    <div class="txt3MPMC_PayDt5">
        <input type="text" id="txt3MPMC_PayDt5" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_PayDt5" style="width: 83px; height: 20px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Reason5></txt3MPMC_Reason5> -->
    <div class="txt3MPMC_Reason5">
        <input type="text" id="txt3MPMC_Reason5" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Reason5" style="width: 65px; height: 20px;" class="text-center font10">
    </div>

    <!-- <txt3MPMC_Comment5></txt3MPMC_Comment5> -->
    <div class="txt3MPMC_Comment5">
        <input type="text" id="txt3MPMC_Comment5" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Comment5" style="width: 210px; height: 20px;" class="font10">
    </div>

    <!-- <txt3MPMC_Other></txt3MPMC_Other> -->
    <div class="txt3MPMC_Other">
        <input type="text" id="txt3MPMC_Other" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Other" style="width: 305px; height: 20px;" class="font10">
    </div>

    <!-- <txt3MPMC_Comment></txt3MPMC_Comment> -->
    <div class="txt3MPMC_Comment">
        <input type="text" id="txt3MPMC_Comment" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Comment" style="width: 920px; height: 20px;" class="font10">
    </div>

    <!-- <txt3MPMC_SolicitorNm></txt3MPMC_SolicitorNm> -->
    <div class="txt3MPMC_SolicitorNm">
        <input type="text" id="txt3MPMC_SolicitorNm" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_SolicitorNm" style="width: 230px;" class="text-center">
    </div>

    <!-- <txt3MPMC_Date></txt3MPMC_Date> -->
    <div class="txt3MPMC_Date">
        <input type="text" id="txt3MPMC_Date" (click)="onClick($event)" (change)="onChange_need2Save()"
            [(ngModel)]="e.txt3MPMC_Date" style="width: 95px;">
    </div>

    <div class="lnk1">
        <button type="button" class="btn btn-link" (click)="reasonLnks('1')">__</button>
    </div>

    <div class="lnk2">
        <button type="button" class="btn btn-link" (click)="reasonLnks('2')">__</button>
    </div>

    <div class="lnk3">
        <button type="button" class="btn btn-link" (click)="reasonLnks('3')">__</button>
    </div>

    <div class="lnk4">
        <button type="button" class="btn btn-link" (click)="reasonLnks('4')">__</button>
    </div>

    <div class="lnk5">
        <button type="button" class="btn btn-link" (click)="reasonLnks('5')">__</button>
    </div>

    <div class="lnk6">
        <button type="button" class="btn btn-link" (click)="reasonLnks('6')">__</button>
    </div>

    <div class="lnk7">
        <button type="button" class="btn btn-link" (click)="reasonLnks('7')">__</button>
    </div>

    <div class="lnk8">
        <button type="button" class="btn btn-link" (click)="reasonLnks('8')">__</button>
    </div>

    <div class="lnk9">
        <button type="button" class="btn btn-link" (click)="reasonLnks('9')">__</button>
    </div>

    <!-- <pre>=======</pre>
    <pre>{{ currIndx }}</pre>
    <pre>=======</pre> -->

</div>