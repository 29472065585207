<p class="text-center">{{ reclFormDescSeled }}</p>

<div class="rowContainer">
    <div>
        <span class="text-left mb-0 pb-0 ml-3" style="font-size: 90%;">Request for clerical error reopening:</span>

        <div class="rowContainer border">
            <div class="colContainer rowFlex">

                <div>
                    &nbsp;
                </div>

                <div class="rowContainer zeroPadMar mt-3">
                    <div class="rowFlex">
                        <button type="button" class="btn btn-link zeroPadMar" (click)="onClick_pcode()">Procedure</button>
                        &nbsp;
                        <label class="zeroPadMar">or</label>
                        &nbsp;
                        <button type="button" class="btn btn-link zeroPadMar">Dx
                            code</button>&nbsp;
                        <label class="zeroPadMar">incorrect</label>
                    </div>
                </div>

                <div class="rowFlex">
                    <button type="button" class="btn btn-link zeroPadMar" (click)="onClick_mods()">Modifier
                        omitted
                        or
                        incorrect</button>
                </div>

                <div class="rowFlex">
                    <button type="button" class="btn btn-link zeroPadMar" (click)="onClick_npi()">Provider number
                        incorrect:</button>
                </div>

                <div class="rowFlex">
                    <button type="button" class="btn btn-link zeroPadMar" (click)="onClick_qty()">Quantity billed
                        incorrect:</button>
                </div>

                <div class="rowFlex">
                    <button type="button" class="btn btn-link zeroPadMar" (click)="onClick_usual()">Billed amount
                        incorrect:</button>
                </div>

                <div class="rowFlex">
                    <button type="button" class="btn btn-link zeroPadMar" (click)="onClick_patZip()">Zip code
                        incorrect:</button>
                </div>

                <!-- more rows on column 1 -->
            </div>

            <div class="colContainer rowFlex">
                <div class="rowFlex">
                    Originally
                </div>

                <div class="rowContainer zeroPadMar">
                    <div class="rowFlex">
                        <input id="txt_1" type="text" maxlength="10" (change)="onChange_need2Save()">
                    </div>
                </div>

                <div class="rowContainer zeroPadMar">
                    <div class="rowFlex">
                        <input id="txt_3" type="text" maxlength="10" (change)="onChange_need2Save()">
                    </div>
                </div>

                <div class="rowContainer zeroPadMar">
                    <div class="rowFlex">
                        <input id="txt_5" type="text" maxlength="10" (change)="onChange_need2Save()">
                    </div>
                </div>

                <div class="rowContainer zeroPadMar">
                    <div class="rowFlex">
                        <input id="med_1" type="text" maxlength="10" (change)="onChange_need2Save()">
                    </div>
                </div>

                <div class="rowContainer zeroPadMar">
                    <div class="rowFlex">
                        <input id="txt_8" type="text" maxlength="10" (change)="onChange_need2Save()">
                    </div>
                </div>

                <div class="rowContainer zeroPadMar">
                    <div class="rowFlex">
                        <input id="med_5" type="text" maxlength="10" (change)="onChange_need2Save()">
                    </div>
                </div>

                <!-- more rows on column 2 -->
            </div>

            <div class="colContainer rowFlex">
                <div class="rowFlex">
                    Correction
                </div>

                <div class="rowContainer zeroPadMar">
                    <div class="rowFlex">
                        <input id="txt_2" type="text" maxlength="10" (change)="onChange_need2Save()">
                    </div>
                </div>

                <div class="rowContainer zeroPadMar">
                    <div class="rowFlex">
                        <input id="txt_4" type="text" maxlength="10" (change)="onChange_need2Save()">
                    </div>
                </div>

                <div class="rowContainer zeroPadMar">
                    <div class="rowFlex">
                        <input id="txt_6" type="text" maxlength="10" (change)="onChange_need2Save()">
                    </div>
                </div>

                <div class="rowContainer zeroPadMar">
                    <div class="rowFlex">
                        <input id="med_2" type="text" maxlength="10" (change)="onChange_need2Save()">
                    </div>
                </div>

                <div class="rowContainer zeroPadMar">
                    <div class="rowFlex">
                        <input id="txt_9" type="text" maxlength="10" (change)="onChange_need2Save()">
                    </div>
                </div>

                <div class="rowContainer zeroPadMar">
                    <div class="rowFlex">
                        <input id="med_6" type="text" maxlength="10" (change)="onChange_need2Save()">
                    </div>
                </div>

                <!-- more rows on column 3 -->
            </div>

            <!-- 1 row of 3 columns -->
        </div>

    </div>

    <div>
        <span class="text-left mb-0 pb-0 ml-3" style="font-size: 90%;">The reason I disagree with the initial
            determination is:</span>

        <div class="rowContainer border">
            <div class="colContainer rowFlex">

                <div class="zeroPadMar">
                    <input id="opt_1" type="radio" maxlength="10" name="grpRadios" (change)="onChange_need2Save()">
                    <label for="opt_1" class="m-1">This is an appeal of an overpayment request</label>
                </div>

                <div class="zeroPadMar">
                    <input id="opt_2" type="radio" maxlength="10" name="grpRadios" (change)="onChange_need2Save()">
                    <label for="opt_2" class="m-1">The service was medically necessary</label>
                </div>

                <div class="zeroPadMar">
                    <input id="opt_3" type="radio" maxlength="10" name="grpRadios" (change)="onChange_need2Save()">
                    <label for="opt_3" class="m-1">The service was denied as a duplicate incorrectly</label>
                </div>

                <div class="zeroPadMar">
                    <input id="opt_4" type="radio" maxlength="10" name="grpRadios" (change)="onChange_need2Save()">
                    <label for="opt_4" class="m-1">The service was not overutilized</label>
                </div>

                <div class="zeroPadMar">
                    <input id="opt_5" type="radio" maxlength="10" name="grpRadios" (change)="onChange_need2Save()">
                    <label for="opt_5" class="m-1">The service was denied indicating there was other
                        insurance involvement</label>
                </div>

                <div>
                    <button type="button" class="btn btn-link" (click)="clearRadios()">Clear</button>
                </div>

                <!-- more rows column 4 -->
            </div>

        </div>

    </div>

    <div>
        <span class="text-left mb-0 pb-0 ml-3" style="font-size: 90%;">Please attach all pertinent documentation:</span>

        <div class="rowContainer border">
            <div class="colContainer rowFlex">

                <div class="zeroPadMar">
                    <input id="chk_1" type="checkbox" maxlength="10" (change)="onChange_need2Save()">
                    <label class="m-1">Ambulance run sheet</label>
                </div>

                <div class="zeroPadMar">
                    <input id="chk_2" type="checkbox" maxlength="10" (change)="onChange_need2Save()">
                    <label class="m-1">History and physical</label>
                </div>

                <div class="zeroPadMar">
                    <input id="chk_3" type="checkbox" maxlength="10" (change)="onChange_need2Save()">
                    <label class="m-1">Invoices for unlisted procedures and medication</label>
                </div>

                <div class="zeroPadMar">
                    <input id="chk_4" type="checkbox" maxlength="10" (change)="onChange_need2Save()">
                    <label class="m-1">Diagnostic text results</label>
                </div>

                <div class="zeroPadMar">
                    <input id="chk_5" type="checkbox" maxlength="10" (change)="onChange_need2Save()">
                    <label class="m-1">Pathology reports</label>
                </div>

                <div class="zeroPadMar">
                    <input id="chk_6" type="checkbox" maxlength="10" (change)="onChange_need2Save()">
                    <label class="m-1">Progress notes</label>
                </div>

                <div class="zeroPadMar">
                    <input id="chk_7" type="checkbox" maxlength="10" (change)="onChange_need2Save()">
                    <label class="m-1">Other medical records</label>
                </div>

                <!-- more rows on column 5 -->
            </div>

        </div>

    </div>

    <div class="ml-0 pl-0">
        <span class="text-left mb-0 pb-0 ml-3" style="font-size: 90%;">Additional narrative:</span>
        <textarea id="txt_7" class="form-control" maxlength="300" cols="70" rows="5"
            (change)="onChange_need2Save()"></textarea>
    </div>
</div>